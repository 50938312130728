import { useUserContext } from 'context/userContext';

/**
 * @summary Returns if the logged-in user is an MOR account
 * @returns {[{certificateIsClickable: (function(*): *), canSeeSupplierInfo: (*|boolean), isMoREnabled: (false|*)}]}
 * @example Hook usage,
 * const {isMoREnabled, certificateIsClickable} = useMoRFlag();
 */
export const useMoRFlag = () => {
  const { user = {}, company = {} } = useUserContext();

  const isUserRoleCustomer = user?.role === 'CUSTOMER';
  let company_settings = company?.company_settings;
  // noinspection JSUnresolvedReference
  const isMOR = company_settings?.is_mor_enabled;

  const isMoREnabled = isUserRoleCustomer && isMOR;
  const certificateIsClickable = (certificate) => {
    const url = certificate?.pdfUrl;
    const isPdfFile = url?.toLowerCase()?.endsWith('.pdf') ?? false;
    return (
      certificate.lab !== 'NONE' && // must not be null
      (!isMoREnabled || (isMoREnabled && isPdfFile))
    );
  };

  // noinspection JSUnresolvedReference
  const canSeeSupplierInfo =
    user?.role === 'ADMIN' || company_settings?.is_supplier_details_enabled;
  return { isMoREnabled, certificateIsClickable, canSeeSupplierInfo };
};
