import { gql } from '@apollo/client';
import { MeleeFragment } from './MeleeFragment';
import { CertificateInfoFragment } from './CertificateFragment';
import { GemstoneCertificate } from './GemstoneOfferFragment';

export const adminListItemFragment = gql`
  fragment SupplierListItem on Invoice {
    id
    invoice_number
    invoiced_at
    total_amount
    invoice_nivoda_reference
    invoice_status
    supplier_subtotal
    supplier_total
    status
    review_status
    due_date
    invoice_link
    invoice_comment
    invoice_comment_date
    invoice_approved_date
    invoice_rejected_date
    exchange_rate
    settled_at
    settled_amount
    currency
    usd_amount
    total_amount
    total_amount_tax
    tax_amount
    invoice_tax_rate
    due_date
    payment_discount
    volume_discount
    shipping_charges
    other_charges
    invoice_rejection_list {
      id
      reason
    }
    Payments {
      id
      due_date
    }
    invoice_history {
      id
      value_name
      value_from
      value_to
      createdAt
      updatedAt
      created_by
      invoice_id
      adjustment_note
      reason_rejected
      changed_by
    }
    legal_entity_id
    items {
      id
      rupee_rate
      exchange_rate
      status
      comment
      expectedDeliveryDate
      buy_price
      order_date
      order {
        id
        order_date
      }
      origin_country
      invoice {
        id
        invoice_number
        total_amount
        exchange_rate
        currency
      }
      FbnItem {
        id
        internal_status
        consignment_closure_date
        current_location
      }
      Qc {
        id
        status
      }
      QcGemstone {
        id
        status
      }
      Product {
        ... on Diamond {
          id
          supplierStockId
          bowtie
          brown
          green
          blue
          gray
          milky
          other
          eyeClean
          availability
          has_bgmec_enquiry
          has_media_enquiry
          offer {
            id
            price
            discount
            price_per_carat
            product_price
            product_discount
            return_price
            return_discount
          }
          certificate {
            ...CertificateInfo
          }
          location {
            id
            name
            country
          }
          supplier {
            id
            name
            website
            country
            accept_returns
          }
        }
        ... on Melee {
          ...MeleeFragment
          child_melee_collections {
            ...MeleeFragment
            supplierStockId
            total_price
            price_per_carat
            IsParentMeleeCollection
          }
          total_price
          price_per_carat
          IsParentMeleeCollection
        }
        ... on Gemstone {
          id
          supplierStockId
          availability
          last_enquiry_answered
          has_bgmec_enquiry
          has_media_enquiry
          GemstoneCertificate: certificate {
            id
            ...GemstoneCertificate
          }
          location {
            id
            name
            country
          }
          supplier {
            id
            name
            website
            country
            accept_returns
          }
        }
      }
      FbnType
      ProductType
    }
  }
  ${CertificateInfoFragment}
  ${MeleeFragment}
  ${GemstoneCertificate}
`;
