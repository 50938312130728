import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button } from '@nivoda/ui';
import PropTypes from 'prop-types';
import { asDayjs, isMobile } from 'utils';
import theme from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';
import styled from 'styled-components';

export const DatePicker = (props) => {
  const {
    dateType,
    disabled,
    filterId,
    labelProps = {},
    placeholder,
    placement,
    setStartDate,
    shouldCloseOnSelect = true,
    queryState = {},
    ...rest
  } = props;
  const dateFromState = queryState[filterId];

  const isThisStartDate = dateType === 'startDate';
  const date = dateFromState[dateType]
    ? asDayjs(dateFromState[dateType])
    : null;

  const onChange = (_date) => {
    if (_date && setStartDate) {
      setStartDate({
        date: _date,
        filterId,
        id: isThisStartDate ? 'startDate' : 'endDate',
      });
    }
  };

  return (
    <ReactDatePicker
      {...rest}
      customInput={
        <CustomLabel
          btnId={filterId}
          date={date}
          disabled={disabled}
          text={placeholder}
          {...labelProps}
        />
      }
      disabled={disabled}
      dropdownMode={'select'}
      endDate={asDayjs(dateFromState.endDate)}
      nextMonthButtonLabel={rest.nextMonthButtonLabel || ''}
      onChange={onChange}
      popperPlacement={placement || 'top-end'}
      previousMonthButtonLabel={rest.previousMonthButtonLabel || ''}
      selected={date}
      shouldCloseOnSelect={shouldCloseOnSelect}
      selectsEnd={!isThisStartDate}
      selectsStart={isThisStartDate}
      startDate={asDayjs(dateFromState.startDate)}
      tabIndex={0}
    />
  );
};
const popperPlacementPositions = [
  'bottom',
  'bottom-end',
  'bottom-start',
  'left',
  'left-end',
  'left-start',
  'right',
  'right-end',
  'right-start',
  'top',
  'top-end',
  'top-start',
];

const labelProps = {
  btnId: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  onLabelClick: PropTypes.func,
  text: PropTypes.node,
  unstyled: PropTypes.bool,
};

DatePicker.propTypes = {
  disabled: PropTypes.bool.isRequired,
  dateType: PropTypes.oneOf(['startDate', 'endDate']),
  placement: PropTypes.oneOf(popperPlacementPositions),
  filterId: PropTypes.string.isRequired,
  labelProps: PropTypes.shape(labelProps),
  placeholder: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  shouldCloseOnSelect: PropTypes.bool.isRequired,
  queryState: PropTypes.object.isRequired,
};

const getDate = (date, format = 'DD-MM-YYYY') =>
  date ? date.format(format) : '';

const CustomLabel = ({
  btnId,
  date,
  format,
  iconColor,
  iconName = 'calendar',
  iconSize = 12,
  onLabelClick = () => {},
  prefix,
  text,
  unstyled,
  ...rest
}) => (
  <S date={date} unstyled={unstyled} onClick={onLabelClick}>
    <Button
      className="date_picker--btn"
      ghost
      customPadding={
        unstyled ? '0' : isMobile() ? `6px ${theme.spacing.s1}` : '6px 10px'
      }
      noBorder={false}
      noShadow={unstyled}
      {...(btnId ? { 'data-btn-id': btnId } : {})}
      {...rest}
    >
      <Icon
        aria-hidden="true"
        className="date_picker--icon"
        name={iconName}
        color={
          iconColor || (date ? theme.palette.themePrimary : theme.palette.gray)
        }
        size={iconSize}
        style={{ cursor: 'not-allowed', pointerEvents: 'none', marginTop: -1 }}
      />
      <span className="date_picker--txt">
        {' '}
        {date
          ? prefix
            ? prefix(getDate(date, format))
            : getDate(date, format)
          : text}
      </span>
    </Button>
  </S>
);
const S = styled.span`
  button.btn {
    border: ${({ date, unstyled }) =>
      unstyled
        ? 'none !important'
        : `1px solid ${
            date ? theme.palette.themePrimary : theme.palette.white3
          }`};

    display: flex;
    align-items: center;
    gap: ${theme.spacing.s3};
    ${theme.fonts.absolute.small};
    margin: 0;
  }
`;
CustomLabel.propTypes = labelProps;
