import { gql } from '@apollo/client';

export const GET_PRODUCT_CFM_MARKUP_DETAILS = gql`
  query ($product_id: ID!, $product_type: ProductType!) {
    get_product_cfm_markup_details(
      product_id: $product_id
      product_type: $product_type
    ) {
      cfm_markup_price
      cfm_markup_percentage
    }
  }
`;
