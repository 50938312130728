import { ReqPopup, theme } from '@nivoda/ui';
import styled, { css } from 'styled-components';

export const SMSPreview = styled.div`
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  background: #f4f4f5;
  height: 100%;
  .SMS-preview-pill {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 999px;
    border: 1px solid #18181b;
    background: #18181b;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
  .SMS-preview-card-container {
    position: relative;
    width: 100%;
    .preview-card {
      display: flex;
      width: 100%;
      padding: 4px 9px;
      flex-direction: column;
      gap: 6px;
      border-radius: 10px;
      background: #27272a;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      .card-description {
        color: #fff;
        line-height: 20px;
        font-size: 16px;
        display: inline-block;
        .item-pricing {
          display: flex;
          align-items: center;
        }
      }
      .preview-time {
        color: #71717a;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.8px;
        letter-spacing: -0.3px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .preview-card-shape {
      position: absolute;
      right: -6px;
      bottom: 0px;
    }
  }
`;

export const EmailPreview = styled.div`
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  background: #f4f4f5;
  height: 100%;
  .email-preview-pill {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 999px;
    border: 1px solid #18181b;
    background: #18181b;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
  .email-preview-card {
    display: flex;
    padding: 20px;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #e4e4e7;
    background: #fff;
    .item-media {
      width: 101px;
      height: 101px;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .item-details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1 0 0;
      .item-description {
        color: #27272a;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
      .item-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-self: stretch;
        max-height: 195px;
        overflow-y: auto;
      }
      .list-row {
        display: grid;
        grid-template-columns: 103px 3fr;
        align-items: center;
        gap: 12px;
        .row-label {
          color: #71717a;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
        .row-value {
          color: #27272a;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
        .row-amount {
          color: #27272a;
          font-size: 20px;
          font-weight: 800;
          line-height: 28px;
        }
      }
      .view-detail-btn {
        display: flex;
        height: 40px;
        padding: 10px 12px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #f6f9fa !important;
        background: #f6f9fa !important;
        margin: 0 !important;

        .details-btn-label {
          color: #3e537f;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
        }
        &:hover {
          box-shadow: none !important;
          border: 1px solid #dde2ee !important;
          background: #dde2ee !important;
        }
      }
    }
  }
`;

export const WhatsAppPreviewCard = styled.div`
  display: flex;
  width: 275px;
  padding: 4px;
  flex-direction: column;
  gap: 6px;
  border-radius: 10px;
  background: #e7fed8;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  .video-preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
  .message-container {
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .item-pricing {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
  .message-time-read {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    padding-right: 2px;
    .time {
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      font-weight: 500;
      line-height: 14.8px;
      letter-spacing: -0.3px;
    }
  }
`;

export const StyledInput = styled.div`
  width: 100%;
  & .form-group {
    margin: 0 !important;
    & .form-control {
      border-radius: 4px;
      border: 1px solid #d4d4d8;
      background: #fff;
      height: 40px !important;
      padding: 4px 12px !important;
      &:focus {
        border-color: #d4d4d8 !important;
      }
    }
  }
`;

export const StyledMaskedInput = styled.div`
  width: 100%;
  .markup-input-label {
    color: #27272a;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px !important;
  }
  .markup-input-container {
    display: flex;
    height: 40px;
    padding: 4px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #d4d4d8;
    background: #fff;
    .markup-input {
      width: 100%;
      border: none;
      outline: none;
      font-weight: 400;
      line-height: 20px;
    }
    .markup-input-suffix {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #27272a;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const StyledReqPopup = styled(ReqPopup)`
  * {
    font-family: inter, 'Hind', Arial, sans-serif;
  }
  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: #e4e4e7;
    border-radius: 5px;
    border: 2px solid #fff;
  }
  margin-top: 0 !important;
  padding: 0 !important;
  .drop_down {
    & input {
      height: 0px !important;
      width: 0px !important;
    }
  }
  &.popup {
    display: flex;
    width: ${({ width }) => width};
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: ${({ borderRadius }) => borderRadius || '8px'};
    .content-area {
      width: 100%;
      .popup_title {
        display: none;
      }
      .popup_content {
        padding: 0;
      }

      .custom_radio_font {
        display: flex;
        align-items: center;
        gap: 8px;
        & > div {
          height: 18px;
          width: 18px;
          border: 1px solid #2d3c5c;
          & > span {
            height: 10px;
            width: 10px;
          }
        }
        & > label {
          color: #18181b;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          border: none;
          margin: 0;
        }
      }

      .customize-details-btn {
        display: flex;
        height: 40px;
        padding: 10px 12px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d4d4d8 !important;
        background: #fff !important;
        margin: 0 !important;
        &:hover {
          border: 1px solid #a1a1aa !important;
        }
        .customize-details-btn-label {
          color: #27272a;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
        }
      }

      .share-popup-header {
        display: flex;
        padding: 16px 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid #e4e4e7;
        background: #fff;
        .share-popup-title {
          color: #27272a;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
        .share-popup-close {
          cursor: pointer;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            svg {
              color: #000;
            }
          }
        }
      }
      .share-popup-content {
        display: flex;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        height: 500px;
        .section--title {
          color: #27272a;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        .section--heading {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }

      .share-popup-footer {
        display: flex;
        padding: 16px 24px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-top: 1px solid #e4e4e7;
        background: #fff;

        .share-popup-footer-description {
          color: #71717a;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }
  .share-btn {
    width: 100%;
    height: 40px;
    margin: 0;
    display: flex;
    padding: 10px 12px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px !important;
    border-radius: 4px;
    border: 1px solid #0c0a09 !important;
    background: #0c0a09 !important;
    outline: none;
    &:hover {
      background: #292524 !important;
    }
    .share-btn-label {
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }
  }
  .success-message {
    padding: 20px;
    .copy_clipboard__icon,
    .copy_clipboard__icon.active path {
      &:hover {
        fill: transparent;
      }
    }
  }
  ${({ hasMedia }) =>
    hasMedia &&
    css`
      .diamond_image_container {
        & > div > div > img,
        & > div > img {
          width: 100%;
        }
      }
    `}
  .custom__checkbox > .checkbox_checked.is_disabled {
    background-color: #0c0a09;
    border-color: #0c0a09;
    opacity: 0.5;
  }
  .preview_link {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #1a0dab;
    }
  }
`;

export const PreviewPill = styled.div`
  display: flex;
  padding: ${({ padding }) => padding || '4px 12px'};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ background }) => background};

  ${({ shadow = false }) =>
    shadow &&
    css`
      box-shadow: 0px -0.4000000059604645px 0px 0px #eeeef4,
        0px 0.4000000059604645px 0px 0px rgba(98, 98, 98, 0.2);
    `}

  color: ${({ color }) => color} !important;
  font-size: ${({ fontSize }) => fontSize || '14px'} !important;
  font-weight: 600 !important;
  line-height: normal !important;
`;
export const Span = styled.span`
  color: ${({ color }) => color || 'rgba(0, 0, 0, 0.9)'};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 19px;
  letter-spacing: -0.3px;
  margin-right: ${({ marginRight }) => marginRight};
`;
export const ListSection = styled.div`
  width: ${({ width }) => width};
  flex: 1;
  overflow: auto;
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .section--divider {
    width: 100%;
    height: 1px;
    background: #f4f4f5;
  }

  .shared-toggle {
    display: flex;
    padding: 8px 0px;
    align-items: center;
    gap: 8px;
    .shared-toggle-label {
      color: #18181b;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export const SkeletonElement = styled.div`
  &:empty::after {
    width: ${({ width }) => width};
    ${({ height }) =>
      height &&
      css`
        height: ${({ height }) => height} !important;
      `}
  }
`;
export const PreviewSection = styled.div`
  width: ${({ width }) => width};
  max-height: ${({ maxHeight }) => maxHeight};
  height: ${({ height }) => height};
  position: relative;
  overflow: auto;
`;

export const ListGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  .selected-shared-details {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 2px;
    align-self: stretch;
    flex-wrap: wrap;
    .shared-item {
      color: #27272a;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .customize-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .custom__checkbox {
      gap: 8px;
      .checkbox_media {
        margin: 0 !important;
      }
    }
  }
  .markup-and-final-price {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .final-shared-price-text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    .shared-final-price {
      display: flex;
      align-items: baseline;
      gap: 8px;
      .price__ {
        color: #27272a;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
      .price__info {
        color: #71717a;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .final-price-description {
      color: #ef4444;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  }
`;

export const PhoneInputWrapper = styled.div`
  width: 100% !important;
  &.phone_input_wrapper {
    &--label {
      ${theme.fonts.smallBold}
      line-height:14.52px;
      color: ${theme.palette.Neutrals950};
      font-family: Inter;
      margin-bottom: unset;
      padding-left: 10px;
    }
  }
  .react-tel-input {
    .form-control {
      width: unset;
      font-size: 12px !important;
      &:focus {
        box-shadow: none !important;
        border: 1px solid #d4d4d8 !important;
      }
    }
    input {
      width: 97%;
    }
  }
`;
