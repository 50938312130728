import React, { useEffect, useState } from 'react';
import { navigate, Router } from '@reach/router';
import { gql } from '@apollo/client';
import { Lifecycle } from '@nivoda/common';
import 'react-phone-number-input/style.css';
import 'react-phone-input-2/lib/bootstrap.css';
import { Track } from 'fabric/integrations';
// import { Form } from 'fabric/components';
// import { GraphqlMutation } from 'graphql/factory';
import { WithHistory } from '../../Elements';
import { useTranslation } from 'react-i18next';
import { create_generator_component, GoodState } from '../../MetaComponents';
import { SignupContainer } from '../../Signup/SignupContainer';
import { ConfirmAddress } from './shared/ConfirmAddress.js';
// import completed2 from 'fabric/resources/svg/success-icons/completed2.svg';
import { BusinessInfo } from './shared/BusinessInfo';
import '../../Signup/signup.css';
import { BasicInfoPage } from './shared/BasicInfoPage';
import { ConfirmEmailAndPhone } from './shared/ConfirmEmailAndPhone.js';
import { SubtleButton } from './styles';
import axios from 'axios';
import { ROLES } from './shared/helper';
import { ConfirmEmailWithToken } from 'graphql/mutations/common/user-onboarding';

let NewBasicProfileDetails = ({ setSession }) => {
  let initialState = {
    email: '',
    first_name: '',
    role: ROLES.CUSTOMER,
    country: '',
    phone_country_code: '',
    phone: '',
    defaultCountry: 'GB',
    email_sent: false,
    phone_sent: false,
    phone_confirmed: false,
    email_confirmed: false,
    business_info: false,
    basic_info: false,
    signup_id: '',
    phone_is_invalid: false,
    email_phone_verify: false,
  };
  const [state, setState] = useState(initialState);
  let {
    role,
    email,
    phone,
    phone_confirmed,
    email_confirmed,
    email_sent,
    phone_sent,
    business_info,
    signup_id,
    email_phone_verify,
    first_name,
  } = state;

  const getGeoInfo = async () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          country: role !== 'CUSTOMER' ? data.country_code : '',
          defaultCountry: data.country_code,
          phone_country_code: data.country_calling_code.replace('+', ''),
        });
        localStorage.setItem('defaultCountry', data.country_code);
        localStorage.setItem(
          'phone_country_code',
          data.country_calling_code.replace('+', '')
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  let setDefaultStates = async () => {
    let step = await localStorage.getItem('step');
    var local_role = localStorage.getItem('role');
    if (step !== null && local_role !== null) {
      let loadStep = async (step) => {
        switch (step) {
          case '3':
            setState({
              ...state,
              business_info: true,
              role:
                local_role === ROLES.CUSTOMER
                  ? ROLES.CUSTOMER
                  : local_role === ROLES.SUPPLIER
                  ? ROLES.SUPPLIER
                  : ROLES.CUSTOMER,
            });
            break;
          default:
            setState({
              ...state,
              basic_info: false,
              email_phone_verify: false,
              business_info: false,
            });
            break;
        }
      };
      localStorage.removeItem('graphql_session');
      await loadStep(step);
    } else {
      await getGeoInfo();
      localStorage.removeItem('graphql_session');
      //navigate('/register');
    }
  };

  useEffect(() => {
    (async () => {
      await setDefaultStates();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (email_phone_verify) {
    const all_props = {
      email_sent,
      phone_sent,
      email,
      phone,
      setState,
      state,
      phone_confirmed,
      email_confirmed,
    };

    return <ConfirmEmailAndPhone {...all_props} />;
  }

  if (business_info) {
    localStorage.setItem('step', 3);
    const all_props = {
      input: {
        role,
        signup_id,
        first_name,
      },
      setState,
      state,
      setSession,
    };

    return <BusinessInfo {...all_props} />;
  }

  return <BasicInfoPage setState={setState} state={state} />;
};

let EmailConfirmationWithToken = create_generator_component(function* ({
  props,
  resolve,
}) {
  const { t } = props;
  let { confirmation_token } = props;

  let confirm_email_token = yield <ConfirmEmailWithToken children={resolve} />;
  let {
    state: { error },
    set_state,
  } = yield (
    <GoodState merge initialValue={{ error: null }} children={resolve} />
  );

  return (
    <SignupContainer progress={2}>
      <Lifecycle
        componentDidMount={async () => {
          try {
            let {
              data: { confirm_email_with_token: session },
            } = await confirm_email_token({
              confirmation_token: confirmation_token,
            });
            Track.alias(session.user.id);
            Track.identify(session.user.id, {
              ...session.user,
            });
            Track.track('Onboarding: Email confirmed with token');
            // onConfirm({ session });
          } catch (err) {
            set_state({ error: err });
          }
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h1 className="sign_up__title" style={{ textAlign: 'center' }}>
          {t('confirming_your_email')}...
        </h1>

        {error && <h1 className="sign_up__title">{t('link_has_expired')}</h1>}

        {error && (
          <p>
            {t('please')}{' '}
            <WithHistory>
              {(history) => (
                <SubtleButton onClick={() => history.push('/register')}>
                  {t('request_new_confirmation_link')}
                </SubtleButton>
              )}
            </WithHistory>
          </p>
        )}
      </div>
    </SignupContainer>
  );
});

export let NewOnBoardingPage = (props) => {
  const { t: translation } = useTranslation();
  return (
    <React.Fragment>
      <Router style={{ height: '100%' }}>
        <NewBasicProfileDetails
          path="/"
          setSession={props.setSession}
          t={translation}
          onConfirm={async ({ session }) => {
            await props.setSession(session);
            let {
              data: { me },
            } = await props.apollo.query({
              query: gql`
                query {
                  me {
                    id
                    steps_required
                    role
                  }
                }
              `,
            });
            if (me.role === 'CUSTOMER') {
              await navigate(`/register/set-address`);
            } else {
              await navigate(`/suppliers/stock-info`);
            }
          }}
        />

        <ConfirmAddress
          path="/set-address"
          onConfirm={async ({ session }) => {
            await props.setSession(session);
            await props.apollo.query({
              query: gql`
                query {
                  me {
                    id
                    steps_required
                  }
                }
              `,
            });
          }}
          t={translation}
        />

        <EmailConfirmationWithToken
          path="/confirm-email/:confirmation_token"
          t={translation}
          onConfirm={async ({ session }) => {
            await props.setSession(session);
            let {
              data: { me },
            } = await props.apollo.query({
              query: gql`
                query {
                  me {
                    id
                    steps_required
                    role
                  }
                }
              `,
            });
            if (me.role === 'CUSTOMER') {
              await navigate(`/register/set-address`);
            } else {
              await navigate(`/suppliers/stock-info`);
            }
          }}
        />
      </Router>
    </React.Fragment>
  );
};
