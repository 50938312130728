import { GraphqlFactory, GraphqlQuery } from 'graphql/factory';
import { gql } from '@apollo/client';
import { CertificateMiniInfoFragment, HoldFragment } from '../../fragments';
import { process_single_gemstone_data } from 'Helper/processGemstoneQuery';

export const GET_HOLDS_REQUEST_BY_STATUS = gql`
  query getHoldsByStatus($query: HoldItemQuery!, $offset: Int) {
    get_holds_by_status(limit: 30, offset: $offset, query: $query) {
      total_count
      items {
        ...HoldItemFragment
      }
    }
  }
  ${HoldFragment}
`;
export const GET_HOLDS_REQUEST_BY_STATUS_COUNT = gql`
  query getHoldsByStatus($query: HoldItemQuery!, $offset: Int) {
    get_holds_by_status(limit: 30, offset: $offset, query: $query) {
      total_count
    }
  }
`;
export let map_holds_data = (props) => {
  const items =
    props.get_holds_by_status && props.get_holds_by_status.items
      ? props.get_holds_by_status.items
          .filter((hi) => hi.Product)
          .map(process_single_gemstone_data)
      : [];

  let newProps = Object.assign({}, props, {
    get_holds_by_status: {
      items,
      total_count: props.get_holds_by_status
        ? props.get_holds_by_status.total_count || 0
        : 0,
      __typename: 'HoldList',
    },
  });
  return newProps;
};

export let FetchHoldsRequestByStatus = GraphqlFactory({
  query: GET_HOLDS_REQUEST_BY_STATUS,
  map_data: (props) => map_holds_data(props),
});
export let FETCH_ALL_CFM_HOLDS_REQUEST_BY_STATUS = gql`
  query get_cfm_holds($fetch_all: Boolean!, $offset: Int) {
    get_all_active_cfm_holds(
      limit: 15
      fetch_all: $fetch_all
      offset: $offset
    ) {
      total_count
      items {
        id
        buyer {
          id
          firstName
          lastName
          company {
            id
          }
          __typename
        }
        requested_by {
          id
          firstName
          lastName
        }
        Product {
          ... on Diamond {
            id
            price
            final_price
            certificate {
              ...CertificateMiniInfo
            }
            location {
              id
            }
            supplier {
              id
            }
            offer {
              id
              product_discount
              product_price
              price
              return_price
              return_discount
              price_per_carat
            }
          }
        }
        cfm_final_price
        is_cfm
        customer_reference
        status
        extension_status
      }
    }
  }
  ${CertificateMiniInfoFragment}
`;
export const FetchCFMHoldRequests = GraphqlQuery({
  query: FETCH_ALL_CFM_HOLDS_REQUEST_BY_STATUS,
});
