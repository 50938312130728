/* eslint-disable no-console */
import Axios from 'axios';
import moment from 'moment-business-days';
import { capitalize, isEmpty, uniq } from 'lodash';
import passwordValidator from 'password-validator';
import _ from 'lodash';
import FlagIcon from '@nivoda/react-flag-kit/lib/FlagIcon';
import theme from 'fabric/theme';
import { holdStatusTypes, notificationTypes, ProductTypes } from './constants';
import { CopyText } from '@nivoda/common';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'context/userContext';
import { getUserReviewStatus } from 'utils/user';
import { Tooltip } from '@nivoda/ui';
import cx from 'classnames';
import uniqBy from 'lodash/uniqBy';
import StockWithIcon from 'gemstone/components/certAndStock/stock_with_icon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { featureFlagLS } from 'graphql/factory/featureFlagResolver';

export function formatDate(date, options, monthFormat, customFormat) {
  let month = monthFormat ? monthFormat : 'MMM';
  let separator = month === 'MM' ? '-' : null;

  if (Array.isArray(options)) {
    return moment(new Date(date)).isValid() && date != null && date !== ''
      ? separator
        ? moment(new Date(date)) // eslint-disable-next-line no-unexpected-multiline
            [options[0]](options[1], 'days')
            .format(
              customFormat
                ? customFormat
                : `DD${separator}${month}${separator}YYYY`
            )
        : moment(new Date(date)) // eslint-disable-next-line no-unexpected-multiline
            [options[0]](options[1], 'days')
            .format(customFormat ? customFormat : `DD ${month}, YYYY`)
      : '';
  }
  return moment(new Date(date)).isValid() && date != null && date !== ''
    ? separator
      ? moment(new Date(date)).format(
          customFormat ? customFormat : `DD${separator}${month}${separator}YYYY`
        )
      : moment(new Date(date)).format(
          customFormat ? customFormat : `DD ${month}, YYYY`
        )
    : '';
}

export const getSource = (path = '') => {
  if (window.location.pathname.includes('search')) {
    let path = window.location.pathname.split('search/')[1];
    let isListPage =
      /^(natural\/diamond|labgrown\/diamond|gemstones|natural\/melee|labgrown\/melee)$/.test(
        path
      );
    return isListPage ? 'ProductListing' : 'ProductDetail';
  } else {
    let _path = path !== '' ? path : window.location.pathname;
    if (_path.includes('customer-orders')) {
      return 'Orders';
    } else if (_path.includes('invoices')) {
      return 'Invoices';
    } else if (_path.includes('shortlist')) {
      return 'Shortlist';
    } else if (_path.includes('requests')) {
      return 'Requests';
    } else if (_path.includes('customer-holds')) {
      return 'Holds';
    } else if (_path.includes('feed-center')) {
      return 'FeedCenter';
    } else if (_path.includes('settings')) {
      return 'Settings';
    } else if (_path.includes('checkout')) {
      return 'Checkout';
    } else if (_path.includes('self-serve')) {
      return 'FeedSetup';
    } else if (_path.includes('memo')) {
      return 'Memo';
    } else return null;
  }
};

export const defaultStyles = {
  control: (base, state) => ({
    ...base,
    marginTop: '5px !important',
    border: state.isFocused
      ? `1px solid ${theme.palette.themePrimary} !important`
      : `1px solid ${theme.palette.gray300} !important`,
    borderRadius: '8px !important',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    color: `${theme.palette.gray800}`,
    // This line disable the blue border
    boxShadow: 'none !important',
    maxWidth: '350px',
    minHeight: '40px',
    ':hover': {
      border: `1px solid ${theme.palette.themePrimary} !important`,
    },
  }),
};

export function getConsignmentEndDate(shipment_date, is_fbn) {
  return moment(shipment_date).add(is_fbn ? 180 : 90, 'days');
}

const consolidateShipment = (s) => {
  if (s?.child_shipments?.length > 0) {
    return true;
  }

  if (s?.destination?.owner && s?.origin?.owner) {
    return s.destination.owner.company.id === s.origin.owner.company.id;
  }
  return false;
};

export function canRedrictToConsolidatedShipment(shipment) {
  return (
    consolidateShipment(shipment) &&
    !shipment.transit_shipment &&
    !office_to_office(shipment.origin?.country) &&
    !shipment.fbn_shipment &&
    !(shipment?.origin?.city?.toLowerCase() === 'surat')
  );
}

export function dateToBackendFormat(date) {
  if (date && moment(new Date(date)).isValid()) {
    return moment(new Date(date)).format('DD-MM-YYYY');
  } else {
    return '';
  }
}

export function timeAgo(date) {
  dayjs.extend(relativeTime);
  if (!date) {
    return '';
  }
  return dayjs().to(dayjs(date));
}

export function downloadFile(url) {
  let link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

let shortenText = (text) => {
  if (text.toLowerCase() === 'treated') {
    return 'Tr.';
  }
  let textArray = text.split(' ');
  return textArray.map((t) => t[0]).join('');
};
let map = {
  AU: 'Australia',
  BE: 'Belgium',
  CA: 'Canada',
  CH: 'Switzerland',
  CN: 'China',
  FR: 'France',
  GB: 'UK',
  IL: 'Israel',
  IN: 'India',
  JP: 'Japan',
  AE: 'Dubai',
  NL: 'Netherlands',
  HK: 'Hong Kong',
  TH: 'Thailand',
  SE: 'Sweden',
  US: 'USA',
  SA: 'South Africa',
  ZA: 'South Africa',
  IT: 'Italy',
};

export let location_mapper = (id) => map[id] || 'India';
const GetDiscountStatus = (is_natural) => {
  const naturalDiamondDiscount = featureFlagLS.get()?.naturaldiscount_disable;
  const isSuppPage = window.location.pathname.includes('supplier');
  if (is_natural) {
    return naturalDiamondDiscount;
  } else {
    return !isSuppPage;
  }
};
export let Melee = {
  get_description: (certificate, hideOptions = []) =>
    cx(
      !hideOptions.includes('shape') && Certificate.get_shape(certificate),
      !hideOptions.includes('cut') && Certificate.get_cut(certificate),
      !hideOptions.includes('polish') && Certificate.get_polish(certificate),
      !hideOptions.includes('symmetry') &&
        Certificate.get_symmetry(certificate),
      !hideOptions.includes('size') && Certificate.get_size(certificate) + 'ct',
      !hideOptions.includes('color') && Certificate.get_color(certificate),
      !hideOptions.includes('clarity') && Certificate.get_clarity(certificate),
      !hideOptions.includes('fluorescence') && certificate?.floInt
        ? Certificate.get_fluorescence(certificate)
        : null
    ),
};

export const naturalDiamondDiscount =
  featureFlagLS.get()?.naturaldiscount_disable;

export const split_by_space_or_comma = (value) => {
  if (!value) return null;
  return Array.isArray(value) ? value : value.split(/[ ,]+/);
};
export let Diamond = {
  location_mapper: (diamond, flag_only = false) => {
    if (flag_only) {
      return (
        <FlagIcon
          style={{ display: 'inline-block' }}
          code={diamond.location.country || 'IN'}
          size={14}
        />
      );
    }
    return map[diamond.location.country] || 'India';
  },
  delivery_time: (diamond, options = {}) => {
    let { add_latest_day = false, short = false, translate } = options;
    let day = {
      key: short ? 'days' : 'business_days',
      text: short ? 'days' : 'business days',
    };
    let map_text =
      typeof translate === 'function' ? translate(day.key) : day.text;
    let min_day =
        diamond && diamond.delivery_time
          ? diamond.delivery_time.min_business_days
          : 10,
      max_day =
        diamond && diamond.delivery_time
          ? diamond.delivery_time.max_business_days
          : 14;
    if (add_latest_day) {
      let latest_day = moment().businessAdd(max_day).format('MMMM Do');
      return `${min_day}-${max_day} ${map_text} (${latest_day})`;
    }
    return `${min_day}-${max_day} ${map_text}`;
  },
  get_bgm: (diamond) => {
    // TODO What The Fuck
    diamond = diamond.diamond || diamond;

    // use values from last qc if there
    const brown = diamond.last_qc?.brown || diamond.brown;
    const green = diamond.last_qc?.green || diamond.green;
    const milky = diamond.last_qc?.milky || diamond.milky;

    // only show the ask supplier when we have no information
    if (
      brown == null &&
      green == null &&
      diamond.other == null &&
      milky == null
    ) {
      return null;
    }
    const BROWN = {
      VVLB: 'Very Very Light Br',
      VLB: 'Very Light Brown',
      LB: 'Light Brown',
      B: 'Brown',
      SB: 'Strong Brown',
      VSB: 'Very Strong Brown',
    };

    const GREEN = {
      VVLG: 'Very Very Light Green',
      VLG: 'Very Light Green',
      LG: 'Light Green',
      G: 'Green',
      SG: 'Strong Green',
      VSG: 'Very Strong Green',
    };

    const OTHER = {
      PINK: 'Pink Tinge',
      MIX: 'Mixed Tinge',
    };

    const MILKY = {
      VVLM: 'Very Very Light Milky',
      VLM: 'Very Light Milky',
      LM: 'Light Milky',
      M: 'Milky',
      HM: 'Heavy Milky',
      VHM: 'Very Heavy Milky',
    };

    // one of them is not no & null so display that long form
    if (diamond.other != null) {
      return OTHER[diamond.other];
    }
    if (brown !== 'No' && brown != null) {
      let merged_obj = { ...BROWN, ...OTHER };
      return merged_obj[brown];
    }

    if (milky !== 'No' && milky != null) {
      return MILKY[milky];
    }

    if (green !== 'No' && green != null) {
      let merged_obj = { ...GREEN, ...OTHER };
      return merged_obj[green];
    }

    // All of them are 'No', so we show 'None'
    return 'No BGM';
  },
  get_shade: (diamond) => {
    // TODO What The Fuck
    diamond = diamond.diamond || diamond;
    // Use Last Qc data if available
    const brown = diamond.last_qc?.brown || diamond.brown;
    const green = diamond.last_qc?.green || diamond.green;
    const gray = diamond.last_qc?.gray || diamond.gray;
    const blue = diamond.last_qc?.blue || diamond.blue;

    let allShade = [
      { brown: brown },
      { green: green },
      { blue: blue },
      { gray: gray },
      { other: diamond.other },
    ];
    let shadeArray = [];
    if (brown === 'No' && green === 'No' && blue === 'No' && gray === 'No') {
      shadeArray = [
        {
          code: 'No Shade',
          full_text: 'No Shade',
          tooltip_text: 'No Shade',
          color: 'green',
          default: 'NS',
          abbr: 'No Sh.',
          type: 'NONE',
        },
      ];
    }

    // only show the ask supplier when we have no information
    if (
      brown == null &&
      green == null &&
      blue == null &&
      gray == null &&
      diamond.other == null
    ) {
      return [];
    }
    const BROWN = {
      VVLB: {
        code: 'VVL Brown',
        full_text: 'Very Very Light Brown',
        tooltip_text: 'Very Very Light Brown',
        color: 'warm',
        default: brown,
        abbr: 'VVL Br.',
        type: 'BROWN',
      },
      VLB: {
        code: 'VL Brown',
        full_text: 'Very Light Brown',
        tooltip_text: 'Very Light Brown',
        color: 'warm',
        default: brown,
        abbr: 'VL Br.',
        type: 'BROWN',
      },
      LB: {
        code: 'L Brown',
        full_text: 'Light Brown',
        tooltip_text: 'Light Brown',
        color: 'warm',
        default: brown,
        abbr: 'L Br.',
        type: 'BROWN',
      },
      B: {
        code: 'Brown',
        full_text: 'Brown',
        tooltip_text: 'Brown',
        color: 'red',
        default: brown,
        abbr: 'Brown',
        type: 'BROWN',
      },
      SB: {
        code: 'S  Brown',
        full_text: 'Strong Brown',
        tooltip_text: 'Strong Brown',
        color: 'red',
        default: brown,
        abbr: 'S Br.',
        type: 'BROWN',
      },
      VSB: {
        code: 'VS Brown',
        full_text: 'Very Strong Brown',
        tooltip_text: 'Very Strong Brown',
        color: 'red',
        default: brown,
        abbr: 'VS Br.',
        type: 'BROWN',
      },
    };
    const GREEN = {
      VVLG: {
        code: 'VVL Green',
        full_text: 'Very Very Light Green',
        tooltip_text: 'Very Very Light Green',
        color: 'warm',
        default: green,
        abbr: 'VVL Gr.',
        type: 'GREEN',
      },
      VLG: {
        code: 'VL Green',
        full_text: 'Very Light Green',
        tooltip_text: 'Very Light Green',
        color: 'warm',
        default: green,
        abbr: 'VL Gr.',
        type: 'GREEN',
      },
      LG: {
        code: 'L Green',
        full_text: 'Light Green',
        tooltip_text: 'Light Green',
        color: 'warm',
        default: green,
        abbr: 'L Gr.',
        type: 'GREEN',
      },
      G: {
        code: 'Green',
        full_text: 'Green',
        tooltip_text: 'Green',
        color: 'red',
        default: green,
        abbr: 'Green',
        type: 'GREEN',
      },
      SG: {
        code: 'S  Green',
        full_text: 'Strong Green',
        tooltip_text: 'Strong Green',
        color: 'red',
        default: green,
        abbr: 'S Gr.',
        type: 'GREEN',
      },
      VSG: {
        code: 'VS Green',
        full_text: 'Very Strong Green',
        tooltip_text: 'Very Strong Green',
        color: 'red',
        default: green,
        abbr: 'VS Gr.',
        type: 'GREEN',
      },
    };

    const GRAY = {
      VVLG: {
        code: 'VVL Grey',
        full_text: 'Very Very Light Grey',
        tooltip_text: 'Very Very Light Grey',
        color: 'warm',
        default: gray,
        abbr: 'VVL Gray',
        type: 'GRAY',
      },
      VLG: {
        code: 'VL Grey',
        full_text: 'Very Light Grey',
        tooltip_text: 'Very Light Grey',
        color: 'warm',
        default: gray,
        abbr: 'VL Gray',
        type: 'GRAY',
      },
      LG: {
        code: 'L Grey',
        full_text: 'Light Grey',
        tooltip_text: 'Light Grey',
        color: 'warm',
        default: gray,
        abbr: 'L Gray',
        type: 'GRAY',
      },
      G: {
        code: 'Grey',
        full_text: 'Grey',
        tooltip_text: 'Grey',
        color: 'red',
        default: gray,
        abbr: 'Gray',
        type: 'GRAY',
      },
      SG: {
        code: 'S Grey',
        full_text: 'Strong Grey',
        tooltip_text: 'Strong Grey',
        color: 'red',
        default: gray,
        abbr: 'S Gray',
        type: 'GRAY',
      },
      VSG: {
        code: 'VS Grey',
        full_text: 'Very Strong Grey',
        tooltip_text: 'Very Strong Grey',
        color: 'red',
        default: gray,
        abbr: 'VS Gray',
        type: 'GRAY',
      },
    };

    const BLUE = {
      VVLB: {
        code: 'VVL Blue',
        full_text: 'Very Very Light Blue',
        tooltip_text: 'Very Very Light Blue',
        color: 'warm',
        default: blue,
        abbr: 'VVL Bl.',
        type: 'BLUE',
      },
      VLB: {
        code: 'VL Blue',
        full_text: 'Very Light Blue',
        tooltip_text: 'Very Light Blue',
        color: 'warm',
        default: blue,
        abbr: 'VL Bl.',
        type: 'BLUE',
      },
      LB: {
        code: 'L Blue',
        full_text: 'Light Blue',
        tooltip_text: 'Light Blue',
        color: 'warm',
        default: blue,
        abbr: 'L Bl.',
        type: 'BLUE',
      },
      B: {
        code: 'Blue',
        full_text: 'Blue',
        tooltip_text: 'Blue',
        color: 'red',
        default: blue,
        abbr: 'Blue',
        type: 'BLUE',
      },
      SB: {
        code: 'S Blue',
        full_text: 'Strong Blue',
        tooltip_text: 'Strong Blue',
        color: 'red',
        default: blue,
        abbr: 'S Bl.',
        type: 'BLUE',
      },
      VSB: {
        code: 'VS Blue',
        full_text: 'Very Strong Blue',
        tooltip_text: 'Very Strong Blue',
        color: 'red',
        default: blue,
        abbr: 'VS Bl.',
        type: 'BLUE',
      },
    };

    const OTHER = {
      MIX: {
        code: 'M. Tinge',
        full_text: 'Mixed Tinge',
        tooltip_text: 'Mixed Tinge',
        color: 'red',
        default: diamond.other ? diamond.other : diamond.brown,
        abbr: 'Mx Tin',
        type: 'MIX',
      },
      PINK: {
        code: 'P. Tinge',
        full_text: 'Pink Tinge',
        tooltip_text: 'Pink Tinge',
        color: 'red',
        default: diamond.other ? diamond.other : diamond.brown,
        abbr: 'P Tin',
        type: 'MIX',
      },
      LMIX: {
        code: 'Light M. Tinge',
        full_text: 'Light Mixed Tinge',
        tooltip_text: 'Light Mixed Tinge',
        color: 'red',
        default: diamond.other ? diamond.other : diamond.brown,
        abbr: 'LM. Tin',
        type: 'LMIX',
      },
    };

    // merge all shade into one array
    allShade.forEach((ele) => {
      if (ele.brown !== 'No' && ele.brown != null) {
        let merged_obj = { ...BROWN, ...OTHER };
        shadeArray = [...shadeArray, merged_obj[ele.brown]];
      }
      if (ele.green !== 'No' && ele.green != null) {
        let merged_obj = { ...GREEN, ...OTHER };
        shadeArray = [...shadeArray, merged_obj[ele.green]];
      }
      if (ele.blue !== 'No' && ele.blue != null) {
        let merged_obj = { ...BLUE, ...OTHER };
        shadeArray = [...shadeArray, merged_obj[ele.blue]];
      }
      if (ele.gray !== 'No' && ele.gray != null) {
        let merged_obj = { ...GRAY, ...OTHER };
        shadeArray = [...shadeArray, merged_obj[ele.gray]];
      }
    });
    // remove duplicate elements
    shadeArray = uniqBy(shadeArray, 'type');

    // If item has more than 2 shades then we need to display mix tinge
    if (shadeArray.length > 2) {
      let allShadeValue = shadeArray.map((ele) => ele?.full_text).join(', ');
      shadeArray = [
        {
          code: 'M. Tinge',
          full_text: 'Mixed Tinge',
          tooltip_text: allShadeValue,
          color: 'red',
          default: 'MIX',
          abbr: 'Mx Tin',
          type: 'MIX',
        },
      ];
    }
    if (shadeArray.length === 0) {
      let isNoShade = allShade.find(
        (ele) =>
          ele.brown === 'No' ||
          ele.blue === 'No' ||
          ele.green === 'No' ||
          ele.gray === 'No' ||
          ele.other === 'No'
      );
      if (isNoShade) {
        shadeArray = [
          {
            code: 'No Shade',
            full_text: 'No Shade',
            tooltip_text: 'No Shade',
            color: 'green',
            default: 'NS',
            abbr: 'No Sh.',
            type: 'NONE',
          },
        ];
      }
    }
    return shadeArray;
  },

  get_luster: (diamond) => {
    diamond = diamond.diamond || diamond;
    // use values from last qc
    const milky = diamond.last_qc?.milky || diamond.milky;

    // only show the ask supplier when we have no information
    if (milky == null) {
      return null;
    }

    const MILKY = {
      VVLM: {
        code: 'VVL Milky',
        full_text: 'Very Very Light Milky',
        color: 'warm',
        default: milky,
        abbr: 'VVL Mil.',
      },
      VLM: {
        code: 'VL Milky',
        full_text: 'Very Light Milky',
        color: 'warm',
        default: milky,
        abbr: 'VL Mil.',
      },
      LM: {
        code: 'L Milky',
        full_text: 'Light Milky',
        color: 'warm',
        default: milky,
        abbr: 'L Mil.',
      },
      M: {
        code: 'Milky',
        full_text: 'Milky',
        color: 'red',
        default: milky,
        abbr: 'Milky',
      },
      HM: {
        code: 'H  Milky',
        full_text: 'Heavy Milky',
        color: 'red',
        default: milky,
        abbr: 'H Mil.',
      },
      VHM: {
        code: 'VH Milky',
        full_text: 'Very Heavy Milky',
        color: 'red',
        default: milky,
        abbr: 'VH Mil.',
      },
      MIX: {
        code: 'M. Tinge',
        full_text: 'Mixed Tinge',
        color: 'red',
        default: milky,
        abbr: 'Mx Tin',
      },
    };

    if (milky !== 'No' && milky != null) {
      return MILKY[milky];
    }
    // All of them are 'No', so we show 'None'
    return {
      code: 'Ex. Luster',
      full_text: 'Excellent Luster',
      color: 'green',
      default: 'Ex. Lust.',
      abbr: 'Ex. Lust.',
    };
  },

  // Not used anywhere
  // get_bgm_code: (diamond) => {
  //   const BROWN = {
  //     VVLB: 1,
  //     VLB: 2,
  //     LB: 3,
  //     B: 4,
  //     SB: 5,
  //     VSB: 6,
  //     MIX: 7,
  //   };
  //
  //   const GREEN = {
  //     VVLG: 1,
  //     VLG: 2,
  //     LG: 3,
  //     G: 4,
  //     SG: 5,
  //     VSG: 6,
  //     MIX: 7,
  //   };
  //
  //   const MILKY = {
  //     VVLM: 1,
  //     VLM: 2,
  //     LM: 3,
  //     M: 4,
  //     HM: 5,
  //     VHM: 6,
  //     MIX: 7,
  //   };
  //   let has_brown = diamond.brown !== 'No' && diamond.brown != null;
  //   let has_green = diamond.green !== 'No' && diamond.green != null;
  //   let has_milky = diamond.milky !== 'No' && diamond.milky != null;
  //
  //   if (has_brown && has_green && has_milky) {
  //     return {
  //       code: `${diamond.brown}, ${diamond.milky}`,
  //       ranking:
  //         BROWN[diamond.brown] > MILKY[diamond.milky]
  //           ? BROWN[diamond.brown]
  //           : MILKY[diamond.milky],
  //     };
  //   }
  //   if (has_brown && has_milky) {
  //     return {
  //       code: `${diamond.brown}, ${diamond.milky}`,
  //       ranking:
  //         BROWN[diamond.brown] > MILKY[diamond.milky]
  //           ? BROWN[diamond.brown]
  //           : MILKY[diamond.milky],
  //     };
  //   }
  //   if (has_green && has_milky) {
  //     return {
  //       code: `${diamond.green}, ${diamond.milky}`,
  //       ranking:
  //         GREEN[diamond.green] > MILKY[diamond.milky]
  //           ? GREEN[diamond.green]
  //           : MILKY[diamond.milky],
  //     };
  //   }
  //   if (has_brown && has_green) {
  //     return { code: `${diamond.brown}`, ranking: BROWN[diamond.brown] };
  //   }
  //
  //   if (has_brown) {
  //     return { code: diamond.brown, ranking: BROWN[diamond.brown] };
  //   }
  //
  //   if (has_milky) {
  //     return { code: diamond.milky, ranking: MILKY[diamond.milky] };
  //   }
  //
  //   if (has_green) {
  //     return { code: diamond.green, ranking: GREEN[diamond.green] };
  //   }
  //   return {
  //     code: null,
  //     ranking: 0,
  //   };
  // },

  get_eyeclean: (diamond) => {
    if (diamond.last_qc?.eyeClean || diamond.eyeClean)
      return diamond.last_qc?.eyeClean ?? diamond.eyeClean;
    return null;
  },
  return_discount: (diamond, discount) => {
    return isStoneFancy(diamond.certificate?.color, diamond.certificate?.carats)
      ? Diamond.get_diamond_discount({ diamond })
      : ` ${
          diamond.rapaportDiscount === 0
            ? ''
            : diamond.rapaportDiscount > 0
            ? '+'
            : ''
        }${(discount / 100).toFixed(2)}%`;
  },
  get_discount: ({
    diamond,
    endline = false,
    number_only = false,
    is_hover = false,
    manual_discount,
  }) => {
    const is_fancy = is_fancy_checker(diamond);
    const is_natural = !diamond?.certificate?.labgrown;

    if (!is_hover && GetDiscountStatus(is_natural)) {
      return null;
    }
    if (is_fancy) {
      return manual_discount
        ? manual_discount
        : Diamond.get_carat_price(diamond, endline, number_only);
    }

    return (
      diamond.rapaportDiscount != null &&
      `${
        diamond.rapaportDiscount === 0
          ? ''
          : diamond.rapaportDiscount > 0
          ? '+'
          : ''
      }${diamond.rapaportDiscount}%`
    );
  },
  get_coupon_discount: (
    item,
    end_line = false,
    number_only = false,
    is_diamond = false
  ) => {
    let diamond = item.diamond;
    let hide_arr = [
      'FANCY',
      'O',
      'NO',
      'PR',
      'SZ',
      'OP',
      'P',
      'Q',
      'QR',
      'R',
      'S',
      'ST',
      'T',
      'U',
      'UV',
      'V',
      'W',
      'WX',
      'X',
      'XY',
      'Y',
      'YZ',
      'Z',
      'N',
    ];

    let decimal = (item.discount / 100).toFixed(2);
    if (hide_arr.indexOf(diamond.certificate?.color) > -1) {
      return Diamond.get_carat_price(
        diamond,
        end_line,
        number_only,
        is_diamond
      );
    }
    return `${
      item.discount === 0 ? '' : item.discount > 0 ? '+' : ''
    }${decimal}%`;
  },

  /*
   * used for supersaver coupon offer discount price change
   * The diamond which is converted to coupon, on that the diamond.offer.diamond_price gets updated with updated offer price
   * In this case the diamond.offer.original_offer gets original diamond value from BE.
   * Diamond.get_coupon_discount() returns diamond offer discount  ---> needs to customer  price
   * Diamond.get_offer_coupon_discount() returns diamond original discount  ---> needs to show list price
   */
  get_offer_coupon_discount: (
    item,
    end_line = false,
    number_only = false,
    is_diamond = false
  ) => {
    let diamond = item.diamond;
    let hide_arr = [
      'FANCY',
      'O',
      'NO',
      'PR',
      'SZ',
      'OP',
      'P',
      'Q',
      'QR',
      'R',
      'S',
      'ST',
      'T',
      'U',
      'UV',
      'V',
      'W',
      'WX',
      'X',
      'XY',
      'Y',
      'YZ',
      'Z',
      'N',
    ];

    let decimal = (item.discount / 100).toFixed(2);
    if (hide_arr.indexOf(diamond.certificate?.color) > -1) {
      return Diamond.get_coupon_carat_price(
        diamond,
        end_line,
        number_only,
        is_diamond
      );
    }
    return `${
      item.discount === 0 ? '' : item.discount > 0 ? '+' : ''
    }${decimal}%`;
  },

  get_diamond_discount: ({
    diamond,
    endline = false,
    number_only = false,
    is_diamond_price = true,
    is_hundred = false,
    is_hover = false,
    manual_discount,
  }) => {
    if (!diamond) {
      throw new Error('Diamond is required');
    }
    let is_labgrown = diamond?.certificate?.labgrown || false;
    let is_natural = !is_labgrown;
    let is_fancy = is_fancy_checker(diamond);

    if (!is_hover && GetDiscountStatus(is_natural)) {
      return null;
    }

    if (is_fancy) {
      return manual_discount
        ? manual_discount
        : Diamond.get_carat_price(
            diamond,
            endline,
            number_only,
            is_diamond_price
          );
    }

    let diamond_disc = is_hundred
      ? diamond?.offer?.product_discount
      : diamond?.offer?.product_discount / 100;

    return `${
      diamond_disc === 0 ? '' : diamond_disc > 0 ? '+' : ''
    }${diamond_disc}%`;
  },

  get_avg_discount: (discount) => {
    const hideNatDiscount = naturalDiamondDiscount;
    const isNatural = true; // Settings this true because, all labgrown discounts are already hidden, which means if we are getting here we are only concerned about Natural discounts
    if (hideNatDiscount && isNatural) return null;
    if (discount === 0) return `0.00%`;
    return `${discount === 0 ? '' : discount > 0 ? '+' : ''}${Number(
      discount
    ).toPrecision(4)}%`;
  },

  sell_discount: (base_sell_discount, base_sell_price, markup_price) => {
    let get_original_price = ({ discount, price }) => {
      let inv_disc = discount * -1;
      return price / (1 - inv_disc * 0.01);
    };

    let original_price = get_original_price({
      discount: base_sell_discount,
      price: base_sell_price,
    });

    let markup_discount = (1 - markup_price / original_price) * -100;

    return markup_discount.toPrecision(4);
  },

  get_carat_price: (
    diamond,
    endline = false,
    number_only = false,
    is_diamond_price = false
  ) => {
    let cp;
    if (is_diamond_price) {
      cp =
        (diamond.offer.product_price / 100) * (1 / diamond.certificate?.carats);
    } else {
      cp = diamond.price * (1 / diamond.certificate?.carats);
    }
    if (number_only) {
      return cp;
    }
    cp = cp
      .toLocaleString('en-GB', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      .replace(/US/, '');
    if (endline) {
      return `${cp}\n/ct`;
    } else {
      return `${cp}/ct`;
    }
  },
  /*
   * used for supersaver coupon offer discount price change
   * The diamond which is converted to coupon, on that the diamond.offer.diamond_price gets updated with updated offer price
   * In this case the diamond.offer.original_offer gets original diamond value from BE.
   * Diamond.get_carat_price() returns diamond offer discount  ---> needs to customer  price
   * Diamond.get_coupon_carat_price() returns diamond original discount  ---> needs to show list price
   */
  get_coupon_carat_price: (
    diamond,
    endline = false,
    number_only = false,
    is_diamond_price = false
  ) => {
    let cp;
    if (is_diamond_price) {
      cp =
        (diamond.offer.original_offer.diamond_price / 100) *
        (1 / diamond.certificate?.carats);
    } else {
      cp = diamond.price * (1 / diamond.certificate?.carats);
    }
    if (number_only) {
      return cp;
    }
    cp = cp
      .toLocaleString('en-GB', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      .replace(/US/, '');
    if (endline) {
      return `${cp}\n/c`;
    } else {
      return `${cp}/c`;
    }
  },

  get_stock_id: (
    diamond,
    { returnPlainText = false, stockWithIconProps = {}, label = '' }
  ) => {
    if (!diamond?.supplierStockId) return null;
    return returnPlainText ? (
      diamond.supplierStockId
    ) : (
      <StockWithIcon
        iconHeight={16}
        iconWidth={16}
        label={label}
        supplierStockId={diamond.supplierStockId}
        {...stockWithIconProps}
      />
    );
  },
  get_diamond_supplier_stock_id: (
    diamond,
    { returnPlainText = false, stockWithIconProps = {}, label = '' }
  ) => {
    const diamondSupplierStockId = diamond?.diamondSupplierStockId;
    if (!diamondSupplierStockId) return null;

    return returnPlainText ? (
      diamondSupplierStockId
    ) : (
      <StockWithIcon
        iconHeight={16}
        iconWidth={16}
        label={label}
        supplierStockId={diamondSupplierStockId}
        {...stockWithIconProps}
      />
    );
  },
};

export let is_fancy_checker = (diamond) => {
  let hide_arr = [
    'FANCY',
    'O',
    'NO',
    'PR',
    'SZ',
    'OP',
    'P',
    'Q',
    'QR',
    'R',
    'S',
    'ST',
    'T',
    'U',
    'UV',
    'V',
    'W',
    'WX',
    'X',
    'XY',
    'Y',
    'YZ',
    'Z',
  ];
  return diamond && diamond.certificate
    ? hide_arr.includes(diamond.certificate?.color) ||
        (diamond.certificate?.color === 'N' &&
          Number(diamond.certificate?.carats) > 0.29)
    : false;
};

export let ToLocaleStringOnly = (cp, endline = false) => {
  cp = cp
    .toLocaleString('en-GB', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })
    .replace(/US/, '');

  if (endline) {
    return `${cp}\n/c`;
  } else {
    return `${cp}/c`;
  }
};

let first_letter_cap = (text) =>
  text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();

export let Certificate = {
  get_fluorescence: (certificate) => {
    let floInt = certificate?.floInt;
    if (floInt == null) return '-';
    let floInt_titles = {
      NON: 'None',
      FNT: 'Faint',
      MED: 'Medium',
      STG: 'Strong',
      STN: 'Strong',
      VST: 'Very Strong',
      VSL: 'Very Slight',
    };

    const floInt_title_str = floInt_titles[floInt] || '-';
    const floCol = certificate?.floCol
      ? first_letter_cap(certificate?.floCol)
      : '';

    if (floInt_title_str === '-') {
      // eslint-disable-next-line no-console
      console.warn(`Unknown floInt '${floInt}'`);
      return '-';
    } else {
      return `${floInt_title_str} ${floCol}`;
    }
  },
  get_clarity: (certificate) => {
    return certificate?.clarity !== null ? certificate?.clarity : '';
  },
  get_polish: (certificate) => {
    return certificate?.polish != null ? certificate?.polish : '';
  },
  get_symmetry: (certificate) => {
    return certificate?.symmetry != null ? certificate?.symmetry : '';
  },
  get_color: (certificate) => {
    if (certificate?.color === 'FANCY') {
      let str = certificate?.f_intensity
        ? certificate?.f_intensity.replace('_', ' ')
        : '';
      if (certificate?.f_overtone) {
        str += ` ${certificate?.f_overtone}`;
      }
      if (certificate?.f_color) {
        str += ` ${certificate?.f_color}`;
      }
      return str;
    }

    if (certificate?.color.length === 2) {
      let str = `${certificate?.color[0]}-${certificate?.color[1]}`;
      if (certificate?.colorShade) {
        str += ` ${certificate?.colorShade}`;
      }
      return str;
    }

    let str = certificate?.color;
    if (certificate?.colorShade) {
      str += ` ${certificate?.colorShade}`;
    }
    return str || '';
  },
  get_shortened_color: (certificate) => {
    let full_text = Certificate.get_color(certificate);
    return shortenText(full_text);
  },
  get_measurements: (certificate = {}, plain_text = false) => {
    let { length, width, depth, shape } = certificate;
    let demarcation = (text) =>
      plain_text ? text : <span style={{ color: '#777777' }}> {text} </span>;

    if (shape !== 'ROUND') {
      return plain_text ? (
        `${parseFloat(length).toFixed(2)} ${demarcation('x')} ${parseFloat(
          width
        ).toFixed(2)} ${demarcation('x')} ${parseFloat(depth).toFixed(2)}`
      ) : (
        <span>
          {parseFloat(length).toFixed(2)}
          {demarcation('x')}
          {parseFloat(width).toFixed(2)}
          {demarcation('x')}
          {parseFloat(depth).toFixed(2)}
        </span>
      );
    }

    if (shape === 'ROUND') {
      if (parseFloat(length).toFixed(2) < parseFloat(width).toFixed(2)) {
        return plain_text ? (
          `${parseFloat(length).toFixed(2)} ${demarcation('-')} ${parseFloat(
            width
          ).toFixed(2)} ${demarcation('x')} ${parseFloat(depth).toFixed(2)}`
        ) : (
          <span>
            {parseFloat(length).toFixed(2)}
            {demarcation('-')}
            {parseFloat(width).toFixed(2)} {demarcation('x')}
            {parseFloat(depth).toFixed(2)}
          </span>
        );
      } else {
        return plain_text ? (
          `${parseFloat(width).toFixed(2)} ${demarcation('-')} ${parseFloat(
            length
          ).toFixed(2)} ${demarcation('x')} ${parseFloat(depth).toFixed(2)}`
        ) : (
          <span>
            {parseFloat(width).toFixed(2)}
            {demarcation('-')}
            {parseFloat(length).toFixed(2)} {demarcation('x')}
            {parseFloat(depth).toFixed(2)}
          </span>
        );
      }
    }
    return parseFloat(depth).toFixed(2);
  },
  get_ratio: (certificate) =>
    (certificate?.length / certificate?.width).toFixed(2),
  get_shape: (certificate) => {
    return certificate?.shape ? capitalize(certificate?.shape) : '';
  },
  get_cut: (certificate) => {
    if (certificate?.shape !== 'ROUND') {
      return '';
    }
    return certificate?.cut != null
      ? ` ${certificate?.cut === 'EIGHTX' ? '8X' : certificate?.cut}`
      : '';
  },
  get_size: (certificate) => certificate?.carats?.toFixed(2),
  get_price: (gemstone) => {
    return gemstone.price;
  },
  get_carat_price: (gemstone) => {
    return gemstone.price / gemstone.Gemstonecertificate?.carats;
  },
};

export let compare_bgm_ec = (reply, diamond, hasMixWN) => {
  let mixOptionWithoutNo = hasMixWN
    ? mixOptions.filter((r) => r.value !== 0)
    : [];
  // convert the reply to numbers
  let converted_reply = (diamond) => {
    return {
      brown: isNaN(diamond.brown)
        ? (hasMixWN
            ? [...brownOptions, ...mixOptionWithoutNo]
            : brownOptions
          ).find((i) => i.code === diamond.brown).value
        : diamond.brown,
      green: isNaN(diamond.green)
        ? (hasMixWN
            ? [...greenOptions, ...mixOptionWithoutNo]
            : greenOptions
          ).find((i) => i.code === diamond.green).value
        : diamond.green,
      milky: isNaN(diamond.milky)
        ? milkyOptions.find((i) => i.code === diamond.milky).value
        : diamond.milky,
      eyeclean: !isEmpty(diamond.eyeClean)
        ? Array.isArray(diamond.eyeClean) && isNaN(diamond.eyeClean[0])
          ? ecOptions.find((i) => i.code === diamond.eyeClean[0]).value
          : isNaN(diamond.eyeClean)
          ? ecOptions.find((i) => i.code === diamond.eyeClean).value
          : diamond.eyeClean
        : diamond.eyeClean,
    };
  };
  //Comparison here
  let response = converted_reply(reply);
  let brown =
    response.brown > diamond.brown ||
    (response.brown !== 0 && diamond.brown === null);

  // compare green
  let green =
    response.green > diamond.green ||
    (response.green !== 0 && diamond.green === null);
  // compare milky
  let milky =
    response.milky > diamond.milky ||
    (response.milky !== 0 && diamond.milky === null);

  // compare eyeclean (note capitalization because of db)
  let eyeclean =
    response.eyeclean < diamond.eyeClean ||
    (response.eyeclean !== 100 && diamond.eyeClean === null);
  return !(brown || green || milky || eyeclean);
};

export const brownOptions = [
  { value: 0, label: 'None', code: 'No', title: 'None', tooltip_text: 'None' },
  {
    value: 1,
    label: 'VVL Brown',
    code: 'VVLB',
    title: 'VVL Brown',
    tooltip_text: 'Very Very Light Brown',
  },
  {
    value: 2,
    label: 'VL Brown',
    code: 'VLB',
    title: 'VL Brown',
    tooltip_text: 'Very Light Brown',
  },
  {
    value: 3,
    label: 'L Brown',
    code: 'LB',
    title: 'L Brown',
    tooltip_text: 'Light Brown',
  },
  {
    value: 4,
    label: 'Brown',
    code: 'B',
    title: 'Brown',
    tooltip_text: 'Brown',
  },
  {
    value: 5,
    label: 'S Brown',
    code: 'SB',
    title: 'S Brown',
    tooltip_text: 'Strong Brown',
  },
  {
    value: 6,
    label: 'VS Brown',
    code: 'VSB',
    title: 'VS Brown',
    tooltip_text: 'Very Strong Brown',
  },
];

export const blueOptions = [
  { value: 0, label: 'None', code: 'No', title: 'None', tooltip_text: 'None' },
  {
    value: 1,
    label: 'VVL Blue',
    code: 'VVLB',
    title: 'VVL Blue',
    tooltip_text: 'Very Very Light Blue',
  },
  {
    value: 2,
    label: 'VL Blue',
    code: 'VLB',
    title: 'VL Blue',
    tooltip_text: 'Very Light Blue',
  },
  {
    value: 3,
    label: 'L Blue',
    code: 'LB',
    title: 'L Blue',
    tooltip_text: 'Light Blue',
  },
  { value: 4, label: 'Blue', code: 'B', title: 'Blue', tooltip_text: 'Blue' },
  {
    value: 5,
    label: 'S Blue',
    code: 'SB',
    title: 'S Blue',
    tooltip_text: 'Strong Blue',
  },
  {
    value: 6,
    label: 'VS Blue',
    code: 'VSB',
    title: 'VS Blue',
    tooltip_text: 'Very Strong',
  },
];

export const grayOptions = [
  { value: 0, label: 'None', code: 'No', title: 'None', tooltip_text: 'None' },
  {
    value: 1,
    label: 'VVL Grey',
    code: 'VVLG',
    title: 'VVL Grey',
    tooltip_text: 'Very Very Light Grey',
  },
  {
    value: 2,
    label: 'VL Grey',
    code: 'VLG',
    title: 'VL Grey',
    tooltip_text: 'Very Light Grey',
  },
  {
    value: 3,
    label: 'L Grey',
    code: 'LG',
    title: 'L Grey',
    tooltip_text: 'Light',
  },
  { value: 4, label: 'Grey', code: 'G', title: 'Grey', tooltip_text: 'Grey' },
  {
    value: 5,
    label: 'S Grey',
    code: 'SG',
    title: 'S Grey',
    tooltip_text: 'Strong Grey',
  },
  {
    value: 6,
    label: 'VS Grey',
    code: 'VSG',
    title: 'VS Grey',
    tooltip_text: 'Very Strong Grey',
  },
];

export const greenOptions = [
  { value: 0, label: 'None', code: 'No', title: 'None', tooltip_text: 'None' },
  {
    value: 1,
    label: 'VVL Green',
    code: 'VVLG',
    title: 'VVL Green',
    tooltip_text: 'Very Very Light Green',
  },
  {
    value: 2,
    label: 'VL Green',
    code: 'VLG',
    title: ' VL Green',
    tooltip_text: 'Very Light Green',
  },
  {
    value: 3,
    label: 'L Green',
    code: 'LG',
    title: 'L Green',
    tooltip_text: 'Light Green',
  },
  {
    value: 4,
    label: 'Green',
    code: 'G',
    title: 'Green',
    tooltip_text: 'Green',
  },
  {
    value: 5,
    label: 'S Green',
    code: 'SG',
    title: 'S Green',
    tooltip_text: 'Strong Green',
  },
  {
    value: 6,
    label: 'VS Green',
    code: 'VSG',
    title: 'VS Green',
    tooltip_text: 'Very Strong Green',
  },
];
// label: 'Extremely Thind', value: 'ETN', name: 'Extremely Thin'
export const milkyOptions = [
  { value: 0, label: 'excellent', code: 'No', title: 'Excellent' },
  {
    value: 1,
    label: 'vvl milky',
    code: 'VVLM',
    title: 'VVL Milky',
    name: 'VVL Milky',
  },
  { value: 2, label: 'vl milky', code: 'VLM', title: 'VL Milky' },
  { value: 3, label: 'l milky', code: 'LM', title: 'L Milky' },
  { value: 4, label: 'milky', code: 'M', title: 'Milky' },
  { value: 5, label: 'h milky', code: 'HM', title: 'H Milky' },
  { value: 6, label: 'vh milky', code: 'VHM', title: 'VH Milky' },
];
export const mixOptions = [
  { value: 0, label: 'none', code: 'NO', title: 'None' },
  {
    value: 8,
    label: 'Light Mixed Tinge',
    code: 'LMIX',
    title: 'Light Mixed Tinge',
  },
  { value: 9, label: 'Pink Tinge', code: 'PINK', title: 'Pink Tinge' },
  { value: 10, label: 'Mix Tinge', code: 'MIX', title: 'Mix Tinge' },
];
//
// export const NOBGM_option = [
//   {
//     value: 'NONE',
//     title: 'No BGM',
//     result: { brown: ['0'], green: ['0'], mix: ['0'], luster: ['NONE'] },
//   },
// ];
export let styled_predicate = (fn) => {
  return (p) => (fn(p) ? '&' : '&:not(&)');
};
export const ecOptions = [
  { value: 100, label: 'Yes', code: '100%' },
  { value: 95, label: '95%', code: '95%' },
  { value: 90, label: '90%', code: '90%' },
  { value: 85, label: '85%', code: '85%' },
  { value: 80, label: '80%', code: '80%' },
  { value: 75, label: '75%', code: '75%' },
  { value: 0, label: 'No', code: 'No' },
];

export const currencies = [
  {
    value: 'USD',
    label: 'USD',
    long_label: 'US Dollar (USD)',
    flag: 'US',
    icon: '$',
  },
  {
    value: 'GBP',
    label: 'GBP',
    long_label: 'British Pound (£)',
    flag: 'GB',
    icon: '£',
  },
  {
    value: 'EUR',
    label: 'EUR',
    long_label: 'Euro (€)',
    flag: 'EU',
    icon: '€',
  },
  {
    value: 'AUD',
    label: 'AUD',
    long_label: 'Australian Dollar ($ AUD)',
    flag: 'AU',
    // icon: 'AU$',
    icon: '$',
  },
  {
    value: 'CAD',
    label: 'CAD',
    long_label: 'Canadian Dollar  ($ CAD)',
    flag: 'CA',
    // icon: 'CA$',
    icon: '$',
  },
  {
    value: 'AED',
    label: 'AED',
    long_label: 'UAE Dirham (د.إ)',
    flag: 'AE',
    icon: 'د.إ',
  },
  {
    value: 'HKD',
    label: 'HKD',
    long_label: 'Hong Kong Dollar ($ HKD)',
    flag: 'HK',
    // icon: 'HK$',
    icon: '$',
  },
  {
    value: 'ZAR',
    label: 'ZAR',
    long_label: 'South African Rand (R)',
    flag: 'ZA',
    icon: 'R',
  },
  {
    value: 'INR',
    label: 'INR',
    long_label: 'Indian Rupee (₹)',
    flag: 'IN',
    icon: '₹',
  },
];

export const languages = [
  { value: 'en', label: 'English', flag: 'GB' },
  { value: 'fr', label: 'Français', flag: 'FR' },
  { value: 'it', label: 'Italiano', flag: 'IT' },
  { value: 'es', label: 'Español', flag: 'ES' },
  { value: 'de', label: 'Deutsch', flag: 'DE' },
  { value: 'jp', label: 'Japanese', flag: 'JP' },
];

export const passwordChecker = (password) => {
  let password_schema = new passwordValidator();
  password_schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits();

  return password_schema.validate(password);
};

// export const forceDownload = (url, filename) => {
//   let xhr = new XMLHttpRequest();
//   xhr.open('GET', 'https://cors-anywhere.herokuapp.com/' + url, true);
//   xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
//   xhr.responseType = 'blob';
//   xhr.onload = (response) => {
//     let urlCreator = window.URL || window.webkitURL;
//     let imageUrl = urlCreator.createObjectURL(response.currentTarget.response);
//     let tag = document.createElement('a');
//     tag.href = imageUrl;
//     tag.download = filename;
//     tag.click();
//   };
//   xhr.send();
// };

const triggerDownload = (url, filename) => {
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(url);

  const tag = document.createElement('a');
  tag.href = imageUrl;
  tag.target = '_parent';
  tag.download = filename;
  tag.click();
};

export const downloadImageFile = async ({
  cert_number,
  downloadHandler = () => {},
  type = 'IMAGE',
  source,
  url,
}) => {
  const onDownloadProgress = async (progressEvent) => {
    if (typeof downloadHandler === 'function') {
      const percent = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      const _type = type === 'IMAGE' ? 'Image' : 'Video';

      const status = {};
      if (percent < 100) {
        status.message = `downloading_${_type.toLowerCase()}`;
        status.percent = percent;
        status.status = notificationTypes.downloading;
        status.progressbar_color = theme.palette.themePrimary;
      } else {
        status.message = `${_type.toLowerCase()}_downloaded`;
        status.percent = percent;
        status.status = notificationTypes.completed;
        status.progressbar_color =
          theme.semanticColors.notificationProgressColor;
        status.source = source;
      }
      downloadHandler(status);
    }
  };

  if (type === 'IMAGE') {
    const valid_image_extensions = ['jpeg', 'jpg', 'png', 'webp'];
    const response = await Axios({
      cancelToken: source.token,
      method: 'GET',
      onDownloadProgress,
      responseType: 'blob',
      url: url,
    });

    let image_extension = response.headers['content-type']
      ? response.headers['content-type'].split('/')[1]
      : 'jpg';

    if (!valid_image_extensions.includes(image_extension)) {
      image_extension = 'jpg';
    }
    let date = Date.now();
    triggerDownload(response.data, `${date}.${image_extension}`);
  } else {
    const response = await Axios({
      cancelToken: source.token,
      method: 'GET',
      onDownloadProgress,
      responseType: 'blob',
      url: url,
    });

    triggerDownload(response.data, cert_number);
  }
};

export let userInReviewText = 'We are verifying your account.';
export let userInReviewStatements = {
  shortlist_text: 'Selected diamond(s) have been added to your shortlist',
};
export const isActiveSet = (superset, subset, withoutLab = false) => {
  if (withoutLab) {
    superset.labgrown = false;
  }
  return !_.isEqual(
    subset,
    _.pick({ ...subset, ...superset }, Object.keys(subset))
  );
};

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export let set_cookie = (cname, referral) => {
  const d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + referral + ';' + expires + ';path=/';
};

export let get_cookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const NewSortOptions = [
  {
    title: 'Recently Added',
    type_title: 'Recently Added',
    direction_full: '',
    direction: '',
    value: 'recent-desc',
    object: {
      direction: 'DESC',
      type: 'createdAt',
    },
  },
  {
    value: 'discount-high-to-low',
    title: 'Discount (High to Low)',
    type_title: 'discount',
    direction_full: 'high_low',
    direction: 'up',
    object: {
      direction: 'ASC',
      type: 'discount',
    },
  },
  {
    value: 'price-high-to-low',
    title: 'Price (High to Low)',
    type_title: 'price',
    direction_full: 'high_low',
    direction: 'down',
    object: {
      direction: 'DESC',
      type: 'price',
    },
  },
  {
    value: 'price-low-to-high',
    title: 'Price (Low to High)',
    type_title: 'price',
    direction_full: 'low_high',
    direction: 'up',
    object: {
      direction: 'ASC',
      type: 'price',
    },
  },

  {
    value: 'size-high-to-low',
    title: 'Size (High to Low)',
    type_title: 'size',
    direction_full: 'high_low',
    direction: 'down',
    object: {
      direction: 'DESC',
      type: 'size',
    },
  },
  {
    value: 'size-low-to-high',
    title: 'Size (Low to High)',
    type_title: 'size',
    direction_full: 'low_high',
    direction: 'up',
    object: {
      direction: 'ASC',
      type: 'size',
    },
  },
];

export const NewSortOptionsV3 = [
  {
    title: '',
    subList: [
      {
        value: 'recent-desc',
        title: 'Recently Added',
        type_title: 'Recently Added',
        direction_full: '',
        direction: '',
        icon: 'star-icon',
        object: {
          direction: 'DESC',
          type: 'createdAt',
        },
      },
      {
        value: 'discount-high-to-low',
        title: 'Discount: high to low',
        type_title: 'discount',
        direction_full: 'high_low',
        direction: 'up',
        icon: 'sort_descending_numbers',
        object: {
          direction: 'ASC',
          type: 'discount',
        },
      },
    ],
  },
  {
    title: 'price',
    subList: [
      {
        value: 'price-high-to-low',
        title: 'Price: high to low',
        type_title: 'price',
        direction_full: 'high_low',
        direction: 'down',
        icon: 'sort_descending_v3',
        object: {
          direction: 'DESC',
          type: 'price',
        },
      },
      {
        value: 'price-low-to-high',
        title: 'Price: low to high',
        type_title: 'price',
        direction_full: 'low_high',
        direction: 'up',
        icon: 'sort_ascending_v3',
        object: {
          direction: 'ASC',
          type: 'price',
        },
      },
    ],
  },
  {
    title: 'carat',
    subList: [
      {
        value: 'carat-high-to-low',
        title: 'carat: high to low',
        type_title: 'carat',
        direction_full: 'high_low',
        direction: 'down',
        icon: 'sort_descending_v3',
        object: {
          direction: 'DESC',
          type: 'size',
        },
      },
      {
        value: 'carat-low-to-high',
        title: 'carat: low to high',
        type_title: 'carat',
        direction_full: 'low_high',
        direction: 'up',
        icon: 'sort_ascending_v3',
        object: {
          direction: 'ASC',
          type: 'size',
        },
      },
    ],
  },
];

export const HelpOptions = [
  {
    value: 'live-chat',
    title: 'live-chat',
    type_title: 'live-chat',
    icon: 'live-chat-icon',
  },
  {
    value: 'help-center',
    title: 'help-center',
    type_title: 'help-center',
    icon: 'faq-icon',
    right_icon: 'external_link_v2',
  },
  // {
  //   value: 'submit-feedback',
  //   title: 'submit_feedback',
  //   type_title: 'submit_feedback',
  //   icon: 'send',
  // },
];

export let get_order_item = (order) => {
  if (!_.isEmpty(order.object)) return get_order_item(order.object);
  if (order.type == null || order.direction == null) return NewSortOptions[1];
  let result = NewSortOptions.find((opt) => {
    return (
      opt.object.type === order.type && opt.object.direction === order.direction
    );
  });
  return result || NewSortOptions[0];
};

export const get_order_item_v3 = (order) => {
  if (!_.isEmpty(order.object)) return get_order_item(order.object);
  if (order.type == null || order.direction == null) return NewSortOptionsV3[1];

  let filtered = [];
  NewSortOptionsV3.forEach((item) => {
    if (item && item?.subList && item.subList.length) {
      item.subList.forEach((x) => filtered.push(x));
    } else filtered.push(item);
  });
  const isNatural = window.location.pathname.includes('natural');
  const hideNatDiscount = naturalDiamondDiscount;

  if ((isNatural && hideNatDiscount) || !isNatural) {
    filtered = filtered.filter((x) => x.value !== 'discount-high-to-low');
  }

  const result = filtered.find(
    (x) =>
      x.object.type === order.type && x.object.direction === order.direction
  );
  return result ?? NewSortOptionsV3[0];
};

/**
 * isStoneFancy will return if stone is fancy
 * @param {string} color - valid diamond color.
 * @param {number} carats - valid diamond weight in carats.
 */
export const isStoneFancy = (color = '', carats) => {
  const fancy_list = [
    'FANCY',
    'O',
    'NO',
    'PR',
    'SZ',
    'OP',
    'P',
    'Q',
    'QR',
    'R',
    'S',
    'ST',
    'T',
    'U',
    'UV',
    'V',
    'W',
    'WX',
    'X',
    'XY',
    'Y',
    'YZ',
    'Z',
  ];
  return fancy_list.includes(color) || (color === 'N' && Number(carats) > 0.29);
};

export const get_tracking_link = (
  carrier,
  tracking_code,
  origin_country,
  destination_country
) => {
  if (!tracking_code) {
    return ``;
  }
  if (carrier === 'FEDEX') {
    return `https://www.fedex.com/fedextrack/?trknbr=${tracking_code}`;
  }
  if (carrier === 'GOPHR') {
    return ``;
  }
  if (carrier === 'ROYAL') {
    return `https://services.royalmail.com/track-your-item#/tracking-results/${tracking_code}`;
  }
  if (carrier === 'BRINKS') {
    if (origin_country === 'IN' && destination_country === 'NL') {
      return `https://alltrack.org/air-cargo/air-france-af-tracking?AirCargo[number]=${tracking_code}`;
    }
    return `https://bgs01.brinksglobal.com/bleprod/trackTrace.bi?a=${tracking_code}`;
  }
  if (carrier === 'DHL') {
    return `https://www.dhl.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_code}`;
  }
  if (carrier === 'TNT') {
    return `https://www.tntexpress.com.au/interaction/track.aspx?con=${tracking_code}`;
  }
  if (carrier === 'UPS') {
    return `https://www.ups.com/track?loc=en_GB&tracknum=${tracking_code}`;
  }
  if (carrier === 'THE_COURIER_GUY') {
    return `https://thecourierguy.co.za/tracking?tracking=${tracking_code}`;
  }
  if (carrier === 'MALCA') {
    return `https://tracking.malca-amit.com/${tracking_code}`;
  }
  return `https://track.nivoda.net/${tracking_code.replace(/\s/g, '')}`;
};

export let toTwodecimal = (value) => {
  let new_value = +value;
  let is_float = new_value === +new_value && new_value !== (new_value | 0);
  return is_float ? Number.parseFloat(new_value).toPrecision(4) : new_value;
};

export let is_mobile = window.innerWidth < 700;

export const UsesAWebsiteButton = (website) => (
  <>
    <button
      style={{
        width: '94px',
        height: '25px',
        border: 'none',
        borderRadius: '50px',
        backgroundColor: '#E5E5F4',
        color: theme.palette.themePrimary,
        marginBottom: '5px',
      }}
      onClick={() =>
        window.open(
          website.startsWith('http') ? website : `https://${website}`,
          '_blank'
        )
      }
    >
      uses a website
    </button>
  </>
);

export const CommunicationEmail = (email) => (
  <button
    style={{
      width: '100px',
      height: '25px',
      border: 'none',
      borderRadius: '50px',
      backgroundColor: '#E5E5F4',
      color: theme.palette.themePrimary,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    uses an email
    <span style={{ marginTop: '7px' }}>
      <CopyText
        className="comm_email__copy"
        clipboardId={`${email}`}
        copyHintText={`Copy Email`}
        textToCopy={`${email}`}
      />
    </span>
  </button>
);

export const CapitalizeStatus = (status) => {
  if (!status) {
    return 'Unknown';
  }
  let statusArr = status.split('_');
  statusArr = statusArr.map(
    (element) => element[0].toUpperCase() + element.slice(1, element.length)
  );
  if (statusArr[statusArr.length - 1].length === 2) {
    statusArr[statusArr.length - 1] =
      statusArr[statusArr.length - 1].toUpperCase();
  }
  return statusArr.join(' ');
};
// let getVersion = () => {
//   return window && window.location.pathname.includes('/v2');
// };
export const getLivePath = () => '/v2/live';
// export let isVersion2 =
//   (window && window.location.pathname.includes('/v2')) ||
//   localStorage.getItem('app_version') === 'v2';
export let livePath = '/v2/live';
export const office_to_office = (originCountry) => {
  const office_countries = ['US', 'GB', 'NL', 'BE', 'ZA'];
  return office_countries.includes(originCountry);
};

export const DiamondDetailsUrl = ({
  children = '',
  labgrown,
  productId,
  productType,
}) => {
  let linkText = `${
    productType === ProductTypes.GEMSTONE
      ? ProductTypes.GEMSTONE
      : ProductTypes.DIAMOND
  } details`;

  let url =
    productType === 'Gemstone'
      ? `/v2/live/search/gemstones/${productId}`
      : `${livePath}/search/${
          labgrown ? 'labgrown' : 'natural'
        }/diamond/${productId}`;
  return (
    <span
      style={{
        color: theme.palette.offBlack4,
        textDecoration: 'underline',
        cursor: 'pointer',
        ...(is_mobile ? theme.fonts.small : {}),
      }}
      onClick={() => {
        window.open(url);
      }}
    >
      {children}
      {linkText}
    </span>
  );
};

export const ShowPaymentTerms = ({ orderItem }) => {
  if (!orderItem?.Product?.supplier) {
    return '-';
  }

  let has_terms =
    Array.isArray(orderItem?.Product?.supplier?.payment_terms) &&
    orderItem.Product.supplier.payment_terms.find(
      (t) =>
        t.location === orderItem.origin_country &&
        t.payment_term_type !== 'ADVANCE'
    );

  return has_terms ? <span>Terms</span> : 'No Terms';
};

export let CustomTranswithT = ({
  text,
  fallBack,
  returnPlainText = false,
  t,
}) => {
  if (typeof t !== 'function') return text;
  let translated_text = t(text);

  let is_same = translated_text === text;
  if (is_same && fallBack)
    return returnPlainText ? `${fallBack}` : <span>{fallBack}</span>;
  return returnPlainText ? (
    `${translated_text}`
  ) : (
    <span>{translated_text}</span>
  );
};
export let CustomTrans = ({
  text,
  fallBack,
  returnPlainText = false,
  ...rest
}) => {
  let { t } = useTranslation();
  let translated_text = t(text);
  let is_same = translated_text === text;
  if (is_same && fallBack)
    return returnPlainText ? `${fallBack}` : <span {...rest}>{fallBack}</span>;
  return returnPlainText ? (
    `${translated_text}`
  ) : (
    <span {...rest}>{translated_text}</span>
  );
};

export let HideOnReview = ({ customText, hasTooltip, children }) => {
  let { user } = useUserContext();
  const { is_user_in_review } = getUserReviewStatus(user);
  if (is_user_in_review) {
    let text = (
      <CustomTrans text={customText ?? 'Hidden'} fallBack={customText} />
    );
    return hasTooltip ? (
      <Tooltip
        content={
          <div style={{ width: 120 }}>
            <CustomTrans text="your_account_is_currently_being_reviewed" />
          </div>
        }
      >
        {text}
      </Tooltip>
    ) : (
      text
    );
  }
  return children;
};

export function checkItemAvailability({ offer = {}, user = {} }) {
  let diamond = offer['Product'];
  let on_memo = diamond?.availability === 'ON_MEMO';
  const isSoldOut = offer && offer?.Product?.availability === 'SOLD_OUT';
  const isOnHoldForAnotherCustomer = isOnHoldForAnotherUser({
    offer,
    userId: user?.id,
  });
  const isWithinCompanyATCEnabled = isHoldByCompanyUser({
    offer,
    user: user,
  });
  const isStonedOnHoldFromSupplier =
    offer && offer?.Product?.availability === 'ON_HOLD' && !offer?.hold?.id;
  let isOnHoldForExternalCustomer =
    isOnHoldForAnotherCustomer && !isWithinCompanyATCEnabled;
  return {
    on_memo,
    isSoldOut,
    isOnHoldForAnotherCustomer,
    isWithinCompanyATCEnabled,
    isStonedOnHoldFromSupplier,
    isOnHoldForExternalCustomer,
    notAvailable:
      isOnHoldForExternalCustomer ||
      isStonedOnHoldFromSupplier ||
      isSoldOut ||
      on_memo ||
      (diamond?.OrderItemId && diamond?.OrderItemId !== null),
  };
}

export const isProductOnHold = (offer) =>
  offer &&
  offer.hold &&
  (offer.hold.status === holdStatusTypes.ON_HOLD ||
    offer.hold.status === holdStatusTypes.REQUESTED ||
    offer.hold.status === holdStatusTypes.EXTENSION_REQUESTED);

export const isOnHoldForAnotherUser = ({ offer, userId }) =>
  isProductOnHold(offer) && offer?.hold?.buyer?.id !== userId;

export const isHoldByCompanyUser = ({ offer, user }) =>
  user &&
  user.company?.company_settings?.action_other_user_hold &&
  offer &&
  offer.hold &&
  offer.hold?.buyer?.company?.id === user?.company?.id;

export let checkholdsStatusForItem = (offer, user) => {
  const isOnHoldForAnotherCustomer = isOnHoldForAnotherUser({
    offer,
    userId: user.id,
  });

  const isWithinCompanyATCEnabled = isHoldByCompanyUser({
    offer,
    user,
  });

  const isStonedOnHoldFromSupplier =
    offer &&
    offer.Product.availability === holdStatusTypes.ON_HOLD &&
    !offer?.hold?.id;
  let on_hold_for_external_customer =
    isOnHoldForAnotherCustomer && !isWithinCompanyATCEnabled;

  return on_hold_for_external_customer || isStonedOnHoldFromSupplier;
};

export const enquiryOptionType = {
  all: 'all',
  eyeclean: 'eyeclean',
  hold: 'hold',
  image: 'image',
  video: 'video',
  luster: 'luster',
  shade: 'shade',
};

export const useMissingInfo = (diamond) => {
  const missing = [];
  const missingInfo = {
    hasEyeclean: false,
    hasShade: false,
    hasLuster: false,
    hasImage: false,
    hasVideo: false,
  };

  if (diamond) {
    // Push the keys --> which are missing into tracking array
    if (diamond.eyeClean == null) {
      missing.push(enquiryOptionType.eyeclean);
    } else {
      missingInfo.hasEyeclean = true;
    }
    if (
      diamond.brown == null &&
      diamond.green == null &&
      diamond.other == null
    ) {
      missing.push(enquiryOptionType.shade);
    } else {
      missingInfo.hasShade = true;
    }
    if (diamond.milky == null) {
      missing.push(enquiryOptionType.luster);
    } else {
      missingInfo.hasLuster = true;
    }
    if (diamond.certificate.image == null && !diamond.has_media_enquiry) {
      missing.push(enquiryOptionType.image);
    } else {
      missingInfo.hasImage = !!diamond.certificate.image;
    }
    if (diamond.certificate.v360 == null && !diamond.has_media_enquiry) {
      missing.push(enquiryOptionType.video);
    } else {
      missingInfo.hasVideo = !!diamond.certificate.v360;
    }
  }

  const hasRequestAllInOpt = missing.length > 1;

  // return some useful info
  return {
    requestOpts: uniq(
      hasRequestAllInOpt ? [...missing, enquiryOptionType.all] : missing
    ),
    hasAllMissing: diamond && missing.length === 5,
    hasAll: diamond && missing.length === 0,
    hasRequestAllInOpt,
    missing: uniq(missing),
    ...missingInfo,
  };
};
export const decode_query_from_url = ({ query_string, p_type }) => {
  let isValid = window.location.pathname.includes(p_type.toLowerCase());
  let obj = {};

  if (isValid && query_string) {
    const vars = query_string
      .substring(1)
      .split('&')
      .map((x) => x.split('='));
    for (let [k, value] of vars) {
      try {
        if (k === 'certificate_numbers') {
          obj[k] = JSON.parse(decodeURIComponent(value)).join(',');
        } else obj[k] = JSON.parse(decodeURIComponent(value));
      } catch (err) {
        // its ok, if there is nothing to read
      }
    }
  }
  return obj;
};

export const write_query_variables = ({ query_state, p_type }) => {
  let isValid = window.location.pathname.includes(p_type.toLowerCase());
  let query = '';
  if (query_state && isValid) {
    query = Object.entries(query_state)
      .filter(([_, val]) => {
        if (val == null || val.length === 0) {
          return false;
        }
        return !(
          Array.isArray(val) && val.some((x) => x == null || x.length === 0)
        );
      })
      .map(([key, val]) => `${key}=${encodeURIComponent(JSON.stringify(val))}`)
      .join('&');
  }

  return query_state && isValid ? `?${query}` : '';
};

export const animateCustomCSS = (ref, animation, prefix = 'animate__') => {
  const element = ref.current;

  if (element) {
    const animationName = `${prefix}${animation}`;
    element.classList.add(`${prefix}animated`, animationName);

    const handleAnimationEnd = () => {
      element.classList.remove(`${prefix}animated`, animationName);
    };

    element.addEventListener('animationend', handleAnimationEnd, {
      once: true,
    });
  }
};

/**
 * Show or hide hubspot chat widget
 * @param {*} hubspotState
 */
export const toggleHubspotChat = (hubspotState = {}) => {
  const settings = window.HubSpotConversations || hubspotState?.settings;
  const status = settings?.widget?.status();
  if (status?.loaded) {
    settings?.widget?.open();
  } else {
    settings?.widget?.load({ widgetOpen: true });
  }
};

/**
 * Get shipment link wrt shipment_method field
 * @param {*} shipment
 * @returns link
 */
export const getShipmentLink = (shipment) => {
  if (!shipment) return '';
  if (!shipment?.shipment_method) {
    console.warn(`Required parameter shipment_method not provided`);
  }
  const shipment_method = shipment?.shipment_method || 'REGULAR';
  const link =
    shipment_method === 'SBS_CSHIPMENT'
      ? 'sbs-cshipment'
      : shipment_method === 'CSHIPMENT'
      ? 'cshipment'
      : 'shipment/view-shipment';
  return `/admin/${link}/${shipment.id}`;
};

/**
 * Get Current Categgory of the Page
 * @param {*} AppSessionData
 * @returns Filter Category
 */
export const getProductCategory = (sessionData = {}) => {
  const {
    isMeleePage,
    isDiamondPage,
    isGemstonePage,
    isLabgrownView,
    isNaturalView,
  } = sessionData;

  if (isDiamondPage || isMeleePage) {
    if (isNaturalView) return isDiamondPage ? 'NaturalDiamond' : 'NaturalMelee';
    if (isLabgrownView)
      return isDiamondPage ? 'LabgrownDiamond' : 'LabGrownMelee';
  }

  if (isGemstonePage) return 'Gemstones';

  return 'Unknown';
};

/**
 * Process Filter Query for Analytics
 * @param {*} Query
 * @returns Processed Query
 */
export const processQuery = (query = {}) => {
  const has_image = query.has_image ? query.has_image : false;
  const has_v360 = query.has_v360 ? query.has_v360 : false;

  return {
    ...query,
    has_image,
    has_v360,
  };
};

export const getStoneCategory = (offer) => {
  const { Product, ProductType } = offer;
  const certificateType =
    ProductType === 'Melee' ? Product.MeleeCertificate : Product.certificate;
  const category =
    certificateType?.labgrown !== undefined
      ? `${certificateType.labgrown ? 'Labgrown' : 'Natural'} ${ProductType}`
      : ProductType ?? '';
  return category;
};
