import styled from 'styled-components';
import cx from 'classnames';
import { Icon } from 'fabric/resources/icon';
import theme from 'fabric/theme';
import React, { useState } from 'react';
import { noop, omit } from 'lodash';
import { CopyText } from '@nivoda/common';
import uniq from 'lodash/uniq';
import { WithScrollbar } from '@nivoda/ui';
import { bool, func, object, string } from 'prop-types';
import * as PropTypes from 'prop-types';

const ENTER_KEY = 13;
const stringToMapParser = (str = '', sources = {}) => {
  const ids = (typeof str === 'string' ? str : '')
    // Remove any white space
    .replace(/\s/g, '')
    .split(',');

  return {
    str: ids.join(),
    strMap: ids.reduce(
      (a, c = '') => ({ ...a, ...(c ? { [c]: c } : {}) }),
      sources
    ),
  };
};

const validateString = (str = '') =>
  uniq(
    str
      .split(',')
      // Remove empty elements
      .filter(Boolean)
  ).join();

export function LabelChip(props) {
  return (
    <Label
      className={cx('ssb__st_label', props.highlight && 'highlight')}
      htmlFor="ssb_input"
    >
      <CopyText
        className="ssb__st_copy"
        clipboardId={props.text}
        copyHintText="Copy"
        textToCopy={props.text}
        textToShow={props.text}
        placement={props.i < 6 ? 'bottom' : 'top'}
      />

      {typeof props.onClick === 'function' && (
        <>
          <Icon
            className="ssb__st_close_icon"
            name={'error_icon'}
            onClick={props.onClick}
            role="button"
            size={10}
            title={`Remove ${props.text}`}
          />
          <div className="ssb__st_close_icon--hidden" />
        </>
      )}
    </Label>
  );
}
const Label = styled.label`
  &.ssb {
    &__st_label {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.s1};
      border-radius: 30px;
      border: 1px solid ${theme.palette.blue100};
      color: ${theme.palette.black};
      padding: 0 ${theme.spacing.s2};
      position: relative;
      height: 24px;

      &.highlight {
        &,
        &:hover {
          background: ${theme.palette.blue100};
        }
      }

      .copy_clipboard__icon {
        margin-left: 2px;
        margin-bottom: 2px;
      }

      &:hover {
        background: ${theme.semanticColors.tableSelectedRowBackground};

        .ssb__st_close_icon {
          display: inline-flex;

          &--hidden {
            display: none;
          }
        }
      }
    }
  }
`;

LabelChip.propTypes = {
  highlight: PropTypes.bool,
  text: PropTypes.string,
  i: PropTypes.number,
  onClick: PropTypes.func,
};
export const StatefulSearchBox = (props) => {
  const {
    focus = false,
    contentAreaHeight = '200px',
    disabled = false,
    highlightStyleResolver = (_ = '') => false,
    isContentScrollable = false,
    loading = false,
    name = '',
    onBlur = noop,
    onFocus = noop,
    onKeyDown = noop,
    onKeyUp = noop,
    onRemove = noop,
    placeholder = 'Search',
    readOnly = false,
    type = 'text',
    searchIcon,
    style = {},
    value = '',
  } = props;
  const textData = stringToMapParser(value);
  const [text, setText] = useState('');
  const updatedStr = validateString(`${text},${textData.str}`);

  const handleBlur = () => {
    // passes validated string value to parent
    onBlur(updatedStr);
  };
  const handleKeyDown = (e) => {
    e.persist();
    if (e.keyCode === ENTER_KEY) {
      // passes validated string value to parent
      onKeyDown(e, updatedStr);
    }
  };
  const handleRemove = (id) => {
    const updatedMap = omit(textData.strMap, [id]);
    const updatedKeys = Object.keys(updatedMap);
    const updatedStr =
      updatedKeys.length === 0 ? '' : validateString(updatedKeys.join());

    onRemove({ id, str: updatedStr });
  };
  const onChange = (e) => {
    const { target } = e;
    setText(target.value);
  };

  const handleClear = () => !loading && !disabled && setText('');

  return (
    <StyledBox {...style}>
      <div className="ssb__si_box">
        <Icon
          className={'ssb__search_icon'}
          name={searchIcon?.name ?? 'search'}
          size={searchIcon?.size ?? 14}
          color={searchIcon?.color ?? ''}
        />
        <input
          autoFocus={focus}
          className="ssb__input"
          disabled={disabled}
          name={name}
          onBlur={handleBlur}
          id="ssb_input"
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          readOnly={readOnly}
          type={type}
          value={text}
        />
        <span className="ssb__clear_box" onClick={handleClear}>
          <Icon
            className="ssb__clear_icon"
            color={theme.palette.offWhite1}
            hoverColor={theme.palette.black}
            name={'close'}
            noHover={false}
            onClick={handleClear}
            role="button"
            size={8}
          />
        </span>
      </div>

      <WithScrollbar
        id={'ssb__st_box_scrollbar'}
        isActive={isContentScrollable}
        maxHeight={contentAreaHeight}
        style={{
          marginTop: 10,
          marginBottom: 10,
          height: '60px',
        }}
      >
        <div className="ssb__st_box">
          {Object.keys(textData.strMap)
            .filter(Boolean)
            .map((stockId, i) => (
              <LabelChip
                key={stockId}
                highlight={highlightStyleResolver(stockId)}
                text={stockId}
                i={i}
                onClick={() => !loading && !disabled && handleRemove(stockId)}
              />
            ))}
        </div>
      </WithScrollbar>
    </StyledBox>
  );
};

StatefulSearchBox.propTypes = {
  disabled: bool,
  focus: bool,
  contentAreaHeight: string,
  highlightStyleResolver: func,
  isContentScrollable: bool,
  loading: bool,
  name: string,
  onBlur: func,
  onFocus: func,
  onKeyDown: func,
  onKeyUp: func,
  onRemove: func,
  placeholder: string,
  readOnly: bool,
  searchIcon: object,
  style: object,
  type: string,
  value: string,
};

const StyledBox = styled.div`
  position: relative;

  .ssb {
    &__clear_box {
      background: ${theme.palette.white};
      position: absolute;
      top: 2px;
      right: 2px;
      width: 16px;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__input {
      border: ${theme.semanticProps.borderWhite3};
      border-radius: ${theme.effects.roundedCorner3};
      margin: 0;
      padding-left: ${theme.spacing.s6};
      padding-right: ${theme.spacing.s1};
      width: ${(p) => p?.width ?? '90px'};
      height: 32px;
      ${theme.fonts.medium};

      transition: background-color 0.1s cubic-bezier(0, 0, 0.35, 1),
        width 0.1s cubic-bezier(0, 0, 0.35, 1);

      &::placeholder {
        color: ${theme.palette.gray};
        ${theme.fonts.medium};
        @media (max-width: 700px) {
          ${theme.fonts.small};
        }
      }

      @media (max-width: 700px) {
        ${theme.fonts.small};
      }
    }

    &__search_icon {
      position: absolute;
      top: 9px;
      left: 6px;
    }

    &__si_box {
      display: inline-flex;
      position: relative;

      &:hover,
      &:focus,
      &:focus-within {
        .ssb__input {
          width: ${(p) => p?.expandWidth ?? '200px'};
        }
      }
    }

    &__st_box {
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing.s2};
      width: 400px;
    }

    &__st_close_icon {
      background: ${theme.palette.blue100};
      cursor: pointer;
      display: none;

      &--hidden {
        display: inline-flex;
        height: 10px;
        width: 10px;
      }
    }
  }
`;
