import { QUANTITY_UNITS } from './melee-constants';

export const MeleeCollectionType = {
  NATURAL: 'NATURAL',
  LABGROWN: 'LABGROWN',
};

// noinspection SpellCheckingInspection
export const ShapeType = {
  ASSCHER: 'ASSCHER',
  BAGUETTE: 'BAGUETTE',
  BRIOLETTE: 'BRIOLETTE',
  BULLET: 'BULLET',
  CALF: 'CALF',
  CUSHION_ALL: 'CUSHION_ALL',
  CUSHION_BRILLIANT: 'CUSHION_BRILLIANT',
  CUSHION_MODIFIED: 'CUSHION_MODIFIED',
  EMERALD: 'EMERALD',
  EUROPEAN_CUT: 'EUROPEAN_CUT',
  FLANDERS: 'FLANDERS',
  HALF_MOON: 'HALF_MOON',
  HEART: 'HEART',
  HEXAGONAL: 'HEXAGONAL',
  KITE: 'KITE',
  LOZENGE: 'LOZENGE',
  MARQUISE: 'MARQUISE',
  OCTAGONAL: 'OCTAGONAL',
  OLD_MINER: 'OLD_MINER',
  OTHER: 'OTHER',
  OVAL: 'OVAL',
  PEAR: 'PEAR',
  PENTAGONAL: 'PENTAGONAL',
  PRINCESS: 'PRINCESS',
  RADIANT: 'RADIANT',
  ROSE: 'ROSE',
  ROUND: 'ROUND',
  SHIELD: 'SHIELD',
  SQ_EMERALD: 'SQ_EMERALD',
  SQ_RADIANT: 'SQ_RADIANT',
  SQUARE: 'SQUARE',
  STAR: 'STAR',
  STEP_CUT_EPAULETTE: 'STEP_CUT_EPAULETTE',
  TAPERED_BAGUETTE: 'TAPERED_BAGUETTE',
  TAPERED_BULLET: 'TAPERED_BULLET',
  TRAPEZOID: 'TRAPEZOID',
  TRIANGULAR: 'TRIANGULAR',
  TRILLIANT: 'TRILLIANT',
};

export const MeleeColorType = {
  CHAMPAGNE: 'CHAMPAGNE',
  COGNAC: 'COGNAC',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  OW: 'OW',
  Q: 'Q',
  QR: 'QR',
  T: 'T',
  TLB: 'TLB',
  YZ: 'YZ',
  Y: 'Y',
  Z: 'Z',
};

export const ProductTypes = {
  DIAMOND: 'Diamond',
  MELEE: 'Melee',
  GEMSTONE: 'Gemstone',
  ALL: 'ALL',
};
export const OrderDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};
export const MeleeCollectionOrderType = {
  CARATS: 'carats',
  CREATED_AT: 'createdAt',
  NONE: 'none',
  PRICE: 'price',
  SIZE: 'size',
};

export const ProductTypeNameMapper = {
  [ProductTypes.DIAMOND]: 'Diamond',
  [ProductTypes.MELEE]: 'Melee',
};

export const MeleeClarityType = {
  VVS: 'VVS',
  VVS1: 'VVS1',
  VVS2: 'VVS2',
  VS1: 'VS1',
  VS2: 'VS2',
  SI1: 'SI1',
  SI2: 'SI2',
  I1: 'I1',
  I2: 'I2',
  I3: 'I3',
  IF: 'IF',
};

export const FIntensityType = {
  NON: 'NON',
  VSL: 'VSL',
  SLT: 'SLT',
  FNT: 'FNT',
  MED: 'MED',
  STG: 'STG',
  STN: 'STN',
  VST: 'VST',
};

export const CutType = [
  { value: 'EX', label: 'Excellent' },
  { value: 'VG', label: 'Very Good' },
  { value: 'GD', label: 'Good' },
  { value: 'F', label: 'Fair' },
];
export const MELEE_CX_CUT = [
  {
    label: 'very_good',
    value: 'VG',
  },
  {
    label: 'excellent',
    value: 'EX',
  },
];
export const MELEE_ALL_SHAPES = [
  { value: 'ROUND', name: 'round', icon: 'Round' },
  { value: 'OVAL', name: 'oval', icon: 'Oval' },
  { value: 'PRINCESS', name: 'princess', icon: 'Princess' },
  { value: 'HEART', name: 'heart', icon: 'Heart' },

  { value: 'EMERALD', name: 'emerald', icon: 'Emerald' },
  {
    value: 'SQ_EMERALD',
    name: 'sq_emerald',
    icon: 'SqEmerald',
  },
  {
    value: 'CUSHION_ALL',
    name: 'cushion_all',
    icon: 'CushionAll',
  },
  {
    value: 'CUSHION_BRILLIANT',
    name: 'cushion_brilliant',
    icon: 'CushionBrilliant',
  },
  {
    value: 'CUSHION_MODIFIED',
    name: 'cushion_modified',
    icon: 'CushionModified',
  },
  { value: 'MARQUISE', name: 'marquise', icon: 'Marquise' },
  { value: 'PEAR', name: 'pear', icon: 'Pear' },
  { value: 'ASSCHER', name: 'asscher', icon: 'Asscher' },
  {
    value: 'SQ_RADIANT',
    name: 'sq_radiant',
    icon: 'SqRadiant',
  },
  { value: 'RADIANT', name: 'radiant', icon: 'Radiant' },
  {
    value: 'OLD_MINER',
    name: 'old_miner',
    icon: 'OldMiner',
  },
  { value: 'STAR', name: 'star', icon: 'Star' },
  { value: 'ROSE', name: 'rose', icon: 'Rose' },
  { value: 'SQUARE', name: 'square', icon: 'Square' },
  {
    value: 'HALF_MOON',
    name: 'half_moon',
    icon: 'HalfMoon',
  },
  {
    value: 'TRAPEZOID',
    name: 'trapezoid',
    icon: 'Trapezoid',
  },
  { value: 'FLANDERS', name: 'flanders', icon: 'Flanders' },
  {
    value: 'BRIOLETTE',
    name: 'briolette',
    icon: 'Briolette',
  },
  {
    value: 'PENTAGONAL',
    name: 'pentagonal',
    icon: 'Pentagonal',
  },
  {
    value: 'HEXAGONAL',
    name: 'hexagonal',
    icon: 'Hexagonal',
  },
  {
    value: 'OCTAGONAL',
    name: 'octagonal',
    icon: 'Octagonal',
  },
  {
    value: 'TRIANGULAR',
    name: 'triangular',
    icon: 'Triangular',
  },
  {
    value: 'TRILLIANT',
    name: 'trilliant',
    icon: 'Trilliant',
  },
  { value: 'CALF', name: 'calf', icon: 'Calf' },
  { value: 'SHIELD', name: 'shield', icon: 'Shield' },
  { value: 'LOZENGE', name: 'lozenge', icon: 'Lozenge' },
  { value: 'KITE', name: 'kite', icon: 'Kite' },
  {
    value: 'EUROPEAN_CUT',
    name: 'european_cut',
    icon: 'European_Cut',
  },
  { value: 'BAGUETTE', name: 'baguette', icon: 'Baguette' },
  {
    value: 'TAPERED_BAGUETTE',
    name: 'tapered_baguette',
    icon: 'Tapered_Baguette',
  },
  { value: 'BULLET', name: 'bullet', icon: 'Bullet' },
  {
    value: 'TAPERED_BULLET',
    name: 'tapered_bullet',
    icon: 'Tapered_Bullet',
  },
  { value: 'OTHER', name: 'other', icon: 'Other' },
];
export const MELEE_CX_SHAPES = [
  { value: 'ROUND', name: 'round', icon: 'Round' },
  { value: 'OVAL', name: 'oval', icon: 'Oval' },
  { value: 'PRINCESS', name: 'princess', icon: 'Princess' },
  { value: 'EMERALD', name: 'emerald', icon: 'Emerald' },
  {
    value: 'SQ_EMERALD',
    name: 'sq_emerald',
    icon: 'SqEmerald',
  },
  { value: 'ASSCHER', name: 'asscher', icon: 'Asscher' },
  {
    value: 'CUSHION',
    name: 'cushion_all',
    icon: 'CushionAll',
  },
  { value: 'MARQUISE', name: 'marquise', icon: 'Marquise' },
  { value: 'PEAR', name: 'pear', icon: 'Pear' },
  {
    value: 'SQ_RADIANT',
    name: 'sq_radiant',
    icon: 'SqRadiant',
  },
  { value: 'RADIANT', name: 'radiant', icon: 'Radiant' },
  { value: 'HEART', name: 'heart', icon: 'Heart' },
  {
    value: 'OLD_MINER',
    name: 'old_miner',
    icon: 'OldMiner',
  },
  { value: 'STAR', name: 'star', icon: 'Star' },
  { value: 'ROSE', name: 'rose', icon: 'Rose' },
  { value: 'SQUARE', name: 'square', icon: 'Square' },
  {
    value: 'HALF_MOON',
    name: 'half_moon',
    icon: 'HalfMoon',
  },
  {
    value: 'CUSHION_BRILLIANT',
    name: 'cushion_brilliant',
    icon: 'CushionBrilliant',
  },
  {
    value: 'TRAPEZOID',
    name: 'trapezoid',
    icon: 'Trapezoid',
  },
  { value: 'FLANDERS', name: 'flanders', icon: 'Flanders' },
  {
    value: 'BRIOLETTE',
    name: 'briolette',
    icon: 'Briolette',
  },
  {
    value: 'PENTAGONAL',
    name: 'pentagonal',
    icon: 'Pentagonal',
  },
  {
    value: 'HEXAGONAL',
    name: 'hexagonal',
    icon: 'Hexagonal',
  },
  {
    value: 'OCTAGONAL',
    name: 'octagonal',
    icon: 'Octagonal',
  },
  {
    value: 'TRIANGULAR',
    name: 'triangular',
    icon: 'Triangular',
  },
  {
    value: 'TRILLIANT',
    name: 'trilliant',
    icon: 'Trilliant',
  },
  { value: 'CALF', name: 'calf', icon: 'Calf' },
  { value: 'SHIELD', name: 'shield', icon: 'Shield' },
  { value: 'LOZENGE', name: 'lozenge', icon: 'Lozenge' },
  { value: 'KITE', name: 'kite', icon: 'Kite' },
  {
    value: 'EUROPEAN_CUT',
    name: 'european_cut',
    icon: 'European_Cut',
  },
  { value: 'BAGUETTE', name: 'baguette', icon: 'Baguette' },
  {
    value: 'TAPERED_BAGUETTE',
    name: 'tapered_baguette',
    icon: 'Tapered_Baguette',
  },
  { value: 'BULLET', name: 'bullet', icon: 'Bullet' },
  {
    value: 'TAPERED_BULLET',
    name: 'tapered_bullet',
    icon: 'Tapered_Bullet',
  },
  { value: 'OTHER', name: 'other', icon: 'Other' },
];
export const MELEE_CX_LABGROWN_COLOR = [
  { value: 'D_E', label: 'D-E' },
  { value: 'E_F', label: 'E-F' },
  { value: 'F', label: 'F' },
  { value: 'H', label: 'H' },
];
export const MELEE_CX_NATURAL_COLOR = [
  { value: 'E', label: 'E' },
  { value: 'E_F', label: 'E-F' },
  { value: 'F', label: 'F' },
  { value: 'F_G', label: 'F-G' },
  { value: 'G_H', label: 'G-H' },
  { value: 'H', label: 'H' },
];
export const MELEE_CX_NATURAL_CLARITY = [
  { value: 'VVS', label: 'VVS' },
  { value: 'VS', label: 'VS' },
  { value: 'SI', label: 'SI' },
  { value: 'I1', label: 'I1' },
];
export const MELEE_CX_LABGROWN_CLARITY = [
  { value: 'VS', label: 'VS' },
  { value: 'SI', label: 'SI' },
];
export const PolishType = [
  { value: 'P', label: 'Poor' },
  { value: 'F', label: 'Fair' },
  { value: 'G', label: 'Good' },
  { value: 'VG', label: 'Very Good' },
  { value: 'EX', label: 'Excellent' },
  { value: 'ID', label: 'Ideal' },
  { value: 'EIGHTX', label: '8X' },
];

export const FIntensityOptions = {
  [FIntensityType.NON]: 'None',
  [FIntensityType.VSL]: 'Very Slight',
  [FIntensityType.FNT]: 'Faint',
  [FIntensityType.SLT]: 'Slight',
  [FIntensityType.MED]: 'Medium',
  [FIntensityType.STG]: 'Strong',
  [FIntensityType.VST]: 'Very Strong',
};

export const InclusionType = {
  B0: 'B0',
  B1: 'B1',
  B2: 'B2',
  B3: 'B3',
};

// export const FluorescenceColorOptions = {
//   NONE: 'None',
//   BLUE: 'Blue',
//   WHITE: 'White',
//   YELLOW: 'Yellow',
//   ORANGE: 'Orange',
// };

export const MilkyOptions = ['None', 'Light', 'Medium', 'Heavy'];

export const meleeProductOperationType = {
  ADD_MC_PARENT: 'ADD_MC_PARENT',
  ADD_MC_CHILD: 'ADD_MC_CHILD',
  EDIT_MC_PARENT: 'EDIT_MC_PARENT',
  EDIT_MC_CHILD: 'EDIT_MC_CHILD',
  DELETE_MC_PARENT: 'DELETE_MC_PARENT',
  DELETE_MC_CHILd: 'DELETE_MC_CHILD',
};

export const meleeFilterDefaultState = {
  clarity: [],
  color: {
    white: [],
    fancy: [],
    fancy_treated: [],
  },
  supplierStockId: [],
  cut: [],
  shapes: [],
  sizes: {
    carat_range: [],
    mm_size: [],
    sieve_size: [],
  },
  supplier_id: [],
};

export const meleeLabgrownFilterDefaultState = {
  clarity: [],
  color: {
    white: [],
    fancy: [],
    fancy_treated: [],
  },
  supplierStockId: [],
  cut: [],
  shapes: [],
  sizes: {
    carat_range: [],
    mm_size: [],
    sieve_size: [],
  },
  supplier_id: [],
};

export const DEFAULT_MELEE_QUERY_STATE = {
  order: {
    direction: OrderDirection.ASC,
    type: MeleeCollectionOrderType.PRICE,
  },
  query: { ...meleeFilterDefaultState, type: MeleeCollectionType.NATURAL },
};

export const meleeSourceType = {
  CONSIGNMENT: 'CONSIGNMENT',
  // LIVE: 'LIVE',
  SOURCE_TO_ORDER: 'SOURCE_TO_ORDER',
  // CONSIGNMENT_PERIOD_OVER: 'CONSIGNMENT_PERIOD_OVER', // Not yet supported
  // CONSIGNMENT_RETURNED: 'CONSIGNMENT_RETURNED', // Not yet supported
  SPECIAL_REQUEST: 'SPECIAL_REQUEST',
  // STOCK_FINISHED: 'STOCK_FINISHED', // Not yet supported
  OTHER: 'OTHER',
};

export const parcelType = {
  FINE: 'FINE',
  CLEAN: 'CLEAN',
  COMMERCIAL: 'COMMERCIAL',
  FAIR: 'FAIR',
};

export const MELEE_ADMIN_REPOSITORY_LOCAL_FILTER = {
  supplier_id: [],
  location_id: '',
  source: {},
  parcel: [],
  cut: [],
  type: {},
  date: {
    from: '',
    to: '',
  },
  status: {},
  mm_size: '',
  text: '',
  memo_number: [],
  shapes: [],
  color: [],
};

export const DEFAULT_MELEE_REPO_EDIT_STATE = {
  type: '',
  source: '',
  status: '',
  supplierStockId: '',
  parcel: '',
  mm_size: { from: '', to: '' },
  qc_accepted_carats: null,
  qc_accepted_pieces: null,
  sourced_date: null,
  consignment_period: null,
  cost_price_per_carat: null,
  sell_price_per_carat: null,
  carats_left: null,
  carats_ordered: null,
  pieces_left: null,
  pieces_ordered: null,
  stones_per_carat: null,
  carat_weight: null,
  location_id: '',
};
export const defaultSpecialRequestState = {
  step: '',
  details: {
    shape: '',
    clarity: '',
    cut: '',
    color: '',
    f_color: '',
    expected_delivery_by: '',
    order_request_variable_quotes: [
      {
        mm_size: { from: '', to: '' },
        carats: '',
        pieces: '',
        measurement: { length: '', width: '', depth: '' },
      },
    ],
    buyer_comment: '',
    stone_type: '',
    order_request_ref_image: '',
    customer_preference: QUANTITY_UNITS?.CTS,
  },
  referenceImage: [],
  spr_number: '',
};

export const defaultSpecialAdminRequestState = {
  step: '',
  details: {
    stone_type: '',
    shape: '',
    color: '',
    f_color: '',
    clarity: '',
    cut: '',
    expected_delivery_date: '',
    order_request_variable_quotes: [
      {
        mm_size: { from: '', to: '' },
        carats: '',
        pieces: '',
        measurement: { length: '', width: '', depth: '' },
        price_per_carat: null,
      },
    ],
    comment: '',
    admin_comment: '',
    order_request_ref_image: '',
    customer_preference: QUANTITY_UNITS?.CTS,
  },
  referenceImage: [],
  spr_number: '',
  company_details: {
    search_text: '',
    company_id: '',
    customer_id: '',
  },
};

export const defaultSpecialEditAdminRequestState = {
  step: '',
  srId: '',
  details: {
    stone_type: '',
    shape: '',
    color: '',
    f_color: '',
    clarity: '',
    cut: '',
    expected_delivery_date: '',
    order_request_variable_quotes: [
      {
        id: '',
        mm_size: { from: '', to: '' },
        carats: '',
        pieces: '',
        measurement: { length: '', width: '', depth: '' },
        price_per_carat: null,
      },
    ],
    buyer_comment: '',
    admin_comment: '',
    customer_preference: QUANTITY_UNITS?.CTS,
  },
};
