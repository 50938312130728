import { css } from 'styled-components';

export const roundSkeletonStyles = (width = '20px') => {
  return css`
    &:empty:after {
      width: ${width} !important;
      height: ${width} !important;
      border-radius: 50%;
    }
  `;
};
