/* eslint-disable no-console */
import React from 'react';
import { AWS360, initial_image_for, does_support_webp } from './AWS360';
import { CarouselContext } from '../components/ui/Carousel';
import * as images from './sample_images';
import { utils } from '@nivoda/common';
import { useTranslation, Translation } from 'react-i18next';
import { noop } from 'lodash/util';
import capitalize from 'lodash/capitalize';
class Lifecycle extends React.Component {
  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  render() {
    return null;
  }
}

const SHAPE_MAP = [
  'ASSCHER',
  'BAGUETTE',
  'BRIOLETTE',
  'BULLET',
  'CALF',
  'CUSHION_ALL',
  'CUSHION_BRILLIANT',
  'CUSHION_MODIFIED',
  'EMERALD',
  'EUROPEAN_CUT',
  'FLANDERS',
  'HALF_MOON',
  'HEART',
  'HEXAGONAL',
  'KITE',
  'LOZENGE',
  'MARQUISE',
  'OCTAGONAL',
  'OLD_MINER',
  'OTHER',
  'OVAL',
  'PEAR',
  'PENTAGONAL',
  'PRINCESS',
  'RADIANT',
  'ROSE',
  'ROUND',
  'SHIELD',
  'SQ_EMERALD',
  'SQ_RADIANT',
  'SQUARE',
  'STAR',
  'TAPERED_BAGUETTE',
  'TAPERED_BULLET',
  'TRAPEZOID',
  'TRIANGULAR',
  'TRILLIANT',
];
let image_extension = does_support_webp() ? 'webp' : 'jpg';
const FallbackImage = ({
  onLoad,
  style,
  onError,
  small = false,
  onlyImage = false,
  onlyButton = false,
  shape = 'ROUND',
  showRequestPhoto,
  alreadyAsked,
  is_loading = false,
  is_video_only,
  missingItem,
}) => {
  let updated_shape = '';
  shape = shape?.replace(' ', '_');
  let is_supplier = window.location.pathname.includes('/supplier/');
  const _session = localStorage.getItem('graphql_session');
  const session = _session ? JSON.parse(_session) : null;
  const { is_user_in_review } = session
    ? utils.getUserReviewStatus(session.user)
    : { is_user_in_review: true };
  let { t } = useTranslation();
  // prevent crashing on images we havent added yet.
  if (shape === 'SQUARE EMERALD') {
    updated_shape = 'SQ_EMERALD';
  } else if (shape === 'SQUARE RADIANT') {
    updated_shape = 'SQ_RADIANT';
  }
  if (!SHAPE_MAP.includes(shape) && !SHAPE_MAP.includes(updated_shape)) {
    shape = 'ROUND';
  }
  let used_shp = updated_shape !== '' ? updated_shape : shape;
  let ShapeComponent = images[capitalize(used_shp)];
  if (!ShapeComponent) {
    console.error(`Error in mapping shape: ${used_shp}.`);
    return null;
  }
  // noinspection NpmUsedModulesInstalled
  const waitingIcon =
    require('fabric/resources/svg/ui-icons/waiting.svg').default;
  const requestMediaHandler = (missingItem) => {
    let enq_options = [];
    if (missingItem === 'media') {
      enq_options = ['image', 'video'];
    }
    if (missingItem === 'image') {
      enq_options = ['image'];
    }
    if (missingItem === 'v360') {
      enq_options.push('video');
    }
    showRequestPhoto(enq_options);
  };
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: small ? 'space-evenly' : 'center',
        height: '100%',
        ...style,
        zIndex: 0,
        color: 'black',
        background: '#C1C2C6',
        flexDirection: 'column',
        borderRadius: 4,
      }}
    >
      {!onlyButton && (
        <ShapeComponent
          alt="Sample Diamond"
          style={{
            width: '40%',
            maxHeight: 160,
          }}
          onLoad={onLoad}
          onError={onError}
        />
      )}
      {!small && !onlyButton && (
        <span
          style={{
            fontWeight: 'bold',
            padding: '3% 0',
            color: 'white',
          }}
        >
          {is_loading
            ? ''
            : missingItem === 'media'
            ? t('no_media')
            : is_video_only || missingItem === 'v360'
            ? t(['No', 'Video'])
            : t(['No', 'Image'])}
        </span>
      )}
      {typeof showRequestPhoto === 'function' &&
        !is_user_in_review &&
        !is_loading &&
        !onlyImage &&
        !is_supplier && (
          <>
            {!alreadyAsked ? (
              <button
                onClick={() =>
                  showRequestPhoto ? requestMediaHandler(missingItem) : null
                }
                className="ask_supplier_button ask_for_photo"
                style={
                  small
                    ? {
                        margin: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 0,
                      }
                    : { margin: 0, borderRadius: 8 }
                }
              >
                {!small
                  ? `${
                      missingItem === 'media'
                        ? t('request_media')
                        : is_video_only || missingItem === 'v360'
                        ? t('request_video')
                        : t('request_photo')
                    }`
                  : 'Ask'}
              </button>
            ) : (
              // Check Pending Photos
              //   <Label>
              //   {t('pending')}{' '}
              //   {missingItem === 'v360'
              //     ? t('Video')
              //     : missingItem === 'media'
              //     ? t('media')
              //     : t('photo')}
              // </Label>
              <>{alreadyAsked && <img src={waitingIcon} alt="waiting" />}</>
            )}
          </>
        )}
    </div>
  );
};

export let SquarePixelForRatio = () => (
  <img
    alt="Transparent pixel"
    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    style={{ width: '100%', height: '100%' }}
  />
);

class SimpleDiamondImage extends React.Component {
  render() {
    let {
      small,
      src,
      className,
      onLoad,
      onError,
      minSize,
      onClick,
      hidePreview = false,
      objectFit,
    } = this.props;

    return (
      <img
        onClick={hidePreview ? noop : onClick}
        alt="Diamond" // TODO Add diamond name?
        style={{
          userSelect: 'none',
          cursor: hidePreview ? 'default' : 'pointer',
          objectFit: objectFit,
          minWidth: minSize,
          minHeight: minSize,
          height: small ? 'initial' : '100%',
        }}
        src={src}
        className={className}
        onLoad={onLoad}
        onError={onError}
      />
    );
  }
}

class DiamondImage extends React.Component {
  state = {
    // 'image', 'initial_360', 'fallback'
    current_image_type:
      this.props.imageUrl == null || this.props.is_video_only
        ? this.props.url360
          ? 'initial_360'
          : 'fallback'
        : 'image',
    is_loaded: false,

    is_hovering: this.props.is_hovering,
    is_360_loaded: false,
    initial_360_image: null,
  };

  render() {
    let { current_image_type, is_hovering, is_360_loaded, initial_360_image } =
      this.state;
    let {
      imageUrl,
      className,
      url360,
      small,
      styles = {},
      onlyImage,
      shape,
      showRequestPhoto, // TODO Rename to onShowRequestPhoto
      onlyButton,
      alreadyAsked,
      hasRadius,
      maxHeight,
      onVideoLoad,
      showIcon,
      minSize = 48,
      hidePreview = false,
      onClick,
      is_loading = false,
      is_video_only,
      icon_position = 'top',
      missingItem = '',
      baseBackground = 'white',
      notAvailable = false,
      isRectangleCard = false,
      variant = '',
    } = this.props;
    let image_path_without_extension =
      imageUrl && imageUrl.substring(0, imageUrl.lastIndexOf('.'));
    let _image_url =
      imageUrl == null
        ? null
        : imageUrl.includes('nivoda')
        ? `${image_path_without_extension}.${image_extension}`
        : imageUrl;
    return (
      <div
        style={{
          position: 'relative',
          backgroundColor: '#eee',
          height: '100%',
          overflow: 'hidden',
          borderRadius: `${hasRadius ? '3px' : ''}`,
          maxHeight: `${maxHeight ? maxHeight : ''}`,
          ...styles,
        }}
        className="diamond_image_container"
        onMouseEnter={() => {
          if (!hidePreview) {
            this.setState({ is_hovering: true });
          }
        }}
        onMouseLeave={() => {
          if (!hidePreview) {
            this.setState({ is_hovering: false });
          }
        }}
        onClick={onClick}
      >
        <SquarePixelForRatio />

        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: notAvailable ? 0.5 : 1,
            transition: 'opacity 1s',
            backgroundColor: baseBackground,
          }}
        >
          {current_image_type === 'fallback' && (
            <FallbackImage
              style={{
                userSelect: 'none',
                pointerEvents: `${showRequestPhoto ? '' : 'none'}`, // Remove this to allow button click on request
              }}
              className={className}
              showRequestPhoto={showRequestPhoto}
              onError={() => {
                // Trouble
                console.error(
                  'TROUBLE BOIII (Fallback image not even loading)'
                );
              }}
              small={small}
              onlyImage={this.props.onlyImage}
              onlyButton={onlyButton}
              shape={shape}
              alreadyAsked={alreadyAsked}
              is_loading={is_loading}
              is_video_only={is_video_only}
              missingItem={missingItem}
            />
          )}

          {current_image_type === 'image' && (
            // TODO Make this work background image so we can do background-size: cover
            // .... as the support for object-fit is not in ie11
            <div onClick={onClick} style={{ width: '100%', height: '100%' }}>
              <SimpleDiamondImage
                // TODO Small should not be part of this, but handled in this component
                small={small}
                src={_image_url}
                // TODO I really don't want classes here
                className={className}
                onLoad={() => {
                  if (!hidePreview) {
                    this.setState({ is_loaded: true });
                  }
                }}
                onClick={onClick}
                minSize={minSize}
                objectFit={variant === 'INLINE_CAROUSEL' ? 'contain' : 'cover'}
                hidePreview={hidePreview}
                onError={() => {
                  if (!hidePreview) {
                    console.log('Image loading failed');
                    this.setState({
                      is_loaded: false,
                      current_image_type: url360 ? 'initial_360' : 'fallback',
                    });
                  }
                }}
              />
            </div>
          )}

          {current_image_type === 'initial_360' && (
            <React.Fragment>
              <div
                className={'video_icon_v360'}
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  [icon_position]: '5px',
                  left: '10px',
                  width: 30,
                }}
              >
                {showIcon && (
                  <img className="v360" src={'/img/v360.svg'} alt="scroll" />
                )}
              </div>
              <SimpleDiamondImage
                alt="Diamond"
                small={small}
                src={initial_360_image}
                className={className}
                onClick={onClick}
                onLoad={() => {
                  if (!hidePreview) {
                    this.setState({ is_loaded: true });
                  }
                }}
                minSize={minSize}
                hidePreview={hidePreview}
                onError={() => {
                  if (!hidePreview) {
                    console.log('Error in initial_360, falling back to sample');
                    this.setState({
                      is_loaded: false,
                      current_image_type: 'fallback',
                    });
                  }
                }}
                objectFit={variant === 'INLINE_CAROUSEL' ? 'contain' : 'cover'}
              />

              <Lifecycle
                onMount={async () => {
                  try {
                    let image = await initial_image_for({ diamond: url360 });
                    if (!image) {
                      // noinspection ExceptionCaughtLocallyJS
                      throw new Error(`Got empty 360 image`);
                    }
                    if (!hidePreview) {
                      this.setState({ initial_360_image: image });
                    }
                  } catch (err) {
                    console.error(`Load 360 error:`, err.message);
                    this.setState({
                      is_loaded: false,
                      current_image_type: 'fallback',
                    });
                  }
                }}
              />
            </React.Fragment>
          )}

          {!onlyImage && (
            <div
              style={{
                opacity: 1,
                pointerEvents: is_360_loaded ? 'all' : 'none',

                transition: 'opacity .5s',
                transitionDelay: '.2s',

                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,

                display:
                  url360 && (is_hovering || is_360_loaded) ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!small && !notAvailable && (
                <div
                  className={'loading_360'}
                  style={{
                    color: 'black',
                    fontSize: 18,
                    backgroundColor: '#eee',
                    padding: 10,
                  }}
                >
                  <Translation>
                    {(t) => `${t('loading_only')} 360°`}
                  </Translation>
                </div>
              )}

              {url360 && (is_hovering || is_360_loaded) && (
                <AWS360
                  showIcon={showIcon}
                  onClick={onClick}
                  v360_info={url360}
                  animate={is_hovering}
                  icon_position={icon_position}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                    transition: 'opacity .5s', // I'm really over-using this transition jeez
                    opacity: is_360_loaded ? 1 : 0,
                    pointerEvents: is_360_loaded ? 'all' : 'none',
                  }}
                  onLoad={() => {
                    if (onVideoLoad && typeof onVideoLoad === 'function')
                      onVideoLoad();
                    this.setState({ is_360_loaded: true });
                  }}
                  isRectangleCard={isRectangleCard}
                  variant={variant}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

let DiamondImageWithPopup = (props) => {
  const { set_carousel } = React.useContext(CarouselContext);

  if (props.fallback) {
    return <DiamondImage {...props} />;
  }

  const default_open_carousel = (e) => {
    if (!props.hidePreview) {
      if (typeof props.onClick === 'function') {
        props.onClick(e);
      }

      const assets = [
        (props.url360 ||
          (props.diamond && props?.diamond?.certificate?.v360)) && {
          diamond: props.diamond,
          type: 'v360',
          url:
            props.url360 ||
            (props.diamond && props.diamond.certificate?.v360) ||
            '',
          userInReview: props.userInReview,
        },
        (props.imageUrl ||
          (props.diamond && props.diamond.certificate?.image)) && {
          diamond: props.diamond,
          type: 'image',
          url:
            props.imageUrl ||
            (props.diamond && props.diamond.certificate?.image) ||
            '',
          userInReview: props.userInReview,
        },
      ].filter(Boolean);

      // only open if we got something
      if (assets.length > 0) {
        set_carousel(assets);
      }
    }
  };
  return <DiamondImage {...props} onClick={default_open_carousel} />;
};

export default DiamondImageWithPopup;

// THis is for pending data
// let Label = styled.span`
// background: #e0e2ec;
// border: 0;
// font-weight: 600;
// color: #1c277c;
// border-radius: 3px;
// padding: 2px 6px;
// display: flex;
// align-items: center;
// justify-content: center;
// text-align: center;
// `;
