import { useEffect } from 'react';

/**
 * @summary use-window-event adds event listener to window object on component
 * mount and removes it on unmount
 * @param type {string}
 * @param listener {(this:Window, ev: WindowEventMap[keyof WindowEventMap]) => any}
 * @param options {(boolean|AddEventListenerOptions)=}
 * @example
 *
 * const handler = (event) => console.log(event);
 *
 * // regular way
 * useEffect(() => {
 *   window.addEventListener('keydown', handler);
 *   return () => window.removeEventListener('keydown', handler);
 * }, []);
 *
 * // with use-window-event hook
 * useWindowEvent('keydown', handler);
 *
 * // Example 2: with refs
 *
 * function Demo() {
 *   const inputRef = useRef<HTMLInputElement>();
 *
 *   useWindowEvent('keydown', (event) => {
 *     if (event.code === 'KeyK' && (event.ctrlKey || event.metaKey)) {
 *       event.preventDefault();
 *       inputRef.current.focus();
 *     }
 *   });
 *
 *   return <input ref={inputRef} />;
 * }
 */
export function useWindowEvent(type, listener, options) {
  useEffect(() => {
    window.addEventListener(type, listener, options);
    return () => window.removeEventListener(type, listener, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
