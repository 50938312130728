import styled from 'styled-components';

export const StyledBox = styled.span`
  /* SVG Progress Indicator */

  .svg-pi-wrapper {
    position: relative;
  }

  /* Fix the orientation */
  .svg-pi {
    transform: rotate(-90deg);
  }

  /* Animated spinner version */
  .svg-pi-indicator--spinner {
    animation: spinner 0.75s linear infinite;
    transform-origin: center;
  }

  .svg-pi-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .svg-pi-label__loading {
    opacity: 0.5;
    font-size: 0.75em;
  }

  .svg-pi-label__progress {
    font-size: 1.5em;
    font-weight: bold;
  }

  .svg-pi-label__loading,
  .svg-pi-label__progress {
    display: block;
  }

  /* Spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
