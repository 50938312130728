import React from 'react';
import { WithScrollbar } from '@nivoda/ui';
import styled from 'styled-components';
import theme from '../../theme';
import { useOnClickOutside } from '../../hooks';
import { any, bool, func, string } from 'prop-types';

export function Paper(props) {
  const ref = React.useRef();

  const onClickOutSide = async (e) => {
    if (typeof props.onClickOutSide === 'function') {
      await props.onClickOutSide(e, props);
    }
  };

  // Call when clicked outside the body
  useOnClickOutside(ref, onClickOutSide);
  return (
    <StyledWrapper
      customSX={props.customSX}
      menuBGColor={props.bgColor}
      placement={props.placement}
      ref={ref}
      minWidth={props.minWidth}
    >
      <WithScrollbar
        id="paper_box"
        isActive={props.showScroll}
        maxHeight={props.menuHeight || '60vh'}
        xHidden={props.xHidden}
        yHidden={props.yHidden}
        className="content_wrapper"
      >
        <div className="content_wrapper__box">{props.children}</div>
      </WithScrollbar>
    </StyledWrapper>
  );
}

Paper.propTypes = {
  customSX: any,
  bgColor: string,
  menuHeight: string,
  onClickOutSide: func,
  showScroll: bool,
  xHidden: bool,
  yHidden: bool,
  minWidth: string,
};

const StyledWrapper = styled.div`
  .content_wrapper {
    position: absolute;
    background: ${({ menuBGColor }) =>
      menuBGColor ? menuBGColor : theme.palette.white};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
    border-radius: ${theme.effects.roundedCorner4};
    z-index: 5;
    // z-index: 11;
    // bottom: -54px;
    // top: 50px;
    left: 0;
    color: ${theme.palette.blackPrimary};

    &__box {
      padding: ${theme.spacing.s3};
    }
    ${({ placement }) => (placement ? placement : '')}
  }

  #paper_box {
    width: max-content;
    min-width: ${({ minWidth }) => (minWidth ? minWidth : '600px')};
  }
  ${({ customSX = '' }) => customSX};
`;
