import styled from 'styled-components';
import theme from '../../theme';

export const Box = styled.div`
  background: ${({ isBlueTheme }) =>
    isBlueTheme
      ? 'linear-gradient(219.43deg, #1B1B71 14.85%, #0E0D48 77.44%)'
      : theme.palette.white};
  color: ${({ isBlueTheme }) =>
    isBlueTheme ? theme.palette.white : theme.palette.themePrimary};

  .stepper {
    &__container {
      display: grid;
      grid-template-columns: minmax(130px, max-content) 1fr;
      gap: ${theme.spacing.s6};
      @media (max-width: 700px) {
        gap: 0;
      }
    }
    &__content {
      height: ${({ dimensions }) => (dimensions ? dimensions.h : '100%')};
      width: ${({ dimensions }) => (dimensions ? dimensions.w : '100%')};
    }
  }

  .top_header__ {
    &item {
      display: flex;
      gap: ${theme.spacing.s2};
      width: 100%;
      position: relative;
      transition: all 0.4s ease-out;

      &:before {
        content: '';
        height: 100%;
        width: 1px;
        background: ${({ isBlueTheme }) =>
          isBlueTheme ? 'rgba(255, 255, 255, 0.3)' : 'rgba(42, 44, 51, 0.3)'};
        position: absolute;
        top: 35px;
        left: 15px;
      }

      &:last-child:before {
        content: unset;
      }

      &.active_after {
        &:before {
          background: ${({ isBlueTheme }) =>
            isBlueTheme ? theme.palette.white : theme.palette.themePrimary};
        }
        .top_header__item-- {
          &count {
            color: ${({ isBlueTheme }) =>
              isBlueTheme ? theme.palette.white : theme.palette.themePrimary};
            border: ${({ isBlueTheme }) =>
              `1px solid ${
                isBlueTheme ? theme.palette.white : theme.palette.themePrimary
              }`};
          }
          &label {
            color: ${({ isBlueTheme }) =>
              isBlueTheme
                ? 'rgba(255, 255, 255, 0.5)'
                : 'rgba(42, 44, 51, 0.5)'};
          }
        }

        &.completed {
          .top_header__item--count {
            border: 1px solid ${theme.palette.green};
          }
        }
      }

      &.active {
        .top_header__item-- {
          &count {
            background: ${({ isBlueTheme }) =>
              isBlueTheme
                ? theme.palette.white
                : 'linear-gradient(219.43deg, #1B1B71 14.85%, #0E0D48 77.44%)'};
            color: ${({ isBlueTheme }) =>
              isBlueTheme ? theme.palette.themePrimary : theme.palette.white};
            border: ${({ isBlueTheme }) =>
              `1px solid ${
                isBlueTheme ? theme.palette.white : theme.palette.themePrimary
              }`};
          }
          &label {
            color: ${({ isBlueTheme }) =>
              isBlueTheme ? theme.palette.white : theme.palette.themePrimary};
            ${theme.fonts.mediumPlus};
            @media (max-width: 700px) {
              ${theme.fonts.smallPlus};
            }
          }
        }
        &.completed {
          .top_header__item--count {
            background: ${theme.palette.white};
            border: 1px solid ${theme.palette.green};
          }
        }
      }
      &s {
        display: grid;
        gap: ${theme.spacing.s10};
        border-bottom: ${({ isBlueTheme, noBorder }) =>
          noBorder
            ? 'none'
            : `1px solid ${
                isBlueTheme ? theme.palette.themePrimary : '#D3D4DC'
              }`};
        grid-template-rows: ${({ count }) => `repeat(${count},auto)`};
        margin: 0 auto;
        justify-items: center;
        padding: ${({ stepperPadding = theme.spacing.s1 }) => stepperPadding};
        @media (max-width: 700px) {
          padding: 20px 0 26px;
          margin-bottom: 36px;
          width: 84%;
        }
      }
      &--count {
        &-box {
          display: grid;
          justify-content: center;
        }
        background: ${({ isBlueTheme }) =>
          isBlueTheme
            ? 'linear-gradient(219.43deg, #1B1B71 14.85%, #0E0D48 77.44%)'
            : theme.palette.white};
        color: ${({ isBlueTheme }) =>
          isBlueTheme ? 'rgba(255, 255, 255, 0.3)' : 'rgba(42, 44, 51, 0.3)'};
        border: ${({ isBlueTheme }) =>
          `1px solid ${
            isBlueTheme ? 'rgba(255, 255, 255, 0.3)' : 'rgba(42, 44, 51, 0.3)'
          }`};
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        z-index: 1;
      }
      &--label {
        margin-top: 6px;
        ${theme.fonts.medium};
        @media (max-width: 700px) {
          ${theme.fonts.small};
        }
        color: ${({ isBlueTheme }) =>
          isBlueTheme ? 'rgba(255, 255, 255, 0.3)' : 'rgba(42, 44, 51, 0.3)'};
      }
    }
    &logo {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: ${({ align }) => (align ? align : 'center')};
      padding: 42px 0 30px;
      @media (max-width: 700px) {
        padding: 24px 0 0;
        width: 84%;
      }
    }
  }
`;
