import { gql } from '@apollo/client';
import { NoteFragment } from './NoteFragment';
import { AdminReturnOrderItemFragment } from './OrderItemFragment';

export const ReturnItemFragment = gql`
  fragment ReturnItem on Return {
    id
    internal_status
    order_item {
      ...AdminReturnOrderItem
      customerReturnDeadlineDate
      shipments {
        id
      }
      Product {
        ... on Diamond {
          other
          bowtie
          last_enquiry_answered
          has_bgmec_enquiry
          has_media_enquiry
          location {
            id
            name
            country
          }
        }
      }
      notes {
        ...AdminNote
      }
      current_location {
        id
        country
      }
    }
    return_qcd
    return_received
    return_type
    destination {
      id
      country
      name
    }
    origin {
      id
      country
      name
    }
    return_days
    createdAt
    created_by {
      id
      role
    }
  }
  ${AdminReturnOrderItemFragment}
  ${NoteFragment}
`;
