import axios from 'axios';

export const ZendeskAPI = (...args) => {
  if (
    !(
      typeof window === 'undefined' ||
      !window.document ||
      !window.document.createElement
    ) &&
    window.zE
  ) {
    window.zE.apply(null, args);
  } else {
    // eslint-disable-next-line no-console
    console.warn('Zendesk is not initialized yet');
  }
};

export const initZendesk = (session) => {
  if (session) {
    ZendeskAPI('messenger', 'hide');
    ZendeskAPI('messenger', 'loginUser', function (callback) {
      callback(session?.zendesk_token);
    });
  }
};

export const getZendeskStatus = async () => {
  const subdomain = process.env.REACT_APP_ZENDESK_SUBDOMAIN;
  return await axios
    .get(
      `https://status.zendesk.com/api/incidents/active?subdomain=${subdomain}`
    )
    .then((res) => res);
};
