import {
  GemstoneOfferFragment,
  HoldFragment,
  OfferFragment,
} from 'graphql/fragments';

import { omit } from 'lodash';
import { isGemstoneItem } from 'utils';
import { mapHoldStatusToTabId } from './helper';
import { holdsTabStatus } from '../../../constants';
import { FetchHoldsRequestByStatus } from 'graphql/queries/common';

const getQueryVar = (tabId) => ({
  query: FetchHoldsRequestByStatus.query,
  variables: {
    query: { status: tabId },
  },
});

export const cacheUpdater = (updaterId, store, { data }) => {
  if (data[updaterId] && data[updaterId].Product) {
    let holdItem = data[updaterId];
    const isMelee = !data[updaterId].Product.offer.id;

    let isGemstone = isGemstoneItem(holdItem);
    let offerName = isGemstone ? 'GemstoneOffer' : 'Offer';
    const variables = {
      id: `${offerName}:${
        isGemstone ? 'GEMSTONE' : isMelee ? 'MELEE' : 'DIAMOND'
      }/${holdItem.Product.id}`,
      fragment: isGemstone ? GemstoneOfferFragment : OfferFragment,
      fragmentName: isGemstone ? 'GemstoneOfferFragment' : 'OfferFragment',
    };
    // // Read the cached offer from apollo-cache
    let offerData = store.readFragment(variables);

    const newOfferData =
      typeof offerData === 'object' ? { ...offerData } : offerData;

    // Push the new hold response on existing offer
    if (newOfferData) {
      newOfferData.hold = omit(data[updaterId], [
        'Product',
        'ProductType',
        'customer_reference',
      ]);
      // Write the updated offer back to the cache
      // to update and re-hydrate the FE state

      store.writeFragment({
        ...variables,
        data: {
          ...newOfferData,
          __typename: isGemstone ? 'GemstoneOffer' : 'Offer',
        },
      });

      // // check if the item was in pending / active tab if found remove it from there
      if (updaterId === 'cancel_hold') {
        [holdsTabStatus.PENDING, holdsTabStatus.ACTIVE].forEach((tabId) => {
          updateHoldsDataWithDashboard({
            itemForUpdate: data[updaterId],
            store,
            tabId,
            remove: true,
          });
        });
      }

      if (updaterId !== 'admin_create_hold') {
        // Now updated the targeted (new status's) tab items

        updateHoldsDataWithDashboard({
          itemForUpdate: data[updaterId],
          store,
          tabId: mapHoldStatusToTabId[data[updaterId].status],
        });
      }
    }
  }
  // if (updaterId === 'create_multiple_holds') {
  //   data[updaterId].forEach((data) => {
  //     const isMelee = !data.Product.offer.id;
  //     const variables = {
  //       id: `Offer:${isMelee ? 'MELEE' : 'DIAMOND'}/${
  //         data.Product.id
  //       }`,
  //       fragment: OfferFragment,
  //       fragmentName: 'OfferFragment',
  //     };
  //     // Read the cached offer from apollo-cache
  //     let offerData = store.readFragment(variables);
  //     const newOfferData =
  //       typeof offerData === 'object' ? { ...offerData } : offerData;

  //     // Push the new hold response on existing offer
  //     if (newOfferData) {
  //       newOfferData.hold = data;

  //       // Write the updated offer back to the cache
  //       // to update and re-hydrate the FE state
  //       store.writeFragment({
  //         ...variables,
  //         data: {
  //           ...newOfferData,
  //           __typename: 'Offer',
  //         },
  //       });

  //       // Now updated the targeted (new status's) tab items
  //       updateHoldsDataWithDashboard({
  //         itemForUpdate: data,
  //         store,
  //         tabId: mapHoldStatusToTabId[data.status],
  //       });
  //     }
  //   });
  // }
};

const holdsQueryFromCache = (store, type, isCustomer = false) => {
  // noinspection JSUnresolvedVariable
  return store.data.getFieldValue(
    store?.data?.data?.ROOT_QUERY || {},
    `${
      isCustomer ? 'get_holds_by_status' : 'get_supplier_holds_by_status'
      // eslint-disable-next-line
    }:{\"query\":{\"status\":\"${type}\"}}`
  );
};
export const updateHoldsDataWithDashboard = ({
  itemForUpdate,
  store,
  tabId,
  remove = false,
}) => {
  const getHoldsByStatus = holdsQueryFromCache(store, tabId, true);
  let get_holds_by_status =
    typeof getHoldsByStatus === 'object'
      ? { ...getHoldsByStatus }
      : getHoldsByStatus;
  if (get_holds_by_status) {
    const qv = getQueryVar(tabId);
    get_holds_by_status.items = [...get_holds_by_status.items].map((hi) => {
      if (hi?.__ref && !hi.__typename) {
        return store.readFragment({
          fragment: HoldFragment,
          fragmentName: 'HoldItemFragment',
          id: hi?.__ref,
        });
      }
      return hi;
    });

    // update and move to its respective tab don't just update it
    if (get_holds_by_status && Array.isArray(get_holds_by_status.items)) {
      const holdItemIds = get_holds_by_status.items.map((i) => i.id);
      const isExistingHold =
        holdItemIds.length > 0 && holdItemIds.includes(itemForUpdate.id);

      if (isExistingHold) {
        if (remove) {
          get_holds_by_status.items = [...get_holds_by_status.items].filter(
            (hi) => hi.id !== itemForUpdate.id
          );
        } else {
          get_holds_by_status.items = [...get_holds_by_status.items].map((hi) =>
            hi.id === itemForUpdate.id ? { ...hi, ...itemForUpdate } : hi
          );
        }
      } else {
        if (!remove) {
          get_holds_by_status.items = [
            ...get_holds_by_status.items,
            itemForUpdate,
          ];
        }
      }

      get_holds_by_status.total_count = get_holds_by_status.items.length;

      store.writeQuery({
        ...qv,
        data: { get_holds_by_status },
      });
    }
  }
};
