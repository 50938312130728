import { gql } from '@apollo/client';
import { ShipmentCostFragment } from 'graphql/fragments';

const { AdminGraphqlQuery } = require('graphql-admin');

export const GetShipmentCosts = AdminGraphqlQuery({
  query: gql`
    query (
      $type: ShipmentCostModel
      $offset: Int
      $limit: Int
      $status: ShipmentCostStatus
      $tracking_code: String
      $start_date: String
      $end_date: String
    ) {
      get_shipment_costs(
        type: $type
        offset: $offset
        limit: $limit
        status: $status
        tracking_code: $tracking_code
        start_date: $start_date
        end_date: $end_date
      ) {
        total_count
        items {
          ...ShipmentCostFragment
        }
      }
    }
    ${ShipmentCostFragment}
  `,
});
