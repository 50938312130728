import { useState, useCallback, useEffect } from 'react';
import { useWindowEvent } from './useWindowEvent';

function serializeJSON(value) {
  try {
    return JSON.stringify(value);
  } catch (error) {
    throw new Error('@hooks use-local-storage: Failed to serialize the value');
  }
}

function deserializeJSON(value) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

/**
 * @summary use-local-storage allows you to use value from localStorage as react
 * state. Hook works exactly the same as useState, but also writes the value to
 * local storage
 * @param key
 * @param defaultValue
 * @param getInitialValueInEffect
 * @param deserialize
 * @param serialize
 * @returns {(any|string|(function(*): void))[]}
 * @example
 *
 * const [value, setValue] = useLocalStorage({ key: 'color-scheme', defaultValue: 'dark' });
 * setValue('light');
 * setValue((current) => (current === 'dark' ? 'light' : 'dark'));
 */
export function useLocalStorage({
  key,
  defaultValue = undefined,
  getInitialValueInEffect = false,
  deserialize = deserializeJSON,
  serialize = serializeJSON,
}) {
  const [value, setValue] = useState(
    typeof window !== 'undefined' &&
      'localStorage' in window &&
      !getInitialValueInEffect
      ? deserialize(window.localStorage.getItem(key) ?? undefined)
      : defaultValue ?? ''
  );

  const setLocalStorageValue = useCallback(
    (val) => {
      if (val instanceof Function) {
        setValue((current) => {
          const result = val(current);
          window.localStorage.setItem(key, serialize(result));
          window.dispatchEvent(
            new CustomEvent('nivoda-local-storage', {
              detail: { key, value: val(current) },
            })
          );
          return result;
        });
      } else {
        window.localStorage.setItem(key, serialize(val));
        window.dispatchEvent(
          new CustomEvent('nivoda-local-storage', {
            detail: { key, value: val },
          })
        );
        setValue(val);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key]
  );

  useWindowEvent('storage', (event) => {
    if (event.storageArea === window.localStorage && event.key === key) {
      setValue(deserialize(event.newValue ?? undefined));
    }
  });

  useWindowEvent('nivoda-local-storage', (event) => {
    // noinspection JSUnresolvedVariable
    if (event.detail?.key === key) {
      // noinspection JSUnresolvedVariable
      setValue(event.detail?.value);
    }
  });

  useEffect(() => {
    if (defaultValue !== undefined && value === undefined) {
      setLocalStorageValue(defaultValue);
    }
  }, [defaultValue, value, setLocalStorageValue]);

  useEffect(() => {
    if (getInitialValueInEffect) {
      setValue(
        deserialize(window.localStorage.getItem(key) ?? undefined) ||
          (defaultValue ?? '')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value === undefined ? defaultValue : value, setLocalStorageValue];
}
