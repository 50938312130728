import React from 'react';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import { CustomRadio } from '@nivoda/forms';
import { TINGE_MAPPER } from './constant';
import { BGMSelectionContainer, SelectionRadioStyle } from './styles';
import { func, object, string } from 'prop-types';
import theme from 'fabric/theme';

export const TingeSelection = ({
  label = 'Stone tinge',
  subLabelText = '',
  tingeState,
  onSelectTingeType,
  onSelectTingeValue,
  supplierTinge,
  tingeItems = {},
  status,
  locked,
  isObject = true,
  mixTinge = false,
}) => {
  const bgmItemsMapper =
    Object.keys(tingeItems)?.length === 0 ? TINGE_MAPPER : tingeItems;

  return (
    <BGMSelectionContainer className="wrapper" status={status} locked={locked}>
      <span className="wrapper__label">{label}</span>
      <div className="wrapper__header-area ">
        <div className="wrapper__header-area__tinge-wrp hide-radio-button">
          {Object.keys(bgmItemsMapper).map((tingeType) => {
            return (
              <div
                className={`wrapper__header-area__upper-tinge ${
                  Object.keys(supplierTinge).includes(tingeType)
                    ? 'supplier'
                    : ''
                }`}
                key={tingeType}
              >
                {isObject && (
                  <div className="wrapper__selection-area__label">
                    <span
                      className={classNames('label-circle', tingeType)}
                    ></span>
                  </div>
                )}
                <CustomRadio
                  key={tingeType}
                  checked={
                    Object.keys(tingeState).includes(tingeType) ||
                    (Array.isArray(tingeState) &&
                      tingeState.includes(tingeType))
                  }
                  setChecked={() => onSelectTingeType(tingeType)}
                  value={tingeType}
                  label={capitalize(tingeType.replaceAll('_', ' '))}
                  labelStyle={{
                    ...SelectionRadioStyle,
                    ...(locked &&
                      Object.keys(tingeState).includes(tingeType) && {
                        color: `${
                          status === 'QC_MISMATCH'
                            ? theme.semanticColors.labelWarn
                            : status === 'QC_PASSED'
                            ? theme.semanticColors.labelSuccess
                            : status === 'QC_REJECTED'
                            ? theme.semanticColors.labelError
                            : theme.palette.offBlack4
                        }`,
                      }),
                  }}
                />
              </div>
            );
          })}
        </div>
        {subLabelText && (
          <div className="wrapper__header-area__sub-label">{subLabelText}</div>
        )}
      </div>

      {isObject && (
        <>
          {Object.keys(tingeState).length === 1 &&
          Object.keys(tingeState)[0] === 'mix_tinge' ? null : (
            <span className="wrapper__label">Tinge Strength</span>
          )}
          {Object.keys(tingeState)?.length === 0 && (
            <div className="wrapper__no-tinge">
              Selected tinges will appear here
            </div>
          )}
          <div className="wrapper__tinge_selection">
            {Object.keys(tingeState)?.length !== 0
              ? Object.keys(tingeState).map((tingeType) => {
                  const haveKeys =
                    bgmItemsMapper[tingeType] &&
                    bgmItemsMapper[tingeType]?.length > 0;

                  return (
                    haveKeys && (
                      <div className="wrapper__selection-area" key={tingeType}>
                        {haveKeys ? (
                          <div className="wrapper__selection-area__label">
                            <span
                              className={classNames('label-circle', tingeType)}
                            ></span>
                          </div>
                        ) : null}

                        {haveKeys &&
                          bgmItemsMapper[tingeType].map((bgm) => (
                            <div
                              key={bgm.value}
                              className={`wrapper__selection-area__values hide-radio-button ${
                                supplierTinge?.[tingeType] === bgm.value
                                  ? 'supplier'
                                  : ''
                              }`}
                            >
                              <CustomRadio
                                key={bgm.value}
                                checked={bgm.value === tingeState[tingeType]}
                                setChecked={() =>
                                  onSelectTingeValue(tingeType, bgm.value)
                                }
                                value={bgm.value}
                                label={bgm.label}
                                labelStyle={{
                                  ...SelectionRadioStyle,
                                  ...(locked &&
                                    bgm.value === tingeState[tingeType] && {
                                      color: `${
                                        status === 'QC_MISMATCH'
                                          ? theme.semanticColors.labelWarn
                                          : status === 'QC_PASSED'
                                          ? theme.semanticColors.labelSuccess
                                          : status === 'QC_REJECTED'
                                          ? theme.semanticColors.labelError
                                          : theme.palette.offBlack4
                                      }`,
                                    }),
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    )
                  );
                })
              : null}
          </div>
        </>
      )}
    </BGMSelectionContainer>
  );
};

TingeSelection.propTypes = {
  label: string,
  subLabelText: string,
  tingeItems: object,
  tingeState: object.isRequired,
  onSelectTingeType: func.isRequired,
  onSelectTingeValue: func.isRequired,
};
