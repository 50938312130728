import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.object.values';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.starts-with';
import 'core-js/modules/es.array.from';
import 'core-js/modules/es.array.of';
import 'core-js/modules/es.array.is-array';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.array.find-index';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { LoadingPage } from 'fabric/components';
import { SentryGlobalErrorBoundary } from 'fabric/integrations';

// import registerServiceWorker from './registerServiceWorker';

const ErrorWrapper =
  process.env.NODE_ENV === 'production'
    ? SentryGlobalErrorBoundary
    : React.Fragment;
// const ErrorWrapper = SentryGlobalErrorBoundary;

let StrictMode =
  process.env.REACT_RUN_STRICT != null ? React.StrictMode : React.Fragment;

let render = () => {
  ReactDOM.render(
    <StrictMode>
      <React.Suspense delayMs={1000} fallback={<LoadingPage loading={true} />}>
        <ErrorWrapper>
          <App />
        </ErrorWrapper>
      </React.Suspense>
    </StrictMode>,
    document.getElementById('root')
  );
};
// registerServiceWorker();

render();
if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}

document.addEventListener(
  'touchmove',
  function (event) {
    event = event.originalEvent || event;
    if (event.scale !== 1) {
      event.preventDefault();
    }
  },
  false
);
