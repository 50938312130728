/**
 * @param {{element: HTMLInputElement, clear: boolean}} props
 * @description Executes focus for intending input field
 */
export const autoFocusHandler = (props) => {
  if (props.element && props.element.setSelectionRange) {
    if (props.clear) {
      props.element.value = '';
    }
    props.element.focus();
    props.element.setSelectionRange(
      props.element.value.length,
      props.element.value.length
    );
  }
};
