/**
 * @desc isMeleeItem checks for item type for passed product
 * @param {object<Product>} product product object --> [Diamond, Melee]
 * @return boolean
 */
export function isMeleeItem(product) {
  return product
    ? (product.ProductType || '').includes('Melee') ||
        (product.__typename || '').includes('Melee')
    : false;
}
export function isDiamondItem(product) {
  return product
    ? (product.ProductType || '').includes('Diamond') ||
        (product.__typename || '').includes('Diamond')
    : false;
}
export function isGemstoneItem(product) {
  return product
    ? (product.ProductType || '').includes('Gemstone') ||
        (product.__typename || '').includes('Gemstone')
    : false;
}

export function getOriginalSupplierStockID(Product) {
  return isGemstoneItem(Product) && Product?.gemstoneSupplierStockId
    ? Product.gemstoneSupplierStockId
    : isDiamondItem(Product) && Product.diamondSupplierStockId
    ? Product.diamondSupplierStockId
    : Product.supplierStockId;
}

export function isJewelleryItem(product) {
  return product
    ? (product.ProductType || '').includes('Jewellery') ||
        (product.__typename || '').includes('Jewellery')
    : false;
}
