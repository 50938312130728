import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { BlockHeading, CardHeading, Tooltip } from '@nivoda/ui';
import cx from 'classnames';
import { Certificate } from 'helpers';
import { customArrayOfEnumValidator } from 'utils/customArrayOfEnumValidator';
import { CopyText } from '@nivoda/common';
import theme from '../../theme';
import styled from 'styled-components';
import { DiamondAvailability } from '../../../constants';
import { RequestStatus } from '../../../components/ui/RequestStatus';
import { getOriginalSupplierStockID } from 'utils';

DiamondDescription.propTypes = {
  fontNormal: bool,
  hasClipboard: bool,
  hideOptions: arrayOf(
    customArrayOfEnumValidator.bind(null, [
      'certNumber',
      'color',
      'clarity',
      'cut',
      'fluorescence',
      'polish',
      'shape',
      'size',
      'symmetry',
      'supplierStockId',
    ])
  ),
  largeFont: bool,
  noMB: bool,
  noDMB: bool,
  product: object.isRequired,
};

const getDiamondDescriptionText = (certificate, hideOptions = []) =>
  cx(
    !hideOptions.includes('shape') &&
      (Certificate.get_shape(certificate) || '-'),
    !hideOptions.includes('cut') && (Certificate.get_cut(certificate) || '-'),
    !hideOptions.includes('polish') &&
      (Certificate.get_polish(certificate) || '-'),
    !hideOptions.includes('symmetry') &&
      (Certificate.get_symmetry(certificate) || '-'),
    !hideOptions.includes('size') && Certificate.get_size(certificate) + 'ct',
    !hideOptions.includes('color') &&
      (Certificate.get_color(certificate) || '-'),
    !hideOptions.includes('clarity') &&
      (Certificate.get_clarity(certificate) || '-'),
    !hideOptions.includes('fluorescence') &&
      (Certificate.get_fluorescence(certificate) || '-')
  );

const getDiamondDescription = (certificate, hideOptions = []) => {
  const color = Certificate.get_color(certificate);

  return (
    <Div>
      <div className="desc_row">
        <span className="desc_col">
          {!hideOptions.includes('shape') && (
            <span className="desc_cell desc_shape" title={'shape'}>
              {Certificate.get_shape(certificate) || '-'}
            </span>
          )}
          {!hideOptions.includes('cut') && (
            <span className="desc_cell desc_cut" title={'cut'}>
              {Certificate.get_cut(certificate) || '-'}
            </span>
          )}
        </span>
        <span className="desc_col">
          {!hideOptions.includes('size') && (
            <span className="desc_cell desc_size" title={'carat'}>
              {Certificate.get_size(certificate) + 'ct'}
            </span>
          )}
          {!hideOptions.includes('polish') && (
            <span className="desc_cell desc_polish" title={'polish'}>
              {Certificate.get_polish(certificate) || '-'}
            </span>
          )}
        </span>
        <span className="desc_col">
          {!hideOptions.includes('color') && (
            <span className="desc_cell desc_color" title={'color - ' + color}>
              {(color?.length > 10 && color?.toLowerCase().includes('fancy') ? (
                <Tooltip
                  placement={'top'}
                  content={<div className="tooltip__content">{color}</div>}
                >
                  <u>
                    {color
                      .split(' ')
                      .map((t, i) =>
                        i === 0 ? t : `${t.trim().substring(0, 1)}`
                      )
                      .join(' ')}
                  </u>
                </Tooltip>
              ) : (
                color
              )) || '-'}
            </span>
          )}
          {!hideOptions.includes('symmetry') && (
            <span className="desc_cell desc_symmetry" title={'symmetry'}>
              {Certificate.get_symmetry(certificate) || '-'}
            </span>
          )}
        </span>
        <span className="desc_col">
          {!hideOptions.includes('clarity') && (
            <span className="desc_cell desc_clarity" title={'clarity'}>
              {Certificate.get_clarity(certificate) || '-'}
            </span>
          )}
          {!hideOptions.includes('fluorescence') && (
            <span
              className="desc_cell desc_fluorescence"
              title={'fluorescence'}
            >
              {Certificate.get_fluorescence(certificate) || '-'}
            </span>
          )}
        </span>
      </div>
    </Div>
  );
};

const Div = styled.div`
  .desc {
    &_row {
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing.s1};
    }
    &_col {
      display: flex;
      flex-direction: column;
    }
  }
`;

/**
 * Diamond description component
 * @param copyWithoutLab {boolean} copy without Lab name for cert number clipboard
 * @param fontNormal {boolean}
 * @param hasClipboard {boolean}
 * @param hideOptions {string[]} options to hide from description info
 * @param largeFont {boolean}
 * @param noMB {boolean} no bottom margin
 * @param noDMB {boolean} no bottom margin
 * @param product {object} Product with certificate and stock ic
 * @param rest {object} spread operator
 * @param sx {string[]} styled css
 * @param smallFont {boolean}
 * @param tooltipPopperPlacements {string[]} array for defining placement for tooltip popper
 * @param v2 {boolean} use version two of product description
 * @param width {number|string} width
 * @returns {JSX.Element}
 * @constructor
 */
export function DiamondDescription({
  copyWithoutLab = false,
  fontNormal = false,
  hasClipboard = false,
  hideOptions = [],
  largeFont = false,
  noMB = false,
  noDMB = false,
  product,
  smallFont = false,
  sx = [],
  tooltipPopperPlacements = [],
  width = 'max-content',
  v2,
  ...rest
}) {
  const { certificate } = product;

  const getSubText = (text) =>
    smallFont ? (
      <span>{text}</span>
    ) : largeFont ? (
      <CardHeading fontNormal={fontNormal} noMB={noMB} text={text} {...rest} />
    ) : (
      <BlockHeading
        fontNormal={fontNormal}
        noMB={noDMB}
        text={text}
        {...rest}
      />
    );

  const description = {
    main: v2
      ? getDiamondDescription(certificate, hideOptions)
      : getDiamondDescriptionText(certificate, hideOptions),
    sub: hasClipboard ? (
      <div className={'description_box'}>
        {!hideOptions.includes('certNumber') && (
          <div className={'description_box__cert'}>
            {getSubText(cx(`${certificate.lab} ${certificate.certNumber}`))}
            <CopyText
              className={'description_box__cert--clip'}
              clipboardId={certificate.certNumber}
              copyHintText={`Copy Cert #: ${certificate.certNumber}`}
              icon={{ name: 'copyIcon', color: '#bdbdbd' }}
              placement={tooltipPopperPlacements[0] || 'top'}
              textToCopy={
                copyWithoutLab
                  ? certificate.certNumber
                  : `${certificate.lab} ${certificate.certNumber}`
              }
            />
          </div>
        )}
        {!hideOptions.includes('supplierStockId') && (
          <div className={'description_box__stock'}>
            {getSubText(cx(`Stock ID: ${getOriginalSupplierStockID(product)}`))}
            <CopyText
              className={'description_box__stock--clip'}
              clipboardId={getOriginalSupplierStockID(product)}
              copyHintText={`Copy StockId: ${getOriginalSupplierStockID(
                product
              )}`}
              icon={{ name: 'copyIcon', color: '#bdbdbd' }}
              placement={
                tooltipPopperPlacements[0] ||
                tooltipPopperPlacements[1] ||
                'top'
              }
              textToCopy={getOriginalSupplierStockID(product)}
            />
          </div>
        )}
      </div>
    ) : (
      cx(
        !hideOptions.includes('certNumber') &&
          `${certificate.lab}: ${certificate.certNumber}`,
        !hideOptions.includes('supplierStockId') && '|',
        !hideOptions.includes('supplierStockId') &&
          `Stock ID: ${product.supplierStockId}`
      )
    ),
  };

  return (
    <Span sf={smallFont} sx={sx} w={width}>
      {smallFont && <span>{description.main}</span>}
      {!smallFont &&
        (largeFont ? (
          <CardHeading
            fontNormal={fontNormal}
            noMB={noMB}
            text={description.main}
            {...rest}
          />
        ) : (
          <BlockHeading
            fontNormal={fontNormal}
            noMB={noDMB}
            text={description.main}
            {...rest}
          />
        ))}
      {description.sub}
      {product.availability === DiamondAvailability.ON_MEMO && (
        <RequestStatus name={product.availability} fitContent hideDot />
      )}
    </Span>
  );
}

DiamondDescription.getDescription = getDiamondDescription;
DiamondDescription.getDescriptionText = getDiamondDescriptionText;

const Span = styled.span`
  display: inline-flex;
  flex-direction: column;
  .description_box {
    &,
    &__cert,
    &__stock {
      display: inline-flex;
      .copy_clipboard__tooltip {
        color: ${theme.palette.white};
      }
    }

    &__cert {
      margin-right: ${theme.spacing.s1};
    }
  }
  max-width: ${({ w }) => (w && (typeof w === 'number' ? `${w}px` : w)) || ''};
  ${({ sf }) => (sf ? theme.fonts.small : '')};
  ${({ sx }) => sx};
`;
