/* eslint-disable no-undef */
/* eslint-disable no-console */

import { printerConfigVar } from './reactiveVar';
import { toast } from 'react-hot-toast';

export const MOCK_ZEBRA_PRINTER = !!process?.env?.REACT_APP_MOCK_ZEBRA_PRINTER;
const PRINTER_CONFIG_KEY = 'printerConfig';
const mockConfig = {
  name: 'Mock Printer',
  label_width: 900,
  label_height: 1200,
};
const DEFAULT_DPI = 300;

export const resetPrinterConfig = () => {
  printerConfigVar(null);
  localStorage.removeItem(PRINTER_CONFIG_KEY);
};

export const logPrinterConfig = (printerState) => {
  const { selectedDevice, label_width, label_height } = printerState;

  if (selectedDevice && label_height && label_width) {
    const width_in_inches = Math.round(label_width / DEFAULT_DPI);
    const height_in_inches = Math.round(label_height / DEFAULT_DPI);
    console.log(`DPI: ${DEFAULT_DPI}`);
    console.log(`Label width: ${width_in_inches} inches`);
    console.log(`Label height: ${height_in_inches} inches`);
    toast.success(`Connected to printer: ${selectedDevice.name}`);
    toast.success(
      `DPI: ${DEFAULT_DPI}, Label width: ${width_in_inches}, Height: ${height_in_inches} inches`,
      { duration: 4000 }
    );
    return { ...printerState };
  }

  return printerState;
};
const getLabelWidthAndHeight = (config) => {
  const width = config?.printWidth ? Number(config?.printWidth) : null;
  const height = config?.labelLength ? Number(config?.labelLength) : null;
  return { height, width };
};

export const savePrinterConfig = (config) => {
  printerConfigVar(config);
  localStorage.setItem(PRINTER_CONFIG_KEY, JSON.stringify(config));
};

export const getPrinterConfigFromLocalStorage = () => {
  const storedConfig = localStorage.getItem(PRINTER_CONFIG_KEY);
  if (storedConfig) {
    const parsedConfig = JSON.parse(storedConfig);

    printerConfigVar(parsedConfig);
    return parsedConfig;
  }
  return null;
};

const connectToPrinter = () => {
  return new Promise((resolve, reject) => {
    try {
      if (MOCK_ZEBRA_PRINTER) {
        // Mock response for testing purposes

        const printerConfig = {
          selectedDevice: {
            name: mockConfig.name,
          },
          label_width: mockConfig.label_width,
          label_height: mockConfig.label_height,
        };
        resolve(printerConfig);
      } else {
        BrowserPrint.getDefaultDevice(
          'printer',
          (device) => {
            if (device) {
              if (typeof Zebra.Printer === 'undefined') {
                toast.error(`Error: BrowserPrint SDK 1.1 not installed`);
                reject('BrowserPrint SDK not installed');
              }
              const connectedPrinter = new Zebra.Printer(device);
              connectedPrinter.getConfiguration(
                (config) => {
                  const { height, width } = getLabelWidthAndHeight(config);
                  const printerConfig = {
                    selectedDevice: device,
                    label_width: width,
                    label_height: height,
                  };
                  resolve(printerConfig);
                },
                (error) => {
                  toast.error(
                    `Error: ${error || 'Setting label width and height'}`
                  );
                  reject(error || 'Setting label width and height');
                }
              );
            } else {
              toast.error('No printer found');
              reject('No printer found');
            }
          },
          (error) => {
            toast.error(error ? `Error: ${error}` : 'Printer not configured');
            reject(error || 'Printer not configured');
          }
        );
      }
    } catch (error) {
      console.error('Error connecting to printer:', error);
      reject(error);
    }
  });
};

export const getPrinterConfig = async () => {
  const config = getPrinterConfigFromLocalStorage();
  if (config) {
    return config;
  } else {
    return connectToPrinter();
  }
};
