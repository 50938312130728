import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { featureFlagsRx } from '../../graphql/factory/reactiveVar';
import { Track } from '../integrations';
import { get_current_session } from 'graphql/factory';

export function useFeatureFlags() {
  const rxCtx = useReactiveVar(featureFlagsRx);
  const session = get_current_session();
  const [tracked, setTracked] = useState([]);

  useEffect(() => {
    const ffsFromSession = sessionStorage.getItem(rxCtx.featureFlagStorageKey);
    if (ffsFromSession == null) {
      // Tracker to limit calls to the service to a maximum of 10 in one hour
      // if the feature flag service fails.
      const ffLog = JSON.parse(localStorage.getItem('ffLog'));
      if (
        !ffLog ||
        (typeof ffLog === 'object' &&
          ffLog.retries < 10 &&
          Date.now() - ffLog.firstCallAt < ffLog.timeout)
      ) {
        // Init tracker
        if (!ffLog) {
          localStorage.setItem(
            'ffLog',
            JSON.stringify({
              retries: 0,
              firstCallAt: Date.now(),
              timeout: 3600000,
            })
          );
        } else {
          // Update call tracker
          localStorage.setItem(
            'ffLog',
            JSON.stringify({
              ...(ffLog ? ffLog : {}),
              retries: ffLog.retries + 1,
            })
          );
        }
        rxCtx
          .fetch(session)
          .then(function (response) {
            const apiRes = response?.data;

            if (response.status === 200 && typeof apiRes === 'object') {
              for (const featureName in apiRes) {
                // Feature is enabled
                if (apiRes[featureName]) {
                  // Push mixpanel tracking for this feature
                  if (rxCtx.user && !tracked.includes(featureName)) {
                    Track.track(`Feature > ${featureName} > enabled`, {
                      c_id: rxCtx.user.company?.id,
                      u_id: rxCtx.user.id,
                    });
                    setTracked((prev) => [...prev, featureName]);
                  }
                }
              }

              const fetchedAt = new Date();
              // Update storage (LS + SS)
              rxCtx.updateLS(apiRes);
              sessionStorage.setItem(
                rxCtx.featureFlagStorageKey,
                fetchedAt.toString()
              );
              // Updates Reactive store
              // noinspection JSCheckFunctionSignatures
              featureFlagsRx({
                ...rxCtx,
                featureFlags: apiRes,
                ffsFromSession: fetchedAt.toString(),
              });
            }
          })
          .catch(function (err) {
            // Reset FF storage
            rxCtx.resetFFStorage();
            // Reset the state store
            featureFlagsRx(rxCtx);

            // eslint-disable-next-line no-console
            console.error(
              `Something went wrong while fetching feature flag data ${
                ffLog?.retries || ''
              }`,
              err
            );
          });
      }
    }
  }, [rxCtx, session, tracked]);

  return rxCtx;
}
