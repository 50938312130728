// noinspection JSCommentMatchesSignature

import { noop } from 'lodash/util';
import { bool, func, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

/**
 * Expanding icon component. Uses `+` sign for expanded state and `-` sign  for
 * collapsed state
 * @param {string} className root className
 * @param {boolean} expanded expanded state
 * @param {function} onClick function to be called on on click
 * @param {string} title ally title
 * @param {spread} rest
 * @returns {JSX.Element}
 * @constructor
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 */
export function RowExpanderIcon(props) {
  const {
    className = '',
    expanded = false,
    onClick = noop,
    title = '',
    ...rest
  } = props;

  return (
    <Span
      className={`${className} ${expanded ? '__expanded' : ''}`}
      onClick={onClick}
      title={
        title
          ? title
          : expanded
          ? 'Click to collapse row'
          : 'Click to expand row'
      }
      {...rest}
    >
      {/* \u002D --> +  AND \u002B --> - */}
      {expanded ? '\u002D' : '\u002B'}
    </Span>
  );
}

RowExpanderIcon.propTypes = {
  className: string,
  expanded: bool.isRequired,
  onClick: func,
  title: string,
};

const Span = styled.span`
  align-items: center;
  border: ${theme.semanticProps.inputBorder};
  border-radius: ${theme.effects.roundedCorner3};
  color: ${theme.palette.gray2};
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  padding-bottom: 2px;
  width: 22px;
  ${theme.fonts.absolute.xxLargePlus};

  &.__expanded,
  &:hover {
    color: ${theme.palette.blackPrimary};
  }
`;
