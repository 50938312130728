import { Button } from '@nivoda/ui';
import cx from 'classnames';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../theme';

export function ButtonGroup(props) {
  const [activeAction, setActiveAction] = React.useState(
    props?.activeTab ? props?.activeTab : props.list[0]?.id
  );

  const handleBtnClick = (e, action) => {
    const btnId = e.target?.dataset?.actionId;
    if (typeof action.onClick === 'function') {
      action.onClick(btnId);
    }
    setActiveAction(btnId);
  };

  useEffect(() => {
    if (props?.activeTab && props?.activeTab !== activeAction) {
      setActiveAction(props?.activeTab);
    }
  }, [activeAction, props?.activeTab]);

  return (
    <BtnWrapper className={props.className}>
      {props.list.map((action, i) => (
        <Button
          key={action.id}
          className={cx(
            `btn${i + 1}`,
            // Styles when there's only one button
            props.list.length === 1 && 'bgi1',
            // Styles when there are 2 buttons
            props.list.length === 2 && 'bgi2',
            activeAction === action.id && 'active',
            action.className
          )}
          disabled={props.disabled}
          onClick={(e) => handleBtnClick(e, action)}
          data-action-id={action.id}
          customPadding={props.customPadding}
        >
          {action.label}
        </Button>
      ))}
    </BtnWrapper>
  );
}

const BtnWrapper = styled.div`
  display: flex;
  .btn {
    flex: 1;
    margin: 0;
    background-color: ${theme.palette.white} !important;
    color: ${theme.palette.themePrimary} !important;
    border-radius: 0 !important;
    border: 1px solid ${theme.palette.themePrimary};
    &.active {
      background-color: ${theme.palette.themePrimary} !important;
      color: ${theme.palette.white} !important;
    }

    &:disabled {
      border: 1px solid ${theme.palette.themePrimary};
      border-radius: 0;
    }

    &,
    &:disabled {
      border: 1px solid ${theme.palette.themePrimary};
    }

    &:disabled:first-child,
    &:first-child {
      border-top-left-radius: ${theme.effects.roundedCorner4} !important;
      border-bottom-left-radius: ${theme.effects.roundedCorner4} !important;
      border-right: none !important;
    }

    &:disabled:last-child,
    &:last-child {
      border-top-right-radius: ${theme.effects.roundedCorner4} !important;
      border-bottom-right-radius: ${theme.effects.roundedCorner4} !important;
      border-left: none !important;
    }

    &.bgi1 {
      border-radius: ${theme.effects.roundedCorner4} !important;
      border: 1px solid ${theme.palette.themePrimary} !important;
    }

    &.bgi2 {
      border-right: 1px solid ${theme.palette.themePrimary};
    }
  }
`;
