export const DiamondAvailability = {
  AVAILABLE: 'AVAILABLE',
  CONSIGNMENT: 'CONSIGNMENT',
  HOLD: 'HOLD',
  MEMO: 'MEMO',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  ON_HOLD: 'ON_HOLD',
  ON_MEMO: 'ON_MEMO',
  SOLD: 'SOLD',
  SOLD_OUT: 'SOLD_OUT',
};
