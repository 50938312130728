export const TABS = [
  {
    id: 'RECENT_SEARCH',
    icon: 'update_search',
    title: 'recent_search',
    contentTitle: 'recent',
    analyticsTitle: 'RecentSearch',
  },
  {
    id: 'DIAMOND_NATURAL',
    icon: 'meleeIcon',
    title: 'diamonds_natural',
    contentTitle: 'diamonds',
    analyticsTitle: 'NaturalDiamond',
  },
  {
    id: 'DIAMOND_LABGROWN',
    icon: 'lab',
    contentTitle: 'diamonds',
    title: 'diamonds_labgrown',
    analyticsTitle: 'LabGrownDiamond',
  },
  {
    id: 'GEMSTONE',
    icon: 'gemStones',
    title: 'gemstones',
    analyticsTitle: 'Gemstone',
  },
  {
    id: 'MELEE_NATURAL',
    icon: 'meleeIcon',
    title: 'melee_natural',
    analyticsTitle: 'NaturalMelee',
  },
  {
    id: 'MELEE_LABGROWN',
    icon: 'lab',
    title: 'melee_labgrown',
    analyticsTitle: 'LabgrownMelee',
  },
  {
    id: 'ORDER',
    icon: 'order_list',
    title: 'orders',
    analyticsTitle: 'Orders',
  },
  {
    id: 'INVOICE',
    icon: 'invoices',
    title: 'invoices',
  },
  {
    id: 'FINANCE',
    icon: 'invoices',
    title: 'finances',
    analyticsTitle: 'Invoices',
  },
  {
    id: 'SHORTLIST',
    icon: 'heart',
    title: 'shortlist',
    analyticsTitle: 'Shortlist',
  },
  {
    id: 'DIAMOND_REQUEST',
    icon: 'infoCircle',
    title: 'requests',
    contentTitle: 'request',
    analyticsTitle: 'DiamondRequest',
  },
  {
    id: 'HOLD',
    icon: 'menu-hold',
    title: 'holds',
    contentTitle: 'holds',
    analyticsTitle: 'Holds',
  },
];

export const renderTitle = (translate, tabId, title) => {
  switch (tabId) {
    case 'RECENT_SEARCH':
      return `${translate('recent_search')}`;
    case 'DIAMOND_NATURAL':
      return `${translate('natural_diamonds')}`;
    case 'DIAMOND_LABGROWN':
      return `${translate('labgrown_diamonds')}`;
    case 'MELEE_NATURAL':
      return `${translate('natural_melee')}`;
    case 'MELEE_LABGROWN':
      return `${translate('lab_melee')}`;
    default:
      return translate(title);
  }
};
