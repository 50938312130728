import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';
import { CertificateMiniFragment } from '../../fragments';

export const GetExpressItems = AdminGraphqlQuery({
  query: gql`
    query (
      $carat: [Float]
      $cert_number: [String]
      $colour: [String]
      $location: [ID]
      $offset: Int
      $stock_id: [String]
      $type: ProductType!
    ) {
      search_fbn_items(
        cert_number: $cert_number
        stock_id: $stock_id
        type: $type
        location: $location
        colour: $colour
        carat: $carat
        offset: $offset
        limit: 24
      ) {
        items {
          ... on Diamond {
            id
            has_bgmec_enquiry
            # image
            location {
              id
              name
              city
              country
            }
            certificate {
              ...CertificateMini
              depth
              width
              length
              image
            }
            supplierStockId
            diamondSupplierStockId
            supplier {
              id
              name
            }
            offer {
              id
            }
          }
        }
        total_count
        not_found {
          cert_no
          stock_id
          not_found_count
        }
      }
    }
    ${CertificateMiniFragment}
  `,
});

export let FetchSCMPreferenceList = AdminGraphqlQuery({
  query: gql`
    query {
      spacecode_preference_settings_list {
        id
        country
        drawer_service_url
        delay_milliseconds
        location_id
        middleware_url
        name
        status
      }
    }
  `,
});

export let SPACE_CODE_PREFERENCE_SETTINGS_LIST = gql`
  query spacecodePreferenceSettingsList {
    spacecode_preference_settings_list {
      id
      country
      drawer_service_url
      delay_milliseconds
      location_id
      middleware_url
      name
      status
    }
  }
`;

export let ADMIN_GET_INVENTORY_IDENTIFIERS = gql`
  query adminGetInventoryIdentifiers($country: String) {
    admin_get_inventory_identifiers(country: $country) {
      id
      name
      linked_location {
        id
        name
        country
      }
    }
  }
`;

export let SOURCING_TYPES = gql`
  query admin_sourcing_types {
    admin_get_sourcing_type
  }
`;
