// noinspection SpellCheckingInspection

/**
 * @summary centralize global / component level admin configurations
 *  1. default - default configuration (includes india & rest of the places)
 *  2. GB - configuration for london
 *  3. NL - configuration for netherlands
 *
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 **/
export const adminAllowedUserConfig = Object.freeze({
  default: Object.freeze({
    orders: Object.freeze({
      // Users will appear on FE in same order as their index position in following list
      allowedAssignToUsers: [
        '70f53075-513f-4e29-8db9-4287bcff1acd', // Saurabh Shah
        'e6598c89-c123-4d81-af26-d6047584034b', // Kairav Dholakia
        '56016fa3-a7c7-463e-a755-6ec1da7b0f29', // Divyesh Modi
        '596d2ca7-1233-45f6-abf4-57474741dc2c', // Akhil S
        '966fe63e-59d2-44b6-9a39-95c7be9cf762', // Shruti Sardal
        'ac551b1d-4379-4a97-8b65-2e393ae203e1', // Keerti Saseendran
        '3863fe8f-5107-43cc-ba11-4023f8d577c0', // Tanisha Kapale
        '4fd444b2-a6ff-4f72-b5e5-205bb365cd2f', // Keerthivasan K
        '31538ed8-7f7a-4d40-b885-8e27fe7b3fb1', // Swapnil Shah
        'e8f3a62b-0820-4c2e-83d6-0f51ee1798ea', // Abhijeet Das
        '64684eaf-43a2-44cf-8ca2-10fea39d6834', // Archana Bhutada
        'cf15208e-e623-48a8-b396-a5e20aedf38d', // Roque Dsouza
        '27ba0f54-57b3-4487-a243-760f04a9c3ce', // Nikhil More
        '10ffdbce-90d3-470e-ae3c-c026df2ace26', // Roshna Nair
        '4abcdec7-d723-4195-9e41-0169c2f5fffe', // Priyam Verma
        'a0dd8e15-314c-4b53-ad77-5733f6d4bcc2', // Geeta Lakheda
        '0d7b2738-1b57-485b-ba24-b2601d706ef7', // Aashish Pattani
        '44088dd7-ead9-48f6-85a7-c2fd7f74e5a4', // Neha Kumari
        '65fcf255-8121-4d35-b3c0-9575b40ad666', // Aneeta Thomas
        '87f9cb1d-a1e1-4d91-9822-e9039c70cf5a', // Vaishali Bhalekar
        'a80768f0-8370-4434-bab2-53232acc4242', //  Abha Jaiswal
        '82471bc0-63cf-4f2a-b1c1-e94225964f79', //  Vidya Jaiswar
        '9d158884-339e-4dc2-99cf-9acc83bd466e', // Aman Raj
        '42842b43-1489-4e1f-86e8-b545eb38c264', // Ashish Singh
        'fdc7ab5f-0ebe-4a27-ad3e-6e2a9b51c2f6', // Aathira Nandakumar
        '0ed09344-0e68-44e3-9def-b0dffb84a344', // Pranay Karekar
        '7d0d1001-f745-45dd-9321-195da6e924b2', // Atreyee Sil
        '3f3f7fdd-8a95-458c-b031-0e5a02d643d1', // Pawan Lulla
        '49f9d2b8-b427-417a-9627-1569e3bd1993', // Deepak Belwalkar
        'f8915928-be31-4fb1-ad6c-00266e8c9224', // Sakshi Shetty
        'a75ec463-95c3-4d1b-8f57-bd636d59528f', // Shalvin Shah
        '50eacb64-abb6-46d9-b8bd-b7527fefe3ed', // Bharti Binani
        'ba79cf92-260b-4961-9041-e87faaf54153', // Cayla Wong
        '5d3112ea-2953-4f9b-b90e-c77c41c93318', // Bhavik Shah
        'c99a63b9-6b2f-4585-928e-7e7b6c067902', // Andre Woons
        '21baa21f-557e-4a6f-9ac9-04d7290b69a0', // Joseph Ducrocq
        'e3b48ef2-7c17-4ca5-bc05-3e1fc8aaae06', // Prince Verma
        '1faddba8-6881-4b6a-ae86-6ff860f11534', // Humerasaba Shaikh
        '96d633a3-d177-44f9-ba1f-bb2c3828304d', // Poonam Jaiswar
        'afc5d78e-413a-4223-8e5c-2a6557abb4cf', // Shubham Jadhav
        '040591e0-f059-482d-b690-48d92ffed143', // Manisha Sharma
        '77b885f9-ebca-4e37-a7a3-9c593f2a207b', // Mitra Khodani
        'd3a89018-cb27-44ac-a157-6aeb9a2b58a9', // Maitri Vejani
        'bdc54bb9-ecba-42e8-8f8b-2754b3a2b5be', // Sahiba Shaikh
        '207d6452-c3a3-4237-b2f6-2fe7ae6965a1', // Dattatray Sawant
        'bedfeaa7-b01f-4567-8781-56537719296b', // Karthick S
        '5a84bc93-2930-456a-a3a0-74b63da57081', // Ancy Thomas
        'c4664ac4-67dd-42aa-9208-73ef03986c4f', // Shakliyan Shaikh
      ],
    }),
    suppliers: Object.freeze({
      sales_person: [
        'af986172-1e4b-4c07-ad27-fa0acee261b2', // Neeta Maru
        '62106258-686d-41a2-9bf9-98a6bb30d32d', // Ayesha Basu
        '95cbff9a-e88f-4400-9559-9bec1d4372b6', // Faiz Khan
        '0f584dc9-cde8-4c2a-aede-cf4429ffa6c7', // Loren Baum
      ],
      account_manager: [
        '2a67c65d-f9e1-4420-ae13-1c608a72dfea', // Tina Dey
        '4d8ac93b-6b1a-4121-9d21-88907fe15f36', // Astha Chaubey
        'a74ed8b2-65a0-4f07-ab14-3ea6f7e4a34d', // Prerna Dsouza
        '59a7744f-f26d-44be-8a0b-39737378905b', // Alfiya Patel
        '1f8cf0c4-be22-4140-80cb-cd8eaa24f095', // Shriya Vijan
        '2a67c65d-f9e1-4420-ae13-1c608a72dfeb', // Simran Nainani
        '6ab13c7f-c14b-4b6c-a43d-89b531b36bcf', // Kasturi Inamdar
      ],
    }),
  }),
});
