export let DEFAULT_QUERY = {
  dollar_value: [0, 0],
  dollar_per_carat: [0, 0],
  sizes: [['', '']],
  sizeRangeFrom: '',
  sizeRangeTo: '',
  shapes: [],
  cut: [],
  polish: [],
  symmetry: [],
  flouresence: [],
  flouresence_color: [],
  color: [],
  clarity: [],
  luster: [],
  brown: [],
  green: [],
  gray: [],
  blue: [],
  other: [],
  eyeClean: [],
  sub: true, // show unknown bgm
  sue: true, // show unknown eyeclean
  suk: true, // show unknown key to symbols
  sul: true, // show unknown luster
  girdle: [],
  culet: [],
  table_percentage: ['', ''],
  depth_percentage: ['', ''],
  length_mm: ['', ''],
  width_mm: ['', ''],
  depth_mm: ['', ''],
  ratio: ['', ''],
  suppliers: [],
  fancyColor: [],
  fancyIntensity: [],
  fancyOvertone: [],
  certificate_numbers: '',
  delivery_time: [],
  location: [],
  order: { type: 'price', direction: 'ASC' },
  certificate_lab: [],
  mine_to_market: [],
  mine_of_origin: [],
  key_to_symbols: [],
  key_to_symbols_contains: false,
  has_image: null,
  has_v360: null,
  star_length: ['', ''],
  crown_angle: ['', ''],
  pav_angle: ['', ''],
  // diameter: ['', ''],
  // star_length: ['', ''],
  lower_half: ['', ''],
  // extra_facet_table: [],
  // extra_facet_crown: [],
  // extra_facet_pavilion: [],
  // heart_and_arrow: [],
  rjc: false,
  inclusion_color: [],
  inclusion_table: [],
  inclusion_sides: [],
  open_inclusion_table: [],
  open_inclusion_sides: [],
  labgrown: false,
  labgrown_type: [],
  treated: false,
  returns: false,
  hide_memo: true,
  is_fbn: false,
  is_memo: false,
  post_growth: false,
  sustainability_rated_diamond: false,
  hearts_arrows: false,
};

export const CUSTOM_QUERY_COMPARATOR = {
  cut: [],
  polish: [],
  symmetry: [],
  color: [],
  sizes: [null],
  shapes: [],
  flouresence: [],
  flouresence_color: [],
  clarity: [],
  girdle: [],
  culet: [],
  lower_half: null,
  dollar_value: null,
  dollar_per_carat: null,
  table_percentage: null,
  depth_percentage: null,
  length_mm: null,
  width_mm: null,
  depth_mm: null,
  pav_angle: null,
  crown_angle: null,
  star_length: null,
  ratio: null,
  has_image: null,
  has_v360: null,
  luster: [],
  eyeClean: [],
  brown: [],
  green: [],
  gray: [],
  blue: [],
  other: [],
  sub: true,
  sue: true,
  suk: true,
  sul: true,
  certificate_lab: [],
  suppliers: [],
  fancyColor: [],
  fancyIntensity: [],
  fancyOvertone: [],
  key_to_symbols: [],
  mine_of_origin: [],
  mine_to_market: [],
  location: [],
  delivery_time: [],
  inclusion_color: [],
  inclusion_table: [],
  inclusion_sides: [],
  open_inclusion_table: [],
  open_inclusion_sides: [],
  treated: false,
  returns: false,
  rjc: false,
  hide_memo: true,
  is_fbn: false,
  post_growth: false,
  labgrown_type: [],
  sustainability_rated_diamond: false,
  hearts_arrows: false,
};
