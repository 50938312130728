import { gql } from '@apollo/client';

export const NeedCertsFragment = gql`
  fragment NeedCertificates on NeedCertDiamond {
    total_count
    items {
      id
      certNumber
      stockId
      type
      reason
      lab
    }
  }
`;
export const NeedCertsGemstonesFragment = gql`
  fragment NeedCertificates on NeedCertDiamond {
    total_count
    items {
      id
      certNumber
      stockId
      type
      reason
      lab
    }
  }
`;

export const CertMismatchFragment = gql`
  fragment CertMismatches on CertMismatchDiamond {
    total_count
    items {
      id
      certNumber
      stockId
      type
      reason
      lab
    }
  }
`;

export const PriceMismatchFragment = gql`
  fragment PriceMismatches on PriceMismatchDiamond {
    total_count
    items {
      id
      stockId
      lab
      provided_price
      provided_discount
      calculated_price_by_nivoda
      reference_rap_price
      certificate_number
      human_reason
    }
  }
`;

export const DuplicateStonesFragment = gql`
  fragment Duplicates on DuplicateStones {
    total_count
    items {
      id
      stockId
      reason
      certNumber
      lab
      SupplierName
    }
  }
`;

export const DuplicateStonesFragmentSupplier = gql`
  fragment DuplicatesSupplier on DuplicateStones {
    total_count
    items {
      id
      stockId
      reason
      certNumber
      lab
    }
  }
`;

export const OrderedStoneFragment = gql`
  fragment OrderedStones on OrderedDiamond {
    total_count
    items {
      id
      stockId
      type
      certNumber
      lab
    }
  }
`;

export const OrderedGemstoneFragment = gql`
  fragment OrderedStones on OrderedDiamond {
    total_count
    items {
      id
      stockId
      type
      certNumber
      lab
    }
  }
`;

export const FeedErrorDiamondFragment = gql`
  fragment FeedError on FeedErrorDiamond {
    total_count
    items {
      stock_id
      json
      text
    }
  }
`;

export const FeedErrorGemstoneFragment = gql`
  fragment FeedError on FeedErrorDiamond {
    total_count
    items {
      stock_id
      json
      text
    }
  }
`;
