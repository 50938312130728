import { gql } from '@apollo/client';
import {
  CertificateInfoFragment,
  NivodaExpressRequestsFragment,
  NoteFragment,
} from 'graphql/fragments';

export const ADMIN_GET_EXPRESS_REQUESTED_STONES = gql`
  query adminGetExpressRequestedStones(
    $query: ExpressRequestInventoryInput
    $count_only: Boolean
  ) {
    admin_get_express_request_items(query: $query, count_only: $count_only) {
      total_count
      items {
        id
        createdAt
        status
        parent_express_request {
          id
          consignment_number
          requested_date
          status
          stones_requested
          stones_accepted
          total_value
          destination {
            id
            country
            city
          }
          express_request_items {
            id
            status
            is_deleted
          }
          requested_by {
            id
            firstName
            lastName
          }
          supplier {
            id
            name
            country
          }
          notes {
            ...AdminNote
          }
        }
      }
    }
  }
  ${NoteFragment}
`;

export const ADMIN_GET_EXPRESS_REQUEST_ITEMS = gql`
  query adminGetExpressRequestItems(
    $query: ExpressRequestInventoryInput
    $offset: Int
    $limit: Int
    $count_only: Boolean
  ) {
    admin_get_express_request_items(
      query: $query
      count_only: $count_only
      limit: $limit
      offset: $offset
    ) {
      total_count
      items {
        id
        createdAt
        status
        Product {
          ... on Diamond {
            id
            availability
            brown
            green
            other
            gray
            blue
            milky
            bowtie
            eyeClean
            supplierStockId
            diamondSupplierStockId
            supplier {
              id
              name
              country
            }
            offer {
              id
              product_price
            }
            certificate {
              ...CertificateInfo
            }
          }
        }
        ProductType
        parent_express_request {
          id
          consignment_number
          consignment_form {
            id
            form_url
          }
          destination {
            id
            country
            city
          }
          notes {
            ...AdminNote
          }
          requested_by {
            id
            firstName
            lastName
          }
          requested_date
          status
          stones_accepted
        }
      }
    }
  }
  ${CertificateInfoFragment}
  ${NoteFragment}
`;

export const ADMIN_GET_EXPRESS_REQUEST_ITEMS_FILTERS_DATA = gql`
  query adminGetExpressRequestItemsFiltersData(
    $count_only: Boolean
    $query: ExpressRequestInventoryInput
  ) {
    admin_get_express_request_items(count_only: $count_only, query: $query) {
      requested_count
      processing_count
      in_express_inventory_count
      pending_return_count
      returned_to_supplier_count
      suppliers_list {
        id
        name
      }
      requesters_list {
        id
        firstName
        lastName
      }
      destinations_list {
        id
        country
      }
    }
  }
`;

export const ADMIN_GET_EXPRESS_REQUEST_BY_ID = gql`
  query adminGetExpressRequestById($express_request_id: ID!) {
    admin_get_express_request_by_id(express_request_id: $express_request_id) {
      ...NivodaExpressRequestsFragment
      requested_by {
        id
        firstName
        lastName
      }
      supplier {
        id
        name
        country
      }
    }
  }
  ${NivodaExpressRequestsFragment}
`;

export const ADMIN_GET_EXPRESS_REQUEST_HISTORY = gql`
  query adminGetExpressRequestHistory($express_request_id: ID!) {
    admin_get_express_request_by_id(express_request_id: $express_request_id) {
      id
      consignment_form {
        id
        form_url
      }
      updates {
        id
        type
        value_name
        value_from
        value_to
        reverse
        action_taken
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ADMIN_GET_EXPRESS_REQUEST_RETURN_REASONS = gql`
  query adminGetExpressRequestReturnReasons($type: ReturnReasonType) {
    get_express_request_return_reasons(type: $type) {
      id
      reason
      type
    }
  }
`;
