import { capitalize } from 'lodash';
// noinspection JSValidateTypes
/**
 * Helps us by checking the text type before calling the Translation
 * @param t {TFunction<"translation", undefined>}
 * @param txt {string|*}
 * @param fallbackText {string|*}
 * @returns {string|*}
 */
export const tFn = (t, txt, fallbackText) =>
  typeof t === 'function' && typeof txt === 'string'
    ? t(txt)
    : typeof txt === 'string' && typeof fallbackText === 'string'
    ? fallbackText
    : typeof txt === 'string'
    ? capitalize(txt)
    : txt;
