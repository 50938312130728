import { Popup, Tooltip } from '@nivoda/ui';
import {
  TabBarWrapper,
  MarkupModalWrapper,
  SelectionBtn,
  FixLastRowWrapper,
} from './style';
import { Icon } from 'fabric/resources/icon';
import { CustomTrans } from 'helpers';
import { sortBy, startCase } from 'lodash';
import {
  MARKUP_TABS,
  PRICE_DIFFERENCE,
  CARAT_DIFFERENCE,
  DEFAULT_MARKUPS,
  CURRENCY,
} from './constants';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { theme } from 'fabric/theme';
import './style.css';
import { graphqlClient } from 'graphql/factory';
import { Button } from 'fabric/components';
import {
  SAVE_MARKUP_SETTINGS,
  ADD_COMPANY_MARKUP,
} from 'graphql/mutations/common';
import { useErrorWrapper } from 'fabric/hooks';
import {
  check_all_fields_have_value,
  Sanitize_markup_data,
  check_range_gaps,
  generate_Id,
} from './helpers';
import { Input } from './markupInput';
import { WithScrollbar } from '@nivoda/ui';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';
import { useRowData } from './hooks/useRowData';
import { APPLY_ON_OPTIONS } from './constants';
import { CustomReactSelect } from 'fabric/components/CustomReactSelect';
export const MarkupTabBar = ({
  tabsList,
  activeTab,
  setActiveTab,
  diabledTab,
}) => {
  return (
    <TabBarWrapper>
      {tabsList.length > 0 &&
        tabsList.map((tab) => (
          <div
            key={tab.tab_id}
            className={`tab_button ${
              activeTab?.tab_id === tab.tab_id && 'active_tab'
            } ${
              diabledTab && diabledTab?.tab_id === tab?.tab_id && 'disabled_tab'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab?.iconProps && <Icon {...tab.iconProps} />}
            <span>
              <CustomTrans
                text={tab.name}
                returnPlainText={true}
                fallBack={startCase(tab.name?.replace('_', ' '))}
              />
            </span>
          </div>
        ))}
    </TabBarWrapper>
  );
};

export const MarkupModal = ({
  onClose,
  data,
  isLoading,
  refetchData,
  cb,
  ProductType = '',
}) => {
  let {
    me: {
      company: { cfm_settings },
    },
  } = data;
  const {
    activeTab,
    setActiveTab,
    markup_settings,
    set_markup_settings,
    editable_rows,
    set_editable_row,
    Markups,
    is_rows_changed,
  } = useRowData(
    cfm_settings,
    MARKUP_TABS.find((ele) => ele.tab_id === ProductType)
  );
  const [error, set_error] = useState({});
  const [fnWrapper, context] = useErrorWrapper({ useToastNotification: true });
  const [add_markups, { loading: save_markups_loading }] = useMutation(
    ADD_COMPANY_MARKUP,
    {
      client: graphqlClient,
    }
  );
  const [save_cfm_settings, { loading: save_cfm_loading }] = useMutation(
    SAVE_MARKUP_SETTINGS,
    {
      client: graphqlClient,
    }
  );
  const [fix_last_row, set_fix_last_row] = useState(false);

  let is_carat = markup_settings?.markup_preference?.value === 'carats';

  const handleMarkupSettingsChanges = (key, item) => {
    if (key === 'markup_preference') {
      const new_markups = sortBy(
        Markups.filter(
          (ele) =>
            ele.ProductSubtype === activeTab.tab_id &&
            ele.ProductType === activeTab.product &&
            ele.markup_type === item.value
        ),
        'from_amount'
      );
      set_editable_row(new_markups);
    }
    set_markup_settings({ ...markup_settings, [key]: item });
  };

  let update_next_row = async (rows, id, value) => {
    let new_row = rows.map((row) => {
      return row.id === id
        ? {
            ...row,
            from_amount: value,
          }
        : row;
    });
    await set_editable_row(new_row);
    return new_row;
  };

  const addFormFields = () => {
    let highest_to_amount =
      editable_rows.length > 0
        ? Math.max(...editable_rows.map((r) => r.to_amount))
        : 0;

    const item = {
      is_new: true,
      id: generate_Id(editable_rows.length + 1, {
        ProductSubtype: activeTab.tab_id,
        ProductType: activeTab.product,
        markup_type: markup_settings?.markup_preference.value,
        extra_text: Math.random().toString(36).substring(2, 7),
      }),
      from_amount:
        highest_to_amount +
        (markup_settings?.markup_preference.value === 'amount'
          ? PRICE_DIFFERENCE
          : CARAT_DIFFERENCE),
      to_amount: '',
      percent:
        editable_rows.length > 0
          ? editable_rows[editable_rows.length - 1].percent
          : '',
      ProductSubtype: activeTab.tab_id,
      ProductType: activeTab.product,
      markup_type: markup_settings?.markup_preference?.value,
    };
    set_editable_row([...editable_rows, item]);
  };

  const reset_to_default = async () => {
    let data =
      DEFAULT_MARKUPS[activeTab.product][activeTab.tab_id][
        markup_settings?.markup_preference?.value
      ];
    if (!isEmpty(data)) {
      set_editable_row(data);
    }
  };

  const removeFormFields = async (id_to_remove) => {
    let indexOfDeletedRow = editable_rows.findIndex(
      (r) => r.id === id_to_remove
    );
    let new_rows = editable_rows.filter((r) => r.id !== id_to_remove);

    if (indexOfDeletedRow === 0) {
      await update_next_row(
        new_rows,
        editable_rows[indexOfDeletedRow + 1].id,
        0.01
      );
    } else {
      await update_next_row(
        new_rows,
        editable_rows[indexOfDeletedRow + 1].id,
        parseFloat(
          typeof editable_rows[indexOfDeletedRow - 1].to_amount == 'number'
            ? editable_rows[indexOfDeletedRow - 1].to_amount
            : editable_rows[indexOfDeletedRow - 1].to_amount.replace(',', '')
        ) + 0.01
      );
    }
    set_error({});
  };

  const handleChange = async ({ value, id, field }) => {
    set_error({});
    let new_row = editable_rows?.map((row) => {
      return row.id === id
        ? {
            ...row,
            [field]: value,
          }
        : row;
    });
    await set_editable_row(new_row);
  };

  const handleBlur = async ({ id, next_row_id, field, field_value }) => {
    let {
      status,
      error: sanitize_error,
      id: error_id,
      error_type,
    } = Sanitize_markup_data(id, editable_rows, is_carat);

    if (status === 'success') {
      if (!isEmpty(error)) {
        set_error({});
      }
      if (field === 'to_amount') {
        let update_editable_row = editable_rows.map((row) => {
          return row.id === next_row_id
            ? {
                ...row,
                from_amount:
                  parseFloat(field_value.replace(',', '')) +
                  (markup_settings?.markup_preference?.value === 'amount'
                    ? PRICE_DIFFERENCE
                    : CARAT_DIFFERENCE),
              }
            : row;
        });
        await set_editable_row(update_editable_row);
      }
    } else {
      if (
        field === 'to_amount' &&
        (error_type === 'TO_INVALID' ||
          error_type === 'FROM_INVALID' ||
          error_type === 'OVER_LAP' ||
          error_type === 'FROM_GREATER_THEN_TO')
      ) {
        set_error({
          field,
          error: sanitize_error,
          id: error_id ? error_id : id,
        });
      }
      if (
        field === 'percent' &&
        (error_type === 'MARKUP_PERCENT_INVALID' ||
          error_type === 'PERCENT_INVALID')
      ) {
        set_error({
          field,
          error: sanitize_error,
          id: error_id ? error_id : id,
        });
      }
    }
  };

  const applyChanges = async () => {
    // const markups = [...editable_rows];
    const markup_preference = markup_settings?.markup_preference?.value;
    const markup_currency = markup_settings?.markup_currency?.value;

    let update_markups = editable_rows.map(
      ({
        from_amount,
        to_amount,
        percent,
        ProductType,
        ProductSubtype,
        markup_type,
      }) => {
        return {
          from_amount,
          to_amount,
          percent,
          ProductType,
          ProductSubtype,
          markup_type,
        };
      }
    );
    let is_valid = check_all_fields_have_value(editable_rows);
    let range_gap = check_range_gaps(editable_rows, is_carat);

    let bad_row = editable_rows.findIndex((row, index) => {
      return index > 0
        ? +(+row.from_amount - +editable_rows[index - 1].to_amount)?.toFixed(
            2
          ) >
            markup_settings?.markup_preference?.value ===
          'amount'
          ? PRICE_DIFFERENCE
          : CARAT_DIFFERENCE
        : 0;
    });
    if (range_gap) {
      set_error({
        status: 'error',
        error: `One or more range gap is invalid. The gap between this row and the previous row is greater than ${
          markup_settings?.markup_preference?.value === 'amount'
            ? PRICE_DIFFERENCE
            : CARAT_DIFFERENCE
        }`,
        id: bad_row >= 0 ? editable_rows[bad_row].id : null,
        error_type: 'ONE_ARE_MORE_RANGE_GAP',
      });
      return false;
    }

    if (!is_valid) {
      set_error({
        field: 'all',
        error: 'one_or_more_field_are_empty',
        error_type: 'ALL_ERROR',
      });
      return false;
    }

    if (
      Number(editable_rows[0].from_amount) < 0 ||
      Number(editable_rows[0].from_amount) > 1
    ) {
      set_error({
        field: 'all',
        error:
          'starting_range_cannot_be_less_than_0_and_cannot_be_greater_than_1',
        id: editable_rows[0].id,
        error_type: 'START_RANGE_ERROR',
      });
      return false;
    }

    let lastRow = editable_rows[editable_rows.length - 1];

    if (
      (markup_settings?.markup_preference?.value === 'amount' &&
        lastRow?.to_amount < 5000000) ||
      (markup_settings?.markup_preference?.value === 'carats' &&
        lastRow?.to_amount < 100)
    ) {
      set_fix_last_row(true);
    } else {
      fnWrapper(
        async () => {
          context.setBusy({
            type: 'Update markups',
            status: true,
          });
          let clean_data = update_markups.map(({ is_new, ...rest }) => {
            return { ...rest };
          });
          let sanity_check = clean_data.every(
            (r) =>
              r.percent != null && r.from_amount != null && r.to_amount != null
          );

          if (sanity_check) {
            await add_markups({
              variables: {
                input_markups: clean_data,
              },
            });
          }
          await save_cfm_settings({
            variables: {
              markup_preference: markup_preference,
              markup_currency: markup_currency,
            },
          });
          await refetchData();
          onClose();
          context.setBusy({
            type: '',
            status: false,
          });
          if (cb && typeof cb === 'function') cb();
        },
        null,
        {
          error: {
            messageResolver: () => (
              <CustomTrans
                text={'failed_to_update_the_markups_at_this_time'}
                returnPlainText={true}
              />
            ),
          },
          success: {
            messageResolver: () => (
              <CustomTrans
                text={'successfully_updated_the_markups'}
                returnPlainText={true}
              />
            ),
          },
        }
      );
    }
  };

  const onTabClick = (tab) => {
    const new_markups = sortBy(
      Markups.filter(
        (ele) =>
          ele.ProductSubtype === tab.tab_id &&
          ele.ProductType === tab.product &&
          ele.markup_type === markup_settings.markup_preference?.value
      ),
      'from_amount'
    );
    set_editable_row(new_markups);
    setActiveTab(tab);
  };

  return (
    <Popup
      onClose={onClose}
      title={<CustomTrans text={'markup_center'} returnPlainText={true} />}
      className="markup-modal"
    >
      <MarkupModalWrapper>
        <MarkupTabBar
          tabsList={MARKUP_TABS}
          activeTab={activeTab}
          setActiveTab={onTabClick}
        />
        <div className="tab_body">
          <MarkupPreferenceSection
            isLoading={isLoading}
            markup_settings={markup_settings}
            handleMarkupSettingsChanges={handleMarkupSettingsChanges}
            reset_to_default={reset_to_default}
          />
          <MarkupBody
            editable_rows={editable_rows}
            currency={
              markup_settings?.markup_preference.value === 'amount'
                ? markup_settings?.markup_currency?.symbol
                : 'ct'
            }
            addFormFields={addFormFields}
            removeFormFields={removeFormFields}
            handleBlur={handleBlur}
            handleChange={handleChange}
            error={error}
          />
          <div style={{ width: '100%' }}>
            {error &&
              (error.error_type === 'ALL_ERROR' ||
                error.error_type === 'ONE_ARE_MORE_RANGE_GAP') && (
                <div style={{ color: 'red', ...theme.fonts.smallBold }}>
                  <CustomTrans text={error.error} returnPlainText={true} />
                </div>
              )}
          </div>
          {markup_settings?.markup_preference.value === 'carats' && (
            <div className="carat_warning_note">
              <Icon
                name="info-btn-2"
                size={12}
                color={theme.palette.themeSecondary}
              />
              <CustomTrans text={'carat_warning_note'} returnPlainText={true} />
            </div>
          )}
        </div>
        <MarkupFooter
          onClose={onClose}
          isDisabled={error.error || !is_rows_changed}
          applyChanges={applyChanges}
          isLoading={save_markups_loading || save_cfm_loading}
        />
        {fix_last_row && (
          <Popup onClose={() => set_fix_last_row(false)}>
            <FixLastRowWrapper>
              <CustomTrans
                text={'your_row_data_is_invalid_would_you_like_to_fix_it'}
                returnPlainText={true}
              />
              <div className="fix_last_row">
                <Button
                  className="cancel"
                  onClick={() => set_fix_last_row(false)}
                >
                  <CustomTrans text={'cancel'} returnPlainText={true} />
                </Button>
                <Button
                  className="fix"
                  onClick={() => {
                    let lastRow = editable_rows[editable_rows.length - 1];
                    let newRows = [
                      ...editable_rows
                        .slice(0)
                        .splice(0, editable_rows.length - 1),
                      {
                        ...lastRow,
                        to_amount:
                          markup_settings?.markup_preference.value === 'amount'
                            ? 5000000
                            : 100,
                      },
                    ];
                    set_editable_row(newRows);
                    set_fix_last_row(false);
                  }}
                >
                  <CustomTrans text={'fix'} returnPlainText={true} />
                </Button>
              </div>
            </FixLastRowWrapper>
          </Popup>
        )}
      </MarkupModalWrapper>
    </Popup>
  );
};

const MarkupPreferenceSection = ({
  isLoading,
  markup_settings,
  handleMarkupSettingsChanges,
  reset_to_default,
}) => {
  const { markup_currency, markup_preference } = markup_settings;

  const defaultStyles = {
    control: (base) => ({
      ...base,
      border: `1px solid ${theme.palette.Neutrals300} !important`,
      color: theme.palette.gray800,
      fontSize: '14px',
      width: '100px',
      fontWeight: '600',
      height: '47px !important',
      borderRadius: '8px',
      // This line disable the blue border
      boxShadow: 'none !important',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
  };

  return (
    <div className="preference_section">
      <div className="preference_section--info">
        <CustomTrans
          text={'select_how_you_would_like_to_apply_your_markup'}
          returnPlainText={true}
        />
        <a
          href="https://nivoda-public-objects.s3.eu-west-2.amazonaws.com/Diamond_Markups.pdf"
          className="guide-link"
        >
          <CustomTrans text={'check_markup_guide'} returnPlainText={true} />
        </a>
      </div>
      <div className="preference_section--tabs_section">
        <div className="preference_section--tabs_section__tabs">
          {APPLY_ON_OPTIONS.map((ele, index) => {
            return (
              <SelectionBtn
                key={ele.value + index}
                className={
                  markup_preference.value === 'amount' &&
                  index === 1 &&
                  'no-border-left'
                }
                is_active={ele?.value === markup_preference.value}
                is_first={index === 0}
                is_last={index === APPLY_ON_OPTIONS?.length - 1}
                onClick={() =>
                  handleMarkupSettingsChanges('markup_preference', ele)
                }
              >
                {`${CustomTrans({
                  text: 'by',
                  returnPlainText: true,
                })} ${CustomTrans({
                  text: ele?.label,
                  returnPlainText: true,
                }).toLowerCase()}`}
              </SelectionBtn>
            );
          })}
        </div>
        {markup_preference.value === 'amount' && (
          <CustomReactSelect
            wrapperClass="drop_down"
            isSearchable={false}
            isClearable={false}
            options={CURRENCY}
            isDisabled={isLoading}
            styles={defaultStyles}
            onChange={(item) =>
              handleMarkupSettingsChanges('markup_currency', item)
            }
            value={markup_currency}
            rightIconProps={
              isLoading
                ? {
                    name: 'loader',
                    size: 20,
                    color: theme.palette.black,
                  }
                : {}
            }
          />
        )}
      </div>
      <Button
        className="markup_buttons--reset"
        iconConfig={{
          name: 'arrow-back-up',
          size: 16,
          color: theme.palette.themePrimary,
        }}
        leftDir={false}
        onClick={() => reset_to_default()}
      >
        <CustomTrans text={'reset_to_default'} returnPlainText={true} />
      </Button>
    </div>
  );
};

const MarkupBody = ({
  editable_rows,
  currency,
  addFormFields,
  removeFormFields,
  handleChange,
  handleBlur,
  error,
}) => {
  let disposable_row =
    editable_rows.length > 0
      ? editable_rows.map(({ ...rest }) => {
          return { ...rest };
        })
      : [];

  let all_fields_have_value =
    disposable_row.length > 0
      ? check_all_fields_have_value(disposable_row)
      : true;

  return (
    <div
      className={`markup_body ${
        editable_rows?.length + 1 < 5 && 'markup_body_no_min'
      }`}
    >
      <div className="markup_body--headers">
        <div className="column label_col1">
          {`${CustomTrans({
            text: currency === 'ct' ? 'size' : 'price',
            returnPlainText: true,
          })} ${CustomTrans({
            text: 'range',
            returnPlainText: true,
          }).toLowerCase()}`}
          <Tooltip
            content={
              <div style={{ width: '180px', color: theme.palette.white }}>
                {currency === 'ct' ? (
                  <CustomTrans
                    text={'create_size_ranges_in_order_to_apply_markup'}
                    returnPlainText={true}
                  />
                ) : (
                  <CustomTrans
                    text={'create_cost_ranges_in_order_to_apply_markup'}
                    returnPlainText={true}
                  />
                )}
              </div>
            }
            placement="top"
          >
            <Icon
              name="infoCircle"
              size={16}
              color={theme.palette.Neutrals500}
            />
          </Tooltip>
        </div>
        <span className="column label_col2">
          <CustomTrans text={'markup'} returnPlainText={true} />
          <Tooltip
            content={
              <div style={{ width: '180px', color: theme.palette.white }}>
                <CustomTrans
                  text={'additional_percentage_profit_above_the_cost'}
                  returnPlainText={true}
                />
              </div>
            }
            placement="top"
          >
            <Icon
              name="infoCircle"
              size={16}
              color={theme.palette.Neutrals500}
            />
          </Tooltip>
        </span>
      </div>
      <WithScrollbar isActive={true} id="markup_values">
        {editable_rows &&
          editable_rows?.map((markup, index) => {
            return (
              <MarkupRow
                key={index}
                id={markup.id}
                error={error}
                from_value={markup.from_amount}
                to_value={markup.to_amount}
                markup_per={markup.percent}
                markup_x={
                  markup?.percent > 0
                    ? (1 + markup.percent / 100).toPrecision(3)
                    : ''
                }
                removeFormFields={removeFormFields}
                handleBlur={(e) =>
                  handleBlur({
                    id: markup.id,
                    next_row_id: editable_rows[index + 1]?.id,
                    field: e.target.name,
                    field_value: e.target.value,
                    is_base_row: false,
                  })
                }
                handleChange={(e) =>
                  handleChange({
                    value: e.target.value.replace(/[^0-9.]+|(\.(?=.*\.))/g, ''),
                    next_row_id: editable_rows[index + 1]?.id,
                    id: markup.id,
                    is_base_row: false,
                    field: e.target.name,
                  })
                }
                currency={currency}
              />
            );
          })}
        <div className="add_row_button_wrapper">
          <Button
            className="add_row_button"
            disabled={!all_fields_have_value}
            onClick={() => addFormFields()}
          >
            <Icon name="add" size={12} color={theme.palette.brandBlue4} />
            <span className="add_row_button--label">
              <CustomTrans text="add_row" returnPlainText={true} />
            </span>
          </Button>
        </div>
      </WithScrollbar>
    </div>
  );
};

const MarkupRow = ({
  id,
  from_value,
  to_value,
  markup_per,
  markup_x,
  removeFormFields,
  handleChange,
  handleBlur,
  error,
  currency,
}) => {
  let has_error = (field) =>
    !isEmpty(error) && error.id === id && error.field === field
      ? '1px solid #EA4C46'
      : null;
  return (
    <div className="markup_body--row">
      <div className="row_items">
        <div className="column column_1">
          {from_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </div>
        {CustomTrans({ text: 'to', returnPlainText: true }).toLowerCase()}
        <div className="column column_2">
          <Input
            name="to_amount"
            value={to_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{
              border: has_error('to_amount'),
            }}
            className="markup_input"
            PrefixIcon={currency !== 'ct' && currency}
            PostfixIcon={currency === 'ct' && currency}
          />
        </div>
        <div className="column column_3">
          <Input
            name="percent"
            value={markup_per}
            onChange={handleChange}
            onBlur={handleBlur}
            className="markup_input"
            PostfixIcon={'%'}
            style={{
              border: has_error('percent'),
            }}
          />
        </div>
        <div className="column column_4">
          <Trans
            i18nKey={'sale_price_will_be_times_the_initial_value'}
            values={{
              value: markup_x,
            }}
            components={{ bold: <strong /> }}
          />
        </div>
        <div className="column column_5">
          <div className="icon_div" onClick={() => removeFormFields(id)}>
            <Icon name="trash_can" size={16} color={theme.palette.neutral600} />
          </div>
        </div>
      </div>
      {error != null && error.id === id && error.error && (
        <span style={{ color: '#EA4C46', ...theme.fonts.smallBolder }}>
          <CustomTrans
            text={error.error}
            returnPlainText={true}
            fallBack={
              error.error.replace('_', ' ').charAt(0).toUpperCase() +
              error.error.slice(1)
            }
          />
        </span>
      )}
    </div>
  );
};

const MarkupFooter = ({ onClose, isDisabled, applyChanges, isLoading }) => {
  return (
    <div className="markup_footer">
      <Button className="markup_footer--cancel" onClick={() => onClose()}>
        <CustomTrans text="cancel" returnPlainText={true} />
      </Button>
      <Button
        className={`markup_footer--apply ${isLoading && 'while_loading'}`}
        onClick={() => applyChanges()}
        disabled={isDisabled || isLoading}
      >
        <CustomTrans text={'apply_changes'} returnPlainText={true} />
        {isLoading && (
          <Icon name="loader" color={theme.palette.gray500} size={14} />
        )}
      </Button>
    </div>
  );
};
