import { gql } from '@apollo/client';
import { CertificateInfoFragment } from './CertificateFragment';

export const NivodaExpressItemFragment = gql`
  fragment NivodaExpressItemFragment on FbnItem {
    id
    inventory_type
    supplierStockId
    internal_status
    SpacecodeProductTag
    SpacecodeCertTag
    Product {
      ... on Diamond {
        id
      }
      ... on Melee {
        id
      }
    }
  }
`;

export const NivodaExpressRequestItemFragment = gql`
  fragment NivodaExpressRequestItemFragment on ExpressRequestItem {
    id
    status
    is_deleted
    product_price
    certificate {
      ...CertificateInfo
    }
    Product {
      ... on Diamond {
        id
        availability
        brown
        green
        other
        gray
        blue
        milky
        bowtie
        eyeClean
        supplierStockId
        diamondSupplierStockId
        OrderItemId
        location {
          id
          country
        }
      }
    }
    ProductType
  }
  ${CertificateInfoFragment}
`;

export const NivodaExpressRequestsFragment = gql`
  fragment NivodaExpressRequestsFragment on ExpressRequest {
    id
    consignment_number
    consignment_form {
      id
      form_url
    }
    destination {
      id
      country
      city
    }
    express_request_items {
      ...NivodaExpressRequestItemFragment
    }
    requested_date
    status
    stones_requested
    stones_accepted
    total_value
  }
  ${NivodaExpressRequestItemFragment}
`;
