import React from 'react';
import theme from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';
import { string } from 'prop-types';
import styled from 'styled-components';
import { globalStyleFabric } from '../../styles';
import cx from 'classnames';

export function Message(props) {
  return (
    <Wrap
      className={cx('cmp_msg_box', props?.className)}
      hasCloseIcon={props.hasCloseIcon}
    >
      <div
        className={`msg_box ${props.type} ${props.type}-br ${props.type}_card__block`}
        style={{
          marginTop: props.mt,
        }}
      >
        <Icon
          color={
            theme.semanticColors[props.type === 'warn' ? 'warning' : props.type]
          }
          name={props.name || (props.type === 'warn' ? 'warning' : 'check')}
          size={18}
          {...(props.iconConfig || {})}
        />
        <div className="section_right">{props.children}</div>
        {props.hasCloseIcon && (
          <Icon
            color={
              theme.semanticColors[
                props.type === 'warn' ? 'warning' : props.type
              ]
            }
            name={'close-icon'}
            onClick={props.onClose}
            size={18}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
    </Wrap>
  );
}
Message.propTypes = {
  type: string,
};

const Wrap = styled.div`
  ${globalStyleFabric};
  .msg_box {
    display: grid;
    grid-template-columns: ${({ hasCloseIcon }) =>
      hasCloseIcon
        ? `${theme.spacing.absolute.s6}px 1fr ${
            theme.spacing.absolute.s3 + 2
          }px`
        : `${theme.spacing.absolute.s6}px 1fr`};
    justify-items: center;
    padding: ${theme.spacing.s4};

    svg {
      margin-left: ${theme.spacing.s1};
      margin-top: ${theme.spacing.s1};
      justify-self: start;
      align-self: start;
    }
    .section_right {
      justify-self: start;
    }

    .error_heading,
    .info_heading,
    .success_heading,
    .warn_heading {
      margin-bottom: ${theme.spacing.s3};
    }
  }
`;
