import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';

export const GET_NIVODA_LOCATIONS = gql`
  query GetNivodaLocations {
    all_nivoda_locations {
      id
      name
      address1
      address2
      postalCode
      city
      state
      country
      phone1
      owner {
        id
        firstName
        country
      }
    }
  }
`;

export const GetNivodaLocations = AdminGraphqlQuery({
  query: GET_NIVODA_LOCATIONS,
});
