import { gql } from '@apollo/client';

export let GET_AUSTRALIAN_SUBURBS = gql`
  query ($postal_code: Int) {
    get_australian_suburbs(postal_code: $postal_code) {
      name
      postcode
      state {
        name
        abbreviation
      }
      locality
      latitude
      longitude
    }
  }
`;

export const GET_CERTIFICATE_DETAILS = gql`
  query GetCertificateById($cert_id: ID!) {
    get_certificate_by_id(cert_id: $cert_id) {
      id
      keyToSymbols
      comments
      fullShape
      country_of_origin
      canada_mark_eligible
      pdfUrl
      depth
      width
      length
      crownAngle
      crownHeight
      pavAngle
      pavHeight
      pavDepth
      table
      depthPercentage
      starLength
      girdle
      lowerGirdle
      culetSize
      labgrown_type
      treated
      f_color
      f_overtone
      f_intensity
      verified
      polish
      colorShade
      symmetry
      labgrown
      certNumber
      cut
      shape
      carats
      color
      clarity
      floCol
      floInt
      lab
      image
    }
  }
`;
