import { css } from 'styled-components';
import { SCREEN as screen } from './screen';
import { theme as t } from '@nivoda/ui';

/**
 * @summary Centralize default theme for Nivoda
 *  1. Palette exposes official colors for Nivoda.
 *  2. SemanticColors are for different UI areas like colors for input placeholder, disabled state etc.
 *  3. semanticProps are for different css props border & types, shadows.
 *  4. Fonts provide different fonts and variants. Note: <type>plus versions are bolder counterparts for each typeface.
 *  5. Effect exposes different animation, shadow values, shades.
 *  6. Screen exposes all the supported screen sizes (screen width, height)
 *  7. Spacing exposes values for padding and margin which are multiples of four
 *     Eg. s[4xn] => s1 -> s[4x1] = 4px and s4 -> s[4x4] => 16px
 *
 *  @note
 *  Golden ratio: *1.1618* is used for line height calculation
 *  Line height calculation example:
 *  font size = 14px
 *  line height = (14 * 1.618) = 23px
 *  width @ 50cpl = 50 * (14/1.618) = 433px
 *
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 **/
export const theme = Object.freeze({
  palette: Object.freeze({
    ...t.palette,
    black: '#000',
    blackNeutral: '#161616',
    blackPrimary: '#2c2c2c',
    blackSecondary: '#353434',
    nivodaBlue50: '#E9F2FD',
    nivodaBlue80: '#C2DBFC',
    blue05: '#FAFAF9',
    blue1: '#DDE2EE',
    blue01: '#E7F1FF',
    blue100: '#DBEAFE',
    blue2: '#9EC5FE',
    blue200: '#BCD4FF',
    blue300: '#93C5FD',
    blue3: '#6B80AE',
    black4: '#424243',
    blue400: '#60A5FA',
    blue5: '#0D6EFD',
    blue50: '#EEF4FF',
    blue6: '#2563EB',
    blue600: '#1E4CF5',
    blue700: '#1436E1',
    brandBlue1: '#DDE2EE',
    brandBlue3: '#6B80AE',
    brandBlue4: '#292524',
    brandBlue5: '#FAFAF9',
    brandPink4: '#5620E1',
    cyan: '#17a2b8',
    gray: '#BDBDBD',
    gray1: '#9E9E9E',
    gray2: '#757575',
    gray50: '#FAFAFA',
    gray100: '#F4F4F5',
    gray200: '#E4E4E7',
    gray300: '#D4D4D8',
    gray400: '#A1A1AA',
    gray500: '#71717A',
    gray2Alt: '#777777',
    gray3: '#616161',
    gray4: '#424242',
    gray700: '#3F3F46',
    gray6: '#52525B',
    gray800: '#27272A',
    gray9: '#18181B',
    greenPrimary: '#35823c',
    green: '#28a745',
    green1: '#008000',
    green11: '#D1E7DD',
    green100: '#D6F2E3',
    green200: '#B4E4CB',
    green2: '#90ee90',
    green22: '#a3cfbb',
    green3: '#B3E870',
    green4: '#479F76',
    green400: '#4ADE80',
    green5: '#198754',
    green50: '#F0FDF4',
    green55: '#2FBD51',
    green6: '#23983F',
    green600: '#16A34A',
    green700: '#15803D',
    green800: '#315345',
    green900: '#14532D',
    green8: '#0b5727',
    indigo: '#6610f2',
    lightGray: '#efefef',
    lightGreen1: '#ebf3eb',
    navyBlue1: '#DDE2EE',
    navyBlue2: '#ADBAD7',
    navyBlue3: '#6B80AE',
    navyBlue4: '#292524',
    navyBlue400: '#3E537F',
    offBlack1: '#ADADAD',
    offBlack2: '#8c8c8c',
    offBlack3: '#616161',
    offBlack4: '#424243',
    offWhite1: '#ADADAD',
    offWhite2: '#dadada',
    offWhite3: '#E7E7E7',
    offWhite4: '#f1f1f1',
    offWhite5: '#f6f6f6',
    offWhite6: '#F7F7F7',
    orange: '#fd7e14',
    orange700: '#C2410C',
    pink: '#e83e8c',
    pink1: '#FF0156',
    pink100: '#FFE1EB',
    pink200: '#FFB9D0',
    pink400: '#FF679A',
    purple: '#6f42c1',
    red: '#ff0000',
    csRed: '#F75A5A', // complementary solid red
    csRed05: 'rgba(246, 90, 90, 0.5)', // complementary solid red 50
    red0: '#F65A5A',
    red1: '#F8D7DA',
    red100: '#FEE2E2',
    red200: '#FECACA',
    red2: '#F1AEB5',
    red5: '#DC3545',
    red6: '#B02A37',
    red400: '#F87171',
    red500: '#EF4444',
    red600: '#DC2626',
    red700: '#B91C1C',
    red800: '#991B1B',
    red50: '#FEF2F2',
    subtleGreen: 'rgb(235 255 246)',
    subtleRed: '#FFF5F6',
    teal: '#20c997',
    themeDark: '#343a40',
    themePrimary: '#0C0A09', // nivoda black
    themeSecondary: '#5620E1',
    themeSecondaryDark: '#D20F6D',
    themeSecondaryHover: '#9886FF',
    themeTertiary: '#96B4DB',
    themeLight: '#f8f9fa',
    themeLighter: '',
    themeLighterAlt: '',
    white: '#fff',
    white0: '#fafafa',
    white1: '#F5F5F5',
    white2: '#EEEEEE',
    white3: '#E0E0E0',
    yellow0: '#F5BF00',
    yellow: '#fff653',
    yellow1: '#ffc107',
    yellow100: '#FEF3C7',
    yellow700: '#B45309',
    yellow2: '#f7e985',
    yellow400: '#FBBF24',
    textColor: '#3c3c3b',
    yellow200: '#FFE69C',
    yellow3: '#FFDA6A',
    yellow4: '#FFCD39',
    yellow5: '#FFC107',
    yellow7: '#997404',
    backgroundColor: '#FAFAF9',
    Neutrals50: '#FAFAFA',
    Neutrals100: '#F5F5F4',
    Neutrals200: '#E7E5E4',
    Neutrals300: '#D6D3D1',
    Neutrals400: '#A8A29E',
    Neutrals500: '#78716C',
    Neutrals600: '#57534E',
    Neutrals700: '#44403C',
    Neutrals900: '#181717',
    Neutrals950: '#0C0A09',
    brandGold: '#5620E1',
    brandGoldHover: '#EFC176',
    violet: '#F4F2FF',
    violet100: '#E9E8FF',
    blueStoke: '#9886FF',
    subdued: '#78716C',
    successGreen: '#3D745C',
    amber50: '#FFFBEB',
    amber900: '#78350F',
    amber200: '#FDE68A',
    amber800: '#92400E',
    neutral200: '#E7E5E4',
    neutral400: '#A8A29E',
    neutral600: '#57534E',
    greenNew100: '#D6F2E3',
    greenNew500: '#59B186',
    greenNew600: '#479570',
    borderHard: '#D6D3D1',
    violet50: '#F4F2FF',
    violet200: '#D7D4FF',
    violet300: '#BAB2FF',
    violet400: '#9886FF',
    violet500: '#7655FD',
    violet600: '#6330f5',
    violet700: '#5620E1',
    violet800: '#481ABD',
    borderxHard: '#A8A29E',
    amber500: '#F59E0B',
    semanticPrimary300: '#9886FF',
    semanticPrimary500: '#481ABD',
    disabled: '#F1F1F1',
  }),
  semanticColors: Object.freeze({
    ...t.semanticColors,
    regularPrimary100: '#F4F2FF',
    regularPrimary200: '#E9E8FF',
    regularPrimary300: '#9886FF',
    regularPrimary400: '#5620E1',
    regularPrimary500: '#481ABD',
    memoPrimary100: '#EEF4FF',
    memoPrimary200: '#D9E6FF',
    memoPrimary300: '#5893FF',
    memoPrimary400: '#326CFF',
    memoPrimary500: '#1E4CF5',
    avatarBG: '#F1F2F7',
    avatarTextColor: '#C6C9DE',
    buttonPrimary: '#0C0A09',
    buttonSecondaryDisabled: '#FAFAF9',
    memoButtonPrimary: '#326CFF',
    memoButtonPrimaryHover: '#5893FF',
    memoButtonPrimaryActive: '#1E4CF5',
    memoButtonPrimaryBackground: '#D9E6FF',
    buttonGhostPrimary: '#44403C',
    cardTextHighlight: '#fafafa',
    flagSelectedBorder: '#6B80AE',
    cardBodyWhiteTransparent: 'rgba(255, 255, 255, 0.7)',
    cancelledColor: '#C0392B',
    disabledBG: '#868e96',
    extensionRequestedBG: '#2387CF',
    expressBrandingColor: '#DF2B50',
    errorIcon: '#dc3545',
    error: '#D8000C',
    errorBG: '#FFBABA',
    errorBG0: '#ffbaba66',
    errorBGHover: '#FFA4A4',
    flagBtn: '#FAFAF9',
    filterIndicator: '#EF4444',
    forbidBG: '#FFF5F6',
    forbidBG1: '#e00017',
    headingText: '#353434',
    holdButtonBG: '#50AE55',
    infoCardBG: '#17a2b8',
    info: '#31708F',
    infoBG: '#D9EDF7',
    infoBG0: '#d9edf766',
    infoBGHover: '#D5D8F7',
    infoIcon: '#bdbdbd',
    inputLabel: '#6e6e6e',
    inputText: '#2c2c2c',
    subtleText: '#78716c',
    linkText: '#0C0A09',
    linkTextHovered: '#141b5d',
    orderHLBl: '#D9EDF7',
    orderHLGr: '#CBE1D3',
    orderHLYl: '#F9EDBC',
    tooltipOrange: '#DD9426',
    pageBackground: '#F4F4F4',
    naturalProductBG: '#4959ff',
    notificationProgressBarInitColor: '#e0e0e0',
    notificationProgressColor: '#35823C',
    notificationProgressErrorColor: '#A9371B',
    menuHeaderBackground: '',
    menuIcon: '',
    productInfoBG: '#f6f7fa',
    nonActiveBorder: '#A9371B',
    placeholderText: '#bdbdbd',
    popupTitleHighlight: '#ebfbff',
    // color for small tag when used for providing more info e.g. Downloading <small>(10%)</small>
    rateTextColor: '#ff6347', // same as tomato
    rejectedColor: '#C0392B',
    rejectedBG: '#E8E0DA',
    requestedHoldBG: '#FFA44A',
    rowBG0: '#FFE5E5',
    rowBG1: '#F3E3CB',
    sidebarTextColor: '#9e9e9e',
    smallTextColor: '#9E9E9E',
    success: '#4F8A10',
    successBG: '#D1E7DD',
    successBG0: '#dff2bf66',
    successBG1: '#EAF4F0',
    successBG2: '#4ade80',
    successBGHover: '#B4F298',
    tableExpandedRowBG: '#EEEEEE',
    tableHeaderBackground: '#f6f6f6',
    tableHeaderLoadingBackground: '#e0e0e0',
    tableHeaderText: '#757575',
    tableHeaderTextLight: '#757575',
    tableSelectedRowBackground: '#f5ffff',
    tableRowHoverBackground: '#F5F5F5',
    warning: '#9F6000',
    warningBG: '#FFF3CD',
    warningBG0: '#FED7A0',
    warningBGHover: '#FED7A0',
    tableRowBackground: '#fbfbfb',
    labelSuccess: '#15803D',
    labelSuccessBG: '#DCFCE7',
    labelError: '#B91C1C',
    labelErrorBG: '#FEF2F2',
    labelWarn: '#D97706',
    labelWarn1: '#534200',
    labelWarnBG: '#FEF3C7',
    labelWarnBG1: '#fcd742',
    labelInfoDark: '#FFFFFF',
    labelInfoDarkBG: '#666666',
    labelInfo: '#71717A',
    labelInfoBG: '#F4F4F5',
    labelBlue: '#1D4ED8',
    labelBlueBG: '#DBEAFE',
    signupPrimaryHoverBg: '#5620E1',
  }),
  semanticProps: Object.freeze({
    ...t.semanticProps,
    avatarBorder: '1px solid #c6c9de',
    borderOffWhite2: '1px solid #dadada',
    borderOffWhite3: '1px solid #e7e7e7',
    borderGrey3: '1px solid #D4D4D8',
    borderWhite3: '1px solid #e0e0e0',
    borderDashedWhite3: '1px dashed #e0e0e0',
    borderGray: '1px solid #BDBDBD',
    borderGray2: '1px solid #757575',
    borderGray3: '1px solid #616161',
    borderGray4: '1px solid #F4F4F5',
    borderBlack: '1px solid #000',
    borderDashedBlack: '1px dashed #000',
    borderBlackPrimary: '1px solid #2c2c2c',
    borderDashedBlackPrimary: '1px dashed #2c2c2c',
    borderDashedThemePrimary: '1px dashed #0C0A09',
    ellipsisMenuBorder: '1px solid #f7f7f7',
    menuShadow: '0 4px 4px rgb(0 0 0 / 10%)',
    menuSubtleShadow: '0 4px 6px rgba(44, 44, 44, 0.05)',
    cardDividerPipe: '1px solid #f5f5f5',
    holdButtonBorder: '1px solid #50AE55',
    inputBorder: '1px solid #e5e5e5',
    expiredBorder: '1px solid #F9F9F9',
    holdBorder: '1px solid #50AE55',
    extensionRequestedBorder: '1px solid #2387CF',
    requestedHoldBorder: '1px solid #FFA44A',
    tableBorder: '1px solid #e0e0e0',
    tableRowBorder: '1px solid #0000000d',
    borderGray5: '1px solid #f1f1f1',
    bottomLine: '1px solid #dde2ee',
    csRedBorder: '1px solid #f75a5a',
    badgeBorder: '1px solid #b3e870',
  }),
  fonts: Object.freeze({
    absolute: {
      xTiny: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 8px;
        font-weight: 400;
        line-height: 13px;
      `,
      tiny: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
      `,
      xSmall: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
      `,
      xSmallBolder: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 10px;
        font-weight: 700;
        line-height: 16px;
      `,
      small: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
      `,
      smallPlus: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
      `,
      smallBold: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
      `,
      smallBolder: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
      `,
      medium: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
      `,
      mediumPlus: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
      `,
      mediumBold: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 600;
        line-height: 23px;
      `,
      // review and remove
      mediumBolder: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      `,
      large: css`
        font-family: Inter, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
      `,
      largePlus: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
      `,
      largeBold: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
      `,
      largeBolder: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      `,
      xLarge: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        font-weight: 400;
        line-height: 29px;
      `,
      xLargePlus: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        font-weight: 500;
        line-height: 29px;
      `,
      xLargeBold: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        font-weight: 600;
        line-height: 29px;
      `,
      xxLargePlus: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
      `,
      xxLargeBold: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 24px;
        font-weight: 700;
        line-height: 39px;
      `,
      superLargePlus: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 28px;
        font-weight: 500;
        line-height: 45px;
      `,
      superLargeBold: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 28px;
        font-weight: 600;
        line-height: 45px;
      `,
      xSuperLarge: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
      `,
      mega: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 32px;
        font-weight: 600;
        line-height: 52px;
      `,
      megaPlus: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 42px;
        font-weight: 600;
        line-height: 67px;
      `,
      // review and remove
      xLargeBolder: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        font-weight: 700;
        line-height: 29px;
      `,
      xxLargeBolder: css`
        font-family: Inter, Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 24px;
        font-weight: 700;
        line-height: 39px;
      `,
    },
    xTiny: {
      ...t.fonts.xTiny,
      fontFamily: 'Inter, Arial, sans-serif',
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '8px',
      fontWeight: '400',
      lineHeight: '13px',
    },
    tiny: {
      ...t.fonts.tiny,
      fontFamily: 'Inter, Arial, sans-serif',
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '16px',
    },
    xSmall: {
      ...t.fonts.xSmall,
      fontFamily: 'Inter, Arial, sans-serif', // Semi Bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
    },
    xSmallBold: {
      ...t.fonts.xSmall,
      fontFamily: 'Inter, Arial, sans-serif', // Semi Bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '16px',
    },
    xSmallBolder: {
      ...t.fonts.xSmall,
      fontFamily: 'Inter, Arial, sans-serif', // Semi Bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '10px',
      fontWeight: '700',
      lineHeight: '16px',
    },
    small: {
      ...t.fonts.small,
      fontFamily: 'Inter, Arial, sans-serif', // Normal
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '12px',
    },
    smallPlus: {
      ...t.fonts.smallPlus,
      fontFamily: 'Inter, Arial, sans-serif', // Semi bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '12px',
    },
    smallBold: {
      ...t.fonts.smallBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '12px',
    },
    smallBolder: {
      ...t.fonts.smallBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '12px',
    },
    medium: {
      ...t.fonts.medium,
      fontFamily: 'Inter, Arial, sans-serif', // Normal
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '23px',
    },
    mediumPlus: {
      ...t.fonts.mediumPlus,
      fontFamily: 'Inter, Arial, sans-serif', // Semi bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '23px',
    },
    mediumBold: {
      ...t.fonts.mediumBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '23px',
    },
    mediumBolder: {
      ...(t.fonts?.mediumBolder ?? {}),
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '20px',
    },
    large: {
      ...t.fonts.large,
      fontFamily: 'Inter, Arial, sans-serif', // Normal
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '26px',
    },
    largePlus: {
      ...t.fonts.largePlus,
      fontFamily: 'Inter, Arial, sans-serif', // Semi bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '26px',
    },
    largeBold: {
      ...t.fonts.largeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '26px',
    },
    largeBolder: {
      ...t.fonts.largeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
    },
    xLarge: {
      ...t.fonts.xLarge,
      fontFamily: 'Inter, Arial, sans-serif', // Normal
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '29px',
    },
    xLargePlus: {
      ...t.fonts.xLargePlus,
      fontFamily: 'Inter, Arial, sans-serif', // Semi bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '29px',
    },
    xLargeBold: {
      ...t.fonts.xLargeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '29px',
    },
    xxLarge: {
      ...t.fonts.xxLargePlus,
      fontFamily: 'Inter, Arial, sans-serif', // Normal
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '32px',
    },
    xxLargePlus: {
      ...t.fonts.xxLargePlus,
      fontFamily: 'Inter, Arial, sans-serif', // Semi bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '32px',
    },
    xxLargeBold: {
      ...t.fonts.xxLargeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '24px',
    },
    xxLargeBolder: {
      ...t.fonts.xxLargeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '24px',
    },
    xxMedium: {
      ...t.fonts.xxLargeBold,
      fontFamily: 'Inter, Arial, sans-serif',
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '22px',
      fontWeight: '400',
      lineHeight: '35px',
    },
    xl: {
      ...t.fonts.xxLargeBold,
      fontFamily: 'Inter, Arial, sans-serif',
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '39px',
    },
    xlPlus: {
      ...t.fonts.xxLargeBold,
      fontFamily: 'Inter, Arial, sans-serif', // semi bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '24px',
      fontWeight: '500',
      lineHeight: '39px',
    },
    xlBold: {
      ...t.fonts.xxLargeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '39px',
    },
    xlExtraBold: {
      ...t.fonts.xxLargeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '39px',
    },
    superLargePlus: {
      ...t.fonts.superLargePlus,
      fontFamily: 'Inter, Arial, sans-serif', // semi bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '28px',
      fontWeight: '500',
      lineHeight: '45px',
    },
    superLargeBold: {
      ...t.fonts.superLargeBold,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '28px',
      fontWeight: '600',
      lineHeight: '45px',
    },
    xSuperLarge: {
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '30px',
      fontWeight: '700',
      lineHeight: '36px',
    },
    mega: {
      ...t.fonts.mega,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '32px',
      fontWeight: '600',
      lineHeight: '52px',
    },
    megaPlus: {
      ...t.fonts.megaPlus,
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '42px',
      fontWeight: '600',
      lineHeight: '67px',
    },
    // review and remove
    xLargeBolder: {
      fontFamily: 'Inter, Arial, sans-serif', // bold
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '29px',
    },
  }),
  effects: Object.freeze({
    ...t.effects,
    cardShadow: '0 3px 5px rgb(22 22 22 / 5%)',
    hover: '0 0.1px',
    hover1: '0 0.1px',
    hover2: '0 0.1px',
    menuShadow: '0 4px 4px rgb(0 0 0 / 10%)',
    menuSubtleShadow: '0 4px 6px rgba(44, 44, 44, 0.05)',
    roundedCorner2: '2px',
    roundedCorner3: '3px',
    roundedCorner4: '4px',
    roundedCorner6: '4px',
    tableRowHoverShadow: '0px -1px 0px #E5E5E5',
    tableShadow: '0 3px 10px rgba(51, 51, 51, 0.07)',
    gridCardShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
  }),
  screen,
  // multiples of 4
  spacing: Object.freeze({
    // for inline calculations
    absolute: {
      px: 1,
      s1: 4,
      s2: 8,
      s3: 12,
      s4: 16,
      s5: 20,
      s6: 24,
      s7: 28,
      s8: 32,
      s9: 36,
      s10: 40,
    },
    px: '1px', // to support 1px border
    s1: '4px',
    s2: '8px',
    s3: '12px',
    s4: '16px',
    s5: '20px',
    s6: '24px',
    s7: '28px',
    s8: '32px',
    s9: '36px',
    s10: '40px',
    s11: '44px',
    s12: '48px',
  }),
});

export default theme;
