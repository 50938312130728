import React, { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useOnClickOutside } from '../../hooks';
import { Icon } from '../../resources/icon';
import styled from 'styled-components';
import theme from '../../theme';

export const TableColumn = (props) => {
  const { delay = 500, onChange } = props;
  const ref = React.useRef();
  const [focus, setFocus] = React.useState(false);
  const [value, setValue] = React.useState('');

  const handleOnBlur = (e) => {
    if (typeof props.onBlur === 'function') {
      setFocus(false);
      props.onBlur(e, value);
    }
  };

  const debouncedResults = useMemo(() => {
    return debounce((v) => {
      if (typeof onChange === 'function') {
        onChange(v);
      }
    }, delay);
  }, [onChange, delay]);

  const handleOnChange = (e) => {
    const v = e.target.value;
    setValue(v);
    debouncedResults(props.validate(v));
  };

  const onClickOutSideSave = async () => {
    setFocus(false);
    if (props[`${props.colId}SaveHandler`]) {
      await props[`${props.colId}SaveHandler`](value);
    }
  };

  useOnClickOutside(ref, onClickOutSideSave);

  return (
    <StyledWrapper ref={ref}>
      {!focus ? (
        <div className="iconRow">
          {props.column.name}{' '}
          <div className="headerIcon">
            <Icon
              name={'search-2'}
              size={12}
              onClick={() => {
                setFocus(true);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="headerSearchBox">
          <Icon className="icn1" name={'search-2'} size={12} />
          <input
            className="headerInput"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            autoFocus={focus}
            placeholder={`Search ...`}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            value={value}
          />
          <Icon
            className="icn2"
            name={'small-close'}
            size={12}
            onClick={(e) => {
              setValue('');
              if (typeof onChange === 'function') {
                onChange('');
              }
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      )}
    </StyledWrapper>
  );
};
const StyledWrapper = styled.div`
  & {
    width: 100%;
    position: relative;

    .iconRow {
      display: flex;
      height: 20px;
      align-items: center;
      justify-content: space-between;
      gap: ${theme.spacing.s3};

      svg {
        margin-top: -3px;
        z-index: 1;
      }
    }

    .headerInput {
      position: absolute;
      width: 90%;
      border: none;
      height: 16px;
      padding: ${theme.spacing.s3} 0 ${theme.spacing.s3} ${theme.spacing.s2};
      outline: none;
    }

    .headerIcon {
      position: relative;
      margin-right: ${theme.spacing.s3};
      cursor: pointer;

      &:hover:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: ${theme.palette.offWhite4};
        border-radius: ${theme.effects.roundedCorner4};
        top: -4px;
        left: -4px;
        z-index: -1;
      }
    }

    .headerSearchBox {
      height: 20px;
      width: 90%;

      svg {
        margin-bottom: -2px;
        position: absolute;
        z-index: 2;
      }

      .icn1 {
        left: -9px;
        top: 4px;
      }

      .icn2 {
        right: 6px;
        top: 4px;
        cursor: pointer;
      }
    }
  }
`;
