export const holdsTabStatus = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  PENDING_ENQUIRY: 'PENDING_ENQUIRY',
  INACTIVE: 'INACTIVE',
  CONVERTED: 'CONVERTED',
  ALL: 'ALL',
};

export const holdsTabStatusToTitle = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  PENDING_ENQUIRY: 'Pending Enquiry',
  REJECTED: 'Rejected',
  INACTIVE: 'Inactive',
  CONVERTED: 'Converted',
  CANCELLED: 'Cancelled',
};

export const holdStatusTypes = Object.freeze({
  REQUESTED: 'REQUESTED',
  ON_HOLD: 'ON_HOLD',
  EXTENSION_REQUESTED: 'EXTENSION_REQUESTED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED', // Inactive
  CANCELLED: 'CANCELLED', // Inactive
});

export const holdStatusToTitle = {
  REQUESTED: 'Requested',
  ON_HOLD: 'On Hold',
  EXTENSION_REQUESTED: 'Extension Requested',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired', // Inactive
  CANCELLED: 'Cancelled', // Inactive
};

export const holdExtensionStatusTypes = Object.freeze({
  EXPIRY_NOTIFIED: 'EXPIRY_NOTIFIED',
  SUPPLIER_EXTENDED: 'SUPPLIER_EXTENDED',
  REQUESTED: 'REQUESTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
});

export const holdExtensionStatusToTitle = {
  EXPIRY_NOTIFIED: 'Expiry notified',
  SUPPLIER_EXTENDED: 'Supplier extended',
  REQUESTED: 'Requested',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  NOT_APPLICABLE: 'Not applicable',
};

export const holdsQueryTypes = {
  REQUEST_DATE: 'request_date',
};
