export const notificationTypes = {
  completed: 'completed',
  downloading: 'downloading',
  error: 'error',
  paired: 'paired',
  warning: 'warning',
  uploading: 'uploading',
};

export const successTypes = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  OTHER: 'OTHER',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
};
