// noinspection ExceptionCaughtLocallyJS

export let get_current_session = () => {
  const session = localStorage.getItem('graphql_session');

  if (session != null && session !== 'null') {
    try {
      let parsedSession = JSON.parse(session);
      if (typeof parsedSession?.token !== 'string') {
        throw new Error(`No token`);
      }
      if (typeof parsedSession.refresh_token !== 'string') {
        throw new Error(`No refresh token`);
      }
      if (
        parsedSession.user == null ||
        parsedSession.user.steps_required == null
      ) {
        throw new Error(
          `No steps required found (complete should be empty array)`
        );
      }
      return parsedSession;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error while session parsing:', e);
      return null;
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('No session found');
    return null;
  }
};
