import {
  CertificateFragment,
  CertificateInfoFragment,
} from './CertificateFragment';
import { gql } from '@apollo/client';

export const DiamondOfferFrg = gql`
  fragment DiamondOfferFrg on Offer {
    id
    price
    discount
    is_returnable
    Product {
      ... on Diamond {
        id
      }
    }
    product_price
    product_discount
    return_price
    return_discount
    memo_return_deadline
    original_offer {
      id
      discount
      diamond_price
      diamond_discount
    }
  }
`;

export const DiamondOfferFrgPairs = gql`
  fragment DiamondOfferFrg on Offer {
    id
    price
    discount
    is_returnable
    Product {
      ... on Diamond {
        id
      }
    }
    hold {
      id
      ProductType
      extension_until
      until
      denied
      deny_reason
      extension_status
      status
    }
    fbn_details {
      id
      inventory_type
    }
    product_price
    product_discount
    return_price
    return_discount
    original_offer {
      id
      discount
      diamond_price
      diamond_discount
    }
  }
`;

export const DiamondFragment = gql`
  fragment DiamondFragment on Diamond {
    id
    price
    rapaportDiscount
    supplierStockId
    brown
    green
    blue
    gray
    origin_country
    other
    milky
    eyeClean
    bowtie
    supplier {
      id
      name
      country
      company_settings {
        CompanyId
        self_serve_po
        accept_returns
        memo_enabled
        memo_supplier
        memo_stock_outside_express
      }
    }
    location {
      id
      name
      country
    }
  }
`;

export const DiamondWithCertFragment = gql`
  fragment DiamondWithCert on Diamond {
    ...DiamondFragment
    certificate {
      ...CertificateInfo
      labgrown
    }
    offer {
      id
      discount
      price
      is_returnable
      original_offer {
        id
        discount
      }
    }
  }
  ${DiamondFragment}
  ${CertificateInfoFragment}
`;

// Note: earlier BaseDiamondFragment
export const OrderDiamondFragment = gql`
  fragment OrderDiamond on Diamond {
    ...DiamondFragment
    delivery_time {
      min_business_days
      max_business_days
    }
    final_price
    has_bgmec_enquiry
    supplier {
      id
      preferred
      company_image
      rating
      supplier_status
      company_settings {
        CompanyId
        accept_holds
        accept_returns
        final_price
        holds_enabled
        hold_hours
      }
    }
    availability
    certificate {
      ...CertificateFragment
    }
    offer {
      ...DiamondOfferFrg
    }
  }
  ${DiamondFragment}
  ${CertificateFragment}
  ${DiamondOfferFrg}
`;

export const GridItemDiamondFragment = gql`
  fragment GridDiamond on Diamond {
    id
    rapaportDiscount
    other
    blue
    brown
    green
    blue
    gray
    milky
    eyeClean
    price
    has_bgmec_enquiry
    supplier {
      id
      name
      preferred
    }
    offer {
      id
      price
      discount
    }
    location {
      id
      country
    }
    certificate {
      ...CertificateFragment
    }
  }
  ${CertificateFragment}
`;

export const AdminDiamondFragment = gql`
  fragment AdminDiamond on Diamond {
    ...DiamondFragment
    diamondSupplierStockId
    certificate {
      ...CertificateInfo
    }
    # image
    availability
    OrderItemId
    offer {
      id
      discount
      price
      product_price
      product_discount
      total_price_without_credit_charge
      original_offer {
        id
        discount
      }
    }
  }
  ${DiamondFragment}
  ${CertificateInfoFragment}
`;

export const AdminDiamondPriceFragment = gql`
  fragment AdminDiamondPrice on Diamond {
    base_price
    base_discount
    last_updated
  }
`;

export const CustomerDiamondFragment = gql`
  fragment CustomerDiamond on Diamond {
    ...DiamondFragment
    certificate {
      ...CertificateInfo
    }
    # image
    availability
    OrderItemId
    offer {
      id
      discount
      price
      original_offer {
        id
        discount
      }
    }
  }
  ${DiamondFragment}
  ${CertificateInfoFragment}
`;
//for price calculator admin
export const CalculatorDiamondFragment = gql`
  fragment DiamondFragment on Diamond {
    id
    price
    rapaportDiscount
    rateWithDiscount
    valueWithDiscount
    supplierStockId
    # image
    brown
    green
    blue
    gray
    origin_country
    other
    milky
    eyeClean
    supplier {
      id
      name
      company_settings {
        CompanyId
        accept_returns
      }
      locations {
        id
        name
      }
    }
    location {
      id
      name
      country
    }
  }
`;

export const OrderDiamondFragmentCalculator = gql`
  fragment OrderDiamond on Diamond {
    ...DiamondFragment
    delivery_time {
      min_business_days
      max_business_days
    }
    final_price
    has_bgmec_enquiry
    supplier {
      id
      preferred
      company_image
      rating
      company_settings {
        CompanyId
        accept_holds
        accept_returns
        final_price
        holds_enabled
        hold_hours
      }
    }
    availability
    certificate {
      ...CertificateFragment
    }
    offer {
      ...DiamondOfferFrg
    }
  }
  ${CalculatorDiamondFragment}
  ${CertificateFragment}
  ${DiamondOfferFrg}
`;

export const OrderDiamondFragmentPairs = gql`
  fragment OrderDiamond on Diamond {
    ...DiamondFragment
    delivery_time {
      min_business_days
      max_business_days
    }
    final_price
    has_bgmec_enquiry
    supplier {
      id
      preferred
      company_image
      rating
      company_settings {
        CompanyId
        accept_holds
        accept_returns
        final_price
        holds_enabled
        hold_hours
      }
    }
    availability
    certificate {
      ...CertificateFragment
    }
    offer {
      ...DiamondOfferFrg
    }
  }
  ${DiamondFragment}
  ${CertificateFragment}
  ${DiamondOfferFrgPairs}
`;
