import { gql } from '@apollo/client';

export const FETCH_DYNAMIC_FILTERS_OPTIONS = gql`
  query getDynamicFilterOptions(
    $filters: [MeleeFilterFields!]!
    $melee_type: MeleeType!
    $melee_shape: [Shape!]!
    $melee_status: [MeleeStatus!]!
    $melee_source: [MeleeSource!]!
  ) {
    get_melee_dynamic_filters(
      filters: $filters
      melee_type: $melee_type
      melee_shape: $melee_shape
      melee_status: $melee_status
      melee_source: $melee_source
    ) {
      carats
      mm_size {
        from_mm_size
        to_mm_size
      }
    }
  }
`;
