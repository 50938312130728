import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import theme from '../../theme';

export const MailToLink = ({
  label = 'support@nivoda.com',
  to = 'support@nivoda.com',
  ...rest
}) => {
  return (
    <A href={`mailto:${to}`} {...rest}>
      {label}
    </A>
  );
};

MailToLink.propTypes = {
  to: string,
};

const A = styled.a`
  color: ${(p) => p.color || theme.palette.themePrimary};
  font-style: ${(p) => p.fonts || theme.fonts.smallPlus};
  text-decoration: ${(p) => p.textDecoration || 'underline'};
`;
