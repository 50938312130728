import React from 'react';
import { PageHeader } from '../page-header/page-header';
import { gql } from '@apollo/client';
import { GraphqlFactory, GraphqlMutation } from 'graphql/factory';
import { Compose } from '../../MetaComponents';
import { WithHistory } from '../../Elements';
import './style.css';

let is_mobile = window.innerWidth < 700;

export let user_fragment = gql`
  fragment Full_User on User {
    id
    firstName
    lastName
    country
    city
    postalCode
    phone1
    phone2
    email
    address
    default_currency
    email_notifications
    preferred_language
    exchange_rate {
      id
      name
      to_USD
    }
  }
`;

export let ProfileMutations = ({ children }) => {
  return (
    <Compose
      update={(fn) => <UpdateUserProfile children={fn} />}
      children={children}
    />
  );
};

export const UpdateCompanySettings = GraphqlMutation({
  query: gql`
    mutation UpdateCompany(
      $main_location: ID!
      $website: String
      $about: String
      $office_closure_dates: [OfficeClosureDate]
      $office_closure_recurring_days: [OfficeClosureReccuringDays]
      $deleted_date_ids: [String]
    ) {
      update_company(
        main_location: $main_location
        website: $website
        about: $about
        office_closure_dates: $office_closure_dates
        deleted_date_ids: $deleted_date_ids
        office_closure_recurring_days: $office_closure_recurring_days
      ) {
        id
        name
        website
        about
        main_location
        company_image
        company_banner
        country_of_incorporation
        closure_dates {
          id
          company_id
          start_date
          end_date
        }
        office_closure_recurring_days
      }
    }
  `,
});

export let UpdateUserProfile = GraphqlMutation({
  query: gql`
    mutation UpdateUserProfile($user: ProfileInput!) {
      update_user_profile(user: $user) {
        ...Full_User
      }
    }
    ${user_fragment}
  `,
  optimisticResponse: ({ user }, store) => {
    let { me } = store.readQuery({
      query: gql`
        query {
          me {
            ...Full_User
          }
        }
        ${user_fragment}
      `,
    });

    return {
      update_user_profile: {
        ...user,
        ...(me ?? {}),
        __typename: 'User',
      },
    };
  },
});

export let PasswordMutations = ({ children }) => {
  return (
    <Compose
      update={(fn) => <UpdateUserPassword children={fn} />}
      children={children}
    />
  );
};

export let UpdateUserPassword = GraphqlMutation({
  query: gql`
    mutation UpdateUserPassword($passwords: PasswordInput!) {
      update_user_password(passwords: $passwords) {
        id
        firstName
      }
    }
  `,
});

export let GetUserProfile = GraphqlFactory(gql`
  query {
    me {
      id
      firstName
      lastName
      country
      city
      postalCode
      phone1
      phone2
      email
      skype
      address
      default_currency
      profile_image
      email_notifications
      preferred_language
      company {
        id
        loupe360_key
        is_owner
      }
    }
  }
`);

let default_tabs = [
  {
    title: 'Personal Profile',
    status: 'personal_profile',
    link: 'settings/personal_profile',
  },
  {
    title: 'Password',
    status: 'password',
    link: 'settings/password_settings',
  },
];

let company_owner_tabs = [
  {
    title: 'Company Profile',
    status: 'company_settings',
    link: 'settings/company_settings',
  },
  {
    title: 'User Management',
    status: 'user_management',
    link: 'settings/user_management',
  },
  {
    title: 'Security',
    status: 'security',
    link: 'settings/security',
  },
  {
    title: 'B2C Plugin',
    status: 'b2c_plugin',
    link: 'settings/b2c_plugin',
  },
  {
    title: 'Manage Locations',
    status: 'manage_locations',
    link: 'settings/locations',
  },
];

let SettingsPage = ({ user }) => {
  //This page is only for mobile
  let tabs = user.company.is_owner
    ? default_tabs.concat(company_owner_tabs)
    : default_tabs;
  let is_supplier = user.role === 'SUPPLIER';

  return (
    <>
      {is_mobile && (
        <>
          <PageHeader title={'Settings'} />
          <WithHistory>
            {(history) => (
              <>
                {is_mobile &&
                  tabs
                    .filter((item) => {
                      if (is_supplier) {
                        return (
                          item.status !== 'b2c_plugin' &&
                          item.status !== 'security'
                        );
                      }
                      return item;
                    })
                    .map((item) => (
                      <div
                        className="order-overview__item"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        key={item.status}
                        id={item.status}
                        onClick={() => {
                          history.push(item.link);
                        }}
                      >
                        <div>{item.title} </div>
                        <div style={{ marginRight: 20 }}>
                          <img
                            src={'/img/chevron_right.svg'}
                            alt="chevron_right"
                          />
                        </div>
                      </div>
                    ))}
              </>
            )}
          </WithHistory>
        </>
      )}
    </>
  );
};

export default SettingsPage;
