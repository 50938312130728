import { gql } from '@apollo/client';
import { AdminGraphqlQuery } from '../../../graphql-admin';
import {
  CertificateInfoFragment,
  GemstoneCertificate,
  MeleeFragment,
  JewelleryFragment,
} from 'graphql/fragments';
import { process_gemstone_data } from 'Helper/processGemstoneQuery';
import { groupBy } from 'lodash';

export const GET_RETURN_SHIPMENT_DETAILS = gql`
  query getReturnShipmentDetails(
    $offset: Int
    $origin_country: OriginCountry
    $origin_city: OriginCity
    $from_date: String
    $to_date: String
    $tracking_code: String
    $order_type: String
    $supplier_name: String
    $order_number: String
  ) {
    get_to_return_items(
      limit: 1000
      offset: $offset
      origin_country: $origin_country
      origin_city: $origin_city
      from_date: $from_date
      to_date: $to_date
      tracking_code: $tracking_code
      order_type: $order_type
      supplier_name: $supplier_name
      order_number: $order_number
    ) {
      total_count
      items {
        id

        order {
          id
          order_date
        }

        supplier_invoice {
          id
          invoice_number
        }

        FbnType
        status
        order_date
        order_number
        origin_country
        supplierReturnDeadlineDate
        origin_city

        destination {
          id
          name
          city
          country
        }

        ProductType
        Product {
          ... on Diamond {
            id
            supplierStockId
            diamondSupplierStockId
            certificate {
              ...CertificateInfo
            }
            location {
              id
              name
              country
            }
            supplier {
              id
              name
              country
              city
            }
          }
          ... on Melee {
            ...MeleeFragment
            child_melee_collections {
              ...MeleeFragment
              supplierStockId
              total_price
              price_per_carat
              pieces
              IsParentMeleeCollection
            }
            total_price
            price_per_carat
            pieces
            IsParentMeleeCollection
          }
          ... on Gemstone {
            id
            supplierStockId
            gemstoneSupplierStockId
            availability
            last_enquiry_answered
            has_bgmec_enquiry
            has_media_enquiry
            GemstoneCertificate: certificate {
              id
              ...GemstoneCertificate
            }
            location {
              id
              name
              country
            }
            supplier {
              id
              name
              country
            }
          }
        }
        Qc {
          id
          status
        }
        QcGemstone {
          id
          status
        }
      }
    }
  }
  ${CertificateInfoFragment}
  ${MeleeFragment}
  ${GemstoneCertificate}
`;

export let OrdersByKeyOfficeRTS = AdminGraphqlQuery({
  query: gql`
    query getRTSOfficeOrderByCountry(
      $status_key: OrderItemStatusKey!
      $query: AdminOrderItemQuery!
      $origin_country: OriginCountry
      $origin_city: OriginCity
    ) {
      orders_by_status(
        query: $query
        origin_country: $origin_country
        origin_city: $origin_city
        key: $status_key
        limit: 1000
      ) {
        total_count
        office_closure_dates {
          id
          start_date
          end_date
          company_id
        }
        items {
          id
          order {
            id
            user_comment
            order_date
          }
          # Melee order description
          order_number
          origin_country
          origin_city
          deliveryDeadline
          deliveryDate

          destination {
            id
            name
            city
            country
            kyc_verified
          }
          CustomerInvoice {
            status
            id
            settled
          }
          FbnId
          FbnType
          shipments {
            id
            tracking_code
            inter_office_shipment
            envelope_number
            shipment_created
            carrier
            envelope_barcode {
              barcode_text
            }
            CanClose {
              can_close
              n_items_qc_pass_avl
              n_items_avl
              qc_pending_items {
                id
                order_number
                order {
                  id
                  order_date
                }
                buyer {
                  id
                  company {
                    id
                    name
                    country
                  }
                }
                ProductType
                Product {
                  ... on Diamond {
                    id
                    certificate {
                      id
                      lab
                      certNumber
                    }
                    supplier {
                      id
                      name
                      country
                    }
                  }
                  ... on Melee {
                    id
                    MeleeCertificate: certificate {
                      id
                      lab
                      certNumber
                    }
                    supplier {
                      id
                      name
                      country
                      company_settings {
                        CompanyId
                        accept_returns
                      }
                    }
                  }
                  ... on Gemstone {
                    id
                    GemstoneCertificate: certificate {
                      id
                      lab
                      certNumber
                    }
                    supplier {
                      id
                      name
                    }
                  }
                }
              }
            }
            origin {
              id
              country
            }
            destination {
              id
              country
            }
            parent_shipment {
              id
              tracking_code
              shipment_created
              carrier
              origin {
                id
                country
              }
            }
          }
          invoice {
            status
            id
            usd_amount
            currency
            settled
            settled_at
            invoice_number
          }

          buyer {
            id
            firstName
            company {
              id
              name
              company_settings {
                CompanyId
                company_code
              }
            }
          }
          ProductType
          Product {
            ... on Diamond {
              id
              certificate {
                id
                lab
                certNumber
                shape
                carats
                color
                cut
                polish
                symmetry
                clarity
                floCol
                floInt
              }

              supplier {
                id
                name
              }
            }
            ... on Melee {
              ...MeleeFragment
            }
            ... on Gemstone {
              id
              GemstoneCertificate: certificate {
                id
                lab
                gemType
                certNumber
                shape
                carats
                color
                cut
                clarity
              }

              supplier {
                id
                name
              }
            }
            ... on Jewellery {
              ...JewelleryFragment
              supplier {
                id
                name
              }
            }
          }
          carats
          pieces
          assigned_to {
            id
            firstName
            lastName
            profile_image
          }
        }
      }
    }
    ${MeleeFragment}
    ${JewelleryFragment}
  `,
  map_data: (data) => {
    let key = 'orders_by_status';

    let old_items = (data[key] != null && data[key]?.items) || [];
    if (data[key] == null) {
      return data;
    }
    let items = process_gemstone_data(old_items);
    data[key] = {
      ...data[key],
      items: items,
    };

    let grouped_closure_dates = groupBy(
      data?.orders_by_status?.office_closure_dates,
      (office_closure_date) => office_closure_date?.company_id
    );

    return {
      ...data,
      refetch: data.refetch,
      orders_by_status: {
        ...data?.orders_by_status,
        office_closure_dates: grouped_closure_dates,
      },
    };
  },
});
