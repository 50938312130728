export const calculateDiamondTotal = (items, isDiamondPrice) => {
  const amounts = isDiamondPrice
    ? items.map((item) => item.offer.product_price)
    : items.map((item) => item.offer.price);
  let total = 0.0;
  for (let i = 0; i < amounts.length; i++) {
    total += amounts[i];
  }
  return total / 100;
};
