import _, { isEmpty, pick } from 'lodash';

export let DEFAULT_GEMS_QUERY = {
  certificate_lab: [],
  certificate_numbers: '',
  clarity: [],
  color: [],
  color_overtone: [],
  cut: [],
  delivery_time: [],
  dollar_value: [0, 0],
  dollar_per_carat: [0, 0],
  depth_mm: null,
  has_image: null,
  has_v360: null,
  length_mm: null,
  location: [],
  order: {
    direction: 'ASC',
    type: 'price',
  },
  mine_of_origin: [],
  returns: false,
  shapes: [],
  sizes: [['', '']],
  sizeRangeFrom: '',
  sizeRangeTo: '',
  suppliers: [],
  treatment: [],
  type: [],
  width_mm: null,
};

export const CUSTOM_QUERY_GEMS_COMPARATOR = pick(DEFAULT_GEMS_QUERY, [
  'certificate_lab',
  'certificate_numbers',
  'clarity',
  'color',
  'color_overtone',
  'cut',
  'delivery_time',
  'depth_mm',
  'dollar_value',
  'dollar_per_carat',
  'has_image',
  'has_v360',
  'hide_memo',
  'length_mm',
  'location',
  'origin', //??? we don't use this.
  'mine_of_origin',
  'other',
  'returns',
  'shapes',
  'sizes',
  'suppliers',
  'treated',
  'treatment',
  'type',
  'width_mm',
]);
export const gems_breadcrumbs_filters = {
  type: [],
  shapes: [],
  color: [],
  clarity: [],
  cut: [],
  depth_mm: ['', ''],
  length_mm: ['', ''],
  width_mm: ['', ''],
  has_v360: null,
  has_image: null,
  treatment: [],
  mine_of_origin: [],
  location: [],
  dollar_value: [0, 0],
  certificate_lab: [],
  returns: false,
  sizes: [['', '']],
  suppliers: '',
  sizeRangeFrom: '',
  sizeRangeTo: '',
  delivery_time: [],
};

export const GEM_STONE_TYPES = [
  { name: 'sapphire', value: 'SAPPHIRE' },
  { name: 'emerald', value: 'EMERALD' },
  { name: 'ruby', value: 'RUBY' },
  { name: 'tourmaline', value: 'TOURMALINE' },
  { name: 'tanzanite', value: 'TANZANITE' },
  { name: 'aquamarine', value: 'AQUAMARINE' },
  { name: 'alexandrite', value: 'ALEXANDRITE' },
  { name: 'spinel', value: 'SPINEL' },
  { name: 'opal', value: 'OPAL' },
  { name: 'topaz', value: 'TOPAZ' },
  { name: 'garnet', value: 'GARNET' },
  { name: 'morganite', value: 'MORGANITE' },
  { name: 'peridot', value: 'PERIDOT' },
  { name: 'zircon', value: 'ZIRCON' },
  { name: 'amethyst', value: 'AMETHYST' },
  { name: 'kunzite', value: 'KUNZITE' },
  { name: 'grandidierite', value: 'GRANDIDIERITE' },
  { name: 'ametrine', value: 'AMETRINE' },
  { name: 'jade', value: 'JADE' },
  { name: 'citrine', value: 'CITRINE' },
  { name: 'moonstone', value: 'MOONSTONE' },
  { name: 'sodalite', value: 'SODALITE' },
  { name: 'quartz', value: 'QUARTZ' },
  { name: 'chrysoberyl', value: 'CHRYSOBERYL' },
  { name: 'beryl', value: 'BERYL' },
  { name: 'apatite', value: 'APATITE' },
  { name: 'sphene', value: 'SPHENE' },
  { name: 'heliodor', value: 'HELIODOR' },
  { name: 'prehnite', value: 'PREHNITE' },
  { name: 'spectrolite', value: 'SPECTROLITE' },
  { name: 'other', value: 'OTHER' },
];

export const gemsFancyColor = [
  { name: 'blue', value: 'BLUE', color: 'blue' },
  {
    name: 'green',
    value: 'GREEN',
    color: 'green',
  },
  { name: 'red', value: 'RED', color: '#F53B3B' },
  { name: 'Teal', value: 'TEAL', color: 'teal' },
  { name: 'pink', value: 'PINK', color: '#EFA5DA' },
  { name: 'Bi-Color', value: 'BI_COLOR', color: '#78EDCD' },
  { name: 'Multi-Color', value: 'MULTI_COLOR', color: '#CDD0A5' },
  { name: 'purple', value: 'PURPLE', color: '#DB00FF' },
  { name: 'yellow', value: 'YELLOW', color: 'yellow' },
  { name: 'violet', value: 'VIOLET', color: 'violet' },
  { name: 'brown', value: 'BROWN', color: '#B26746' },
  { name: 'orange', value: 'ORANGE', color: 'orange' },
  { name: 'grey', value: 'GRAY', color: 'gray' },
  { name: 'white', value: 'WHITE', color: '#FEFEFe' },
  { name: 'black', value: 'BLACK', color: '#000000' },
  { name: 'other', value: 'OTHER', color: '#E9E9E9' },
];

export const GEMS_SHAPES = [
  { value: 'OVAL', name: 'oval', icon: 'Oval' },

  { value: 'ROUND', name: 'round', icon: 'Round' },
  { value: 'EMERALD', name: 'emerald', icon: 'Emerald' },
  { value: 'PEAR', name: 'pear', icon: 'Pear' },
  {
    value: 'OCTAGONAL',
    name: 'octagonal',
    icon: 'Octagonal',
  },
  { value: 'SQUARE', name: 'square', icon: 'Square' },
  { value: 'MARQUISE', name: 'marquise', icon: 'Marquise' },
  { value: 'HEART', name: 'heart', icon: 'Heart' },
  {
    value: 'TRIANGULAR',
    name: 'triangular',
    icon: 'Triangular',
  },

  { value: 'RADIANT', name: 'radiant', icon: 'Radiant' },
  { value: 'KITE', name: 'kite', icon: 'Kite' },

  {
    value: 'CUSHION',
    name: 'Cushion',
    icon: 'CushionBrilliant',
  },
  {
    value: 'TRILLIANT',
    name: 'trilliant',
    icon: 'Trilliant',
  },
  {
    value: 'HEXAGONAL',
    name: 'hexagonal',
    icon: 'Hexagonal',
  },
  { value: 'OTHER', name: 'other', icon: 'Other' },
];

export const gemsFancyOvertone = [
  { name: 'blue', value: 'BLUE', color: '#F6E0BF' },
  { name: 'blueish', value: 'BLUEISH', color: '#000000' },
  { name: 'black', value: 'BLACK', color: '#B26746' },
  { name: 'brown', value: 'BROWN', color: '#F6E0BF' },
  { name: 'brownish', value: 'BROWNISH', color: '#C96228' },
  {
    name: 'champagne',
    value: 'CHAMPAGNE',
    color:
      'conic-gradient(from 180deg at 50% 50%, #379FFF 0deg, #D61E1E 360deg)',
  },

  { name: 'gray', value: 'GRAY', color: '#7C73DD' },
  { name: 'grayish', value: 'GRAYISH', color: '#EFA5DA' },
  { name: 'green', value: 'GREEN', color: '#DB00FF' },
  { name: 'greenish', value: 'GREENISH', color: '#7C73DD' },
  { name: 'orange', value: 'ORANGE', color: '#EFA5DA' },
  { name: 'orangy', value: 'ORANGY', color: '#DB00FF' },
  { name: 'pink', value: 'PINK', color: '#7C73DD' },
  { name: 'pinkish', value: 'PINKISH', color: '#EFA5DA' },
  { name: 'purple', value: 'PURPLE', color: '#DB00FF' },
  { name: 'purplish', value: 'PURPLISH', color: '#7C73DD' },
  { name: 'red', value: 'RED', color: '#EFA5DA' },
  { name: 'reddish', value: 'REDDISH', color: '#DB00FF' },
  { name: 'violet', value: 'VIOLET', color: '#7C73DD' },
  { name: 'white', value: 'WHITE', color: '#EFA5DA' },
  { name: 'yellow', value: 'YELLOW', color: '#DB00FF' },
  { name: 'yellowish', value: 'YELLOWISH', color: '#7C73DD' },
  { name: 'other', value: 'OTHER', color: '#FFFF' },
];

export const GEMS_CUTS = [
  { value: 'CABOCHON', name: 'Cabochon' },
  { value: 'FACETED', name: 'Faceted' },
  { value: 'BRILLIANTSTEP', name: 'Brilliant step' },
  { value: 'BRILLIANT', name: 'Brilliant' },
  { value: 'BRILLIANTFANCY', name: 'Brilliant fancy' },
  { value: 'MODIFIEDBRILLIANT', name: 'Modified brilliant' },
  { value: 'MODIFIEDBRILLIANTFANCY', name: 'Modified brilliant fancy' },
  { value: 'FANCY', name: 'Fancy' },
  { value: 'MODIFIEDBRILLIANTSTEP', name: 'Modified brilliant step' },
  { value: 'BUFFTOP', name: 'Bufftop' },
  { value: 'PRINCESS', name: 'Princess' },
  { value: 'RADIANT', name: 'Radiant' },
  { value: 'STEP', name: 'Step' },
  { value: 'FANTASY', name: 'Fantasy' },
  { value: 'INTAGLIO', name: 'Intaglio' },
  { value: 'ROSE', name: 'Rose' },
  { value: 'CARVED', name: 'Carved' },
  { value: 'CAMEO', name: 'Cameo' },
  { value: 'CHECKERBOARD', name: 'Checkerboard' },
  { value: 'SUGERLOAFCABOCHON', name: 'Sugarloaf cabochon' },
  { value: 'OTHER', name: 'Other' },
];

export const GEMSTONE_TREATMENTS = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'Heating',
    value: 'HEATED',
  },
  {
    label: 'Heating & pressure',
    value: 'HEATINGPRESSURE',
  },
  {
    label: 'Oiling',
    value: 'OILING',
  },
  {
    label: 'Enhancement',
    value: 'ENHANCEMENT',
  },

  {
    label: 'Composite',
    value: 'COMPOSITE',
  },
  {
    label: 'Dyeing',
    value: 'DYEING',
  },
  {
    label: 'Waxing',
    value: 'WAXING',
  },
  {
    label: 'Coating',
    value: 'COATING',
  },
  {
    label: 'Diffusion',
    value: 'DIFFUSION',
  },
  {
    label: 'Filling',
    value: 'FILLING',
  },
  {
    label: 'Imitation',
    value: 'IMITATION',
  },
  {
    label: 'Bleaching',
    value: 'BLEACHING',
  },

  {
    label: 'Irradiation',
    value: 'IRRADIATION',
  },
  {
    label: 'Impregnated',
    value: 'IMPREGNATED',
  },

  {
    label: 'Lasering',
    value: 'LASERING',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const ORIGIN = [
  {
    label: 'Zambia',
    value: 'ZAMBIA',
  },
  {
    label: 'Madagascar',
    value: 'MADAGASCAR',
  },
  {
    label: 'Thailand',
    value: 'THAILAND',
  },
  {
    label: 'Mozambique',
    value: 'MOZAMBIQUE',
  },
  {
    label: 'Myanmar',
    value: 'MYANMAR',
  },
  {
    label: 'Sri Lanka',
    value: 'SRILANKA',
  },
  {
    label: 'Brazil',
    value: 'BRAZIL',
  },
  {
    label: 'Australia',
    value: 'AUSTRALIA',
  },
  {
    label: 'Ethiopia',
    value: 'ETHIOPIA',
  },
  {
    label: 'Tanzania',
    value: 'TANZANIA',
  },
  {
    label: 'Cambodia',
    value: 'CAMBODIA',
  },
  {
    label: 'Afghanistan',
    value: 'AFGHANISTAN',
  },
  {
    label: 'Colombia',
    value: 'COLUMBIA',
  },
  {
    label: 'Greenland',
    value: 'GREENLAND',
  },

  {
    label: 'India',
    value: 'INDIA',
  },
  {
    label: 'Iran',
    value: 'IRAN',
  },
  {
    label: 'Kenya',
    value: 'KENYA',
  },

  {
    label: 'Pakistan',
    value: 'PAKISTAN',
  },
  {
    label: 'Tajikistan',
    value: 'TAJISKISTAN',
  },
  {
    label: 'Vietnam',
    value: 'VIETNAM',
  },
  {
    label: 'Rest of Africa',
    value: 'REST_OF_AFRICA',
  },
  {
    label: 'United States',
    value: 'UNITEDSTATES',
  },
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },

  // {
  //   label: 'Others',
  //   value: 'OTHER',
  // },
];

export const GEMSTONE_CERTIFICATE = [
  { label: 'ICL', value: 'ICL' },
  { label: 'GIA', value: 'GIA' },
  { label: 'AGL', value: 'AGL' },
  { label: 'GRS', value: 'GRS' },
  { label: 'CDC', value: 'CDC' },
  { label: 'AGS', value: 'AGS' },
  { label: 'DSEF', value: 'DSEF' },
  { label: 'EGL', value: 'EGL' },
  { label: 'FGL', value: 'FGL' },
  { label: 'GCAL', value: 'GCAL' },
  { label: 'GGL', value: 'GGL' },
  { label: 'HRD', value: 'HRD' },
  { label: 'IGI', value: 'IGI' },
  { label: 'AIGS', value: 'AIGS' },
  { label: 'LOTUS', value: 'LOTUS' },
  { label: 'GUBELIN', value: 'GUBELIN' },
  { label: 'RGL', value: 'RGL' },
  { label: 'TGL', value: 'TGL' },
  { label: 'ALGT', value: 'ALGT' },
  { label: 'other', value: 'OTHER', customWidth: '100%' },
  { label: 'none', value: 'NONE', customWidth: '100%' },
];

export const GemSortOptions = [
  {
    title: 'Recently Added',
    type_title: 'Recently Added',
    direction_full: '',
    direction: '',
    value: 'recent-desc',
    icon: 'star-icon',
    object: {
      direction: 'DESC',
      type: 'createdAt',
    },
  },
  {
    title: 'price',
    subList: [
      {
        value: 'price-high-to-low',
        title: 'Price: high to low',
        type_title: 'price',
        direction_full: 'high_low',
        direction: 'down',
        icon: 'sort_descending_v3',
        object: {
          direction: 'DESC',
          type: 'price',
        },
      },
      {
        value: 'price-low-to-high',
        title: 'Price: low to high',
        type_title: 'price',
        direction_full: 'low_high',
        direction: 'up',
        icon: 'sort_ascending_v3',
        object: {
          direction: 'ASC',
          type: 'price',
        },
      },
    ],
  },
  {
    title: 'carat',
    subList: [
      {
        value: 'carat-high-to-low',
        title: 'Carat: high to low',
        type_title: 'carat',
        direction_full: 'high_low',
        direction: 'down',
        icon: 'sort_descending_v3',
        object: {
          direction: 'DESC',
          type: 'size',
        },
      },
      {
        value: 'carat-low-to-high',
        title: 'Carat: low to high',
        type_title: 'carat',
        direction_full: 'low_high',
        direction: 'up',
        icon: 'sort_ascending_v3',
        object: {
          direction: 'ASC',
          type: 'size',
        },
      },
    ],
  },
];

export let get_gemstone_order_item = (order) => {
  if (order == null || isEmpty(order)) return GemSortOptions[0];
  if (!_.isEmpty(order.object)) return get_gemstone_order_item(order.object);
  if (order.type == null || order.direction == null) return GemSortOptions[0];

  let filtered = [];
  GemSortOptions.forEach((item) => {
    if (item && item?.subList && item.subList.length) {
      item.subList.forEach((x) => filtered.push(x));
    } else filtered.push(item);
  });

  let result = filtered.find(
    (x) =>
      x.object.type === order.type && x.object.direction === order.direction
  );
  return result || GemSortOptions[0];
};
