import React from 'react';
import cx from 'classnames';
import { FlagIcon } from '@nivoda/react-flag-kit';
import styled from 'styled-components';
import theme from '../../theme';

export function FlagButton(props) {
  const preventClick = () => {};
  const flagCode = props?.flag?.country
    ? props?.flag?.country
    : props?.flag?.id;

  return (
    <Button
      className={cx(props.selected && 'selectedOpt')}
      disabled={props.disabled}
      data-flag-id={flagCode}
      onClick={props.onClick || preventClick}
      padding={props.padding}
    >
      <span
        className="office_flag"
        data-flag-id={flagCode}
        onClick={preventClick}
      >
        <FlagIcon
          style={{ display: 'inline-block' }}
          code={flagCode}
          size={24}
          data-flag-id={flagCode}
          onClick={preventClick}
        />
      </span>
      <span
        className="office_name"
        data-flag-id={flagCode}
        onClick={preventClick}
      >
        {props?.flag?.label}
      </span>
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s1};
  border: ${theme.semanticProps.borderOffWhite2};
  padding: ${({ padding }) =>
    padding || `${theme.spacing.s2} ${theme.spacing.s3}`};
  background: transparent;
  width: 140px;
  z-index: 2;
  &.selectedOpt {
    background-color: ${theme.semanticColors.flagBtn};
    border-color: ${theme.semanticColors.flagSelectedBorder};
  }

  .office_name {
    margin-top: 2px;
    z-index: 1;
    text-align: left;
  }

  .office_flag {
    z-index: 1;
    &,
    img {
      clip-path: ellipse(35% 35% at 50% 50%);
    }
  }
`;
