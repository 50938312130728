import React from 'react';

import * as BasicGraphql from './graphql-common';

let AdminGraphql = React.createContext(null);

export let AdminGraphqlProvider = ({ client, children }) => {
  return <AdminGraphql.Provider value={client} children={children} />;
};

export let AdminGraphqlQuery = (options) => {
  let BasicQuery = BasicGraphql.GraphqlFactory(options);
  let sub_component = ({ options, ...props }) => (
    <AdminGraphql.Consumer>
      {(client) => <BasicQuery {...props} options={{ ...options, client }} />}
    </AdminGraphql.Consumer>
  );

  sub_component.query = BasicQuery.query;
  return sub_component;
};

export let AdminGraphqlMutation = (options) => {
  const BasicMutation = BasicGraphql.GraphqlMutation(options);

  return ({ options, ...props }) => (
    <AdminGraphql.Consumer>
      {(client) => (
        <BasicMutation {...props} options={{ ...options, client }} />
      )}
    </AdminGraphql.Consumer>
  );
};
