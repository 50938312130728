/**
 * Simple util to open passed URL in new tab using window API
 * @param url
 * @param options
 * @returns {function(): Window}
 * @see https://mathiasbynens.github.io/rel-noopener/
 */
export function openInNewWindow(url = '', options = 'noopener') {
  if (!url) return null;
  return () => window.open(url, '_blank', options);
}
