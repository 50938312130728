/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import theme from '../theme';
/**
 * @summary Allows you to detect clicks outside a specified element
 * @param ref element ref
 * @param handler function to be called on outside click
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 * @example
 * function App() {
 *   // Create a ref that we add to the element for which we want to detect outside clicks
 *   const ref = useRef();
 *   // State for our modal
 *   const [isModalOpen, setModalOpen] = useState(false);
 *   // Call hook passing in the ref and a function to call on outside click
 *   useOnClickOutside(ref, () => setModalOpen(false));
 *   return (
 *     <div>
 *       {isModalOpen ? (
 *         <div ref={ref}>
 *           👋 Hey, I'm a modal. Click anywhere outside me to close.
 *         </div>
 *       ) : (
 *         <button onClick={() => setModalOpen(true)}>Open Modal</button>
 *       )}
 *     </div>
 *   );
 * }
 */
export function useOnClickOutside(ref, handler, options = {}) {
  let more = Array.isArray(options.moreDep)
    ? options.moreDep
    : [options.moreDep];
  let deps = options?.noHandler ? [ref, ...more] : [ref, handler, ...more];

  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref?.current?.contains(event.target)) {
          return;
        }
        return handler(event);
      };
      document.addEventListener('mousedown', listener);
      if (theme.screen.width.medium700) {
        document.addEventListener('touchstart', listener);
      }

      return () => {
        document.removeEventListener('mousedown', listener);
        if (theme.screen.width.medium700) {
          document.removeEventListener('touchstart', listener);
        }
      };
    },

    // Adding ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [...deps]
  );
}
