import theme from 'fabric/theme';

export const ReturnsTabURLs = {
  INITIATE: 'INITIATE',
  INITIATED_INTERNAL: 'INITIATED_INTERNAL',
  INITIATED_CUSTOMER: 'INITIATED_CUSTOMER',
};

export const ReturnsTabTitles = {
  INITIATE: theme.screen.width.medium700 ? 'Initiate' : 'Initiate return',
  INITIATED_CUSTOMER: 'Initiated Returns',
  INITIATED_INTERNAL: 'Initiated internal',
};
