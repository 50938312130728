/**
 * @summary Centralized static & dynamic tracking text list
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 **/

export const trackers = Object.freeze({
  assets: Object.freeze({
    copy: Object.freeze({
      image: 'Copy Image Link',
      video: 'Copy Video Link',
      info: 'Copy Info',
    }),
    download: Object.freeze({
      image: 'Download Image',
      video: 'Download Video',
    }),
  }),
  search: Object.freeze({
    diamond: Object.freeze({
      // example of dynamic tracker
      getTrackerText: function getTrackerId(productType, trackingOrigin) {
        return `${productType} -> ${trackingOrigin} ${
          trackingOrigin ? '->' : ''
        } Add To Cart`;
      },
    }),
  }),
  orders: Object.freeze({
    acceptOrder: 'Accepted internal order',
    createOrder: 'Converted internal order --> PO',
    cancelOrder: 'Canceled internal order',
  }),
  holds: Object.freeze({
    acceptHold: 'Accepted Hold',
    cancelHold: 'Canceled Hold',
    extendHold: 'Extended Hold',
    rejectHold: 'Rejected Hold',
    requestExtensionHold: 'Requested Extension Hold',
    requestHold: 'Requested Hold',
    searchToDashboard: 'Search --> Hold Dashboard',
  }),
});

export default trackers;
