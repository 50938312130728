import { Button } from 'fabric/components';
import { CustomTrans } from 'helpers';
import { CustomInputWrapper, CustomSelectWrapper } from 'Signup/styles';
import { useState } from 'react';
import { theme } from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { CustomReactSelect } from 'fabric/components/CustomReactSelect';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Link } from '@reach/router';
let Div = styled.div`
  width: 100%;
  @media (max-width: 700px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px 24px;
    background: white;
    border-top: 1px solid #f0ecea;
  }
`;
export const PrimaryButton = ({ onClick, btnText, disabled, loading }) => {
  return (
    <Div>
      <Button
        className="continue_button"
        data-automation-id="continue-btn"
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            <Icon name="loader" size={16} color="black" />{' '}
            <CustomTrans text="loading" returnPlainText={true} />{' '}
          </span>
        ) : (
          <CustomTrans text={btnText} returnPlainText={true} />
        )}
      </Button>
      <div
        className="only-show-on-mobile "
        style={{ textAlign: 'center', margin: '0 auto' }}
      >
        <Trans
          i18nKey={'already_have_nivoda_account_2'}
          components={{
            lnk: (
              <Link
                to={'/login'}
                style={{
                  ...theme.fonts.mediumBold,
                  textDecoration: 'underline',
                  lineHeight: '24px',
                  fontFamily: 'Inter',
                }}
              />
            ),
          }}
        />
      </div>
    </Div>
  );
};

export const CustomInput = (props) => {
  const [showError, setShowError] = useState(false);

  const onBlur = () => {
    let onBlu = typeof props.onBlur === 'function' ? props.onBlur : () => {};
    onBlu();
    // Show error to trigger error evaluation logic
    setShowError(true);
  };
  const {
    type,
    id,
    onChange,
    errorStyle = {},
    labelStyle,
    label = '',
    placeholder,
    className = '',
    style = {},
    value,
    isRequired,
    hasError = false,
    skipError = false,
    errorMessage = '',
    place_by_side,
    prefix,
    postfix,
    onKeyPress,
    isShowTick,
    wrapperClass = '',
    ...rest
  } = props;
  let Local_style = place_by_side
    ? {
        display: 'flex',
        justifyContent: 'center',
      }
    : null;
  const validEmail = (email) => /^(.+)@(.+)\.(.+)$/.test(email);
  // Initialize is_error only when showError is true
  // To avoid showing on error when component mounts
  // And show only after focus blurs from the input
  // To another input
  const is_error =
    // check if dynamic error state passed from parent component
    hasError ||
    // if not then proceed with internal error logic
    (!skipError && showError
      ? type === 'phone'
        ? !isValidPhoneNumber(value)
        : type === 'email'
        ? isRequired && !validEmail(value)
        : isRequired && value === ''
      : false);
  return (
    <CustomInputWrapper
      style={Local_style}
      className={`custom_input_wrapper ${
        is_error ? 'has-error' : ''
      } ${wrapperClass}`}
    >
      {label && (
        <label
          className="custom_input_wrapper--label"
          htmlFor={id}
          style={{
            ...theme.fonts.smallBold,
            color: theme.palette.Neutrals950,
            fontFamily: 'Inter',
            lineHeight: '14.52px',
            ...labelStyle,
          }}
        >
          {label}
          {isRequired && <span>*</span>}
        </label>
      )}
      <div
        className={`custom_input_wrapper--input ${className} ${
          is_error ? 'control-error' : ''
        }`}
        style={prefix ? { position: 'relative' } : {}}
      >
        {prefix ? prefix : null}
        <input
          id={id}
          data-automation-id="signup-input-field"
          onChange={(e) => (onChange ? onChange(e.target.value) : '')}
          onBlur={onBlur}
          type={type ? type : 'text'}
          placeholder={placeholder ? placeholder : ''}
          value={value || ''}
          style={{
            ...style,
          }}
          onKeyPress={onKeyPress}
          {...rest}
          required={isRequired}
        />
        {isShowTick && (
          <Icon name="check" color={theme.palette.green1} size={16} />
        )}
        {postfix ? postfix : null}
      </div>
      {((isRequired && value === '' && !skipError && showError) || is_error) &&
        type !== 'email' && (
          <small className="has-error control-label" style={errorStyle}>
            {errorMessage ? (
              errorMessage
            ) : label && typeof label === 'string' ? (
              `${(
                <CustomTrans text={'please_enter'} returnPlainText={true} />
              )} ${label.toLowerCase()}`
            ) : (
              <CustomTrans text={'enter_valid_value'} returnPlainText={true} />
            )}
          </small>
        )}
      {value !== '' && isRequired && type === 'email' && is_error && (
        <small className="has-error control-label" style={errorStyle}>
          {errorMessage ? (
            errorMessage
          ) : (
            <CustomTrans text={'enter_valid_email'} returnPlainText={true} />
          )}
        </small>
      )}
    </CustomInputWrapper>
  );
};

export const CustomSelect = ({
  hasError,
  error,
  wrapperWidth,
  wrapperClass,
  ...props
}) => {
  let signup_styles = {
    control: (base) => ({
      ...base,
      border: '1px solid #C3BEBC',
      borderRadius: '8px !important',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
      color: '#27272A',
      // This line disable the blue border
      boxShadow: 'none !important',
      background: theme.palette.Neutrals100,
      width: '100%',
      height: '24px',
      cursor: 'pointer',
    }),
    placeholder: (base) => ({ ...base }),
    valueContainer: (base) => ({ ...base }),
  };
  let labelStyles = {
    ...theme.fonts.smallBold,
    lineHeight: '14.52px',
    fontFamily: 'Inter !important',
    color: theme.palette.Neutrals950,
    paddingLeft: '10px',
  };

  return (
    <CustomSelectWrapper>
      <CustomReactSelect
        {...props}
        styles={signup_styles}
        labelStyles={labelStyles}
        className={wrapperClass}
      />
      {hasError && (
        <span style={{ color: 'red', fontSize: '10.8px' }}>{error}</span>
      )}
    </CustomSelectWrapper>
  );
};
