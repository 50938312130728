import { gql } from '@apollo/client';
import { GraphqlFactory, GraphqlMutation } from 'graphql/factory';

export let AddCompanyUser = GraphqlMutation({
  query: gql`
    mutation AddCompanyUser($user: CompanyUserInput!) {
      add_company_user(user: $user) {
        id
      }
    }
  `,
});

export let EditCompanyUser = GraphqlMutation({
  query: gql`
    mutation AddCompanyUser($user: CompanyUserUpdateInput!) {
      update_company_user(user: $user) {
        id
        firstName
        lastName
        default_location {
          location {
            id
          }
        }
        role
        company {
          id
          company_users {
            id
            firstName
            lastName
            phone1
            email
            can_update_cfm_settings
            admin_review
            subtype
            default_location {
              location {
                id
              }
            }
          }
        }
      }
    }
  `,
});

export let GetUserProfile = GraphqlFactory(
  gql`
    query {
      me {
        id
        role
        country
        locations {
          location {
            id
            name
          }
        }
        company {
          id
          is_owner
          company_users {
            id
            firstName
            lastName
            profile_image
            phone1
            email
            country
            can_update_cfm_settings
            admin_review
            subtype
            locations {
              location {
                id
                name
              }
            }
            default_location {
              location {
                id
                name
              }
            }
          }
        }
      }
    }
  `
);

export let DeleteCompanyUser = GraphqlMutation({
  query: gql`
    mutation DeleteCompanyUser($user_id: ID!) {
      remove_company_user(user_id: $user_id) {
        id
        company {
          id
          company_users {
            id
            firstName
            lastName
            phone1
            email
            admin_review
            subtype
            can_update_cfm_settings
          }
        }
      }
    }
  `,
});
