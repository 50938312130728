import { css } from 'styled-components';
import theme from '../theme';
import { roundSkeletonStyles } from './skeletonStyles';

export const getTabStyles = (
  tabFor = 'common',
  rightPadding = theme.spacing.s4
) => css`
  .__custom_tab {
    width: auto;
    @media (max-width: 700px) {
      width: 100%;
      &,
      & * {
        ${theme.fonts.small};
      }
    }
    &s {
      .__custom_tab + div {
        margin-bottom: ${theme.spacing.s3};
        button {
          margin: 0;
        }
      }

      .${tabFor}_tab {
        padding-top: ${theme.spacing.s3};
        display: flex;
        align-items: center;
        justify-content: center;

        .${tabFor}_page__heading {
          &,
          &--skeleton {
            display: inline-flex;
            align-items: center;
            gap: ${theme.spacing.s1};
          }
          &--skeleton {
            ${roundSkeletonStyles('16px')};
          }
        }
      }
    }
  }
  &.__custom_tab_wrapper {
    padding-left: ${theme.spacing.s1};
    padding-right: ${rightPadding};
    height: auto;
    overflow-y: auto;

    .${tabFor}_body {
      background: ${theme.palette.white};
    }
    @media (max-width: 700px) {
      padding-right: ${theme.spacing.s1};
      .${tabFor}_body {
        background: transparent;
      }
      .__custom_tabs {
        margin-top: ${theme.spacing.s1};
      }
    }
  }
`;

export const getNewTabStyles = (
  tabFor = 'common',
  rightPadding = theme.spacing.s4
) => css`
  ${getTabStyles(tabFor, rightPadding)};
  .__custom_tab {
    &s {
      .__custom_tab + div {
        margin-bottom: 0;
      }
    }
  }
`;
