import React from 'react';
import { Button } from '@nivoda/ui';

import theme from '../../theme';
import { isMobile } from 'utils';
import { LoadingIcon } from '../Loaders/LoadingIcon';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';

export const EnterButton = (props) => {
  return (
    <Div>
      <Button
        className="action__btn"
        disabled={props.disabled}
        customPadding={
          isMobile()
            ? `${theme.spacing.s2} ${theme.spacing.s3}`
            : `${theme.spacing.s1} ${theme.spacing.s2}`
        }
        onClick={props.onClick}
        title={props.title || ''}
        ref={props._ref}
        {...props}
        data-automation-id="action--btn"
      >
        {props.isLoading ? (
          <LoadingIcon />
        ) : (
          <span className="action__arrow">
            {isMobile() ? `${props.mobileLabel || 'Search'} ⭢` : '⭢'}
          </span>
        )}
      </Button>
    </Div>
  );
};

EnterButton.propTypes = {
  disabled: bool,
  isLoading: bool,
  onClick: func,
  mobileLabel: string,
  title: string,
};

const Div = styled.div`
  display: inline-flex;
  align-items: center;

  .action {
    &__arrow {
      ${theme.fonts.large};
      @media (max-width: 700px) {
        ${theme.fonts.medium};
      }
    }

    &__btn {
      width: 45px;
      margin: 0 ${theme.spacing.s1};
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 700px) {
        margin-left: 0;
        margin-top: ${theme.spacing.s2};
        width: auto;
      }
    }
  }
`;
