/* eslint-disable no-undef */
/* eslint-disable no-console */

import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useReactiveVar } from '@apollo/client';
import { printerConfigVar } from '../../graphql/factory/reactiveVar';
import {
  getPrinterConfig,
  logPrinterConfig,
  getPrinterConfigFromLocalStorage,
  savePrinterConfig,
  resetPrinterConfig,
  MOCK_ZEBRA_PRINTER,
} from 'graphql/factory/printerRequestResolver';

const DEFAULT_DPI = 300;

// default Zebra device URL/port where the BrowserPrint utility is listening
const DEVICE_URL = 'http://127.0.0.1:9100';
const DEVICE_KEYS = [
  'name',
  'uid',
  'connection',
  'deviceType',
  'version',
  'provider',
  'manufacturer',
];

/**
 * Method to check if input string is valid url
 * isURL method from utils returns false when barcode text contains '/' in case of envelope barcode
 * @param {*} url
 * @returns true/false
 */
const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid url ${url} passed. ${e}`);
    return false;
  }
  return true;
};

/**
 * Hook to connect and print from zebra printer
 * @param {*} connectPrinterTimeout initial time to connect to printer
 * @param {*} printButtonDisabledTimeout time for which printing is disabled
 * @returns dpi,label dimensions, printLabel fx etc
 */
export const useZebraPrinter = (defaultValues = {}) => {
  const { printerConnectionTimeout = 6000, printButtonDisabledTimeout = 4000 } =
    defaultValues;

  const printerConfig = useReactiveVar(printerConfigVar);
  const [printDisabled, setPrintDisabled] = useState(false);

  const { selectedDevice, label_width, label_height } = printerConfig || {};

  const changePrinterDisabledState = useCallback(
    (value) => {
      setPrintDisabled(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [printerConfig]
  );

  const addPrintDisabledInterval = useCallback(
    (interval = printButtonDisabledTimeout) => {
      changePrinterDisabledState(true);
      setTimeout(() => {
        changePrinterDisabledState(false);
      }, interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [printerConfig]
  );

  const open_url = (url) => {
    if (isValidUrl(url)) {
      setTimeout(() => {
        window.open(url);
      }, 1000);
    }
  };

  const getDevicePayload = () => {
    const device = {};
    for (const key of DEVICE_KEYS) {
      device[key] = selectedDevice[key];
    }
    return device;
  };

  const printLabel = async ({ zpl, url }) => {
    if (printDisabled) {
      toast.error(`Please try in few secs`);
      return;
    }
    if (!zpl || zpl.startsWith('error')) {
      toast.error(`Invalid content. ${url ? 'Opening Pdf' : ''}`);
      open_url(url);
      return;
    }

    let connected_device = selectedDevice;
    if (!connected_device) {
      fetchPrinterConfig();
      return;
    }

    if (MOCK_ZEBRA_PRINTER) {
      console.log(`Mock print:\n ${zpl}`);
      toast.success('Label printed');
    } else if (connected_device) {
      try {
        await fetch(`${DEVICE_URL}/write`, {
          method: 'POST',
          body: JSON.stringify({
            device: getDevicePayload(),
            data: zpl,
          }),
        });

        toast.success('Label printed');
      } catch (error) {
        toast.error(`Error: ${error}`);
      }
    }

    addPrintDisabledInterval();
  };

  const fetchPrinterConfig = useCallback(() => {
    getPrinterConfig()
      .then((config) => {
        const updatedState = logPrinterConfig(config);
        savePrinterConfig(updatedState);
        setPrintDisabled(false);
      })
      .catch((error) => {
        console.error('Error connecting to printer:', error);
        resetPrinterConfig();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDevice) return;
    const config = getPrinterConfigFromLocalStorage();
    if (config) {
      printerConfigVar(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectToPrinter = useCallback(() => {
    addPrintDisabledInterval(printerConnectionTimeout);
    fetchPrinterConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dpi: DEFAULT_DPI,
    label_height,
    label_width,
    printerConfig,
    isPrinterReady: !!(selectedDevice && label_height && label_width),
    printDisabled,
    printLabel,
    connectToPrinter,
    clearPrinterConfig: resetPrinterConfig,
  };
};
