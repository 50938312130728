import styled from 'styled-components';
import theme from '../../theme';

export const Div = styled.div`
  & > div:first-child {
    box-shadow: none;
  }
`;

export const Ul = styled.ul`
  &.pagination_container {
    display: flex;
    justify-content: ${({ position = 'right' }) =>
      position === 'apart' ? 'justify-content' : position};
    align-items: center;
    list-style-type: none;
    margin: ${theme.spacing.s3} 0;

    .pagination_item {
      padding: 0 ${theme.spacing.s2};
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto 6px;
      color: rgba(0, 0, 0, 0.87);
      box-sizing: border-box;
      letter-spacing: 0.01071em;
      border-radius: ${theme.spacing.s4};
      min-width: 24px;

      ${theme.fonts.absolute.small}
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }

      &.selected {
        background-color: rgba(0, 0, 0, 0.08);
        cursor: default;
      }

      .arrow {
        &::before {
          position: relative;
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }

        &.left {
          transform: rotate(-135deg) translate(-50%);
        }

        &.right {
          transform: rotate(45deg);
        }
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
`;
