import {
  CertificateFragment,
  GemstoneFragment,
  MeleeFragment,
} from 'graphql/fragments';
import { AdminGraphqlQuery } from '../../../graphql-admin';
import { CartItemFragment } from '../../fragments/CartItemFragment';
import { ShortlistItemFragment } from '../../fragments/ShortlistFragment';
import { gql } from '@apollo/client';
import { UserProfileDetailsFragment } from 'graphql/fragments/UserFragment';

export let AdminUserById = AdminGraphqlQuery({
  query: gql`
    query ($id: ID!) {
      user(id: $id) {
        id
        profile_image
        firstName
        lastName
        email
        country
        steps_required
        default_location {
          location {
            id
            name
            country
            address1
            address2
            postalCode
            city
            state
          }
        }
        locations {
          location {
            id
            name
            address1
            city
            postalCode
            state
            country
          }
        }
        company {
          name
          id
          company_settings {
            CompanyId
            memo_enabled
          }
        }
        company_role
        phone1
        is_hidden
        cart_items {
          ...CartItemFragment
        }
        shortlist_items {
          ...ShortlistItemFragment
        }
        default_currency
        preferred_language
        subtype
        can_update_cfm_settings
        verifyStatus
      }
    }
    ${CartItemFragment}
    ${ShortlistItemFragment}
  `,
  map_data: (props) => {
    let { user } = props;

    let mapped = (item) => {
      if (!item || item.offer == null) return null;
      return {
        ...item,
        offer: {
          ...item.offer,
          Product: {
            ...item.offer.Product,
            certificate:
              item?.offer?.Product?.certificate ??
              item?.offer?.Product?.GemstoneCertificate,
          },
        },
      };
    };
    let cart_items =
      user?.cart_items?.map(mapped).filter((r) => r.offer != null) || [];
    let shortlists =
      user?.shortlists?.map(mapped).filter((r) => r.offer != null) || [];

    let new_user = {
      ...user,
      cart_items,
      shortlists,
    };
    return {
      ...props,
      user: new_user,
    };
  },
});
// export let AdminUserOrdersById = AdminGraphqlQuery({
export let ADMIN_USER_ORDERS_BY_ID = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      order_items(limit: 20, query: { users: [$id] }) {
        items {
          order_number
          order {
            id
            order_date
            user_comment
          }
          offer {
            id
            price
          }
          deliveryDate
          Product {
            ... on Diamond {
              diamondSupplierStockId
              supplierStockId
              certificate {
                ...CertificateFragment
              }
            }
            ... on Melee {
              ...MeleeFragment
              child_melee_collections {
                ...MeleeFragment
                carats
                supplierStockId
                total_price
                price_per_carat
                IsParentMeleeCollection
              }

              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            ... on Gemstone {
              ...GemstoneFragment
              gemstoneSupplierStockId
              supplierStockId
            }
          }
          ProductType
          status
        }
      }
    }
  }
  ${CertificateFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
`;

export const GET_ALL_SUPPLIERS = gql`
  query getAllSuppliers {
    all_suppliers {
      items {
        id
        name
      }
    }
  }
`;

export let AdminAllSuppliers = AdminGraphqlQuery({
  query: GET_ALL_SUPPLIERS,
});

export const GetCustomerList = AdminGraphqlQuery({
  query: gql`
    query ($q: String!, $type: String) {
      search_by_company(q: $q, type: $type) {
        items {
          name
          id
        }
      }
    }
  `,
});
export const GET_COMPANY_ADMINS = gql`
  query getCompanyAdmins {
    me {
      id
      company {
        id
        company_users {
          id
          firstName
          lastName
          profile_image
        }
      }
    }
  }
`;
export const GetCompanyAdmins = AdminGraphqlQuery(GET_COMPANY_ADMINS);

export const AdminProfileComprehensive = AdminGraphqlQuery({
  query: gql`
    query ProfileBasics {
      me {
        admin_preference {
          id
          user_id
          qc_location {
            id
            name
            city
            country
          }
        }
        company {
          id
          company_settings {
            CompanyId
            action_other_user_hold
          }
        }
        ...UserProfileDetailsFragment
      }
    }
    ${UserProfileDetailsFragment}
  `,
});
