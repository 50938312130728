import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CheckboxMedia, CustomRadio } from '@nivoda/forms';
import { Button, LoadingSkeleton, Tooltip } from '@nivoda/ui';
import axios from 'axios';
import { SwitchV3 } from 'components/filter-drawer/switchV3';
import { MarkupModal } from 'components/markup';
import { Input } from 'components/ui/Input';
import { shareTypes } from 'constants/download';
import { validate } from 'email-validator';
import { useErrorWrapper } from 'fabric/hooks';
import { Track } from 'fabric/integrations';
import { Icon } from 'fabric/resources/icon';
import { graphqlClient } from 'graphql/factory';
import { CREATE_SHARE_LIST } from 'graphql/mutations/common/sharelist';
import { GET_PRODUCT_CFM_MARKUP_DETAILS } from 'graphql/queries/common/cfmMarkupDetails';
import { GET_COMPANY_MARKUPS } from 'graphql/queries/common/company';
import { PROFILE_BASICS } from 'graphql/queries/common/profile';
import capitalize from 'lodash/capitalize';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { useAsState } from 'utils';
import {
  LinkModal,
  MobilePhoneWrapper,
  calculateNewPrice,
  calculatePercentageIncrease,
  convertToNumber,
  format_price,
  get_carat_price,
  shareViaWhatsApp,
  share_detail_list as share_list,
} from './helper';
import {
  EmailPreviewSection,
  SMSPreviewSection,
  WhatsAppPreviewSection,
} from './preview';
import {
  ListGroup,
  ListSection,
  PreviewSection,
  SkeletonElement,
  Span,
  StyledInput,
  StyledMaskedInput,
  StyledReqPopup,
} from './style';
import { useUserContext } from 'context/userContext';

let initiateFormStatus = {
  hasEnterPhone: false,
  phone_is_valid: false,
};

export const ShareModal = ({
  clearModalState,
  data: product,
  share_video_preview,
  shareType,
  view_details_link,
  isOpen,
}) => {
  let product_type = product?.__typename;
  const { t } = useTranslation();
  const {
    data: profile_basics,
    loading: profile_loading,
    refetch: profile_refetch,
  } = useQuery(PROFILE_BASICS, {
    client: graphqlClient,
  });

  const [
    getMarkupDetails,
    { loading: markupDetailsLoading, data: markupDetails },
  ] = useLazyQuery(GET_PRODUCT_CFM_MARKUP_DETAILS, {
    client: graphqlClient,
  });
  const cfm_markup_details = markupDetails?.get_product_cfm_markup_details;
  const { userInReview } = useUserContext();
  const enabledAttributes =
    profile_basics?.me?.share_list_enabled_product_info ?? [];
  const role = profile_basics?.me?.role;
  const is_owner = profile_basics?.me?.company?.is_owner ?? false;
  const exchange_rate = profile_basics?.me?.exchange_rate?.to_USD ?? 1;
  const exchange_currency = profile_basics?.me?.exchange_rate?.name ?? 'USD';

  const [initial_price_string, initial_price] = format_price({
    amount: product?.price,
    exchangeRate: exchange_rate,
    currency: exchange_currency,
  });

  const [markup_price_string, markup_price] = format_price({
    amount: cfm_markup_details?.cfm_markup_price / 100,
    exchangeRate: exchange_rate,
    currency: exchange_currency,
  });
  const initialState = {
    parameters: share_list({ data: product, t }),
    email: '',
    phone: '',
    include_price: false,
    markup: 'one_off_markup',
    markup_rate: 0,
    markup_price: initial_price_string,
    share_link: '',
    open_markup_center: false,
  };
  const [fnWrapper, context] = useErrorWrapper({
    useToastNotification: shareType === shareTypes.WHATSAPP ? false : true,
  });
  const [share_details, set_share_details] = React.useState(initialState);
  let [formStatus, setFormStatus] = useAsState(initiateFormStatus);
  let [countryData, setCountryData] = React.useState({
    defaultCountry: '',
  });
  const [
    getCfmSettings,
    {
      loading: cfmSettingsLoading,
      data: cfmSettingsData,
      refetch: cfm_settings_refetch,
    },
  ] = useLazyQuery(GET_COMPANY_MARKUPS, {
    client: graphqlClient,
    onCompleted: () => {
      set_share_details({ ...share_details, open_markup_center: true });
    },
  });

  const [createShareList, { loading: shareListLoading }] = useMutation(
    CREATE_SHARE_LIST,
    {
      client: graphqlClient,
      onCompleted: async (data) => {
        const link = data?.create_share_list ?? '';
        handleChange('share_link', link);
        await profile_refetch();
      },
    }
  );

  const [open_customize, set_open_customize] = React.useState(false);
  const selectedDetails = share_details.parameters.filter(
    (detail) => detail.isSelected
  );
  const selectedDetailsIds = selectedDetails.map((i) => i.id);

  const SHARE_VIA_WHATSAPP = shareType === shareTypes.WHATSAPP;
  const SHARE_VIA_EMAIL = shareType === shareTypes.EMAIL;
  const SHARE_VIA_SMS = shareType === shareTypes.SMS;

  const handleChange = (key, value) => {
    return set_share_details((prev) => ({ ...prev, [key]: value }));
  };

  const handleCheckboxChange = (id) => {
    set_share_details((prevState) => {
      const updatedParameters = prevState.parameters.map((detail) =>
        detail.id === id && detail.editable
          ? { ...detail, isSelected: !detail.isSelected }
          : detail
      );

      return {
        ...prevState,
        parameters: updatedParameters,
      };
    });
  };

  const is_markup_center = share_details.markup === 'markup_center';
  const is_one_off_markup = share_details.markup === 'one_off_markup';

  const price_toggle = {
    value: share_details.include_price,
    isActive: share_details.include_price,
  };

  const generateWhatsAppMessage = ({
    selectedDetails,
    include_price,
    price,
    price_per_carat,
    link,
  }) => {
    const selectedItems = selectedDetails
      .map((detail) => {
        const value = detail.value ? detail.value.trim() : '';
        if (value) {
          return detail.isBold ? `*${value}*` : `· ${value}`;
        } else {
          return '';
        }
      })
      .filter(Boolean)
      .join(' ');

    const priceInfo = include_price ? `*${price}* (${price_per_carat})` : '';
    return priceInfo
      ? `${selectedItems}\n${priceInfo}\n${link}`
      : `${selectedItems}\n${link}`;
  };

  const handleSaveParams = (items) => {
    localStorage.setItem('enabled_product_info', JSON.stringify(items));
    Track.track(
      `${capitalize(shareType)} share opened -> Share setting updated.`,
      {
        preferred_config: items,
      }
    );
  };

  const handleShare = ({ shared_via }) => {
    fnWrapper(
      async () => {
        context.setBusy({ type: 'create share list', status: true });
        const enabled_product_info = [...selectedDetailsIds];

        if (share_details.include_price) {
          enabled_product_info.push('PRICE');
        } else {
          const priceIndex = enabled_product_info.indexOf('PRICE');
          if (priceIndex !== -1) {
            enabled_product_info.splice(priceIndex, 1);
          }
        }

        const recipient_details = SHARE_VIA_EMAIL
          ? share_details.email
          : SHARE_VIA_SMS
          ? share_details.phone
          : '';
        const customer_price = is_markup_center
          ? markup_price
          : parseFloat(share_details.markup_price.replace(/[^\d.]/g, ''));

        handleSaveParams([
          ...enabled_product_info,
          share_details.markup.toUpperCase(),
        ]);

        //properties to exclude from share_list_params in payload
        let exclude_params_properties = ['LAB', 'CERTIFICATE'];

        const payload = {
          share_list_products: [
            {
              product_id: product?.id,
              product_type: product_type,
              customer_price: customer_price,
              customer_currency: exchange_currency,
            },
          ],
          share_list_params: {
            enabled_product_info: enabled_product_info.filter(
              (r) => !exclude_params_properties.includes(r)
            ),
            recipient_details: recipient_details,
            shared_via: shared_via,
          },
        };
        await createShareList({
          variables: payload,
        });

        context.setBusy({ type: '', status: false });
      },
      null,
      {
        error: {
          messageResolver: () => {
            return <>{t('error_generating_link')}</>;
          },
        },
        success: {
          messageResolver: () =>
            SHARE_VIA_EMAIL
              ? t('email_sent_successfully')
              : SHARE_VIA_SMS
              ? t('message_sent_successfully')
              : undefined,
        },
      }
    );
  };

  const product_price = is_markup_center
    ? markup_price_string
    : share_details.markup_price;

  const product_carat_price = get_carat_price({
    carats: product?.certificate?.carats,
    price: is_markup_center
      ? markup_price
      : convertToNumber(share_details.markup_price),
    exchangeRate: exchange_rate,
    currency: exchange_currency,
  });

  const handlePriceChange = (e) => {
    const currentPrice = e.target.value.replace(/[^\d.]/g, '');
    const [formattedPrice, price] = format_price({
      amount: currentPrice,
      currency: exchange_currency,
    });
    Track.track(
      `${capitalize(shareType)} share opened -> Share price updated.`,
      {
        price_type: share_details.markup,
        markup_price: formattedPrice,
      }
    );

    handleChange('markup_price', !price ? 0 : formattedPrice);
    const newPercentage = calculatePercentageIncrease(
      initial_price,
      parseFloat(price)
    );
    if (newPercentage) handleChange('markup_rate', newPercentage);
  };

  const handlePercentageChange = (e) => {
    const percentage = e.target.value;
    handleChange('markup_rate', percentage);

    Track.track(
      `${capitalize(shareType)} share opened -> Share percentage updated.`,
      {
        price_type: share_details.markup,
        markup_price: percentage,
      }
    );

    // eslint-disable-next-line no-unused-vars
    const [_, price] = format_price({
      amount: product?.price,
      exchangeRate: exchange_rate,
      currency: exchange_currency,
    });

    const newPrice = calculateNewPrice(price, parseFloat(percentage) || 0);
    const [formattedPrice] = format_price({
      amount: newPrice,
      currency: exchange_currency,
    });
    if (newPrice) handleChange('markup_price', formattedPrice);
  };

  useEffect(() => {
    const localSavedItems =
      JSON.parse(localStorage.getItem('enabled_product_info')) || [];
    const isOneOfMarkup = localSavedItems?.find((x) => x === 'ONE_OFF_MARKUP');
    const isMarkupCenter = localSavedItems?.find((x) => x === 'MARKUP_CENTER');

    let defaultProductInfo = [];
    if (localSavedItems && localSavedItems.length)
      defaultProductInfo = [...localSavedItems];
    else if (selectedDetailsIds && selectedDetailsIds.length)
      defaultProductInfo = [...selectedDetailsIds];
    else if (enabledAttributes && enabledAttributes.length)
      defaultProductInfo = [...enabledAttributes];

    if (!isOneOfMarkup && !isMarkupCenter) {
      const updated = defaultProductInfo.includes('PRICE')
        ? defaultProductInfo
        : [...defaultProductInfo, 'PRICE'];
      localStorage.setItem(
        'enabled_product_info',
        JSON.stringify([...updated, 'ONE_OFF_MARKUP'])
      );
    }

    if (isMarkupCenter) {
      getMarkupDetails({
        variables: {
          product_id: product?.id,
          product_type: product_type,
        },
        fetchPolicy: 'network-only',
      });
    }

    const markup = (
      isOneOfMarkup ||
      isMarkupCenter ||
      'ONE_OFF_MARKUP'
    ).toLowerCase();

    if (localSavedItems.length > 0) {
      const updatedParameters = share_details.parameters.map((parameter) => ({
        ...parameter,
        isSelected:
          !parameter.editable || localSavedItems.includes(parameter.id),
      }));

      set_share_details((prevShareDetails) => ({
        ...prevShareDetails,
        parameters: updatedParameters,
        include_price: localSavedItems.includes('PRICE'),
        markup,
      }));
    } else if (isOpen && !profile_loading && enabledAttributes?.length > 0) {
      const updatedParameters = share_details.parameters.map((parameter) => ({
        ...parameter,
        isSelected:
          !parameter.editable || enabledAttributes.includes(parameter.id),
      }));

      set_share_details((prevShareDetails) => ({
        ...prevShareDetails,
        parameters: updatedParameters,
        include_price: enabledAttributes.includes('PRICE'),
        markup,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, profile_loading, enabledAttributes]);

  useEffect(() => {
    if (SHARE_VIA_WHATSAPP && share_details.share_link) {
      const message = generateWhatsAppMessage({
        selectedDetails,
        include_price: share_details.include_price,
        price: product_price,
        price_per_carat: product_carat_price,
        link: share_details.share_link,
      });
      shareViaWhatsApp({ message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [share_details.share_link]);

  useEffect(() => {
    if (shareType !== shareTypes.SMS) return;
    let phone_country_code = localStorage.getItem('phone_country_code');
    let defaultCountry = localStorage.getItem('defaultCountry');
    let setFromLocal = async () => {
      if (defaultCountry != null && phone_country_code != null) {
        setCountryData({
          defaultCountry: defaultCountry,
          phone_country_code: phone_country_code,
        });
      }
    };
    const getGeoInfo = async () => {
      axios
        .get('https://ipapi.co/json/')
        .then((response) => {
          let data = response.data;

          setCountryData({
            country: role !== 'CUSTOMER' ? data.country_code : '',
            defaultCountry: data.country_code,
            phone_country_code: data.country_calling_code.replace('+', ''),
          });
          localStorage.setItem('defaultCountry', data.country_code);
          localStorage.setItem(
            'phone_country_code',
            data.country_calling_code.replace('+', '')
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    };

    function loadData() {
      if (phone_country_code && defaultCountry) {
        return setFromLocal();
      } else getGeoInfo();
    }
    loadData();
    return () => {
      loadData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateAmount = () => {
    let state_price = 0;
    if (typeof share_details.markup_price === 'string') {
      const formate_price = share_details.markup_price.replace(/[^\d.]/g, '');
      state_price = parseFloat(formate_price);
    } else {
      state_price = share_details.markup_price;
    }
    return initial_price.toFixed(2) > state_price;
  };

  const isEmailValid =
    !shareListLoading &&
    !markupDetailsLoading &&
    share_details.email &&
    validate(share_details.email.toLowerCase().trim());

  const isPhoneValid =
    !shareListLoading &&
    !markupDetailsLoading &&
    share_details.phone.length > 0 &&
    isValidPhoneNumber(share_details.phone);

  const commonProps = {
    share_video_preview,
    share_details,
    selectedDetails,
    data: product,
    view_details_link,
    product_price,
    product_carat_price,
    markupDetailsLoading,
    product_type,
    stoneType: product?.certificate?.labgrown ? 'Labgrown' : 'Natural',
  };

  return (
    <React.Fragment>
      {share_details.share_link && !SHARE_VIA_WHATSAPP ? (
        <LinkModal
          link={share_details.share_link}
          message={
            SHARE_VIA_SMS
              ? t('message_sent_successfully')
              : t('email_sent_successfully')
          }
          clearModalState={() => {
            handleChange('share_link', '');
            clearModalState();
          }}
        />
      ) : (
        <StyledReqPopup
          clearModalState={clearModalState}
          noMaxHeight
          showBorder={false}
          remove_top_margin
          width={SHARE_VIA_EMAIL ? '1000px' : '760px'}
          hasMedia={
            !!product?.certificate?.v360 || !!product?.certificate?.image
          }
        >
          <LoadingSkeleton>
            {share_details.open_markup_center && (
              <MarkupModal
                data={cfmSettingsData}
                onClose={() => handleChange('open_markup_center', false)}
                isLoading={cfmSettingsLoading}
                refetchData={cfm_settings_refetch}
                cb={() =>
                  getMarkupDetails({
                    variables: {
                      product_id: product?.id,
                      product_type: product_type,
                    },
                    fetchPolicy: 'network-only',
                  })
                }
              />
            )}
            <div className="share-popup-header">
              <span className="share-popup-title">
                {SHARE_VIA_EMAIL
                  ? t('share_to_email')
                  : SHARE_VIA_SMS
                  ? t('share_to_sms')
                  : t('share_to_whatsapp')}
              </span>
              <span
                className="share-popup-close"
                onClick={() => clearModalState()}
              >
                <Icon name="close" size={12} />
              </span>
            </div>

            <div className="share-popup-content">
              <ListSection width={SHARE_VIA_EMAIL ? '40%' : '54%'}>
                {SHARE_VIA_EMAIL && (
                  <React.Fragment>
                    <ListGroup>
                      {sectionHeading({
                        title: t('recipient_email'),
                        show_tooltip: false,
                        tooltipPlacement: 'right',
                      })}
                      <StyledInput>
                        <Input
                          placeholder="customer@email.com"
                          value={share_details.email}
                          onChange={(value) => handleChange('email', value)}
                          errorMessage={t('please_enter_valid_email_address')}
                          hasError={
                            share_details.email.length > 0 &&
                            !validate(share_details.email.toLowerCase().trim())
                          }
                        />
                      </StyledInput>
                    </ListGroup>
                    <div className="section--divider" />
                  </React.Fragment>
                )}

                {SHARE_VIA_SMS && (
                  <React.Fragment>
                    <ListGroup>
                      {sectionHeading({
                        title: t('recipient_phone_number'),
                        show_tooltip: false,
                        tooltipPlacement: 'right',
                      })}
                      <MobilePhoneWrapper
                        phone={share_details.phone}
                        setFormValues={handleChange}
                        setFormStatus={setFormStatus}
                        phone_is_valid={formStatus.phone_is_valid}
                        hasEnterPhone={formStatus.hasEnterPhone}
                        phone_country_code={countryData.phone_country_code}
                        defaultCountry={countryData.defaultCountry}
                      />
                    </ListGroup>
                    <div className="section--divider" />
                  </React.Fragment>
                )}
                <ListGroup className="shared-details-section">
                  {sectionHeading({
                    title: t('shared_details'),
                    tooltipContent: t('shared_details_tooltip_text'),
                    tooltipPlacement: 'bottom',
                  })}
                  {selectedDetails && selectedDetails?.length > 0 && (
                    <SkeletonElement
                      className="selected-shared-details skeleton"
                      width="100%"
                      height="50px"
                    >
                      {!profile_loading &&
                        selectedDetails.map((item, index) => {
                          return (
                            <span key={index} className="shared-item">
                              {/* {index === 0
                                ? item.translated_key
                                : index === selectedDetails.length - 1
                                ? item.translated_key
                                : `${item.translated_key} · `} */}
                              {index === selectedDetails.length - 1
                                ? item.translated_key
                                : `${item.translated_key} · `}
                            </span>
                          );
                        })}
                    </SkeletonElement>
                  )}
                  {open_customize && (
                    <React.Fragment>
                      {share_details?.parameters?.length > 0 && (
                        <div className="customize-details">
                          {share_details?.parameters.map((item, i) => {
                            return (
                              <CheckboxMedia
                                checked={item.isSelected || !item.editable}
                                disabled={!item.editable}
                                onChange={() => {
                                  handleCheckboxChange(item.id);
                                }}
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '20px',
                                }}
                                key={i}
                              >
                                <div
                                  className="checkbox-label"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '4px',
                                  }}
                                >
                                  <span>{item.translated_key}</span>
                                </div>
                              </CheckboxMedia>
                            );
                          })}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  {!profile_loading && (
                    <Button
                      className="customize-details-btn"
                      ghost
                      noShadow
                      onClick={() => {
                        set_open_customize(!open_customize);
                        if (!open_customize) return;
                        let temp = JSON.parse(
                          localStorage.getItem('enabled_product_info')
                        );
                        if (
                          temp &&
                          typeof temp === 'object' &&
                          temp.includes('PRICE')
                        ) {
                          const localSavedItems =
                            JSON.parse(
                              localStorage.getItem('enabled_product_info')
                            ) || [];
                          const isOneOfMarkup = localSavedItems?.find(
                            (x) => x === 'ONE_OFF_MARKUP'
                          );
                          const isMarkupCenter = localSavedItems?.find(
                            (x) => x === 'MARKUP_CENTER'
                          );
                          const markup =
                            isOneOfMarkup || isMarkupCenter || 'ONE_OFF_MARKUP';

                          handleSaveParams([
                            ...selectedDetailsIds,
                            'PRICE',
                            markup,
                          ]);
                        } else handleSaveParams(selectedDetailsIds);
                      }}
                    >
                      <Icon
                        name="apply-filters"
                        width={16}
                        height={16}
                        color={'#27272A'}
                      />
                      <span className="customize-details-btn-label">
                        {open_customize
                          ? t('save_customisation_options')
                          : t('customise_details_to_share')}
                      </span>
                    </Button>
                  )}
                </ListGroup>

                <div className="section--divider" />

                {!userInReview && (
                  <ListGroup className="shared-price-section">
                    {sectionHeading({
                      title: t('shared_price'),
                      tooltipContent: t('shared_price_tooltip_text'),
                    })}

                    <Switch
                      label={t('include_price_in_message')}
                      item={price_toggle}
                      onChange={(value) => {
                        handleChange('include_price', !value);
                        let temp = JSON.parse(
                          localStorage.getItem('enabled_product_info')
                        );
                        if (temp && typeof temp === 'object') {
                          const delIndex = temp?.findIndex(
                            (x) => x === 'PRICE'
                          );
                          if (delIndex !== -1) {
                            temp.splice(delIndex, 1);
                          } else if (!value) {
                            temp.push('PRICE');
                          }
                        } else if (!value) {
                          temp = ['PRICE'];
                        }
                        if (temp?.length > 0) handleSaveParams(temp);
                      }}
                      active={price_toggle.isActive}
                    />
                    {share_details.include_price && (
                      <React.Fragment>
                        <CustomRadio
                          className="custom_radio_font"
                          checked={is_one_off_markup}
                          setChecked={({
                            target: {
                              dataset: { targetValue: role },
                            },
                          }) => {
                            handleChange('markup', role);
                            const items = JSON.parse(
                              localStorage.getItem('enabled_product_info')
                            );
                            if (items && items?.length) {
                              const index = items.findIndex(
                                (x) => x === 'MARKUP_CENTER'
                              );
                              if (index !== -1) {
                                items.splice(index, 1);
                              }
                              localStorage.setItem(
                                'enabled_product_info',
                                JSON.stringify([...items, 'ONE_OFF_MARKUP'])
                              );
                            }

                            Track.track(
                              `${capitalize(
                                shareType
                              )} share opened -> Share price updated.`,
                              {
                                price_type: 'one_off_markup',
                              }
                            );
                          }}
                          value={'one_off_markup'}
                          label={t('share_with_a_one_off_markup')}
                        />

                        <CustomRadio
                          className="custom_radio_font"
                          checked={is_markup_center}
                          setChecked={({
                            target: {
                              dataset: { targetValue: role },
                            },
                          }) => {
                            handleChange('markup', role);
                            const items = JSON.parse(
                              localStorage.getItem('enabled_product_info')
                            );
                            if (items && items?.length) {
                              const index = items.findIndex(
                                (x) => x === 'ONE_OFF_MARKUP'
                              );
                              if (index !== -1) {
                                items.splice(index, 1);
                              }
                              localStorage.setItem(
                                'enabled_product_info',
                                JSON.stringify([...items, 'MARKUP_CENTER'])
                              );
                            }

                            Track.track(
                              `${capitalize(
                                shareType
                              )} share opened -> Share price updated.`,
                              { price_type: 'markup_center' }
                            );
                            getMarkupDetails({
                              variables: {
                                product_id: product?.id,
                                product_type: product_type,
                              },
                              fetchPolicy: 'network-only',
                            });
                          }}
                          value={'markup_center'}
                          label={
                            <React.Fragment>
                              {t('use_markup_from_markup_centre')}{' '}
                              {is_markup_center &&
                                !markupDetailsLoading &&
                                `(${cfm_markup_details?.cfm_markup_percentage}%)`}
                            </React.Fragment>
                          }
                        />

                        {share_details.markup === 'one_off_markup' ? (
                          <div className="markup-and-final-price">
                            <MarkupInput
                              label={`${t('markup')} %`}
                              mask={createNumberMask({
                                prefix: '',
                                allowDecimal: true,
                                decimalSymbol: '.',
                                decimalLimit: 2,
                                integerLimit: 3,
                              })}
                              value={share_details.markup_rate}
                              placeholder="15.32"
                              onChange={(e) => handlePercentageChange(e)}
                              suffix={'%'}
                              onBlur={() => {
                                if (!share_details.markup_rate) {
                                  handleChange('markup_rate', 0);
                                }
                              }}
                            />
                            <MarkupInput
                              label={t('final_price')}
                              mask={createNumberMask({
                                prefix: exchange_currency,
                                allowDecimal: true,
                                decimalSymbol: '.',
                                decimalLimit: 2,
                              })}
                              value={share_details.markup_price}
                              placeholder="$3,876.97"
                              onChange={(e) => handlePriceChange(e)}
                              error={
                                validateAmount()
                                  ? t(
                                      'final_price_must_be_greater_than_delivered_price'
                                    )
                                  : ''
                              }
                              onBlur={() => {
                                if (!share_details.markup_price) {
                                  handleChange(
                                    'markup_price',
                                    initial_price_string
                                  );
                                }
                              }}
                            />
                          </div>
                        ) : is_owner ? (
                          <Button
                            className="customize-details-btn"
                            ghost
                            noShadow
                            onClick={() => {
                              getCfmSettings();

                              Track.track(
                                `${capitalize(
                                  shareType
                                )} share opened -> Markup modal opened.`
                              );
                            }}
                          >
                            <Icon
                              name="external_link"
                              width={16}
                              height={16}
                              color={'#27272A'}
                            />
                            <span className="customize-details-btn-label">
                              {t('open_markup_center')}
                            </span>
                          </Button>
                        ) : null}
                        <div className="final-shared-price-text-box">
                          <SkeletonElement
                            width="100%"
                            height="30px"
                            className="shared-final-price skeleton"
                            style={{ width: '100%' }}
                          >
                            {!markupDetailsLoading && (
                              <React.Fragment>
                                <span className="price__">{product_price}</span>
                                <span className="price__info">
                                  {t('final_shared_price')}
                                </span>
                              </React.Fragment>
                            )}
                          </SkeletonElement>
                          <div className="final-price-description">
                            * {t('price_is_exclusive_vat')}
                          </div>
                          <div className="final-price-description">
                            *{' '}
                            {t(
                              'your_customer_will_not_see_your_markup_percentage_in_the_message'
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </ListGroup>
                )}
              </ListSection>

              {SHARE_VIA_WHATSAPP && (
                <PreviewSection width={'46%'} height={'100%'}>
                  <WhatsAppPreviewSection {...commonProps} />
                </PreviewSection>
              )}

              {SHARE_VIA_EMAIL && (
                <PreviewSection width={'60%'} maxHeight="100%" height={'100%'}>
                  <EmailPreviewSection {...commonProps} />
                </PreviewSection>
              )}

              {SHARE_VIA_SMS && (
                <PreviewSection width={'45%'} height={'100%'}>
                  <SMSPreviewSection {...commonProps} />
                </PreviewSection>
              )}
            </div>

            <div className="share-popup-footer">
              <Button
                className="share-btn"
                disabled={
                  SHARE_VIA_WHATSAPP
                    ? shareListLoading || markupDetailsLoading
                    : SHARE_VIA_EMAIL
                    ? !isEmailValid
                    : !isPhoneValid
                }
                onClick={() => {
                  handleShare({ shared_via: shareType });
                  Track.track(
                    `${capitalize(
                      shareType
                    )} share opened -> Share button clicked.`
                  );
                }}
              >
                <span className="share-btn-label">
                  {SHARE_VIA_WHATSAPP ? t('share_to_whatsapp') : t('send')}
                </span>
                <Icon name="arrow-right-2" color="#fff" size={14} />
              </Button>
              {SHARE_VIA_WHATSAPP && (
                <div className="share-popup-footer-description">
                  {t(
                    'this_action_will_open_where_you_can_further_edit_your_message'
                  )}
                </div>
              )}
            </div>
          </LoadingSkeleton>
        </StyledReqPopup>
      )}
    </React.Fragment>
  );
};

const sectionHeading = ({
  title,
  show_tooltip = true,
  tooltipContent,
  tooltipPlacement = 'right',
}) => (
  <div className="section--heading">
    <span className="section--title">{title}</span>
    {show_tooltip && (
      <Tooltip
        style={{ display: 'flex', alignItems: 'center' }}
        content={
          <div
            style={{
              width: 190,
              color: '#fff',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '16px',
            }}
          >
            {tooltipContent}
          </div>
        }
        radius={3}
        placement={tooltipPlacement}
      >
        <Icon name="help_v2" color="#A1A1AA" size={20} />
      </Tooltip>
    )}
  </div>
);

const MarkupInput = ({
  label,
  mask,
  value,
  placeholder,
  onChange,
  suffix,
  error,
  onBlur,
}) => {
  return (
    <StyledMaskedInput>
      <label className="markup-input-label">{label}</label>
      <div className="markup-input-container">
        <MaskedInput
          mask={mask}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          className="markup-input"
          onBlur={onBlur}
        />
        {suffix && <span className="markup-input-suffix">{suffix}</span>}
      </div>
      {error && (
        <Span fontSize={'12px'} color="red">
          {error}
        </Span>
      )}
    </StyledMaskedInput>
  );
};

const Switch = ({ item, onChange, label, disabled }) => {
  return (
    <div className="shared-toggle">
      <SwitchV3
        item={item}
        onChange={onChange}
        active={item.isActive}
        disabled={disabled}
      />
      <span className="shared-toggle-label">{label}</span>
    </div>
  );
};
