import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';

let setup_user_fragment = gql`
  fragment setup_user on User {
    id
    steps_required
    email
    role
    country
    company {
      id
      name
    }
  }
`;

export const INITIALISE_STEPONE = gql`
  mutation (
    $email: String!
    $password: String!
    $first_name: String!
    $last_name: String!
    $role: String!
    $phone: String!
    $preferred_language: PreferredLanguages!
  ) {
    initialize_signup_step_one(
      email: $email
      first_name: $first_name
      last_name: $last_name
      password: $password
      role: $role
      phone: $phone
      preferred_language: $preferred_language
    ) {
      id
      verify_status
    }
  }
`;

export const INITIALISE_STEPTWO = gql`
  mutation (
    $signup_id: ID!
    $company_name: String!
    $company_type: String!
    $company_role: String!
    $country_of_incorporation: String!
    $company_registration_number: String
    $vat_number: String
    $company_website: String
    $average_monthly_purchase: String
    $first_touch: String
    $referrer: String
    $rapnet_id: String
    $jbt_number: String
    $utm_source: String
    $utm_medium: String
    $utm_campaign: String
    $utm_term: String
    $utm_content: String
  ) {
    initialize_signup_step_two(
      signup_id: $signup_id
      company_name: $company_name
      company_type: $company_type
      company_role: $company_role
      country_of_incorporation: $country_of_incorporation
      vat_number: $vat_number
      company_registration_number: $company_registration_number
      company_website: $company_website
      average_monthly_purchase: $average_monthly_purchase
      first_touch: $first_touch
      referrer: $referrer
      rapnet_id: $rapnet_id
      jbt_number: $jbt_number
      utm_source: $utm_source
      utm_medium: $utm_medium
      utm_campaign: $utm_campaign
      utm_term: $utm_term
      utm_content: $utm_content
    ) {
      token
      expires
      refresh_token
      user {
        id
        steps_required
        role
        country
      }
    }
  }
`;

export const SET_ADDRESS = gql`
  mutation (
    $user_registered_address: LocationInput!
    $user_shipping_address: LocationInput
    $same_address: Boolean
    $user_id: ID!
  ) {
    set_address(
      user_registered_address: $user_registered_address
      user_shipping_address: $user_shipping_address
      same_address: $same_address
      user_id: $user_id
    ) {
      token
      expires
      refresh_token
      user {
        ...setup_user
      }
    }
  }
  ${setup_user_fragment}
`;

export const InitialiseStepTwo = GraphqlMutation({
  query: gql`
    mutation (
      $signup_id: ID!
      $company_name: String!
      $company_type: String!
      $company_role: String!
      $country_of_incorporation: String!
      $company_registration_number: String
      $vat_number: String
      $company_website: String
      $average_monthly_purchase: String
      $first_touch: String
      $referrer: String
      $rapnet_id: String
      $jbt_number: String
      $utm_source: String
      $utm_medium: String
      $utm_campaign: String
      $utm_term: String
      $utm_content: String
    ) {
      initialize_signup_step_two(
        signup_id: $signup_id
        company_name: $company_name
        company_type: $company_type
        company_role: $company_role
        country_of_incorporation: $country_of_incorporation
        vat_number: $vat_number
        company_registration_number: $company_registration_number
        company_website: $company_website
        average_monthly_purchase: $average_monthly_purchase
        first_touch: $first_touch
        referrer: $referrer
        rapnet_id: $rapnet_id
        jbt_number: $jbt_number
        utm_source: $utm_source
        utm_medium: $utm_medium
        utm_campaign: $utm_campaign
        utm_term: $utm_term
        utm_content: $utm_content
      ) {
        token
        expires
        refresh_token
        user {
          id
          steps_required
          role
          country
        }
      }
    }
  `,
});

export const ConfirmEmailWithToken = GraphqlMutation({
  query: gql`
    mutation ($confirmation_token: String!) {
      confirm_email_with_token(token: $confirmation_token)
    }
    ${setup_user_fragment}
  `,
});

export const SetAddress = GraphqlMutation({
  query: gql`
    mutation (
      $user_registered_address: LocationInput!
      $user_shipping_address: LocationInput
      $same_address: Boolean
      $user_id: ID!
    ) {
      set_address(
        user_registered_address: $user_registered_address
        user_shipping_address: $user_shipping_address
        same_address: $same_address
        user_id: $user_id
      ) {
        token
        expires
        refresh_token
        user {
          ...setup_user
        }
      }
    }
    ${setup_user_fragment}
  `,
});
