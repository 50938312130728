import React from 'react';
import cx from 'classnames';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Tooltip } from '@nivoda/ui';
import theme from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';
import { isMobile } from 'utils';
import {
  bool,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { dashboardModeStateRx } from 'graphql/factory/reactiveVar';
import { useUserContext } from 'context/userContext';

export const NivodaExpressBadge = (props) => {
  const {
    className,
    deliveryTime,
    iconConfig,
    position,
    tooltip,
    isSmallExpressLogo,
    notAvailable = false,
  } = props;

  const { company } = useUserContext();
  const isMemoEnabled = company?.company_settings?.memo_enabled ?? false;

  const { isMemoMode } = useReactiveVar(dashboardModeStateRx);

  const baseIconPosition = {
    top: isSmallExpressLogo ? '4px' : 0,
    left: isSmallExpressLogo ? '4px' : 0,
    position: isSmallExpressLogo ? 'absolute' : 'static',
  };

  const iconPlacement = { ...baseIconPosition, ...position };

  const baseIconConfig = {
    height: isSmallExpressLogo ? 20 : 21,
    name: isSmallExpressLogo ? 'express-logo-abbrev' : 'express-logo',
    size: isSmallExpressLogo ? 24 : 80,
    width: isSmallExpressLogo ? 24 : 80,
    style: { filter: notAvailable ? 'grayscale(1)' : 'unset' },
  };

  const icon = { ...baseIconConfig, ...iconConfig };

  const minDay = deliveryTime?.min_business_days || 2;
  const maxDay = deliveryTime?.max_business_days || 3;

  const dynamicStyles = {
    display: 'flex',
    padding: '1px',
    alignItems: 'center',
    width: 'fit-content',
    gap: theme.spacing.s1,
    background: theme.palette.red50,
    borderRadius: theme.effects.roundedCorner4,
    ...baseIconPosition,
  };

  const renderContent = () => (
    <Icon
      name={icon.name}
      size={icon.size}
      width={icon.width}
      height={icon.height}
      style={icon.style}
    />
  );

  if (isMemoEnabled) {
    if (isMemoMode) return null;

    return (
      <div style={dynamicStyles}>
        <Icon
          size={isSmallExpressLogo ? 14 : 18}
          name="delivery-truck-3"
          className="delivery-truck"
        />
      </div>
    );
  }

  return (
    <Wrp
      className={cx('nivoda_express_badge', className)}
      top={iconPlacement.top}
      left={iconPlacement.left}
      position={iconPlacement.position}
      isSmallExpressLogo={isSmallExpressLogo}
    >
      {tooltip ? (
        <Tooltip
          placement={tooltip?.placement || 'bottomRight'}
          offset={tooltip?.offset ? tooltip?.offset : 3}
          content={
            <div
              style={{
                width: 230,
                ...(isMobile() ? theme.fonts.tiny : theme.fonts.small),
              }}
            >
              {tooltip?.content ? (
                tooltip?.content
              ) : (
                <div>
                  <Trans
                    i18nKey="nivoda_express_delivery"
                    values={{
                      delivery_time: `${minDay}-${maxDay}`,
                    }}
                    components={{ bold: <strong /> }}
                  />
                </div>
              )}
            </div>
          }
        >
          {renderContent()}
        </Tooltip>
      ) : (
        renderContent()
      )}
    </Wrp>
  );
};

const Wrp = styled.div`
  display: flex;
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

NivodaExpressBadge.propTypes = {
  className: string,
  deliveryTime: shape({
    min_business_days: number,
    max_business_days: number,
  }),
  isSmallExpressLogo: bool,
  iconConfig: shape({
    height: number,
    name: string,
    size: number,
    width: number,
    style: object,
  }),
  position: shape({
    top: oneOfType([number, string]),
    left: oneOfType([number, string]),
  }),
  tooltip: oneOfType([
    bool,
    shape({
      placement: string,
      offset: number,
      content: node,
      style: object,
    }),
  ]),
};

export { NivodaExpressBadgeWithDeliveryTime } from './NivodaExpressBadgeWithDeliveryTime';
