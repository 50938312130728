export const NivodaExpressRequestsStatus = {
  REQUESTED_STONES: 'REQUESTED_STONES',
  PROCESSING_STONES: 'PROCESSING_STONES',
  IN_EXPRESS_INVENTORY: 'IN_EXPRESS_INVENTORY',
  PENDING_RETURN: 'PENDING_RETURN',
  RETURNED_TO_SUPPLIER: 'RETURNED_TO_SUPPLIER',
};

export const ExpressRequestsQueryStatus = {
  REQUESTED_STONES: 'REQUESTED',
  PROCESSING_STONES: 'PROCESSING',
  IN_EXPRESS_INVENTORY: 'DONE',
  PENDING_RETURN: 'PENDING_RETURN',
  RETURNED_TO_SUPPLIER: 'RETURNED_TO_SUPPLIER',
};
