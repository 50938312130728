import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon, theme } from '@nivoda/ui';
import './index.css';

export class Switcher extends Component {
  render() {
    let style = {};
    return (
      <>
        <input
          type="checkbox"
          data-automation-id="set-as-default-location-on-edit-location"
          id={this.props.id || ''}
          onChange={this.props.onChange}
          checked={this.props.checked}
          className={[
            'switch_1',
            this.props.type === 'secondary' ? 'switch_2' : '',
            this.props.type === 'tertiary' ? 'switch_3' : '',
          ].join(' ')}
          style={style}
        />
      </>
    );
  }
}

let SwitcherWrap = styled.div`
  & {
    .switch {
      display: flex;
      align-items: center;
      font-size: 30px;
      text-align: right;
      justify-content: ${(p) =>
        p?.active === 'true' ? 'flex-start' : 'flex-end'};
      min-width: 50px;
      width: fit-content;
      height: 24px;
      background: ${(p) =>
        p.active === 'true' ? p.active_color : p.non_active_color};
      border-radius: 3em;
      position: relative;
      cursor: ${({ editable }) => (editable ? 'pointer' : 'not-allowed')};
      outline: none;
      transition: all 0.2s ease-in-out;
      border: ${(p) =>
        p.active === 'true'
          ? `1px solid  ${p.active_color}`
          : `1px solid ${p.non_active_border}`};
    }

    .switch:after {
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: ${(p) =>
        p.active === 'true'
          ? p.active_toggle_color
          : p.non_active_toggle_color};
      right: ${(p) => (p.active === 'true' ? 'calc(100% - 93%)' : '58%')};
      top: 2px;
      transition: all 0.2s ease-in-out;
    }
  }
`;
export let SwitcherWithLabel = ({
  className = '',
  checked,
  label,
  non_active_color = '#ffffff',
  onChange,
  active_color = theme.palette.themePrimary,
  non_active_border = '#bdbdbd',
  active_toggle_color = '#fff',
  non_active_toggle_color = theme.palette.themePrimary,
  editable = true,
  style,
}) => {
  return (
    <SwitcherWrap
      active={checked ? 'true' : 'false'}
      editable={editable}
      active_color={active_color}
      non_active_color={non_active_color}
      non_active_border={non_active_border}
      non_active_toggle_color={non_active_toggle_color}
      active_toggle_color={active_toggle_color}
    >
      <div
        onClick={() => {
          onChange(!checked);
        }}
        className={[
          className,
          `switch`,
          checked ? '__checked' : '',
          // this.props.type === 'secondary' ? 'switch_2' : '',
          // this.props.type === 'tertiary' ? 'switch_3' : '',
        ].join(' ')}
        style={style}
      >
        {label ? (
          label
        ) : (
          <Icon
            name={checked ? 'check' : 'close'}
            color={'white'}
            noHover
            size={12}
            style={{ margin: 5 }}
          />
        )}
      </div>
    </SwitcherWrap>
  );
};
