import { GraphqlFactory } from 'graphql/factory';
import { gql } from '@apollo/client';
import { OfferFragment } from '../../fragments';

export const GetOffer = GraphqlFactory({
  query: gql`
    query ($id: ID!) {
      offer(id: $id) {
        ...OfferFragment
      }
    }
    ${OfferFragment}
  `,
});
