import cx from 'classnames';
import { Icon } from 'fabric/resources/icon';
import searchIcon from 'fabric/resources/svg/ui-icons/search.svg';
import debounce from 'lodash/debounce';
import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import theme from '../fabric/theme';
import { dummyFn } from '../utils';

const is_mobile = window.innerWidth < 700;

const SearchInputWrapper = styled.div`
  cursor: pointer;
  width: ${is_mobile ? '100%' : '350px'};
  max-width: 400px;
  grid-area: certificate;
  padding-left: ${({ hasIcon }) => (hasIcon ? 0 : theme.spacing.s4)};
  position: relative;

  .certificateNumberInput.form-control.searchbar_input_text {
    padding-left: 30px;
    margin: ${is_mobile ? '2px 0 10px' : '10px'};
    width: 95%;
    padding-right: 25px;
  }

  .custom_icon {
    position: absolute;
    top: 11px;
    left: 8px;
  }

  .form-control {
    border-radius: ${theme.effects.roundedCorner3};
    height: 35px;
    ${theme.fonts.medium};
    @media (max-width: 700px) {
      ${theme.fonts.small};
    }

    &::placeholder {
      color: ${theme.palette.gray};
      ${theme.fonts.smallPlus};
      @media (max-width: 700px) {
        ${theme.fonts.small};
      }
    }
  }

  .form-control.customIcon {
    border-radius: ${theme.effects.roundedCorner3};
    padding-left: ${theme.spacing.s6};

    & + ._clear_icon {
      top: 8px;
      right: 12px;
    }
  }

  .searchbar_input_text {
    background: url(${searchIcon}) 3% center no-repeat;
    background-size: 15px;
  }

  ._clear_icon {
    color: ${theme.palette.gray2};
    position: absolute;
    top: ${is_mobile ? '22px' : '27px'};
    right: ${is_mobile ? '36px' : '30px'};
    font-weight: 700;
  }

  ._sticky {
    position: absolute;
    top: 0;
  }
  .custom_padding {
    padding-right: 35px !important;
  }

  ${({ customStyle }) => customStyle}
`;

const _fn = () => {};
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

const defaultProps = {
  changeHandler: _fn,
  className: '',
  clearQuery: _fn,
  debounceFunc: _fn,
  custom_style: '',
  disabled: false,
  focus: true,
  name: '',
  needDebounce: false,
  placeholder: '',
  onBlur: _fn,
  onFocus: _fn,
  onKeyDown: _fn,
  onKeyUp: _fn,
  sticky: false,
  style: {},
};

const propTypes = {
  className: PropTypes.string,
  changeHandler: PropTypes.func,
  clearQuery: PropTypes.func,
  // styled string literal
  custom_style: PropTypes.any,
  disabled: PropTypes.bool,
  // function which will be used as debounce handler
  debounceFunc: PropTypes.func,
  focus: PropTypes.bool,
  inputId: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  // specify if debounce functionality is needed
  needDebounce: PropTypes.bool,
  noIcon: PropTypes.bool,
  numType: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  showClearIcon: PropTypes.bool,
  sticky: PropTypes.bool,
  // style object
  style: PropTypes.object,
  target: PropTypes.any,
  value: PropTypes.any,
  waitInterval: PropTypes.number,
};

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);
  }
  state = {
    value: this.props.reset ? '' : this.props.value || '',
  };

  onBlurHandler = (e) => {
    const { target } = e;
    if (this.props.disableOnBlur) return;
    if (this.state.value && this.props.needDebounce) {
      this.debounceHandler();
    }

    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur({
        target,
        inputId: this.props.inputId || '',
        set: this.setState,
      });
    }
  };

  onClearInput = async () => {
    // clear child state
    if (this.props.needDebounce) {
      await this.setState({ value: '' }, () => this.debounceHandler());
    } else {
      await this.setState({ value: '' });
    }

    if (typeof this.props.clearQuery === 'function') {
      // clear parent state
      this.props.clearQuery({
        inputId: this.props.inputId || '',
      });
    }
  };

  onChangeInput = async (e) => {
    const { target } = e;
    if (this.props.needDebounce) {
      await this.setState(
        {
          value: this.props.numType
            ? target.value.replace(/[^0-9.-]/g, '')
            : target.value,
        },
        () => this.debounceHandler()
      );
    }

    if (typeof this.props.changeHandler === 'function') {
      // run any custom logic using changeHandler in parent component
      await this.props.changeHandler({
        target,
        inputId: this.props.inputId || '',
      });
    }
  };

  // will be called if enter is pressed while typing in search input
  handleKeyDown = async (e) => {
    const {
      target: { value },
      keyCode,
    } = e;

    if (e.keyCode === ENTER_KEY) {
      await this.debounceHandler();
    }
    if (typeof this.props.onKeyDown === 'function') {
      await this.props.onKeyDown({
        target: { value },
        inputId: this.props.inputId || '',
        keyCode,
      });
    }
  };

  // will be called after WAIT_INTERVAL is over
  debounceHandler = debounce(async () => {
    if (this.props.needDebounce) {
      if (this.props.reset) {
        this.setState({ value: '' });
      }

      this.props.debounceFunc(this.props.reset ? '' : this.state.value, {
        inputId: this.props.inputId || '',
      });
    }
  }, this.props.waitInterval || WAIT_INTERVAL);

  render() {
    let {
      className,
      custom_style,
      disabled,
      // focus,
      iconName,
      iconColor = theme.palette.gray,
      id,
      inputId,
      name,
      noIcon,
      onFocus,
      onKeyUp,
      placeholder = '',
      showClearIcon,
      sticky,
      style,
      readOnly = false,
      target,
      // onKeyPress,
      inputRef,
      iconSize = 12,
      top,
    } = this.props;
    const inputID = id || inputId ? { id: id || inputId } : {};

    const { value } = this.props.needDebounce
      ? !this.state.value && this.props.reset
        ? { value: '' }
        : this.state
      : this.props || { value: 'No value passed from parent' };

    return (
      <SearchInputWrapper
        className={`input_container ${className}`}
        customStyle={custom_style}
        hasIcon={iconName}
        style={style}
      >
        {iconName && !noIcon && (
          <Icon
            className={'custom_icon'}
            name={iconName}
            size={iconSize}
            color={iconColor}
          />
        )}
        <input
          {...inputID}
          ref={inputRef}
          data-testid={`input_box_${className}`}
          data-automation-id={`input_box_${className}`}
          disabled={disabled}
          // To make input focused on load
          // autoFocus={focus}
          placeholder={
            placeholder
              ? placeholder
              : is_mobile
              ? 'Search'
              : `Search for ${target}`
          }
          className={cx(
            !noIcon &&
              (iconName
                ? 'customIcon'
                : 'searchbar_input_text certificateNumberInput'),
            'form-control',
            this.props?.top && 'custom_padding',
            sticky && '_sticky'
          )}
          readOnly={readOnly}
          type="text"
          value={value}
          name={name}
          onBlur={readOnly ? dummyFn : this.onBlurHandler}
          onFocus={onFocus}
          onChange={this.onChangeInput}
          onKeyDown={this.handleKeyDown}
          onKeyUp={onKeyUp}
        />
        <span
          className="_clear_icon"
          data-testid={`input_box_${className}_clear_icon`}
          data-automation-id={`input_box_${className}_clear_icon`}
          style={{
            display: `${
              !readOnly && (showClearIcon || value) ? 'block' : 'none'
            }`,
            top: top ?? '6px',
          }}
          aria-hidden={showClearIcon || value ? 'false' : 'true'}
          role="button"
          tabIndex="0"
          onClick={readOnly ? dummyFn : this.onClearInput}
        >
          ×
        </span>
      </SearchInputWrapper>
    );
  }
}

SearchInput.propTypes = propTypes;
SearchInput.defaultProps = defaultProps;

export default SearchInput;
