import styled from 'styled-components';
import theme from '../../../fabric/theme';

export const AsDiv = styled.div`
  width: 480px;
  .radio__wrapper {
    border-bottom: 0 !important;
    label {
      border-bottom: 0 !important;
      margin-left: 8px;
      font-size: 14px;
      line-height: 22px;
      color: #2c2c2c;
    }
  }
  .action__cancel {
    &,
    &:disabled,
    &:active,
    &:focus,
    &:focus-visible,
    &:hover {
      border-color: ${theme.palette.themePrimary};
      color: ${theme.palette.themePrimary} !important;
    }
  }
  .action__confirm--label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 700px) {
    width: auto;
  }
  .action__container {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing.s6};

    button {
      margin-bottom: 0;
      width: 40%;
      &:first-child {
        margin-right: ${theme.spacing.s1};
      }
    }
  }
  .holds {
    &__extend--customer {
      .form-group {
        display: flex;
      }
    }
    &__extend--supplier {
      .form-group {
        width: 400px;
        display: flex;
        align-items: center;
      }
    }
    &__input--accept {
      margin: 0 ${theme.spacing.s3};
    }
    &__input--others {
      margin-top: ${theme.spacing.s3};
    }
    &__input--empty {
      height: 30px;
      width: 200px;
    }
    &__input--label {
      margin-bottom: ${theme.spacing.s5};
    }
    &__input--title {
      margin-bottom: ${theme.spacing.s5};
      ${theme.fonts.largePlus};
      @media (max-width: 700px) {
        ${theme.fonts.mediumPlus};
      }
      font-weight: 600;
      &-2 {
        margin-bottom: ${theme.spacing.s3};
      }
    }

    &__reject_inputs {
      display: grid;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing.s2};
    }
    &__enquiry_pending {
      ${theme.fonts.large};
      color: ${theme.semanticColors.error} !important;
    }
  }
`;
