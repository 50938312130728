import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon } from 'fabric/resources/icon';
import theme from 'fabric/theme';
import { Button } from 'fabric/components';
import { Price } from 'Elements';

export const SelectionActionBar = (props) => {
  const {
    actions = [],
    className,
    selectedItemsCount = 0,
    totalValue = 0,
    onClose,
  } = props;
  const { t } = useTranslation();

  return (
    <Wrp className={className}>
      <div className="selection-bar-container">
        {props.children ? (
          props.children
        ) : (
          <div className="selection-bar-info">
            <span>
              {selectedItemsCount} {t('item')}
              {selectedItemsCount < 2 ? '' : 's'}
            </span>
            <span className="selection-bar-info__reg-text">
              <Price
                price={{
                  currency: 'USD',
                  amount: totalValue,
                }}
              />
            </span>
          </div>
        )}
        <div className="selection-bar-trailing">
          <div className="selection-bar-trailing_action-area">
            {actions.length > 0 &&
              actions.map((action, index) => {
                return (
                  <Button
                    key={action?.id + '' + index}
                    className={cx(
                      'selection-bar-action',
                      action?.id?.toLowerCase(),
                      index === actions.length - 1 ? 'primary' : 'secondary'
                    )}
                    data-testid={`${action?.id?.toLowerCase()}-selection-bar-action`}
                    data-automation-id={`${action?.id?.toLowerCase()}-selection-bar-action`}
                    customPadding={theme.spacing.s3}
                    onClick={
                      typeof action?.onClick === 'function'
                        ? action.onClick
                        : noop
                    }
                    noShadow
                  >
                    {action?.label}
                  </Button>
                );
              })}
          </div>
          <Icon name="close-v2" color="white" size={24} onClick={onClose} />
        </div>
      </div>
    </Wrp>
  );
};

const Wrp = styled.div`
  position: fixed;
  right: 0;
  bottom: ${theme.spacing.s4};
  width: 100%;

  .selection-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
    max-width: 1024px;
    padding: ${theme.spacing.s3} ${theme.spacing.s5};

    border-radius: ${theme.spacing.s3};
    background: ${theme.palette.violet600};

    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 1px 2px -1px rgba(0, 0, 0, 0.1);

    svg {
      cursor: pointer;
    }
  }
  .selection-bar-info {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.s2};
    ${theme.fonts.mediumBold};
    color: ${theme.palette.white};

    &__reg-text {
      ${theme.fonts.medium};
    }
  }
  .selection-bar-trailing {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.s4};

    &_action-area {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.s2};
    }
  }
  .selection-bar-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing.s2};

    ${theme.fonts.mediumPlus};
    line-height: 20px;
    color: ${theme.palette.white};
    border-radius: ${theme.spacing.s2};

    &.primary {
      background: ${theme.palette.violet500};
      &:hover:not(:disabled) {
        background: ${theme.palette.white};
        color: ${theme.palette.violet700};
        box-shadow: none;
      }
    }
    &.secondary {
      background: ${theme.palette.violet600};
      &:hover:not(:disabled) {
        background: ${theme.palette.violet500};
        box-shadow: none;
      }
    }
  }
`;
