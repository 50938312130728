import { isMobile } from 'utils';
export const AdminOrderItemQueryTypes = {
  ASSIGNED_TO: 'assigned_to',
  UNASSIGNED: 'unassigned',
  BUYER: 'buyer_companies',
  SUPPLIER: 'supplier_companies',
  COLLECTED_DATE: 'collected_date',
  COLLECTED_LOCATION: 'collected_location',
  ORDER_DATE: 'order_date',
  PAYMENT: 'payment',
  RETURNS_POLICY: 'returns_policy',
  ON_MEMO: 'on_memo',
  SHIPPING_DATE: 'shipping_date',
  TERMS: 'terms',
  STATUS: 'status',
  QC_STATUS: 'qcStatus',
  ORDER_TYPE: 'order_type',
  QC_CUSTOMER_STATUS: 'qcCustomerStatus',
  COLLECTED_DATE_ORDER: 'collected_date_order',
  CONFIRMED_DATE_ORDER: 'confirmed_date_order',
  PROMISED_DELIVERY_DATE_ORDER: 'promised_delivery_date_order',
  SELF_SERVE_SUPLIER: 'self_serve_supplier',
  AUTO_SHIPMENT_CREATION: 'auto_shipment_creation',
};

export const CustomerOrderItemQueryTypes = {
  STATUS: 'status',
  CONFIRMED_BY: 'users',
  SHIPPING_TO: 'locations',
  SUPPLIER: 'supplier', // while sending, it will map to suppliers
  ORDER_DATE: 'order_date',
};

export const OrderItemStatus = {
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  ISSUE_RAISED: 'ISSUE_RAISED',
  PENDING_NIVODA_VERIFICATION: 'PENDING_NIVODA_VERIFICATION',
  CANCELLED: 'CANCELLED',
  CONFIRMED: 'CONFIRMED',
  CONFIRMED_READY_TO_COLLECT: 'CONFIRMED_READY_TO_COLLECT',
  CONFIRMED_IN_TRANSIT: 'CONFIRMED_IN_TRANSIT',
  COLLECTED: 'COLLECTED',
  DELIVERED: 'DELIVERED',
  EXPRESS_READY_TO_COLLECT: 'EXPRESS_READY_TO_COLLECT',
  IN_CUSTOMS: 'IN_CUSTOMS',
  IN_TRANSIT: 'IN_TRANSIT',
  LOST: 'LOST',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  PO_ON_HOLD: 'PO_ON_HOLD',
  RETURNED: 'RETURNED',
  RETURN_ACCEPTED: 'RETURN_ACCEPTED',
  RETURN_AT_NIVODA: 'RETURN_AT_NIVODA',
  RETURN_DELIVERED: 'RETURN_DELIVERED',
  RETURN_DENIED: 'RETURN_DENIED',
  RETURN_IN_TRANSIT: 'RETURN_IN_TRANSIT',
  RETURN_REQUESTED: 'RETURN_REQUESTED',
  READY_TO_COLLECT: 'READY_TO_COLLECT',
  READY_TO_SHIP: 'READY_TO_SHIP',
  READY_TO_TAG: 'READY_TO_TAG',
  READY_TO_QC: 'READY_TO_QC',
  SHIPMENT_BOOKED: 'SHIPMENT_BOOKED',
  SHIPPED: 'SHIPPED',
  TO_PAY: 'TO_PAY',
  TO_INVOICE: 'TO_INVOICE',
  OFFICE_INCOMING: 'OFFICE_INCOMING',
  DESTINATION_RECEIVED: 'DESTINATION_RECEIVED',
  TRANSIT_DONE: 'TRANSIT_DONE',
  READY_FINAL_DELIVERY: 'READY_FINAL_DELIVERY',
  FINAL_DELIVERY: 'FINAL_DELIVERY',
  SHIPPED_FINAL_DELIVERY: 'SHIPPED_FINAL_DELIVERY',
  UK_DELIVERED: 'UK_DELIVERED',
  CONFIRMED_READY_TO_SHIP: 'CONFIRMED_READY_TO_SHIP',
  WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
  AP_AVAILABLE: 'AP_AVAILABLE',
  AP_AVAILABLE_HOLD: 'AP_AVAILABLE_HOLD',
  PAID_BY_NIVODA: 'PAID_BY_NIVODA',
  ALL: 'ALL',
  DONE: 'DONE',
  EN_ROUTE: 'EN_ROUTE',
  PENDING: 'PENDING',
  REPORT_PROBLEM: 'REPORT_PROBLEM',
  FINAL: 'FINAL',
  RETURN_INITIATED: 'RETURN_INITIATED',
  ON_MEMO: 'ON_MEMO',
  NIVODA_VERIFIED: 'NIVODA_VERIFIED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

// To Map dual status to correct tabs
export const nextOrderTabId = {
  [OrderItemStatus.CONFIRMED_READY_TO_COLLECT]:
    OrderItemStatus.READY_TO_COLLECT,
  [OrderItemStatus.CONFIRMED_IN_TRANSIT]: OrderItemStatus.IN_TRANSIT,
  [OrderItemStatus.TRANSIT_DONE]: OrderItemStatus.READY_TO_COLLECT,
  [OrderItemStatus.READY_TO_COLLECT]: OrderItemStatus.READY_TO_COLLECT,
};

export const CustomerOrderTabURL = {
  ALL: 'ALL',
  OPEN_ORDERS: 'OPEN_ORDERS',
  PENDING_ORDERS: 'PENDING_ORDERS',
  ON_WAY: 'ON_WAY',
  DELIVERED: 'DELIVERED',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  CANCELLED: 'CANCELLED',
};

export const CustomerOrderTabReverseKeys = CustomerOrderTabURL;

export const OrderTabToOrderStatusMap = {
  [CustomerOrderTabURL.OPEN_ORDERS]: OrderItemStatus.PURCHASE_ORDER,
  [CustomerOrderTabURL.PENDING_ORDERS]: OrderItemStatus.WAITING_FOR_CUSTOMER,
  [CustomerOrderTabURL.ON_WAY]: OrderItemStatus.EN_ROUTE,
  [CustomerOrderTabURL.DELIVERED]: OrderItemStatus.DONE,
  [CustomerOrderTabURL.ALL]: OrderItemStatus.ALL,
  [CustomerOrderTabURL.NOT_AVAILABLE]: OrderItemStatus.NOT_AVAILABLE,
  [CustomerOrderTabURL.CANCELLED]: OrderItemStatus.CANCELLED,
};

export const CustomerOrderTabTitles = {
  [CustomerOrderTabURL.OPEN_ORDERS]: isMobile() ? 'P.O' : 'po_request',
  [CustomerOrderTabURL.PENDING_ORDERS]: isMobile()
    ? 'action_req'
    : 'action_required',
  [CustomerOrderTabURL.ON_WAY]: 'on_its_way',
  [CustomerOrderTabURL.DELIVERED]: 'delivered',
  [CustomerOrderTabURL.ALL]: 'all',
  [CustomerOrderTabURL.NOT_AVAILABLE]: 'sold_out',
  [CustomerOrderTabURL.CANCELLED]: 'cancelled',
};

export let ORDER_STATUS = {
  PURCHASE_ORDER: {
    name: 'Purchase Order',
    supplier_tab: 'po',
    customer_tab: 'po',
    admin_tab: 'po',
    icon: '',
    color: '',
  },
  CONFIRMED: {
    name: 'Confirmed',
    supplier_tab: 'open',
    customer_tab: 'open',
    admin_tab: 'open',
    icon: '',
    color: '',
  },
  DELIVERED: {
    name: 'Delivered',
    icon: '',
    color: '',
    supplier_tab: 'delivered',
    customer_tab: 'delivered',
    admin_tab: 'delivered',
  },
  LOST: {
    name: 'Insurance Claim',
    supplier_tab: 'lost',
    customer_tab: 'lost',
    admin_tab: 'lost',
    icon: '',
    color: '',
  },
  CANCELLED: {
    name: 'Cancelled',
    supplier_tab: 'cancelled',
    customer_tab: 'cancelled',
    admin_tab: 'cancelled',
    icon: '',
    color: '',
  },
  NOT_AVAILABLE: {
    name: 'Not Available',
    supplier_tab: 'missed',
    customer_tab: 'missed',
    admin_tab: 'missed',
    icon: '',
    color: '',
  },
  PAID_BY_NIVODA: {
    name: 'Paid By Nivoda',
    supplier_tab: 'paid_by_nivoda',
    icon: '',
    color: '',
  },
  IN_CUSTOMS: {
    name: 'In Customs',
    supplier_tab: 'delivered',
    customer_tab: 'open',
    admin_tab: 'open',
    icon: '',
    color: '',
  },
  SHIPPED: {
    name: 'Shipped',
    icon: '',
    color: '',
    supplier_tab: 'delivered',
    customer_tab: 'open',
    admin_tab: 'shipped',
  },
  IN_TRANSIT: {
    name: 'In Transit',
    supplier_tab: 'delivered',
    customer_tab: 'open',
    admin_tab: 'open',
    icon: '',
    color: '',
  },
  AWAITING_PAYMENT: {
    name: 'Awaiting Payment',
    icon: '',
    color: '',
    supplier_tab: 'awaiting_payment',
    customer_tab: 'awaiting_payment',
    admin_tab: 'awaiting_payment',
  },
  RETURNED: {
    name: 'Returned',
    icon: '',
    color: '',
    supplier_tab: 'returned',
    customer_tab: 'returned',
    admin_tab: 'returned',
  },
  RETURN_INITIATED: {
    name: 'Returned initiated',
    icon: '',
    color: '',
    supplier_tab: 'returned',
    customer_tab: 'returned',
    admin_tab: 'returned',
  },
  SHIPMENT_BOOKED: {
    name: 'Shipment Booked',
    icon: '',
    color: '',
    supplier_tab: '',
    customer_tab: '',
    admin_tab: '',
  },
  AWAITING_AUTH: {
    name: 'Awaiting Auth',
    customer_tab: 'awaiting_auth',
    supplier_tab: 'awaiting_auth',
    admin_tab: 'awaiting_auth',
  },
};

export const ORDER_ITEM_FBN_TYPE = {
  INTERNAL: 'INTERNAL',
  CONFIRMED_SALE: 'CONFIRMED_SALE',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const ORDER_ITEM_QC_STATUS = {
  QC_MISMATCH: 'QC_MISMATCH',
  QC_PENDING: 'QC_PENDING',
  QC_PASSED: 'QC_PASSED',
  QC_REJECTED: 'QC_REJECTED',
};

export const TRACK_ORDER_STATUS = {
  ORDER_PLACED: 'order_placed',
  NOT_AVAILABLE: 'stone_no_longer_available',
  ORDER_CONFIRMED: 'order_confirmed',
  WAITING_FOR_CUSTOMER: 'waiting_for_customer',
  NIVODA_OFFICE: 'at_nivoda_office',
  QC_REJECTED: 'failed_qc',
  QC_PASSED: 'passed_qc',
  IN_CUSTOMS: 'item_in_customs',
  ORDER_IN_TRANSIT: 'order_in_transit',
  ORDER_SHIPPED: 'order_shipped_tracking',
  ORDER_DELIVERED: 'order_is_delivered',
  ORDER_CANCELLED: 'order_is_cancelled',
  RETURNED: 'order_is_returned',
  LOST: 'order_lost',
  HAND_DELIVERED: 'hand_delivered',
  PICKED_UP: 'picked_up',
};

export const NIV_LOCATION_MAP = {
  HK: 'Hong Kong',
  IN: 'India',
  US: 'US',
  GB: 'London',
  NL: 'NL',
  AE: 'UAE',
  AU: 'Australia',
  BE: 'Belgium',
  ZA: 'South Africa',
};
