import { gql } from '@apollo/client';

export const GET_ZPL_FROM_PDF_URL = gql`
  query get_zpl_from_pdf_url(
    $url: String!
    $labelType: ZebraLabelType!
    $label_width: Int
    $label_height: Int
    $dpi: Int
  ) {
    get_zpl_from_pdf_url(
      url: $url
      labelType: $labelType
      label_width: $label_width
      label_height: $label_height
      dpi: $dpi
    )
  }
`;

export const GET_ZPL_FROM_BARCODE_TEXT = gql`
  query get_zpl_from_barcode_text($text: String!, $labelType: ZebraLabelType!) {
    get_zpl_from_barcode_text(text: $text, labelType: $labelType)
  }
`;
