import { GatewayProvider } from 'react-gateway-strictmode';
import { ApolloProvider } from '@apollo/client';
import { GraphqlProvider } from './apolloSetupCore';
import { AdminGraphqlProvider } from '../../graphql-admin';
import { SupplierGraphqlProvider } from '../../Supplier/graphql-supplier';
import { PermissionProvider } from 'context/permissions';
import { QueryClientProvider } from '@tanstack/react-query';
import { PairsContextProvider } from '../../context/PairsContext';
import { createContext, useState } from 'react';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/ja';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import dayjs from 'dayjs';

const dayjsLocaleMapper = (language = 'en') => {
  switch (language) {
    case 'en':
    case 'fr':
    case 'es':
    case 'de':
    case 'it':
      return language;
    case 'jp':
      return 'ja';
    default:
      return 'en';
  }
};

export const LanguageContext = createContext();

export const Providers = ({ children, clients }) => {
  const [language, setLanguage] = useState(
    JSON.parse(localStorage.getItem('preferred_language')) || {}
  );

  let locale = language ? language?.value : 'en';
  dayjs.locale(dayjsLocaleMapper(locale));

  return (
    <GatewayProvider>
      <ApolloProvider client={clients.graphqlClient}>
        <GraphqlProvider client={clients.graphqlClient}>
          <AdminGraphqlProvider client={clients.adminGraphqlClient}>
            <SupplierGraphqlProvider client={clients.supplierGraphQlClient}>
              <PermissionProvider>
                <LanguageContext.Provider value={{ language, setLanguage }}>
                  <QueryClientProvider client={clients.queryClient}>
                    <PairsContextProvider>{children}</PairsContextProvider>
                  </QueryClientProvider>
                </LanguageContext.Provider>
              </PermissionProvider>
            </SupplierGraphqlProvider>
          </AdminGraphqlProvider>
        </GraphqlProvider>
      </ApolloProvider>
    </GatewayProvider>
  );
};
