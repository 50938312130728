import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';
import { CustomerOrderItemFragment } from '../../fragments';

export const UpdateCFMOrderItem = GraphqlMutation({
  query: gql`
    mutation (
      $action: OrderItemActions!
      $new_value: String
      $order_item_id: ID!
      $code: String
    ) {
      update_order_item(
        action: $action
        new_value: $new_value
        order_item_id: $order_item_id
        code: $code
      ) {
        ...CustomerOrderItem
      }
    }
    ${CustomerOrderItemFragment}
  `,
});

export const OrderExcel = GraphqlMutation({
  query: gql`
    mutation ($order_item_ids: [String!]) {
      create_order_items_excel_export(order_item_ids: $order_item_ids)
    }
  `,
});

export const SET_EXPORT_ITEMS = gql`
  mutation CreateOrderItemsForExcelExport(
    $order_item_ids: [String!]
    $order_date: OrderDate!
    $status: CustomerOrderItemStatus!
  ) {
    create_order_items_excel_export(
      query: {
        order_item_ids: $order_item_ids
        order_date: $order_date
        status: $status
      }
    )
  }
`;

UpdateCFMOrderItem.OrderItemActions = {
  APPROVE_CFM_ORDER: 'APPROVE_CFM_ORDER',
  CANCEL_CFM_ORDER: 'CANCEL_CFM_ORDER',
  UPDATE_DESTINATION: 'UPDATE_DESTINATION',
  UPDATE_ORDER_NUMBER: 'UPDATE_ORDER_NUMBER',
};
