import { gql } from '@apollo/client';
import {
  CertificateFragment,
  CertificateInfoFragment,
} from './CertificateFragment';

export const HistoricDiamondFragment = gql`
  fragment HistoricDiamond on Diamond {
    id
    supplier {
      id
      name
    }
    location {
      id
      name
      country
    }
    supplierStockId
    diamondSupplierStockId
    certificate {
      ...CertificateInfo
    }
  }
  ${CertificateInfoFragment}
`;

export const HoldsHistoricDiamondFragment = gql`
  fragment HoldsHistoricDiamond on Diamond {
    ...HistoricDiamond
    other
    brown
    green
    blue
    gray
    milky
    eyeClean
    has_bgmec_enquiry
    base_price
    base_discount
    supplier {
      id
      preferred
    }
    certificate {
      ...CertificateFragment
      __typename
    }
    __typename
  }
  ${CertificateFragment}
  ${HistoricDiamondFragment}
`;
