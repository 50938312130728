import styled from 'styled-components';
export const AsDiv = styled.div`
  &.diamond__badge {
    position: absolute;
    top: ${({ top }) => (top ? `${top}px` : 0)};
    left: ${({ left }) => (left ? `${left}px` : 0)};
    width: ${({ width }) => (width ? `${width}px` : 0)};
    height: ${({ height }) => (height ? `${height}px` : 0)};
    z-index: 1;
    &--free-delivery {
      left: 0;
      top: 4px;
      width: auto;
      &.LIST {
        right: -8px;
        bottom: 4px;
        top: initial;
        left: initial;
        @media (max-width: 700px) {
          bottom: 0;
        }
      }
    }
  }
  .diamond__badge {
    &--text {
      background: #2d3c5c;
      color: #fff;
      padding: 9px;
      font-size: 10px;
      line-height: 16px;
      font-weight: 500;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 3px;
      font-family: 'HindMedium', sans-serif;
      &.LIST {
        border-radius: 50%;
        border: 2px solid #fff;
        background: #2d3c5c;
        color: #fff;
        padding: 5px;
        font-size: 10px;
        line-height: 16px;
        font-weight: normal;
        font-family: 'Hind', Arial, sans-serif;
        display: flex;
        place-content: center;
        width: fit-content;
      }
      & {
        &-desc {
          display: none;
          width: 0;
        }
        &:hover {
          svg {
            width: 0;
          }
          .diamond__badge--text-desc {
            display: block;
            padding-top: 2px;
            width: auto;
            transition: width 0.1s ease-out;
          }
        }
      }
    }
    &--tooltip-text {
      font-size: 12px;
    }
    &--icon {
      width: 18px;
      height: 18px;
    }
  }
`;
