export const SHAPES = [
  { value: 'ROUND', name: 'round', icon: 'Round' },
  { value: 'OVAL', name: 'oval', icon: 'Oval' },
  { value: 'PRINCESS', name: 'princess', icon: 'Princess' },
  { value: 'EMERALD', name: 'emerald', icon: 'Emerald' },
  {
    value: 'SQUARE EMERALD',
    name: 'sq_emerald',
    icon: 'SqEmerald',
  },
  { value: 'ASSCHER', name: 'asscher', icon: 'Asscher' },
  // {
  //   value: 'CUSHION MODIFIED',
  //   name: 'cushion_modified',
  //   icon: 'CushionModified',
  // },
  {
    value: 'CUSHION',
    name: 'cushion_all',
    icon: 'CushionAll',
  },
  { value: 'MARQUISE', name: 'marquise', icon: 'Marquise' },
  { value: 'PEAR', name: 'pear', icon: 'Pear' },
  {
    value: 'SQUARE RADIANT',
    name: 'sq_radiant',
    icon: 'SqRadiant',
  },
  { value: 'RADIANT', name: 'radiant', icon: 'Radiant' },
  { value: 'HEART', name: 'heart', icon: 'Heart' },
  {
    value: 'OLD MINER',
    name: 'old_miner',
    icon: 'OldMiner',
  },
  { value: 'STAR', name: 'star', icon: 'Star' },
  { value: 'ROSE', name: 'rose', icon: 'Rose' },
  { value: 'SQUARE', name: 'square', icon: 'Square' },
  {
    value: 'HALF MOON',
    name: 'half_moon',
    icon: 'HalfMoon',
  },
  {
    value: 'CUSHION BRILLIANT',
    name: 'cushion_brilliant',
    icon: 'CushionBrilliant',
  },
  {
    value: 'TRAPEZOID',
    name: 'trapezoid',
    icon: 'Trapezoid',
  },
  { value: 'FLANDERS', name: 'flanders', icon: 'Flanders' },
  {
    value: 'BRIOLETTE',
    name: 'briolette',
    icon: 'Briolette',
  },
  {
    value: 'PENTAGONAL',
    name: 'pentagonal',
    icon: 'Pentagonal',
  },
  {
    value: 'HEXAGONAL',
    name: 'hexagonal',
    icon: 'Hexagonal',
  },
  {
    value: 'OCTAGONAL',
    name: 'octagonal',
    icon: 'Octagonal',
  },
  {
    value: 'TRIANGULAR',
    name: 'triangular',
    icon: 'Triangular',
  },
  {
    value: 'TRILLIANT',
    name: 'trilliant',
    icon: 'Trilliant',
  },
  { value: 'CALF', name: 'calf', icon: 'Calf' },
  { value: 'SHIELD', name: 'shield', icon: 'Shield' },
  { value: 'LOZENGE', name: 'lozenge', icon: 'Lozenge' },
  { value: 'KITE', name: 'kite', icon: 'Kite' },
  {
    value: 'EUROPEAN CUT',
    name: 'european_cut',
    icon: 'European_Cut',
  },
  { value: 'BAGUETTE', name: 'baguette', icon: 'Baguette' },
  {
    value: 'TAPERED BAGUETTE',
    name: 'tapered_baguette',
    icon: 'Tapered_Baguette',
  },
  { value: 'BULLET', name: 'bullet', icon: 'Bullet' },
  {
    value: 'TAPERED BULLET',
    name: 'tapered_bullet',
    icon: 'Tapered_Bullet',
  },
  { value: 'OTHER', name: 'other', icon: 'Other' },
];

export const POSSIBLE_SIZES = [
  { value: 0.1, sizeRangeFrom: 0.01, sizeRangeTo: 0.19, name: '10s' },
  { value: 0.2, sizeRangeFrom: 0.2, sizeRangeTo: 0.29, name: '20s' },
  { value: 0.3, sizeRangeFrom: 0.3, sizeRangeTo: 0.39, name: '30s' },
  { value: 0.4, sizeRangeFrom: 0.4, sizeRangeTo: 0.49, name: '40s' },
  { value: 0.5, sizeRangeFrom: 0.5, sizeRangeTo: 0.59, name: '50s' },
  { value: 0.6, sizeRangeFrom: 0.6, sizeRangeTo: 0.69, name: '60s' },
  { value: 0.7, sizeRangeFrom: 0.7, sizeRangeTo: 0.79, name: '70s' },
  { value: 0.8, sizeRangeFrom: 0.8, sizeRangeTo: 0.89, name: '80s' },
  { value: 0.9, sizeRangeFrom: 0.9, sizeRangeTo: 0.99, name: '90s' },
  { value: 1.0, sizeRangeFrom: 1.0, sizeRangeTo: 1.09, name: '1ct' },
  { value: 1.1, sizeRangeFrom: 1.1, sizeRangeTo: 1.19, name: '1.1ct' },
  { value: 1.2, sizeRangeFrom: 1.2, sizeRangeTo: 1.49, name: '1.2ct' },
  { value: 1.5, sizeRangeFrom: 1.5, sizeRangeTo: 1.99, name: '1.5ct' },
  { value: 2.0, sizeRangeFrom: 2.0, sizeRangeTo: 2.49, name: '2ct' },
  { value: 2.5, sizeRangeFrom: 2.5, sizeRangeTo: 2.99, name: '2.5ct' },
  { value: 3.0, sizeRangeFrom: 3.0, sizeRangeTo: 3.99, name: '3ct' },
  // { value: 3.5, sizeRangeFrom: 3.5, sizeRangeTo: 3.99, name: '3.5ct' },
  { value: 4.0, sizeRangeFrom: 4.0, sizeRangeTo: 4.99, name: '4ct' },
  // { value: 4.5, sizeRangeFrom: 4.5, sizeRangeTo: 4.99, name: '4.5ct' },
  { value: 5.0, sizeRangeFrom: 5.0, sizeRangeTo: 100, name: '5ct+' },
];

export const CLARITIES = [
  { value: 'FL', name: 'FL' },
  { value: 'IF', name: 'IF' },
  { value: 'VVS1', name: 'VVS1' },
  { value: 'VVS2', name: 'VVS2' },
  { value: 'VS1', name: 'VS1' },
  { value: 'VS2', name: 'VS2' },
  { value: 'SI1', name: 'SI1' },
  { value: 'SI2', name: 'SI2' },
  { value: 'I1', name: 'I1' },
  { value: 'I2', name: 'I2' },
  { value: 'I3', name: 'I3' },
];

export const GEMS_CLARITY = [
  {
    value: 'EC',
    name: 'eyeclean',
    label: 'Eye clean',
    labelShort: 'Eye clean',
  },
  {
    value: 'SI',
    name: 'slightly_included',
    label: 'Slightly included',
    labelShort: 'Sli. Included',
  },
  {
    value: 'MI',
    name: 'moderately_included',
    label: 'Moderately included',
    labelShort: 'Mod. Included',
  },
  {
    value: 'VI1',
    name: 'visible_included',
    label: 'Visibly included',
    labelShort: 'Vis. Included',
  },
];
export const GEMS_ORIGIN = [
  {
    label: 'Zambia',
    value: 'ZAMBIA',
  },
  {
    label: 'Madagascar',
    value: 'MADAGASCAR',
  },
  {
    label: 'Thailand',
    value: 'THAILAND',
  },
  {
    label: 'Mozambique',
    value: 'MOZAMBIQUE',
  },
  {
    label: 'Myanmar',
    value: 'MYANMAR',
  },
  {
    label: 'Sri Lanka',
    value: 'SRILANKA',
  },
  {
    label: 'Brazil',
    value: 'BRAZIL',
  },
  {
    label: 'Australia',
    value: 'AUSTRALIA',
  },
  {
    label: 'Ethiopia',
    value: 'ETHIOPIA',
  },
  {
    label: 'Tanzania',
    value: 'TANZANIA',
  },
  {
    label: 'Cambodia',
    value: 'CAMBODIA',
  },
  {
    label: 'Afghanistan',
    value: 'AFGHANISTAN',
  },
  {
    label: 'Colombia',
    value: 'COLUMBIA',
  },
  {
    label: 'Greenland',
    value: 'GREENLAND',
  },

  {
    label: 'India',
    value: 'INDIA',
  },
  {
    label: 'Iran',
    value: 'IRAN',
  },
  {
    label: 'Kenya',
    value: 'KENYA',
  },

  {
    label: 'Pakistan',
    value: 'PAKISTAN',
  },
  {
    label: 'Tajikistan',
    value: 'TAJISKISTAN',
  },
  {
    label: 'Vietnam',
    value: 'VIETNAM',
  },
  {
    label: 'Rest of Africa',
    value: 'REST_OF_AFRICA',
  },
  {
    label: 'United States',
    value: 'UNITEDSTATES',
  },
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },

  // {
  //   label: 'Others',
  //   value: 'OTHER',
  // },
];

export const POSSIBLE_COMBOS = [
  {
    value: '3EX',
    title: '3EX',
    result: { cut: ['EIGHTX', 'ID', 'EX'], polish: ['EX'], symmetry: ['EX'] },
  },
  {
    value: 'EX',
    title: 'EX Cut',
    result: { cut: ['EX'], polish: ['EX', 'VG'], symmetry: ['EX', 'VG'] },
  },
  {
    value: '3VG',
    title: '3VG+',
    result: {
      cut: ['EX', 'VG'],
      polish: ['EX', 'VG'],
      symmetry: ['EX', 'VG'],
    },
  },
];

export const CUTS = [
  { value: 'EIGHTX', name: '8X' },
  { value: 'ID', name: 'ideal' },
  { value: 'EX', name: 'excellent' },
  { value: 'VG', name: 'very_good' },
  { value: 'GD', name: 'good' },
  { value: 'F', name: 'fair' },
  { value: 'P', name: 'poor' },
];

export const POLISH = [
  { value: 'EX', name: 'excellent' },
  { value: 'VG', name: 'very_good' },
  { value: 'GD', name: 'good' },
  { value: 'F', name: 'fair' },
  { value: 'P', name: 'poor' },
];

export const SYMMETRY = [
  { value: 'EX', name: 'excellent' },
  { value: 'VG', name: 'very_good' },
  { value: 'GD', name: 'good' },
  { value: 'F', name: 'fair' },
  { value: 'P', name: 'poor' },
];

export const FLUORESCENCE = [
  { value: 'NON', name: 'none' },
  { value: 'FNT', name: 'faint' },
  { value: 'MED', name: 'medium' },
  { value: 'STG', name: 'strong' },
  { value: 'VST', name: 'very_strong' },
];

export const COLOUR = [
  { value: 'Blue', name: 'blue' },
  { value: 'Yellow', name: 'yellow' },
  { value: 'Red', name: 'red' },
  { value: 'Green', name: 'green' },
  { value: 'Purple', name: 'purple' },
  { value: 'Orange', name: 'orange' },
];

export const WHITE_COLOUR = [
  { value: 'D', name: 'D' },
  { value: 'E', name: 'E' },
  { value: 'F', name: 'F' },
  { value: 'G', name: 'G' },
  { value: 'H', name: 'H' },
  { value: 'I', name: 'I' },
  { value: 'J', name: 'J' },
  { value: 'K', name: 'K' },
  { value: 'L', name: 'L' },
  { value: 'M', name: 'M' },
  { value: 'N', name: 'N' },
  { value: 'NO', name: 'NO' },
  { value: 'PR', name: 'PR' },
  { value: 'SZ', name: 'SZ' },
  { value: 'OP', name: 'OP' },
  { value: 'QR', name: 'QR' },
  { value: 'ST', name: 'ST' },
  { value: 'UV', name: 'UV' },
  { value: 'WX', name: 'WX' },
  { value: 'XY', name: 'XY' },
  { value: 'YZ', name: 'YZ' },
  { value: 'Fancy', name: 'Fancy', hide: true },
];

export let main_default_filters = {
  shapes: [],
  certificate_lab: [],
  // certificate_date: [],
  sizes: [['', '']],
  sizeRangeFrom: '',
  sizeRangeTo: '',
  color: [],
  clarity: [],
  cut: [],
  polish: [],
  symmetry: [],
  flouresence: [],
  fancyColor: [],
  fancyIntensity: [],
  fancyOvertone: [],
  brown: [],
  green: [],
  blue: [],
  gray: [],
  other: [],
  luster: [],
  eyeClean: [],
  delivery_time: [],
  has_image: null,
  has_v360: null,
  flouresence_color: [],
  suppliers: [],
  location: [],
  sub: true,
  sul: true,
  sue: true,
  hide_memo: false,
  is_fbn: false,
};

export let premium_default_filter = {
  inclusion_color: [],
  inclusion_table: [],
  inclusion_sides: [],
  open_inclusion_table: [],
  open_inclusion_sides: [],
  key_to_symbols: [],
  girdle: [],
  culet: [],
  treated: false,
  returns: false,
  rjc: false,
  mine_of_origin: [],
  mine_to_market: [],
  labgrown_type: [],
  suk: true,
  post_growth: false,
  sustainability_rated_diamond: false,
  hearts_arrows: false,
};

export let price_default_filter = {
  dollar_per_carat: [0, 0],
  dollar_value: [0, 0],
  // discount: [-100, 100],
};

export let parameters_defaults_filter = {
  table_percentage: ['', ''],
  depth_percentage: ['', ''],
  length_mm: ['', ''],
  width_mm: ['', ''],
  depth_mm: ['', ''],
  ratio: ['', ''],
  // culet: [],
  crown_angle: ['', ''],
  pav_angle: ['', ''],
  // diameter: ['', ''],
  star_length: ['', ''],
  lower_half: ['', ''],
};
