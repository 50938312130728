import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';
import { EnquiryFragment } from '../../fragments';

export const CreateEnquiry = GraphqlMutation({
  query: gql`
    mutation CreateEnquiry($enquiry: [InputEnquiry!]!) {
      create_enquiry(enquiry: $enquiry) {
        ...EnquiryFragment
      }
    }
    ${EnquiryFragment}
  `,
});
