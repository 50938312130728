import dayjs from 'dayjs';

export const skipWeekends = (days) => {
  let date = dayjs().add(days, 'd');
  if (dayjs(date).day() === 6) {
    date = dayjs(date).add(2, 'd');
  }
  if (dayjs(date).day() === 0) {
    date = dayjs(date).add(1, 'd');
  }
  return new Date(date.valueOf());
};
