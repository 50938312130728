import { gql } from '@apollo/client';

export const FETCH_CREDIT_NOTE_INVOICES = gql`
  query fetchCreditNotesForInvoices($id: ID!, $offset: Int) {
    credit_note_by_company_id(id: $id, offset: $offset, limit: 48) {
      total_count
      items {
        id
        invoiced_at
        invoice_number
        total_amount_tax
        invoice_status
        settled_at
        settled_amount
        reconciliation
        currency
        items {
          id
        }
        invoice_credit_note {
          status
          createdAt
        }
        due_date
        invoiced_company {
          id
          name
        }
      }
    }
  }
`;
