import { useAsState } from '../utils';
import { useShortlistContext } from 'context/shortlistContext';
import { ShortlistWrap } from '../components/diamond-grid-item/diamond-grid-style';
import { Icon, Tooltip } from '@nivoda/ui';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Icon as UIcon } from 'fabric/resources/icon';
import { useTranslation } from 'react-i18next';

export function ShortlistButton(props) {
  const {
    custom_style = {},
    displayText,
    iconWidth,
    iconHeight,
    placement = 'bottom',
  } = props;
  const { t } = useTranslation();

  let { is_in_shortlist, set_in_shortlist } = useShortlistContext({
    offerId: props.offerId,
    offer: props.offer,
  });
  const [{ shortlist_hovered }, setState] = useAsState({
    shortlist_hovered: false,
  });

  const handleMouseEnter = async () =>
    await setState({
      shortlist_hovered: true,
    });

  const handleOnMouseLeave = async () =>
    await setState({
      shortlist_hovered: false,
    });
  let handleClick = () => {
    set_in_shortlist(!is_in_shortlist);
    if (!is_in_shortlist) {
      typeof props.onClick === 'function' && props.onClick();
    }
  };
  return (
    <>
      {props.is_new ? (
        <React.Fragment>
          {props.section === 'list_view' && (
            <Tooltip
              radius={4}
              content={
                <div
                  style={{ width: '120px', textAlign: 'center' }}
                  data-automation-id="shortlist-msg"
                >
                  {is_in_shortlist
                    ? t('remove_to_shortlist_v2')
                    : t('add_to_shortlist_v2')}
                </div>
              }
              placement={placement}
              data-automation-id="shortlist-msg"
            >
              <ShortlistWrap
                style={props.style_overide}
                is_list_view={props.section === 'list_view'}
                active_or_hovered={shortlist_hovered || is_in_shortlist}
                className={'shortlist_btn'}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onClick={handleClick}
                data-automation-id="shortlist-button"
              >
                <UIcon
                  name={'heart'}
                  width={iconWidth || 18}
                  height={iconHeight || 18}
                  color={'#0000'}
                  className={
                    shortlist_hovered || is_in_shortlist
                      ? 'shortlist-icon-active'
                      : 'shortlist-icon-default'
                  }
                />
                {displayText && (
                  <div data-automation-id="shortlist-msg">
                    {is_in_shortlist
                      ? t('Shortlisted')
                      : t('add_to_shortlist_v2')}
                  </div>
                )}
              </ShortlistWrap>
            </Tooltip>
          )}
          {props.section === 'product_view' && (
            <ShortlistWrap
              style={props.style_overide}
              is_product_view={props.section === 'product_view'}
              active_or_hovered={
                (shortlist_hovered || is_in_shortlist) && !props.isOnHold
              }
              is_in_shortlist={is_in_shortlist}
              className={'shortlist_btn'}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              onClick={() => {
                if (!props.isOnHold) handleClick();
              }}
            >
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                data-automation-id="shortlist-button"
              >
                <UIcon
                  name={'heart'}
                  width={20}
                  height={18}
                  color={!props.isOnHold ? '#0000' : '#BDBDBD !important'}
                  className={
                    (shortlist_hovered || is_in_shortlist) && !props.isOnHold
                      ? 'shortlist-icon-active'
                      : 'shortlist-icon-default'
                  }
                />
              </div>
              <div data-automation-id="shortlist-msg">
                {is_in_shortlist ? t('Shortlisted') : t('add_to_shortlist_v2')}
              </div>
            </ShortlistWrap>
          )}
          {props.section === 'custom' && (
            <Tooltip
              radius={4}
              content={
                <div
                  style={{ width: '120px', textAlign: 'center' }}
                  data-automation-id="shortlist-msg"
                >
                  {is_in_shortlist
                    ? t('remove_to_shortlist_v2')
                    : t('add_to_shortlist_v2')}
                </div>
              }
              placement={placement}
            >
              <ShortlistWrap
                custom_style={custom_style}
                is_custom={props.section === 'custom'}
                active_or_hovered={shortlist_hovered || is_in_shortlist}
                className={'shortlist_btn'}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onClick={handleClick}
                data-automation-id="shortlist-button"
              >
                <UIcon
                  name={'heart'}
                  width={iconWidth || 18}
                  height={iconHeight || 18}
                  color={'#0000'}
                  className={
                    shortlist_hovered || is_in_shortlist
                      ? 'shortlist-icon-active'
                      : 'shortlist-icon-default'
                  }
                />
                {displayText && (
                  <div data-automation-id="shortlist-msg">
                    {is_in_shortlist
                      ? t('Shortlisted')
                      : t('add_to_shortlist_v2')}
                  </div>
                )}
              </ShortlistWrap>
            </Tooltip>
          )}
        </React.Fragment>
      ) : (
        <ShortlistWrap
          style={props.style_overide}
          className={'shortlist_btn'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          onClick={() => {
            set_in_shortlist(!is_in_shortlist);
            if (!is_in_shortlist) {
              typeof props.onClick === 'function' && props.onClick();
            }
          }}
          data-automation-id="shortlist-button"
        >
          <Icon
            name={
              is_in_shortlist || shortlist_hovered
                ? 'shortlist_solid'
                : 'shortlist'
            }
            size={24}
            background={{ color: 'white', type: 'rounded' }}
          />
        </ShortlistWrap>
      )}
    </>
  );
}

ShortlistButton.propTypes = {
  offerId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
