import React, { useEffect } from 'react';
import { StepOneWrapper } from '../styles';
import { CustomTrans } from 'helpers';
import { ROLES } from './helper';
import { PrimaryButton } from 'Signup/components/CustomSignupComponents';
import { Track } from 'fabric/integrations';

export let CustomerRole = ({ onBtnClick, role, onSubmit }) => {
  useEffect(() => {
    Track.track_page('Signup page -> Select type');
  }, []);
  return (
    <StepOneWrapper>
      <div className="step_one--header">
        <span className="step_one--header__title">
          <CustomTrans text={'welcome_to_nivoda'} returnPlainText={true} />
        </span>
        <p className="step_one--header__content">
          <CustomTrans
            text={
              'lets_start_your_signup_process_by_collecting_some_basic_details_about_you'
            }
            returnPlainText={true}
          />
        </p>
      </div>
      <div className="step_one--main">
        <span className="step_one--main__title">
          <CustomTrans
            text="are_you_a_buyer_or_a_seller_what_will_you_use_nivoda_for"
            returnPlainText={true}
          />
        </span>
        <div className="step_one--main__content">
          <div
            className={`box_radio ${role === ROLES.CUSTOMER && 'active'}`}
            onClick={() => onBtnClick(ROLES.CUSTOMER)}
          >
            <span className="box_radio__title">
              <CustomTrans
                text="i_want_to_buy_on_nivoda"
                returnPlainText={true}
              />
            </span>
            <p className="box_radio__desc">
              <CustomTrans
                text="id_like_to_browse_and_purchase_stock"
                returnPlainText={true}
              />
            </p>
          </div>
          <div
            className={`box_radio ${role === ROLES.SUPPLIER && 'active'}`}
            onClick={() => onBtnClick(ROLES.SUPPLIER)}
          >
            <span className="box_radio__title">
              <CustomTrans
                text="i_want_to_sell_on_nivoda"
                returnPlainText={true}
              />
            </span>
            <p className="box_radio__desc">
              <CustomTrans
                text="id_like_to_put_my_stock_up_for_sale"
                returnPlainText={true}
              />
            </p>
          </div>
        </div>
      </div>
      <PrimaryButton
        btnText="continue"
        onClick={() => {
          onSubmit();
        }}
      />
    </StepOneWrapper>
  );
};
