import React from 'react';

class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default ScrollToTop;

export class ScrollToView extends React.Component {
  componentDidMount() {
    let el = document.getElementById('scrollIntoView');
    el && el.scrollIntoView();
  }
  componentDidUpdate() {
    let el = document.getElementById('scrollIntoView');
    el && el.scrollIntoView();
  }

  render() {
    return null;
  }
}

export class ScrollToTopV2 extends React.Component {
  componentDidMount() {
    document
      .getElementById('DashboardContent')
      .scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  render() {
    return null;
  }
}
