import React from 'react';
import { any, array, bool, string, func, object, node } from 'prop-types';
import { SelectOptions } from '@nivoda/forms';
import { Label, theme } from '@nivoda/ui';
import styled from 'styled-components';

export function Select(props) {
  const hasCustomLabel = typeof props.labelProps === 'object';
  const onChange = (e) => props.onChange(e, props.inputId);

  return (
    <Wrapper>
      {hasCustomLabel && <Label {...props.labelProps} />}
      <SelectOptions
        disabled={props.disabled}
        label={hasCustomLabel ? '' : props.label}
        value={props.value ? props.value : 'None'}
        onChange={onChange}
        title={typeof props.title === 'string' ? props.title : ''}
        style={{
          color: !props.value ? theme.semanticColors.placeholderText : null,
        }}
      >
        <option className="placeholder_label" value="None" disabled>
          {props.placeholder}
        </option>
        {props.options?.map((item) => {
          return (
            <option
              disabled={item.disabled}
              value={item.value}
              key={item.value}
              style={{
                color:
                  !props.value || !item.disabled
                    ? theme.palette.black
                    : item.disabled
                    ? theme.semanticColors.placeholderText
                    : null,
              }}
            >
              {item.label}
            </option>
          );
        })}
      </SelectOptions>
    </Wrapper>
  );
}
Select.propTypes = {
  disabled: bool,
  inputId: string,
  label: node,
  labelProps: object,
  onChange: func.isRequired,
  options: array.isRequired,
  placeholder: string,
  value: any.isRequired,
};

const Wrapper = styled.div`
  select {
    border-radius: ${theme.effects.roundedCorner3};
  }
`;
