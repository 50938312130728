import styled, { css } from 'styled-components';
import { theme } from '@nivoda/ui';
export let StyledReturnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  height: 40px;
  // border-top: 1px solid #e0e0e0;
  font-size: 12px;
  .text-label {
    margin-left: 4px;
    line-height: 20px;
  }
  .check-box {
    display: flex;
    justify-content: center;
  }
`;

export let DeliveredWrapper = styled.div`
  width: 100%;
  .article_price_header {
    font-size: 11px;
    font-weight: 600;
    color: #888888;
  }
  .top-head {
    display: flex;
    justify-content: flex-start;
  }
`;

export let PriceSectWrapper = styled.div`
  display: ${({ is_supplier }) => (is_supplier ? 'grid' : 'flex')};
  grid-template-columns: ${({ is_supplier }) =>
    is_supplier ? 'repeat(2,1fr)' : 'unset'};
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;

  .article_price_body_container {
    justify-content: flex-start;
  }
  &.user_in_review {
    display: ${({ is_supplier }) => (is_supplier ? 'grid' : 'flex')};
    grid-template-columns: ${({ is_supplier }) =>
      is_supplier ? 'repeat(2,1fr)' : 'unset'};
    justify-content: space-between;
    .diamond_price {
      display: grid;
      grid-template-columns: 1.5fr 0.5fr;
      justify-items: flex-start;
      &__delivered {
        justify-items: flex-end;
        &--supplier {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
      &__supplier {
        grid-template-columns: ${({ is_supplier }) =>
          is_supplier ? '0fr 1fr' : 'repeat(2,1fr)'};
        align-items: center;
        justify-items: flex-start;
      }
    }
    .article_price_body_container {
      display: inline;
      margin-top: 0;
    }
  }

  .article__discount {
    height: 19px;
    color: green;
    font-size: 12px;
    line-height: 19px;
    font-family: 'Hind SemiBold', sans-serif;
    box-sizing: border-box;
    font-weight: 600;
    text-align: left;
  }
`;
export let AStyledButton = styled.button`
  width: 50%;
  border-radius: 0;
  font-size: 12px;
  line-height: 12px;
  background: ${theme.palette.themePrimary};
  color: #ffffff;
  font-weight: 600;
  border: none;
  padding: 2px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
  &:hover {
    box-shadow: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export let SellerAndDeliveryWrap = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  ._seller_info {
    width: 50%;
  }
  ._delivery_info {
    width: 50%;
  }
`;

export let ShortlistWrap = styled.div`
  // position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  ${({ is_list_view }) =>
    is_list_view &&
    css`
  position: static !important;
  height: 36px !important;
  z-index: unset;
  min-width: 36px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active_or_hovered }) =>
    active_or_hovered ? '#e9f2fd' : '#fff'}
  
  border: 1px solid  ${({ active_or_hovered }) =>
    active_or_hovered ? '#c2dbfc' : '#dadada'};

  .shortlist-icon-active {
    color: #000;
  }
  .shortlist-icon-default {
    color: #000;
  }
  .shortlist-icon-active {
    color: #dc2626;
    fill: #dc2626;
  }
  .shortlist-icon-default {
    color: ${({ defaultIconColor = '#000' }) => defaultIconColor || '#0C0A09'};
  }
  `};

  ${({ is_product_view }) =>
    is_product_view &&
    css`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 12px;
  height: 40px;
  box-shadow: 8px 8px 24px rgba(2, 2, 70, 0.05);
  border-radius: 8px;
  color:${({ active_or_hovered }) => (active_or_hovered ? '#fff' : '#0C0A09')};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background: ${({ active_or_hovered }) =>
    active_or_hovered ? '#0C0A09' : '#fff'}
  
  border: 1px solid  ${({ active_or_hovered }) =>
    active_or_hovered ? '#dadada' : '#0C0A09'};

  .shortlist-icon-active {
    color: #fff;
    fill: ${({ is_in_shortlist }) => (is_in_shortlist ? '#fff' : '')};
  }
  .shortlist-icon-default {
    color: #0C0A09;
  }
  `};

  ${({ is_custom }) =>
    is_custom &&
    css`
      position: static;
      top: 10px;
      right: 10px;
      z-index: 1;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: ${({ custom_style }) => custom_style.gap || '12px'};
      height: ${({ custom_style }) => custom_style.height || '40px'};
      width: ${({ custom_style }) => custom_style.width};
      /* box-shadow: 8px 8px 24px rgba(2, 2, 70, 0.05); */
      border-radius: ${({ custom_style }) =>
        custom_style.borderRadius || '8px'};
      font-weight: ${({ custom_style }) => custom_style.fontWeight || '700'};
      font-size: ${({ custom_style }) => custom_style.fontSize || '14px'};
      line-height: ${({ custom_style }) => custom_style.lineHeight || '20px'};

      padding: ${({ custom_style }) => custom_style.padding || 0};
      color: ${({ active_or_hovered }) =>
        active_or_hovered
          ? ({ custom_style }) => custom_style.activeColor || '#fff'
          : ({ custom_style }) => custom_style.defaultColor || '#0C0A09'};

      .shortlist-icon-active {
        color: #dc2626;
        fill: #dc2626;
      }
      .shortlist-icon-default {
        color: ${({ custom_style }) =>
          custom_style.defaultIconColor || '#0C0A09'};
      }
    `};
`;
