import styled from 'styled-components';
import theme from '../../theme';

export const StyledDiv = styled.div`
  margin: ${theme.spacing.s8};
  @media (max-width: 700px) {
    margin: ${theme.spacing.s3} ${theme.spacing.s2};
  }

  .card {
    padding: 0;

    &,
    &__body {
      background: ${theme.palette.white1};
    }

    &__body {
      padding: ${theme.spacing.s4};
    }
  }

  .pnf_action {
    border-radius: ${theme.effects.roundedCorner4};
    padding: ${theme.spacing.s3} !important;
    margin: 0 auto;
    @media (max-width: 700px) {
      ${theme.fonts.absolute.tiny};
    }

    &__report,
    &__back {
      display: inline-flex;
      align-items: center;
      gap: ${theme.spacing.s1};
    }

    &__report {
      border: ${theme.semanticProps.borderGrey3};
      margin-left: ${theme.spacing.s3};
    }
  }

  .pnf_connect__box {
    width: 640px;
    margin: ${theme.spacing.s5} 0 ${theme.spacing.s5};
    @media (max-width: 700px) {
      margin: ${theme.spacing.s5} auto 0;
      width: auto;
    }
  }

  .pnf_desktop {
    display: flex;
    height: calc(100vh - 145px);
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;

    &.pub {
      height: calc(100vh - 155px);
    }
  }

  .pnf_image {
    width: 400px;
    height: auto;
    margin-bottom: -10px;
    animation: fallAndRise 3s infinite;

    &__box {
      overflow: hidden;
    }

    @media (max-width: 700px) {
      width: 146px;
      height: auto;
    }
  }

  .pnf_heading {
    &__1 {
      ${theme.fonts.absolute.megaPlus};
      @media (max-width: 700px) {
        ${theme.fonts.absolute.xxLargeBold};
      }
    }

    &__2 {
      color: ${theme.palette.navyBlue4};
      margin-top: ${theme.spacing.s3};
      ${theme.fonts.absolute.superLargeBold};
      @media (max-width: 700px) {
        ${theme.fonts.absolute.xLargePlus};
      }
    }

    &__3 {
      margin: ${theme.spacing.s6} 0;
      color: ${theme.palette.gray800};
      ${theme.fonts.absolute.xLarge};
      @media (max-width: 700px) {
        text-align: center;
        ${theme.fonts.absolute.large};
      }
    }
  }

  .pub {
    .pnf_image__box {
      margin-left: 100px;
      margin-bottom: 175px;

      @media (max-width: 700px) {
        margin: 0;
      }
    }

    img {
      width: 212px;
      height: auto;
      margin-bottom: 0;
      animation: none;
      @media (max-width: 700px) {
        width: 160px;
      }
    }
  }

  @keyframes fallAndRise {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
