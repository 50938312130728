import { Icon } from 'fabric/resources/icon';
import whatsappPattern from 'fabric/resources/img/whatsapp-pattern.png';
import { useTranslation } from 'react-i18next';
import {
  EmailPreview,
  PreviewPill,
  SMSPreview,
  SkeletonElement,
  Span,
  WhatsAppPreviewCard,
} from './style';
import { useUserContext } from 'context/userContext';

const dummy_url = 'https://www.loupe360.com/a8hjk';

export const WhatsAppPreviewSection = ({
  share_video_preview,
  share_details,
  selectedDetails,
  data,
  product_price,
  product_carat_price,
  markupDetailsLoading,
  stoneType,
}) => {
  const { t } = useTranslation();
  const { userInReview } = useUserContext();
  return (
    <div
      style={{
        background: `url(${whatsappPattern}), #efeae2 no-repeat`,
        backgroundSize: 'contain',
        height: '100%',
        display: 'flex',
        padding: '24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        flex: '1 0 0',
        alignSelf: 'stretch',
      }}
    >
      <PreviewPill
        shadow
        background={'#DDDDE9'}
        fontSize={'12px'}
        padding={'3px 20px'}
      >
        {t('preview_message')}
      </PreviewPill>
      <WhatsAppPreviewCard className="whats-app-preview-card">
        {share_video_preview ? (
          <div className="video-preview">{share_video_preview}</div>
        ) : null}

        <div className="message-container">
          {selectedDetails?.length > 0 && (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                flexWrap: 'wrap',
              }}
            >
              {/* <span
                style={{ fontSize: 16, fontWeight: 600, lineHeight: '19px' }}
                className={`item-${'LAB'}`}
              >
                {stoneType}
              </span> */}
              {selectedDetails.slice(1).map((dec, i) => {
                const value = dec.value ? dec.value : '';
                return value ? (
                  <Span
                    fontWeight={dec.isBold ? '600' : '400'}
                    key={i}
                    className={`item-${dec.id}`}
                  >
                    {dec.isBold ? value : `· ${value}`}
                  </Span>
                ) : (
                  ''
                );
              })}
            </div>
          )}
          {share_details.include_price && !userInReview && (
            <SkeletonElement className="item-pricing skeleton" width="100%">
              {!markupDetailsLoading && (
                <>
                  <Span fontWeight={'600'}>{product_price}</Span>
                  <Span fontWeight={'400'}>({product_carat_price})</Span>
                </>
              )}
            </SkeletonElement>
          )}
          <Span
            fontWeight={'400'}
            color="#3f80f4"
            style={{
              wordBreak: 'break-all',
              textDecoration: 'underline',
              userSelect: 'none',
            }}
          >
            {dummy_url}
          </Span>
        </div>
        <div className="message-time-read">
          <span className="time">09:41</span>
          <Icon name="read" height={9} width={14} />
        </div>
      </WhatsAppPreviewCard>
    </div>
  );
};
export const EmailPreviewSection = ({
  share_video_preview,
  share_details,
  selectedDetails,
  product_price,
  product_carat_price,
  markupDetailsLoading,
  stoneType,
}) => {
  const { userInReview } = useUserContext();
  const description =
    selectedDetails?.length > 0
      ? selectedDetails.filter((detail) => detail.isBold)
      : [];
  const { t } = useTranslation();
  return (
    <EmailPreview>
      <div className="email-preview-pill">{t('preview_item_in_email')}</div>
      <div className="email-preview-card">
        {share_video_preview ? (
          <div className="item-media">{share_video_preview}</div>
        ) : null}

        <div className="item-details">
          {description.length > 0 && (
            <div className="item-description">
              {/* <span
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  lineHeight: '19px',
                  marginRight: 4,
                }}
                className={`item-${'LAB'}`}
              >
                {stoneType}
              </span> */}
              {description.map((dec, i) => {
                const value = dec.value ? dec.value : '';
                return value ? (
                  <Span
                    fontWeight={700}
                    key={i}
                    fontSize={'20px'}
                    marginRight={'5px'}
                  >
                    {value}
                  </Span>
                ) : (
                  ''
                );
              })}
            </div>
          )}
          {selectedDetails?.length > 0 && (
            <div className="item-list">
              {selectedDetails.slice(1).map((item, index) => (
                <div className="list-row" key={index}>
                  <div className="row-label">{item.translated_key}</div>
                  <div className="row-value">
                    {item.value ? item.value : '-'}
                  </div>
                </div>
              ))}
            </div>
          )}
          {share_details.include_price && !userInReview && (
            <div className="item-price-row">
              <div className="list-row">
                <div className="row-label">{t('total_price')}</div>
                <SkeletonElement className="row-amount skeleton" width="100%">
                  {!markupDetailsLoading ? product_price : ''}
                </SkeletonElement>
              </div>
            </div>
          )}
        </div>
      </div>
    </EmailPreview>
  );
};
export const SMSPreviewSection = ({
  share_details,
  data,
  selectedDetails,
  product_price,
  product_carat_price,
  markupDetailsLoading,
  stoneType,
}) => {
  const { t } = useTranslation();
  return (
    <SMSPreview>
      <div className="SMS-preview-pill">{t('preview_message')}</div>
      <div className="SMS-preview-card-container">
        <div className="preview-card">
          <div className="card-description">
            {selectedDetails?.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  flexWrap: 'wrap',
                }}
              >
                {selectedDetails.slice(1).map((dec, i) => {
                  const value = dec.value ? dec.value : '';
                  return value ? (
                    <Span
                      fontWeight={dec.isBold ? '600' : '400'}
                      key={i}
                      className={`item-${dec.id}`}
                      color="#fff"
                    >
                      {dec.isBold ? value : `· ${value}`}
                    </Span>
                  ) : (
                    ''
                  );
                })}
              </div>
            )}
            {share_details.include_price && (
              <SkeletonElement className="item-pricing skeleton" width="100%">
                {!markupDetailsLoading && (
                  <>
                    <span
                      className="item-price"
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '19px',
                        color: '#ffff',
                        paddingRight: '3px',
                      }}
                    >
                      {product_price}
                    </span>
                    <span
                      className="item-price-per-carat"
                      style={{
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        color: '#ffff',
                        paddingRight: '3px',
                      }}
                    >
                      ({product_carat_price})
                    </span>
                  </>
                )}
              </SkeletonElement>
            )}
            <Span
              fontWeight={'400'}
              color="#3f80f4"
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                userSelect: 'none',
              }}
            >
              {dummy_url}
            </Span>
          </div>
          <div className="preview-time">09:08</div>
        </div>
        <Icon
          name="preview_shape"
          className="preview-card-shape"
          height={13}
          width={11}
        />
      </div>
    </SMSPreview>
  );
};
