/**
 * util to find if current page or path is part of active URL
 * @param href {string} Active href
 * @param pathname {string} active path
 * @param urls {string[]} valid sub urls
 * @returns {boolean}
 */
export const isValidActiveUrl = ({ href = '', pathname = '', urls = [] }) => {
  return (
    href &&
    !!urls.find((url) => href.includes(url)) &&
    !!urls.find((url) => pathname.includes(url))
  );
};
