import { theme } from 'fabric/theme';
import styled from 'styled-components';
import check_mark from 'fabric/resources/svg/check_mark.svg';
import { styled_predicate } from 'helpers';

export const TabBarWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.palette.gray100};
  padding: 0 16px;
  .tab_button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 5px;
    ${theme.fonts.mediumPlus};
    color: ${theme.palette.gray800};
    svg {
      color: ${theme.palette.gray800};
    }
    &.active_tab {
      ${theme.fonts.mediumPlus};
      border-bottom: 2px solid ${theme.palette.themeSecondary};
      color: ${theme.palette.themeSecondary};
      svg {
        color: ${theme.palette.themeSecondary};
      }
    }
    &.disabled_tab {
      pointer-events: none;
      cursor: not-allowed;
    }
    .is_primary {
      width: 60px;
      height: 18px;
      background: ${theme.palette.gray100};
      color: ${theme.palette.gray500};
      border-radius: 20px;
      text-align: center;
      ${theme.fonts.smallBold}
    }
  }
`;

export const FixLastRowWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-item: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  ${theme.fonts.mediumPlus}
  .fix_last_row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cancel {
      ${theme.fonts.smallBold}
      line-height:20px;
      border: 1px solid ${theme.palette.borderHard};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 80px;
      background: ${theme.palette.white};
      color: ${theme.palette.Neutrals600};
      box-shadow: none !important;
      border-radius: 8px;
      :hover {
        background: ${theme.palette.Neutrals200};
      }
    }
    .fix {
      ${theme.fonts.smallBold}
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      height: 35px;
      width: 80px;
      border-radius: 8px;
      :hover {
        background: ${theme.palette.brandBlue4};
        box-shadow: none;
      }
    }
  }
`;

export const MarkupModalWrapper = styled.div`
  width: 650px;
  .tab_body {
    padding: 16px;
    .carat_warning_note {
      display: flex;
      align-items: center;
      gap: 5px;
      color: ${theme.palette.themeSecondary};
      ${theme.fonts.smallPlus};
    }
  }
  .radio_buttons {
    padding-bottom: 5px;
    label {
      border-bottom: none;
    }
    .same_markup_values_radio {
      gap: 5px;
      > div {
        width: 16px;
        height: 16px;
        > span {
          width: 8px;
          height: 8px;
        }
      }
    }
    .custom_markup_values_radio {
      gap: 5px;
      > div {
        width: 16px;
        height: 16px;
        > span {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .markup_footer {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 16px 10px 16px;
    border-top: 1px solid ${theme.palette.gray100};
    &--cancel {
      border: 1px solid ${theme.palette.gray300};
      background: ${theme.palette.white};
      color: ${theme.palette.gray800};
      border-radius: 5px;
      ${theme.fonts.smallBolder}
      &:hover {
        background: ${theme.palette.white};
        border: 1px solid rgba(161, 161, 170, 1);
        box-shadow: none;
      }
    }
    .while_loading {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: center;
    }
    &--apply {
      border-radius: 5px;
      ${theme.fonts.smallBolder}
      &:hover {
        background: ${theme.palette.brandBlue4};
        box-shadow: none;
      }
      &:disabled {
        cursor: not-allowed;
        border-radius: 5px;
        box-shadow: none;
        ${theme.fonts.smallBolder}
      }
    }
  }
  .markup_body_no_min {
    height: unset !important;
    min-height: unset !important;
  }
  .markup_body {
    border: 1px solid ${theme.palette.gray300};
    border-radius: 5px;
    margin: 15px 0px;
    height: 295px;
    min-height: 295px;
    position: relative;
    #markup_values {
      max-height: 87% !important;
    }
    .add_row_button_wrapper {
      padding: 24px 16px;
      .add_row_button {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        width: 100%;
        margin: unset;
        height: 30px;
        background: ${theme.palette.neutral200};
        border-radius: 8px;
        border-top: 1px solid ${theme.palette.gray100};
        :hover {
          background: rgba(24, 24, 27, 0.03);
          box-shadow: none;
        }
        :disabled {
          box-shadow: none;
          background: ${theme.palette.gray50};
          color: ${theme.palette.gray300};
          svg {
            color: ${theme.palette.gray300};
          }
        }
        &--label {
          color: ${theme.palette.gray700};
          ${theme.fonts.smallBolder}
        }
      }
    }
    &--headers {
      display: flex;
      padding: 10px;
      border-bottom: 1px solid ${theme.palette.gray100};
      color: ${theme.palette.gray800};
      ${theme.fonts.smallBold}
      .column {
        flex-grow: 1;
      }
      .label_col1 {
        flex-basis: 0;
        gap: 4px;
        align-items: center;
        display: flex;
      }
      .label_col2 {
        flex-basis: 7%;
        gap: 4px;
        align-items: center;
        display: flex;
      }
    }
    &--row:last-child {
      border-bottom: unset;
    }
    &--row {
      padding: 5px;
      border-bottom: 1px solid ${theme.palette.gray100};
      text-align: center;
      .row_items {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group {
        margin-bottom: unset !important;
      }
      .markup_input {
        border: 1px solid ${theme.palette.gray300};
        height: 34px;
        border-radius: 5px;
      }
      .column {
        flex-grow: 1;
      }
      .column_1 {
        flex-basis: 12%;
        text-align: end;
        ${theme.fonts.mediumBold}
        color: ${theme.palette.gray800};
      }

      .column_2 {
        flex-basis: 125px;
      }

      .column_3 {
        flex-basis: 10%;
      }
      .column_4 {
        text-align: left;
        width: 143px;
        ${theme.fonts.small}
        line-height:12px;
        color: ${theme.semanticColors.subtleText};
      }
      .column_5 {
        display: flex;
        justify-content: center;
        align-items: center;
        .blank_div {
          width: 34px;
          height: 24px;
        }
        .icon_div {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px 8px;
          border-radius: 8px;
          :hover {
            background: ${theme.palette.Neutrals100};
          }
        }
      }
    }
  }
  .preference_section {
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    &--info {
      ${theme.fonts.medium}
      line-height: 14px;
      .guide-link {
        padding-left: 8px;
        text-decoration: underline;
        ${theme.fonts.mediumPlus}
        line-height: 14px;
      }
    }
    &--tabs_section {
      padding: 20px 0;
      display: flex;
      align-items: center;
      gap: 12px;
      &__tabs {
        display: flex;
        align-items: center;
        .no-border-left {
          border-left: none;
        }
      }
      .drop_down {
        height: 100%;
      }
    }
    .markup_buttons--reset {
      align-self: flex-end;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: ${theme.palette.white};
      color: ${theme.palette.gray800};
      border: 1px solid ${theme.palette.gray300};
      border-radius: 8px;
      padding: 6px 8px !important;
      ${theme.fonts.mediumPlus}
      :hover {
        background: ${theme.palette.white};
        box-shadow: none;
        border: 1px solid rgba(161, 161, 170, 1);
      }
      :disabled {
        background: ${theme.palette.gray50};
        border: 1px solid ${theme.palette.gray200};
        color: ${theme.palette.gray300};
        svg {
          color: ${theme.palette.gray300};
        }
      }
    }
  }
  .select_as_primary {
    .custom__checkbox {
      ${theme.fonts.mediumPlus}
      color:${theme.palette.gray800};
      .checkbox_media {
        min-width: 14px;
        margin-top: 4px;
        width: 14px !important;
        height: 14px !important;
      }
      .checkbox_checked {
        background: ${theme.palette.brandBlue4} url(${check_mark}) no-repeat
          center;
      }
    }
    .primary_option_description {
      ${theme.fonts.smallPlus}
      color:${theme.palette.gray500};
    }
  }
`;

export const SelectionBtn = styled.div`
  padding: 12px;
  cursor: pointer;
  border: ${({ is_active }) =>
    is_active
      ? `2px solid ${theme.palette.themeSecondary}`
      : `1px solid ${theme.palette.Neutrals300}`};
  border-radius: ${({ is_first, is_last }) =>
    is_first ? '8px 0 0 8px' : is_last ? ' 0 8px 8px 0' : '0'};
  ${styled_predicate(({ is_last, is_active }) => !is_last && !is_active)} {
    border-right: none;
  }
`;
