import dayjs from 'dayjs';

/**
 * compareFunction is helper compare function which can be passed to compare two values
 *
 * Note:
 * * In Ascending order sort icon points --> upwards    ⏶ [1,2,3,7,9,..13,...]
 * * In Descending order sort icon points --> downwards ⏷ [...13, ..9,7,3,2,1]
 * @param {any} a - first value
 * @param {any} b - second value.
 * @return {number} number (-1 | 0 | 1)
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 */
export function compareFunction(a, b) {
  // value > than 0, sort b before a
  if (a > b) {
    return 1;
  }
  // value < 0, leave a and b in the same order
  if (a < b) {
    return -1;
  }
  //  value == 0, leave a and b in the same order
  return 0;
}

/**
 * compareDateFunction is helper compare function which can be passed to compare
 * two valid time stamps
 *
 * Note:
 * * In Ascending order sort icon points --> upwards    ⏶ [1,2,3,7,9,..13,...]
 * * In Descending order sort icon points --> downwards ⏷ [...13, ..9,7,3,2,1]
 * @param {Date} d1 - first value
 * @param {Date} d2 - second value.
 * @param {boolean} isAscending - In ascending order.
 * @return {number} number (-1 | 0 | 1)
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 */
export const compareDateFunction = (d1, d2, isAscending = false) => {
  const date1 = new Date(d1),
    date2 = new Date(d2);
  // null or invalid dates will be sorted after anything else
  if (!dayjs(date1).isValid()) {
    return 1;
  }
  if (!dayjs(date2).isValid()) {
    return -1;
  }

  // equal dates will sort equally
  if (date1 === date2) {
    return 0;
  }
  // if in ascending -> the lowest sorts first
  else if (isAscending) {
    return date1 < date2 ? -1 : 1;
  } // otherwise, if descending -> the highest sorts first
  else {
    return date1 < date2 ? 1 : -1;
  }
  // OR we can also achieve same by following
  // return date2 - date1 instead of above if-else-if block
};
