import React, { useState, useEffect } from 'react';
import { ConfirmEmailAndPhoneWrapper } from '../styles';
import { SignupContainer } from '../../../Signup/SignupContainer';
import { useErrorWrapper } from 'fabric/hooks';
import { formatPhoneNumberIntl } from 'react-phone-number-input/core';
import { Trans, useTranslation } from 'react-i18next';
import { CustomTrans } from 'helpers';
import { CustomInput } from 'Signup/components/CustomSignupComponents';
import { PrimaryButton } from 'Signup/components/CustomSignupComponents';
import { useMutation } from '@apollo/client';
import { graphqlClient } from 'graphql/factory';
import {
  CONFIRM_PHONE_DATA,
  CONFIRM_EMAIL_WITH_CODE,
  REQUEST_EMAIL,
  CHANGE_PHONE,
} from 'graphql/mutations/common/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import ScrollToTop from 'layout/ScrollToTop';
import { AddClass } from 'Elements';
import { isMobile } from 'utils';
import { Track } from 'fabric/integrations';
import { Icon } from '@nivoda/ui';

export const ConfirmEmailAndPhone = ({
  email_sent,
  phone_sent,
  email,
  phone,
  setState,
  state,
  phone_confirmed,
  email_confirmed,
}) => {
  useEffect(() => {
    Track.track_page('Signup page -> Confirm email and phone');
  }, []);
  const [fnWrapper, context] = useErrorWrapper({
    showSuccess: false,
    disableHelmet: true,
    useToastNotification: true,
    hideSuccess: true,
    toastNotificationProps: { duration: 10000 },
  });
  const [request_new_code, set_request_new_code] = useState({
    email: false,
    phone: false,
  });
  const [code_input, set_code_input] = useState('');
  const [newReCaptchaToken, setNewReCaptchaToken] = useState('');
  const [error, set_error] = useState('');
  const { t } = useTranslation();
  const [confirm_email_with_code] = useMutation(CONFIRM_EMAIL_WITH_CODE, {
    client: graphqlClient,
  });
  const [confirm_phone_with_code] = useMutation(CONFIRM_PHONE_DATA, {
    client: graphqlClient,
  });
  const [request_email] = useMutation(REQUEST_EMAIL, {
    client: graphqlClient,
  });
  const [change_phone] = useMutation(CHANGE_PHONE, { client: graphqlClient });

  const onSubmit = async () => {
    if (email_sent && !email_confirmed) {
      await fnWrapper(
        async () => {
          context.setBusy({
            type: 'CONT_BUTTON',
            status: true,
          });
          let res = await confirm_email_with_code({
            variables: {
              email: email.toLowerCase().trim(),
              confirmation_code: code_input,
              reCaptchaToken: newReCaptchaToken,
            },
          });

          if (res.data.confirm_email_with_code === true) {
            await setState({
              ...state,
              email_sent: false,
              phone_sent: true,
              email_confirmed: true,
              token: '',
            });
            await set_error('');
            await set_code_input('');
          }
          context.setBusy({
            type: '',
            status: false,
          });
        },
        null,
        {
          error: {
            title: 'Error',
            messageResolver: (msg) => {
              if (msg.includes('RECAPTCHA_ERROR')) {
                set_error(t('Invalid_captcha_try_again'));
                return t('Invalid_captcha_try_again');
              } else if (msg.includes('NO_CODE')) {
                set_error(t('incorrect_code'));
                return t('incorrect_code');
              } else if (msg.includes('EXPIRED_CODE')) {
                set_error(t('your_code_expired'));
                return t('your_code_expired');
              } else if (
                msg.includes(
                  'Permission to send an SMS has not been enabled for the region'
                )
              ) {
                set_error(
                  <div>
                    <strong>{t('registration_failed')}</strong>
                    <br />
                    {t('region_not_supported_message')}
                  </div>
                );
                return (
                  <div>
                    <strong>{t('registration_failed')}</strong>
                    <br />
                    {t('region_not_supported_message')}
                  </div>
                );
              } else {
                set_error(t('something_wrong_contact_support'));
                return t('something_wrong_contact_support');
              }
            },
          },
        }
      );
    } else if (phone_sent && !phone_confirmed) {
      await fnWrapper(
        async () => {
          context.setBusy({
            type: 'CONT_BUTTON',
            status: true,
          });
          let res = await confirm_phone_with_code({
            variables: { phone: phone, sms_code: code_input },
          });
          if (res.data.confirm_phone === true) {
            await setState({
              ...state,
              phone_sent: false,
              progress: 3,
              phone_confirmed: true,
            });
            await set_error('');
          }
          context.setBusy({
            type: '',
            status: false,
          });
        },
        null,
        {
          error: {
            title: 'Error',
            messageResolver: (msg) => {
              if (msg.includes('NO_CODE')) {
                set_error(t('incorrect_code'));
                return t('incorrect_code');
              } else if (msg.includes('EXPIRED_CODE')) {
                set_error(t('your_code_expired'));
                return t('your_code_expired');
              } else if (
                msg.includes(
                  'Permission to send an SMS has not been enabled for the region'
                )
              ) {
                set_error(
                  <div>
                    <strong>{t('registration_failed')}</strong>
                    <br />
                    {t('region_not_supported_message')}
                  </div>
                );
                return (
                  <div>
                    <strong>{t('registration_failed')}</strong>
                    <br />
                    {t('region_not_supported_message')}
                  </div>
                );
              } else {
                set_error(t('something_wrong_contact_support'));
                return t('something_wrong_contact_support');
              }
            },
          },
        }
      );
    } else if (phone_confirmed && email_confirmed) {
      await setState({
        ...state,
        email_phone_verify: false,
        basic_info: false,
        business_info: true,
      });
    }
    set_request_new_code({
      email: false,
      phone: false,
    });
  };

  const requestEmail = async (email) => {
    await fnWrapper(
      async () => {
        context.setBusy({
          type: 'REQUEST_EMAIL',
          status: true,
        });

        let res = await request_email({
          variables: { email: email.toLowerCase() },
        });
        //if (res)   setNewEmailCode(true);
        if (res) {
          set_request_new_code({
            email: true,
            phone: false,
          });
        }
        await set_error('');

        context.setBusy({
          type: '',
          status: false,
        });
      },
      null,
      {
        error: {
          title: 'Error',
          messageResolver: (msg) => {
            if (msg.includes('Not allowed')) {
              set_error(t('contact_tech_support'));
              return (
                <div>
                  <strong>{t('no_password_attempts_left')}</strong>
                  <p style={{ fontSize: 14 }}>
                    {t('no_password_attempts_left_detail_text')}
                  </p>
                </div>
              );
            } else {
              set_error(t('failed_to_resend_code'));
              return t('failed_to_resend_code');
            }
          },
        },
      }
    );
  };

  const resendPhoneCode = async (phone) => {
    await fnWrapper(
      async () => {
        context.setBusy({
          type: 'REQUEST_PHONE',
          status: true,
        });

        let res = await change_phone({
          variables: { phone: phone, is_super_saver: false },
        });
        // if (res) setNewPhoneCode(true);
        if (res) set_request_new_code({ email: false, phone: true });
        await set_error('');

        context.setBusy({
          type: '',
          status: false,
        });
      },
      null,
      {
        error: {
          title: 'Error',
          messageResolver: (msg) => {
            if (msg.includes('Not allowed.')) {
              set_error(t('no_password_attempts_left'));
              return (
                <div>
                  <strong>{t('no_password_attempts_left')}</strong>
                  <p style={{ fontSize: 14 }}>
                    {t('no_password_attempts_left_detail_text')}
                  </p>
                </div>
              );
            } else {
              set_error(t('failed_to_resend_code'));
              return t('failed_to_resend_code');
            }
          },
        },
      }
    );
  };

  if (email_confirmed && phone_confirmed) {
    setState({
      ...state,
      basic_info: false,
      email_phone_verify: false,
      business_info: true,
    });
  }

  return (
    <SignupContainer>
      <ScrollToTop />
      <AddClass
        element={document.body}
        className={isMobile() ? 'confirm-email-page' : 'do-nothng'}
      >
        <ConfirmEmailAndPhoneWrapper className="confirm_email_wrapper">
          {email_sent && !email_confirmed ? (
            <>
              <div className="confirm_email_wrapper--header">
                <span className="confirm_email_wrapper--header__title">
                  <CustomTrans
                    text="lets_verify_your_information_first_your_e-mail_address"
                    returnPlainText={true}
                  />
                </span>
              </div>
              <CoreComponent
                set_code_input={set_code_input}
                title_display={email.toLowerCase().trim()}
                is_phone={false}
                request_new_code={request_new_code.email}
                error={error}
                onBtnClick={() => onSubmit()}
                onResendClick={() => requestEmail(email)}
                btnDisabled={code_input.length !== 4 || context.busy.status}
                btnLoading={context.busy.status}
                setNewReCaptchaToken={setNewReCaptchaToken}
              />
            </>
          ) : (
            <>
              <div className="confirm_email_wrapper--header">
                <span className="confirm_email_wrapper--header__title">
                  <CustomTrans
                    text="next_lets_verify_your_phone_number"
                    returnPlainText={true}
                  />
                </span>
              </div>
              <CoreComponent
                set_code_input={set_code_input}
                title_display={phone || formatPhoneNumberIntl(phone)}
                is_phone={true}
                request_new_code={request_new_code.phone}
                error={error}
                onBtnClick={() => onSubmit()}
                onResendClick={() => resendPhoneCode(phone)}
                btnDisabled={code_input.length !== 4 || context.busy.status}
                btnLoading={context.busy.status}
                setNewReCaptchaToken={setNewReCaptchaToken}
              />
            </>
          )}
        </ConfirmEmailAndPhoneWrapper>
      </AddClass>
    </SignupContainer>
  );
};

const OTPInput = ({ onChange, onBlur, is_phone }) => {
  const [otpDigits, setOtpDigits] = useState(['', '', '-', '', '']);

  const handleDigitChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index] = value;
      setOtpDigits(newOtpDigits);

      // Focus on the previous input if backspace is pressed and the input is empty
      if (index > 0 && value === '') {
        const prevInput = document.querySelector(`#otp-${index - 1}`);
        prevInput && prevInput.focus();
      }
      // Focus on the next input (if available)
      if (index < otpDigits.length - 1 && value !== '') {
        const nextInput =
          index !== 1
            ? document.querySelector(`#otp-${index + 1}`)
            : document.querySelector(`#otp-${index + 2}`);
        nextInput && nextInput.focus();
      }
    }
  };

  const onPaste = (event) => {
    const pasteData = (event.clipboardData || window?.clipboardData).getData(
      'text'
    );
    if (pasteData && pasteData.length > 0 && pasteData.length <= 5) {
      setOtpDigits([
        pasteData[0],
        pasteData[1],
        '-',
        pasteData[2],
        pasteData[3],
      ]);
    }
  };

  useEffect(() => {
    if (is_phone) {
      setOtpDigits(['', '', '-', '', '']);
    }
  }, [is_phone]);

  useEffect(() => {
    onChange(otpDigits.join('').replace('-', ''));
    if (otpDigits?.length === 4) {
      onBlur();
    }
    // eslint-disable-next-line
  }, [otpDigits]);

  return (
    <div className="confirm_email_wrapper--content__codearea">
      {otpDigits.map((digit, index) =>
        index !== 2 ? (
          <CustomInput
            className="otp_input"
            key={index}
            id={`otp-${index}`}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => {
              handleDigitChange(index, e);
            }}
            onFocus={(e) => e.target.select()}
            inputMode="numeric" // Show numeric keyboard on mobile devices
            onPaste={onPaste}
          />
        ) : (
          <hr key={index} className="hr_line" />
        )
      )}
    </div>
  );
};

const CoreComponent = ({
  set_code_input,
  title_display,
  is_phone,
  request_new_code,
  error,
  onBtnClick,
  onResendClick,
  btnDisabled,
  btnLoading,
  setNewReCaptchaToken,
}) => {
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    let timerInterval;

    if (isTimerActive && !btnLoading) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [isTimerActive, btnLoading]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimerActive(false);
    }
  }, [timer]);

  useEffect(() => {
    if (error) {
      setIsTimerActive(false);
    }
  }, [error]);

  const handleResendClick = () => {
    onResendClick();
    setIsTimerActive(true);
    setTimer(60);
  };

  return (
    <>
      <div className="confirm_email_wrapper--content">
        <div className="confirm_email_wrapper--content__info">
          <Trans
            i18nKey={'please_enter_the_6_digit_code_sent_to_email_below'}
            values={{
              email: title_display,
            }}
            components={{ bold: <strong /> }}
          />
        </div>
        <OTPInput
          onChange={(text) => {
            set_code_input(text);
          }}
          is_phone={is_phone}
        />
        {(request_new_code || error) && (
          <span
            className={`confirm_email_wrapper--content__${
              request_new_code ? 'new_code' : 'error'
            }`}
          >
            {request_new_code ? (
              <CustomTrans text={'new_code_sent'} returnPlainText={true} />
            ) : (
              error
            )}
          </span>
        )}
        {!is_phone && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
            onChange={(token) => setNewReCaptchaToken(token)}
            onExpired={(e) => setNewReCaptchaToken('')}
          />
        )}
      </div>
      <div className="confirm_email_wrapper--footer">
        <PrimaryButton
          btnText="continue"
          loading={btnLoading}
          disabled={btnDisabled}
          onClick={onBtnClick}
        />
        <span className={`resend_otp ${isTimerActive ? 'otp_timer' : ''}`}>
          <CustomTrans text={'i_didnt_receive_a_code'} returnPlainText={true} />
          .
          {btnLoading ? (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Icon name="loader" size={16} color="black" />
            </span>
          ) : (
            <span>
              {isTimerActive ? (
                <span className="resend_otp--timer">
                  <CustomTrans text={'resend_in'} returnPlainText={true} />
                  <span className="timer">{timer}</span>
                  <CustomTrans text={'seconds'} returnPlainText={true} />
                </span>
              ) : (
                <span
                  className="resend_otp--text"
                  onClick={isTimerActive ? null : handleResendClick}
                >
                  <CustomTrans text={'resend_code'} returnPlainText={true} />
                </span>
              )}
            </span>
          )}
        </span>
      </div>
    </>
  );
};

// export const Timer = ({ minutes }) => {
//   const [timeRemaining, setTimeRemaining] = useState(minutes / 60); // 300 seconds = 5 minutes

//   useEffect(() => {
//     if (timeRemaining <= 0) {
//       // Timer has reached 0, you can perform any desired actions here
//       clearInterval(intervalId);
//       alert('Timer has ended!');
//     }
//   }, [timeRemaining]);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setTimeRemaining((prevTime) => prevTime - 1);
//     }, 1000);

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, []);

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secondsLeft = seconds % 60;
//     return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
//   };

//   return (
//     <div>
//       <h1>Timer</h1>
//       <p>Time remaining: {formatTime(timeRemaining)}</p>
//     </div>
//   );
// };
