import {
  Asscher,
  Baguette,
  Round,
  Princess,
  Oval,
  Emerald,
  SQ_Emerald,
  CushionAll,
  CushionBrilliant,
  CushionModified,
  Radiant,
  Marquise,
  Pear,
  SQ_Radiant,
  Heart,
  OldMiner,
  Star,
  Rose,
  Square,
  Half_moon,
  Trapezoid,
  Flanders,
  Briolette,
  Pentagonal,
  hexagonal,
  Octagonal,
  Triangular,
  Trilliant,
  Calf,
  Shield,
  Lozenge,
  Kite,
  EuropeanCut,
  TaperedBaguette,
  Bullet,
  TaperedBullet,
  Other,
} from '../diamond-image/sample_images';
// checkboxOrSelect should be "title" if it is checkbox
export const colorOptions = (checkboxOrSelect = 'label') => [
  { value: 'D', [checkboxOrSelect]: 'D' },
  { value: 'E', [checkboxOrSelect]: 'E' },
  { value: 'F', [checkboxOrSelect]: 'F' },
  { value: 'G', [checkboxOrSelect]: 'G' },
  { value: 'H', [checkboxOrSelect]: 'H' },
  { value: 'I', [checkboxOrSelect]: 'I' },
  { value: 'J', [checkboxOrSelect]: 'J' },
  { value: 'K', [checkboxOrSelect]: 'K' },
  { value: 'L', [checkboxOrSelect]: 'L' },
  { value: 'M', [checkboxOrSelect]: 'M' },
  { value: 'N', [checkboxOrSelect]: 'N' },
  { value: 'NO', [checkboxOrSelect]: 'N-O' },
  { value: 'PR', [checkboxOrSelect]: 'P-R' },
  { value: 'SZ', [checkboxOrSelect]: 'S-Z' },
  { value: 'OP', [checkboxOrSelect]: 'O-P' },
  { value: 'QR', [checkboxOrSelect]: 'Q-R' },
  { value: 'ST', [checkboxOrSelect]: 'S-T' },
  { value: 'UV', [checkboxOrSelect]: 'U-V' },
  { value: 'WX', [checkboxOrSelect]: 'W-X' },
  { value: 'XY', [checkboxOrSelect]: 'X-Y' },
  { value: 'YZ', [checkboxOrSelect]: 'Y-Z' },
  { value: 'FANCY', [checkboxOrSelect]: 'FANCY' },
];

export const girdleOptions = (checkboxOrSelect = 'label') => [
  { name: 'ETN', [checkboxOrSelect]: 'Extremely Thin', value: 'ETN' },
  { name: 'VTN', [checkboxOrSelect]: 'Very Thin', value: 'VTN' },
  { name: 'THN', [checkboxOrSelect]: 'Thin', value: 'THN' },
  { name: 'STN', [checkboxOrSelect]: 'Slightly Thin', value: 'STN' },
  { name: 'MED', [checkboxOrSelect]: 'Medium', value: 'MED' },
  { name: 'STK', [checkboxOrSelect]: 'Slightly Thick', value: 'STK' },
  { name: 'THK', [checkboxOrSelect]: 'Thick', value: 'THK' },
  { name: 'VTK', [checkboxOrSelect]: 'Very Thick', value: 'VTK' },
  { name: 'ETK', [checkboxOrSelect]: 'Extremely Thick', value: 'ETK' },
];

export const cutOptions = (checkboxOrSelect = 'label') => {
  return [
    { value: 'ID', [checkboxOrSelect]: 'Ideal' },
    { value: 'EX', [checkboxOrSelect]: 'Excellent' },
    { value: 'F', [checkboxOrSelect]: 'Fair' },
    { value: 'VG', [checkboxOrSelect]: 'Very Good' },
    { value: 'GD', [checkboxOrSelect]: 'Good' },
    { value: 'P', [checkboxOrSelect]: 'Poor' },
    { value: '8X', [checkboxOrSelect]: '8X' },
  ];
};

export const polishOptions = (checkboxOrSelect = 'label') => {
  return [
    { value: 'EX', [checkboxOrSelect]: 'Excellent' },
    { value: 'F', [checkboxOrSelect]: 'Fair' },
    { value: 'VG', [checkboxOrSelect]: 'Very Good' },
    { value: 'GD', [checkboxOrSelect]: 'Good' },
    { value: 'P', [checkboxOrSelect]: 'Poor' },
  ];
};

export const symmetryOptions = (checkboxOrSelect = 'label') => {
  return [
    { value: 'EX', [checkboxOrSelect]: 'Excellent' },
    { value: 'F', [checkboxOrSelect]: 'Fair' },
    { value: 'VG', [checkboxOrSelect]: 'Very Good' },
    { value: 'GD', [checkboxOrSelect]: 'Good' },
    { value: 'P', [checkboxOrSelect]: 'Poor' },
  ];
};

export const floIntOptions = (checkboxOrSelect = 'label') => [
  { value: 'NON', [checkboxOrSelect]: 'None' },
  { value: 'FNT', [checkboxOrSelect]: 'Faint' },
  { value: 'MED', [checkboxOrSelect]: 'Medium' },
  { value: 'STG', [checkboxOrSelect]: 'Strong' },
  { value: 'VST', [checkboxOrSelect]: 'V-Strong' },
  { value: 'VSL', [checkboxOrSelect]: 'Very Slight' },
];

export const fancyOvertoneOptions = [
  { value: 'None', label: 'None' },
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Yellowish', label: 'Yellowish' },
  { value: 'Pink', label: 'Pink' },
  { value: 'Pinkish', label: 'Pinkish' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Blueish', label: 'Blueish' },
  { value: 'Red', label: 'Red' },
  { value: 'Reddish', label: 'Reddish' },
  { value: 'Green', label: 'Green' },
  { value: 'Greenish', label: 'Greenish' },
  { value: 'Purple', label: 'Purple' },
  { value: 'Purplish', label: 'Purplish' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Orangey', label: 'Orangey' },
  { value: 'Violet', label: 'Violet' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Grayish', label: 'Grayish' },
  { value: 'Black', label: 'Black' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Brownish', label: 'Brownish' },
  { value: 'Champagne', label: 'Champagne' },
  { value: 'Cognac', label: 'Cognac' },
  { value: 'Chameleon', label: 'Chameleon' },
  { value: 'White', label: 'White' },
  { value: 'Other', label: 'Other' },
];

export const fancyColorOptions = [
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Pink', label: 'Pink' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Red', label: 'Red' },
  { value: 'Green', label: 'Green' },
  { value: 'Purple', label: 'Purple' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Violet', label: 'Violet' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Black', label: 'Black' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Champagne', label: 'Champagne' },
  { value: 'Cognac', label: 'Cognac' },
  { value: 'Chameleon', label: 'Chameleon' },
  { value: 'White', label: 'White' },
  { value: 'Salt and Pepper', label: 'salt_and_pepper' },
  { value: 'Other', label: 'Other' },
];

export const fancyIntensityOptions = [
  { value: 'Faint', label: 'Faint' },
  { value: 'Very_Light', label: 'Very Light' },
  { value: 'Light', label: 'Light' },
  { value: 'Fancy_Light', label: 'Fancy Light' },
  { value: 'Fancy', label: 'Fancy' },
  { value: 'Fancy_Dark', label: 'Fancy Dark' },
  { value: 'Fancy_Intense', label: 'Fancy Intense' },
  { value: 'Fancy_Vivid', label: 'Fancy Vivid' },
  { value: 'Fancy_Deep', label: 'Fancy Deep' },
];

// noinspection SpellCheckingInspection
export const certificateLabTypes = {
  GIA: 'GIA',
  IGI: 'IGI',
  HRD: 'HRD',
  GCAL: 'GCAL',
  EGL: 'EGL',
  EGLISR: 'EGLISR',
  AGS: 'AGS',
  NONE: 'NONE', // Non cert
  LAB: 'LAB', // legacy as well
  DBIOD: 'DBIOD',
  GSI: 'GSI',
  SGL: 'SGL',
  STOCKID: 'STOCKID', // To support diamond requests with Stock IDs
  OTHER: 'OTHER', // To support 'other' diamond requests
};

// noinspection SpellCheckingInspection
export const labOptions = [
  { value: certificateLabTypes.GIA, label: 'GIA' },
  { value: certificateLabTypes.IGI, label: 'IGI' },
  { value: certificateLabTypes.HRD, label: 'HRD' },
  { value: certificateLabTypes.GCAL, label: 'GCAL' },
  { value: certificateLabTypes.EGL, label: 'EGL' },
  { value: certificateLabTypes.DBIOD, label: 'DBIOD' },
  { value: certificateLabTypes.GSI, label: 'GSI' },
  { value: certificateLabTypes.SGL, label: 'SGL' },
  { value: certificateLabTypes.AGS, label: 'AGS' },
  { value: certificateLabTypes.EGLISR, label: 'EGLISR' },
  { value: certificateLabTypes.OTHER, label: 'supplier_cert' },
  { value: certificateLabTypes.NONE, label: 'Non-cert' },
];

export const shapeMapper = [
  { label: 'ROUND', value: 'RBC' },
  { label: 'PEAR', value: 'PB' },
  { label: 'PRINCESS', value: 'RMB' },
  { label: 'PRINCESS', value: 'SMB' },
  { label: 'MARQUISE', value: 'MB' },
  { label: 'EMERALD', value: 'EM' },
  { label: 'ASSCHER', value: 'CRSC' },
  { label: 'OVAL', value: 'OB' },
  { label: 'RADIANT', value: 'CRMB' },
  { label: 'HEART', value: 'HB' },
  { label: 'CUSHION', value: 'CMB' },
  { label: 'CUSHION', value: 'RCRMB' },
  { label: 'CUSHION', value: 'OMB' },
  { label: 'CUSHION', value: 'CB' },
  { label: 'EMERALD', value: 'SEM' },
  { label: 'ASSHER', value: 'CSSC' },
  { label: 'ASSHER', value: 'CSMB' },
  { label: 'PRINCESS', value: 'SHB' },
];

export const clarityOptions = [
  { value: 'FL', title: 'FL' },
  { value: 'IF', title: 'IF' },
  { value: 'VVS1', title: 'VVS1' },
  { value: 'VVS2', title: 'VVS2' },
  { value: 'VS1', title: 'VS1' },
  { value: 'VS2', title: 'VS2' },
  { value: 'SI1', title: 'SI1' },
  { value: 'SI2', title: 'SI2' },
  { value: 'I1', title: 'I1' },
  { value: 'I2', title: 'I2' },
  { value: 'I3', title: 'I3' },
];

export const shapeOptions = [
  {
    value: 'ROUND',
    image: require(`../diamond-image/sample_images/ROUND.svg`),
    title: 'Round',
    svg_image: Round,
  },
  {
    value: 'OVAL',
    image: require(`../diamond-image/sample_images/OVAL.svg`),
    title: 'Oval',
    shapeClass: 'stroke',
    svg_image: Oval,
  },
  {
    value: 'PRINCESS',
    image: require(`../diamond-image/sample_images/PRINCESS.svg`),
    title: 'Princess',
    svg_image: Princess,
  },
  {
    value: 'EMERALD',
    image: require(`../diamond-image/sample_images/EMERALD.svg`),
    title: 'Emerald',
    svg_image: Emerald,
  },
  {
    value: 'SQUARE EMERALD',
    image: require(`../diamond-image/sample_images/EMERALD.svg`),
    title: 'SQ. Emerald',
    svg_image: SQ_Emerald,
  },
  {
    value: 'ASSCHER',
    image: require(`../diamond-image/sample_images/ASSCHER.svg`),
    title: 'Asscher',
    svg_image: Asscher,
  },
  {
    value: 'CUSHION ALL',
    image: require(`../diamond-image/sample_images/CUSHION_ALL.svg`),
    title: 'Cushion All',
    svg_image: CushionAll,
  },
  {
    value: 'CUSHION BRILLIANT',
    image: require(`../diamond-image/sample_images/CUSHION_BRILLIANT.svg`),
    title: 'Cushion Brilliant',
    svg_image: CushionBrilliant,
  },
  {
    value: 'CUSHION MODIFIED',
    image: require(`../diamond-image/sample_images/CUSHION_MODIFIED.svg`),
    title: 'Cushion Modified',
    svg_image: CushionModified,
  },
  {
    value: 'RADIANT',
    image: require(`../diamond-image/sample_images/RADIANT.svg`),
    title: 'Radiant',
    svg_image: Radiant,
  },
  {
    value: 'MARQUISE',
    image: require(`../diamond-image/sample_images/MARQUISE.svg`),
    title: 'Marquise',
    svg_image: Marquise,
  },
  {
    value: 'PEAR',
    image: require(`../diamond-image/sample_images/PEAR.svg`),
    title: 'Pear',
    shapeClass: 'stroke',
    svg_image: Pear,
  },
  {
    value: 'SQUARE RADIANT',
    image: require(`../diamond-image/sample_images/SQ_RADIANT.svg`),
    title: 'Sq. Radiant',
    svg_image: SQ_Radiant,
  },
  {
    value: 'HEART',
    image: require(`../diamond-image/sample_images/HEART.svg`),
    title: 'Heart',
    shapeClass: 'stroke',
    svg_image: Heart,
  },
  {
    value: 'OLD MINER',
    image: require(`../diamond-image/sample_images/OLD_MINER.svg`),
    title: 'Old Miner',
    svg_image: OldMiner,
  },
  {
    value: 'STAR',
    image: require(`../diamond-image/sample_images/STAR.svg`),
    title: 'Star',
    svg_image: Star,
  },
  {
    value: 'ROSE',
    image: require(`../diamond-image/sample_images/ROSE.svg`),
    title: 'Rose',
    svg_image: Rose,
  },
  {
    value: 'SQUARE',
    image: require(`../diamond-image/sample_images/SQUARE.svg`),
    title: 'Square',
    svg_image: Square,
  },
  {
    value: 'HALF MOON',
    image: require(`../diamond-image/sample_images/HALF_MOON.svg`),
    title: 'Half Moon',
    svg_image: Half_moon,
  },
  {
    value: 'TRAPEZOID',
    image: require(`../diamond-image/sample_images/TRAPEZOID.svg`),
    title: 'Trapezoid',
    svg_image: Trapezoid,
  },
  {
    value: 'FLANDERS',
    image: require(`../diamond-image/sample_images/FLANDERS.svg`),
    title: 'Flanders',
    svg_image: Flanders,
  },
  {
    value: 'BRIOLETTE',
    image: require(`../diamond-image/sample_images/BRIOLETTE.svg`),
    title: 'Briolette',
    svg_image: Briolette,
  },
  {
    value: 'PENTAGONAL',
    image: require(`../diamond-image/sample_images/PENTAGONAL.svg`),
    title: 'Pentagonal',
    svg_image: Pentagonal,
  },
  {
    value: 'HEXAGONAL',
    image: require(`../diamond-image/sample_images/HEXAGONAL.svg`),
    title: 'Hexagonal',
    svg_image: hexagonal,
  },
  {
    value: 'OCTAGONAL',
    image: require(`../diamond-image/sample_images/OCTAGONAL.svg`),
    title: 'Octagonal',
    svg_image: Octagonal,
  },
  {
    value: 'TRIANGULAR',
    image: require(`../diamond-image/sample_images/TRIANGULAR.svg`),
    title: 'Triangular',
    svg_image: Triangular,
  },
  {
    value: 'TRILLIANT',
    image: require(`../diamond-image/sample_images/TRILLIANT.svg`),
    title: 'Trilliant',
    svg_image: Trilliant,
  },
  {
    value: 'CALF',
    image: require(`../diamond-image/sample_images/CALF.svg`),
    title: 'Calf',
    svg_image: Calf,
  },
  {
    value: 'SHIELD',
    image: require(`../diamond-image/sample_images/SHIELD.svg`),
    title: 'Shield',
    svg_image: Shield,
  },
  {
    value: 'LOZENGE',
    image: require(`../diamond-image/sample_images/LOZENGE.svg`),
    title: 'Lozenge',
    svg_image: Lozenge,
  },
  {
    value: 'KITE',
    image: require(`../diamond-image/sample_images/KITE.svg`),
    title: 'Kite',
    svg_image: Kite,
  },
  {
    value: 'EUROPEAN CUT',
    image: require(`../diamond-image/sample_images/EUROPEAN_CUT.svg`),
    title: 'European Cut',
    svg_image: EuropeanCut,
  },
  {
    value: 'BAGUETTE',
    image: require(`../diamond-image/sample_images/BAGUETTE.svg`),
    title: 'Baguette',
    svg_image: Baguette,
  },
  {
    value: 'TAPERED BAGUETTE',
    image: require(`../diamond-image/sample_images/TAPERED_BAGUETTE.svg`),
    title: 'Tapered Baguette',
    svg_image: TaperedBaguette,
  },
  {
    value: 'BULLET',
    image: require(`../diamond-image/sample_images/BULLET.svg`),
    title: 'Bullet',
    svg_image: Bullet,
  },
  {
    value: 'TAPERED BULLET',
    image: require(`../diamond-image/sample_images/TAPERED_BULLET.svg`),
    title: 'Tapered Bullet',
    svg_image: TaperedBullet,
  },
  {
    value: 'OTHER',
    image: require(`../diamond-image/sample_images/OTHER.svg`),
    title: 'Other',
    svg_image: Other,
  },
];

export const SHAPES = [
  'ROUND',
  'OVAL',
  'PRINCESS',
  'EMERALD',
  'SQ EMERALD',
  'SQUARE EMERALD',
  'ASSCHER',
  'CUSHION',
  'CUSHION BRILLIANT',
  'CUSHION MODIFIED',
  'RADIANT',
  'SQ RADIANT',
  'SQUARE RADIANT',
  'MARQUISE',
  'PEAR',
  'HEART',
  'OLD MINER',
  'STAR',
  'ROSE',
  'SQUARE',
  'HALF MOON',
  'TRAPEZOID',
  'TRAPEZE EMERALD',
  'FLANDERS',
  'PENTAGONAL',
  'BRIOLETTE',
  'TRIANGULAR',
  'RECTANGULAR',
  'HEXAGONAL',
  'OCTAGONAL',
  'NONAGONAL',
  'DECAGONAL',
  'HEPTAGONAL',
  'TETRAGONAL',
  'POLYGONAL',
  'RHOMBOID',
  'TRILLIANT',
  'CALF',
  'SHIELD',
  'LOZENGE',
  'KITE',
  'EUROPEAN CUT',
  'BAGUETTE',
  'TAPERED BAGUETTE',
  'BULLET',
  'TAPERED BULLET',
  'OTHER',
  'FAN',
  'OCCASIONAL',
];

export const shapesLabelMapping = SHAPES.map((shape) => {
  return { label: shape, value: shape };
});
