import { gql } from '@apollo/client';
import { CertificateLongInfoFragment } from './CertificateFragment';
import { OrderDiamondFragment } from './DiamondFragment';

export const DiamondRequestFragment = gql`
  fragment DiamondRequest on OrderRequest {
    id
    certNumber
    supplier_stock_id
    lab
    created_at
    status
    request_type
    customer_order_number
    comment
    payment_term_selected
    delivery_location {
      name
      address1
      address2
      postalCode
      city
      state
      country
    }
    requester {
      id
      firstName
      lastName
    }
    diamond {
      id
      price
      rapaportDiscount
      # image
      offer {
        id
        price
        discount
        product_price
        product_discount
        Product {
          ...OrderDiamond
        }
      }
      location {
        id
        country
      }
      supplier {
        id
        name
      }
      supplierStockId
      certificate {
        ...CertificateLongInfo
      }
    }

    order_item {
      id
      order {
        id
      }
    }
  }
  ${CertificateLongInfoFragment}
  ${OrderDiamondFragment}
`;
