import React from 'react';
import { Link } from '@reach/router';
import primaryLogo from 'fabric/resources/svg/nivoda-icon/logo-blue.svg';
import whiteLogo from 'fabric/resources/svg/new_nivoda_logo.svg';
import classnames from 'classnames';
import { theme } from '@nivoda/ui';
import { withTranslation } from 'react-i18next';
import { LanguageChanger } from 'components/language_changer';
import { NeedHelp } from 'fabric/integrations';
import { isMobile } from 'utils';

import './Header.css';
import styled from 'styled-components';

const MenuLink = ({ className, to, children }) => {
  let is_mobile = window.innerWidth < 700;
  if (is_mobile) {
    return (
      <a className={className} href={to}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
};
let Div = styled.div`
  .flex-class {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrollTop: false,
      hide_get_started: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll(event) {
    if (event.currentTarget.scrollY !== 0 && !this.state.isScrollTop) {
      this.setState({
        isScrollTop: true,
      });
    } else {
      this.setState({ isScrollTop: false });
    }
  }

  render() {
    const {
      transparent,
      white,
      whiteHeader,
      blueHeader,
      t,
      itemFlexed,
      is_login,
      is_button,
      // openHubSpot,
    } = this.props;
    let is_mobile = window.innerWidth < 700;
    // let active_link = window.location.pathname;
    // let buyer_link = '/get-started';
    // let seller_link = '/suppliers';
    // let about_link = '/about-nivoda';
    // let contact_link = '/contact';
    const navBarClasses = classnames(
      'navbar navbar-expand-lg new-navbar',
      transparent ? 'navbar-transparent' : '',
      white ? 'navbar-white' : '',
      whiteHeader ? 'white-header' : '',
      blueHeader ? 'blue-header' : ''
    );

    return (
      <Div>
        <nav className={navBarClasses}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            className={
              this.state.isScrollTop
                ? 'header-content scroll'
                : 'header-content '
            }
          >
            <div
              className={classnames(
                'navbar-translate',
                itemFlexed ? 'flex-class' : ''
              )}
            >
              <a
                href={'https://nivoda.net'}
                style={{ color: theme.palette.themePrimary, fontWeight: 400 }}
              >
                <img
                  className="white-logo mobile-logo"
                  alt="Nivoda white logo"
                  src={isMobile() ? whiteLogo : primaryLogo}
                />
                <img
                  className="blue-logo mobile-logo"
                  alt="Nivoda blue logo"
                  src={isMobile() && !whiteHeader ? whiteLogo : primaryLogo}
                />
              </a>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {this.props.showCreate && (
                  <div>
                    {is_mobile && (
                      <MenuLink className="primary-btn-design" to="/register">
                        {t('create_account')}
                      </MenuLink>
                    )}
                  </div>
                )}
                {this.props.showHelp && (
                  <div>
                    <NeedHelp
                      is_button={is_button}
                      // toggleIntercom={openHubSpot}
                    />
                  </div>
                )}
                {(is_mobile || is_login) && (
                  <div
                    style={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 12,
                    }}
                  >
                    <LanguageChanger
                      is_public={true}
                      is_login={is_mobile ? false : is_login}
                    />
                  </div>
                )}
              </div>
            </div>

            {this.props.empty && null}
          </div>
        </nav>
      </Div>
    );
  }
}

export default withTranslation()(Header);
