import { gql } from '@apollo/client';
import { BaseTranslationFragment } from '../../fragments';

export const GET_TRANSLATION_BY_TRANSLATION_KEY = gql`
  query GetTranslationsByTranslationKey($translationKey: String!) {
    getTranslationsByTranslationKey(translationKey: $translationKey) {
      ...BaseTranslation
    }
  }
  ${BaseTranslationFragment}
`;

export const GET_TRANSLATION = gql`
  query GetTranslation($getTranslationId: ID!) {
    getTranslation(id: $getTranslationId) {
      ...BaseTranslation
    }
  }
  ${BaseTranslationFragment}
`;

export const GET_ALLTRANSLATIONS = gql`
  query GetAllTranslations($pageSize: Int, $page: Int) {
    getAllTranslations(pageSize: $pageSize, page: $page) {
      translations {
        id
        de
        en
        es
        fr
        it
        jp
        fallback
        translationKey
      }
      totalTranslations
    }
  }
`;

export const GET_TRANSLATIONS_BY_LANGUAGE = gql`
  query GetTranslationsBylanguage(
    $languageCodes: [String!]
    $de: Boolean!
    $en: Boolean!
    $es: Boolean!
    $fr: Boolean!
    $it: Boolean!
    $jp: Boolean!
  ) {
    getTranslationsByLanguages(languageCodes: $languageCodes) {
      id
      de @include(if: $de)
      en @include(if: $en)
      es @include(if: $es)
      fr @include(if: $fr)
      it @include(if: $it)
      jp @include(if: $jp)
      translationKey
    }
  }
`;
