import { fetchTranslations } from '../utils';

/**
 * @description overrides and implements required backend read event handler for i18next
 */
export class NivodaBackendI18NAPI {
  async read(language, namespace, callback) {
    callback(null, (await fetchTranslations()).value[language][namespace]);
  }
}

NivodaBackendI18NAPI.type = 'backend';
NivodaBackendI18NAPI.async = true;
