import { AdminGraphqlQuery } from '../../../graphql-admin';
import { EnquiryDiamondFragment } from '../../fragments';
import { gql } from '@apollo/client';

const EnquiryDataFragment = gql`
  fragment EnquiryDataFragment on Enquiry {
    id
    requested_info
    internal_status
    assigned_to {
      id
      firstName
      lastName
      profile_image
    }
    cancelled_reason {
      id
      reason
    }
    admin_comment
    offer {
      id
      fbn_details {
        id
        OrderItem {
          id
          FbnType
        }
        inventory_type
      }
      Product {
        ... on Diamond {
          ...EnquiryDiamond
        }
      }
      ProductType
    }
    requester {
      id
      firstName
      lastName
      company {
        id
        name
      }
    }
    createdAt
  }
`;
// GetUnansweredEnquiries
export const GET_UNANSWERED_ENQUIRIES = gql`
  query getAdminUnansweredEnquiries {
    unanswered_queries {
      ...EnquiryDataFragment
    }
  }
  ${EnquiryDataFragment}
  ${EnquiryDiamondFragment}
`;
export const GET_ANSWERED_ENQUIRIES = gql`
  query getAdminAnsweredEnquiries {
    answered_queries {
      ...EnquiryDataFragment
    }
  }
  ${EnquiryDataFragment}
  ${EnquiryDiamondFragment}
`;

export let SearchEnquiries = AdminGraphqlQuery({
  query: gql`
    query searchEnquiries($cert_number: String!) {
      search_enquiries(cert_number: $cert_number) {
        total_count
        items {
          ...EnquiryDataFragment
        }
      }
    }
    ${EnquiryDataFragment}
    ${EnquiryDiamondFragment}
  `,
});
