//#region  Custom select section
import Select, { components, createFilter } from 'react-select-latest';
import CreatableSelect from 'react-select-latest/creatable';
import AsyncSelect from 'react-select-latest/async';
import { Icon } from 'fabric/resources/icon';
import { theme } from 'fabric/theme';
import styled from 'styled-components';
import { Button } from '../Button';

// export const CreatableSelectDropown = ({ options }) => (
//   <CreatableSelect isClearable options={options} />
// );
export const CustomSelectWrapper = styled.div`
  .label_style {
    ${theme.fonts.medium}
    span {
      color: red;
    }
  }
`;
export const CustomMenuWithAddNewButton = ({ children, ...props }) => {
  const {
    addNewButtonProps = {
      label: '',
      buttonConfig: {},
      btnLeftIconProps: {},
      btnRightIconProps: {},
    },
  } = props.selectProps;
  return (
    <components.MenuList {...props}>
      <Button {...addNewButtonProps.buttonConfig}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Icon {...addNewButtonProps.btnLeftIconProps} />
          {addNewButtonProps.label}
        </div>
        <Icon {...addNewButtonProps.btnRightIconProps} />
      </Button>
      {children}
    </components.MenuList>
  );
};

export const CustomOptions = ({ children, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return <components.Option {...newProps}>{children}</components.Option>;
};

const DropdownIndicator = ({ selectProps, ...props }) => {
  const { menuIsOpen, rightIconProps = {} } = selectProps;
  const { style = {} } = rightIconProps;

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        data-automation-id="arrow-icon"
        name="dropdown-arrow"
        color="#27272A"
        size={18}
        {...rightIconProps}
        style={{
          ...style,
          maxHeight: 200,
          rotate: `${
            rightIconProps?.enableRotate && menuIsOpen ? '180deg' : '0deg'
          }`,
        }}
      />
    </components.DropdownIndicator>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const { leftIconProps = {}, data_automation_id } = props.selectProps;
  const { iconProps = {} } = leftIconProps;
  return (
    <components.ValueContainer {...props}>
      <div
        data-automation-id={data_automation_id}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {Object.keys(leftIconProps).length !== 0 && <Icon {...iconProps} />}
        {children}
      </div>
    </components.ValueContainer>
  );
};

const IndicatorSeparator = ({ children, ...props }) => {
  const { isIndicator } = props.selectProps;
  return (
    <>
      {isIndicator ? (
        <components.IndicatorSeparator {...props}>
          {children}
        </components.IndicatorSeparator>
      ) : null}
    </>
  );
};

export const CustomReactSelect = (props) => {
  const {
    label,
    rightIconProps = {},
    leftIconProps = {},
    isAsync = false,
    isRequried = false,
    styles = {},
    labelStyles = {},
    components,
    isIndicator = false,
    wrapperClass,
    wrapperStyles,
    data_automation_id,
    ...defaultProps
  } = props;

  const defaultStyles = {
    control: (base) => ({
      ...base,
      marginTop: '5px !important',
      border: '1px solid #d4d4d8 !important',
      borderRadius: '5px !important',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
      color: '#27272A',
      minHeight: '30px',
      // This line disable the blue border
      boxShadow: 'none !important',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
    clearIndicator: (base) => ({ ...base, cursor: 'pointer' }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected
        ? 'rgb(221,226,238) !important'
        : state.isFocused
        ? 'none'
        : state.isDisabled && '#fafafa',
      color: state.isSelected
        ? '#27272A'
        : state.isDisabled
        ? '#ADADAD'
        : '#27272A',
      '&:hover': {
        background: 'rgba(244, 244, 245, 1) !important',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flex: '1 1 0% !important',
    }),
    placeholder: (base) => ({
      ...base,
      top: '50%',
      position: 'absolute',
      transform: 'translateY(-50%)',
      gridArea: 'none',
    }),
    ...styles,
  };

  return (
    <CustomSelectWrapper
      className={wrapperClass || 'custom_react_select_wrapper'}
      style={wrapperStyles}
    >
      {label && (
        <span className="label_style" style={labelStyles}>
          {label}
          {isRequried && <span>*</span>}
        </span>
      )}
      {!isAsync ? (
        <Select
          styles={defaultStyles}
          rightIconProps={rightIconProps}
          leftIconProps={leftIconProps}
          isIndicator={isIndicator}
          data_automation_id={data_automation_id}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            DropdownIndicator: DropdownIndicator,
            IndicatorSeparator: IndicatorSeparator,
            ValueContainer: ValueContainer,
            ...components,
          }}
          {...defaultProps}
        />
      ) : (
        <AsyncSelect
          styles={defaultStyles}
          rightIconProps={rightIconProps}
          data_automation_id={data_automation_id}
          leftIconProps={leftIconProps}
          isIndicator={isIndicator}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            DropdownIndicator: DropdownIndicator,
            IndicatorSeparator: IndicatorSeparator,
            ValueContainer: ValueContainer,
            ...components,
          }}
          {...defaultProps}
        />
      )}
    </CustomSelectWrapper>
  );
};

export const CreatableSelectDropown = (props) => {
  const {
    label,
    rightIconProps = {},
    leftIconProps = {},
    isRequried = false,
    styles = {},
    labelStyles = {},
    components,
    isIndicator = false,
    wrapperClass,
    wrapperStyles,
    ...defaultProps
  } = props;

  const defaultStyles = {
    control: (base) => ({
      ...base,
      marginTop: '5px !important',
      border: '1px solid #d4d4d8 !important',
      borderRadius: '5px !important',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
      color: '#27272A',
      minHeight: '30px',
      // This line disable the blue border
      boxShadow: 'none !important',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
    clearIndicator: (base) => ({ ...base, cursor: 'pointer' }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected
        ? 'rgb(221,226,238) !important'
        : state.isFocused
        ? 'none'
        : state.isDisabled && '#fafafa',
      color: state.isSelected
        ? '#27272A'
        : state.isDisabled
        ? '#ADADAD'
        : '#27272A',
      '&:hover': {
        background: 'rgba(244, 244, 245, 1) !important',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flex: '1 1 0% !important',
    }),
    placeholder: (base) => ({
      ...base,
      top: '50%',
      position: 'absolute',
      transform: 'translateY(-50%)',
      gridArea: 'none',
    }),
    ...styles,
  };

  return (
    <CustomSelectWrapper
      className={wrapperClass || 'custom_react_select_wrapper'}
      style={wrapperStyles}
    >
      {label && (
        <span className="label_style" style={labelStyles}>
          {label}
          {isRequried && <span>*</span>}
        </span>
      )}

      <CreatableSelect
        styles={defaultStyles}
        rightIconProps={rightIconProps}
        leftIconProps={leftIconProps}
        isIndicator={isIndicator}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: IndicatorSeparator,
          ValueContainer: ValueContainer,
          ...components,
        }}
        {...defaultProps}
      />
    </CustomSelectWrapper>
  );
};

//#endregion
