import { gql } from '@apollo/client';

export const CUSTOMER_CREATE_MEMO_RETURN = gql`
  mutation customerCreateMemoReturn(
    $order_item_ids: [ID]
    $pickup_date: String!
    $return_reason_id: ID
    $return_method: MemoReturnMethod!
  ) {
    customer_create_memo_return(
      order_item_ids: $order_item_ids
      pickup_date: $pickup_date
      return_reason_id: $return_reason_id
      return_method: $return_method
    ) {
      id
      status
      ReturnShipment {
        id
        label
        package_slip
      }
    }
  }
`;

export const CUSTOMER_GENERATE_PACKAGE_SLIP = gql`
  mutation customerGeneratePackageSlip($shipment_id: ID!) {
    customer_generate_package_slip(shipment_id: $shipment_id) {
      package_slip
    }
  }
`;

export const CUSTOMER_CREATE_SHIPMENT_LABEL = gql`
  mutation customerCreateShipmentLabel($shipment_id: ID!) {
    customer_create_shipment_label(shipment_id: $shipment_id) {
      label
    }
  }
`;

export const RESEND_MEMO_RETURN_NOTIFICATION = gql`
  mutation resendMemoReturnNotification($shipment_id: ID!) {
    resend_memo_return_notification(shipment_id: $shipment_id) {
      message
      sent
    }
  }
`;

export const CUSTOMER_SCHEDULE_RETURN_PICKUP = gql`
  mutation customerScheduleReturnPickup(
    $shipment_id: ID!
    $pickup_date: String
  ) {
    customer_schedule_return_pickup(
      shipment_id: $shipment_id
      pickup_date: $pickup_date
    ) {
      pickupDate
      startTime
      endTime
    }
  }
`;

export const CUSTOMER_CANCEL_MEMO_REQUEST = gql`
  mutation customerCancelMemoRequest($memo_ids: [ID]!) {
    cancel_memo_requests(memo_ids: $memo_ids) {
      id
      status
    }
  }
`;

export const CUSTOMER_MARK_AS_SOLD_MEMO_REQUEST = gql`
  mutation customerMarkAsSoldMemoRequest($memo_ids: [ID]!) {
    customer_convert_memo(memo_ids: $memo_ids) {
      id
      status
    }
  }
`;
