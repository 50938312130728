import React, { useCallback } from 'react';
import { Button as B, Tooltip } from '@nivoda/ui';
import { Icon } from '../../resources/icon';
import theme from '../../theme';

export { ButtonGroup } from './ButtonGroup';

export function Button(props) {
  const {
    className,
    danger = false,
    success = false,
    btnConfig = {},
    iconConfig = {},
    onClick,
    disabled,
    leftDir = false,
    subtle = false,
    light = false,
    style = {},
    ...restProps
  } = props;

  const lightStyles = !light
    ? { style }
    : {
        style: {
          background: danger
            ? theme.palette.subtleRed
            : success
            ? theme.palette.subtleGreen
            : theme.palette.blue05,
          color: danger
            ? theme.palette.red6
            : success
            ? theme.palette.green6
            : theme.palette.themePrimary,
          ...style,
        },
        noShadow: true,
      };
  const subtleStyles = !subtle
    ? { style: light ? lightStyles.style : style }
    : {
        style,
        customPadding: '0',
        is_default: true,
        ghost: true,
        noShadow: true,
      };

  const renderIcon = useCallback(() => {
    const { tooltip, ...rest } = iconConfig;
    const jsx = <Icon {...rest} />;

    return tooltip ? (
      <Tooltip
        placement={tooltip.placement || 'bottom'}
        offset={tooltip.offset || -6}
        radius={tooltip.radius || 3}
        content={
          <div className="tooltip__content">{tooltip.content || '-'}</div>
        }
      >
        {jsx}
      </Tooltip>
    ) : (
      jsx
    );
  }, [iconConfig]);

  return (
    <B
      className={className}
      customPadding={`${theme.spacing.s2} ${theme.spacing.s3}`}
      disabled={disabled}
      {...btnConfig}
      {...lightStyles}
      {...subtleStyles}
      onClick={
        typeof btnConfig.onClick === 'function' ? btnConfig.onClick : onClick
      }
      {...restProps}
    >
      {leftDir && props.children} {iconConfig?.name && renderIcon()}{' '}
      {!leftDir && props.children}
    </B>
  );
}
