import { config } from 'fabric/config';
import { appEnv } from 'utils';
import { create } from 'zustand';
import { ZendeskAPI } from './helper';

export const useZendeskChatWidgetStore = create((set, get) => ({
  openZendesk: false,
  setZendesk: (value) => {
    set({ openZendesk: value });
    if (appEnv.isProd || config.integration.intercomSettings.enableOnDevENV) {
      ZendeskAPI('messenger', 'show');
      ZendeskAPI('messenger', value ? 'open' : 'close');
    }
  },
}));
