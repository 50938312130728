import { gql } from '@apollo/client';
import { HoldFragment } from './HoldsFragment';
import { CertificateInfoFragment } from './CertificateFragment';

export const EnquiryFragment = gql`
  fragment EnquiryFragment on Enquiry {
    id
    answered
    supplier {
      id
      name
      company_image
      rating
      locations {
        id
        name
      }
    }
    offer {
      id
      price_per_carat
      price
      product_price
      product_discount
      original_offer {
        id
      }
      ProductType
      Product {
        ... on Diamond {
          id
          brown
          green
          milky
          eyeClean
          availability
          origin_country
          other
          certificate {
            id
          }
          delivery_time {
            max_business_days
            min_business_days
          }
          has_bgmec_enquiry
          has_media_enquiry
        }
        ... on Melee {
          id
        }
      }
      hold {
        ...HoldItemFragment
      }
    }
    __typename
  }
  ${HoldFragment}
`;

export const EnquiryDiamondFragment = gql`
  fragment EnquiryDiamond on Diamond {
    id
    supplierStockId
    diamondSupplierStockId
    location {
      id
      country
    }
    supplier {
      id
      name
      country
      website
      company_settings {
        CompanyId
        website_order_placement
        communicates_through_email
        communication_email
        supplier_based_comment
      }
    }
    brown
    green
    blue
    gray
    milky
    eyeClean
    availability
    OrderItemId
    offer {
      id
    }
    certificate {
      ...CertificateInfo
    }
  }
  ${CertificateInfoFragment}
`;
