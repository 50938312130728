export const downloadTypes = {
  COPY_IMAGE: 'COPY_IMAGE',
  COPY_VIDEO: 'COPY_VIDEO',
  DOWNLOAD_IMAGE: 'DOWNLOAD_IMAGE',
  DOWNLOAD_VIDEO: 'DOWNLOAD_VIDEO',
};

export const shareTypes = {
  WHATSAPP: 'WHATSAPP',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};

export const infoTypes = {
  COPY_INFO: 'COPY_INFO',
};
