import theme from '../../theme';
import React from 'react';
import { bool, node } from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'fabric/resources/icon';
import classNames from 'classnames';

/**
 * Small loading spinner
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const LoadingIcon = (props) => (
  <Span className={classNames(props.className, 'action__confirm--label')}>
    <Icon
      name={props.iconName ? props.iconName : 'loader'}
      size={props.iconSize ? props.iconSize : 20}
      color={
        props.error
          ? theme.semanticColors.error
          : props.color || theme.palette.white
      }
      style={props.style}
    />
    {props.loadingText || ''}
  </Span>
);
LoadingIcon.propTypes = {
  error: bool,
  // can be string or jsx
  loadingText: node,
};

const Span = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
  svg {
    margin-top: -3px;
  }
`;
