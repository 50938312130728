import React from 'react';

import * as BasicGraphql from '../graphql-common';

let SupplierGraphql = React.createContext(null);

export let SupplierGraphqlProvider = ({ client, children }) => {
  return <SupplierGraphql.Provider value={client} children={children} />;
};

export let SupplierGraphqlQuery = (options) => {
  let BasicQuery = BasicGraphql.GraphqlFactory(options);
  let sub_component = ({ options, ...props }) => (
    <SupplierGraphql.Consumer>
      {(client) => <BasicQuery {...props} options={{ ...options, client }} />}
    </SupplierGraphql.Consumer>
  );

  sub_component.query = BasicQuery.query;
  return sub_component;
};

export let SupplierGraphqlMutation = (options) => {
  let BasicMutation = BasicGraphql.GraphqlMutation(options);

  let sub_component = ({ options, ...props }) => (
    <SupplierGraphql.Consumer>
      {(client) => (
        <BasicMutation {...props} options={{ ...options, client }} />
      )}
    </SupplierGraphql.Consumer>
  );

  return sub_component;
};
