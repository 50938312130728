import { gql } from '@apollo/client';

export const NoteFragment = gql`
  fragment AdminNote on Note {
    id
    createdAt
    updatedAt
    status
    data
    user {
      id
      firstName
      lastName
      profile_image
      role
    }
  }
`;
