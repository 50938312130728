import {
  APPLY_ON_OPTIONS,
  CURRENCY,
  CARAT_DIFFERENCE,
  PRICE_DIFFERENCE,
} from './constants';
import { flatten, toPairs } from 'lodash';

export const markup_preferance_mapper = (type) => {
  return APPLY_ON_OPTIONS.find((ele) => ele.value === type);
};

export const markup_currency_mapper = (type) => {
  return CURRENCY.find((ele) => ele.value === type);
};
export let check_range_gaps = (rows, is_carat) => {
  let DIFF = is_carat ? CARAT_DIFFERENCE : PRICE_DIFFERENCE;
  return rows.some((row, index) => {
    return index > 0
      ? +(+row.from_amount - +rows[index - 1].to_amount)?.toFixed(2) > DIFF
      : false;
  });
};
export const check_all_fields_have_value = (arr = []) => {
  let paired_result = flatten(
    arr.map(({ percent, from_amount, to_amount, ...rest }) =>
      flatten(toPairs({ percent, from_amount, to_amount })).filter(
        (result, id) => id % 2 === 1
      )
    )
  );

  return arr.length * 3 === paired_result.filter((r) => r !== '').length;
};
export const generate_Id = (
  index,
  { ProductSubtype, ProductType, markup_type, extra_text }
) => {
  return extra_text
    ? `${ProductSubtype}-${ProductType}-${markup_type}-${index}-${extra_text}`
    : `${ProductSubtype}-${ProductType}-${markup_type}-${index}`;
};
export let Sanitize_markup_data = (id, rows, is_carat) => {
  let DIFF = is_carat ? CARAT_DIFFERENCE : PRICE_DIFFERENCE;
  let all_rows = [...rows].sort((a, b) => a.from_amount - b.to_amount);

  if (!id) {
    let markup_gap_is_invalid = check_range_gaps(all_rows, is_carat);
    return markup_gap_is_invalid
      ? {
          status: 'error',
          error: 'invalid_markup_gaps',
          error_type: 'ALL_ERROR',
        }
      : { status: 'success' };
  }
  let newRow = rows.find((r) => r.id === id);

  let percent_is_invalid =
    newRow.percent === '' ||
    (newRow.percent?.length === 0 &&
      !(Number(newRow.percent) >= 0 && Number(newRow.percent) <= 10000));
  let from_amount_is_invalid = Number(newRow.from_amount) < 0;
  let to_amount_is_invalid = Number(newRow.to_amount) < DIFF;
  let from_is_greater = Number(newRow.to_amount) <= Number(newRow.from_amount);

  let row_is_invalid =
    percent_is_invalid ||
    from_amount_is_invalid ||
    to_amount_is_invalid ||
    from_is_greater;

  let message = percent_is_invalid
    ? { error: 'percent_is_invalid', error_type: 'MARKUP_PERCENT_INVALID' }
    : to_amount_is_invalid
    ? { error: 'to_amount_is_invalid', error_type: 'TO_INVALID' }
    : from_is_greater
    ? {
        error: 'from_amount_is_greater_than_to_amount',
        error_type: 'FROM_GREATER_THEN_TO',
      }
    : { error: 'from_amount_is_invalid', error_type: 'FROM_INVALID' };

  let is_overlapping = (newRow, row) => {
    let fr =
      Number(newRow.from_amount) >= row.from_amount &&
      Number(newRow.from_amount) < row.to_amount;
    let tr =
      Number(newRow.from_amount) <= row.from_amount &&
      Number(newRow.to_amount) >= row.to_amount;
    let fth =
      Number(newRow.from_amount) >= row.from_amount &&
      Number(newRow.to_amount) <= row.to_amount;
    return fr || tr || fth;
  };

  if (row_is_invalid) {
    return {
      status: 'error',
      id: newRow.id,
      ...message,
    };
  }
  const otherRows = rows.filter((r) => r.id !== id);

  if (newRow.is_new) {
    let is_overlap = otherRows
      .filter((f) => !f.id !== newRow.id)
      .some((r) => is_overlapping(newRow, r));
    if (is_overlap) {
      return {
        status: 'error',
        id: is_overlap.id,
        error: 'overlapping_markup',
        error_type: 'OVER_LAP',
      };
    }
  } else {
    let is_overlap = otherRows
      .filter((f) => !f.id !== newRow.id)
      .some((r) => is_overlapping(newRow, r));
    if (is_overlap)
      return {
        status: 'error',
        id: is_overlap.id,
        error: 'overlapping_with_other_markups',
        error_type: 'OVER_LAP',
      };
  }

  return {
    status: 'success',
    id: newRow.id,
  };
};
