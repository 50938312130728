import React from 'react';
import cx from 'classnames';
import theme from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';
import styled from 'styled-components';

export const ToggleButton = ({
  className,
  items = [],
  mode = 'primary',
  selectedMode,
}) => {
  return (
    <ToggleButtonWrp className={cx('toggle-button', className)} mode={mode}>
      {items.map((item) => {
        return (
          <div
            key={item.id}
            className={cx(
              'toggle-btn',
              selectedMode === item.id
                ? 'toggle-btn-active'
                : 'toggle-btn-inactive'
            )}
            onClick={item.onClick}
          >
            <Icon
              name={item.iconConfig.name}
              size={item.iconConfig.size || 20}
              color={
                selectedMode === item.id
                  ? theme.palette.white
                  : theme.palette.Neutrals700
              }
            />
            <span className="toggle-btn-label">{item.label}</span>
          </div>
        );
      })}
    </ToggleButtonWrp>
  );
};

const ToggleButtonWrp = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: ${theme.spacing.s1};
  border-radius: ${theme.spacing.s2};
  background: ${theme.palette.offWhite4};

  .toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    padding: ${theme.spacing.s2} ${theme.spacing.s4};
    gap: ${theme.spacing.s1};
    border-radius: ${theme.spacing.s1};
    cursor: pointer;
  }

  .toggle-btn-label {
    ${theme.fonts.mediumPlus};
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 165px;
  }

  .toggle-btn-inactive {
    background: transparent;
    span {
      color: ${theme.palette.Neutrals700};
      opacity: 0.8;
    }
    svg {
      opacity: 0.8;
    }

    :hover {
      span,
      svg {
        opacity: 1;
      }
    }
  }

  .toggle-btn-active {
    background: ${({ mode }) =>
      mode === 'default'
        ? theme.semanticColors.regularPrimary400
        : theme.semanticColors.memoPrimary400};
    span {
      color: ${theme.palette.white};
    }

    :hover {
      background: ${({ mode }) =>
        mode === 'default'
          ? theme.semanticColors.regularPrimary500
          : theme.semanticColors.memoPrimary500};
    }
  }
`;
