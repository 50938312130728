import { getUserReviewStatus } from 'utils/user';
import { compareDateFunction, compareFunction, isMobile } from 'utils';
import {
  Diamond,
  formatDate,
  Certificate,
  livePath,
  CustomTrans,
  HideOnReview,
} from 'helpers';
import { ExchangeRate } from 'graphql/queries/common';
import { Price, PriceExchanged } from 'Elements';
import { Link } from '@reach/router';
import { theme } from 'fabric/theme';
import { CertWithIconV2 } from 'Elements';
import { Button } from 'fabric/components';
import { StatusPill } from 'components/ui/StatusPill';
import { getPaymentTitleOnly } from 'App/Checkout/helper';

export const diamond_requests_table_headers = (t, is_aip) => {
  let cols = [
    {
      name: t('certificate_number'),
      sortable: false,
      selector: 'certNumber',
      id: 'certNumber',
    },
    // {
    //   name: t('stock_id'),
    //   sortable: false,
    //   selector: 'stock_id',
    //   id: 'stock_id',
    // },
    {
      name: t('stone_info'),
      sortable: false,
      selector: 'description',
      id: 'description',
    },
    {
      name: t('date_requested'),
      sortable: true,
      selector: 'requested_date',
      id: 'requested_date',
      sortFunction: (a, b) => {
        const requestDateA = a?.request?.created_at;
        const requestDateB = b?.request?.created_at;

        return compareDateFunction(requestDateA, requestDateB, true);
      },
    },
    {
      name: t('user'),
      sortable: true,
      selector: 'requester',
      id: 'requester',
      sortFunction: (a, b) => {
        const requesterA =
          `${a?.request?.requester?.firstName} ${a?.request?.requester?.lastName}` ||
          '';
        const requesterB =
          `${b?.request?.requester?.firstName} ${b?.request?.requester?.lastName}` ||
          '';
        return compareFunction(requesterA, requesterB);
      },
    },
    {
      name: t('internal_no'),
      sortable: true,
      selector: 'customer_order_number',
      id: 'customer_order_number',
    },
    {
      name: t('note'),
      sortable: false,
      selector: 'comment',
      id: 'comment',
    },
    {
      name: t('address'),
      sortable: false,
      selector: 'delivery_address',
      id: 'address',
    },
    {
      name: t('payment_method'),
      sortable: false,
      selector: 'payment_method',
      id: 'payment_method',
    },
    {
      name: `${t('diamond')} $`,
      sortable: true,
      selector: 'diamond_price',
      id: 'diamond_price',
      sortFunction: (a, b) => {
        const diamond_priceA = a.diamond ? a.diamond.offer.product_price : '';
        const diamond_priceB = b.diamond ? b.diamond.offer.product_price : '';
        return compareFunction(diamond_priceA, diamond_priceB);
      },
    },
    {
      name: is_aip ? t('total_price') : `${t('delivered')} $`,
      sortable: true,
      selector: 'price',
      id: 'price',
      sortFunction: (a, b) => {
        const diamond_priceA = a.diamond ? a.diamond.offer.price : '';
        const diamond_priceB = b.diamond ? b.diamond.offer.price : '';
        return compareFunction(diamond_priceA, diamond_priceB);
      },
    },
    {
      name: t('status'),
      sortable: false,
      selector: 'status',
      id: 'status',
    },
    {
      name: '',
      sortable: false,
      selector: 'action',
      id: 'action',
    },
  ];
  return is_aip ? cols.filter((r) => r.id !== 'diamond_price') : cols;
};

export const status_options = [
  { value: 'PENDING', display: 'Pending' },
  { value: 'ADDED', display: 'Available' },
  { value: 'UNAVAILABLE', display: 'Unavailable' },
  { value: 'SOLD_OUT', display: 'Sold Out' },
  { value: 'PURCHASED', display: 'Purchased' },
  // { value: 'NOT_AVAILABLE', display: 'Not Available' },
  // { value: 'LOST', display: 'Lost' },
];

export const mapRequestsData = (data, t, handleCancel) => {
  let mini_user = {
    steps_required: data?.me?.steps_required,
  };
  const { is_user_in_review } = getUserReviewStatus(mini_user);
  let all_uncancelled_requests =
    data?.me?.company?.diamond_requests?.filter(
      (x) => x.status !== 'CANCELLED'
    ) || [];
  const pending_request = all_uncancelled_requests?.filter(
    (x) => x.diamond === null
  );
  const available_in_system = all_uncancelled_requests.filter(
    (x) => x.diamond !== null
  );
  let month_format = 'MM';

  let pending_table_data = pending_request.map((request) => {
    return {
      id: request.id,
      request,
      gia_number: request.certNumber,
      seller: `-`,
      stock_id: request.supplier_stock_id,
      certNumber: (
        <CertWithIconV2
          certificate={{ lab: request.lab, certNumber: request.certNumber }}
          truncate_width={'90px'}
        />
      ),
      delivery_address: (
        <div style={{ ...theme.fonts.medium }}>
          <strong style={{ color: theme.palette.Neutrals700 }}>
            {request?.delivery_location?.name}
          </strong>{' '}
          {request?.delivery_location?.address1}{' '}
          {request?.delivery_location?.city}{' '}
          {request?.delivery_location?.postalCode}{' '}
        </div>
      ),
      payment_method: getPaymentTitleOnly(request?.payment_term_selected),
      requester: (
        <div style={{ textTransform: 'capitalize' }}>
          {' '}
          {request.requester.firstName} {request.requester.lastName}
          {(isMobile() && (
            <span className="card__content--created-at">
              {'  '}
              {formatDate(request.created_at, null, month_format)}
            </span>
          )) ||
            ''}
        </div>
      ),
      customer_order_number: request.customer_order_number || '',
      comment: request.comment || '',
      created_at: request.created_at,
      requested_date: formatDate(request.created_at, null, month_format),
      description: '-',
      diamond: request.diamond,
      diamond_price: request.diamond ? (
        request.diamond.offer.product_price
      ) : (
        <div className="card__content--diamond">-</div>
      ),
      diamond_discount: request.diamond ? (
        request.diamond.offer.product_discount
      ) : (
        <div className="card__content--diamond">-</div>
      ),
      diamond_amount:
        request.diamond && request.diamond.offer.product_price / 100,
      price: <div className="card__content--delivered">-</div>,
      diamond_size: request.diamond ? request.diamond.certificate.carats : 0,
      status: (
        <StatusPill
          name={request?.request_type === 'MEMO' ? request?.status : 'PENDING'}
          large
        />
      ),
      status_text: 'pending',
      action: (
        <Button
          is_default
          customPadding={isMobile() ? '6px 12px' : '6px'}
          className="diamond_request--cancel-request"
          onClick={() => {
            handleCancel(request.certNumber, request.id);
          }}
        >
          {/* <Icon
            color={'#1b237e'}
            name="delete"
            title={t('cancel_diamond_request')}
            desc={t('cancel_diamond_request')}
          /> */}
          {t('remove')}
        </Button>
      ),
      filterOptions: {
        status: 'pending',
        supplier: null,
        gia_number: request.certNumber,
        users: request.requester.id,
      },
    };
  });

  let in_house_table_data = available_in_system.map((request) => {
    const {
      diamond,
      diamond: { certificate },
    } = request;

    return {
      id: request.id,
      request,
      gia_number: request.certNumber,
      stock_id: request.supplier_stock_id,
      certNumber: `${certificate.lab != null ? certificate.lab : ''} ${
        request.certNumber
      }`,
      delivery_address: (
        <span>
          <strong style={{ color: theme.palette.Neutrals700 }}>
            {request?.delivery_location?.name}
          </strong>{' '}
          {request?.delivery_location?.address1}{' '}
          {request?.delivery_location?.city}{' '}
          {request?.delivery_location?.postalCode}
        </span>
      ),
      payment_method: getPaymentTitleOnly(request?.payment_term_selected),
      requester: (
        <div style={{ textTransform: 'capitalize' }}>
          {' '}
          {request.requester.firstName} {request.requester.lastName}
        </div>
      ),
      diamond: request.diamond,
      created_at: request.created_at,
      customer_order_number: request.customer_order_number || '',
      comment: request.comment || '',
      requested_date: formatDate(request.created_at, null, month_format),
      discount: Diamond.get_discount({ diamond }),
      diamond_discount: Diamond.get_diamond_discount({ diamond }),
      diamond_amount: diamond.offer.product_price / 100,
      delivered_amount: diamond.price,
      diamond_size: request.diamond ? request.diamond.certificate.carats : 0,
      price: (
        <ExchangeRate>
          {({ exchange_rate }) => {
            return (
              <div>
                {is_user_in_review ? (
                  <CustomTrans text="hidden" />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                    }}
                  >
                    <div
                      style={{
                        color: '#35823C',
                        fontWeight: 500,
                        fontSize: 12,
                      }}
                    >
                      {Diamond.get_discount({ diamond })}
                    </div>

                    <span
                      style={{
                        color: '#2C2C2C',
                        fontWeight: 500,
                      }}
                    >
                      <Price
                        price={{
                          currency: 'USD',
                          amount: diamond.price,
                        }}
                      />
                    </span>
                    {exchange_rate.id !== 'USD' && (
                      <span
                        style={{
                          color: '#353434',
                          fontSize: '12px',
                        }}
                      >
                        <PriceExchanged
                          price={{ currency: 'USD', amount: diamond.price }}
                        />
                      </span>
                    )}
                  </div>
                )}
              </div>
            );
          }}
        </ExchangeRate>
      ),
      diamond_price: (
        <ExchangeRate>
          {({ exchange_rate }) => (
            <div>
              {is_user_in_review ? (
                <CustomTrans text="hidden" />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                >
                  <div
                    style={{
                      color: '#35823C',
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {Diamond.get_diamond_discount({ diamond })}
                  </div>
                  <span
                    style={{
                      color: '#2C2C2C',
                      fontWeight: 500,
                    }}
                  >
                    <Price
                      price={{
                        currency: 'USD',
                        amount: diamond.offer.product_price / 100,
                      }}
                    />
                  </span>
                  {exchange_rate.id !== 'USD' && (
                    <span
                      style={{
                        color: '#353434',
                        fontSize: '12px',
                      }}
                    >
                      <PriceExchanged
                        price={{
                          currency: 'USD',
                          amount: diamond.offer.product_price / 100,
                        }}
                      />
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </ExchangeRate>
      ),
      seller: (
        <div className="inline__seller">
          {!is_user_in_review && diamond.supplier.preferred ? (
            <img src={`/img/preferred_badge.svg`} alt="badge" />
          ) : (
            ''
          )}
          <HideOnReview>
            <span className="inline__seller--name">
              {diamond.supplier.name}
            </span>
          </HideOnReview>
        </div>
      ),
      // status: (
      //   <div
      //     className={`customer_order_status_label ${'Added'.replace(
      //       / /g,
      //       '-'
      //     )}`}
      //   >
      //     {/* {item.item_status.name} */}
      //     {'Added'}
      //   </div>
      // ),
      status: (
        <StatusPill
          name={
            request?.request_type === 'MEMO' ? request?.status : 'AVAILABLE'
          }
          large
        />
      ),
      filterOptions: {
        status: 'added',
        suppliers: diamond.supplier.id,
        gia_number: request.certNumber,
        users: request.requester.id,
      },
    };
  });
  let tableData = { pending_table_data, in_house_table_data };

  return {
    ...data,
    tableData,
    total_count: pending_table_data.length + in_house_table_data.length,
  };
};

export const mapExtendedInHouseData = (data, exchange_rate) => {
  return data.map((in_house_default) => {
    let {
      diamond,
      diamond: { certificate },
    } = in_house_default;
    const product = 'diamond';
    // will need to handle if type is melee
    const is_labgrown = diamond.certificate.labgrown;
    const product_type = is_labgrown ? 'labgrown' : 'natural';

    return {
      ...in_house_default,
      description: (
        <div
          style={{
            textAlign: 'left',
            fontWeight: 600,
            fontSize: isMobile() ? '14px' : '12px',
          }}
        >
          <div>
            <Link
              style={{ color: theme.palette.themePrimary }}
              to={`${livePath}/search/${product_type}/${product}/${diamond.id}`}
            >
              {Certificate.get_shape(certificate)}{' '}
              {Certificate.get_size(certificate)}ct{' '}
              {Certificate.get_color(certificate)}{' '}
              {Certificate.get_clarity(certificate)}{' '}
              {Certificate.get_cut(certificate)}{' '}
              {Certificate.get_polish(certificate)}{' '}
              {Certificate.get_symmetry(certificate)}{' '}
              {Certificate.get_fluorescence(certificate)}
              {(!isMobile() && in_house_default.seller) || ''}
            </Link>
          </div>
        </div>
      ),
      certNumber: (
        <div>
          <CertWithIconV2
            certificate={certificate}
            truncate_width="120px"
            cert_wrapper={{
              fontWeight: 600,
              fontSize: '12px',
              color: theme.palette.gray800,
            }}
          />
          {Diamond.get_stock_id(diamond, {
            returnPlainText: false,
            stockWithIconProps: {
              label: 'stock_id',
              stock_label: {
                fontSize: '12px',
                fontWeight: '400',
                color: '#616161',
              },
              stock_value: {
                fontSize: '12px',
                fontWeight: '400',
                color: '#424243',
              },
              truncate_width: '80px',
            },
          })}
        </div>
      ),

      price: isMobile() ? (
        <div className="card__content--delivered">
          <div className="card__content--delivered-discount">
            {in_house_default.discount}
          </div>
          <div className="card__content--delivered-price">
            <span className="card__content--delivered-value">
              <Price
                price={{
                  currency: 'USD',
                  amount: in_house_default.delivered_amount,
                }}
              />
            </span>
            {exchange_rate.name !== 'USD' && (
              <span className="card__content--delivered-exchange">
                <PriceExchanged
                  price={{
                    currency: 'USD',
                    amount: in_house_default.delivered_amount,
                  }}
                />
              </span>
            )}
          </div>
        </div>
      ) : (
        in_house_default.price
      ),

      diamond_price: isMobile() ? (
        <div className="card__content--diamond">
          <div className="card__content--delivered-discount">
            {in_house_default.diamond_discount}
          </div>
          <div className="card__content--delivered-price">
            <span className="card__content--delivered-value">
              <Price
                price={{
                  currency: 'USD',
                  amount: in_house_default.diamond_amount,
                }}
              />
            </span>
            {exchange_rate.name !== 'USD' && (
              <span className="card__content--delivered-exchange">
                <PriceExchanged
                  price={{
                    currency: 'USD',
                    amount: in_house_default.diamond_amount,
                  }}
                />
              </span>
            )}
          </div>
        </div>
      ) : (
        in_house_default.diamond_price
      ),
    };
  });
};
