import { gql } from '@apollo/client';

export const LocationFragment = gql`
  fragment LocationFragment on Location {
    id
    name
    address1
    address2
    country
    city
    postalCode
    state
    suburb
    kyc_verified
    default_shipping_address
    last_mile_delivery_fee {
      id
      country_code
      country_name
      last_mile_fee
      local_fee
    }
  }
`;

export const GET_QUERY = gql`
  query {
    me {
      id
      company {
        id
        main_location
        website
        about
        is_owner
      }
      locations {
        location {
          ...LocationFragment
        }
        type
      }
    }
  }
  ${LocationFragment}
`;
