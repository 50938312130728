import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';

export const UpdateNotificationPreferences = GraphqlMutation({
  query: gql`
    mutation ($preferences: PreferencesInput!) {
      update_notification_preferences(preferences: $preferences) {
        order_placed {
          enabled
          emails
        }
        order_confirmed {
          enabled
          emails
        }
        order_shipping_updates {
          enabled
          emails
        }
        delivery_partner_change_tracking {
          enabled
          emails
        }
        order_received_local_hub {
          enabled
          emails
        }
        order_out_delivery_last_mile {
          enabled
          emails
        }
        sold_memo_updates {
          enabled
          emails
        }
        request_info_updates {
          enabled
          emails
        }
        on_hold_info_updates {
          enabled
          emails
        }
        feed_centre_updates {
          enabled
          emails
        }
        invoice_communication {
          enabled
          emails
        }
      }
    }
  `,
});
