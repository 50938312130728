import { GraphqlFactory } from 'graphql/factory';
import { GraphqlQuery } from 'graphql/factory';
import { gql } from '@apollo/client';
import { CFMFragments } from '../../fragments/CfmFragments';
import { graphqlClient } from '../../factory';
import {
  UserProfileDetailsFragment,
  AdminUserProfileDetailsFragment,
} from 'graphql/fragments/UserFragment';

export const FetchUserCompanyLocations = GraphqlQuery({
  query: gql`
    query CompanyAccounting {
      me {
        id
        company {
          id
          locations {
            id
            name
          }
        }
      }
    }
  `,
});

export const FetchUserDefaultLocation = GraphqlQuery({
  query: gql`
    query CompanyAccounting {
      me {
        id
        default_location {
          location {
            id
            name
          }
        }
      }
    }
  `,
});

export const CUSTOMER_DASHBOARD_USER = gql`
  query CustomerDashboardUser {
    me {
      id
      steps_required
      firstName
      lastName
      profile_image
      default_currency
      preferred_language
      role
      email
      phone1
      default_sort_type
      default_sort_direction
      country
      email_notifications
      exchange_rate {
        id
        name
        to_USD
      }
      fraudulent_customer {
        restriction_date
        restriction_reason
      }
      company {
        code_disabled
        id
        name
        is_owner
        memo_return_fee
        memo_return_penalty
        company_settings {
          CompanyId
          holds_enabled
          accept_holds
          hold_hours
          supersaver_enabled
          display_supplier_name
          memo_duration
          memo_enabled
        }
        is_airwallex_enabled
        all_in_pricing
        market_pay_active
        credit_consumed
        credit_limit
        account_limit
        account_limit_consumed
        ip_active
        default_credit_enabled
        nivoda_express_enabled
      }
    }
  }
`;

/** Before you add anything to this ask if it's needed at the core of page loading
 * otherwise create a new query as this (query) needs to be resolved before the dashboard is displayed.
 * which can cause a slight delay.
 */
export let ProfileBasics = GraphqlQuery({
  query: CUSTOMER_DASHBOARD_USER,
});

export const PROFILE_BASICS = gql`
  query ProfileBasics {
    me {
      id
      share_list_enabled_product_info
      role
      exchange_rate {
        id
        name
        to_USD
      }
      company {
        is_owner
      }
    }
  }
`;

export let AllInPriceSettings = GraphqlQuery({
  query: gql`
    query ProfileBasics {
      me {
        id
        company {
          company_settings {
            CompanyId
            display_supplier_name
          }
          all_in_pricing
          credit_consumed
          credit_limit
          account_limit
          account_limit_consumed
          ip_active
          default_credit_enabled
        }
      }
    }
  `,
});

export let MyIpInfo = GraphqlQuery({
  query: gql`
    query ProfileBasics {
      me {
        id
      }
      ip_info {
        country_code
      }
    }
  `,
});
//only cx uses this.
export const MY_COMPANY_PROFILE = gql`
  query CompanyProfile {
    me {
      id
      default_currency
      role
      office_closure_recurring_days
      closure_dates {
        id
        company_id
        start_date
        end_date
      }
      fraudulent_customer {
        restriction_date
        restriction_reason
      }
      company {
        id
        about
        is_owner
        name
        country
        code_disabled
        market_pay_limit
        market_pay_limit_consumed
        billing_currency_preference
        market_pay_active
        market_pay_eligible
        disable_checkout
        credit_limit
        credit_consumed
        country_of_incorporation
        account_limit
        account_limit_consumed
        ip_active
        default_credit_enabled
        all_in_pricing
        is_kyc_verified
        is_airwallex_enabled
        nivoda_express_enabled
        diamonds {
          total_count
        }
        company_banner
        company_image
        loupe360_url
        loupe360_markups {
          id
          from_amount
          to_amount
          percent
          ProductType
          markup_type
          ProductSubtype
        }
        signup_coupon_info {
          supplier {
            id
            name
          }
          uses_left
        }
        inventory_type
        free_shipments_left
        company_settings {
          CompanyId
          holds_enabled
          accept_holds
          hold_hours
          supersaver_enabled
          action_other_user_hold
          display_supplier_name
          auto_fetch_feeds
        }
        locations {
          id
          name
          city
          state
          country
        }
        main_location
        website
      }
    }
  }
`;

// we don't need the cfm_settings here.
export let MyProfileCompany = GraphqlQuery({
  query: MY_COMPANY_PROFILE,
});

export const FETCH_CUSTOMER_PAYMENT_TERMS = gql`
  query ($company_id: ID!, $cart_price: Float, $memo_terms: Boolean) {
    customer_payment_terms_list(
      company_id: $company_id
      cart_price: $cart_price
      memo_terms: $memo_terms
    ) {
      api_name
      title
      subTitle
      isEnabled
      interest_rate
      sub_total
      default_selected
      disable_selection
      translationTitleKey
      translationSubTitleKey
    }
  }
`;

export const MeProfile = GraphqlFactory(gql`
  query {
    me {
      id
      firstName
      lastName
      email
      phone1
      role
      company {
        id
        loupe360_url
        type_detail
      }
    }
  }
`);

export const GetLoupe360Token = async () => {
  return await graphqlClient.query({
    query: gql`
      query GetLoupeToken {
        get_loupe360_token
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export let ProfileComprehensive = GraphqlQuery({
  query: gql`
    query ProfileBasics {
      me {
        is_supplier_invoicing_enabled
        company {
          id
          is_kyc_verified
          created_at
          company_settings {
            CompanyId
            action_other_user_hold
            upload_allowed
          }
        }
        ...UserProfileDetailsFragment
      }
    }
    ${UserProfileDetailsFragment}
  `,
});

export const PROFILE_COMPREHENSIVE = gql`
  query ProfileBasics {
    me {
      id
      is_supplier_invoicing_enabled
      company {
        id
        company_settings {
          CompanyId
          action_other_user_hold
        }
      }
      ...UserProfileDetailsFragment
    }
  }
  ${UserProfileDetailsFragment}
`;
export const CUSTOMER_FINANCE_INFORMATION = gql`
  query cxFinanceSettings {
    me {
      id
      companyFinance: company {
        id
        account_limit
        account_limit_consumed
        all_in_pricing
        billing_currency_preference
        cleared_for_credit
        credit_limit
        credit_consumed
        credit_default_days
        credit_default_days
        charge_last_mile_fee
        country_of_incorporation
        default_credit_enabled
        disable_checkout
        ip_active
        is_airwallex_enabled
        is_kyc_verified
        market_pay_active
        market_pay_eligible
        market_pay_limit
        market_pay_limit_consumed
        nivoda_express_enabled
        qc_pref
      }
      fraudulent_customer {
        restriction_date
        restriction_reason
      }
    }
  }
`;
export const GET_USER_COMPREHENSIVE_PROFILE = gql`
  query getUserProfileComprehensive {
    me {
      ...UserProfileDetailsFragment
      company {
        id
        is_kyc_verified
        company_settings {
          CompanyId
          action_other_user_hold
          max_allowed_returns
        }
        country_of_incorporation
        customer_return_days
        returns_used
      }
      can_update_cfm_settings
      fraudulent_customer {
        restriction_date
        restriction_reason
      }
      share_list_enabled_product_info
    }
  }
  ${UserProfileDetailsFragment}
`;
// CONSIDER CREATING A DIFFERENT query for ADMIN_PREFERENCE
// admin_preference {
//   id
//   user_id
//   qc_location {
//     id
//     name
//     city
//     country
//   }
// }
export const GET_ADMIN_USER_COMPREHENSIVE_PROFILE = gql`
  query getUserProfileComprehensive {
    me {
      ...UserProfileDetailsFragment
      company {
        id
        company_settings {
          CompanyId
          action_other_user_hold
          max_allowed_returns
        }
        customer_return_days
        returns_used
      }
    }
  }
  ${AdminUserProfileDetailsFragment}
`;
export let UserProfileForCFM = GraphqlQuery({
  query: gql`
    query ProfileBasics {
      me {
        id
        steps_required
        firstName
        lastName
        profile_image
        default_currency
        role
        email
        phone1
        default_sort_type
        default_sort_direction
        country
        exchange_rate {
          id
          name
          to_USD
        }
        company {
          id
          is_owner
          name
          code_disabled
          diamonds {
            total_count
          }
          company_banner
          cfm_settings {
            ...CFMFragments
          }
          loupe360_url
          loupe360_markups {
            id
            from_amount
            to_amount
            percent
            ProductType
            markup_type
            ProductSubtype
          }
          signup_coupon_info {
            supplier {
              id
              name
            }
            uses_left
          }
          inventory_type
          free_shipments_left
          company_settings {
            CompanyId
            accept_holds
            hold_hours
            supersaver_enabled
            auto_fetch_feeds
          }
          market_pay_active
          market_pay_eligible
          disable_checkout
          credit_default_days
        }
      }
    }
    ${CFMFragments}
  `,
});

export const GetExpressTimeline = GraphqlQuery({
  query: gql`
    query GetExpressTimeline {
      get_express_timeline {
        origin_country
        destination_country
        min_delivery_days
        max_delivery_days
      }
    }
  `,
});

export const GET_EXPRESS_TIMELINE = gql`
  query GetExpressTimeline {
    get_express_timeline {
      origin_country
      destination_country
      min_delivery_days
      max_delivery_days
    }
  }
`;
