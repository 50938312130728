import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';
export const SetCustomerInitiatedPayment = GraphqlMutation({
  query: gql`
    mutation (
      $invoiceIds: [String]
      $customer_initiated_payment: String
      $default_payment_method: String
      $company_id: ID
      $recieved_from_customer: String
      $payment_method: String
      $payment_mode: String
    ) {
      post_payment_activity(
        invoiceIds: $invoiceIds
        customer_initiated_payment: $customer_initiated_payment
        default_payment_method: $default_payment_method
        company_id: $company_id
        recieved_from_customer: $recieved_from_customer
        payment_method: $payment_method
        payment_mode: $payment_mode
      )
    }
  `,
});
