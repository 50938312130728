// noinspection SpellCheckingInspection,JSUnresolvedVariable

import cx from 'classnames';

export const getPrintLabelURL = (orderItem) => {
  const uniqueCustomerCode =
    orderItem.buyer.company.company_settings.company_code;
  const stoneId = orderItem.shipping_reference_number;
  const destinationCode = orderItem.destination.country;
  const destinationLocationCode = orderItem.destination?.location_code;

  const orderNumber = orderItem.order_number;

  const customerOrderNumber = orderItem.customer_order_number;

  const printCustomerOrderNumber =
    orderItem?.buyer?.company?.company_settings?.customer_order_no_on_bc &&
    customerOrderNumber;

  const missing = cx(
    !uniqueCustomerCode && 'Customer code,',
    !stoneId && 'Shipping reference number,',
    !destinationCode && 'Destination Country',
    !destinationLocationCode && 'Destination location code'
  );

  const bottomText = printCustomerOrderNumber
    ? `${uniqueCustomerCode}/${destinationLocationCode}%20${customerOrderNumber.slice(
        -12
      )}%20${stoneId}%20${destinationCode}`
    : `${uniqueCustomerCode}/${destinationLocationCode}%20${stoneId}%20${destinationCode}`;

  const barcode_url = printCustomerOrderNumber
    ? `https://integrations.nivoda.net/upload/barcode?bcid=code128&text=${orderNumber}&alttext=${bottomText}&textsize=13`
    : `https://integrations.nivoda.net/upload/barcode?bcid=code128&text=${orderNumber}&alttext=${bottomText}&textsize=20`;

  return {
    isValid: stoneId && destinationCode && orderNumber && uniqueCustomerCode,
    missing,
    url: barcode_url,
    bottomText: bottomText.replace(/%20/g, ' '),
  };
};
