import { theme } from 'fabric/theme';
import { CustomTrans } from 'helpers';
import { Trans } from 'react-i18next';
import { baseCheckoutPath } from './helper';

export const CHECKOUT_STAGES = ({ isMemoWalletCheckout = false }) => {
  const checkoutPath = baseCheckoutPath(isMemoWalletCheckout);
  return [
    {
      page_id: 'cart',
      name: isMemoWalletCheckout ? 'memo_wallet_and_notes' : 'cart_&_notes',
      page_index: 1,
      url: `${checkoutPath}/cart`,
    },
    {
      page_id: 'delivery',
      name: 'delivery_options',
      page_index: 2,
      url: `${checkoutPath}/delivery-options`,
    },
    {
      page_id: 'payment',
      name: isMemoWalletCheckout
        ? 'payment_terms_after_invoicing'
        : 'payment_method',
      page_index: 3,
      url: `${checkoutPath}/payment`,
    },
    {
      page_id: 'order',
      name: 'order_review',
      page_index: 4,
      url: `${checkoutPath}/order`,
    },
  ];
};

export const CHECKOUT_STEPS = {
  REVIEW_CART_AND_ADD_NOTES: 'REVIEW_CART_AND_ADD_NOTES',
  DELIVERY_OPTIONS: 'DELIVERY_OPTIONS',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  REVIEW_AND_PLACE_ORDER: 'REVIEW_AND_PLACE_ORDER',
  THANK_YOU: 'THANK_YOU',
};

export const LOCATION_CURRENCY_MAPPING = {
  US: 'USD',
  CA: 'USD',
  GB: 'GBP',
  UK: 'GBP',
  GG: 'GBP',
  MA: 'EUR',
  GI: 'EUR',
  AL: 'EUR',
  UA: 'EUR',
  RE: 'EUR',
  ME: 'EUR',
  IL: 'USD',
  NZ: 'USD',
  MX: 'USD',
  TR: 'USD',
  SG: 'USD',
  ID: 'USD',
  CL: 'USD',
  LB: 'USD',
  CO: 'USD',
  PA: 'USD',
  PE: 'USD',
  TW: 'USD',
  JP: 'USD',
  TH: 'USD',
  PF: 'USD',
  MY: 'USD',
  BR: 'USD',
  EC: 'USD',
  KN: 'USD',
  PH: 'USD',
  KR: 'USD',
  PR: 'USD',
  VG: 'USD',
  CN: 'USD',
  PK: 'USD',
  VN: 'USD',
  DZ: 'USD',
  NG: 'USD',
  RU: 'USD',
  KH: 'USD',
  LK: 'USD',
  MK: 'USD',
  NA: 'USD',
  RS: 'USD',
  TN: 'USD',
  IR: 'USD',
  BY: 'USD',
  GH: 'USD',
  BO: 'USD',
  KY: 'USD',
  KE: 'USD',
  EG: 'USD',
  SL: 'USD',
  SN: 'USD',
  VE: 'USD',
  MO: 'USD',
  AR: 'USD',
  KW: 'USD',
  SA: 'USD',
  BD: 'USD',
  GE: 'USD',
  MM: 'USD',
  ML: 'USD',
  MG: 'USD',
  SV: 'USD',
  SX: 'USD',
  BW: 'USD',
  AO: 'USD',
  VI: 'USD',
  BN: 'USD',
  KG: 'USD',
  BH: 'USD',
  JM: 'USD',
  BB: 'USD',
  GM: 'USD',
  QA: 'USD',
  OM: 'USD',
  AU: 'AUD',
  AE: 'AED',
  HK: 'HKD',
  ZA: 'ZAR',
  IN: 'INR',
};

export const PAYMENT_TERMS_AVAILABLE = {
  UPFRONT_PAY: 'up_front_payment',
  // ADVANCE_PAY: 'ADVANCE_PAY',
  PAY_IN_30: 'cr_30_payment',
  PAY_IN_60: 'cr_60_payment',
  PAY_IN_90: 'cr_90_payment',
  CREDIT_3_DAY: 'ip_payment',
};

export const PAYMENT_TERMS = [
  {
    api_name: PAYMENT_TERMS_AVAILABLE.UPFRONT_PAY,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_put_your_stone_on_hold_for_24_hours'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: { name: 'receipt', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_issue_a_proforma_invoice'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'currency-dollar',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'you_pay_the_proforma_invoice'}
              values={{
                time: '24 hours',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: {
          name: 'new_search',
          color: theme.palette.subdued,
          size: 16,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'we_confirm_your_order_and_run_quality_check_before_shipping'
              }
              values={{
                time: '24 hours',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 5,
        iconProps: {
          name: 'packge-export',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <CustomTrans
              text={'we_ship_the_stone_to_you'}
              returnPlainText={true}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.PAY_IN_30,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'we_confirm_the_availability_of_your_order_usually_within_24_hours'
              }
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'new_search',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_will_quality_check_your_order_before_shipping'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'packge-export',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_ship_your_order_and_issue_an_invoice'}
              values={{
                time: '30 days',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'you_have_days_to_pay_the_invoice'}
              values={{
                days: '30',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.CREDIT_3_DAY,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'we_confirm_the_availability_of_your_order_usually_within_24_hours'
              }
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'new_search',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_will_quality_check_your_order_before_shipping'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'packge-export',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_ship_your_order_and_issue_an_invoice'}
              values={{
                time: '3 days',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'you_have_days_to_pay_the_invoice'}
              values={{
                days: '3',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.PAY_IN_60,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'we_confirm_the_availability_of_your_order_usually_within_24_hours'
              }
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'new_search',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_will_quality_check_your_order_before_shipping'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'packge-export',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_ship_your_order_and_issue_an_invoice'}
              values={{
                time: '60 days',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'you_have_days_to_pay_the_invoice'}
              values={{
                days: '60',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.PAY_IN_90,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'we_confirm_the_availability_of_your_order_usually_within_24_hours'
              }
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'new_search',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_will_quality_check_your_order_before_shipping'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'packge-export',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_ship_your_order_and_issue_an_invoice'}
              values={{
                time: '60 days',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'you_have_days_to_pay_the_invoice'}
              values={{
                days: '90',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
];

export const getMemoWalletPaymentTerms = (memoDuration) => [
  {
    api_name: PAYMENT_TERMS_AVAILABLE.UPFRONT_PAY,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_put_your_stone_on_hold_for_24_hours'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: { name: 'receipt', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_issue_a_proforma_invoice'}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'currency-dollar',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'you_pay_the_proforma_invoice'}
              values={{
                time: '24 hours',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: {
          name: 'new_search',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'we_confirm_your_order_and_run_quality_check_before_shipping'
              }
              values={{
                time: '24 hours',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 5,
        iconProps: {
          name: 'packge-export',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <CustomTrans
              text={'we_ship_the_stone_to_you'}
              returnPlainText={true}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.PAY_IN_30,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_confirm_and_ship_your_order_usually_within_24_hours'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'package_import_v4',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_return_the_stones_after_they_are_delivered'
              }
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'file-invoice',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'an_invoice_is_automatically_generated_after_delivery'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_pay_the_invoice_after_it_is_generated'
              }
              values={{
                days: '30',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.CREDIT_3_DAY,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_confirm_and_ship_your_order_usually_within_24_hours'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'package_import_v4',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_return_the_stones_after_they_are_delivered'
              }
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'file-invoice',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'an_invoice_is_automatically_generated_after_delivery'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_pay_the_invoice_after_it_is_generated'
              }
              values={{
                days: '3',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.PAY_IN_60,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_confirm_and_ship_your_order_usually_within_24_hours'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'package_import_v4',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_return_the_stones_after_they_are_delivered'
              }
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'file-invoice',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'an_invoice_is_automatically_generated_after_delivery'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_pay_the_invoice_after_it_is_generated'
              }
              values={{
                days: '60',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
  {
    api_name: PAYMENT_TERMS_AVAILABLE.PAY_IN_90,
    summarysteps: [
      {
        id: 1,
        iconProps: {
          name: 'check-regular',
          color: theme.palette.subdued,
          size: 18,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'we_confirm_and_ship_your_order_usually_within_24_hours'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 2,
        iconProps: {
          name: 'package_import_v4',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_return_the_stones_after_they_are_delivered'
              }
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 3,
        iconProps: {
          name: 'file-invoice',
          color: theme.palette.subdued,
          size: 20,
        },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={'an_invoice_is_automatically_generated_after_delivery'}
              values={{ memoDuration }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
      {
        id: 4,
        iconProps: { name: 'clock', color: theme.palette.subdued, size: 20 },
        description: (
          <span
            style={{ ...theme.fonts.small, color: theme.palette.Neutrals700 }}
          >
            <Trans
              i18nKey={
                'you_will_have_days_to_pay_the_invoice_after_it_is_generated'
              }
              values={{
                days: '90',
              }}
              components={{
                strong: (
                  <strong style={{ color: theme.palette.themePrimary }} />
                ),
              }}
            />
          </span>
        ),
      },
    ],
  },
];

export const ORDER_SUMMARY = {
  CreditBalance: {
    title: 'Your Credit Balance',
    translateKey: 'your_credit_balance',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: '',
  },
  CreditConsumed: {
    title: 'Spent',
    translateKey: 'spent',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: '',
  },
  CreditRemaining: {
    title: 'Remaining',
    translateKey: 'remaining',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: '',
  },
  Subtotal: {
    title: 'Subtotal',
    translateKey: 'subtotal',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: 'The Subtotal is the total of the Delivered Price.',
    toolTipTranslateKey: 'subtotal_is_the_total_of_delivery_price',
  },
  MarketPayCreditLimit: {
    title: 'Account limit available',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: 'Market pay available credit.',
    translateKey: 'account_limit_available',
  },
  Discount: {
    title: 'Discount',
    translateKey: 'discount',
    toolTipIcon: 'coupons',
    toolTipSideInfo: 'Apply coupon',
    toolTipText: '',
  },
  IncentivePayFee: {
    title: 'Incentive Pay fee',
    translateKey: 'incentive_pay_fee',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText:
      'The Incentive Pay fee is 1% of your order subtotal, which is fully refunded if we receive payment within 3 business days.',
  },
  IncentivePayCredit: {
    title: 'Incentive Pay balance',
    translateKey: 'incentive_pay_balance',
    toolTipIcon: 'info-card',
    toolTipSideInfo: '',
    toolTipText:
      'The Incentive Pay balance is the balance from previous invoices. Payments may take up to 1 day to automatically reflect.',
  },
  Total: {
    estimatedTooltip:
      'The total amount including taxes, insurance and door-to-door delivery.',
    finalTooltip:
      'The total amount including taxes, insurance and door-to-door delivery.',
    toolTipTranslateKey: 'total_amount_including_taxes',
  },
  PaymentTermSelected: {
    title: 'Checkout option selected',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: 'The Subtotal is the total of the Delivered Price.',
    translateKey: 'payment_terms',
  },
  PaymentTermFee: {
    title: 'Checkout option fee',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: 'The Subtotal is the total of the Delivered Price.',
    translateKey: 'payment_term_fee',
  },
  LastMileDeliveryFee: {
    title: 'Shipping Fee',
    translateKey: 'shipping_fee',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: 'This is the last mile fee for within your delivery country.',
  },
  FreeReturns: {
    title: 'Returns',
    toolTipIcon: 'info-i-icon',
    toolTipSideInfo: '',
    toolTipText: 'Free Returns',
    translateKey: 'free_returns',
  },
};

export const SUPPLIER_STATUS = {
  LIVE: 'LIVE',
  BLOCKED: 'BLOCKED',
  NOT_INTERESTED: 'NOT_INTERESTED',
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
  MANUALLY_ADDED: 'MANUALLY_ADDED',
};
