import {
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { Link } from '@reach/router';
import whiteLogo from '../../resources/svg/nivoda-icon/white-logo.svg';
import primaryLogo from '../../resources/svg/nivoda-icon/logo-blue.svg';
import React from 'react';
import { Box } from './styles';
import { theme } from '@nivoda/ui';
import cx from 'classnames';
import { Icon } from '../../resources/icon';

const propTypes = {
  dimensions: shape({
    h: oneOfType([string, number]).isRequired,
    w: oneOfType([string, number]).isRequired,
  }),
  showLogo: bool,
  iconSize: number,
  noBorder: bool,
  step: number.isRequired,
  completedSteps: arrayOf(number),
  renderContent: func.isRequired,
  stepOptions: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      icon: string,
      disabled: bool,
      url: string,
      title: string,
    })
  ),

  theme: oneOf(['blue', 'white']),
};

export function Stepper(props) {
  const isBlueTheme = props.theme === 'blue';
  const completedSteps = props.completedSteps || [];

  return (
    <Box
      dimensions={props.dimensions}
      isBlueTheme={isBlueTheme}
      align={props.align}
      count={props.stepOptions.length || 1}
      stepperPadding={props.stepperPadding}
      noBorder={props.noBorder}
    >
      {props.showLogo && (
        <div className="top_header__logo">
          <Link
            to="/"
            style={{ color: theme.palette.themePrimary, fontWeight: 400 }}
          >
            {isBlueTheme ? (
              <img
                className="nivoda-white-logo"
                alt="Nivoda white Logo"
                src={whiteLogo}
              />
            ) : (
              <img
                className="nivoda-blue-logo"
                alt="Nivoda white Logo"
                src={primaryLogo}
              />
            )}
          </Link>
        </div>
      )}

      <div className={'stepper__container'}>
        <div className="top_header__items">
          {props.stepOptions.map((option, i) => (
            <div
              className={cx(
                'top_header__item',
                props.step > i + 1 && 'active_after',
                props.step === i + 1 && 'active',
                completedSteps.includes(i + 1) && 'completed'
              )}
              key={option.id}
            >
              <span className="top_header__item--count-box">
                <span
                  className="top_header__item--count"
                  title={cx(
                    `${i + 1}.`,
                    option.title ||
                      (typeof option.label === 'string' ? option.label : '')
                  )}
                  style={{
                    padding:
                      completedSteps.includes(i + 1) && props.step === i + 1
                        ? 0
                        : theme.spacing.s1,
                  }}
                >
                  {completedSteps.includes(i + 1) ? (
                    <Icon
                      className={`icon__${option.id}`}
                      color={
                        isBlueTheme ? theme.palette.white : theme.palette.green
                      }
                      name={'ok_icon'}
                      size={props.iconSize || 30}
                      title={cx(
                        `${i + 1}.`,
                        option.title ||
                          (typeof option.label === 'string' ? option.label : '')
                      )}
                    />
                  ) : option.icon ? (
                    <Icon
                      className={`icon__${option.id}`}
                      color={
                        props.step === i + 1
                          ? theme.palette.white
                          : theme.palette.themePrimary
                      }
                      name={option.icon}
                      size={props.iconSize || 18}
                      title={cx(
                        `${i + 1}.`,
                        option.title ||
                          (typeof option.label === 'string' ? option.label : '')
                      )}
                    />
                  ) : (
                    i + 1
                  )}
                </span>
              </span>
              <span className="top_header__item--label">{option.label}</span>
            </div>
          ))}
        </div>
        <div className={'stepper__content'}>{props.renderContent()}</div>
      </div>
    </Box>
  );
}

Stepper.propTypes = propTypes;
