import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';

export let GetAnsweredConciergeRequests = AdminGraphqlQuery({
  query: gql`
    query {
      answered_concierge_requests {
        id
        certificate_type
        lab
        size
        shape
        cut
        color
        clarity
        polish
        symmetry
        fluorescence
        comment
        created_at
        type
        status
        internal_status
        requester {
          id
          firstName
          lastName
          company {
            id
            name
          }
        }
      }
    }
  `,
});

export let GetUnanswereConciergeRequests = AdminGraphqlQuery({
  query: gql`
    query {
      unanswered_concierge_requests {
        id
        certificate_type
        lab
        size
        shape
        cut
        color
        clarity
        polish
        symmetry
        fluorescence
        comment
        created_at
        type
        status
        internal_status
        requester {
          id
          firstName
          lastName
          company {
            id
            name
          }
        }
      }
    }
  `,
});

export let GetConciergeRequest = gql`
  query ($id: ID!) {
    get_concierge_request(id: $id) {
      id
      certificate_type
      lab
      size
      shape
      cut
      color
      clarity
      polish
      symmetry
      fluorescence
      comment
      created_at
      type
      status
      internal_status
      create_order
      customer_order_number
      requester {
        id
        firstName
        lastName
        email
        company {
          id
          name
          main_location
          OwnerId
        }
      }
    }
  }
`;

export const GetStockForConciergeRequest = gql`
  query ($id: ID!) {
    get_stock_for_concierge_request(id: $id) {
      id
      ProductId
      product_type
      OrderRequestId
    }
  }
`;
