import { bool, object } from 'prop-types';
import styled from 'styled-components';

const Wrap = styled.div`
  padding: 3px;
  background: ${({ disabled, active, disabledBg, activeBg }) =>
    disabled
      ? disabledBg || '#D4D4D8'
      : active
      ? activeBg || '#292524'
      : '#a1a1aa'};
  height: ${({ wrapHeight }) => wrapHeight || '20px'};
  width: ${({ wrapWidth }) => wrapWidth || '32px'};
  border-radius: 999px;
  justify-content: ${({ active }) => (active ? 'flex-end' : 'flex-start')};
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Thumb = styled.div`
  height: ${({ thumbHeight }) => thumbHeight || '14px'};
  width: ${({ thumbWidth }) => thumbWidth || '14px'};
  background-color: ${({ thumbBg }) => thumbBg || '#fff'};
  border-radius: 999px;
  position: absolute;
  top: 50%;
  transition: 0.2s ease;
  left: ${({ active }) => (active ? '85%' : '15%')};
  transform: ${({ active }) =>
    active ? 'translate(-85%, -50%)' : 'translate(-15%, -50%)'};
`;

export const SwitchV3 = (props) => {
  const { wrapProps, thumbProps, item, onChange, active } = props;

  const handleClick = (e) => {
    e.stopPropagation();
    if (typeof onChange === 'function') {
      if (wrapProps?.disabled) {
        return;
      }
      onChange(item.value ?? item.key);
    }
  };

  return (
    <Wrap {...wrapProps} onClick={handleClick} active={active}>
      <Thumb {...thumbProps} active={active} />
    </Wrap>
  );
};

SwitchV3.propTypes = {
  item: object.isRequired,
  active: bool,
  wrapProps: object,
  thumbProps: object,
};
