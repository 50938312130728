export const TINGE_MAPPER = {
  brown: [
    {
      label: 'Not Brown',
      code: 'No',
      value: 0,
    },
    {
      label: 'V. V. Light Brown',
      code: 'VVLB',
      value: 1,
    },
    {
      label: 'Very Light Brown',
      code: 'VLB',
      value: 2,
    },
    {
      label: 'Light Brown',
      code: 'LB',
      value: 3,
    },
    {
      label: 'Brown',
      code: 'B',
      value: 4,
    },
    {
      label: 'Strong Brown',
      code: 'SB',
      value: 5,
    },
    {
      label: 'Very Strong Brown',
      code: 'VSB',
      value: 6,
    },
  ],
  green: [
    {
      label: 'Not Green',
      code: 'No',
      value: 0,
    },
    {
      label: 'V. V. Light Green',
      code: 'VVLG',
      value: 1,
    },
    {
      label: 'Very Light Green',
      code: 'VLG',
      value: 2,
    },
    {
      label: 'Light Green',
      code: 'LG',
      value: 3,
    },
    {
      label: 'Green',
      code: 'G',
      value: 4,
    },
    {
      label: 'Strong Green',
      code: 'SG',
      value: 5,
    },
    {
      label: 'Very Strong Green',
      code: 'VSG',
      value: 6,
    },
  ],
  blue: [
    {
      label: 'Not Blue',
      code: 'No',
      value: 0,
    },
    {
      label: 'V. V. Light Blue',
      code: 'VVLB',
      value: 1,
    },
    {
      label: 'Very Light Blue',
      code: 'VLB',
      value: 2,
    },
    {
      label: 'Light Blue',
      code: 'LB',
      value: 3,
    },
    {
      label: 'Blue',
      code: 'B',
      value: 4,
    },
    {
      label: 'Strong Blue',
      code: 'SB',
      value: 5,
    },
    {
      label: 'Very Strong Blue',
      code: 'VSB',
      value: 6,
    },
  ],
  gray: [
    {
      label: 'Not Gray',
      code: 'No',
      value: 0,
    },
    {
      label: 'V. V. Light Gray',
      code: 'VVLG',
      value: 1,
    },
    {
      label: 'Very Light Gray',
      code: 'VLG',
      value: 2,
    },
    {
      label: 'Light Gray',
      code: 'LG',
      value: 3,
    },
    {
      label: 'Gray',
      code: 'G',
      value: 4,
    },
    {
      label: 'Strong Gray',
      code: 'SG',
      value: 5,
    },
    {
      label: 'Very Strong Gray',
      code: 'VSG',
      value: 6,
    },
  ],
  milky: [
    {
      label: 'Not Milky',
      code: 'No',
      value: 0,
    },
    {
      label: 'V. V. Light Milky',
      code: 'VVLM',
      value: 1,
    },
    {
      label: 'Very Light Milky',
      code: 'VLM',
      value: 2,
    },
    {
      label: 'Light Milky',
      code: 'LM',
      value: 3,
    },
    {
      label: 'Milky',
      code: 'M',
      value: 4,
    },
    {
      label: 'Strong Milky',
      code: 'SM',
      value: 5,
    },
    {
      label: 'Very Strong Milky',
      code: 'VSM',
      value: 6,
    },
  ],
};

export const EC_MAPPER = [
  {
    label: '100%',
    code: '100%',
    value: 100,
  },
  {
    label: '95%',
    code: '95%',
    value: 95,
  },
  {
    label: '90%',
    code: '90%',
    value: 90,
  },
  {
    label: '85%',
    code: '85%',
    value: 85,
  },
  {
    label: '80%',
    code: '80%',
    value: 80,
  },
  {
    label: '75%',
    code: '75%',
    value: 75,
  },
  {
    label: 'Not',
    code: '0%',
    value: 0,
  },
  {
    label: 'Not',
    code: 'No',
    value: 0,
  },
];
