import { CustomTrans, styled_predicate } from 'helpers';
import { theme } from 'fabric/theme';
import styled from 'styled-components';
const CustomInput = styled.div`
  display: flex;
  align-items: center;
  max-width: 154px;
  height: 40px;
  padding: 12px 8px;
  background: ${(p) => (p.disabled ? theme.palette.gray200 : '#ffffff')};
  border: 1px solid #dadada;
  border-radius: 8px;
  position: relative;
  .prefix {
    height: 20px;
  }

  .input_wrap {
    width: 100%;
    input {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
      border: none;
      outline: none;
      margin-left: 3px;
      width: 90%;
      flex: 1;
    }
    input:disabled {
      cursor: not-allowed;
      background: ${theme.palette.gray200};
    }
  }
  ${styled_predicate((p) => p.disabled)} {
    &:hover::before {
      position: absolute;
      top: 45px;
      left: 10%;
      padding: 5px;
      color: white;
      background: #6e6e6eee;
      white-space: nowrap;
      z-index: 10;
    }
  }
`;

export const Input = ({
  PrefixIcon,
  PostfixIcon,
  style,
  onChange,
  placeholder,
  value = '',
  name,
  onBlur,
  disabled,
}) => {
  return (
    <CustomInput
      style={{ ...style }}
      disabled={disabled}
      title={
        disabled &&
        CustomTrans({
          text: 'you_cannot_edit_the_base_row_starting_field',
          returnPlainText: true,
        })
      }
    >
      {PrefixIcon && <div className="prefix">{PrefixIcon}</div>}
      <div className="input_wrap">
        <input
          type="text"
          name={name}
          disabled={disabled}
          onChange={(e) => {
            onChange(e);
          }}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      </div>
      {PostfixIcon && <div className="prefix">{PostfixIcon}</div>}
    </CustomInput>
  );
};
