import styled from 'styled-components';
import theme from '../../../fabric/theme';

// Error icon wrapper (can be reused)
export const IconDiv = styled.div`
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '500px')};
  text-align: center;
  justify-content: center;

  @media (max-width: 700px) {
    min-width: max-content;
  }

  .svg_icon {
    display: inline-flex;
    border-radius: 50%;
    background: ${({ errorType }) =>
      theme.semanticColors[`${errorType}BG0`] || '#f5f5f5'};
    justify-content: center;
    align-items: center;
    &.warning {
      width: 60px;
      height: 60px;
    }
    &.error {
      width: 60px;
      height: 60px;
    }
    &.info {
      padding: 0;
    }
    &.info,
    &.success {
      width: 70px;
      height: 70px;
    }
  }
`;
