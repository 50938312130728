import { statusMapper } from '../../constants';
import { Icon } from 'fabric/resources/icon';
import { ReactComponent as LockIcon } from 'fabric/resources/svg/ui-icons/lock-icon.svg';
import { CustomTrans } from 'helpers';
import './pill.css';

export const customStatusMapper = (text) => {
  if (!text) return '';
  return text?.replace(' ', '_').toLowerCase();
};

export const StatusPill = ({
  name,
  customLabel = '',
  isBlocked = false,
  showIcon = false,
  overrideStyles = {},
  large = false,
}) => {
  let status_cname = () =>
    statusMapper[name]
      .toLowerCase()
      .replace(/[\s{2,}]\//g, '')
      .replace(/_/g, ' ')
      .split(' ')
      .map((word, index, array) =>
        index === array.length - 1
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : `${word.charAt(0).toUpperCase() + word.slice(1)}-`
      )
      .join('');

  return name ? (
    <>
      <div
        data-automation-id="invoice-status"
        className={`pill_status_new ${status_cname()}`}
        style={{ ...overrideStyles }}
      >
        {customLabel ? (
          customLabel
        ) : large ? (
          <p className="truncate">
            {statusMapper[name] && (
              <CustomTrans
                text={customStatusMapper(statusMapper[name])}
                returnPlainText={true}
                fallBack={statusMapper[name]}
              />
            )}
          </p>
        ) : (
          <span className="truncate">
            {statusMapper[name] && (
              <CustomTrans
                text={customStatusMapper(statusMapper[name])}
                returnPlainText={true}
                fallBack={statusMapper[name]}
              />
            )}
          </span>
        )}
        {isBlocked && <LockIcon />}
      </div>
      {name === 'QC_MISMATCH' && showIcon && (
        <span style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
          <Icon name="nivodaVerified" size={18} color="#ADADAD" />
        </span>
      )}
    </>
  ) : null;
};
