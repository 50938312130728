import styled from 'styled-components';
import { theme } from 'fabric/theme';
//Button
export const StyledButton = styled.button`
  font-size: 1em;
  border-radius: 0.25rem;
  padding: 15px 48px !important;
`;

export const SubtleButton = styled.span`
  color: ${theme.palette.themePrimary};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${theme.palette.themeSecondary};
  }
`;

export const BasicInfoPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  @media (max-width: 900px) {
    margin-bottom: 50px;
  }
  .basic_info_page {
    &--header {
      &__title {
        margin-top: unset;
        ${theme.fonts.xlBold}
        font-family:Inter;
        color: ${theme.palette.Neutrals950} !important;
        line-height: 29px;
        padding-bottom: 16px;
      }
      &__subtitle {
        ${theme.fonts.large}
        font-family:Inter;
        color: ${theme.palette.Neutrals700} !important;
        line-height: 24px;
      }
    }
    &--form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__row1 {
        display: flex;
        align-items: baseline;
        gap: 20px;
        .custom_input_wrapper {
          width: 50%;
        }
        @media (max-width: 900px) {
          flex-direction: column;
          height: unset;
          .custom_input_wrapper {
            width: 100%;
          }
        }
      }
      .terms_and_conditions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &--title {
          ${theme.fonts.largeBold}
          line-height:24px;
          font-family: Inter;
          color: ${theme.palette.Neutrals950};
        }
        &--content {
          ${theme.fonts.medium}
          line-height:16.94px;
          font-family: Inter;
          color: ${theme.palette.Neutrals700};
          display: flex;
          flex-direction: column;
          gap: 10px;
          .check_box_label {
            ${theme.fonts.mediumPlus}
            line-height:16.94px;
            color: ${theme.palette.Neutrals700};
          }
          .custom__checkbox {
            padding-top: 1px;
            .checkbox_checked {
              background-color: ${theme.palette.Neutrals900};
            }
            .checkbox_media {
              height: 17px !important;
              width: 17px !important;
              min-width: 16px;
              margin-right: 6px;
            }
            align-items: flex-start;
          }
          a {
            ${theme.fonts.mediumBold}
            line-height:16.94px;
            font-family: Inter;
            color: ${theme.palette.Neutrals700};
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export const StepOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  .step_one {
    &--header {
      text-align: center;
      &__title {
        margin-top: unset;
        ${theme.fonts.xxLargeBold}
        color: ${theme.palette.Neutrals950} !important;
        font-family: Inter;
        line-height: 20px;
      }
      &__content {
        ${theme.fonts.large}
        font-family: Inter;
        color: ${theme.palette.Neutrals700} !important;
        padding-top: 16px;
      }
    }
    &--main {
      width: 100%;
      text-align: center;
      &__title {
        ${theme.fonts.large}
        font-family: Inter;
        color: ${theme.palette.Neutrals700} !important;
      }
      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        gap: 15px;
        .box_radio {
          width: 50%;
          border: none;
          background: ${theme.palette.Neutrals200};
          border-radius: 5px;
          padding: 10px;
          text-align: left;
          cursor: pointer;
          &__title {
            ${theme.fonts.xLargeBold}
            font-family: Inter;
            color: ${theme.palette.Neutrals600} !important;
          }
          &__desc {
            ${theme.fonts.medium}
            font-family:Inter;
            color: ${theme.palette.Neutrals600} !important;
          }
        }
        .active {
          border: 2px solid ${theme.palette.Neutrals950};
          background: ${theme.palette.gray50};
          .box_radio__title {
            ${theme.fonts.xLargeBold}
            font-family: Inter;
            color: ${theme.palette.Neutrals950} !important;
          }
          .box_radio__desc {
            ${theme.fonts.medium}
            font-family:Inter;
            color: ${theme.palette.Neutrals600} !important;
          }
        }
        @media (max-width: 500px) {
          flex-direction: column;
          .box_radio {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const BusinessInfoStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  @media (max-width: 900px) {
    margin-bottom: 50px;
  }
  .business_info_wrapper {
    &--header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &__title {
        ${theme.fonts.xlBold}
        line-height:29.05px;
        color: ${theme.palette.Neutrals950};
        font-family: Inter;
        padding-bottom: 16px;
        @media (max-width: 700px) {
          ${theme.fonts.largeBold}
        }
      }
      &__subtitle {
        ${theme.fonts.large}
        line-height:24px;
        color: ${theme.palette.Neutrals700};
        font-family: Inter;
        @media (max-width: 700px) {
          ${theme.fonts.medium}
        }
      }
    }
    &--form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__row1 {
        display: flex;
        align-items: baseline;
        gap: 20px;
        .custom_input_wrapper {
          width: 50%;
        }
        @media (max-width: 900px) {
          flex-direction: column;
          height: unset;
          .custom_input_wrapper {
            width: 100%;
          }
        }
      }
      .row-flex {
        @media (max-width: 900px) {
          flex-direction: row;
        }
      }
    }
  }
`;

export const ConfirmEmailAndPhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .confirm_email_wrapper {
    &--header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      &__title {
        ${theme.fonts.xlBold}
        line-height:29.05px;
        color: ${theme.palette.Neutrals950};
        font-family: Inter;
        text-align: center;
      }
      &__subtitle {
        ${theme.fonts.large}
        line-height:24px;
        color: ${theme.palette.Neutrals700};
        font-family: Inter;
      }
    }
    &--content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;
      text-align: center;
      &__info {
        ${theme.fonts.large}
        line-height:24px;
        font-family: Inter;
        color: ${theme.palette.Neutrals700};
      }
      .otp_input {
        border: 1px solid #e0e0e0;
      }
      &__codearea {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        height: 80px;
        @media (max-width: 700px) {
          height: 48px;
        }
        .hr_line {
          width: 17px;
          height: 2px;
        }
        .custom_input_wrapper {
          width: 64px;
          @media (max-width: 700px) {
            width: 36px;
          }
          &--input {
            height: 100%;
            input {
              height: 100%;
              text-align: center;
              width: 100%;
              font-size: 32px;
              font-family: Inter;
              line-height: 24px;
              font-weight: 400;
              @media (max-width: 700px) {
                font-size: 18px;
                font-family: Inter;
                line-height: 24px;
                font-weight: 500;
              }
              color: ${theme.palette.Neutrals950};
            }
          }
        }
        @media (min-width: 700px) and (max-width: 1023px) {
          height: 70px;
          .custom_input_wrapper {
            width: 54px;
            &--input {
              height: 100%;
            }
          }
        }
      }
      &__expirecode {
        ${theme.fonts.large}
        line-height:24px;
        font-family: Inter;
        color: ${theme.palette.Neutrals700};
      }
      &__new_code {
        ${theme.fonts.mediumBold}
        line-height:24px;
        font-family: Inter;
        color: ${theme.palette.green};
      }
      &__error {
        ${theme.fonts.mediumBold}
        line-height:24px;
        font-family: Inter;
        color: ${theme.palette.red};
      }
    }
    &--footer {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .resend_otp {
        display: flex;
        margin-top: 10px;
        gap: 2.5px;
        justify-content: center;
        align-items: center;
        font-family: Inter;
        color: ${theme.palette.Neutrals950};

        &--timer {
          display: flex;
          gap: 2.5px;
          ${theme.fonts.mediumBold}
        }
        &--text {
          cursor: pointer;
          display: flex;
          text-decoration: underline;
          gap: 2.5px;
          ${theme.fonts.mediumBold}
          :hover {
            color: ${theme.semanticColors.signupPrimaryHoverBg};
          }
        }
      }
    }
  }
`;

export const OtpWrapper = styled.div``;

export const ConfirmAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  @media (max-width: 900px) {
    margin-bottom: 120px;
  }
  .confirm_address_wrapper {
    &--header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      &__title {
        ${theme.fonts.xlBold}
        line-height:29.05px;
        color: ${theme.palette.Neutrals950};
        font-family: Inter;
      }
      &__subtitle {
        ${theme.fonts.large}
        line-height:24px;
        color: ${theme.palette.Neutrals700};
        font-family: Inter;
      }
    }
    &--form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &__header {
        .confirm_address_title {
          ${theme.fonts.largeBold}
          color:${theme.palette.Neutrals950};
          font-family: Inter;
          line-height: 24px;
        }
        .toggle_switch {
          display: flex;
          align-items: center;
          gap: 10px;
          .switch_label {
            ${theme.fonts.mediumPlus}
            font-family:Inter;
            color: ${theme.palette.Neutrals950};
            line-height: 14px;
          }
        }
      }
      &__row1 {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 60px;
        .custom_input_wrapper {
          width: 50%;
        }
        @media (max-width: 1023px) {
          flex-direction: column;
          height: unset;
          .custom_input_wrapper {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const OnboardingCompletedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  .onboarding_completed_wrapper {
    &__header {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      align-items: center;
      @media (max-width: 700px) {
        flex-direction: column;
      }
      &--title {
        display: flex;
        flex-direction: column;
        .main_heading {
          ${theme.fonts.xlBold}
          font-family:Inter;
          color: ${theme.palette.Neutrals950} !important;
          line-height: 29.05px;
        }
        .sub_heading {
          ${theme.fonts.large}
          font-family:Inter;
          color: ${theme.palette.Neutrals700} !important;
          line-height: 29.05px;
        }
      }
    }
    &__content {
      ${theme.fonts.large}
      font-family:Inter;
      color: ${theme.palette.Neutrals700} !important;
      line-height: 29.05px;
    }
    &__footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      sub {
        text-decoration: underline;
        cursor: pointer;
        ${theme.fonts.mediumBold}
        line-height:24px;
        font-family: Inter;
        color: ${theme.palette.Neutrals950};
        :hover {
          color: ${theme.palette.brandGold};
        }
      }
    }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
`;

export const PhoneInputWrapper = styled.div`
  .phone_input_wrapper {
    &--label {
      ${theme.fonts.smallBold}
      line-height:14.52px;
      color: ${theme.palette.Neutrals950};
      font-family: Inter;
      margin-bottom: unset;
      padding-left: 10px;
    }
  }
  .react-tel-input {
    width: 49% !important;
    @media (min-width: 700px) and (max-width: 1023px) {
      width: 100% !important;
    }
    .form-control {
      width: unset;
    }
    input {
      width: 97%;
    }
  }
`;
