import { openDB } from 'idb';

class IndexedDBUtil {
  constructor(dbName, objectStoreName) {
    this.dbName = dbName;
    this.objectStoreName = objectStoreName;
    this.db = null;
  }

  async open() {
    try {
      this.db = await openDB(this.dbName, 1, {
        upgrade: (db, oldVersion, _newVersion, transaction) => {
          if (oldVersion < 1) {
            db.createObjectStore(this.objectStoreName, {
              keyPath: 'key',
            });
          }
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error opening IndexedDB:', error);
      throw error;
    }
  }

  async clear() {
    const transaction = this.db.transaction(this.objectStoreName, 'readwrite');
    const store = transaction.objectStore(this.objectStoreName);

    const allKeys = await store.getAllKeys();

    for (const key of allKeys) {
      await store.delete(key);
    }
  }

  async add(item) {
    const transaction = this.db.transaction(this.objectStoreName, 'readwrite');
    const store = transaction.objectStore(this.objectStoreName);
    await store.add(item);
  }

  async get(key) {
    const transaction = this.db.transaction(this.objectStoreName, 'readonly');
    const store = transaction.objectStore(this.objectStoreName);
    return await store.get(key);
  }

  async update(item) {
    const transaction = this.db.transaction(this.objectStoreName, 'readwrite');
    const store = transaction.objectStore(this.objectStoreName);
    await store.put(item);
  }

  async delete(key) {
    const transaction = this.db.transaction(this.objectStoreName, 'readwrite');
    const store = transaction.objectStore(this.objectStoreName);
    await store.delete(key);
  }

  async getAll() {
    const transaction = this.db.transaction(this.objectStoreName, 'readonly');
    const store = transaction.objectStore(this.objectStoreName);
    return await store.getAll();
  }
}

export const connectIDB = async () => {
  const db = new IndexedDBUtil('niv-idb', 'niv-idb-store');
  await db.open();
  return db;
};
