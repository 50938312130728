import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';
import { LocationFragment } from 'graphql/fragments';

// customer mutation to create new location
export const CREATE_LOCATION = gql`
  mutation createLocation($location: LocationInput!) {
    create_location(location: $location) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

// customer mutation to update location
export const UpdateLocation = GraphqlMutation({
  query: gql`
    mutation update_location($id: ID!, $location: LocationInput) {
      update_location(id: $id, location: $location) {
        ...LocationFragment
      }
    }
    ${LocationFragment}
  `,
});

export const UPDATE_LOCATION = gql`
  mutation update_location($id: ID!, $location: LocationInput) {
    update_location(id: $id, location: $location) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

export const DeleteLocation = GraphqlMutation({
  query: gql`
    mutation update_location($id: ID!) {
      delete_location(id: $id)
    }
  `,
});
