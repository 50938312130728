import React from 'react';
import { CustomRadio } from '@nivoda/forms';
import { EC_MAPPER } from './constant';
import { BGMSelectionContainer, SelectionRadioStyle } from './styles';
import { array, func, string } from 'prop-types';
import theme from 'fabric/theme';

export const EyeCleanSelection = ({
  label = 'Eye Clean (%)',
  subLabelText = '',
  eyeCleanState,
  onSelectEyeClean,
  supplierEyeClean,
  items = [],
  status,
  locked,
}) => {
  const itemsMapper = items.length === 0 ? EC_MAPPER : items;

  return (
    <BGMSelectionContainer className="wrapper" status={status} locked={locked}>
      <span className="wrapper__label">{label}</span>
      <div className="wrapper__header-area">
        {subLabelText && (
          <div className="wrapper__header-area__sub-label">{subLabelText}</div>
        )}
        <div className="wrapper__header-area__ec-wrp">
          {itemsMapper.map((ec) => (
            <div
              key={ec.value}
              className={`wrapper__selection-area__values hide-radio-button ${
                +supplierEyeClean === ec.value ? 'supplier' : ''
              }`}
            >
              <CustomRadio
                key={ec.value}
                checked={ec.value.toString() === eyeCleanState}
                setChecked={onSelectEyeClean}
                value={ec.value}
                label={ec.label}
                labelStyle={{
                  ...SelectionRadioStyle,
                  ...(locked &&
                    ec.value.toString() === eyeCleanState && {
                      color: `${
                        status === 'QC_MISMATCH'
                          ? theme.semanticColors.labelWarn
                          : status === 'QC_PASSED'
                          ? theme.semanticColors.labelSuccess
                          : status === 'QC_REJECTED'
                          ? theme.semanticColors.labelError
                          : theme.palette.offBlack4
                      }`,
                    }),
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </BGMSelectionContainer>
  );
};

EyeCleanSelection.propTypes = {
  label: string,
  subLabelText: string,
  items: array,
  eyeCleanState: string.isRequired,
  onSelectEyeClean: func.isRequired,
};
