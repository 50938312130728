import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { NivodaBackendI18NAPI } from './i18n.backend.interface';

const language = JSON.parse(localStorage.getItem('preferred_language'));

/**
 * @description Initializes i18next with a custom backend for fetching
 * translations from the backend API
 */
export default i18n
  .use(NivodaBackendI18NAPI)
  .use(initReactI18next)
  .init({
    lng: language && language.value ? language.value : 'en',
    react: {
      useSuspense: true,
    },
    load: 'all',
    fallbackLng: 'en',
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
