import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';
import { user_fragment } from 'components/settings/settings-page';

export const ADD_QC_COMMENT = gql`
  mutation updateCompanyQCPreference($qc_pref: String!) {
    update_qc_pref(qc_pref: $qc_pref) {
      id
      qc_pref
    }
  }
`;

export const UpdateCfmSettings = GraphqlMutation({
  query: gql`
    mutation (
      $enabled_search_result_params: [SearchResultParams]!
      $enabled_diamond_params: [DiamondParams]!
      $markup_preference: MarkupPreference!
      $enabled_diamond_types: [ProductSubtype!]!
      $tablet_app_params: [CFMWizardSettings]
      $markup_currency: String
      $header_background_color: String
      $tab_color: String
      $background_color: String
      $sidebar_background_color: String
      $text_color: String
      $hover_link_color: String
      $cta_button_color: String
      $display_currency: String
      $display_taxes: Boolean
      $rounded_prices: Boolean
      $display_company_logo: Boolean
      $display_company_name: Boolean
      $display_certificate_pdf: Boolean
      $display_certificate_badge: Boolean
      $language_preference: CFMLanguages
      $font: String
    ) {
      save_cfm_settings(
        enabled_search_result_params: $enabled_search_result_params
        enabled_diamond_params: $enabled_diamond_params
        markup_preference: $markup_preference
        markup_currency: $markup_currency
        enabled_diamond_types: $enabled_diamond_types
        tablet_app_params: $tablet_app_params
        header_background_color: $header_background_color
        tab_color: $tab_color
        background_color: $background_color
        sidebar_background_color: $sidebar_background_color
        text_color: $text_color
        hover_link_color: $hover_link_color
        cta_button_color: $cta_button_color
        display_currency: $display_currency
        display_taxes: $display_taxes
        rounded_prices: $rounded_prices
        display_company_logo: $display_company_logo
        display_company_name: $display_company_name
        display_certificate_badge: $display_certificate_badge
        display_certificate_pdf: $display_certificate_pdf
        language_preference: $language_preference
        font: $font
      ) {
        enabled_diamond_types
        tablet_app_params
        enabled_search_result_params
        enabled_diamond_params
        markup_preference
        markup_currency
        header_background_color
        tab_color
        background_color
        sidebar_background_color
        text_color
        hover_link_color
        cta_button_color
        display_currency
        display_taxes
        rounded_prices
        display_company_logo
        display_company_name
        display_certificate_badge
        display_certificate_pdf
        language_preference
        font
      }
    }
  `,
});

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($user: ProfileInput!) {
    update_user_profile(user: $user) {
      ...Full_User
    }
  }
  ${user_fragment}
`;
