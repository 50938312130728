/**
 * * createAction is factory util to crate action object for reducer
 * @param {string} type - Valid action type of string (All Uppercase)
 * @param {any} payload - action payload to pass to action handler
 * @returns {{payload, type: string}|{type: string}}
 */
export function createAction(type, payload) {
  if (typeof type !== 'string') {
    throw new Error(`Invalid action type of ${typeof type} expected 'String'.`);
  }

  return payload != null
    ? {
        type,
        payload,
      }
    : { type };
}
