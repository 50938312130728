import React, { useState } from 'react';
import SearchInput from 'UI/SearchInput';
import { MultiSearchBoxWrp } from './styles';
import { Paper } from '../Paper';
import { arrayOf, bool, func, shape, string } from 'prop-types';

export const MultiSearchBox = (props) => {
  const {
    loading,
    searchState,
    options,
    onClearSearch,
    onChangeSearch,
    onSearchHandler,
    closeIconPosition = null,
  } = props;
  const [expand, setExpand] = useState(false);
  const onChangeSearchInput = (e) => {
    onChangeSearch(e);
    setExpand(true);
  };

  const onClearSearchInput = () => {
    onClearSearch();
    setExpand(false);
  };

  const onClickSearchTypeHandler = (type) => {
    onSearchHandler(type);
    setExpand(false);
  };

  return (
    <MultiSearchBoxWrp
      className="msw"
      hasValue={!searchState.value}
      closeIconPosition={closeIconPosition}
    >
      <SearchInput
        className={'multi-search-input'}
        clearQuery={onClearSearchInput}
        changeHandler={onChangeSearchInput}
        disabled={loading}
        inputId={'multi-search-input'}
        needDebounce={false}
        placeholder={'Search...'}
        value={searchState.value}
      />
      {searchState.value && expand && (
        <div className="msw_menu">
          <Paper
            onClickOutSide={() => setExpand(false)}
            placement={'top:4px; left: 0; right:0;'}
            showScroll
            menuHeight={'24vh'}
          >
            {options.map((option) => (
              <div
                className="msw_menu_content"
                key={option?.id}
                onClick={() => onClickSearchTypeHandler(option?.id)}
              >
                {option?.title}
              </div>
            ))}
          </Paper>
        </div>
      )}
    </MultiSearchBoxWrp>
  );
};

MultiSearchBox.propTypes = {
  loading: bool.isRequired,
  searchState: shape({
    value: string.isRequired,
    type: string.isRequired,
  }).isRequired,
  options: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    })
  ).isRequired,
  onClearSearch: func.isRequired,
  onChangeSearch: func.isRequired,
  onSearchHandler: func.isRequired,
};
