import React, { useCallback, useState } from 'react';
import { Button, ReqPopup, WithScrollbar } from '@nivoda/ui';
import theme from '../../theme';
import { Avatar } from '@nivoda/common';
import { indexOf } from 'lodash';
import { arrayOf, string } from 'prop-types';
import { AssignToPopup, AssignToWrapper } from './styles';
import classNames from 'classnames';
import { LoadingIcon } from '../Loaders/LoadingIcon';
import SearchInput from 'UI/SearchInput';

export const AssignToWithPopup = (props) => {
  const {
    allowedUserList = [],
    assignedUser,
    isEmpty,
    list,
    index,
    onAssign,
    disabled = false,
  } = props;
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedBuyerId, setSelectedBuyerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const isOpen = selectedRow === index;
  let orderedUsers = {};

  const handlePopupOpen = useCallback(
    () => {
      setSelectedRow(!isOpen ? index : null);
      if (!isEmpty && assignedUser.id) {
        setSelectedBuyerId(assignedUser.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  const handlePopupClose = useCallback(() => {
    setSelectedRow(null);
    setSelectedBuyerId(null);
  }, []);

  function setInMap() {
    return (user) => {
      const index = indexOf(allowedUserList, user.id);

      if (~index) {
        orderedUsers[index] = user;
      }
    };
  }

  if (isOpen && Array.isArray(list) && list.length > 0) {
    list.filter((u) => u && allowedUserList.includes(u.id)).forEach(setInMap());
  }
  const usersList = Object.values(orderedUsers);

  const filteredUserLists = searchText
    ? usersList.filter((s) =>
        s.firstName.toLowerCase().includes(searchText.toLowerCase())
      )
    : usersList.sort((a, b) => a.firstName.localeCompare(b.firstName));

  const handleSelectBuyer = (item) => {
    if (item?.id) {
      setSelectedBuyerId(item.id);
    }
  };

  const handleOnAssign = async () => {
    setLoading(true);
    await onAssign(selectedBuyerId);
    setLoading(false);
    setSelectedRow(null);
    setSelectedBuyerId(null);
  };

  const searchBuyerHandler = ({ target }) => {
    setSearchText(target.value);
  };

  return (
    <AssignToWrapper className="ast" disabled={disabled}>
      <div
        onClick={handlePopupOpen}
        className={classNames(
          disabled ? 'ast__container disabled' : 'ast__container'
        )}
      >
        <Avatar
          name={assignedUser.name}
          url={assignedUser.url}
          is_empty={isEmpty}
          text_color={theme.palette.gray4}
        />
        <span className="ast__title">
          {isEmpty ? (
            <span className="ast__title--unassigned">Unassigned</span>
          ) : (
            assignedUser.name
          )}
        </span>
      </div>

      {isOpen && (
        <ReqPopup
          className={`feed_share_popup confirm_origin_city_popup`}
          title={'Buyer'}
          hasCloseIcon={false}
          active_out_click={false}
          style={{ ...theme.fonts.medium, fontSize: '24px', margin: '0 auto' }}
          noBoxShdw={true}
          remove_top_margin={true}
          popupStyle={{ minWidth: 600, marginTop: 0 }}
        >
          <AssignToPopup className="popup">
            <div className="popup__sub-content">
              <SearchInput
                inputId={'company'}
                changeHandler={searchBuyerHandler}
                placeholder={'Search for a buyer'}
                value={searchText}
                clearQuery={() => setSearchText('')}
              />
              <h2 className="popup__sub-content--title">Select a buyer</h2>
              <WithScrollbar
                className="multi_feed_edit__body"
                isActive={true}
                maxHeight={'70vh'}
              >
                <div className="select-container">
                  {filteredUserLists.map((user) => (
                    <div
                      className={classNames(
                        'select-container__subgroup',
                        selectedBuyerId === user.id ? 'selected' : ''
                      )}
                      key={user.id}
                      onClick={() => handleSelectBuyer(user)}
                    >
                      <p className="select-container__subgroup--title">
                        {user.firstName + ' ' + user.lastName}
                      </p>
                    </div>
                  ))}
                </div>
              </WithScrollbar>
            </div>
            <div className="footer-action">
              <Button
                fontBold
                className={'footer-action_btn footer-action_btn--cancel'}
                customPadding={'9px 0'}
                is_default
                label={'Cancel'}
                disabled={loading}
                onClick={handlePopupClose}
              />
              <Button
                fontBold
                className={'footer-action_btn footer-action_btn--continue'}
                customPadding={'9px 0'}
                label={
                  loading ? <LoadingIcon loadingText={'Assigning'} /> : 'Assign'
                }
                disabled={loading}
                onClick={handleOnAssign}
              />
            </div>
          </AssignToPopup>
        </ReqPopup>
      )}
    </AssignToWrapper>
  );
};
AssignToWithPopup.propTypes = {
  allowedUserList: arrayOf(string).isRequired,
};
