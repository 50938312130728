import theme from 'fabric/theme';
import styled from 'styled-components';

export const Notes = styled.div`
  background: ${theme.palette.blue50};
  border: 1px solid ${theme.palette.blue200};
  padding: ${theme.spacing.s4}
  border-radius: ${theme.effects.roundedCorner4}
  .note_header {
    color: ${theme.palette.blue700};
    ${theme.fonts.mediumBold}
  }
  .note_content {
    margin-top: ${theme.spacing.s1}
    ${theme.fonts.mediumPlus}
  }
`;
