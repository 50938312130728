import { noop } from 'lodash';
import React, { createContext, useState } from 'react';

export let PairsContext = createContext({
  pairsView: false,
  scoreObject: {},
  selectedDiamond: {},
  drawerIsOpen: false,
  setScoreObject: noop,
  setPairsView: noop,
  setSelectedDiamond: noop,
});

export let PairsContextProvider = ({ children }) => {
  let [pairsView, setPairsView] = useState(false);
  let [drawerIsOpen, setDrawerIsOpen] = useState(false);
  let [scoreObject, setScoreObject] = useState({});
  let [selectedDiamond, setSelectedDiamond] = useState({});

  const value = {
    pairsView,
    scoreObject,
    drawerIsOpen,
    setScoreObject,
    setPairsView,
    selectedDiamond,
    setSelectedDiamond,
    setDrawerIsOpen,
  };

  return <PairsContext.Provider value={value} children={children} />;
};

export const PairsConsumer = ({ children }) => {
  return (
    <PairsContext.Consumer>
      {({
        pairsView,
        scoreObject,
        drawerIsOpen,
        setScoreObject,
        setPairsView,
        selectedDiamond,
        setSelectedDiamond,
        setDrawerIsOpen,
      }) => {
        return children({
          pairsView,
          scoreObject,
          drawerIsOpen,
          setScoreObject,
          setPairsView,
          selectedDiamond,
          setSelectedDiamond,
          setDrawerIsOpen,
        });
      }}
    </PairsContext.Consumer>
  );
};
