import { datadogRum } from '@datadog/browser-rum';

const isBrave = () => {
  try {
    if (window.navigator.brave !== undefined) {
      return window.navigator.brave.isBrave.name === 'isBrave';
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
export const isRumEnabled = () => {
  if (
    !isBrave() &&
    process.env.REACT_APP_RUM_APP_ACTIVE &&
    process.env.NODE_ENV !== 'development'
  ) {
    return true;
  }
  return false;
};
export const initRum = () => {
  if (isRumEnabled()) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_RUM_APP_ID,
      clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: process.env.REACT_APP_RUM_SERVICE || 'nivoda-platform',
      env: process.env.REACT_APP_RUM_ENV || 'dev',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: parseInt(process.env.REACT_APP_SAMPLE_RATE) || 100,
      sessionReplaySampleRate: parseInt(process.env.REACT_APP_REPLAY_RATE) || 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [
        /https:\/\/.*\.nivoda\.com/,
        /https:\/\/.*\.nivoda\.net/,
        /https:\/\/.*\.nivodaapi\.net/,
      ],
      defaultPrivacyLevel: 'mask-user-input',
    });
  } else {
    // eslint-disable-next-line no-console
    console.log('RUM is disabled or Browser is Brave');
  }
};
// datadogRum.startSessionReplayRecording(); //Disabling session recording
