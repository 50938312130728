// @flow

import React from 'react';
import shallowEqual from 'shallowequal';

import immutagen from 'immutagen';

export let create_generator_component = (generator_fn) => {
  let { Provider, Consumer } = React.createContext();
  let immutable_generator = immutagen(generator_fn);

  let render_subchild = (generator, value) => {
    let { value: element, next: next_generator } = generator(value);
    return <Provider value={{ generator: next_generator }}>{element}</Provider>;
  };

  let YieldRender = (props) => {
    return render_subchild(immutable_generator, {
      props: props,
      resolve: (value) => {
        return (
          <Consumer>
            {({ generator }) => render_subchild(generator, value)}
          </Consumer>
        );
      },
    });
  };
  return YieldRender;
};

export let generator_render = (generator_fn) => {
  let { Provider, Consumer } = React.createContext();

  let render_subchild = (generator, value) => {
    let { value: element, next: next_generator } = generator(value);
    return <Provider value={{ generator: next_generator }}>{element}</Provider>;
  };

  function render() {
    let element_this = this || {
      get props() {
        throw new Error(
          `Trying to get this.props, but this render function is not bound to a react component :O`
        );
      },
      get state() {
        throw new Error(
          `Trying to get this.state, but this render function is not bound to a react component :O`
        );
      },
    };

    let bound_generator_fn = (...args) =>
      generator_fn.call(element_this, ...args);
    let immutable_generator = immutagen(bound_generator_fn);
    let resolve = (value) => {
      return (
        <Consumer>
          {({ generator }) => render_subchild(generator, value)}
        </Consumer>
      );
    };
    return render_subchild(immutable_generator, resolve);
  }

  return render;
};

export class RenderFunction extends React.Component {
  render() {
    return this.props.render(this.props);
  }
}

export class State extends React.Component {
  /*:flow
  props: {
    initialValue: any,
    children?: (value: any, update: (value: any) => void) => React$Element<*>,
  };
  */

  state = {
    thing: this.props.initialValue,
  };

  render() {
    if (!this.props.children) return;

    return this.props.children(this.state.thing, (valueOrFn) => {
      if (typeof valueOrFn === 'function') {
        this.setState((state) => {
          return { thing: valueOrFn(state.thing) };
        });
      } else {
        if (!shallowEqual(this.state.thing, valueOrFn)) {
          this.setState({ thing: valueOrFn });
        }
      }
    });
  }
}

export let GoodState = ({ initialValue, children, merge }) => {
  return (
    <State
      initialValue={initialValue}
      children={(state, set_state) =>
        children({
          state,
          set_state: merge
            ? (new_state) =>
                set_state((old_state) => {
                  return { ...old_state, ...new_state };
                })
            : set_state,
        })
      }
    />
  );
};

export class Compose extends React.Component {
  props: {
    [key: string]: (children: () => React$Element<*>) => React$Element<*>,
    children?: () => React$Element<*>,
  };

  render() {
    const { children, ...chain } = this.props;

    const entries = Object.entries(chain);
    const fn = entries.reduce(
      (acc, [key, wrapFn]) => {
        // $FlowFixMe
        return (props) => wrapFn((value) => acc({ ...props, [key]: value }));
      },
      (props) => (children ? children(props) : null)
    );

    return fn();
  }
}
