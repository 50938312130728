import React from 'react';
import { Helmet } from 'react-helmet';
import CacheBuster from 'react-cache-buster';
import { Redirect, Router } from '@reach/router';
import { GatewayDest } from 'react-gateway-strictmode';
import { version } from '../package.json';
import { initRum } from './fabric/integrations/datadogRum.js';
import './App/Login.css';
import { RenderFunction } from './MetaComponents.js';

import { NewOnBoardingPage } from './components/onboarding';
import smoothscroll from 'smoothscroll-polyfill';

import { set_cookie } from './helpers';
import {
  adminGraphqlClient,
  clearCurrentSession,
  graphqlClient,
  Providers,
  supplierGraphQlClient,
  userSession,
} from './graphql/factory';
import { config } from 'fabric/config';
import { useHubspotChatWidgetUpdates } from './fabric/hooks';
import { appEnv } from 'utils';
import {
  initHubspotIntercom,
  // addHubspotScript,
  Track,
} from './fabric/integrations';
import axios from 'axios';
import { PageNotFound } from 'fabric/components';
import { useReactiveVar } from '@apollo/client';
import { noop } from 'lodash/functions';
import { QueryClient } from '@tanstack/react-query';
import { Toaster as ReactHotToast } from 'react-hot-toast';
import {
  dashboardModeStateRx,
  featureFlagsRx,
} from 'graphql/factory/reactiveVar';
import './i18n/i18n';
import './App.css';
import './PublicPages/GetStarted/css/main.css';
import { Zendesk } from 'App/Zendesk/Zendesk';
import { initZendesk } from 'App/Zendesk/helper';

const Terms = React.lazy(() => import('./Supplier/Onboarding/Terms'));
const SetInvitesPassword = React.lazy(() => import('./App/setInvitePassword'));
const SuperSaverCampaign = React.lazy(() =>
  import('./PublicPages/Campaign/SuperSaver')
);
const PluginDownload = React.lazy(() =>
  import('./Supplier/components/ExcelPlugin/PluginDownload')
);
const SavingsCalculator = React.lazy(() =>
  import('./PublicPages/SavingsCalculator/SavingsCalculator')
);
const BuyerSupersaverLanding = React.lazy(() =>
  import('./PublicPages/SupersaverLandingPage')
);

const OnboardingCompleted = React.lazy(() =>
  import('./Supplier/Onboarding/Completed')
);
const StockInfoProvider = React.lazy(() => import('context/StockInfoContext'));

const RequestReset = React.lazy(() =>
  import('./App/ForgotPassword/RequestReset')
);
const ChoosePassword = React.lazy(() =>
  import('./App/ForgotPassword/ChoosePassword')
);
const SupplierTandC = React.lazy(() => import('./PublicPages/SupplierTandC'));
const StockInfo = React.lazy(() =>
  import('./Supplier/Onboarding/StockDetails/StockInfo')
);
const StockAPI = React.lazy(() =>
  import('./Supplier/Onboarding/StockDetails/StockAPI')
);
const StockFTP = React.lazy(() =>
  import('./Supplier/Onboarding/StockDetails/StockFTP')
);
const DirectUpload = React.lazy(() =>
  import('./Supplier/Onboarding/StockDetails/DirectUpload')
);
const IDontKnow = React.lazy(() =>
  import('./Supplier/Onboarding/StockDetails/IDontKnow')
);
// noinspection JSCheckFunctionSignatures
const SetupCompleted = React.lazy(() =>
  import('components/onboarding/shared/SetupCompleted')
);
const SupplyOfGoods = React.lazy(() => import('./PublicPages/SupplyOfGoods'));
const NewDashboard = React.lazy(() => import('./App/CustomerDashboardv2'));
// noinspection JSCheckFunctionSignatures
const AdminDashboardPage = React.lazy(() => import('./Admin'));

// noinspection JSCheckFunctionSignatures
const SupplierDashboard = React.lazy(() => import('./Supplier/Dashboard'));
const Login = React.lazy(() => import('./App/Login'));
initRum();

smoothscroll.polyfill();

function get_ip() {
  return axios
    .get('https://api.ipify.org?format=json')
    .then((response) => response.data.ip);
}

if (process.env.NODE_ENV === 'development') {
  window.__APOLLO_CLIENT__ = graphqlClient;
}

const App = (props) => {
  const session = useReactiveVar(userSession);
  const [hubspotState, updateHubspotState] = useHubspotChatWidgetUpdates();
  const rxCtx = useReactiveVar(featureFlagsRx);

  return (
    <NivodaWeb
      hubspotState={hubspotState}
      updateHubspotState={updateHubspotState}
      rxCtx={rxCtx}
      session={session}
      {...props}
    />
  );
};

class NivodaWeb extends React.Component {
  isProduction = process.env.NODE_ENV === 'production';
  componentDidUpdate(prevProps, _, _1) {
    // Update localstorage with the value in state here
    // NOTE ONLY PLACE WHERE `graphql_session` SHOULD BE SET!
    if (prevProps.session !== this.props.session) {
      // set the session on apollo context
      localStorage.setItem(
        'graphql_session',
        JSON.stringify(this.props.session)
      );
      initZendesk(this.props.session);
      initHubspotIntercom({
        user: this.props.session ? this.props.session.user : null,
        hubspot_token: this.props?.session?.hubspot_token,
        hubspotState: this.props.hubspotState,
        updateHubspotSettings: this.updateHubspotSettings,
      });
    }
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const utm_source = searchParams.get('utm_source');
    const utm_medium = searchParams.get('utm_medium');
    const utm_campaign = searchParams.get('utm_campaign');
    const utm_term = searchParams.get('utm_term');
    const utm_content = searchParams.get('utm_content');
    if (utm_source && !localStorage.getItem('utm_source')) {
      localStorage.setItem('utm_source', JSON.stringify(utm_source));
    }
    if (utm_medium && !localStorage.getItem('utm_medium')) {
      localStorage.setItem('utm_medium', JSON.stringify(utm_medium));
    }
    if (utm_campaign && !localStorage.getItem('utm_campaign')) {
      localStorage.setItem('utm_campaign', JSON.stringify(utm_campaign));
    }
    if (utm_term && !localStorage.getItem('utm_term')) {
      localStorage.setItem('utm_term', JSON.stringify(utm_term));
    }
    if (utm_content && !localStorage.getItem('utm_content')) {
      localStorage.setItem('utm_content', JSON.stringify(utm_content));
    }

    // console.log({utm_source});
    if (localStorage.getItem('_ID_check') === null) {
      let mask_ip = (ip) => {
        let ip_arr = ip?.split('.') || [1, 1, 1, 1];
        return ip_arr.map((r, i) => (r * (i + 3)) ** 2).join(':');
      };
      get_ip().then((ip) => {
        localStorage.setItem('_ID_check', mask_ip(ip));
      });
    }

    // This block runs only once
    // disabled hubspot tracking
    // addHubspotScript();
    initZendesk(this.props.session);
    initHubspotIntercom({
      user: this.props.session ? this.props.session.user : null,
      hubspot_token: this.props?.session?.hubspot_token,
      hubspotState: this.props.hubspotState,
      updateHubspotSettings: this.updateHubspotSettings,
    });
    // setTimeout(() => {
    //   this.add_mutation_observer();
    // }, 5000);

    Track.track('Website opened');
  }

  componentDidCatch(err, ...props) {
    // eslint-disable-next-line no-console
    console.log(err);
    if (err.message.includes('NO_USER')) {
      window.location.href = '/login';
    } else {
      throw err;
    }
  }

  updateHubspotSettings = (settings) => {
    this.props.updateHubspotState({
      ...this.props.hubspotState,
      ...settings,
    });
  };

  render() {
    const { rxCtx, session } = this.props;
    let utm_referral_value = window.location.search
      ? window.location.search.split('utm_referral=')[1]
        ? window.location.search.split('utm_referral=')[1].split('&')[0]
        : null
      : null;

    if (utm_referral_value) {
      set_cookie('utm_referral', utm_referral_value);
    }

    let queryClient = new QueryClient();

    if (appEnv.isProd) {
      if (!process.env.REACT_APP_BASE_GRAPHQL_URI) {
        return (
          <div style={{ backgroundColor: 'red', padding: 30 }}>
            <div style={{ padding: 10 }}>
              '{process.env.REACT_APP_BASE_GRAPHQL_URI}' environment variable
              (on heroku) should be set while running in production.
            </div>
          </div>
        );
      }
    }
    const isSSUser = config.supersaverConfig.isSSUser(
      session ? session.user : null
    );

    // Pass the client instance down to children
    // this well allow us to share the same cache as main Provider
    const clients = {
      adminGraphqlClient,
      graphqlClient,
      supplierGraphQlClient,
      queryClient,
    };

    // ApolloProvider (will rename maybe) and AdminGraphqlProvider
    // both need to be present here
    // noinspection RequiredAttributes
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <CacheBuster
        currentVersion={version}
        isEnabled={this.isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        loadingComponent={<span></span>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <Providers clients={clients}>
          <Helmet defaultTitle="Nivoda" titleTemplate="%s - Nivoda" />
          <React.Fragment>
            <GatewayDest name="viewport" />
            <ReactHotToast
              position="top-right"
              reverseOrder={false}
              toastOptions={{
                className: 'notification-wrapper',
                style: {
                  display: 'flex',
                  gap: 10,
                  maxWidth: 400,
                  minWidth: 250,
                  padding: '6px',
                },
                success: {
                  style: {
                    background: '#F0FDF4',
                  },
                },
                error: {
                  style: {
                    maxWidth: 500,
                    background: '#FEF2F2',
                  },
                },
              }}
              gutter={8}
            />
            <Router className="app-main-router" style={{ height: '100%' }}>
              <Login
                path="/"
                setSession={userSession}
                // intercomSettings={intercomSettings}
              />
              <HealthCheck path="/health_check" />

              <SavingsCalculator path="/savings-calculator" />
              <PluginDownload path="/suppliers/excel-plugin-download" />
              <SupplierTandC path="/supplier-terms-and-conditions" />
              <SupplyOfGoods
                market_pay_active={!!session?.user?.company?.market_pay_active}
                path="/supply-of-goods-terms-and-conditions"
              />

              <SuperSaverCampaign path="/free-shipping/*" />
              <Login path="/login" setSession={userSession} />
              <StockInfoProvider path="/suppliers">
                <StockInfo
                  session={session}
                  setSession={userSession}
                  path="/stock-info"
                />
                <Terms path="/terms" />
                <StockAPI path="/api" />
                <StockFTP path="/ftp" />
                <IDontKnow path="/unknown" />
                <DirectUpload
                  path="/direct"
                  isSSUser={isSSUser}
                  session={session}
                  setSession={userSession}
                />
                <OnboardingCompleted path="/complete" />
              </StockInfoProvider>

              <RenderFunction
                path="/logout"
                render={() => {
                  // Clears outs the reactive variables value from in-memory cache
                  userSession(null);
                  clearCurrentSession();
                  // Reset feature flagging storage
                  rxCtx.resetFFStorage();
                  // Reset feature flag retry counter on logout
                  localStorage.setItem('ffLog', null);
                  dashboardModeStateRx({
                    isMemoMode: false,
                  });
                  return <Redirect to="/login" noThrow={true} />;
                }}
              />
              <NewOnBoardingPage
                path="/register/*"
                apollo={graphqlClient}
                setSession={userSession}
              />
              <SetupCompleted path="/setup-completed" />
              <RequestReset path="/forgot-password" />
              <ChoosePassword path="/reset-password/:email/:code" />
              {/* <PublicQuotePage path="/quote/:id" /> */}

              <AdminDashboard
                isSSUser={isSSUser}
                path="/admin/*"
                session={session}
                clients={clients}
                setSession={userSession}
              />
              <SupplierDashboard
                clients={clients}
                isSSUser={isSSUser}
                path="/supplier/*"
                session={session}
                setSession={userSession}
              />

              <SetInvitesPassword path="/invite/:token" />
              <RequireAuth
                path="/v2/live/*"
                session={session}
                setSession={userSession}
                location={this.props.location}
                clients={clients}
                isSSUser={isSSUser}
              />

              <Redirect
                noThrow={true}
                from="/login/invite/order/:couponid"
                to="/invite/order/:couponid"
              />
              <Redirect noThrow from="/invoices" to="/v2/live/invoices" />
              <BuyerSupersaverLanding
                path="/invite/order/:couponid"
                setSession={userSession}
              />

              <PageNotFound default isPublic onReport={noop} />
            </Router>
            <Zendesk />
          </React.Fragment>
          {/* <LocationReminder /> */}
        </Providers>
      </CacheBuster>
    );
  }
}

const HealthCheck = () => {
  return (
    <div>
      <h3>Hey There!!! The App is Healthy</h3>
    </div>
  );
};
export default App;

const RequireAuth = ({ session, setSession, location, clients, isSSUser }) => {
  if (!session) {
    return <Login setSession={setSession} location={location} />;
  }
  return (
    <NewDashboard
      session={session}
      // path="/"
      setSession={userSession}
      clients={clients}
      isSSUser={isSSUser}
    />
  );
  // return (
  //   <React.Fragment style={{ height: 'inherit' }}>{children}</React.Fragment>
  // );
};
const AdminDashboard = ({
  session,
  setSession,
  location,
  clients,
  isSSUser,
}) => {
  if (!session) {
    return <Login setSession={setSession} location={location} />;
  }
  return (
    <AdminDashboardPage
      session={session}
      setSession={userSession}
      clients={clients}
      isSSUser={isSSUser}
    />
  );
};
