import { gql } from '@apollo/client';
import { GraphqlQuery } from 'graphql/factory';
import { MemoWalletItemFragment } from 'graphql/fragments';

export const MEMO_WALLET_ITEMS_QUERY = gql`
  query fetchMemoWalletItems {
    me {
      id
      steps_required
      memo_wallet_items {
        ...MemoWalletItemFragment
      }
    }
  }
  ${MemoWalletItemFragment}
`;

export let GetMemoWalletItemsWithOffers = GraphqlQuery({
  query: MEMO_WALLET_ITEMS_QUERY,
  map_data: (results) => {
    let { me } = results;
    const memoWalletItems =
      me?.memo_wallet_items ??
      []
        ?.filter((item) => {
          return item.offer != null;
        })
        .map((item) => {
          if (item.offer.ProductType === 'Gemstone') {
            return {
              ...item,
              offer: {
                ...item.offer,
                Product: {
                  ...item.offer.Product,
                  certificate: item.offer.Product.GemstoneCertificate,
                },
              },
            };
          }
          return item;
        });
    return {
      ...results,
      me: Object.assign({}, me, { memo_wallet_items: memoWalletItems }),
    };
  },
});
