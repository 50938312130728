import gql from 'graphql-tag';
import { GraphqlFactory } from 'graphql/factory';
import { GET_QUERY } from '../../fragments';
import { useQuery } from '@apollo/client';

// customer get location
export const GetLocations = GraphqlFactory({
  query: GET_QUERY,
  map_data: ({ me, ...rest }) => {
    return {
      me: me,
      locations: me ? me.locations : [],
      ...rest,
    };
  },
});

export const useLocations = () => useQuery(GET_QUERY);

export const GET_CUSTOMER_NIVODA_OFFICE_LOCATIONS = gql`
  query fetchNivodaOfficeLocations {
    customer_get_nivoda_locations {
      id
      address1
      address2
      city
      country
      state
      postalCode
      opening_hours {
        Monday {
          opening_time
          closing_time
        }
        Tuesday {
          opening_time
          closing_time
        }
        Wednesday {
          opening_time
          closing_time
        }
        Thursday {
          opening_time
          closing_time
        }
        Friday {
          opening_time
          closing_time
        }
      }
    }
  }
`;
