// noinspection GraphQLUnresolvedReference

import { gql } from '@apollo/client';
import { MeleeOfferListFragment } from '../../fragments/OfferFragment';

export const GET_MELEE_OFFERS_QUERY_STORE = gql`
  query getMeleeOffersQueryStore($input: MeleeSearchQuery) {
    meleeOffersQueryStore(input: $input)
  }
`;
export const FETCH_MELEE = gql`
  query getMeleeOfferList(
    $query: MeleeSearchQuery
    $offset: Int
    $order: MeleeOrder
  ) {
    melees: melee_offers_by_query(
      limit: 24
      offset: $offset
      query: $query
      order: $order
    ) @connection(key: "melee_offers_by_query", filter: ["query", "order"]) {
      ...MeleeOfferListFragment
    }
  }
  ${MeleeOfferListFragment}
`;

export const FETCH_MELEE_LISTING_COUNT = gql`
  query fetchMeleeListingCount(
    $query: MeleeSearchQuery
    $offset: Int
    $order: MeleeOrder
  ) {
    melee_offers_by_query_count(
      limit: 24
      offset: $offset
      query: $query
      order: $order
    )
  }
`;

export const GET_MELEE_CNP_STORE = gql`
  query GetMeleeCaratNPiecesStore($input: MeleeCaratNPiecesInput) {
    meleeCaratNPiecesStore @client(input: $input)
  }
`;

export const FETCH_MELEE_OFFER_ITEMS = gql`
  query getMeleeItemDetailsFromOrder(
    $query: MeleeSearchQuery
    $offset: Int
    $order: MeleeOrder
  ) {
    melees: melee_offers_by_query(
      offset: $offset
      query: $query
      order: $order
    ) {
      items {
        id
        ProductType
        Product {
          ... on Melee {
            id
            carats
            pieces
            carats_ordered
            supplierStockId
            type
            # This data is required as Delivery time doesn't have any ID viz required
            # by apollo to handle cache properly
            delivery_time {
              express_timeline_applicable
              min_business_days
              max_business_days
            }
          }
        }
      }
      total_count
    }
  }
`;
