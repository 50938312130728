import { getPlaceholderItems } from '@nivoda/ui';
import { OrderItemStatus } from '../constants';
import { capitalize } from 'lodash/string';
import React from 'react';
import styled from 'styled-components';
import { roundSkeletonStyles } from '../fabric/styles';
import theme from '../fabric/theme';

export function getOrdersDataWithLoadingMeta({
  columns = [],
  list,
  loading = false,
  status,
  orderType,
}) {
  const items = loading
    ? getPlaceholderItems(
        columns.map(({ id }) => id),
        15
      )
    : list?.items;

  const getOrderCount = (list, orderType) => {
    let currentTab = 'all';
    if (orderType === 'NIVODA_EXPRESS') currentTab = 'nivodaExpress';
    if (orderType === 'REGULAR') currentTab = 'regular';
    if (loading)
      return {
        all: '-',
        regular: '-',
        nivodaExpress: '-',
      };
    return {
      all: list?.total_all_stones ?? '-',
      regular: list?.total_regular_stones ?? '-',
      nivodaExpress: list?.total_express_stones ?? '-',
      [currentTab]: list?.total_count,
    };
  };

  const heading = loading
    ? status === OrderItemStatus.READY_TO_QC
      ? 'QC'
      : capitalize(status.replace(/_/g, ' '))
    : status === OrderItemStatus.READY_TO_QC
    ? `QC (${list.total_count})`
    : capitalize(`${status.replace(/_/g, ' ')} (${list.total_count})`);

  const tabHeading = loading ? (
    <Span className={'page_heading'}>
      {heading} <span className={'skeleton skeleton__page_heading'} />
    </Span>
  ) : (
    heading
  );
  return {
    items,
    heading,
    tabHeading,
    orderCount: getOrderCount(list, orderType),
  };
}

const Span = styled.span`
  &.page_heading {
    display: inline-flex;
    align-items: center;
    gap: ${theme.spacing.s1};

    .skeleton.skeleton__page_heading {
      ${roundSkeletonStyles('15px')};
      margin-bottom: -2px;
    }
  }
`;
