import { func, object } from 'prop-types';
import { Icon as Icn } from 'fabric/resources/icon';
import styled from 'styled-components';
import { appEnv, isMobile } from '../../../utils';
import theme from '../../theme';
import { config } from '../../config';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Button } from 'fabric/components';
import { useToggleZendeskHubspot } from 'App/Zendesk/Zendesk';

const LiveChatBtn = styled.div`
  border-radius: 4px;
  height: 40px;
  background: ${theme.palette.themePrimary};
  border: 1px solid ${theme.palette.themePrimary};

  &:hover {
    background: ${theme.palette.brandBlue4};
    border: 1px solid ${theme.palette.brandBlue4};
  }
`;

LiveChat.propTypes = {
  onClick: func.isRequired,
  style: object,
};
let is_tablet = window.screen.width <= 768;

export function LiveChat({ onClick, ...props }) {
  const { t } = useTranslation();
  const [toggleSupportWidget] = useToggleZendeskHubspot();
  let onChatBtnClick = () => {
    if (typeof onClick === 'function') return onClick();
    toggleSupportWidget();
  };
  return (
    <>
      <LiveChatBtn
        style={{
          padding: '0px 8px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          fontWeight: '500',
          color: theme.palette.gray2,
          gap: '0',
          ...(props.style || {}),
        }}
        className="cws_intercom chat-with-us"
        onClick={onChatBtnClick}
      >
        {!is_tablet && (
          <div
            className="cws_intercom__text"
            style={{
              color: theme.palette.white,
              fontWeight: 700,
              fontSize: '12px',
              marginLeft: 0,
              marginRight: 6,
            }}
          >
            {t('live_chat')}
          </div>
        )}
        <Icn
          name="support_v3"
          width={16}
          height={17}
          color={theme.palette.white}
        />
      </LiveChatBtn>
      {/* {is_tablet && (
        <Icn
          name="support_v3"
          width={16}
          height={17}
          color={theme.palette.white}
          onClick={props.onClick}
        />
      )} */}
    </>
  );
}

const HelpContainer = styled.div`
  &.need__help {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
    }

    .highlighted--link {
      ${theme.fonts.absolute.mediumBold};
      color: ${theme.palette.themePrimary};
      margin-left: ${theme.spacing.s1};
      cursor: pointer;
      :hover {
        color: ${theme.semanticColors.signupPrimaryHoverBg} !important;
      }
    }
  }
`;
const BtnWrapper = styled.div`
  .mini-help_btn,
  .mini-help_btn:hover {
    margin: 2px !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    border-radius: 25px;
  }
`;
export const NeedHelp = ({
  className = '',
  iconProps = {},
  toggleIntercom,
  is_button,
}) => {
  const { t } = useTranslation();
  const [toggleSupportWidget] = useToggleZendeskHubspot();

  const handleOnClick = () => {
    if (typeof toggleIntercom === 'function') return toggleIntercom();
    toggleSupportWidget();
  };
  return (
    <>
      {is_button ? (
        <BtnWrapper>
          <Button className="mini-help_btn" onClick={handleOnClick}>
            Help
          </Button>
        </BtnWrapper>
      ) : (
        <HelpContainer
          className={`need__help ${className}`}
          onClick={handleOnClick}
          title={`${t('need_help')} ${t('chat_with_us_now')}`}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <span
            style={{
              ...theme.fonts.largeBold,
              fontFamily: 'Inter',
              color: isMobile()
                ? theme.palette.white0
                : theme.palette.Neutrals950,
              lineHeight: '24px',
            }}
          >{`${t('need_help')}`}</span>
          {!isMobile() && (
            <span
              className="highlight highlighted--link"
              tabIndex="0"
              role="button"
              style={{
                ...theme.fonts.largeBold,
                fontFamily: 'Inter',
                color: theme.palette.Neutrals950,
                lineHeight: '24px',
                paddingRight: 8,
              }}
            >
              {`${t('chat_with_us_now')}`}
            </span>
          )}
          <Icn
            name="headset"
            size={18}
            color={theme.palette.Neutrals950}
            {...iconProps}
          />
        </HelpContainer>
      )}
    </>
  );
};

export const initHubspotIntercom = ({
  user = null,
  hubspot_token = null,
  hubspotState = {},
  updateHubspotSettings = () => {},
}) => {
  if (appEnv.isProd || config.integration.intercomSettings.enableOnDevENV) {
    window.hsConversationsSettings = {
      loadImmediately: false,
      ...(user?.email && {
        identificationEmail: user?.email,
      }),
      ...(hubspot_token && {
        identificationToken: hubspot_token,
      }),
    };
    // noinspection JSUnresolvedReference
    let settings = window.HubSpotConversations || hubspotState?.settings;
    const isAdminPath = window.location.pathname.includes('/admin/');

    if (!isEmpty(settings)) {
      if (isAdminPath) {
        // noinspection JSUnresolvedReference
        settings?.widget?.remove();
        return;
      }
      // noinspection JSUnresolvedReference
      settings?.resetAndReloadWidget();
      // noinspection JSUnresolvedReference
      updateHubspotSettings({
        settings: window.HubSpotConversations,
      });
    } else {
      if (isAdminPath) {
        return;
      }
      window.hsConversationsOnReady = [
        () => {
          // noinspection JSUnresolvedReference
          window.HubSpotConversations.widget.load();

          // noinspection JSUnresolvedReference
          updateHubspotSettings({
            // Keep the widget settings in memory to call the intercom methods
            settings: window.HubSpotConversations,
          });
        },
      ];
    }
  }
};

export const addHubspotScript = () => {
  const { intercomSettings } = config.integration;
  if (appEnv.isProd || intercomSettings.enableOnDevENV) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${intercomSettings.HUBSPOT_PORTAL_ID}.js`;
    document.body.appendChild(script);
  }
};
