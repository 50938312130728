import React from 'react';
import { Permission } from 'Admin/helper/PermissionHelpers';
import { appEnv } from '../utils';

const PermissionContext = React.createContext({
  scopes: [],
  set_scopes: () => {},
});

export let PermissionProvider = ({ children }) => {
  const local_scopes = localStorage.getItem('permissions');
  const [scopes, set_scopes] = React.useState(
    appEnv.isDev ? Permission.SUPERADMIN : local_scopes || []
  );

  // noinspection JSValidateTypes
  return (
    <PermissionContext.Provider value={{ scopes, set_scopes }}>
      {children}
    </PermissionContext.Provider>
  );
};

export let usePermissions = () => {
  return React.useContext(PermissionContext);
};
export let PermissionConsumer = ({ children }) => {
  let props = usePermissions();
  return children(props);
};

export let IsPermitted = ({ permissions = [], customMsg, children }) => {
  let { scopes = [] } = usePermissions();

  let handle_permits = (permissions) => {
    let hasPermission =
      permissions.length === 0 || permissions?.some((p) => scopes?.includes(p));
    // if (!hasPermission && permissions.length > 0) {
    //   // eslint-disable-next-line no-console
    //   console.info('%c Denied => ', 'color: #bada55', permissions.join(''));
    // }
    return hasPermission ? children : customMsg ? customMsg : null;
  };
  return (
    <React.Fragment>
      {typeof permissions === 'string'
        ? handle_permits([permissions.trim()])
        : handle_permits(permissions)}
    </React.Fragment>
  );
};
