import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';
import { HoldFragment } from '../../fragments';
import { cacheUpdater } from 'common/Holds/RequestHoldButton/cache';

// Allows customer to cancel hold
export const CANCEL_HOLD_REQUEST = gql`
  mutation ($hold_id: ID!) {
    cancel_hold(hold_id: $hold_id) {
      ...HoldItemFragment
    }
  }
  ${HoldFragment}
`;

// Allows customer to create hold
export const CREATE_HOLD_REQUEST = gql`
  mutation (
    $ProductId: ID!
    $ProductType: ProductType!
    $until: String
    $customer_id: ID
  ) {
    create_hold(
      ProductId: $ProductId
      ProductType: $ProductType
      until: $until
      customer_id: $customer_id
    ) {
      ...HoldItemFragment
    }
  }
  ${HoldFragment}
`;

// Allows customers to request extension of hold with supplier
export const REQUEST_HOLD_EXTENSION = gql`
  mutation ($extension_details: [HoldExtensionInput!]!) {
    request_hold_extension(extension_details: $extension_details) {
      ...HoldItemFragment
    }
  }
  ${HoldFragment}
`;

export const UpdateInternalRefForHold = GraphqlMutation({
  query: gql`
    mutation UpdateInternalRefForHold(
      $hold_id: ID!
      $customer_reference: String!
    ) {
      update_hold_customer_reference(
        hold_id: $hold_id
        customer_reference: $customer_reference
      ) {
        ...HoldItemFragment
      }
    }
    ${HoldFragment}
  `,
});

export let CancelHoldRequest = GraphqlMutation({
  query: CANCEL_HOLD_REQUEST,
  update: cacheUpdater.bind(null, 'cancel_hold'),
});

export let CreateHoldRequest = GraphqlMutation({
  query: CREATE_HOLD_REQUEST,
  update: cacheUpdater.bind(null, 'create_hold'),
});

export let RequestHoldExtension = GraphqlMutation({
  query: REQUEST_HOLD_EXTENSION,
});
