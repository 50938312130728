export const InvoiceTabIDs = {
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  TO_INVOICE: 'TO_INVOICE',
  INV_TO_SEND: 'INV_TO_SEND',
  UPLOAD_INVOICE: 'UPLOAD_INVOICE',
  SUPPLIERS_TO_PAY_INV: 'SUPPLIERS_TO_PAY_INV',
  SUPPLIERS_TO_PAY_PO: 'SUPPLIERS_TO_PAY_PO',
  PAYMENT_HISTORY: 'PAYMENT_HISTORY',
  AIRWALLEX_HISTORY: 'AIRWALLEX_HISTORY',
  SUPPLIER_RETURNS: 'SUPPLIER_RETURNS',
  CUSTOMER_RETURNS: 'CUSTOMER_RETURNS',
};

export const InvoicesQueryTypes = {
  SUPPLIER: 'supplier',
  COLLECTION_DATE: 'collection_date',
  PAYMENT_DATE: 'payment_date',
  UPLOAD_PDF: 'upload_pdf',
  ORDER_DATE: 'order_date',
};
