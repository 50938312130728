import theme from 'fabric/theme';
import styled from 'styled-components';

export const MultiSearchBoxWrp = styled.div`
  width: 300px;
  .multi-search-input {
    width: 100%;
    padding-left: 0;
    .searchbar_input_text {
      padding-left: ${theme.spacing.s9};
      padding-right: ${theme.spacing.s5};
      padding-top: 0;
      border-radius: 4px;
      border: ${theme.semanticProps.borderGrey3};
      background-size: 18px;
      margin: 0 !important;
      width: 100% !important;
      box-shadow: none;
      padding-bottom: ${theme.spacing.px};
    }
    & ._clear_icon {
      ${({ closeIconPosition }) =>
        `
        top: ${closeIconPosition?.top ?? '5px'};
        right: ${closeIconPosition?.right ?? '10px'};
      `}
      font-size: 22px;
      font-weight: 600;
    }
  }
  & .msw_ {
    &menu {
      position: relative;
      width: 100%;
      #paper_box {
        min-width: 100%;
        & .content_wrapper__box {
          padding: 0;
          display: grid;
          ${theme.fonts.mediumPlus};
          color: ${theme.palette.offBlack4};
          font-family: 'Inter';
        }
      }
      &_content {
        border-bottom: ${theme.semanticProps.borderGray4};
        padding: ${theme.spacing.s2};
        cursor: pointer;
      }
    }
  }
`;
