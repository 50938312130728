import { gql } from '@apollo/client';
import { CertificateFragment } from './CertificateFragment';
import { GemstoneCertificate } from './GemstoneOfferFragment';
import { MeleeFragment } from './MeleeFragment';

export const InvoiceOrderItemFragment = gql`
  fragment InvoiceOrderItem on OrderByStatusList {
    total_count
    items {
      id
      supplier_payment {
        payment_date
      }
      Product {
        ... on Diamond {
          id
          supplier {
            id
            name
          }
          supplierStockId
          certificate {
            ...CertificateFragment
          }
        }
        ... on Melee {
          id
          ...MeleeFragment
        }
        ... on Gemstone {
          id
          supplier {
            id
            name
          }
          supplierStockId
          GemstoneCertificate: certificate {
            ...GemstoneCertificate
          }
        }
      }
      ProductType
      invoice {
        id
        currency
        exchange_rate
        invoice_number
        usd_amount
      }
      carats
      pieces
      order_number
      rupee_rate
      collectedDate
      order {
        id
        order_date
      }
      buy_price
    }
  }
  ${CertificateFragment}
  ${GemstoneCertificate}
  ${MeleeFragment}
`;
