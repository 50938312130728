import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { Button } from '@nivoda/ui';

import { appEnv } from 'utils';
import { get_current_session } from 'graphql/factory';
import primaryLogo from 'fabric/resources/svg/nivoda-icon/logo-blue.svg';
import theme from '../theme';
import { livePath } from 'helpers';

// Initialize sentry only on production
if (appEnv.isProd && !appEnv.isStaging) {
  // eslint-disable-next-line no-console
  console.log("Nivoda ♥'s web!");
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
    /**
     * Note: Disable Sentry performance tracing until we figure out the proper
     * configuration that we want to track. See following url for more details
     * https://docs.sentry.io/platforms/javascript/guides/react/performance/
     */

    // integrations: [new BrowserTracing()],
    ignoreErrors: [
      // Add the title of error type. See following examples
      // "jigsaw is not defined",
      // "Can't find variable: eReader",
    ],
    release: '@nivoda/web@0.1.0',

    // tracesSampleRate 1.0 will capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  });
}

export const SentryGlobalErrorBoundary = (props) => {
  const [error, setError] = useState(null);

  const session = get_current_session();
  const isActiveSession =
    session && session.token && session.expires > Date.now();

  useEffect(() => {
    Sentry.setExtra('appID', '@nivoda/web@0.1.0');

    if (session && session?.user?.firstName) {
      Sentry.setTag('u.id', session.user.id);
      Sentry.setTag('cmp.id', session.user.company.id);
      Sentry.setTag('cnt.id', session.user.country);
      Sentry.setTag('r.id', session.user.role[0]);
    }

    //  The popstate event is only triggered by performing a browser action,
    //  such as clicking on the back button (or calling history.back() in JavaScript),
    //  when navigating between two history entries for the same document.
    window.onpopstate = () => {
      if (error != null) {
        window.location.reload();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Sentry.setTag('tagID', 'web@error');
  }, [error]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => (
        <div
          style={{
            maxWidth: 600,
            padding: 20,
            fontSize: 18,
            margin: 'auto',
          }}
        >
          <div style={{ height: 20 }} />
          <img
            className="blue-logo"
            alt="Nivoda blue logo"
            src={primaryLogo}
            style={{ display: 'block' }}
          />
          <div style={{ height: 20 }} />
          <div>Something went wrong.</div>
          <div style={{ height: 20 }} />
          <div>
            To prevent this from happening again, if you have the time we'd
            really appreciate it if you could describe what happened before this
            screen appeared.
          </div>
          <div style={{ height: 20 }} />
          <div>
            Thank you!
            <br />
          </div>
          <div style={{ height: 20 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 12,
            }}
          >
            <Button
              customPadding={theme.spacing.s3}
              onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}
              style={{ width: 215 }}
            >
              Provide us some details
            </Button>
            {session && isActiveSession && (
              <Button
                customPadding={theme.spacing.s3}
                onClick={() => {
                  /* When resetError() is called it will remove the Fallback component */
                  /* and render the Sentry ErrorBoundary's children in their initial state */
                  resetError();
                }}
                style={{ width: 215 }}
              >
                Click here to reset the page
              </Button>
            )}
            <Button
              customPadding={theme.spacing.s3}
              onClick={() => {
                if (session && isActiveSession) {
                  const redirectLink = {
                    SUPPLIER: '/supplier/stock/upload',
                    CUSTOMER: `${livePath}/search/natural/diamond`,
                    ADMIN: '/admin/orders/process',
                  };

                  window.location.href =
                    redirectLink[session.user.role] ?? '/login';
                } else {
                  window.location.href = '/login';
                }
              }}
              is_default
              style={{ width: 215 }}
            >
              {session && isActiveSession ? 'Go back to dashboard' : 'Login'}
            </Button>
          </div>
        </div>
      )}
      onError={(error) => setError(error)}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};
