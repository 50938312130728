import React from 'react';
import { any, bool, func, node, object, string } from 'prop-types';
import { Helmet } from 'react-helmet/es/Helmet';
import { BlockHeading, Icon, ReqPopup } from '@nivoda/ui';

import { IconDiv } from 'common/Holds/styles';
import theme from 'fabric/theme';
import { StyledWrapper } from './styles';
import { successTypes } from '../../../constants';
import { isMobile } from 'utils';

export function GenericModal(props) {
  const errorType = props.errorType || successTypes.SUCCESS.toLowerCase();
  return (
    <ReqPopup
      className={props.className}
      active_out_click={props.activeOutClick}
      noMaxHeight={props.noMaxHeight}
      showBorder={false}
      remove_top_margin
      stopPropagation
      title={
        <span
          style={{
            display: 'flex',
            height: theme.spacing.s6,
            marginBottom: theme.spacing.s2,
          }}
        />
      }
      clearModalState={props.clearModalState}
      noBoxShdw
      popupStyle={props.popupStyle}
      hasCloseIcon={props.hasCloseIcon}
      contentStyle={{
        marginTop: isMobile() ? '50%' : '',
        textAlign: isMobile() ? 'center' : 'left',
      }}
    >
      {props.modalTitle && !props.disableHelmet && (
        <Helmet>
          <title>{props.modalTitle}</title>
        </Helmet>
      )}
      <StyledWrapper className={'error_block'} bodyStyle={props.bodyStyle}>
        <IconDiv errorType={errorType} minWidth={props.minWidth}>
          <span className={`svg_icon ${errorType}`}>
            <Icon
              aria-hidden="true"
              color={theme.semanticColors[errorType]}
              name={
                props.successMessage || props.type === successTypes.SUCCESS
                  ? 'check-icon'
                  : 'error_icon'
              }
              size={26}
              {...(props.iconProps || {})}
            />
          </span>
        </IconDiv>
        {props.headingText && (
          <div
            className="_title"
            style={{ textAlign: 'center' }}
            data-automation-id="pop-msg"
          >
            {props.headingText}
          </div>
        )}
        {(props.successMessage || props.errorMessage) && (
          <BlockHeading
            highlight={'transparent'}
            fontNormal
            text={props.successMessage || props.errorMessage}
            title={
              typeof props.successMessage === 'string'
                ? props.successMessage
                : typeof props.errorMessage === 'string'
                ? props.errorMessage
                : ''
            }
          />
        )}
        {typeof props.renderModalBody === 'function' ? (
          <div className={'gi_modal_body'}>{props.renderModalBody()}</div>
        ) : null}
      </StyledWrapper>
    </ReqPopup>
  );
}
GenericModal.defualtProps = {
  activeOutClick: false,
  className: '',
  headingText: '',
  noMaxHeight: false,
  popupStyle: {},
  showBorder: false,
};

GenericModal.propTypes = {
  activeOutClick: bool,
  bodyStyle: any,
  className: string,
  clearModalState: func.isRequired,
  errorType: string,
  errorMessage: string,
  headingText: any,
  modalTitle: string,
  noMaxHeight: bool,
  popupStyle: object,
  removeToMargin: bool,
  renderModalBody: func,
  showBorder: bool,
  successMessage: node,
};
