import { gql } from '@apollo/client';

export const CREATE_SHARE_LIST = gql`
  mutation (
    $share_list_products: [ShareListProductInput]!
    $share_list_params: ShareListConfiguration!
  ) {
    create_share_list(
      share_list_products: $share_list_products
      share_list_params: $share_list_params
    )
  }
`;
