/**
 * Returns plural for supplied string/word e.g. item -> items, diamond -> diamonds
 * @param count {number}
 * @param label {string}
 * @returns {string}
 */
export const getPluralLabel = (count = 0, label = 'result') =>
  count
    ? count === 1
      ? `${count} ${label}`
      : `${count} ${label}s`
    : `${count} ${label}`;
