import { gql } from '@apollo/client';

export const CFMFragments = gql`
  fragment CFMFragments on CFM_Settings {
    Markups {
      from_amount
      to_amount
      percent
      ProductSubtype
      ProductType
      markup_type
    }
    primary_color
    secondary_color
    enabled_diamond_types
    enabled_diamond_params
    tablet_app_params
    enabled_search_result_params
    markup_preference
    markup_currency
    header_background_color
    tab_color
    background_color
    sidebar_background_color
    display_basic_colors
    text_color
    hover_link_color
    cta_button_color
    display_currency
    display_taxes
    display_wizard
    rounded_prices
    display_company_logo
    display_company_name
    display_certificate_badge
    display_certificate_pdf
    language_preference
    font
  }
`;
