import CopyToClipboard from 'NewDashboard/components/copy-to-clipboard';
import React from 'react';
import styled from 'styled-components';
import { CustomTrans } from 'helpers';

let StockWrapper = styled.div`
  display: flex;
  align-items: ${({ view_type }) =>
    view_type === 'box' ? 'flex-start' : 'center'};
  flex-direction: ${({ view_type }) =>
    view_type === 'box' ? 'column' : 'row'};
  width: fit-content;
  gap: ${({ view_type, gap }) => (view_type === 'box' ? 'none' : gap || '8px')};
  .stock_label {
    font-size: ${({ stock_label }) => stock_label.fontSize || '12px'};
    font-weight: ${({ stock_label }) => stock_label.fontWeight || 400};
    line-height: ${({ stock_label }) => stock_label.lineHeight || 'unset'};
    color: ${({ stock_label }) => stock_label.color || '#000'};
  }
  .stock_value_item {
    display: flex;
    align-items: center;
    width: ${({ truncate_width }) => truncate_width || 'fit-content'};
    gap: 8px;

    .truncate-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stock_value {
      font-size: ${({ stock_value }) => stock_value.fontSize || '12px'};
      font-weight: ${({ stock_value }) => stock_value.fontWeight || 400};
      line-height: ${({ stock_value }) => stock_value.lineHeight || 'unset'};
      color: ${({ stock_value }) => stock_value.color || '#000'};
    }
  }
`;
export default function StockWithIcon({
  supplierStockId,
  iconHeight,
  iconWidth,
  label,
  stock_label = {},
  stock_value = {},
  truncate,
  truncate_width = 'fit-content',
  view_type = 'in-line',
  hide_icon = false,
  separator = ':',
}) {
  return (
    <StockWrapper
      stock_label={stock_label}
      stock_value={stock_value}
      truncate_width={truncate_width}
      className="stock_wrapper"
      view_type={view_type}
    >
      {label && (
        <div className="stock_label">
          {typeof label === 'string' ? (
            <CustomTrans text={label} returnPlainText={true} />
          ) : (
            label
          )}
          {separator}
        </div>
      )}
      <div className="stock_value_item">
        <div
          className="stock_value truncate-text"
          data-automation-id="supplier-stock-id"
        >
          {(truncate &&
          supplierStockId?.length > truncate &&
          supplierStockId !== 'no_stock_id'
            ? supplierStockId.slice(0, truncate) + '...'
            : supplierStockId) || ''}
        </div>
        {supplierStockId !== 'no_stock_id' && !hide_icon && (
          <div
            className="icon_wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CopyToClipboard
              iconName={'copyIcon'}
              iconHeight={iconHeight || 12}
              iconWidth={iconWidth || 12}
              text={supplierStockId}
            />
          </div>
        )}
      </div>
    </StockWrapper>
  );
}
