import uniq from 'lodash/uniq';
import React from 'react';

export function useOnSelectChange(
  selectedInvoices,
  setState,
  id,
  returnOriginalItem = false
) {
  return React.useCallback(
    (item, shouldRemove) => {
      if (item) {
        let modifiedSelectedInvoices;
        if (Array.isArray(item)) {
          let itemsIds = item.map((e) => e.id);
          if (!shouldRemove) {
            // add them to the array
            modifiedSelectedInvoices = uniq(selectedInvoices.concat(itemsIds));
          } else {
            //remove them from the array
            modifiedSelectedInvoices = selectedInvoices.filter(
              (el) => !itemsIds.includes(el)
            );
          }
        } else {
          if (selectedInvoices.includes(item.id)) {
            modifiedSelectedInvoices = selectedInvoices.filter(
              (x) => x !== item.id
            );
          } else {
            modifiedSelectedInvoices = [...selectedInvoices, item.id];
          }
        }
        if (returnOriginalItem) {
          setState({
            [id]: modifiedSelectedInvoices,
            item,
            shouldAdd: !shouldRemove,
          });
        } else {
          setState({ [id]: modifiedSelectedInvoices });
        }
      }
    },
    [id, returnOriginalItem, selectedInvoices, setState]
  );
}
