import React from 'react';
import styled from 'styled-components';
import { Ticker } from '@nivoda/ui';
import { Descriptions } from '../../../constants';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
import { tFn } from 'utils';

const NoDiamondWrapper = styled.div`
  &.no-diamonds-match {
    background: ${({ transparentBG }) =>
      transparentBG ? 'transparent' : theme.palette.white};
    background-color: ${({ transparentBG }) =>
      transparentBG ? 'transparent' : theme.palette.white};
    box-shadow: ${({ noBoxShadow }) => (noBoxShadow ? 'none' : '')};
  }

  margin-bottom: ${({ isGlobalSearchModal }) =>
    isGlobalSearchModal ? '0' : '40px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .empty_search_wrapper {
    background-color: ${({ transparentBG }) =>
      transparentBG ? 'transparent' : theme.palette.white};
  }
  .empty_global_search_text_wrapper {
    display: flex;
    width: 380px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .empty_global_search_text_description {
    color: #44403c;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }
  .empty_global_search_description-text {
    color: #44403c;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .empty__cart {
    &_text_description {
      ${theme.fonts.largeBold};
      @media (max-width: 700px) {
        ${theme.fonts.mediumBold};
      }

      &-text {
        ${theme.fonts.medium};
        @media (max-width: 700px) {
          ${theme.fonts.small};
        }
      }

      margin: 10px 0 10px;
    }

    &__secondary_text {
      ${theme.fonts.medium};
      @media (max-width: 700px) {
        ${theme.fonts.small};
      }
    }

    &_wrapper {
      text-align: center;
      margin: 0 auto;
    }
  }

  .empty_cart_wrapper .img-wrap {
    margin: 0 auto;
  }
`;

export const EmptySearchResult = ({
  className,
  type,
  containerStyle,
  is_grid,
  children,
  showTicker,
  closeTicker,
  message = null,
  errorIcon = null,
  noBoxShadow = false,
  transparentBG = false,
  isCategorySearch = false,
  view = '',
  isGlobalSearchModal = false,
  isUseDefault = true,
  isHeaderText = true,
  isSecondaryText = true,
}) => {
  const { t } = useTranslation();
  const defaultError = () => (
    <div style={{ height: 150, width: 150 }} className="img-wrap">
      <img
        src={`/img/empty-search.png`}
        alt="diamond_blue"
        style={{ width: '100%' }}
      />
    </div>
  );

  return (
    <React.Fragment>
      <NoDiamondWrapper
        noBoxShadow={noBoxShadow}
        transparentBG={transparentBG}
        className={`no-diamonds-match ${className || ''} ${view}`}
        style={containerStyle}
        isGlobalSearchModal={isGlobalSearchModal}
      >
        {/* Remove this */}
        {showTicker && (
          <Ticker
            text={`You will be notified once the stone is available for purchase.`}
            closeTicker={() => closeTicker()}
          />
        )}
        <div className="no-diamonds-match-grid">
          <div className={`empty_cart_wrapper empty_search_wrapper ${view}`}>
            {/* if error icon is provided use that, else keep using the old one */}
            {!errorIcon ? defaultError() : errorIcon}
            <div
              className={
                isGlobalSearchModal
                  ? 'empty_global_search_text_wrapper'
                  : 'empty_cart_text_wrapper'
              }
            >
              {isHeaderText && (
                <div
                  className={
                    isGlobalSearchModal
                      ? 'empty_global_search_text_description'
                      : 'empty__cart_text_description'
                  }
                >
                  {isUseDefault
                    ? tFn(t, Descriptions(type, t).headerText)
                    : message?.headerText}
                </div>
              )}
              <div
                className={
                  isGlobalSearchModal
                    ? 'empty_global_search_description-text'
                    : 'empty__cart_text_description-text'
                }
              >
                {isUseDefault
                  ? tFn(
                      t,
                      message?.primaryText || Descriptions(type, t).primaryText
                    )
                  : message?.primaryText}
              </div>
              {isSecondaryText && (
                <div
                  data-automation-id="not-available-text"
                  className="empty_global_search"
                  style={{
                    margin: isGlobalSearchModal
                      ? 'empty_global_search'
                      : '5px 0px',
                  }}
                >
                  {isUseDefault
                    ? tFn(
                        t,
                        message?.secondaryText ||
                          Descriptions(type, t).secondaryText
                      )
                    : message?.secondaryText}
                </div>
              )}
            </div>
            {children}
          </div>
        </div>
      </NoDiamondWrapper>
      {!is_grid && !isCategorySearch && !isGlobalSearchModal && (
        <div className="empty__spacer-y" style={{ height: 300 }} />
      )}
    </React.Fragment>
  );
};
