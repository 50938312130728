import React, { Fragment } from 'react';
import { LoadingOverlay } from './index.styles';

export let LoadingPage = ({ children, loading }) => {
  return (
    <Fragment>
      {children}
      <LoadingOverlay active={loading}>
        <img
          className="fade-in"
          data-automation-id="loading-overlay"
          alt="loading"
          style={{ width: 120, height: 60 }}
          src={'/img/logo-isotope.png'}
        />
      </LoadingOverlay>
    </Fragment>
  );
};
