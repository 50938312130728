/**
 * LocalStorage helper
 * @param key
 * @param default_value
 * @returns {{set: function(value:any):void, get: ((function(): (null|undefined))|*)}}
 * @constructor
 */
export let LocalstorageValue = ({ key, default_value = null }) => {
  return {
    get: () => {
      let result = localStorage.getItem(key);
      if (result == null) {
        return default_value;
      }
      try {
        return JSON.parse(result);
      } catch (err) {
        localStorage.removeItem(key);
        return default_value;
      }
    },
    set: (value) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
  };
};

export const getExpressTimelineBasedOnUserCountry = (
  expressTimeline = [],
  userCountry,
  countryOfIncorporation
) => {
  userCountry = userCountry === 'EU' ? 'GB' : userCountry;
  const deliveryTimeline = userCountry
    ? expressTimeline?.find(
        (timeline) =>
          timeline?.origin_country === userCountry &&
          timeline?.destination_country === userCountry
      )
    : null;
  if (deliveryTimeline) {
    return {
      minDeliveryDays: deliveryTimeline?.min_delivery_days,
      maxDeliveryDays: deliveryTimeline?.max_delivery_days,
    };
  } else {
    const countryOfIncorporationTimeline = expressTimeline?.find(
      (timeline) =>
        timeline?.origin_country === countryOfIncorporation &&
        timeline?.destination_country === countryOfIncorporation
    );
    if (countryOfIncorporationTimeline) {
      return {
        minDeliveryDays: countryOfIncorporationTimeline?.min_delivery_days,
        maxDeliveryDays: countryOfIncorporationTimeline?.max_delivery_days,
      };
    }
    return {
      minDeliveryDays: 4,
      maxDeliveryDays: 5,
    };
  }
};
