import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import DiamondImage from 'diamond-image/DiamondImage';
import { PageHeading, ReqPopup } from '@nivoda/ui';
import { AWS360 } from 'diamond-image/AWS360';
import theme from 'fabric/theme';

const hasAsset = (assets, _type) =>
  !!assets.find((asset) => asset.type === _type);

export const MediaPopup = (props) => {
  const { product, onClose } = props;
  const certificate = product.certificate;
  const media = [
    certificate.v360 && {
      diamond: product,
      type: 'v360',
      url: certificate.v360,
      imageUrl: certificate.image,
    },
    certificate.image && {
      type: 'image',
      url: certificate.image,
      diamond: product,
    },
  ].filter(Boolean);

  const [state, setState] = useState({
    activeAssetIndex: 0,
    activeAsset: hasAsset(media, 'image')
      ? 'IMAGE'
      : hasAsset(media, 'v360')
      ? 'VIDEO'
      : '',
    show_ticker: false,
    ticker_text: '',
  });

  const SlideImage = ({ url }) => {
    return (
      <div
        style={{
          width: '390px',
          height: '390px',
          backgroundImage: `url("${url}")`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    );
  };

  const V360 = ({ url, active }) => {
    if (active) {
      return <AWS360 v360_info={url} animate={true} />;
    } else {
      return null;
    }
  };

  const newImages = media.map((image) => {
    if (image.type === 'image') {
      return {
        Component: SlideImage,
        props: image,
      };
    }
    if (image.type === 'v360') {
      return {
        Component: V360,
        props: image,
      };
    }
    throw new Error('Unknown slide type for carousel');
  });

  const slide = newImages.find((ele) => {
    const type = ele.props.type === 'v360' ? 'VIDEO' : 'IMAGE';
    return type === state.activeAsset;
  });

  const handleOnAssetItemClick = useCallback(
    async (type, e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      const index = media
        .map((image) => (image.type === 'v360' ? 'VIDEO' : 'IMAGE'))
        .indexOf(type);

      setState((prevState) => ({
        ...prevState,
        activeAssetIndex: index !== -1 ? index : 0,
        activeAsset: type,
      }));
    },
    [media]
  );

  return (
    <ReqPopup
      className="product_media_modal"
      clearModalState={onClose}
      noBoxShdw
      noMaxHeight
      remove_top_margin
      showBorder={false}
      stopPropagation
      padding={`0 ${theme.spacing.s4} ${theme.spacing.s4}`}
      title={
        <PageHeading
          color={theme.palette.offBlack3}
          style={{ ...theme.fonts.xLarge }}
          noMB
          text={`Media Gallery — ${
            certificate.lab + ' ' + certificate.certNumber
          }`}
        />
      }
    >
      <MediaModalWrapper className="container-popup-img" id="imagePopup">
        <div className="carousel_asset__box">
          <div className="carousel_asset__box--media-wrp">
            <div className="carousel_asset__item">
              {state.activeAsset !== '' && (
                <slide.Component {...slide.props} active={true} />
              )}
            </div>
          </div>

          {/* asset item switcher buttons */}
          <div className="carousel_asset__footer-items">
            {slide.props.diamond && slide.props.diamond.certificate.image && (
              <div
                className={`carousel_asset__footer-items--image  ${
                  state.activeAsset === 'IMAGE' ? 'active' : ''
                }`}
                onClick={handleOnAssetItemClick.bind(null, 'IMAGE')}
              >
                <DiamondImage
                  diamond={slide.props.diamond}
                  imageUrl={slide.props.diamond.certificate.image}
                  small={false}
                  showIcon={false}
                  shape={slide.props.diamond.certificate.shape}
                  onlyButton={true}
                  hasRadius
                />
              </div>
            )}
            {slide.props.diamond &&
              slide.props.diamond.certificate &&
              slide.props.diamond.certificate.v360 && (
                <div
                  className={`carousel_asset__footer-items--video ${
                    state.activeAsset === 'VIDEO' ? 'active' : ''
                  }`}
                  onClick={handleOnAssetItemClick.bind(null, 'VIDEO')}
                >
                  <DiamondImage
                    diamond={slide.props.diamond}
                    imageUrl={slide.props.diamond.certificate.image}
                    url360={
                      slide.props.diamond.v360 ||
                      slide.props.diamond.certificate.v360
                    }
                    small={false}
                    showIcon
                    shape={slide.props.diamond.certificate.shape}
                    onlyButton={true}
                    hasRadius
                  />
                </div>
              )}
          </div>
        </div>
      </MediaModalWrapper>
    </ReqPopup>
  );
};

const MediaModalWrapper = styled.div`
  &.container-popup-img {
    width: 490px;
    padding: 0 ${theme.spacing.s3};
  }

  .carousel {
    &_asset {
      &__box {
        display: grid;
        grid-gap: ${theme.spacing.s4};
        &--media-wrp {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }
      }
      &__item {
        overflow: hidden;
        transition: transform 0.2s;
        background: rgb(200 202 203);
        border-radius: 6px;
        margin: ${theme.spacing.s5};

        & .aws_wrapper {
          height: 390px !important;
          width: 390px !important;
        }

        .video_icon_v360 {
          top: ${theme.spacing.s3} !important;
        }
      }

      &__footer-items {
        display: flex;
        gap: ${theme.spacing.s3};

        &--image,
        &--video {
          width: 56px;
          height: 56px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          padding: 2px;

          &.active {
            border: 1px solid ${theme.palette.blue3} !important;
          }
        }

        &--video {
          .video_icon_v360 {
            top: ${theme.spacing.s3} !important;
          }

          & > div {
            border-radius: 4px !important;
          }

          .aws_wrapper {
            width: 56px !important;
            height: 56px !important;
          }
        }
      }
    }
  }
`;
