import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';
import { InvoiceOrderItemFragment } from '../../fragments/InvoiceOrderItemFragment';
import {
  adminListItemFragment,
  AdminFullOrderItemFragment,
  AdminOrderLongInfoFragment,
  AdminAccountingOrderItemFragment,
} from 'graphql/fragments';

export const GET_INVOICE_DETAILS_BY_INVOICE_NUMBER = gql`
  query getInvoiceDetailsByInvoiceNumber($invoice_number: String!) {
    invoice_details(invoice_number: $invoice_number) {
      id
      netsuite_invoice_id
      netsuite_ic_cogs_id
      netsuite_ic_sales_id
      netsuite_journal_id
      invoice_number
      name_on_invoice
      type
      invoiced_at
      invoice_link
      usd_amount
      currency
      total_amount
      exchange_rate
      total_amount_tax
      supplier_subtotal
      supplier_total
      vat_number
      payment_discount
      volume_discount
      shipping_charges
      other_charges
      tax_amount
      reconciliation
      due_date
      settled
      status
      invoice_status
      from_country
      incentive_pay_charge
      incentive_pay_balance
      incentive_pay_settlements
      credit_balance
      credit_settlements
      credit_given_by
      invoice_type
      invoice_nivoda_reference
      cn_refunded_reason
      supplier_provided_adjustment
      dn_refunded_reason
      tds_amount
      supplier_payable_amount
      invoice_rejection_list {
        id
        reason
      }
      settled_invoice {
        id
      }
      settled_at
      debt_type
      settled_amount
      irn
      acknowledgement_date
      acknowledgement_number
      credit_usd_amount
      credit_invoice
      review_status
      last_reviewed_by {
        id
        firstName
        lastName
      }
      invoice_history {
        id
        value_name
        value_from
        value_to
        createdAt
        updatedAt
        created_by
        invoice_id
        adjustment_note
        reason_rejected
        changed_by
      }
      invoice_from {
        id
        name
      }
      invoice_to {
        id
        name
      }
      items {
        ...AdminFullOrderItem
        ...AdminOrderLongInfo
        FbnType
      }
      updates {
        id
        type
        value_name
        value_from
        value_to
        reverse
        action_taken
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
      payments {
        id
        amount
        created_at
        currency_from
        currency_to
        due_date
        original_amount
        status
        type
        payment_note
        updated_at
        added_by
        reversed_by
        user {
          id
          firstName
          lastName
        }
        addedBy {
          id
          firstName
          lastName
        }
        reversedBy {
          id
          firstName
          lastName
        }
        reverse_date
        invoicePayments {
          PaymentId
          InvoiceId
          usd_amount_used
          status
        }
      }
      invoicePayments {
        PaymentId
        InvoiceId
        usd_amount_used
        status
        reversedBy {
          id
          firstName
          lastName
        }
        reversed_by
        reversed_date
        createdAt
        Payment {
          id
          amount
          created_at
          currency_from
          status
          payment_note
          added_by
          reversed_by
          appliedBy {
            creditNoteNumber
            creditNoteId
          }
          user {
            id
            firstName
            lastName
          }
          addedBy {
            id
            firstName
            lastName
          }
          reversedBy {
            id
            firstName
            lastName
          }
        }
      }
      invoiced_company {
        id
        is_faas_company
      }
    }
  }
  ${AdminFullOrderItemFragment}
  ${AdminOrderLongInfoFragment}
`;
// get invoice details by invoiceId
export let GetInvoice = AdminGraphqlQuery({
  query: GET_INVOICE_DETAILS_BY_INVOICE_NUMBER,
});

export const FetchOrdersForAccounting = AdminGraphqlQuery({
  query: gql`
    query (
      $origin_country: OriginCountry
      $offset: Int
      $query: AdminInvoiceQuery
    ) {
      admin_invoices_to_upload(
        limit: 20
        offset: $offset
        origin_country: $origin_country
        query: $query
      ) {
        ...InvoiceOrderItem
      }
    }
    ${InvoiceOrderItemFragment}
  `,
});

export const ADMIN_ALL_INVOICES = gql`
  query adminAllInvoicesBySupplier(
    $offset: Int
    $limit: Int
    $date_range: DateRange
    $suppliers: [String]
    $country: String
    $text: String
  ) {
    admin_all_invoices_by_supplier(
      limit: $limit
      offset: $offset
      date_range: $date_range
      suppliers: $suppliers
      country: $country
      text: $text
    ) {
      total_count
      items {
        id
        ...SupplierListItem
      }
    }
  }
  ${adminListItemFragment}
`;

export const ADMIN_INVOICES_BY_REVIEW_STATUS = gql`
  query adminInvoicesByReviewStatus(
    $offset: Int
    $limit: Int
    $review_status: String!
    $date_range: DateRange
    $suppliers: [String]
    $country: String
    $text: String
  ) {
    admin_invoices_by_review_status(
      limit: $limit
      offset: $offset
      review_status: $review_status
      date_range: $date_range
      suppliers: $suppliers
      country: $country
      text: $text
    ) {
      total_count
      invoices_amount
      items {
        ...SupplierListItem
      }
    }
  }
  ${adminListItemFragment}
`;

export const ADMIN_INVOICES_AWAITING_PAYMENT = gql`
  query adminInvoicesToUpload(
    $previous_offset: Int
    $origin_country: OriginCountry
    $offset: Int
    $limit: Int
    $query: AdminInvoiceQuery
  ) {
    admin_invoices_to_upload(
      limit: $limit
      previous_offset: $previous_offset
      offset: $offset
      origin_country: $origin_country
      query: $query
    ) {
      previous_offset
      total_count
      items {
        FbnType
        ...AdminAccountingOrderItem
      }
    }
  }
  ${AdminAccountingOrderItemFragment}
`;

export const ADMIN_INVOICES_PAID = gql`
  query adminInvoicesPaidBySupplier(
    $offset: Int
    $limit: Int
    $date_range: DateRange
    $suppliers: [String]
    $country: String
    $text: String
  ) {
    admin_invoices_paid_by_supplier(
      limit: $limit
      offset: $offset
      date_range: $date_range
      suppliers: $suppliers
      country: $country
      text: $text
    ) {
      total_count
      invoices_amount
      items {
        ...SupplierListItem
      }
    }
  }
  ${adminListItemFragment}
`;

export const ADMIN_GET_REJECTION_REASON = gql`
  query adminGetRejectionReason {
    admin_get_rejection_reason {
      id
      reason
    }
  }
`;
