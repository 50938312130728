import gql from 'graphql-tag';
import { CertificateLongInfoFragment } from 'graphql/fragments';

export const GET_CUSTOMER_MEMO_ITEMS_BY_STATUS = gql`
  query fetchMemoItemsByStatus(
    $query: memo_item_list_query
    $offset: Int
    $limit: Int
  ) {
    get_memo_items_by_status(query: $query, limit: $limit, offset: $offset) {
      count
      data_list {
        id
        status
        start_date
        end_date
        return_deadline
        delivery_date
        ReturnShipment {
          id
          label
          package_slip
        }
        Product {
          ... on Diamond {
            id
            availability
            brown
            certificate {
              ...CertificateLongInfo
            }
            green
            other
            gray
            blue
            milky
            bowtie
            eyeClean
            supplierStockId
            diamondSupplierStockId
            OrderItemId
            location {
              id
              country
            }
            offer {
              id
              price
            }
          }
        }
        ProductType
        OrderItem {
          id
          customer_order_number
          order {
            id
          }
          invoice {
            id
            invoice_number
            invoice_sent
          }
        }
      }
    }
  }
  ${CertificateLongInfoFragment}
`;

export const GET_CUSTOMER_MEMO_OVERVIEW_DATA = gql`
  query fetchMemoOverviewItems($query: memo_item_list_query) {
    get_memo_items_by_status(query: $query) {
      diamond_count
      gemstone_count
      melee_count
    }
  }
`;

export const GET_CUSTOMER_MEMO_TAB_COUNTS = gql`
  query fetchMemoOverviewItems($query: memo_item_list_query) {
    get_memo_items_by_status(query: $query) {
      tab_count {
        requested_count
        delivered_count
        converted_count
        returned_count
      }
    }
  }
`;

export const GET_CUSTOMER_MEMO_DATA_SUMMARY = gql`
  query getMemoDataSummary {
    get_memo_data_summary {
      active_memo_price
      active_memo_total_items
      active_memo_deadline_total_items
      next_memo_deadline
      invoice_this_week_price
      invoice_this_week_total_items
      credit_info {
        credit_limit
        credit_consumed
        credit_available
      }
    }
  }
`;

export const GET_CUSTOMER_MEMO_ITEMS_BY_ID = gql`
  query getMemoItemByID($memo_ids: [ID!]!) {
    get_memo_items_by_id(memo_ids: $memo_ids) {
      id
      status
      start_date
      end_date
      return_deadline
      delivery_date
      origin_country
      destination_country
      ReturnShipment {
        id
        label
        package_slip
      }
      Product {
        ... on Diamond {
          id
          availability
          brown
          has_bgmec_enquiry
          certificate {
            ...CertificateLongInfo
          }
          green
          other
          gray
          blue
          milky
          bowtie
          eyeClean
          supplierStockId
          diamondSupplierStockId
          OrderItemId
          location {
            id
            country
          }
          offer {
            id
            price
          }
        }
      }
      ProductType
      OrderItem {
        id
        order_number
        customer_order_number
        expectedDeliveryDate
        shippingDate
        order {
          id
        }
        origin {
          id
          country
        }
        destination {
          id
          country
          name
          address1
          postalCode
          city
          state
        }
        deliveryDate
        customerReturnDeadlineDate
        shipments {
          id
          tracking_code
          carrier
        }
        latest_shipment {
          id
          tracking_code
          carrier
        }
        invoice {
          id
          due_date
        }
      }
      updates {
        id
        createdAt
        status
        name
        from
        to
        statements {
          key
          value
        }
      }
    }
  }
  ${CertificateLongInfoFragment}
`;
