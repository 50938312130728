import styled from 'styled-components';
import theme from '../../../fabric/theme';

export const AssignToWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  .ast__ {
    &container {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &.disabled {
        pointer-events: none;
      }
    }
    &title {
      ${theme.fonts.medium};
      color: ${theme.palette.black};
      margin-left: ${theme.spacing.s1};
      &--unassigned {
        color: ${theme.palette.offBlack2};
      }
    }
  }
  .popup__ {
    &sub-content-- {
      &title {
        ${theme.fonts.xLargeBolder};
        color: ${theme.palette.black};
      }
    }
  }
`;

export const AssignToPopup = styled.div`
  min-width: 600px;
  margin-top: -15px;
  .popup__ {
    &sub-content {
      width: 100%;
      position: relative;
      &--title {
        padding: 10px;
        ${theme.fonts.xLargeBolder};
        color: ${theme.palette.black};
        margin-top: ${theme.spacing.s8};
      }
      & .input_container {
        width: 100%;
        padding-left: 0;
        max-width: 100%;
        margin-top: ${theme.spacing.s4};
        & input {
          width: 100% !important;
          margin: 0 !important;
          height: 42px;
          padding-left: ${theme.spacing.s10} !important;
          border: 1px solid ${theme.palette.offWhite2};
          border-radius: 8px;
        }
        & span {
          top: 15px;
          right: 15px;
        }
      }
    }
  }
  .select-container {
    &__subgroup {
      background-color: ${theme.palette.white};
      color: ${theme.palette.black};
      ${theme.fonts.mediumBolder};
      padding: 0 ${theme.spacing.s3};
      cursor: pointer;

      &.selected,
      &:active,
      &:focus,
      &:focus-visible,
      &:hover {
        background-color: ${theme.palette.themePrimary};
        color: ${theme.palette.white};
        ${theme.fonts.mediumBolder};
      }
      &--title {
        border-bottom: 1px solid #ebeaea;
        padding: ${theme.spacing.s3} 0;
        &:hover {
          border: none;
        }
      }
    }
  }
  .footer-action {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: ${theme.spacing.s3};
    & .action__confirm--label {
      justify-content: center;
    }
    &_btn {
      width: 100%;
      border-radius: 8px !important;
      &--cancel {
        &:disabled {
          color: ${theme.palette.black} !important;
        }
      }
    }
  }
`;
