import { gql } from '@apollo/client';

export const feedFragment = gql`
  fragment FeedFragment on FeedShare {
    id
    customer {
      id
      name
      type
    }
    supplier {
      id
      name
      type
      inventory_type
      expected_stones
    }
    agreed
    approval_type
    show_measurements
    show_certificate_numbers
    status
  }
`;
