import { gql } from '@apollo/client';
import { OfferFragment } from './OfferFragment';

export const MemoWalletItemFragment = gql`
  fragment MemoWalletItemFragment on MemoWalletItem {
    offer {
      ...OfferFragment
      is_returnable
      memo_return_deadline
      memo_applicable
    }
    destination {
      id
    }
    added_at
    customer_order_number
    customer_reference
    order_notes
  }
  ${OfferFragment}
`;
