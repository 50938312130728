import { gql } from '@apollo/client';
import {
  CertificateInfoFragment,
  GemstoneCertificate,
  MeleeFragment,
} from 'graphql/fragments';

export const ADMIN_FETCH_INVENTORY_STATUS_COUNT = gql`
  query adminFetchInventoryStatusCount(
    $count_only: Boolean
    $query: express_inventory_query
  ) {
    admin_get_express_inventory(count_only: $count_only, query: $query) {
      pending_count
      inventory_count
      hold_count
      memo_count
      sold_count
      return_count
      return_requested_count
      locations
      suppliers {
        id
        name
      }
      tray_list {
        total_trays {
          trays_count
          stone_count
          cert_count
        }
        individual_trays {
          tray_number
          stone_count
          cert_count
        }
      }
    }
  }
`;

export const ADMIN_FETCH_INVENTORIES_DATA = gql`
  query adminFetchInventoriesData(
    $count_only: Boolean
    $query: express_inventory_query
    $search_string: String
    $offset: Int
    $limit: Int
  ) {
    admin_get_express_inventory(
      count_only: $count_only
      query: $query
      search_string: $search_string
      limit: $limit
      offset: $offset
    ) {
      total_count
      items {
        id
        FbnItem {
          id
          internal_status
          consignment_closure_date
          current_location
          SpacecodeProductTag
          SpacecodeCertTag
          tray_number
          cert_tray_number
        }
        Product {
          ... on Diamond {
            id
            supplierStockId
            diamondSupplierStockId
            bowtie
            brown
            green
            blue
            gray
            milky
            other
            eyeClean
            offer {
              id
              price
              discount
              price_per_carat
              product_price
              product_discount
              return_price
              return_discount
            }
            certificate {
              ...CertificateInfo
            }
            location {
              id
              name
              country
            }
            supplier {
              id
              name
              website
              country
              accept_returns
            }
          }
          ... on Melee {
            ...MeleeFragment

            child_melee_collections {
              ...MeleeFragment
              supplierStockId
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            total_price
            price_per_carat
            IsParentMeleeCollection
          }
          ... on Gemstone {
            id
            supplierStockId
            availability
            last_enquiry_answered
            has_bgmec_enquiry
            has_media_enquiry
            GemstoneCertificate: certificate {
              id
              ...GemstoneCertificate
            }
            location {
              id
              name
              country
            }
            supplier {
              id
              name
              website
              country
              accept_returns
            }
          }
        }
        ProductType
      }
    }
  }
  ${CertificateInfoFragment}
  ${MeleeFragment}
  ${GemstoneCertificate}
`;
