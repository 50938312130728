// import mixpanel from 'mixpanel-browser';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { capitalize } from 'lodash';
import { appEnv } from 'utils';

let analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_WRITEKEY,
});

let env_check = appEnv.permitStaging
  ? true
  : !appEnv.isStaging &&
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_SEGMENT_WRITEKEY != null;

const createNewObject = (props) => {
  let unik_i_p = localStorage.getItem('_ID_check') || null;
  let session = JSON.parse(localStorage.getItem('graphql_session'));
  let u_id = (session && session?.user?.id) || null;
  let c_id = (session && session?.user?.company?.id) || null;
  let email = (session && session?.user?.email) || null;
  let c_name = (session && session?.user?.company?.name) || null;
  let role = (session && session?.user?.role) || null;
  let unmask_ip = (ip) => {
    let ip_arr = ip?.split(':') || [];
    return ip_arr.length > 0
      ? ip_arr.map((masked, i) => Math.sqrt(masked) / (i + 3)).join('.')
      : null;
  };
  return Object.assign({}, props, {
    unik_i_p: unik_i_p != null ? unmask_ip(unik_i_p) : null,
    user_id: u_id,
    Company_id: c_id,
    Email: email,
    CompanyName: c_name,
    Role: capitalize(role),
    RequestPlatform: 'WebPlatform',
  });
};

let actions = {
  identify: (id, props) => {
    if (env_check) {
      analytics.identify(id, props);
    }
  },
  alias: (id) => {
    if (env_check) analytics.alias(id);
  },
  track: (name, props) => {
    let newObject = createNewObject(props);
    if (env_check) {
      if (Array.isArray(name)) {
        return name.map((n) => analytics.track(n, newObject));
      }
      return analytics.track(name, newObject);
    }
  },

  track_page: (name) => {
    let newObject = createNewObject({ page: name });
    if (env_check) {
      // eslint-disable-next-line no-console
      analytics.page(name, newObject);
    }
  },
};

export const Track = actions;
