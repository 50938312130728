import { gql } from '@apollo/client';
import { MemoWalletItemFragment } from 'graphql/fragments';

export const UPDATE_MEMO_WALLET = gql`
  mutation updateMemoWallet($items: [InputMemoWalletItem!]!) {
    update_memo_wallet(items: $items) {
      id
      memo_wallet_items {
        ...MemoWalletItemFragment
      }
    }
  }
  ${MemoWalletItemFragment}
`;
