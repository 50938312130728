import { Icon } from 'fabric/resources/icon';
import { theme } from 'fabric/theme';
import React from 'react';
import styled from 'styled-components';
import { round } from 'lodash';
const ZoomSliderWrpper = styled.div`
  width: 100%;
  margin-right: 10px;
  .slider-background {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    .scale {
      padding: 0 5px;
      color: ${theme.palette.white};
      ${theme.fonts.smallBold}
      font-weight:800;
      margin-right: 5px;
    }
    .slider {
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      width: 100%; /* Full-width */
      height: 2px; /* Specified height */
      background: rgba(12, 12, 12, 0.8); /* Grey background */
      -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
      transition: opacity 0.2s;
      padding: 1px 2px;
    }
    .zoom_icons {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 2px; /* Set a specific slider handle width */
    height: 10px; /* Slider handle height */
    background: white; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
  .slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04aa6d; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
`;
export default function ZoomSlider({
  className = '',
  updateScale,
  value,
  zoomIn,
  zoomOut,
}) {
  return (
    <ZoomSliderWrpper className={className}>
      <div className="slider-background">
        <div className="scale">{round(value)}X</div>
        <input
          type="range"
          min="1"
          max="4"
          step="1"
          className="slider"
          value={value}
          onChange={(e) => updateScale(e)}
        />
        <div className="zoom_icons">
          <Icon
            name="circle-minus_v2"
            style={{ margin: '0 8px' }}
            color="white"
            onClick={() => zoomOut()}
          />
          <Icon name="circle-plus_v2" color="white" onClick={() => zoomIn()} />
        </div>
      </div>
    </ZoomSliderWrpper>
  );
}
