//common for customer and supplier type
import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';
import { SessionFragment } from '../../fragments/SessionFragment';

export const InitialiseStepTwo = GraphqlMutation({
  query: gql`
    mutation (
      $phone: String!
      $company_name: String!
      $company_registration_number: String
      $company_website: String!
      $company_type: String!
      $title: String!
      $average_monthly_purchase: String
      $first_touch: String
      $rapnet_id: String
    ) {
      request_phone(
        phone: $phone
        company_name: $company_name
        company_registration_number: $company_registration_number
        company_website: $company_website
        company_type: $company_type
        title: $title
        average_monthly_purchase: $average_monthly_purchase
        first_touch: $first_touch
        rapnet_id: $rapnet_id
      )
    }
  `,
});

export const ConfirmEmailWithCode = GraphqlMutation({
  query: gql`
    mutation (
      $email: String!
      $confirmation_code: String!
      $reCaptchaToken: String!
    ) {
      confirm_email_with_code(
        email: $email
        confirmation_code: $confirmation_code
        reCaptchaToken: $reCaptchaToken
      )
    }
  `,
});
export const ConfirmPhoneData = GraphqlMutation({
  query: gql`
    mutation ($phone: String!, $sms_code: String!) {
      confirm_phone(phone: $phone, sms_code: $sms_code)
    }
  `,
});

export const SetPhone = GraphqlMutation({
  query: gql`
    mutation ($phone: String!, $sms_code: String!) {
      set_phone(phone: $phone, sms_code: $sms_code) {
        ...session
      }
    }
    ${SessionFragment}
  `,
});

export const RequestEmail = GraphqlMutation({
  query: gql`
    mutation ($email: String!) {
      request_email(email: $email)
    }
  `,
});

export const ChangePhone = GraphqlMutation({
  query: gql`
    mutation ($phone: String!, $is_super_saver: Boolean) {
      change_phone(phone: $phone, is_super_saver: $is_super_saver)
    }
  `,
});

export const SetPasswordTokenLogin = GraphqlMutation({
  query: gql`
    mutation (
      $password: String!
      $verify_token: String!
      $is_super_saver: Boolean
    ) {
      set_password_from_token_login(
        password: $password
        verify_token: $verify_token
        is_super_saver: $is_super_saver
      ) {
        id
        firstName
        lastName
      }
    }
  `,
});

export const UpdateSMSOption = GraphqlMutation({
  query: gql`
    mutation ($code_disabled: Boolean, $code: String) {
      update_code_disabled(code_disabled: $code_disabled, code: $code) {
        code_disabled
      }
    }
  `,
});

export const CodeDisabledSMS = GraphqlMutation({
  query: gql`
    mutation {
      code_disabled_sms {
        id
      }
    }
  `,
});

export const CONFIRM_EMAIL_WITH_CODE = gql`
  mutation (
    $email: String!
    $confirmation_code: String!
    $reCaptchaToken: String!
  ) {
    confirm_email_with_code(
      email: $email
      confirmation_code: $confirmation_code
      reCaptchaToken: $reCaptchaToken
    )
  }
`;

export const CONFIRM_PHONE_DATA = gql`
  mutation ($phone: String!, $sms_code: String!) {
    confirm_phone(phone: $phone, sms_code: $sms_code)
  }
`;

export const REQUEST_EMAIL = gql`
  mutation ($email: String!) {
    request_email(email: $email)
  }
`;

export const CHANGE_PHONE = gql`
  mutation ($phone: String!, $is_super_saver: Boolean) {
    change_phone(phone: $phone, is_super_saver: $is_super_saver)
  }
`;
