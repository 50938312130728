import styled from 'styled-components';

import theme from 'fabric/theme';
import { isMobile } from 'utils';

export const StyledWrapper = styled.div`
  width: ${isMobile() ? '100%' : '600px'};
  &.error_block {
    .svg_icon {
      margin: -40px auto 0;
    }
    .block_heading {
      margin: ${theme.spacing.s5} auto ${theme.spacing.s3};
      width: auto;
      text-align: center;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
  ${({ bodyStyle }) => bodyStyle}
`;
