/**
 * Provides info on various environment
 * @type {{isTest: boolean, isProd: boolean, isStaging: boolean, isDev: boolean}}
 */
export const appEnv = {
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  isStaging:
    process.env.REACT_APP_WDC_ENV === 'staging' ||
    process.env.WDC_ENV === 'staging',
  isTest: process.env.NODE_ENV === 'test',
  permitStaging: process.env.REACT_APP_ALLOW_ANALYTICS === 'true',
};
