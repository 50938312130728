import { gql } from '@apollo/client';
import { AdminHoldByStatusFragment } from '../../fragments';

export const GET_ADMIN_HOLDS_BY_STATUS = gql`
  query getAdminHoldsByStatus(
    $offset: Int
    $limit: Int
    $query: HoldItemAdminQuery!
  ) {
    admin_holds_by_status(limit: $limit, offset: $offset, query: $query) {
      total_count
      items {
        ...AdminHoldsByStatus
      }
    }
  }
  ${AdminHoldByStatusFragment}
`;

export const GET_SEARCH_HOLDS = gql`
  query searchAdminHolds($cert_number: String!) {
    search_holds(cert_number: $cert_number) {
      total_count
      items {
        ...AdminHoldsByStatus
      }
    }
  }
  ${AdminHoldByStatusFragment}
`;

export const GET_ADMIN_HOLDS_BY_ID = gql`
  query getAdminHoldsById($hold_id: ID!) {
    admin_get_hold_by_id(hold_id: $hold_id) {
      ...AdminHoldsByStatus
    }
  }
  ${AdminHoldByStatusFragment}
`;
