import { GET_TRANSLATIONS_BY_LANGUAGE } from 'graphql/queries/tms';
// Import the IndexedDB connection utility
import { connectIDB } from './indexedDB';
import en from '../i18n/en.json';
import { translateEN } from '@nivoda/i18n';
import { tmsGraphqlClient } from 'graphql/factory';

// Define a fallback object for translations in supported languages
const fallbackResources = {
  en: { translation: { ...translateEN, ...en.translation } },
  de: { translation: {} },
  it: { translation: {} },
  es: { translation: {} },
  jp: { translation: {} },
  fr: { translation: {} },
};

const getResourceDefaults = (idbResources) => {
  const isValidRes = idbResources && typeof idbResources === 'object';

  return {
    en: {
      translation: {
        ...translateEN,
        ...en.translation,
        ...(isValidRes ? idbResources.en.translation : {}),
      },
    },
    de: {
      translation: isValidRes ? idbResources.de.translation : {},
    },
    it: {
      translation: isValidRes ? idbResources.it.translation : {},
    },
    es: {
      translation: isValidRes ? idbResources.es.translation : {},
    },
    jp: {
      translation: isValidRes ? idbResources.jp.translation : {},
    },
    fr: {
      translation: isValidRes ? idbResources.fr.translation : {},
    },
  };
};

/**
 * @description Parses raw translation data into a structured object with
 * language-specific translations
 * @param {Array<{ translationKey: string, en?: string, es?: string, fr?: string, it?: string, de?: string, jp?: string }>} data -
 * Array of translation objects
 * @param {Object<string, { translation: { [key: string]: string } }>| undefined} idbData Pre existing indexDB data
 * @returns {{de: {translation: ({}|{})}, jp: {translation: ({}|{})}, en: {translation: (*&{})}, it: {translation: (*|{})}, fr: {translation: (*|{})}, es: {translation: (*|{})}}} An object with language-specific translations
 */
const parseTranslationData = (data, idbData) => {
  const idbResources = typeof idbData === 'object' ? idbData.value : null;
  const resources = getResourceDefaults(idbResources);

  data.forEach((translation) => {
    if (translation.en) {
      resources.en.translation = {
        ...resources.en.translation,
        [translation.translationKey]: translation.en,
      };
    }

    if (translation.es) {
      resources.es.translation = {
        ...resources.es.translation,
        [translation.translationKey]: translation.es,
      };
    }
    if (translation.fr) {
      resources.fr.translation = {
        ...resources.fr.translation,
        [translation.translationKey]: translation.fr,
      };
    }
    if (translation.it) {
      resources.it.translation = {
        ...resources.it.translation,
        [translation.translationKey]: translation.it,
      };
    }
    if (translation.de) {
      resources.de.translation = {
        ...resources.de.translation,
        [translation.translationKey]: translation.de,
      };
    }
    if (translation.jp) {
      resources.jp.translation = {
        ...resources.jp.translation,
        [translation.translationKey]: translation.jp,
      };
    }
  });

  return resources;
};

/**
 * @description Fetches translations, prioritizing cached data from IndexedDB
 * and falling back to GraphQL if needed
 * @returns {Promise<{ key: string, value: Object<string, { translation: { [key: string]: string } }> }>} A Promise resolving to the retrieved translations
 */

async function fetchTranslationsByLanguage(
  translationDB,
  selectedLanguage,
  idbResources
) {
  const languageVariables = {
    de: false,
    en: false,
    es: false,
    fr: false,
    it: false,
    jp: false,
  };

  languageVariables[selectedLanguage] = true;
  try {
    const { data, loading } = await tmsGraphqlClient.query({
      query: GET_TRANSLATIONS_BY_LANGUAGE,
      variables: {
        languageCodes: [selectedLanguage],
        ...languageVariables,
      },
    });
    if (!loading) {
      // Parse the fetched translation data
      // noinspection JSUnresolvedReference
      const resources = parseTranslationData(
        data?.getTranslationsByLanguages ?? [],
        idbResources
      );
      // Update IndexedDB with the new resources
      await translationDB.update({ key: 'i18NResources', value: resources });
      await translationDB.update({
        key: 'preferredLanguage',
        value: { [selectedLanguage]: resources[selectedLanguage] },
      });

      // Update the last update time in localStorage
      const currentTime = new Date().getTime();
      localStorage.setItem('TranslationLastUpdateTime', String(currentTime));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching translations:', error);
  }
}

export const fetchTranslations = async () => {
  const translationDB = await connectIDB();
  const language = JSON.parse(localStorage.getItem('preferred_language'));
  const selectedLanguage = language?.value || 'en';
  const currentTime = new Date().getTime();
  const lastUpdateTime = localStorage.getItem('TranslationLastUpdateTime');

  try {
    let resources = await translationDB.get('i18NResources');
    let preferredLanguage = await translationDB.get('preferredLanguage');
    const preferredLangCode = preferredLanguage
      ? Object.keys(preferredLanguage.value)[0]
      : 'en';

    if (!preferredLanguage) {
      // No preferred lang then fetch EN data for UI use
      await fetchTranslationsByLanguage(
        translationDB,
        selectedLanguage,
        resources
      );
      resources = await translationDB.get('i18NResources'); // EN data
      return { key: selectedLanguage, value: resources.value }; // En data for UI
    }
    // Check if we need to rehydrate and invalidate the client cache
    if (!lastUpdateTime || currentTime - lastUpdateTime > 3600000) {
      // noinspection ES6MissingAwait
      await fetchTranslationsByLanguage(
        translationDB,
        selectedLanguage,
        resources
      );
      resources = await translationDB.get('i18NResources');
      return { key: selectedLanguage, value: resources.value };
    }
    // Check if Preferred lang is updated
    if (selectedLanguage !== preferredLangCode) {
      if (
        !resources ||
        !resources.value[selectedLanguage] ||
        Object.keys(resources?.value?.[selectedLanguage]?.translation || {})
          .length === 0
      ) {
        await fetchTranslationsByLanguage(
          translationDB,
          selectedLanguage,
          resources
        );
        resources = await translationDB.get('i18NResources');
        return { key: selectedLanguage, value: resources.value };
      } else {
        return { key: selectedLanguage, value: resources.value };
      }
    }
    return { key: selectedLanguage, value: resources.value };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error while retrieving/init event ', error);

    return { key: 'i18NResources', value: fallbackResources };
  }
};
