export let process_single_gemstone_data = (item) => {
  if (!item) return;
  if (item && item.ProductType !== 'Gemstone') return item;
  return {
    ...item,
    Product: {
      ...item.Product,
      certificate: item?.Product?.GemstoneCertificate,
    },
  };
};
export let process_gemstone_data = (items = []) => {
  if (items.length === 0) return [];

  let GemstoneItems =
    items
      ?.filter((r) => r.Product != null)
      .filter((item) => item.ProductType === 'Gemstone') || [];
  let itemsWithoutGemstones = items
    .filter((r) => r.Product != null)
    ?.filter((item) => item.ProductType !== 'Gemstone');

  let transformGemstoneItems = GemstoneItems.filter(
    (r) => r.Product != null
  ).map((obj) => {
    return {
      ...obj,
      Product: {
        ...obj.Product,
        certificate: obj?.Product?.GemstoneCertificate,
      },
    };
  });
  return [...transformGemstoneItems, ...itemsWithoutGemstones];
};

export let parse_gemstone = (data, key) => {
  let old_items = (data[key] != null && data[key]?.items) || [];

  if (data[key] == null) {
    return data;
  }
  let items = data[key] != null && process_gemstone_data(old_items);

  return {
    ...data,
    [key]: {
      ...data[key],
      items: items,
    },
  };
};
