import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';
import {
  AdminAccountingOrderItemFragment,
  AwaitingPaymentOrderItemListFragment,
  MeleeFragment,
  SuppliersToPayINVOrderItemListFragment,
  SuppliersToPayOrderItemListFragment,
  ToInvoiceOrderItemListFragment,
} from '../../fragments';

let process_gemstone_data = (items = []) => {
  if (items == null) throw Error('Err: Items is null');
  if (items.length === 0) return [];

  let GemstoneItems =
    items?.filter((item) => item.ProductType === 'Gemstone') || [];
  let itemsWithoutGemstones = items?.filter(
    (item) => item.ProductType !== 'Gemstone'
  );

  let transformGemstoneItems = GemstoneItems.map((obj) => {
    return {
      ...obj,
      Product: {
        ...obj.Product,
        certificate: obj?.Product?.GemstoneCertificate,
      },
    };
  });

  return [...transformGemstoneItems, ...itemsWithoutGemstones];
};

export const FetchOrdersForAccounting = AdminGraphqlQuery({
  query: gql`
    query (
      $previous_offset: Int
      $origin_country: OriginCountry
      $offset: Int
      $limit: Int
      $query: AdminInvoiceQuery
    ) {
      admin_invoices_to_upload(
        limit: $limit
        previous_offset: $previous_offset
        offset: $offset
        origin_country: $origin_country
        query: $query
      ) {
        previous_offset
        total_count
        items {
          FbnType
          FbnItem {
            id
          }
          ...AdminAccountingOrderItem
        }
      }
    }
    ${AdminAccountingOrderItemFragment}
  `,
  map_data: (data) => {
    let key = 'admin_invoices_to_upload';
    let old_items = (data[key] != null && data[key]?.items) || [];
    if (data[key] == null) {
      return data;
    }
    let items = process_gemstone_data(old_items);
    return {
      ...data,
      [key]: {
        ...data[key],
        items: items,
      },
    };
  },
});

export const FetchMeleeOrdersForAccounting = AdminGraphqlQuery({
  query: gql`
    query (
      $previous_offset: Int
      $origin_country: OriginCountry
      $offset: Int
      $query: AdminInvoiceQuery
      $fetch_all: Boolean
    ) {
      admin_melee_invoices_to_upload(
        limit: 50
        previous_offset: $previous_offset
        offset: $offset
        origin_country: $origin_country
        query: $query
        fetch_all: $fetch_all
      ) {
        previous_offset
        total_count
        items {
          InventoryReference {
            id
            supplier_invoice_reference_number
            supplier_invoice_report_sent_at
          }
          Product {
            ... on Melee {
              supplierStockId
              parent_melee_collection {
                id
                carats
              }
              base_melee_collection {
                id
                carats
                carats_ordered
                total_carats
              }
            }
          }
          ...AdminAccountingOrderItem
        }
      }
    }
    ${AdminAccountingOrderItemFragment}
  `,
});

export const GetAdminAwaitingPayment = AdminGraphqlQuery({
  query: gql`
    query (
      $offset: Int
      $query: AdminInvoicesAwaitingPaymentQuery
      $proforma_only: Boolean
      $credit_by: String
      $country: String
      $payment_mode: String
      $debt_type: String
      $dpd_filter: String
    ) {
      admin_invoices_awaiting_payment(
        limit: 50
        offset: $offset
        query: $query
        proforma_only: $proforma_only
        credit_by: $credit_by
        country: $country
        payment_mode: $payment_mode
        debt_type: $debt_type
        dpd_filter: $dpd_filter
      ) {
        ...AwaitingPaymentOrderItemList
        items {
          invoiced_company {
            is_faas_company
          }
        }
      }
    }
    ${AwaitingPaymentOrderItemListFragment}
  `,
  map_data: (data) => {
    let key = 'admin_invoices_awaiting_payment';
    let first_row_item = (data[key] != null && data[key]?.items) || [];
    let items = first_row_item.map((base) => {
      return {
        ...base,
        items: process_gemstone_data(base.items),
      };
    });

    return {
      ...data,
      [key]: {
        ...data[key],
        items: items,
      },
    };
  },
});

export const GetAdminInvoicesToPay = AdminGraphqlQuery({
  query: gql`
    query ($limit: Int, $offset: Int, $query: InputAdminInvoicesToPayQuery) {
      admin_invoices_to_pay(limit: $limit, offset: $offset, query: $query) {
        ...SuppliersToPayINVOrderItemList
      }
    }
    ${SuppliersToPayINVOrderItemListFragment}
  `,
  map_data: (data) => {
    let key = 'admin_invoices_to_pay';
    let first_row_item = (data[key] != null && data[key]?.items) || [];
    let items = first_row_item.map((base) => {
      return {
        ...base,
        items: process_gemstone_data(base.items),
      };
    });

    return {
      ...data,
      [key]: {
        ...data[key],
        items: items,
      },
    };
  },
});

export const GetAdminToInvoice = AdminGraphqlQuery({
  query: gql`
    query getToInvoiceItems(
      $buyer_company_id: ID
      $offset: Int
      $origin_country: OriginCountry
      $ProductType: ProductType
    ) {
      order_items_to_invoice(
        buyer_company_id: $buyer_company_id
        limit: 1000
        offset: $offset
        origin_country: $origin_country
        ProductType: $ProductType
      ) {
        ...ToInvoiceOrderItemList
      }
    }
    ${ToInvoiceOrderItemListFragment}
  `,
  map_data: (data) => {
    let key = 'order_items_to_invoice';

    let old_items = (data[key] != null && data[key]?.items) || [];
    if (data[key] == null) {
      return data;
    }
    let items = process_gemstone_data(old_items);

    return {
      ...data,
      [key]: {
        ...data[key],
        items: items,
      },
    };
  },
});

export const GetAdminToPay = AdminGraphqlQuery({
  query: gql`
    query (
      $previous_offset: Int
      $offset: Int
      $limit: Int
      $query: InputOrderItemsToPayQuery
    ) {
      admin_order_items_to_pay(
        limit: $limit
        previous_offset: $previous_offset
        offset: $offset
        query: $query
      ) {
        ...SuppliersToPayOrderItemList
      }
    }
    ${SuppliersToPayOrderItemListFragment}
  `,
  map_data: (data) => {
    let key = 'admin_order_items_to_pay';

    let first_row_item = (data[key] != null && data[key]?.items) || [];
    let items = first_row_item.map((base) => {
      return {
        ...base,
        items: process_gemstone_data(base.items),
      };
    });

    return {
      ...data,
      [key]: {
        ...data[key],
        items: items,
      },
    };
  },
});

export const GetAdminInvoicing = AdminGraphqlQuery({
  query: gql`
    query ($status: InvoiceStatus!) {
      all_invoices(status: $status, limit: 100) {
        id
        invoice_number
        # invoice_date
        invoiced_at
        invoice_id
        invoice_link
        currency
        total_amount
        exchange_rate

        invoice_to {
          id
          name
        }

        items {
          checkout_currency
          id

          order {
            id
            order_date
          }

          status
          order_number
          base_sell_price
          base_sell_discount
          origin_country
          buy_price_confirmed
          nivoda_markup
          customer_vat_rate

          Product {
            ... on Diamond {
              id
              certificate {
                id
                lab
                certNumber
                shape
                carats
                color
                clarity
                floCol
                floInt
              }
              supplier {
                id
                name
              }
            }
            ... on Melee {
              ...MeleeFragment
              total_price
            }
            ... on Gemstone {
              id
              GemstoneCertificate: certificate {
                id
                lab
                certNumber
                shape
                carats
                color
                gemType
                clarity
              }
              supplier {
                id
                name
              }
            }
          }
          ProductType

          buyer {
            id
            company {
              id
              name
            }
          }
        }
      }
    }
    ${MeleeFragment}
  `,
  map_data: (data) => {
    let key = 'all_invoices';
    let BaseArray = (data[key] != null && data[key]) || [];

    let ProcessedBaseItems = BaseArray.map((obj) => {
      return {
        ...obj,
        items: process_gemstone_data(obj.items),
      };
    });

    return {
      ...data,
      [key]: ProcessedBaseItems,
    };
  },
});

export const GetAwaitingPayment = AdminGraphqlQuery({
  query: gql`
    query (
      $offset: Int
      $query: AdminInvoicesAwaitingPaymentQuery
      $proforma_only: Boolean
    ) {
      admin_invoices_awaiting_payment(
        limit: 50
        offset: $offset
        query: $query
        proforma_only: $proforma_only
      ) {
        ...AwaitingPaymentOrderItemList
      }
    }
    ${AwaitingPaymentOrderItemListFragment}
  `,
});

export const GetDebitNotes = gql`
  query getDebitNoteItems(
    $text: String
    $supplier_company_id: ID
    $offset: Int
    $origin_country: OriginCountry
    $ProductType: ProductType
    $date_range: DateRange
  ) {
    order_items_to_create_debit_notes(
      text: $text
      supplier_company_id: $supplier_company_id
      date_range: $date_range
      offset: $offset
      origin_country: $origin_country
      ProductType: $ProductType
      limit: 1000
    ) {
      ...ToInvoiceOrderItemList
    }
  }
  ${ToInvoiceOrderItemListFragment}
`;

export const FetchAdminInvoicesToPay = gql`
  query InputAdminInvoicesToPayQuery($query: InputAdminInvoicesToPayQuery) {
    admin_invoices_to_pay(query: $query) {
      items {
        id
        invoice_number
        currency
        exchange_rate
        due_date
        tax_amount
        total_amount
        tds_amount
        total_amount_tax
        settled_amount
        supplier_payable_amount
      }
    }
  }
`;

export const GetCreditNote = gql`
  query getCreditNoteItems(
    $buyer_company_id: ID
    $offset: Int
    $ProductType: ProductType
  ) {
    order_items_to_create_credit_note(
      buyer_company_id: $buyer_company_id
      offset: $offset
      ProductType: $ProductType
      limit: 1000
    ) {
      ...ToInvoiceOrderItemList
    }
  }
  ${ToInvoiceOrderItemListFragment}
`;

// get payment invoices list details by paymentId
export const GetPaymentInvoices = AdminGraphqlQuery({
  query: gql`
    query ($offset: Int, $query: AdminPaymentInvoiceListQuery) {
      admin_payment_invoices(offset: $offset, query: $query) {
        payment_invoice_list {
          id
          minor_adjustment
          adjustment_reason
          amount
          created_at
          currency_from
          currency_to
          due_date
          original_amount
          status
          type
          payment_note
          updated_at
          added_by
          reversed_by
          addedBy {
            id
            firstName
            lastName
          }
          reverse_date
          invoicePayments {
            PaymentId
            InvoiceId
            usd_amount_used
            status
            Invoice {
              id
              invoice_number
              invoice_link
              invoice_sent
              invoiced_at
              currency
              exchange_rate
              usd_amount
              total_amount
              total_amount_tax
              invoice_tax_rate
              settled
              settled_at
              due_date
              incentive_pay_balance
              incentive_pay_percentage
              credit_balance
              invoice_comment
              invoice_comment_date
              invoice_status
              incentive_pay_charge
              credit_usd_amount
              credit_invoice
              from_country
              to_country
              status
              settled_amount
              invoice_to {
                name
              }
            }
          }
        }
      }
    }
  `,
});

export const GetDebitNoteInvoicing = gql`
  query (
    $offset: Int
    $text: String
    $supplier_company_id: ID
    $date_range: DateRange
  ) {
    all_debit_notes(
      offset: $offset
      text: $text
      supplier_company_id: $supplier_company_id
      date_range: $date_range
      limit: 100
    ) {
      id
      invoice_number
      # invoice_date
      invoiced_at
      invoice_id
      invoice_link
      currency
      total_amount
      exchange_rate
      type
      status
      settled
      invoice_from {
        id
        name
      }
      invoice_to {
        id
        name
      }
      legal_entity_id
      items {
        checkout_currency
        id

        order {
          id
          order_date
        }
        order_number
        base_sell_price
        base_sell_discount
        origin_country
        buy_price_confirmed
        nivoda_markup
        customer_vat_rate

        Product {
          ... on Diamond {
            id
            certificate {
              id
              lab
              certNumber
              shape
              carats
              color
              clarity
              floCol
              floInt
            }
            supplier {
              id
              name
            }
          }
          ... on Melee {
            ...MeleeFragment
            total_price
          }
          ... on Gemstone {
            id
            GemstoneCertificate: certificate {
              id
              lab
              certNumber
              shape
              carats
              color
              gemType
              clarity
            }
            supplier {
              id
              name
            }
          }
        }
        ProductType

        buyer {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
  ${MeleeFragment}
`;

export const GetAvailableDebitNoteInvoicing = gql`
  query (
    $offset: Int
    $supplier_company_id: ID
    $text: String
    $date_range: DateRange
    $partially_allocated: Boolean
  ) {
    unpaid_debit_notes(
      offset: $offset
      limit: 100
      text: $text
      supplier_company_id: $supplier_company_id
      date_range: $date_range
      partially_allocated: $partially_allocated
    ) {
      id
      invoice_number
      # invoice_date
      invoiced_at
      invoice_id
      invoice_link
      settled_amount
      currency
      total_amount
      total_amount_tax
      exchange_rate
      type
      status
      settled
      invoice_from {
        id
        name
      }
      invoice_to {
        id
        name
      }
      legal_entity_id
      items {
        checkout_currency
        id

        order {
          id
          order_date
        }
        order_number
        base_sell_price
        base_sell_discount
        origin_country
        buy_price_confirmed
        nivoda_markup
        customer_vat_rate

        Product {
          ... on Diamond {
            id
            certificate {
              id
              lab
              certNumber
              shape
              carats
              color
              clarity
              floCol
              floInt
            }
            supplier {
              id
              name
            }
          }
          ... on Melee {
            ...MeleeFragment
            total_price
          }
          ... on Gemstone {
            id
            GemstoneCertificate: certificate {
              id
              lab
              certNumber
              shape
              carats
              color
              gemType
              clarity
            }
            supplier {
              id
              name
            }
          }
        }
        ProductType

        buyer {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
  ${MeleeFragment}
`;

export const GetCreditNoteInvoicing = gql`
  query getCreditNoteInvoicing($offset: Int) {
    all_credit_notes(offset: $offset, limit: 100) {
      id
      invoice_number
      # invoice_date
      invoiced_at
      invoice_id
      invoice_link
      currency
      total_amount
      exchange_rate
      type
      invoice_to {
        id
        name
      }

      items {
        checkout_currency
        id

        order {
          id
          order_date
        }

        status
        order_number
        base_sell_price
        base_sell_discount
        origin_country
        buy_price_confirmed
        nivoda_markup
        customer_vat_rate

        Product {
          ... on Diamond {
            id
            certificate {
              id
              lab
              certNumber
              shape
              carats
              color
              clarity
              floCol
              floInt
            }
            supplier {
              id
              name
            }
          }
          ... on Melee {
            ...MeleeFragment
            total_price
          }
          ... on Gemstone {
            id
            GemstoneCertificate: certificate {
              id
              lab
              certNumber
              shape
              carats
              color
              gemType
              clarity
            }
            supplier {
              id
              name
            }
          }
        }
        ProductType

        buyer {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
  ${MeleeFragment}
`;

export const FETCH_AIRWALLEX_DEPOSIT_HISTORY = gql`
  query fetchAirwallexDepositHistory(
    $companyId: ID
    $status: String!
    $currency: String
    $start_date: String
    $end_date: String
  ) {
    get_airwallex_transactions(
      companyId: $companyId
      status: $status
      currency: $currency
      start_date: $start_date
      end_date: $end_date
    ) {
      status
      success
      data {
        id
        name
        account_id
        deposit_id
        global_account_id
        transaction_id
        ccy
        amount
        balance
        reference
        createdAt
        updatedAt
        status
        invoice_numbers
        customer_name
      }
    }
  }
`;

export const getLegalEntities = gql`
  query admin_search_legal_entities($text: String!) {
    admin_search_legal_entities(text: $text) {
      id
      name
      legal_name
      vat_number
      invoice_currency
      location {
        id
        name
        company_name
        address1
        address2
        city
        state
        country
        postalCode
        phone1
      }
    }
  }
`;
