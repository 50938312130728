import React, { useCallback } from 'react';
import { Button, ClickOutside, SuccessModal, Tooltip } from '@nivoda/ui';
import { Icon } from 'fabric/resources/icon';
import styled, { css } from 'styled-components';
import { copyValue } from '@nivoda/common';
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { customArrayOfEnumValidator, dummyFn } from '../../utils';
import { CustomTrans, downloadImageFile } from '../../helpers';
import {
  getGemsVideoDownloadLink,
  getVideoDownloadLink,
} from '../../graphql/queries/common/diamond-asset';
import { round } from 'lodash/math';
import { Track } from 'fabric/integrations';
import theme from '../../fabric/theme';
import trackers from '../../fabric/trackers';
import { downloadTypes, infoTypes, shareTypes } from '../../constants/download';
import { ProductTypes, notificationTypes } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'fabric/hooks';
import { ShareModal } from 'components/share-modal';
import capitalize from 'lodash/capitalize';
import { ErrorBoundary } from 'ErrorBoundary';
import noop from 'lodash/noop';
import { share_detail_list } from 'components/share-modal/helper';
import { useUserContext } from 'context/userContext';
import { commonThemeCSS } from 'components/navbar-filters/style';
import { useFloating } from '@floating-ui/react';
import { autoPlacement } from '@floating-ui/react';
export const DOWNLOAD_WAIT_TIME = 15;

export const download__assets_action_list = [
  {
    label: 'Copy image link',
    translated_key: 'copy_image_link',
    id: downloadTypes.COPY_IMAGE,
    icon: 'copy_link',
  },

  {
    label: 'Copy video link',
    id: downloadTypes.COPY_VIDEO,
    icon: 'copy_link',
    translated_key: 'copy_video_link',
  },
  {
    label: 'Download image',
    id: downloadTypes.DOWNLOAD_IMAGE,
    icon: 'download_v3',
    translated_key: 'download_image',
  },
  {
    label: 'Download video',
    id: downloadTypes.DOWNLOAD_VIDEO,
    icon: 'download_v3',
    translated_key: 'download_video',
  },
];

export const download__assets_action_category = [
  {
    id: 'SHARE_VIA',
    heading: 'share_via',
    hasBorder: true,
    actions: [
      {
        label: 'WhatsApp',
        translated_key: 'WhatsApp',
        id: shareTypes.WHATSAPP,
        icon: 'whats_app',
      },
      {
        label: 'Email',
        id: shareTypes.EMAIL,
        icon: 'email_v2',
        translated_key: 'Email',
      },
      // {
      //   label: 'SMS',
      //   id: shareTypes.SMS,
      //   icon: 'sms',
      //   translated_key: 'SMS',
      // },
    ],
  },
  {
    id: 'COPY_INFO',
    heading: '',
    hasBorder: true,
    actions: [
      {
        label: 'Copy info',
        translated_key: 'copy_info',
        id: infoTypes.COPY_INFO,
        icon: 'copy',
        icon_size: 16,
      },
    ],
  },
  {
    id: 'COPY_LINK',
    heading: 'copy_link',
    hasBorder: true,
    actions: [
      {
        label: 'Copy image link',
        translated_key: 'copy_image_link',
        id: downloadTypes.COPY_IMAGE,
        icon: 'copy',
        icon_size: 16,
      },
      {
        label: 'Copy video link',
        id: downloadTypes.COPY_VIDEO,
        icon: 'copy',
        icon_size: 16,
        translated_key: 'copy_video_link',
      },
    ],
  },
  {
    id: 'DOWNLOAD_MEDIA',
    heading: 'download_media',
    hasBorder: true,
    actions: [
      {
        label: 'Download image',
        id: downloadTypes.DOWNLOAD_IMAGE,
        icon: 'download_v3',
        icon_size: 16,
        translated_key: 'download_image',
      },
      {
        label: 'Download video',
        id: downloadTypes.DOWNLOAD_VIDEO,
        icon: 'download_v3',
        icon_size: 16,
        translated_key: 'download_video',
      },
    ],
  },
  // {
  //   id: 'IMAGE',
  //   heading: 'image',
  //   hasBorder: true,
  //   actions: [
  //     {
  //       label: 'Copy image link',
  //       translated_key: 'copy_image_link',
  //       id: downloadTypes.COPY_IMAGE,
  //       icon: 'copy',
  //     },
  //     {
  //       label: 'Download image',
  //       id: downloadTypes.DOWNLOAD_IMAGE,
  //       icon: 'download_v3',
  //       translated_key: 'download_image',
  //     },
  //   ],
  // },
  // {
  //   id: 'VIDEO',
  //   heading: 'video',
  //   hasBorder: false,
  //   actions: [
  //     {
  //       label: 'Copy video link',
  //       id: downloadTypes.COPY_VIDEO,
  //       icon: 'copy',
  //       translated_key: 'copy_video_link',
  //     },

  //     {
  //       label: 'Download video',
  //       id: downloadTypes.DOWNLOAD_VIDEO,
  //       icon: 'download_v3',
  //       translated_key: 'download_video',
  //     },
  //   ],
  // },
];

export function countdown(seconds = 0, setProgress = () => {}) {
  if (typeof seconds !== 'number') {
    // eslint-disable-next-line no-console
    console.warn('No value provided for second');
  } else {
    const tick = () => {
      seconds--;
      if (seconds > 0) {
        setProgress(DOWNLOAD_WAIT_TIME - seconds);
        setTimeout(tick, 1000);
      }
    };
    tick();
  }
}

export const getDownloadLabel = (props) => {
  const {
    action,
    processing: { status },
  } = props;

  return action.id === downloadTypes.DOWNLOAD_VIDEO
    ? status === 'PROCESSING'
      ? props.trans('generating_link')
      : status === 'COMPLETE'
      ? 'Download video'
      : props.trans(action.translated_key)
    : props.trans(action.translated_key);
};

export function getAssetList(diamond = {}) {
  return [
    diamond?.certificate?.image ? downloadTypes.COPY_IMAGE : '',
    diamond?.certificate?.v360 ? downloadTypes.COPY_VIDEO : '',
    diamond?.certificate?.image ? downloadTypes.DOWNLOAD_IMAGE : '',
    diamond?.certificate?.v360 ? downloadTypes.DOWNLOAD_VIDEO : '',
  ].filter(Boolean);
}

export const handleActionClick = async (
  {
    cert_id,
    certNumber,
    downloadStateHandler,
    imageUrl,
    source,
    video_url,
    isGemstoneItem = false,
  },
  e
) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  const {
    target: {
      dataset: { targetId },
    },
  } = e;
  let producttype = isGemstoneItem ? 'gemstone' : 'diamond';
  if (targetId === infoTypes.COPY_INFO) {
    Track.track(trackers.assets.copy.info);

    await downloadStateHandler({
      message: <CustomTrans text="info_copied" returnPlainText />,
      percent: 100,
      progressbar_color: theme.semanticColors.notificationProgressColor,
      status: notificationTypes.completed,
      source,
    });
  }
  if (targetId === downloadTypes.COPY_IMAGE) {
    Track.track(trackers.assets.copy.image);

    await copyValue(
      null,
      `https://loupe360.com/${producttype}/${cert_id}/img/500/500`
    );

    await downloadStateHandler({
      message: <CustomTrans text="image_link_copied" returnPlainText />,
      percent: 100,
      progressbar_color: theme.semanticColors.notificationProgressColor,
      status: notificationTypes.completed,
      source,
    });
  }

  if (targetId === downloadTypes.COPY_VIDEO) {
    Track.track(trackers.assets.copy.video);

    await copyValue(
      null,
      `https://loupe360.com/${producttype}/${cert_id}/video/500/500/autoplay`
    );

    await downloadStateHandler({
      message: <CustomTrans text="video_link_copied" returnPlainText />,
      percent: 100,
      progressbar_color: theme.semanticColors.notificationProgressColor,
      status: notificationTypes.completed,
      source,
    });
  }

  if (targetId === downloadTypes.DOWNLOAD_IMAGE) {
    Track.track(trackers.assets.download.image);

    await downloadImageFile({
      cert_number: certNumber,
      downloadHandler: downloadStateHandler,
      type: 'IMAGE',
      source,
      url: imageUrl,
    });
  }

  if (targetId === downloadTypes.DOWNLOAD_VIDEO && video_url) {
    Track.track(trackers.assets.download.video);

    const url = video_url;
    await downloadImageFile({
      cert_number: url.split('/')[url.split('/').length - 1],
      downloadHandler: downloadStateHandler,
      source,
      type: 'VIDEO',
      url,
    });
  }
};

export function GenerateLinkModal(props) {
  let { t } = useTranslation();
  return (
    <SuccessModal
      className="download_popup"
      contentStyle={modal_styles}
      customAction={
        props.loaded ? (
          <IconDiv>
            <button
              className={'btn btn-md'}
              data-target-id={downloadTypes.DOWNLOAD_VIDEO}
              onClick={props.onClick}
            >
              {t('download')}
            </button>
          </IconDiv>
        ) : (
          <span />
        )
      }
      customIcon={
        !props.loaded ? (
          <IconDiv>
            <span className={`svg_icon ${props.hasError ? 'error' : ''}`}>
              <Icon
                color={theme.palette.themePrimary}
                name={props.hasError ? 'error_icon' : 'sand-clock'}
                size={20}
              />
            </span>
          </IconDiv>
        ) : (
          ''
        )
      }
      onClose={props.onClose}
      subtitle={props.subtitle}
      title={props.title}
    />
  );
}

GenerateLinkModal.propTypes = {
  loaded: bool,
  onClick: func,
  hasError: bool,
  onClose: func,
  subtitle: oneOfType([string, object]),
  title: string,
};

export function DownloadAssets(props) {
  //
  //  DownloadAssets State
  // ----------------------------------------------------------------------
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = React.useState(false);
  const [open_share_modal, set_open_share_modal] = React.useState('');
  const { refs, floatingStyles } = useFloating({
    open: showPopover,
    onOpenChange: setShowPopover,
    middleware: [
      autoPlacement({
        crossAxis: true,
        alignment: 'bottom-start',
        allowedPlacements: ['bottom-start', 'bottom-end'],
      }),
    ],
  });

  const wrapperRef = React.useRef(null);
  useOnClickOutside(wrapperRef, () => {
    setShowPopover(false);
  });
  const [processing, setProcessing] = React.useState({
    video_link: null,
    status: '',
  });
  const [progress, setProgress] = React.useState(0);

  const is_processing = processing.status === 'PROCESSING';
  const has_error = processing.status === 'ERROR';
  const loaded = processing.status === 'COMPLETE';
  const productType = props.productType || ProductTypes.DIAMOND;
  const { userInReview } = useUserContext();
  let isGemstoneItem = productType === ProductTypes.GEMSTONE;
  const isRoleSupplier = window.location.pathname.includes('/supplier/search');

  //
  // DownloadAssets Props
  // ----------------------------------------------------------------------
  const {
    assetData,
    assetList,
    downloadStatus,
    setDownloadStatus,
    view,
    showOnlyButtons = false,
    isPDP = false,
    isGemstone = false,
    isList = false,
    notAvailable = false,
    isMemoMode,
    themeColor,
    themeColor2,
    themeBg,
  } = props;
  //
  // DownloadAssets Getters
  // ----------------------------------------------------------------------
  const getSubtitle = useCallback(() => {
    return is_processing ? (
      <span className={'loading_message'}>
        <Icon name={'loader'} color={theme.palette.themePrimary} size={16} />
        <span>
          {t('processing_video_for_download')}...
          {round((progress / DOWNLOAD_WAIT_TIME) * 100)}%
        </span>
      </span>
    ) : loaded ? (
      t('video_link_generated')
    ) : has_error ? (
      t('generating_video_link_fail')
    ) : (
      ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [has_error, loaded, is_processing, progress]);

  const getTitle = useCallback(
    () =>
      !loaded && is_processing
        ? `${t('generating_link')} `
        : `${t('link_for')} ${t('video')}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded, is_processing, assetData.certNumber]
  );

  //
  // DownloadAssets Change Handlers
  // ----------------------------------------------------------------------
  const handleAction = async (targetId, e) => {
    e.persist();

    if (targetId === downloadTypes.DOWNLOAD_VIDEO) {
      await startGenerateVideoReq();
    }

    if (
      targetId === shareTypes.WHATSAPP ||
      targetId === shareTypes.EMAIL ||
      targetId === shareTypes.SMS
    ) {
      set_open_share_modal(targetId);
      Track.track(`${capitalize(targetId)} share opened.`);
    }

    if (targetId === infoTypes.COPY_INFO) {
      const share_list = share_detail_list({ data: props.diamond, t });
      const selectedDetails = share_list.filter((detail) => detail.isSelected);
      const description =
        selectedDetails?.length > 0
          ? selectedDetails.filter((detail) => detail.isBold)
          : [];

      const textToCopy = `${description
        .map((dec) => dec.value)
        .join(' ')}\n\n${selectedDetails
        .slice(1)
        .map((item) => `${item.translated_key}: ${item.value}`)
        .join('\n')}\n${t('total_price')}: ${props.diamond.price}\nImage URL: ${
        assetData?.imageUrl || ''
      }`;

      navigator.clipboard.writeText(textToCopy);
    }

    // trigger the actual download
    handleActionClick.bind(null, {
      ...assetData,
      downloadStateHandler: setDownloadStatus,
      setProcessing,
      source: downloadStatus?.source,
      video_url: processing.video_link,
      isGemstoneItem,
    })({
      preventDefault: e.preventDefault || dummyFn,
      stopPropagation: e.stopPropagation || dummyFn,
      target: { dataset: { targetId } },
    });
  };

  const handleClick = useCallback(
    async () => await setShowPopover(!showPopover),
    [showPopover]
  );

  const handleClose = useCallback(
    async (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      await setProcessing({ ...processing, status: '' });
    },
    [processing]
  );

  const startGenerateVideoReq = useCallback(async () => {
    try {
      // show generating link modal
      await setProcessing({ ...processing, status: 'PROCESSING' });
      // start the percentage counter
      countdown(DOWNLOAD_WAIT_TIME - 0.5, setProgress);

      // call generate link query on BE
      const { data, loading } = isGemstoneItem
        ? await getGemsVideoDownloadLink(assetData.cert_id)
        : await getVideoDownloadLink(assetData.cert_id);

      const key = isGemstoneItem
        ? 'get_gemstone_video_download_link'
        : 'get_video_download_link';
      // success
      if (!loading && data && data[key]) {
        await setProcessing({
          ...processing,
          status: 'COMPLETE',
          video_link: data[key],
        });
        // set percentage to 100%
        await setProgress(DOWNLOAD_WAIT_TIME);
      }
    } catch (e) {
      // handle error
      // eslint-disable-next-line no-console
      console.error(
        'Not cool, Something went wrong while downloading video',
        e
      );
      await setProcessing({ ...processing, status: 'ERROR' });
      await setProgress(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData.certNumber, processing]);

  const temp_assetList =
    isGemstone || isRoleSupplier
      ? [...assetList]
      : ['WHATSAPP', 'SMS', 'EMAIL', 'COPY_INFO', ...assetList];
  const downloadAssetsFilteredCategories = download__assets_action_category
    .filter(
      (category) =>
        !(
          userInReview &&
          (category.id === 'SHARE_VIA' || category.id === 'COPY_INFO')
        )
    )
    .filter((category) =>
      category.actions.some((action) => temp_assetList.includes(action.id))
    );
  const shareAssetsCategories = download__assets_action_category.filter(
    (category) => category.id === 'SHARE_VIA'
  );

  const button_mapper_function = (action) => {
    const is_video_processing =
      action.id === downloadTypes.DOWNLOAD_VIDEO && is_processing;

    return (
      <CustomBtnWithIcon
        key={action.id}
        className={'diamond_download__action'}
        disabled={is_processing || notAvailable || false}
        btnProps={{
          customPadding: '6px 4px',
          hasBorder: false,
        }}
        iconProps={{
          color: is_video_processing ? theme.palette.themePrimary : '#bdbdbd',

          name: is_video_processing ? 'loader' : action.icon,
          noShadow: true,
          size: is_video_processing ? 20 : action?.icon_size || 20,
        }}
        actionId={action.id}
        label={getDownloadLabel({ action, processing, trans: t })}
        onClick={handleAction}
        title={t(action?.translated_key || action.label)}
      />
    );
  };

  const category_mapper_function = (category, index) => {
    const categories = isPDP
      ? shareAssetsCategories
      : downloadAssetsFilteredCategories;
    return (
      <React.Fragment key={category.id}>
        <div className="diamond_download--category">
          {category.heading && (
            <div className="diamond_download--category_heading">
              {t(category.heading)}
            </div>
          )}
          {category.actions
            .filter((asset) => temp_assetList.includes(asset.id))
            .map(button_mapper_function)}
        </div>
        {categories.length - 1 !== index && (
          <div className="diamond_download--category_divider" />
        )}
      </React.Fragment>
    );
  };
  let OpenDropdown = ({ categories, customStyle }) =>
    showPopover && (
      <ClickOutside active={showPopover} onClickOutside={handleClick}>
        <DownloadMediaDropdown
          ref={refs.setFloating}
          style={floatingStyles}
          {...customStyle}
        >
          {categories.map(category_mapper_function)}
        </DownloadMediaDropdown>
      </ClickOutside>
    );

  return (
    <Div
      className={`diamond_info__download ${
        view ? (view || '').toLowerCase() + '_view' : ''
      }`}
      ref={wrapperRef}
      isPDP={isPDP}
      notAvailable={notAvailable}
      themeColor={themeColor}
      themeColor2={themeColor2}
      themeBg={themeBg}
    >
      <>
        {!showOnlyButtons && !isList && (
          <div
            onClick={handleClick}
            className="image-icon"
            ref={refs.setReference}
          >
            {props.has_tooltip && showPopover ? (
              <Tooltip
                style={{ display: 'flex', alignItems: 'center' }}
                content={
                  <div style={{ width: 80, textAlign: 'center' }}>
                    {t('download_share')}
                  </div>
                }
                radius={3}
                placement={'bottom'}
              >
                <Icon
                  name={isRoleSupplier ? 'download_v3' : 'share_v2'}
                  size={20}
                  className="download_v3"
                />
              </Tooltip>
            ) : (
              <Icon
                name={isRoleSupplier ? 'download_v3' : 'share_v2'}
                size={20}
                className="download_v3"
              />
            )}
          </div>
        )}
        {!showOnlyButtons && isList && (
          <Tooltip
            content={
              <div style={{ width: 120, textAlign: 'center' }}>
                {isRoleSupplier ? 'Download' : t('download_share')}
              </div>
            }
            radius={3}
            placement={'bottom'}
            onClick={notAvailable ? noop : handleClick}
          >
            <div className="share__btn">
              <Icon
                name={isRoleSupplier ? 'download_v3' : 'share_v2'}
                size={16}
                color={theme.palette.gray800}
                className="download_v3"
              />
              <span className="share__btn--label">
                {isRoleSupplier ? 'Download' : t('share_this_item')}
              </span>
            </div>
          </Tooltip>
        )}
        {showOnlyButtons ? (
          <React.Fragment>
            <div className={`diamond_info__tooltip`}>
              {!isRoleSupplier && isPDP && !isMemoMode ? (
                <CustomBtnWithIcon
                  className={'diamond_download__action'}
                  disabled={is_processing || notAvailable || false}
                  btnProps={{
                    customPadding: '6px 4px',
                    hasBorder: false,
                  }}
                  iconProps={{
                    color: '#bdbdbd',
                    name: 'share_v2',
                    noShadow: true,
                    size: 24,
                  }}
                  width={'100%'}
                  labelStyle={{ width: 'auto' }}
                  actionId={'SHARE_VIA'}
                  label={t('share_this_item')}
                  onClick={handleClick}
                  title={t('share_this_item')}
                />
              ) : null}

              {assetList.length > 0 &&
                download__assets_action_list
                  .filter((asset) => assetList.includes(asset.id))
                  .map(button_mapper_function)}
            </div>

            <React.Fragment>
              {OpenDropdown({
                categories: shareAssetsCategories,
                customStyle: {
                  top: 0,
                  right: '-10px',
                  left: 'calc(100% + 10px) !important',
                  color: '#000',
                },
              })}
            </React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {OpenDropdown({
              categories: downloadAssetsFilteredCategories,
              customStyle: isList
                ? {
                    top: '50% !important',
                    right: 'calc(100% + 10px) !important',
                    color: '#000',
                    transform: 'translateY(-50%) !important',
                    left: 'unset !important',
                  }
                : '',
            })}
          </React.Fragment>
        )}
      </>
      {processing.status !== '' && (
        <GenerateLinkModal
          hasError={has_error}
          loaded={loaded}
          onClick={handleAction.bind(null, downloadTypes.DOWNLOAD_VIDEO)}
          onClose={handleClose}
          subtitle={getSubtitle()}
          title={getTitle()}
        />
      )}
      {open_share_modal !== '' && (
        <ErrorBoundary silent={true}>
          <ShareModal
            clearModalState={() => set_open_share_modal('')}
            share_video_preview={props.share_video_preview}
            data={props.diamond}
            shareType={open_share_modal}
            isOpen={open_share_modal !== ''}
            view_details_link={props.view_details_link}
            handleAction={handleAction}
          />
        </ErrorBoundary>
      )}
    </Div>
  );
}

DownloadAssets.propTypes = {
  assetList: arrayOf(
    customArrayOfEnumValidator.bind(null, [
      downloadTypes.COPY_IMAGE,
      downloadTypes.COPY_VIDEO,
      downloadTypes.DOWNLOAD_IMAGE,
      downloadTypes.DOWNLOAD_VIDEO,
    ])
  ),
  assetData: shape({
    certNumber: string,
    imageUrl: string,
    video: object,
  }),
  setDownloadStatus: func,
  view: string,
};

export const CustomBtnWithIcon = (props) => (
  <Button
    className={`${props.className}--btn`}
    disabled={props.disabled || false}
    ghost
    customPadding={props.btnProps.customPadding}
    noBorder
    onClick={(e) => props.onClick(props.actionId, e)}
    style={{
      border: props.btnProps.hasBorder
        ? `1px solid ${
            props.btnProps.active ? theme.palette.themePrimary : '#eeeeee'
          }`
        : '',
      width: props.width ? props.width : '',
    }}
    title={props.title}
    data-target-id={props.actionId || ''}
  >
    <Icon
      className={`${props.className}_svg`}
      name={props.iconProps.name}
      data-target-id={props.actionId || ''}
      {...props.iconProps}
    />
    {!!props.label && (
      <span
        className={`${props.className}--label`}
        data-target-id={props.actionId || ''}
        style={props.labelStyle}
      >
        {props.label}
      </span>
    )}
  </Button>
);

const Div = styled.div`
  & {
    position: relative;
    .image-icon {
      height: 31px;
      width: 31px;
      margin-top: -6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      .download_v3 {
        color: #a1a1aa;
        stroke-width: 1.25;
      }
      :hover {
        cursor: pointer;
        /* border: 1px solid #c2dbfc; */
        border-radius: 50%;
        /* background: #e9f2fd; */
        .download_v3 {
          color: #3f3f46;
        }
      }
    }
    .share__btn {
      width: 100%;
      padding: 8px 16px;
      background: ${({ notAvailable }) =>
        notAvailable ? theme.palette.backgroundColor : theme.palette.white};
      border: 1px solid
        ${({ notAvailable }) =>
          notAvailable ? theme.palette.neutral200 : theme.palette.gray300};
      color: ${({ notAvailable }) =>
        notAvailable ? theme.palette.neutral400 : theme.palette.gray800};
      border-radius: 8px;
      margin-top: 16px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 12px;
      gap: 6px;
      cursor: pointer;
      &:hover {
        ${commonThemeCSS()};
      }
      &:active {
        ${commonThemeCSS()};
        background: ${({ themeBg }) => themeBg} !important;
      }
    }
    .diamond_info {
      &__tooltip {
        width: max-content;
        height: auto;
        display: grid;
        min-width: 220px;
        width: max-content;
        height: auto;
        &__content {
          display: grid;
          gap: 8px;
        }
        .btn {
          cursor: ${({ notAvailable }) =>
            notAvailable ? 'not-allowed !important' : 'pointer'};
        }
        .btn:hover {
          svg {
            * {
              color: ${({ notAvailable }) =>
                notAvailable ? theme.palette.neutral400 : '#ffffff'};
            }
          }
        }
        .btn:disabled {
          opacity: 1 !important;
        }
      }

      &__download--btn {
        & + div > div > div:not(.animate) {
          transform: translateX(-95%);
          filter: drop-shadow(0px 10px 50px rgba(22, 22, 22, 0.1));
        }
      }
    }
    .diamond_download {
      &__category {
        display: grid;
        gap: 8px;
        padding: 0 17px 12px;
        &__heading {
          text-transform: capitalize;
          color: ${theme.palette.gray400};
        }
      }
      &__category:first-child {
        border-bottom: 1px solid #eeeeee;
      }
      &__action {
        &--btn {
          margin: 0;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: flex-start;

          .diamond_download__action--label {
            border-bottom: ${({ notAvailable }) =>
              notAvailable ? `none` : `1px dashed transparent`};
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:hover {
            box-shadow: none;
            background: #e9f2fd;
            svg path {
              color: ${(props) =>
                props.isPDP ? '#fff' : theme.palette.themePrimary};
            }
            & .diamond_download__action--label {
              border-bottom: 1px dashed #eeeeee;
            }
          }
        }
      }
    }
  }

  &.diamond_info__download.list_view {
    .diamond_info__download--btn {
      border: none !important;
    }
    .diamond_info__download--btn + div > div > div {
      margin-top: 0;
      transform: translateX(-83%);
    }
  }
`;

const IconDiv = styled.div`
  min-width: 500px;
  text-align: center;
  justify-content: center;

  @media (max-width: 700px) {
    min-width: max-content;
  }

  .svg_icon {
    display: inline-flex;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #f5f5f5;
    justify-content: center;
    align-items: center;

    &.error {
      background: #ffcece;
    }
  }
`;

const DownloadMediaDropdown = styled.div`
  position: absolute;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  transform: ${({ transform }) => transform};
  margin-top: 10px;
  z-index: 10;
  display: flex;
  width: 220px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  .diamond_download--category_divider {
    width: 100%;
    height: 1px;
    background: #f4f4f5;
    margin: 4px 0px;
  }
  .diamond_download--category {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .diamond_download__action--btn {
      display: flex;
      width: 100% !important;
      height: 36px !important;
      padding: 8px 12px !important;
      align-items: center !important;
      gap: 8px !important;
      align-self: stretch !important;
      &:hover {
        background: #fafafa !important;
        .diamond_download__action--label {
          border: none !important;
        }
      }
      .diamond_download__action_svg,
      .diamond_download__action_svg path {
        color: #27272a !important;
      }
      .diamond_download__action--label {
        width: auto !important;
        color: #27272a;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        border: none !important;
      }
    }
  }

  .diamond_download--category_heading {
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    color: #a1a1aa;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
`;

const modal_styles = css`
  ._terms {
    &-info {
      .loading_message {
        display: flex;
        margin: 0;
        align-items: center;

        svg {
          margin-bottom: 3px;
          margin-right: 1px;

          @media (max-width: 700px) {
            align-self: baseline;
            margin-top: 4px;
          }
        }
      }
    }

    span {
      margin-left: 0;
      width: max-content;
      @media (max-width: 700px) {
        width: 100%;
      }
    }

    &._success {
      justify-content: center;
    }
  }
`;
