import React from 'react';
import { mockUUID } from '../../utils';
import { Icon } from '../../resources/icon';
import theme from '../../theme';
import cx from 'classnames';
import { bool, func, number, oneOfType, string } from 'prop-types';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks';
import { Tooltip } from '@nivoda/ui';
import CopyToClipboard from 'NewDashboard/components/copy-to-clipboard';
import { useTranslation } from 'react-i18next';

/**
 * @summary Edit Input with save check mark button
 * @param {string[]} props.customStyle css style in string form
 * @param {string} props.dataInputId data attribute for input
 * @param {string|number} props.iconSize1 edit icon size
 * @param {string|number} props.iconSize2 copy icon size
 * @param {function} props.onBlur onBlur handler
 * @param {function} props.onChange onBlur handler
 * @param {string} props.title title for ally
 * @param {string} props.type input type
 * @returns {JSX.Element}
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 * @constructor
 */
export const EditInput = (props) => {
  const [isInputActive, setIsInputActive] = React.useState(false);
  const ref = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [showSave, setShowSave] = React.useState(false);
  const [value, setValue] = React.useState(props.value || '');
  const { t } = useTranslation();
  const handleOnBlur = (e) => {
    if (typeof props.onBlur === 'function') {
      props.onBlur(e, value);
    }
  };

  const handleOnChange = (e) => {
    setValue(e.target.value);
    if (typeof props.onChange === 'function') {
      props.onChange(e, value);
    }
  };

  const handleSave = async (valueOnOutClick) => {
    if (typeof props.onSave === 'function' && valueOnOutClick) {
      try {
        setShowSave(true);
        setLoading(true);
        await props.onSave({
          endpoint: props.endpoint,
          id: props.id,
          value: valueOnOutClick,
        });

        setLoading(false);
        setIsInputActive(false);
        setShowSave(false);
      } catch (e) {
        setLoading(false);
        setIsInputActive(false);
        setShowSave(false);
        // eslint-disable-next-line no-console
        console.log('Error while saving customer order reference', e);
      }
    }
  };

  const onClickOutSideSave = async () => {
    // Call save only if value is changed (as compared to db)
    if (props.value !== value) {
      await handleSave(value);
    } else {
      setIsInputActive(false);
      setShowSave(false);
    }
  };

  const handleToggleInput = () => {
    // if disabled then can't edit
    if (!props.disabled) {
      setIsInputActive((prevState) => !prevState);
    }
  };

  const id = mockUUID();

  // Call save when clicked outside of input
  useOnClickOutside(ref, onClickOutSideSave);

  return (
    <>
      {value.length !== 0 && (
        <StyledWrapper customStyle={props.customStyle} showSave={showSave}>
          <div className={'edit_input__value'}>
            <span>
              {value.length > 12 ? value.slice(0, 12) + '...' : value}
            </span>
          </div>
          <div className="edit_input__icons">
            {props.withCopyText ? (
              <CopyToClipboard
                iconName={'copyIcon'}
                iconHeight={12}
                iconWidth={12}
                text={value}
                placement="bottom"
              />
            ) : (
              <label className={'edit_input__label'} htmlFor={id}>
                {value || props.placeholder || 'Enter value'}
              </label>
            )}

            {!isInputActive &&
              props.internalOrderNo &&
              !props.disabled &&
              value.length !== 0 && (
                <Tooltip
                  placement={'right'}
                  offset={-2}
                  radius={3}
                  content={
                    <div className="tooltip__content">{t('click_to_edit')}</div>
                  }
                >
                  <Icon
                    className={'edit_input__icon'}
                    hoverColor={theme.palette.themePrimary}
                    name={'edit'}
                    size={props.iconSize1 || 11}
                    onClick={handleToggleInput}
                  />
                </Tooltip>
              )}
          </div>

          <div className={'edit_input__container form-group'}>
            {isInputActive && (
              <input
                id={id}
                ref={ref}
                className={cx('form-control edit_input__box')}
                data-input-id={props.dataInputId || ''}
                disabled={loading}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                placeholder={props.placeholder || 'Enter value'}
                type={props.type || 'text'}
                value={value}
                autoFocus
              />
            )}

            {showSave && (
              <Icon
                className={'edit_input__save'}
                color={theme.palette.green}
                name={'ok_icon'}
                size={props.iconSize2 || 16}
                onClick={handleSave}
                role="button"
                title="Click to save"
              />
            )}
          </div>
        </StyledWrapper>
      )}
    </>
  );
};

EditInput.propsTypes = {
  dataInputId: string,
  iconSize1: string,
  iconSize2: string,
  onBlur: func,
  onChange: func,
  placeholder: string,
  withCopyText: bool,
  value: oneOfType([string, number]),
};

const StyledWrapper = styled.div`
  & {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;

    .edit_input {
      &__box {
        width: max-content;
        min-width: 100px;
        height: 16px;
        padding: ${theme.spacing.s3} 0 ${theme.spacing.s3} ${theme.spacing.s2};
        margin: ${theme.spacing.s1}
          ${({ showSave }) => (showSave ? 0 : theme.spacing.s1)}
          ${theme.spacing.s1} ${theme.spacing.s1};
        border-radius: ${theme.effects.roundedCorner2};
        ${theme.fonts.absolute.small};
        @media (max-width: 700px) {
          ${theme.fonts.absolute.tiny};
        }
      }

      &__container {
        border-radius: ${theme.effects.roundedCorner2};
        background: ${theme.palette.white};
        display: flex;
        align-items: center;
        gap: ${theme.spacing.s1};
        position: absolute;
        top: 0;
        left: 0;
        // transform: translateY(-50%);
        width: 100%;
      }

      &__label {
        margin-bottom: 0;
      }

      &__save {
        margin-right: ${theme.spacing.s1};
      }

      &__value {
        display: inline-flex;
        align-items: center;
        gap: ${theme.spacing.s2};
      }

      &__box {
        margin: 0 !important;
      }
      &__icons {
        display: flex;
        gap: 6px;
      }

      &__icon {
        margin-top: 0;
      }
    }
    ${({ customStyle = '' }) => customStyle}
  }
`;
