import React from 'react';
import { languages } from 'helpers';
import { useTranslation } from 'react-i18next';
import { memo, useContext, useEffect } from 'react';
import { LanguageContext } from 'graphql/factory/Providers';
import { theme } from '../../fabric/theme';
import { UpdateUserProfile } from 'components/settings/settings-page';
import { get_current_session } from 'graphql/factory';
import { getUserReviewStatus } from 'utils/user';
import { useUserContext } from 'context/userContext';
import { pick } from 'lodash';
import { CustomReactSelect } from 'fabric/components/CustomReactSelect';

export const LanguageChanger = memo(
  ({ is_public, showname, is_login, custom_style }) => {
    const session = get_current_session();

    return (
      <>
        {!is_public && session && session.token ? (
          <UpdateUserProfile>
            {(update_user) => (
              <LanguageChangerBox
                update_user={update_user}
                is_public={false}
                showname={showname}
                custom_style={custom_style}
              />
            )}
          </UpdateUserProfile>
        ) : (
          <LanguageChangerBox
            is_public
            showname={showname}
            is_login={is_login}
            custom_style={custom_style}
          />
        )}
      </>
    );
  }
);

export const LanguageChangerBox = ({
  update_user,
  is_public,
  is_login,
  custom_style,
}) => {
  const { i18n } = useTranslation();
  const data = useContext(LanguageContext);
  const { language, setLanguage } = data;

  let { me } = useUserContext();
  let { is_user_in_review } = getUserReviewStatus(me);

  // let Deutsch =
  //   me?.role === 'ADMIN' ? { value: 'de', label: 'Deutsch', flag: 'DE' } : null;
  // let languages = [...def_language, Deutsch].filter(Boolean);
  useEffect(() => {
    let locLanguage = JSON.parse(localStorage.getItem('preferred_language'));
    if (locLanguage && locLanguage.value) {
      let selected_language = languages.find(
        (lang) => lang?.value === locLanguage?.value
      );
      setLanguage(selected_language);
    } else {
      setLanguage({ value: 'en', label: 'English', flag: 'GB' });
    }
    //eslint-disable-next-line
  }, []);

  const handleClick = async (user, method, item) => {
    localStorage.setItem('preferred_language', JSON.stringify({ ...item }));
    i18n.changeLanguage(item?.value);
    data && setLanguage(item);
    // let is_owner = user?.company?.is_owner || false;

    // if user is logged in then we want to update the user's BE data when changed
    if (!is_public && !is_user_in_review) {
      try {
        let user_profile = user;
        const { id, __typename, profile_image, company, ...current_user } =
          user_profile;

        const payload = pick(current_user, [
          'country',
          'default_currency',
          'email',
          'firstName',
          'lastName',
        ]);
        method &&
          (await method({
            user: {
              ...payload,
              preferred_language: item?.value.toUpperCase(),
            },
          }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`Error:`, err);
      }
    }
  };

  const defaultStyle = {
    control: (base, state) => ({
      ...base,
      border: 'none !important',
      outline: state.isFocused && 'none',
      boxShadow: 'none !important',
      background: !is_public
        ? state.menuIsOpen && 'rgba(24, 24, 27, 0.08)'
        : 'rgba(255, 255, 255, 0.05)',
      height: 40,
      padding: is_public ? (is_login ? '0' : '0 0 0 20px') : '0 2px',
      justifyContent: 'center',
      borderRadius: is_public ? '150px' : '5px',
      cursor: 'pointer',
      '&:hover': {
        background: !is_public
          ? !state.menuIsOpen && 'rgba(24, 24, 27, 0.03)'
          : 'rgba(24, 24, 27, 0.03)',
        // : 'rgba(255, 255, 255, 0.1)',
        cursor: 'pointer',
      },
      width: is_public && is_login && '125px',
      ...custom_style,
    }),
    singleValue: (base) => ({
      ...base,
      color: `${
        is_public
          ? is_login
            ? theme.palette.Neutrals950
            : theme.palette.white
          : theme.palette.gray700
      }  !important`,
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flex: '1 1 0% !important',
      gap: '6px',
      padding: '2px 1px',
      paddingLeft: 6,
      fontWeight: is_public ? '600' : '700',
      fontSize: is_public ? '16px' : '12px',
      // padding: 0,
    }),
    menu: (base) => ({
      ...base,
      width: 150,
      right: 0,
      maxHeight: 'none',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: is_public ? '0 15px 0 0' : 0,
    }),
  };

  // TODO: when the user.preferred_language is different from the localstorage value we need to ask  the user to either update the languages
  return (
    <CustomReactSelect
      options={languages}
      value={language}
      styles={defaultStyle}
      leftIconProps={
        !is_public
          ? {
              iconProps: {
                name: 'shipping-v2',
                color: theme.palette.gray700,
                size: 18,
              },
            }
          : {}
      }
      components={
        {}
        // { ValueContainer: () => null }
      }
      isClearable={false}
      isSearchable={false}
      onChange={(value) => {
        handleClick(me, update_user, value);
      }}
      rightIconProps={{
        color: is_public
          ? is_login
            ? theme.palette.Neutrals950
            : theme.palette.white
          : '#27272A',
        size: is_public ? 24 : 18,
      }}
    />
  );
};
