import { useState, useEffect } from 'react';

/**
 * This is just a hook for controlling transition on page
 *
 * To apply the transition itself, correct props should be added to styling file.
 * @returns state action
 */
export const useTransitionStatus = () => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    active,
    setActive,
  };
};
