import { useState, useEffect } from 'react';
import { SignupContainer } from '../../../Signup/SignupContainer';
import { CustomTrans, get_cookie } from 'helpers';
import { Trans, useTranslation } from 'react-i18next';
import {
  countryOptions,
  customerTitleOptions,
  getCompanyOptions,
} from './helper.js';
import { BusinessInfoStyleWrapper } from '../styles';
import { isURL, useAsState } from 'utils';
import { INITIALISE_STEPTWO } from 'graphql/mutations/common/user-onboarding';
import { useErrorWrapper } from 'fabric/hooks';
import { useNavigate } from '@reach/router';
import {
  CustomInput,
  PrimaryButton,
  CustomSelect,
} from 'Signup/components/CustomSignupComponents';
import { useMutation } from '@apollo/client';
import { graphqlClient } from 'graphql/factory';
import { ALL_COUNTRIES } from './helper.js';
import ScrollToTop from 'layout/ScrollToTop';
import { Track } from 'fabric/integrations';

export const BusinessInfo = ({ input, setState, state, setSession }) => {
  useEffect(() => {
    Track.track_page('Signup page -> Business Info');
  }, []);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companyRegistrationError, setCompanyRegistationError] =
    useState(false);

  const [formValues, setFormValues] = useAsState({
    title: {},
    country: '',
    company_name: '',
    trading_name: '',
    rapnet_id: null,
    jbt_number: null,
    hasEnterCompanyName: false,
    company_registration_number: '',
    hasEnterCompanyRegistrationNumber: false,
    company_type: {},
    hasEnterCompanyWebsite: false,
    company_website: '',
    first_touch: {},
    hasEnterTitle: false,
    hasEnterCountry: false,
    hasEnterCompanyType: false,
  });
  const [fnWrapper, context] = useErrorWrapper({
    showSuccess: false,
    disableHelmet: true,
  });

  let { role, signup_id, first_name } = input;
  let {
    company_name,
    trading_name,
    hasEnterCompanyName,
    company_type,
    hasEnterTitle,
    title,
    hasEnterCompanyRegistrationNumber,
    company_registration_number,
    hasEnterCompanyWebsite,
    company_website,
    first_touch,
    country,
    hasEnterCountry,
    hasEnterCompanyType,
  } = formValues;

  let initialCompanyOption = getCompanyOptions(role, t);
  const [companyOptions, setCompanyOptions] = useState(initialCompanyOption);
  const [onlineRetailer, setOnlineRetailer] = useState(false);
  const [validUrl, setValidUrl] = useState(false);

  const [initialize_signup_step_two] = useMutation(INITIALISE_STEPTWO, {
    client: graphqlClient,
  });

  const onSubmit = async () => {
    await fnWrapper(
      async () => {
        context.setBusy({
          type: 'STEP_TWO_CONT_BUTTON',
          status: true,
        });
        Track.track('Personal info submitted', {
          name: first_name,
          company_name,
          company_type: company_type.value,
          company_role: title.value,
          company_registration_number: company_registration_number,
        });
        const stored_utm_source = localStorage.getItem('utm_source') || null;
        const stored_utm_medium = localStorage.getItem('utm_medium') || null;
        const stored_utm_campaign =
          localStorage.getItem('utm_campaign') || null;
        const stored_utm_term = localStorage.getItem('utm_term') || null;
        const stored_utm_content = localStorage.getItem('utm_content') || null;

        let res = await initialize_signup_step_two({
          variables: {
            signup_id: signup_id,
            company_name,
            trading_name,
            company_type: company_type.value,
            company_role: title.value,
            company_registration_number,
            company_website,
            average_monthly_purchase: null,
            first_touch: first_touch.value,
            referrer: get_cookie('utm_referral'),
            country_of_incorporation: country,
            // vat_number: vat_number,
            rapnet_id: null,
            jbt_number: null,
            utm_source: stored_utm_source,
            utm_medium: stored_utm_medium,
            utm_campaign: stored_utm_campaign,
            utm_term: stored_utm_term,
            utm_content: stored_utm_content,
          },
        });

        if (res) {
          localStorage.removeItem('signup_id');
          localStorage.removeItem('role');
          localStorage.removeItem('step');
          if (localStorage.getItem('utm_source')) {
            localStorage.removeItem('utm_source');
          }
          if (localStorage.getItem('utm_medium')) {
            localStorage.removeItem('utm_medium');
          }
          if (localStorage.getItem('utm_campaign')) {
            localStorage.removeItem('utm_campaign');
          }
          if (localStorage.getItem('utm_term')) {
            localStorage.removeItem('utm_term');
          }
          if (localStorage.getItem('utm_content')) {
            localStorage.removeItem('utm_content');
          }

          await setSession(res.data.initialize_signup_step_two);
          if (role === 'CUSTOMER') {
            await navigate(`/register/set-address`);
          } else {
            await navigate(`/suppliers/stock-info`);
          }
        }

        context.setBusy({
          type: '',
          status: false,
        });
      },
      null,
      {
        error: {
          title: companyRegistrationError
            ? company_registration_number
            : 'Error',
          messageResolver: (msg) => {
            Track.track('Business info error', {
              error: msg,
              name: first_name,
              company_name,
              company_type: company_type.value,
              company_role: title.value,
              company_registration_number,
            });
            if (msg.includes('COMPANY_REGISTRATION_NUMBER_EXIST')) {
              setCompanyRegistationError(true);
              return t('company_already_exist');
            } else if (msg.includes('EMAIL_EXISTS')) {
              return t('email_already_exists_in_database_please_contact');
            } else {
              // eslint-disable-next-line no-console
              console.log('message', msg);
              return t('something_wrong_contact_support');
            }
          },
        },
      }
    );
  };

  useEffect(() => {
    setDefaultStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultStates = () => {
    if (signup_id == null || signup_id.length === 0) {
      let localRole = localStorage.getItem('role');
      let local_signup_id = localStorage.getItem('signup_id');
      let defCountry = localStorage.getItem('defaultCountry');
      if (localRole !== null && signup_id !== null) {
        setState({
          ...state,
          role: localRole,
          signup_id: local_signup_id,
          hasSelectedRole: true,
          country: localRole !== 'CUSTOMER' ? defCountry : '',
        });
        var companyOptions = getCompanyOptions(localRole, t);
        setCompanyOptions(companyOptions);
      } else {
        navigate('/register');
      }
    } else {
      let companyOptions = getCompanyOptions(role, t);
      setCompanyOptions(companyOptions);
    }
  };

  let disabled =
    !(
      company_name.length > 0 &&
      Object.keys(company_type).length > 0 &&
      Object.keys(title).length > 0 &&
      country.length > 0
    ) ||
    (country === 'IN' && role === 'CUSTOMER');

  // Find the country object,
  // check if it has a local text for `company registration number`
  // otherwise revert back to default text
  let countryObj = countryOptions.find((opt) => opt.value === country);

  let english =
    countryObj?.company_reg_no?.english?.length > 0
      ? countryObj?.company_reg_no?.english
      : null;
  let company_reg_title =
    countryObj?.company_reg_no !== undefined
      ? `${countryObj?.company_reg_no.local} ${
          english ? '(' + english + ')' : ''
        }`
      : t('company_registration_number');

  return (
    <SignupContainer>
      <ScrollToTop />
      {context.responseJSX}
      <BusinessInfoStyleWrapper className="business_info_wrapper">
        <div className="business_info_wrapper--header">
          <span className="business_info_wrapper--header__title">
            <Trans
              i18nKey={'nice_to_meet_you_john_lets_talk_about_your_business'}
              values={{
                name: `${first_name ? `${first_name}` : ''}`,
              }}
            />
          </span>
          <span className="business_info_wrapper--header__subtitle">
            <CustomTrans
              text="in_order_to_continue_please_provide_the_information_below_so_we_can_verify_your_company"
              returnPlainText={true}
            />
          </span>
        </div>
        <div className="business_info_wrapper--form">
          <div className="business_info_wrapper--form__row1">
            <CustomInput
              label={<span>{t('company_name')}</span>}
              placeholder={''}
              onBlur={() =>
                setFormValues({
                  hasEnterCompanyName: true,
                  company_name: company_name.trim(),
                })
              }
              onChange={(value) => {
                setFormValues({
                  company_name: value,
                });
              }}
              type={'text'}
              value={company_name}
              hasError={hasEnterCompanyName && !(company_name.length > 2)}
              errorMessage={`${t('please_enter_company_name')}`}
              errorStyle={{
                borderColor: 'red',
              }}
              isRequired={true}
            />
            <CustomInput
              label={<span>{t('trading_name')}</span>}
              placeholder={''}
              onBlur={() =>
                setFormValues({
                  trading_name: trading_name.trim(),
                })
              }
              onChange={(trading_name) =>
                setFormValues({
                  trading_name: trading_name,
                })
              }
              type={'text'}
              value={trading_name}
              isRequired={false}
            />
          </div>
          <div className="business_info_wrapper--form__row1">
            <CustomSelect
              label={t('business_type')}
              onChange={(option) => {
                setFormValues({
                  hasEnterCompanyType: true,
                  company_type: option,
                });
                if (option.value === 'Online Retailer') {
                  setOnlineRetailer(true);
                } else {
                  setOnlineRetailer(false);
                }
              }}
              options={companyOptions || null}
              placeholder={t('business_type')}
              hasError={hasEnterCompanyType && !company_type}
              error={t('please_enter_company_type')}
              isRequried={true}
              maxMenuHeight={200}
              data_automation_id="business-type-field"
            />
            <CustomSelect
              label={t('your_role_in_the_business')}
              onChange={(option) => {
                setFormValues({
                  hasEnterTitle: true,
                  title: option,
                });
              }}
              options={customerTitleOptions()}
              placeholder={t('your_role_in_the_business')}
              hasError={hasEnterTitle && !title}
              error={t('please_select_company_role')}
              isRequried={true}
              maxMenuHeight={200}
              data_automation_id="business-role-field"
              rightIconProps={{}}
            />
          </div>
          <div className="business_info_wrapper--form__row1">
            <CustomSelect
              label={<span>{t('company_country')}</span>}
              value={ALL_COUNTRIES.find((ele) => ele.value === country)}
              onChange={(option) => {
                setFormValues({
                  country: option ? option.value : '',
                  hasEnterCountry: true,
                });
              }}
              options={ALL_COUNTRIES}
              placeholder={t('select_country')}
              hasError={
                (hasEnterCountry && !country) ||
                (role === 'CUSTOMER' && country === 'IN')
              }
              error={
                role === 'CUSTOMER' && country === 'IN'
                  ? t('sales_not_in_india')
                  : t('please_enter_country_incorporation')
              }
              isRequried={true}
              maxMenuHeight={200}
              data_automation_id="select-country-field"
            />
            <CustomInput
              label={<span>{company_reg_title}</span>}
              placeholder={''}
              onBlur={() =>
                setFormValues({
                  hasEnterCompanyRegistrationNumber: true,
                  company_registration_number:
                    company_registration_number?.replace(/\s/g, ''),
                })
              }
              onChange={(company_registration_number) =>
                setFormValues({
                  company_registration_number:
                    company_registration_number?.replace(/\s/g, ''),
                })
              }
              type={'text'}
              hasError={
                hasEnterCompanyRegistrationNumber &&
                company_registration_number.trim()?.length === 0
              }
              errorMessage={`${t('please_enter_company_registration_number')}`}
              errorStyle={{
                borderColor: 'red',
              }}
              value={company_registration_number}
              isRequired={false}
            />
          </div>
          <div className="business_info_wrapper--form__row1">
            <CustomInput
              label={<span>{t('company_website')} </span>}
              placeholder={''}
              value={company_website}
              onBlur={() => {
                setFormValues({
                  hasEnterCompanyWebsite: true,
                  company_website: company_website.trim(),
                });
                let url = company_website.split('.');
                if (url[0] === 'www') {
                  if (isURL(url.slice(1, url.length).join('.'))) {
                    setValidUrl(true);
                  } else {
                    setValidUrl(false);
                  }
                } else {
                  if (isURL(url.join('.'))) {
                    setValidUrl(true);
                  } else {
                    setValidUrl(false);
                  }
                }
              }}
              isRequired={company_type.value === 'Online Retailer'}
              onChange={(_company_website) =>
                setFormValues({
                  company_website: _company_website,
                })
              }
              hasError={
                (hasEnterCompanyWebsite &&
                  company_website.length > 0 &&
                  !validUrl) ||
                (hasEnterCompanyWebsite &&
                  company_website.length > 0 &&
                  onlineRetailer &&
                  !validUrl)
              }
              errorMessage={`${t('enter_correct_website_url')}`}
              errorStyle={{
                borderColor: 'red',
              }}
            />
          </div>
        </div>
        <PrimaryButton
          btnText="continue"
          onClick={() => onSubmit()}
          disabled={
            disabled || (onlineRetailer && !validUrl) || context.busy.status
          }
          loading={context.busy.status}
        />
      </BusinessInfoStyleWrapper>
    </SignupContainer>
  );
};
