import React from 'react';
import { any, object, string } from 'prop-types';
import { FlagIcon } from '@nivoda/react-flag-kit';
import styled from 'styled-components';

CountryFlag.propTypes = {
  code: string.isRequired,
  customCss: any,
  flagProps: object,
  label: string,
};

export function CountryFlag(props) {
  return (
    <Div customCss={props.customCss}>
      {props.code && typeof props.code === 'string' && (
        <FlagIcon code={props.code} size={32} {...(props.flagProps || {})} />
      )}
      <span className="flag_label">
        {props.code ? props.label : 'Not a valid country'}
      </span>
    </Div>
  );
}

const Div = styled.div`
  display: inline;
  flex-direction: column;

  ${({ customCss = '' }) => customCss}
`;
