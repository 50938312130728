import React from 'react';
import cx from 'classnames';
import { arrayOf, any, func, string, bool } from 'prop-types';
import styled from 'styled-components';
import theme from '../../theme';
import { useOnClickOutside } from '../../hooks';
import { Loader, WithScrollbar } from '@nivoda/ui';

export function OptionsList(props) {
  const { maxHeight = '200px' } = props;
  const ref = React.useRef();

  const onClickOutSideSave = async (e) => {
    if (typeof props.handleHideList === 'function') {
      props.handleHideList(e);
    }
  };

  // Hide the list when clicked outside
  useOnClickOutside(ref, onClickOutSideSave);

  return props.loading ? (
    <Wrapper className="custom_list" role="list">
      <Loader />
    </Wrapper>
  ) : props.showList ? (
    <Wrapper
      className="custom_list"
      ref={ref}
      style={props.listStyle}
      role="list"
    >
      <WithScrollbar isActive maxHeight={maxHeight}>
        {props.items.map((item) => {
          const disabled =
            typeof props.isDisabled === 'function'
              ? props.isDisabled(item)
              : false;
          return (
            <button
              className={cx(
                'custom_list__item',
                item.id === props.selectedId && 'selected'
              )}
              key={item.id}
              disabled={disabled}
              data-value={item.id}
              aria-selected={item.id === props.selectedId}
              role="option"
              style={{
                cursor: disabled ? 'not-allowed' : 'cursor',
              }}
              onClick={(e) => props.onClick({ e, item })}
            >
              {item.name || item.label}
            </button>
          );
        })}
      </WithScrollbar>
    </Wrapper>
  ) : null;
}

OptionsList.propTypes = {
  isDisabled: func,
  items: arrayOf(any),
  handleHideList: func,
  loading: bool,
  onClick: func,
  selectedId: string,
  showList: bool,
};

const Wrapper = styled.div`
  background: ${theme.palette.white};
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  border-radius: ${theme.effects.roundedCorner3};
  border: ${theme.semanticProps.tableBorder};
  justify-content: center;
  align-items: start;
  width: 205px;
  box-shadow: ${theme.effects.menuShadow};
  z-index: 7;

  .custom_list {
    &__item {
      border-radius: ${theme.effects.roundedCorner3};
      padding: ${theme.spacing.s2} ${theme.spacing.s3};
      background: transparent;
      border-style: none;
      width: 100%;
      display: flex;
      align-self: start;

      &:hover,
      &:focus,
      &:active {
        background: ${theme.palette.white3};
      }
      &:focus {
        border: ${theme.semanticProps.borderDashedWhite3};
      }
    }
  }
`;
