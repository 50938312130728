import { generate_Id } from './helpers';

export const MARKUP_TABS = [
  {
    tab_id: 'NATURAL',
    product: 'Diamond',
    name: 'natural_diamonds',
    default: true,
  },
  {
    tab_id: 'LABGROWN',
    product: 'Diamond',
    name: 'labgrown_diamonds',
    default: false,
  },
];

export const APPLY_ON_OPTIONS = [
  { label: 'price', value: 'amount' },
  { label: 'carat', value: 'carats' },
];

export const PRICE_DIFFERENCE = 1;
export const CARAT_DIFFERENCE = 0.01;

export const DEFAULT_MARKUPS = {
  Diamond: {
    NATURAL: {
      amount: [
        {
          id: generate_Id(1, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 0,
          to_amount: 299.99,
          percent: 45,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
        {
          id: generate_Id(2, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 300.0,
          to_amount: 499.99,
          percent: 40,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
        {
          id: generate_Id(3, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 500.0,
          to_amount: 999.99,
          percent: 35,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
        {
          id: generate_Id(4, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 1000.0,
          to_amount: 1999.99,
          percent: 35,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
        {
          id: generate_Id(5, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 2000.0,
          to_amount: 2999.99,
          percent: 34,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
        {
          id: generate_Id(6, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 3000,
          to_amount: 4999.99,
          percent: 30,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
        {
          id: generate_Id(7, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 5000.0,
          to_amount: 9999.99,
          percent: 30,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
        {
          id: generate_Id(8, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 10000.0,
          to_amount: 14999.99,
          percent: 28,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },

        {
          id: generate_Id(9, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 15000.0,
          to_amount: 29999.99,
          percent: 26,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },

        {
          id: generate_Id(10, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 30000.0,
          to_amount: 99999.99,
          percent: 25,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },

        {
          id: generate_Id(11, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 100000.0,
          to_amount: 5000000,
          percent: 25,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'amount',
        },
      ],
      carats: [
        {
          id: generate_Id(1, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'carats',
          }),
          from_amount: 0.01,
          to_amount: 50,
          percent: 50,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'carats',
        },
        {
          id: generate_Id(2, {
            ProductSubtype: 'NATURAL',
            ProductType: 'Diamond',
            markup_type: 'carats',
          }),
          from_amount: 50.01,
          to_amount: 100,
          percent: 60,
          ProductType: 'Diamond',
          ProductSubtype: 'NATURAL',
          markup_type: 'carats',
        },
      ],
    },
    LABGROWN: {
      amount: [
        {
          id: generate_Id(1, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 0,
          to_amount: 299.99,
          percent: 45,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
        {
          id: generate_Id(2, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 300.0,
          to_amount: 499.99,
          percent: 40,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
        {
          id: generate_Id(3, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 500.0,
          to_amount: 999.99,
          percent: 35,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
        {
          id: generate_Id(4, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 1000.0,
          to_amount: 1999.99,
          percent: 35,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
        {
          id: generate_Id(5, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 2000.0,
          to_amount: 2999.99,
          percent: 34,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
        {
          id: generate_Id(6, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 3000,
          to_amount: 4999.99,
          percent: 30,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
        {
          id: generate_Id(7, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 5000.0,
          to_amount: 9999.99,
          percent: 30,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
        {
          id: generate_Id(8, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 10000.0,
          to_amount: 14999.99,
          percent: 28,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },

        {
          id: generate_Id(9, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 15000.0,
          to_amount: 29999.99,
          percent: 26,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },

        {
          id: generate_Id(10, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 30000.0,
          to_amount: 99999.99,
          percent: 25,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },

        {
          id: generate_Id(11, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'amount',
          }),
          from_amount: 100000.0,
          to_amount: 5000000,
          percent: 25,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'amount',
        },
      ],
      carats: [
        {
          id: generate_Id(1, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'carats',
          }),
          from_amount: 0.01,
          to_amount: 50,
          percent: 50,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'carats',
        },
        {
          id: generate_Id(2, {
            ProductSubtype: 'LABROWN',
            ProductType: 'Diamond',
            markup_type: 'carats',
          }),
          from_amount: 50.01,
          to_amount: 100,
          percent: 60,
          ProductType: 'Diamond',
          ProductSubtype: 'LABGROWN',
          markup_type: 'carats',
        },
      ],
    },
  },
};

export const DEFAULT_BASE_MARKUP = {
  Diamond: {
    NATURAL: {
      amount: {
        id: generate_Id(1, {
          ProductSubtype: 'NATURAL',
          ProductType: 'Diamond',
          markup_type: 'amount',
          extra_text: 'base',
        }),
        from_amount: 0.01,
        to_amount: 5000000,
        percent: 100,
        ProductType: 'Diamond',
        ProductSubtype: 'NATURAL',
        markup_type: 'amount',
      },

      carats: {
        id: generate_Id(1, {
          ProductSubtype: 'NATURAL',
          ProductType: 'Diamond',
          markup_type: 'carats',
          extra_text: 'base',
        }),
        from_amount: 0.01,
        to_amount: 100,
        percent: 100,
        ProductType: 'Diamond',
        ProductSubtype: 'NATURAL',
        markup_type: 'carats',
      },
    },
    LABGROWN: {
      amount: {
        id: generate_Id(1, {
          ProductSubtype: 'LABGROWN',
          ProductType: 'Diamond',
          markup_type: 'amount',
          extra_text: 'base',
        }),
        from_amount: 0.01,
        to_amount: 5000000,
        percent: 100,
        ProductType: 'Diamond',
        ProductSubtype: 'LABGROWN',
        markup_type: 'amount',
      },

      carats: {
        id: generate_Id(1, {
          ProductSubtype: 'LABGROWN',
          ProductType: 'Diamond',
          markup_type: 'carats',
          extra_text: 'base',
        }),
        from_amount: 0.01,
        to_amount: 100,
        percent: 100,
        ProductType: 'Diamond',
        ProductSubtype: 'LABGROWN',
        markup_type: 'carats',
      },
    },
  },
};

const EUR = {
  currency: 'EUR',
  symbol: '€',
  label: '€ EUR',
  value: 'EUR',
};

const GBP = {
  currency: 'GBP',
  symbol: '£',
  label: '£ GBP',
  value: 'GBP',
};
const HKD = {
  currency: 'HKD',
  symbol: 'HK$',
  label: 'HK$',
  value: 'HKD',
};
const INR = {
  currency: 'INR',
  symbol: '₹',
  label: '₹ INR',
  value: 'INR',
};
const ZAR = {
  currency: 'ZAR',
  symbol: 'R',
  label: 'R',
  value: 'ZAR',
};
const USD = {
  currency: 'USD',
  symbol: '$',
  label: '$ USD',
  value: 'USD',
};

const NONE = {
  currency: '',
  symbol: '',
  value: '',
  label: '',
};
const CAD = {
  currency: 'CAD',
  symbol: 'CA$',
  label: 'CAD',
  value: 'CAD',
};
const AUD = {
  currency: 'AUD',
  symbol: 'AU$',
  label: 'AUD',
  value: 'AUD',
};

const AED = {
  currency: 'AED',
  symbol: 'د.إ',
  label: 'AED',
  value: 'AED',
};

export const CURRENCY = [NONE, USD, EUR, GBP, HKD, INR, ZAR, AUD, AED, CAD];
