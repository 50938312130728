import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from '@nivoda/ui';
import { Icon } from 'fabric/resources/icon';

export const NivodaExpressBadgeWithDeliveryTime = (props) => {
  const {
    tooltip,
    short = false,
    deliveryTime = {
      minDay: '',
      maxDay: '',
    },
    logo = {
      name: 'express-logo',
      height: 20,
      width: 80,
    },
    notAvailable = false,
  } = props;
  let { t } = useTranslation();

  return (
    <Tooltip
      placement={tooltip?.placement || 'bottom'}
      offset={tooltip?.offset || -6}
      content={
        <div style={{ width: 150, ...(tooltip?.style || {}) }}>
          {tooltip?.content ? (
            tooltip.content
          ) : (
            <div>
              <Trans
                i18nKey="nivoda_express_delivery"
                values={{
                  delivery_time: `${deliveryTime?.minDay}-${deliveryTime?.maxDay}`,
                }}
                components={{ bold: <strong /> }}
              />
            </div>
          )}
        </div>
      }
    >
      <Icon
        name={logo.name}
        height={logo.height}
        width={logo.width}
        style={{ filter: notAvailable ? 'grayscale(1)' : 'unset' }}
      />
      <span className="express-delivery-time-text">
        {short
          ? `${deliveryTime?.minDay}-${deliveryTime?.maxDay} ${t('days')}`
          : `${deliveryTime?.minDay}-${deliveryTime?.maxDay} ${t(
              'business_days'
            )}`}
      </span>
    </Tooltip>
  );
};
