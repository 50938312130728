export const JewelleryTabURLs = {
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  PROCESSING: 'PROCESSING',
  FINANCE: 'FINANCE',
  PROCUREMENT: 'PROCUREMENT',
  READY_TO_SHIP: 'READY_TO_SHIP',
};

export const JewelleryTabTitles = {
  PURCHASE_ORDER: 'Purchase Order',
  PROCESSING: 'Processing',
  FINANCE: 'Finance',
  PROCUREMENT: 'Procurement',
  READY_TO_SHIP: 'Ready to ship',
  CREATE_JEWELLERY_ORDER: 'Create jewellery order',
};

export const mount_keys = [
  'metal_type',
  'metal_color',
  'metal_weight',
  'metal_size',
  'metal_width',
  'width_tolerance',
  'metal_depth',
  'depth_tolerance',
  'chain_type',
  'clasp_type',
  // to show conditionally
  // 'engraving_font',
  // 'engraving_text',
];

export const UPDATABLE_JEWELLERY_STATUSES = [
  'ORDER_ACCEPTED',
  'AWAITING_SUPPLIER_CONFIRMATION',
  'ORDER_IN_PROCESS',
  'QC_IN_PROCESS',
  'TO_INVOICE',
  'INVOICED',
  'SHIPMENT_IN_PROCESS',
  'SHIPPED_TO_NIVODA',
  'DELIVERED_LAST_MILE_HUB',
  'OUT_FOR_DELIVERY',
  'DELIVERED',
  'CANCELLED',
];

export const JEWELLERY_STATUSES = {
  ORDER_RECEIVED: 'ORDER_RECEIVED',
  ORDER_IN_PROCESS: 'ORDER_IN_PROCESS',
  ORDER_ACCEPTED: 'ORDER_ACCEPTED',
  AWAITING_SUPPLIER_CONFIRMATION: 'AWAITING_SUPPLIER_CONFIRMATION',
  QC_IN_PROCESS: 'QC_IN_PROCESS',
  TO_INVOICE: 'TO_INVOICE',
  INVOICED: 'INVOICED',
  SHIPMENT_IN_PROCESS: 'SHIPMENT_IN_PROCESS',
  SHIPPED_TO_NIVODA: 'SHIPPED_TO_NIVODA',
  DELIVERED_LAST_MILE_HUB: 'DELIVERED_LAST_MILE_HUB',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
};

export const PAGE_SIZE = 10;
