import { MARKUP_TABS } from '../constants';
import { markup_currency_mapper, markup_preferance_mapper } from '../helpers';
import { useEffect, useState } from 'react';
import { isEqual, sortBy } from 'lodash';

export const useRowData = (cfm_settings, DEFAULT_TAB) => {
  let { Markups, markup_preference, markup_currency } = cfm_settings;
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB || MARKUP_TABS[0]);
  const [markup_settings, set_markup_settings] = useState({
    markup_preference: markup_preferance_mapper(markup_preference),
    markup_currency: markup_currency_mapper(markup_currency),
  });
  let sorted_filtered_markups = sortBy(
    Markups?.filter(
      (ele) =>
        ele.ProductSubtype === activeTab.tab_id &&
        ele.ProductType === activeTab.product &&
        ele.markup_type === markup_settings?.markup_preference?.value
    ),
    'from_amount'
  );
  const [editable_rows, set_editable_row] = useState(sorted_filtered_markups);
  const [is_rows_changed, set_is_row_changed] = useState(false);

  useEffect(() => {
    set_is_row_changed(
      !isEqual(sorted_filtered_markups, editable_rows) ||
        markup_settings?.markup_preference?.value !== markup_preference ||
        markup_settings?.markup_currency?.value !== markup_currency
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable_rows]);
  return {
    activeTab,
    setActiveTab,
    markup_settings,
    set_markup_settings,
    editable_rows,
    set_editable_row,
    Markups,
    is_rows_changed,
  };
};
