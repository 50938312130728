import { gql } from '@apollo/client';

export const ShipmentCostFragment = gql`
  fragment ShipmentCostFragment on Shipment {
    id
    fbn_shipment
    tracking_code
    carrier
    service_type
    shipment_created
    origin {
      name
      city
      country
      owner {
        company {
          name
        }
      }
    }
    destination {
      name
      city
      country
      owner {
        company {
          name
        }
      }
    }
    child_shipments {
      id
      items {
        id
        buy_price
      }
    }
    items {
      id
      buy_price
    }
    shipment_cost {
      id
      status
      shipment_currency
      shipment_exchange_rate
      total_shipment_cost
      shipping_cost
      origin_clearance_cost
      destination_clearance_cost
      destination_duties_cost
      bdb_charges
      insurance_type
      insurance_cost
      surcharge_cost
      tax_charges
      fuel_charges
      emergency_charges
      other_costs
      weight
      number_of_stones
      first_updated_at
    }
  }
`;
