import styled from 'styled-components';
import { theme } from 'fabric/theme';

export const SignupContainerWrapper = styled.div`
  .signup-wrapper-desktop {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    .sidebar {
      width: 25%;
      @media (min-width: 700px) and (max-width: 1280px) {
        width: 40%;
        overflow:scroll;
      }
      height: 100%;
      padding: 2%;
      background: ${theme.palette.Neutrals950};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nivoda_logo {
          @media (min-width: 700px) and (max-width: 1280px) {
            width: 35%;
          }
          width: 40%;
        }
        .custom_react_select_wrapper {
          width: 50%;
          @media (min-width: 700px) and (max-width: 1280px) {
            width: 150px;
          }
        }
      }
      &--content {
        .sb_co-title {
          color: ${theme.palette.gray50};
          ${theme.fonts.megaPlus}
          font-family: 'Nanum Myeongjo' !important;
          line-height: 67.2px;
        }
        .sb_co-para {
          color: ${theme.palette.gray50};
          ${theme.fonts.large}
        }
        .author {
          color: ${theme.palette.Neutrals500};
          ${theme.fonts.large}
        }
      }
      &--footer {
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .already_have_text {
          color: ${theme.palette.Neutrals300};
          ${theme.fonts.large}
        }
        .login_button {
          width: 100%;
          border: none;
          border-radius: 50px;
          background: ${theme.palette.Neutrals50};
          color: ${theme.palette.Neutrals950};
          ${theme.fonts.largeBold}
          &:hover {
            background: ${theme.semanticColors.signupPrimaryHoverBg} !important;
            color: ${theme.palette.white};
          }
        }
      }
    }
    .container_content {
      width: 75%;
      @media (max-width: 700px) {
        width: 100%;
      }
      height: 100%;
      background: ${theme.palette.Neutrals100};
      .need__help {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px;
      }
      &--mid {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 90%;
      }
      &--core {
        background: ${theme.palette.gray50};
        border: 1px solid ${theme.palette.Neutrals200};
        border-radius: 10px;
        padding: 24px;
        width: 50%;
        @media (min-width: 700px) and (max-width: 1023px) {
          width: 93%;
        }
        @media (min-width: 1024px) and (max-width: 1366px) {
          width: 80%;
        }
        @media (min-width: 1025px) and (max-width: 1536px) {
          width: 65%;
        }
        .continue_button {
          width: 100%;
          border: none;
          border-radius: 50px;
          background: ${theme.palette.Neutrals950};
          color: ${theme.palette.Neutrals100};
          ${theme.fonts.largeBold}
          box-shadow: none;
          :hover {
            background: ${theme.semanticColors.signupPrimaryHoverBg};
            box-shadow: none;
          }
          :disabled {
            background: ${theme.palette.Neutrals300};
            color: ${theme.palette.Neutrals400};
            cursor: not-allowed;
            box-shadow: none;
          }
        }
      }
    }
  }
  .signup-wrapper-mobile {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    .container_content {
      width: 100%;
      height: 100%;
      background: ${theme.palette.Neutrals100};
      .need__help {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 0;
        
      }
      &--mid {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        @media (max-width: 700px) {
          min-height: calc(100vh - 90px);
          margin-top: 90px;
        }
      }
      &--core {
        background: ${theme.palette.gray50};
        border: 1px solid ${theme.palette.Neutrals200};
        border-radius: 10px;
        padding: 24px 12px;
        width: 95%;
        @media (max-width: 900px) {
          margin-bottom: 120px;
        }
        .continue_button {
          width: 100%;
          border: none;
          border-radius: 50px;
          background: ${theme.palette.Neutrals950};
          color: ${theme.palette.Neutrals100};
          ${theme.fonts.largeBold}
          box-shadow: none;
          :hover {
            background: ${theme.palette.Neutrals700};
            box-shadow: none;
          }
          :disabled {
            background: ${theme.palette.Neutrals300};
            color: ${theme.palette.Neutrals400};
            cursor: not-allowed;
            box-shadow: none;
          }
        }
        .react-tel-input {
          width 100% !important;
          border-radius: 8px;
          input {
            border-radius: 8px;
          }
        }
      }
    }
  }
`;

export const CustomInputWrapper = styled.div`
  height: 100%;
  .custom_input_wrapper {
    &--label {
      ${theme.fonts.smallBold}
      line-height:14.52px;
      color: ${theme.palette.Neutrals950};
      font-family: Inter;
      margin-bottom: unset;
      padding-left: 10px;
    }
    &--input {
      display: flex;
      background: ${theme.palette.Neutrals100};
      border-radius: 8px;
      border: 1px solid #c3bebc;
      align-items: center;
      padding: 7px 10px;
      justify-content: space-between;
      input {
        border: unset;
        background: none;
        outline: none;
        height: 24px;
        width: 100%;
      }
    }
  }
`;

export const CustomSelectWrapper = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 900px) {
    width: 100%;
  }
  .label_style {
    span {
      ${theme.fonts.smallBold}
      font-family:Inter;
      color: ${theme.palette.Neutrals950} !important;
      line-height: 14.52px;
    }
  }
`;
