import React from 'react';
import cx from 'classnames';
import { oneOf, oneOfType, string } from 'prop-types';
import styled, { css } from 'styled-components';
import theme from 'fabric/theme';

export const Pill = (props) => {
  const {
    className,
    content,
    mode = 'default',
    size = 'small',
    variant = 'primary',
  } = props;

  return (
    <PillWrapper
      className={cx('pill-wrapper', className)}
      data-automation-id="pill-wrapper"
      mode={mode}
      size={size}
      variant={variant}
    >
      {content}
    </PillWrapper>
  );
};

const PillWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.s1} 6px;
  gap: ${theme.spacing.s1};
  border-radius: ${theme.spacing.s1};

  ${({ mode, variant }) =>
    variant === 'default'
      ? css`
          background: ${theme.palette.Neutrals200};
          color: ${theme.palette.Neutrals700};
        `
      : variant === 'danger'
      ? css`
          background: ${theme.palette.red100};
          color: ${theme.palette.red800};
        `
      : variant === 'info'
      ? css`
          background: ${theme.semanticColors.memoPrimary100};
          color: ${theme.palette.blue700};
        `
      : variant === 'negative'
      ? css`
          background: ${theme.palette.Neutrals950};
          color: ${theme.palette.white};
        `
      : variant === 'primary'
      ? mode === 'memo'
        ? css`
            background: ${theme.semanticColors.memoPrimary100};
            color: ${theme.semanticColors.memoPrimary400};
          `
        : css`
            background: ${theme.palette.violet50};
            color: ${theme.palette.violet700};
          `
      : variant === 'success'
      ? css`
          background: ${theme.palette.green100};
          color: ${theme.palette.green800};
        `
      : variant === 'warning'
      ? css`
          background: ${theme.palette.yellow100};
          color: ${theme.palette.amber800};
        `
      : css`
          background: ${theme.palette.violet50};
          color: ${theme.palette.violet700};
        `};

  ${({ size }) =>
    size === 'small'
      ? css`
          ${theme.fonts.small};
        `
      : size === 'default'
      ? css`
          ${theme.fonts.medium};
          line-height: 14px;
        `
      : size === 'large'
      ? css`
          ${theme.fonts.large};
          line-height: 16px;
        `
      : null};
`;

Pill.propTypes = {
  className: string,
  content: oneOfType([string, React.Node]),
  mode: oneOf(['default', 'memo']),
  size: oneOf(['small', 'default', 'large']),
  variant: oneOf([
    'default',
    'danger',
    'info',
    'negative',
    'primary',
    'success',
    'warning',
  ]),
};
