import { gql } from '@apollo/client';
import { graphqlClient } from '../../factory';

export const getVideoDownloadLink = async (cert_id) => {
  return await graphqlClient.query({
    query: gql`
      query GetVideoLink($cert_id: ID!) {
        get_video_download_link(cert_id: $cert_id)
      }
    `,
    variables: { cert_id },
  });
};

export const getGemsVideoDownloadLink = async (cert_id) => {
  return await graphqlClient.query({
    query: gql`
      query GetGemsVideoLink($cert_id: ID!) {
        get_gemstone_video_download_link(cert_id: $cert_id)
      }
    `,
    variables: { cert_id },
  });
};
