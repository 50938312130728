import { theme } from 'fabric/theme';
import styled from 'styled-components';

export const SwitchWrapper = styled.div`
  & {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0 !important;
      color: ${theme.palette.blackPrimary} !important;
      font-size: 14px !important;
    }
  }
  input[type='checkbox'].switch_1 {
    background: ${(p) => p.nonActiveColor};
    border: ${(p) =>
      `1px solid ${
        p.non_active_border ? p.non_active_border : p.nonActiveColor
      } !important`};
    min-width: 48px;
    &:after {
      background: ${(p) =>
        p.active ? p.activeToggleColor : p.nonActiveToggleColor};
    }
    :checked {
      background: ${(p) => p.activeColor};
      border: ${(p) => `1px solid ${p.activeColor} !important`};
    }
  }
  @media (max-width: 700px) {
    input[type='checkbox'].switch_1 {
      margin-left: 0;
      height: 24px;
      width: 35px;
      &:after {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: ${(p) =>
          p.active ? p.activeToggleColor : p.nonActiveToggleColor};
        left: 3px;
        top: 1.5px;
      }
      &:checked:after {
        left: 12px;
      }
    }
  }
`;
