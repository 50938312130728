import { Switcher } from 'components/switcher';
import React from 'react';
import { SwitchWrapper } from './styles';
import { theme } from 'fabric/theme';
export const Switch = ({
  className = '',
  switched,
  setSwitched,
  label,
  activeColor = theme.semanticColors.holdButtonBG,
  nonActiveColor = theme.palette.white3,
  activeToggleColor = theme.palette.white,
  nonActiveToggleColor = theme.palette.white,
  ...rest
}) => (
  <SwitchWrapper
    className={`${className}`}
    active={switched}
    activeColor={activeColor}
    nonActiveColor={nonActiveColor}
    nonActiveToggleColor={nonActiveToggleColor}
    activeToggleColor={activeToggleColor}
    {...rest}
  >
    <Switcher
      id={`${className}-id`}
      checked={switched}
      onChange={setSwitched}
    />
    <label className={`${className}-label`} htmlFor={`${className}-id`}>
      {label}
    </label>
  </SwitchWrapper>
);
