import React from 'react';
import { padStart, range } from 'lodash';
import { Overlay } from '@nivoda/ui';
import Promise from 'bluebird';

const AWS360_VARIANT = {
  INLINE_CAROUSEL: 'INLINE_CAROUSEL',
  CAROUSEL: 'CAROUSEL',
};
let precondition = (condition, message) => {
  if (!condition) {
    throw new Error(message);
  }
};

export let does_support_webp = () => {
  let elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
};
let image_extension = does_support_webp() ? 'webp' : 'jpg';

let src_to_image = async (url) => {
  return await new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = url;
  });
};

class AnimationFrameLoop extends React.Component {
  /*:flow
  props: {
    onAnimationFrame: () => mixed,
  }
  */

  componentDidMount() {
    let last_date = Date.now();
    let request = () => {
      if (this.props.onAnimationFrame) {
        // TODO Await this? idk
        this.props.onAnimationFrame(last_date, Date.now());
        last_date = Date.now();
      }
      window.requestAnimationFrame(request);
    };

    // TODO Not run one initially? Props?
    request();
  }

  componentWillUnmount() {
    // noinspection JSUnresolvedReference
    window.cancelAnimationFrame(this.animationFrame);
  }

  render() {
    return null;
  }
}

class Drag extends React.Component {
  /*:flow
  props: {
    onMove: (e: { move_x: number }) => mixed,
    onDraggingChange: (dragging: boolean) => mixed
  }
  */
  state = {
    pressed_in: false,
  };

  render() {
    let { onMove, children, onDraggingChange, ...props } = this.props;
    let { pressed_in, container_width } = this.state;
    return (
      <div
        {...props}
        style={{ touchAction: 'none', ...props.style }}
        draggable={true}
        onDragStart={() => {
          this.setState({ pressed_in: true });
          if (onDraggingChange) {
            onDraggingChange(true);
          }
        }}
        onTouchStart={({ nativeEvent: e }) => {
          this.setState({ pressed_in: true });
          this.last_touch_position = e.changedTouches[0].pageX;
          if (onDraggingChange) {
            onDraggingChange(true);
          }
        }}
        onTouchMove={({ nativeEvent: e }) => {
          const { pressed_in } = this.state;
          const { onMove } = this.props;
          const touchMove = e.changedTouches[0];
          let moveX = touchMove.pageX - this.last_touch_position;
          if (pressed_in) {
            let move_x = -moveX / container_width;
            onMove({ move_x, nativeEvent: e });
          }
          this.last_touch_position = touchMove.pageX;
        }}
        onTouchEnd={() => {
          this.setState({ pressed_in: false });
          if (onDraggingChange) {
            onDraggingChange(false);
          }
        }}
        ref={(ref) => {
          if (ref) {
            let width = ref.getBoundingClientRect().width;
            if (container_width !== width) {
              this.setState({ container_width: width });
            }
          }
        }}
      >
        {pressed_in && (
          <Overlay>
            <div
              className="grabbing-cursor"
              style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: '100',
              }}
              onMouseUp={() => {
                this.setState({ pressed_in: false });
                if (onDraggingChange) {
                  onDraggingChange(false);
                }
              }}
              onMouseMove={({ nativeEvent: e }) => {
                if (pressed_in) {
                  let move_x = -e.movementX / container_width;
                  onMove({ move_x, nativeEvent: e });
                }
              }}
            />
          </Overlay>
        )}

        {children}
      </div>
    );
  }
}

let rotate_around = (max, x) => {
  if (x >= 0) {
    return x % max;
  } else {
    // It may never actually be max, so we have to look out for that here
    let temp = max + (x % max);
    return temp === max ? 0 : temp;
  }
};

class ImageLoop extends React.Component {
  /*:flow
  props: {
    progress: number, // 0 - 1
    frames: Array<ImageElement>,
  }
  */

  last_frame_index = null;

  request = () => {
    if (this.canvas_ref && this.props.frames != null) {
      let { frames, progress, isRectangleCard } = this.props;
      let { width, height } = this.container_ref.getBoundingClientRect();

      let progress_flipped = rotate_around(1, progress);
      let frame_index = Math.floor(progress_flipped * frames.length);

      let frame = frames[frame_index];

      // If the frame is not found, keep looking (down) till you found a frame that exists
      if (frame == null) {
        for (let i of range(frame_index, -1)) {
          if (frames[i] != null) {
            frame_index = i;
            frame = frames[i];
            break;
          }
        }
        if (frame == null) {
          return;
        }
      }
      // noinspection JSUnresolvedReference
      const isInlineCarousel =
        this.props?.variant === AWS360_VARIANT.INLINE_CAROUSEL;
      // noinspection JSUnresolvedReference
      const isCarousel = this.props?.variant === AWS360_VARIANT.CAROUSEL;

      let scale =
        isInlineCarousel || isCarousel
          ? Math.min(width / frame.naturalWidth, height / frame.naturalHeight)
          : Math.max(width / frame.naturalWidth, height / frame.naturalHeight);

      let width_diff = width - frame.naturalWidth * scale;

      let new_transform =
        isInlineCarousel || isCarousel
          ? `scale(${scale}) translate(-50%,-50%)`
          : `scale(${scale}) translateX(${Math.floor(width_diff / 2)}px)`;
      // noinspection JSUnresolvedReference
      if (
        frame_index !== this.canvas_ref.last_frame_index ||
        this.canvas_ref.last_transforms !== new_transform
      ) {
        let container_size = `${Math.max(height, width)}px`;
        this.container_ref.style.height = isInlineCarousel
          ? container_size
          : frame.height;
        this.container_ref.style.width = isInlineCarousel
          ? container_size
          : frame.width;

        this.canvas_ref.height = isInlineCarousel
          ? frame.height
          : isRectangleCard && frame.naturalHeight >= frame?.naturalWidth
          ? frame?.naturalHeight - 140
          : frame?.naturalHeight;
        this.canvas_ref.width = isInlineCarousel
          ? frame.width
          : frame?.naturalWidth;

        this.canvas_ref.style.transform = new_transform;
        this.canvas_ref
          .getContext('2d')
          .drawImage(
            frame,
            0,
            0,
            this.canvas_ref.width,
            this.canvas_ref.height
          );

        this.canvas_ref.last_transform = new_transform;
        this.canvas_ref.last_frame_index = frame_index;
      }
    }

    this.timeout = window.requestAnimationFrame(this.request);
  };

  componentDidMount() {
    this.request();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.timeout);
  }

  render() {
    let { style, onClick, pressed_in, variant } = this.props;

    return (
      <div
        className="aws_wrapper aws_wrapper_medium"
        style={{ cursor: 'pointer' }}
        ref={(ref) => (this.container_ref = ref)}
        onClick={() => {
          if (!pressed_in && onClick != null) {
            onClick();
          }
        }}
      >
        <canvas
          ref={(ref) => (this.canvas_ref = ref)}
          style={{
            ...style,
            pointerEvents: 'none',
            transition: 'opacity .5s',
            transformOrigin: `left top`,
            opacity: this.props.frames == null ? 0 : 1,
            position:
              variant === AWS360_VARIANT.INLINE_CAROUSEL ||
              variant === AWS360_VARIANT.CAROUSEL
                ? 'absolute'
                : 'static',
            top:
              variant === AWS360_VARIANT.INLINE_CAROUSEL ||
              variant === AWS360_VARIANT.CAROUSEL
                ? '50%'
                : 'auto',
            left:
              variant === AWS360_VARIANT.INLINE_CAROUSEL ||
              variant === AWS360_VARIANT.CAROUSEL
                ? '50%'
                : 'auto',
          }}
        />
      </div>
    );
  }
}

// TODO onError so we can fall back to image again
export class AWS360 extends React.Component {
  static defaultProps = {
    showIcon: true,
    // background: ''
  };

  state = {
    all_frames: null,
    rotation: 0,
    pressed_in: false,
  };

  mounted = true;

  async componentDidMount() {
    if (document.readyState !== 'complete') {
      await new Promise((yell) => {
        window.addEventListener('load', () => {
          yell();
        });
      });
    }

    if (this.mounted === false) {
      return;
    }

    let { v360_info } = this.props;
    precondition(v360_info != null, `v360_info has to be provided to AWS360`);
    precondition(
      v360_info.url != null,
      `v360_info.url has to be provided to AWS360`
    );
    precondition(
      v360_info.frame_count != null,
      `v360_info.frame_count has to be provided to AWS360`
    );

    let frame_range = range(0, v360_info.frame_count);
    let top_index = v360_info.top_index || 0;

    let get_frame_number = (i) => {
      if (top_index.toString() === '0') {
        return i;
      }
      if (top_index.toString() === '0000') {
        return padStart(i, 4, '0');
      }

      if (i + +top_index <= frame_range.length - 1) {
        return i + +top_index;
      } else {
        return i - (frame_range.length - +top_index);
      }
    };

    let get_frames = async (number_of_frames) => {
      let modulo = v360_info.frame_count / number_of_frames;
      let frames = await Promise.map(
        frame_range,
        (i) => {
          if (this.state.all_frames && this.state.all_frames[i]) {
            return this.state.all_frames[i];
          }
          if (i % modulo === 0) {
            return src_to_image(
              `${v360_info.url}/${get_frame_number(i)}.${image_extension}`
            );
          } else {
            return null;
          }
        },
        { concurrency: 10 }
      );
      if (!this.mounted) return null;
      this.setState({
        all_frames: frames,
      });
    };

    // First get first 8 frames
    await get_frames(8);
    if (!this.mounted) return;
    this.props.onLoad?.(8);
    await get_frames(32);
    if (!this.mounted) return;
    await get_frames(64);
    if (!this.mounted) return;
    await get_frames(v360_info.frame_count);
  }

  async componentWillUnmount() {
    this.mounted = false;
    await new Promise((yell) => {
      window.addEventListener('load', () => {
        yell();
      });
    });
  }

  render() {
    let { all_frames, rotation, pressed_in } = this.state;
    let {
      style,
      animate,
      canvasStyle,
      onClick,
      showIcon,
      icon_position = 'top',
      variant = '',
      isRectangleCard,
    } = this.props;
    return (
      <Drag
        style={{
          height: '100%',
          ...style,
        }}
        onDraggingChange={(x) => this.setState({ pressed_in: x })}
        onMove={({ move_x }) =>
          this.setState({ rotation: rotation + move_x * 360 })
        }
      >
        <div
          className={'video_icon_v360'}
          style={{
            position: 'absolute',
            zIndex: '2',
            [icon_position]: '5px',
            left: '10px',
            width: 30,
          }}
        >
          {showIcon && (
            <img
              className="v360 only_grid"
              src={'/img/v360.svg'}
              alt="scroll"
            />
          )}
        </div>
        <AnimationFrameLoop
          onAnimationFrame={(last_date, current_date) => {
            if (animate && !pressed_in && all_frames != null) {
              let diff = current_date - last_date;
              this.setState({ rotation: rotation + (diff / 16) * 0.7 });
            }
          }}
        />
        <ImageLoop
          onClick={onClick}
          style={canvasStyle}
          pressed_in={pressed_in}
          progress={rotation / 360}
          frames={all_frames}
          isRectangleCard={isRectangleCard}
          variant={variant}
        />
      </Drag>
    );
  }
}

export let initial_image_for = ({ diamond }) => {
  return `${diamond.url}/${diamond.top_index}.${image_extension}`;
};
