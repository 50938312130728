import { gql } from '@apollo/client';
import {
  ShortlistItemFragment,
  MiniShortlistItemFragment,
} from '../../fragments/ShortlistFragment';

export const SET_SHORTLIST_ITEMS = gql`
  mutation updateShortlistItems(
    $items: [InputShortlistItem!]!
    $is_memo: Boolean
  ) {
    update_shortlist(items: $items, is_memo: $is_memo) {
      id
      shortlist_items {
        ...ShortlistItemFragment
      }
      shortlist_memo_items {
        ...ShortlistItemFragment
      }
    }
  }
  ${ShortlistItemFragment}
`;
export const SET_MINI_SHORTLIST_ITEMS = gql`
  mutation updateShortlistItems(
    $items: [InputShortlistItem!]!
    $is_memo: Boolean
  ) {
    update_shortlist(items: $items, is_memo: $is_memo) {
      id
      shortlist_items {
        ...MiniShortlistItemFragment
      }
      shortlist_memo_items {
        ...MiniShortlistItemFragment
      }
    }
  }
  ${MiniShortlistItemFragment}
`;

export const SHORTLIST_GROUP_MUTATION = gql`
  mutation (
    $action: ShortlistItemActions!
    $shortlist_group_params: ShortlistGroupInput!
  ) {
    update_shortlist_group(
      action: $action
      shortlist_group_params: $shortlist_group_params
    ) {
      id
      group_name
      shortlist_items {
        ...ShortlistItemFragment
      }
    }
  }
  ${ShortlistItemFragment}
`;
