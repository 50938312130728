import React, { useState } from 'react';
import styled from 'styled-components';
import '../../../Supplier/components/Enquiry/style.css';
import { Link } from '@reach/router';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { TableWithExpandableRow } from '@nivoda/forms';
import { ReqPopup } from '@nivoda/ui';
import { theme } from '../../theme';
import { noop } from 'lodash';
import { styled_predicate } from 'helpers';
import cx from 'classnames';
import { adminGraphqlClient } from 'graphql/factory';
import { useQuery } from '@apollo/client';
import { GET_EXCHANGE_RATES } from '../../../graphql/queries/admin';
import { range } from 'lodash';
import { Button } from '@nivoda/ui';

let is_mobile = window.innerWidth < 700;

let UiTabs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  z-index: 2;

  .__custom_tab {
    display: flex;
    align-items: center;
    width: ${is_mobile ? '100%' : '50%'};
  }
`;

let UiTab = styled.div`
  padding: 12px 24px 12px;
  font-family: 'Hind SemiBold', sans-serif;
  color: #888888;

  font-size: 14px;
  background-color: #f3f3f3;
  text-align: center;
  border: 1px solid #e0e0e0;

  ${styled_predicate((p) => p.active === 'true')} {
    padding-bottom: 8px;
    background-color: #fff;
    color: ${theme.palette.themePrimary};
    border-bottom: 1px solid #fff;
    padding-top: 12px;
    position: relative;
    z-index: 2;

    &:after {
      content: '';
      background: ${({ activeborder = false }) =>
        activeborder ? theme.palette.themePrimary : 'transparent'};
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  a {
    color: #888888;
  }

  &:hover {
    color: ${theme.palette.themePrimary};
    cursor: pointer;
    background-color: #f8f8f8;
  }

  &:last-child {
    border-top-right-radius: 3px;
  }
`;

export let TabsContainer = ({
  tabs,
  activeborder = false,
  containerClass,
  tabDesc = 'Tab list',
  tab_index,
  onTabChange,
  style = {},
  rightHandSide,
}) => {
  if (tabs.length === 0) {
    throw new Error('Should have at least 1 tab');
  }
  let is_controlled_component = tab_index != null;

  // Only for uncontrolled usage
  let [activeTabIndex, setActiveTabIndex] = React.useState(tab_index || 0);

  let actualTabIndex = is_controlled_component ? tab_index : activeTabIndex;
  let activeTab = tabs[actualTabIndex];
  const active_id =
    (activeTab.title &&
      typeof activeTab.title === 'string' &&
      activeTab.title.replace(/\s/g, '')) ||
    '__custom_tab';

  return (
    <>
      {/* need selector to target tabs overflow on mobile */}
      <UiTabs
        className="__tabs"
        role="tablist"
        style={style}
        title={tabDesc}
        aria-label={tabDesc}
      >
        <div style={{ display: 'flex', width: is_mobile ? '100%' : '' }}>
          {tabs.map((tab, index) => {
            const active = actualTabIndex === index ? 'true' : 'false';
            const active_id =
              (tab.title &&
                typeof tab.title === 'string' &&
                tab.title.replace(/\s/g, '')) ||
              `__custom_tab${index}`;

            return (
              <UiTab
                className="__tab"
                key={index}
                active={active}
                activeborder={activeborder}
                aria-controls={`${active_id}-tab`}
                aria-selected={active}
                id={active_id}
                onClick={() => {
                  if (is_controlled_component) {
                    onTabChange(index);
                  } else {
                    setActiveTabIndex(index);
                  }
                }}
                aria-roledescription="tab"
                role="tab"
                tabIndex={'0'}
                title={
                  typeof tab.title === 'string'
                    ? tab.titleAttr || tab.title + ' tab'
                    : ''
                }
              >
                {tab.title}
              </UiTab>
            );
          })}
        </div>
        {rightHandSide && (
          <div role="tab" tabIndex={'0'}>
            {rightHandSide}
          </div>
        )}
      </UiTabs>
      <div
        aria-labelledby={active_id}
        className={containerClass}
        id={`${active_id}-tab`}
        tabIndex="0"
        role="tabpanel"
      >
        {activeTab.component}
      </div>
    </>
  );
};
let UiTabLink = styled(Link)`
  padding: ${({ tabpadding }) =>
    tabpadding ? tabpadding : `12px ${is_mobile ? '4px' : '24px'} 12px`};
  font-family: 'Hind SemiBold', sans-serif;
  color: #888888;
  font-size: 14px;
  background-color: #f3f3f3;
  text-align: center;
  width: ${(p) => p.width}%;
  border: 1px solid #e0e0e0;
  position: relative;

  ${styled_predicate((p) => p.active === 'true')} {
    padding-bottom: 8px;
    background-color: #fff;
    color: ${theme.palette.themePrimary} !important;
    border-bottom: 1px solid #fff;
    padding-top: 12px;
    z-index: 2;

    &:after {
      content: '';
      background: ${({ activeborder }) =>
        activeborder ? theme.palette.themePrimary : 'transparent'};
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  a {
    color: #888888;
  }

  &:hover {
    color: ${theme.palette.themePrimary};
    cursor: pointer;
    background-color: #f8f8f8;
  }

  &:last-child {
    border-top-right-radius: 3px;
  }
`;

const Wrapper = styled.div`
  height: calc(100vh - 100px);
  overflow-y: scroll;

  ${({ tabStyles = '' }) => tabStyles};
`;

const showError = (tab_index) => {
  // eslint-disable-next-line no-console
  console.error(
    `No component value passed for tab with selected_tab_index: ${tab_index}. To debug this look in tabs array in parent component.`
  );
  return `You forgot to pass component for tab: ${tab_index}. See dev console for more info.`;
};

let columns = [
  {
    name: 'Date',
    sortable: false,
    selector: 'date',
    id: 'date',
  },
  {
    name: 'USD - INR',
    sortable: false,
    selector: 'rate',
    id: 'rate',
  },
];

export let NewTabsContainer = ({
  activeTopBorder = false,
  tabpadding = '',
  tabDesc = '',
  tabs,
  tabClass = '',
  containerClass,
  selected_tab_index,
  onTabChange = noop,
  style = {},
  rightHandSide,
  tabStyles = '',
  tableName,
}) => {
  if (tabs.length === 0) {
    throw new Error('Should have at least 1 tab');
  }
  let activeTab = tabs.find((r) => r.status === selected_tab_index);

  const [openBackRateHistoryModal, setOpenBackRateHistoryModal] =
    useState(false);

  const DebitNoteStatus = ({ resetModal, description = null }) => {
    const { loading, data } = useQuery(GET_EXCHANGE_RATES, {
      client: adminGraphqlClient,
    });

    let emptyRow = {};

    let loadingRows = range(12).map((r, i) => {
      return {
        id: `${i}`,
        ...emptyRow,
      };
    });

    return (
      <ReqPopup
        active_out_click={true}
        className="upload_invoice__popup_br"
        style={{
          position: 'relative',
          width: '25rem',
          padding: 0,
          margin: 0,
        }}
        noMaxHeight
        remove_top_margin
        showBorder
        title={
          <div className="pop_up_header">
            <span>60 Day Historic Opening BR</span>
          </div>
        }
        clearModalState={resetModal}
      >
        <div className="centered_align">
          <TableWithExpandableRow
            colGap={theme.spacing.s3}
            columns={columns}
            dashedRow
            hasMore={false}
            headerLightText
            loading={loading}
            maxHeight={'300px'}
            rowHover={false}
            stickyTableHead
            rows={!loading ? data?.all_exchange_rates : loadingRows}
            headerZIndex={10}
          />
        </div>
      </ReqPopup>
    );
  };

  const active_id =
    (activeTab &&
      typeof activeTab.title === 'string' &&
      activeTab.title &&
      activeTab.title.replace(/\s/g, '')) ||
    '__custom_tab';

  const handleOnTabChange = (e) => {
    e.persist();
    const status = e?.target?.dataset.status;
    if (typeof onTabChange === 'function') {
      onTabChange(e, status);
    }
    if (tableName && tableName === 'customer_order_table') {
      localStorage.setItem('orders_active_tab', status);
    }
  };

  const showHistoricBrButton = () => {
    return (
      activeTab?.fullTitle?.includes('Purchase order') ||
      (typeof tabDesc === 'string' && tabDesc?.includes('Purchase order'))
    );
  };

  return (
    <Wrapper className="__custom_tab_wrapper" tabStyles={tabStyles}>
      <UiTabs
        className="__custom_tabs"
        title={tabDesc}
        aria-label={tabDesc}
        role="tablist"
        style={style}
      >
        <div className="__custom_tab">
          {tabs.map((tab, index) => {
            let title = tab.title;
            const tabTitle =
              typeof tab.fullTitle === 'string'
                ? tab.fullTitle
                : typeof tab.title === 'string'
                ? tab.title
                : '';

            const active = selected_tab_index === tab.status ? 'true' : 'false';
            const active_id =
              (tab.title &&
                typeof tab.title === 'string' &&
                tab.title.replace(/\s/g, '')) ||
              `__custom_tab${index}`;

            return (
              <UiTabLink
                activeborder={activeTopBorder ? 'true' : 'false'}
                className={`${tabClass} ${active_id}`}
                tabpadding={tabpadding}
                key={index}
                to={tab.link}
                active={active}
                aria-controls={`${active_id}-tab`}
                aria-selected={active}
                data-status={tab.status}
                title={tabTitle}
                aria-label={tabTitle}
                onClick={handleOnTabChange}
                id={active_id}
                role="tab"
                width={is_mobile ? 100 / tabs.length : ''}
                data-automation-id="tab-header"
              >
                {title}
              </UiTabLink>
            );
          })}
          {showHistoricBrButton() && (
            <div style={{ paddingLeft: '2rem' }}>
              <Button
                label={'Historic BR'}
                onClick={() => setOpenBackRateHistoryModal(true)}
              />
            </div>
          )}
        </div>
        {/* after all the tabs focus will move to this */}
        {rightHandSide && (
          <div role="tab" tabIndex={'0'} data-automation-id="refresh-button">
            {rightHandSide}
          </div>
        )}
      </UiTabs>
      <div
        aria-labelledby={active_id}
        className={containerClass}
        tabIndex="0"
        id={`${active_id}-tab`}
        role="tabpanel"
      >
        {(activeTab && activeTab.component) || showError(selected_tab_index)}
      </div>
      {openBackRateHistoryModal && (
        <DebitNoteStatus
          resetModal={() => setOpenBackRateHistoryModal(false)}
        />
      )}
    </Wrapper>
  );
};

let UiTabsv3 = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing.s4};
  margin-bottom: ${theme.spacing.s4};
  z-index: 2;
  .__custom_tab {
    display: flex;
    width: ${is_mobile ? '100%' : '50%'};
  }
`;

let UiTabLinkv3 = styled(Link)`
  padding: ${({ tabpadding }) =>
    tabpadding ? tabpadding : `8px ${is_mobile ? '4px' : '12px'} 8px 12px`};
  ${theme.fonts.mediumPlus};
  color: ${theme.palette.brandBlue4};
  line-height: 14px;
  text-align: center;
  width: ${(p) => p.width}%;
  border: 0;
  position: relative;
  ${styled_predicate((p) => p.active === 'true')} {
    background-color: ${theme.palette.themePrimary};
    color: ${theme.palette.white} !important;
    border-radius: 4px;
    z-index: 2;
  }
  &:hover {
    color: ${theme.palette.themePrimary};
    cursor: pointer;
  }
`;

export let TabsContainerv3 = ({
  activeTopBorder = false,
  tabpadding = '',
  tabDesc = '',
  tabs,
  tabClass = '',
  containerClass,
  selected_tab_index,
  onTabChange = noop,
  style = {},
  rightHandSide,
  tabStyles = '',
  tableName,
}) => {
  if (tabs.length === 0) {
    throw new Error('Should have at least 1 tab');
  }
  let activeTab = tabs.find((r) => r.status === selected_tab_index);
  const active_id =
    (activeTab &&
      typeof activeTab.title === 'string' &&
      activeTab.title &&
      activeTab.title.replace(/\s/g, '')) ||
    '__custom_tab';

  const handleOnTabChange = (e) => {
    e.persist();
    const status = e?.target?.dataset.status;
    if (typeof onTabChange === 'function') {
      onTabChange(e, status);
    }
    if (tableName && tableName === 'customer_order_table') {
      localStorage.setItem('orders_active_tab', status);
    }
  };

  return (
    <Wrapper className="__custom_tab_wrapper" tabStyles={tabStyles}>
      <UiTabsv3
        className="__custom_tabs"
        data-automation-id="finance-tab-header"
        // title={tabDesc}
        aria-label={tabDesc}
        role="tablist"
        style={style}
      >
        <div className="__custom_tab">
          {tabs.map((tab, index) => {
            let title = tab.title;
            const tabTitle =
              typeof tab.fullTitle === 'string'
                ? tab.fullTitle
                : typeof tab.title === 'string'
                ? tab.title
                : '';

            const active = selected_tab_index === tab.status ? 'true' : 'false';
            const active_id =
              (tab.title &&
                typeof tab.title === 'string' &&
                tab.title.replace(/\s/g, '')) ||
              `__custom_tab${index}`;

            return (
              <UiTabLinkv3
                activeborder={activeTopBorder ? 'true' : 'false'}
                // className={`${tabClass} ${active_id}`}
                className={cx(
                  tabClass,
                  active_id,
                  active === 'true' && `${tabClass}_active`
                )}
                tabpadding={tabpadding}
                key={index}
                to={tab.link}
                active={active}
                aria-controls={`${active_id}-tab`}
                aria-selected={active}
                data-status={tab.status}
                data-automation-id="finance-tab-header"
                title={tabTitle}
                aria-label={tabTitle}
                onClick={handleOnTabChange}
                id={active_id}
                role="tab"
                width={is_mobile ? 100 / tabs.length : ''}
              >
                {title}
              </UiTabLinkv3>
            );
          })}
        </div>
        {/* after all the tabs focus will move to this */}
        {rightHandSide && (
          <div role="tab" tabIndex={'0'}>
            {rightHandSide}
          </div>
        )}
      </UiTabsv3>
      <div
        aria-labelledby={active_id}
        className={containerClass}
        tabIndex="0"
        id={`${active_id}-tab`}
        role="tabpanel"
      >
        {(activeTab && activeTab.component) || showError(selected_tab_index)}
      </div>
    </Wrapper>
  );
};

export const Tabs = (props) => {
  return (
    <ContainerDiv
      aria-label={props.tabConfig.description}
      className={`${props.containerClass}_tabs`}
      containerClass={props.containerClass}
      role="tablist"
      tabConfig={props.tabConfig}
      title={props.tabConfig.description}
    >
      {props.tabs.map((tab) => {
        const active = props.activeTab === tab.id;
        const _props =
          typeof props.onTabClick === 'function'
            ? { onClick: props.onTabClick }
            : {};
        return (
          <div
            className={`${props.containerClass}_tab__item ${
              active && '_active_tab'
            }`}
            key={tab.id}
            title={tab.titleAttr}
            aria-controls={`${tab.id}-tab`}
            aria-selected={active}
            data-tab-id={tab.id}
            id={tab.id}
            role="tab"
            tabIndex={'0'}
            {..._props}
          >
            {tab.title}
          </div>
        );
      })}
    </ContainerDiv>
  );
};

Tabs.defaultProps = {
  style: {},
  tabConfig: {
    base_line: true,
    base_line_position: 'bottom',
    tab_count: 0,
    shadow: true,
    type: 'rounded',
    description: 'Tabs',
  },
};
Tabs.propTypes = {
  activeTab: string,
  onTabClick: func,
  style: object,
  tabConfig: shape({
    base_line: bool,
    base_line_position: oneOf(['top', 'bottom']),
    description: string,
    shadow: bool,
    tab_count: number,
    type: oneOf(['rounded', 'flat']),
  }),
  tabs: arrayOf(
    shape({
      id: string,
      title: oneOfType([string, object]),
      titleAttr: string,
    })
  ),
};

const ContainerDiv = styled.div`
  display: grid;
  background: #ffffff;
  border: ${({ tabConfig }) =>
    tabConfig.border ? '1px solid #f5f5f5' : 'none'};
  border-radius: 3px;
  box-shadow: ${({ tabConfig }) =>
    tabConfig.shadow ? '0 3px 5px rgba(22,22, 22 , 0.05)' : 'none'};

  &.${({ containerClass }) => containerClass} {
    &_tabs {
      gap: 12px;
      grid-template-columns: repeat(
        ${({ tabConfig }) => tabConfig.tab_count},
        1fr
      );
      justify-items: center;
    }

    &_tab__item {
      cursor: pointer;
      position: relative;

      &:after {
        content: '';
        background: ${({ tabConfig }) =>
          tabConfig.base_line ? theme.palette.themePrimary : 'transparent'};
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .${({ containerClass }) => containerClass} {
    &_tab__item {
      cursor: pointer;
      position: relative;
      padding: 14px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(44, 44, 44, 0.6);

      &:after {
        content: '';
        background: ${({ tabConfig }) =>
          tabConfig.base_line ? theme.palette.themePrimary : 'transparent'};
        height: 3px;
        position: absolute;
        top: ${({ tabConfig }) =>
          tabConfig.base_line_position === 'top' ? '-1px' : 'unset'};
        left: ${({ tabConfig }) =>
          tabConfig.base_line_position === 'top' ? 0 : 'unset'};
        bottom: ${({ tabConfig }) =>
          tabConfig.base_line_position === 'bottom' ? '-1px' : 'unset'};
        width: 100%;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        visibility: hidden;
        opacity: 0;
      }

      &:hover:after {
        visibility: visible;
        opacity: 1;
        @media (max-width: 700px) {
          visibility: hidden;
          opacity: 0;
        }
      }

      &._active_tab {
        color: ${theme.palette.themePrimary};

        &:after {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
`;
