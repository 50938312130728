function get_text_position(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

export let redirect_to_login = () => {
  let redirect_to = window.location.pathname + window.location.search;
  let count = (redirect_to.match(/redirect_to/g) || []).length;
  if (count > 1) {
    let position = get_text_position(redirect_to, 'redirect_to', 2);
    redirect_to = redirect_to.substring(0, position - 1);
  }
  window.location.href = `/login?redirect_to=${encodeURIComponent(
    redirect_to
  )}`;
};

export let LocalstorageValue = ({ key, default_value = null }) => {
  return {
    get: () => {
      let result = localStorage.getItem(key);
      if (result == null) {
        return default_value;
      }
      try {
        return JSON.parse(result);
      } catch (err) {
        localStorage.removeItem(key);
        return default_value;
      }
    },
    set: (value) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
  };
};

export let allowed = LocalstorageValue({ key: 'allowed', default_value: true });
