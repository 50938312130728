import { css } from 'styled-components';
import theme from '../theme';

export const inputStyles = css`
  .common_input,
  input,
  input.form-control {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 18px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;

    &::placeholder {
      color: #bdbdbd;
    }
  }
`;

export const labelStyles = css`
  .common_label {
    color: ${theme.semanticColors.inputLabel};
    margin-bottom: ${theme.spacing.s1};
    ${theme.fonts.medium};

    @media (max-width: 700px) {
      ${theme.fonts.small};
    }
  }
`;

// global classes for style sharing
export const globalStyleFabric = css`
  .btn_unstyled {
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    svg {
      margin-bottom: -${theme.spacing.s1};
    }
  }
  .spacer_pipe {
    margin: 0 ${theme.spacing.s1};
    color: ${theme.palette.gray};
  }
  .btn {
    &:disabled {
      background: ${theme.palette.offWhite2};
      color: ${theme.palette.offBlack2};
      svg,
      svg path {
        color: ${theme.palette.offBlack2};
      }
    }
  }
  .tooltip__content {
    ${theme.fonts.absolute.small};
    width: max-content;
    &.grd {
      display: grid;
    }
    @media (max-width: 700px) {
      ${theme.fonts.absolute.tiny};
      width: 160px;
    }
  }
  .dotted_link {
    border-radius: ${theme.effects.roundedCorner3};
    border: ${theme.semanticProps.borderDashedThemePrimary};
    padding: ${theme.spacing.s2} ${theme.spacing.s3};
    &:hover {
      box-shadow: ${theme.effects.cardShadow};
    }
  }
  .Select-placeholder,
  .Select-value-label,
  .Select-input {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .error {
    background-color: ${theme.semanticColors.errorBG};
    color: ${theme.semanticColors.error};

    &_heading {
      ${theme.fonts.absolute.medium}
      color: ${theme.semanticColors.error};
    }
  }
  .warn {
    &,
    &-br {
      background-color: ${theme.semanticColors.warningBG};
      color: ${theme.semanticColors.warning};
    }
    &-br {
      border: 1px solid ${theme.palette.yellow4};
    }

    &_heading {
      ${theme.fonts.absolute.medium}
      color: ${theme.semanticColors.warning};
    }
  }
  .success {
    &,
    &-br {
      background-color: ${theme.semanticColors.successBG};
      color: ${theme.semanticColors.success};
    }
    &-br {
      border: 1px solid ${theme.palette.green4};
    }

    &_heading {
      ${theme.fonts.absolute.medium}
      color: ${theme.semanticColors.success};
    }
  }
  .info {
    &,
    &-br {
      background-color: ${theme.semanticColors.infoBG};
      color: ${theme.semanticColors.info};
    }

    &_heading {
      ${theme.fonts.absolute.medium}
      color: ${theme.semanticColors.info};
    }
  }
  .success,
  .error,
  .info,
  .success,
  .warn {
    &,
    &-br {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.s3};
      strong {
        margin: 0;
      }
    }
  }
  .error,
  .info,
  .success,
  .warn {
    &_sub_heading {
      ${theme.fonts.absolute.small};
      color: ${theme.palette.offBlack4};
    }
    &_card {
      &__block,
      &__page {
        max-width: initial;
        text-align: initial;
        padding: ${theme.spacing.s2} ${theme.spacing.s3};
        border-radius: ${theme.effects.roundedCorner4};
        margin: 0 0 ${theme.screen.width.medium700 ? 0 : theme.spacing.s3};
      }
      &__block {
        margin: ${theme.screen.width.medium700 ? 0 : theme.spacing.s3} 0;
        ${theme.fonts.absolute.medium};
        &--mp {
          margin: ${theme.screen.width.medium700 ? 0 : theme.spacing.s3} 0;
          ${theme.fonts.absolute.mediumPlus};
        }
      }
      &__heading {
        margin: ${theme.screen.width.medium700 ? 0 : theme.spacing.s3} 0;
        ${theme.fonts.absolute.largePlus};
        @media (max-width: 700px) {
          ${theme.fonts.absolute.mediumPlus}
        }
      }
      &__page {
        &--heading {
          padding: ${theme.spacing.s3};
          background: ${theme.palette.themePrimary};
          color: ${theme.palette.white};
          border-top-right-radius: ${theme.effects.roundedCorner3};
          border-top-left-radius: ${theme.effects.roundedCorner3};
          ${theme.fonts.absolute.xLargePlus};
          @media (max-width: 700px) {
            ${theme.fonts.absolute.largePlus}
          }
        }
      }
    }
  }
`;

export { getTabStyles, getNewTabStyles } from './tabStyles';
export { roundSkeletonStyles } from './skeletonStyles';
