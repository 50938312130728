import React from 'react';

const default_notification_state = {
  message: '',
  percent: 0,
  status: '', // percent < 100 ? 'downloading' : 'completed',
  progressbar_color: 'transparent',
};

export function useNotificationClearEffect(
  download_status,
  setDownloadStatus,
  CancelToken
) {
  return React.useEffect(() => {
    const completed = +download_status.percent === 100;

    // clear the notification after some 1.5s
    if (download_status.message && completed) {
      const timeout = setTimeout(() => {
        setDownloadStatus({
          ...default_notification_state,
          source: CancelToken.source(),
        });
      }, 1500);

      return () => clearTimeout(timeout);
    }
  }, [
    CancelToken,
    download_status.message,
    download_status.percent,
    setDownloadStatus,
  ]);
}
