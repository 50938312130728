export const getDateRange = (date_ids = [], data = {}) => {
  const _ds = {};
  if (!data) {
    return _ds;
  }

  date_ids.forEach((date_id) => {
    _ds[date_id] = {
      from:
        data[date_id] && data[date_id].start_date
          ? data[date_id].start_date.toDate()
          : '',
      to:
        data[date_id] && data[date_id].end_date
          ? data[date_id].end_date.toDate()
          : '',
    };
  });

  return _ds;
};
