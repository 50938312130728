import { gql } from '@apollo/client';
import { OfferFragment } from './OfferFragment';
// import { HoldFragment } from './HoldsFragment';

// export const ShortlistItemFragment = gql`
//   fragment ShortlistItemFragment on ShortlistItem {
//     id
//     added_at
//     carats
//     offer {
//       ...OfferFragment
//       hold {
//         ...HoldItemFragment
//       }
//     }
//     pieces
//     note
//   }
//   ${HoldFragment}
//   ${OfferFragment}
// `;

// We can have a separate Fragment for the main page which will be called separately
export const ShortlistItemFragment = gql`
  fragment ShortlistItemFragment on ShortlistItem {
    id
    added_at
    carats
    is_memo
    customer_preference
    offer {
      ...OfferFragment
      Product {
        ... on Melee {
          id
          # Usage: carats, carats_ordered
          # are required for melee stock validation on 1st page load
          carats
          carats_ordered
          pieces
          pieces_ordered
          #usage: to location on melee shortlist page
          location {
            id
            country
          }
          delivery_time {
            max_business_days
            min_business_days
          }
        }
      }
    }
    pieces
    note
    shortlist_group {
      id
      group_name
    }
  }
  ${OfferFragment}
`;

export let MiniShortlistItemFragment = gql`
  fragment MiniShortlistItemFragment on ShortlistItem {
    id
    added_at
    carats
    is_memo
    customer_preference
    offer {
      id
      price_per_carat
      Product {
        ... on Diamond {
          id
        }
        ... on Melee {
          id
          # Usage: carats, carats_ordered
          # are required for melee stock validation on 1st page load
          carats
          carats_ordered
        }
        ... on Gemstone {
          id
        }
      }
      ProductType
    }
    pieces
    note
  }
`;
