import React from 'react';
import styled from 'styled-components';
import {
  DiamondAvailability,
  enquiryInternalStatusType,
  holdsTabStatus,
  ShipmentStatus,
  statusMapper,
} from '../../constants';
import { Icon } from 'fabric/resources/icon';
import { ReactComponent as LockIcon } from 'fabric/resources/svg/ui-icons/lock-icon.svg';
import { lighten } from 'polished';
import theme from 'fabric/theme';
import { CustomTrans } from 'helpers';

const getStatusColor = (status) => {
  switch (status) {
    case `EXTENSION_REQUESTED`:
    case 'INITIATED':
      return theme.palette.yellow700;
    case 'AVAILABLE':
    case 'OPEN':
      return theme.palette.gray800;
    case 'MANUAL_RECONCILED':
    case `NOT_VERIFIED`:
      return theme.palette.offBlack4;
    case 'AUTO_RECONCILLED':
      return theme.palette.offBlack4;
    case 'ALL_PENDING':
    case 'PHYSICAL_PENDING':
      return theme.palette.offBlack4;
    case `ACCEPTED`:
    case `Accepted`:
    case `ADDED`:
    case `Active`:
    case `ACTIVE`:
    case `CLOSED`:
    case `PAID`:
      return theme.palette.gray800;
    case `CONFIRMED`:
    case `APPROVED`:
    case `CREDIT_APPROVED`:
    case ShipmentStatus.FINAL:
    case `${enquiryInternalStatusType.DONE}_ENQUIRY`:
    case `Linked`:
    case 'QC_ACCEPTED':
    case DiamondAvailability.ON_HOLD:
      return theme.palette.green700;
    case DiamondAvailability.ON_MEMO:
    case `SELF_CONFIRM_PO`:
    case `SUPPLIER_RAISE_ISSUE_PRICE_RELATED`:
    case `SUPPLIER_RAISE_ISSUE_SOLD_OUT`:
    case `SUPPLIER_RAISE_ISSUE_OTHER`:
      return theme.palette.offBlack3;
    case `REQUESTED`:
    case 'CONSIGNMENT':
      return theme.palette.blue700;
    case `${enquiryInternalStatusType.REQUESTED}_ENQUIRY`:
      return `#F39C12`;
    case `REVOKED`:
    case `DENIED`:
    case `Denied`:
    case `NotLinked`:
    case `OVERDUE`:
      return theme.palette.red700;
    case 'REJECTED':
    case ShipmentStatus.CANCELLED:
    case 'RETURN_DENIED':
    case 'HISTORIC':
      return `#C0392B`;
    case 'SHIPPED':
    case holdsTabStatus.CONVERTED:
    case 'RETURNED':
    case `RETURN_DELIVERED`:
    case `RETURN_SHIPMENT`:
    case `SUPPLIER_RETURN_SHIPMENT`:
    case `INTER_OFFICE_SHIPMENT`:
    case 'SOURCE_TO_ORDER':
    case `INTERNAL_ORDER`:
    case `JEWELLERY`:
      return '#8E44AD';
    case `SUPPLIER_SHIPMENT`:
      return theme.palette.yellow7;
    case `CUSTOMER_RETURN_SHIPMENT`:
      return theme.palette.red0;
    case `CUSTOMER_SHIPMENT`:
      return theme.palette.green;
    case `INTERCOMPANY_SHIPMENT`:
      return theme.palette.blue5;
    case `FRESH_IMPORT`:
      return theme.palette.pink;
    case `${enquiryInternalStatusType.PENDING}_ENQUIRY`:
    case ShipmentStatus.PENDING: // Or CANCELLED
    case 'AWAITING_PAYMENT':
      return theme.palette.yellow700;
    case 'HOLD_REQUEST':
    case 'INACTIVE':
    case 'NOT_AVAILABLE':
    case 'PARTIALLY_CLOSED':
    case 'NOT_PAID':
    case 'SOLD_OUT':
    case 'SOLD':
    case 'LOST':
    case 'FAILED_QC':
    case 'RECEIVED':
    case 'DELIVERED':
    case 'LIVE':
    case `PURCHASE_ORDER`:
    case `IN_CUSTOMS`:
    case `IN_TRANSIT`:
    case 'NIVODA_EXPRESS':
    case `RETURN_ACCEPTED`:
    case `RETURN_IN_TRANSIT`:
    case `RETURN_AT_NIVODA`:
      return `#2C2C2C`;
    case 'EXPIRED':
    case 'CREATED':
      return theme.semanticColors.info;
    case 'QC_PASSED':
      return theme.semanticColors.labelSuccess;
    case 'QC_REJECTED':
      return theme.semanticColors.labelError;
    case 'QC_MISMATCH':
      return theme.semanticColors.labelWarn;
    case 'QC_PENDING':
      return theme.semanticColors.labelInfo;
    case 'HOLD':
      return theme.palette.red700;
    case 'STOCK_FINISHED':
      return '#C0392B';
    case 'IN_CONSIGNMENT':
      return theme.palette.green700;
    case 'RETURN_REQUESTED':
      return theme.semanticColors.labelWarn;
    case 'PENDING_RETURN':
      return theme.semanticColors.labelWarn;
    case 'IN_EXPRESS_INVENTORY':
      return theme.semanticColors.labelWarn;
    case 'FAAS_ORDER':
      return theme.palette.blackPrimary;
    default:
      return `#808080`;
  }
};

export const getStatusBG = (status) => {
  switch (status) {
    case 'ALL_PENDING':
      return theme.palette.offWhite6;
    case 'PHYSICAL_PENDING':
      return theme.palette.yellow3;
    case 'INITIATED':
      return theme.palette.yellow100;
    case 'AVAILABLE':
      return '#B3E87080';

    case 'AUTO_RECONCILED':
    case `ACCEPTED`:
    case `Accepted`:
    case `ADDED`:
    case `Active`:
    case `ACTIVE`:
    case `PAID`:
    case `CONFIRMED`:
    case `APPROVED`:
    case `CREDIT_APPROVED`:
    case `Linked`:
    case 'QC_ACCEPTED':
    case 'CLOSED':
    case DiamondAvailability.ON_HOLD:
    case ShipmentStatus.FINAL:
    case `${enquiryInternalStatusType.DONE}_ENQUIRY`:
    case 'COLLECTED':
      return theme.palette.green100;
    case DiamondAvailability.ON_MEMO:
      return `#50ae55`;
    case 'RECEIVED':
    case 'LIVE':
    case 'DELIVERED':
      return '#D7F2F1';
    case `IN_CUSTOMS`:
    case `IN_TRANSIT`:
    case 'NIVODA_EXPRESS':
    case `RETURN_ACCEPTED`:
      return `#E7F2F8`;
    case 'HOLD_REQUEST':
    case 'RETURN_REQUESTED':
      return '#ebaf3c';
    case 'REJECTED':
      return '#f75a5a80';
    case 'HISTORIC':
      return '#ffe6e8';
    case 'QC_REJECTED':
      return theme.semanticColors.labelErrorBG;
    case 'RETURN_DENIED':
      return '#E8E0DA';
    case ShipmentStatus.CANCELLED: // Or CANCELLED
    case 'NOT_AVAILABLE':
    case 'SOLD_OUT':
    case 'SOLD':
    case 'LOST':
    case 'CN_OPEN':
      return '#f75a5a80';
    case `REQUESTED`:
      return '#f5bf0080';
    case `${enquiryInternalStatusType.REQUESTED}_ENQUIRY`:
      return `#FEFAF3`;
    case 'SHIPPED':
    case 'RETURNED':
    case `RETURN_DELIVERED`:
    case 'RETURNED_TO_SUPPLIER':
    case holdsTabStatus.CONVERTED:
      return '#f9f6fb';
    case `REVOKED`:
    case `DENIED`:
    case `Denied`:
    case `NotLinked`:
    case `OVERDUE`:
    case `EXTENSION_REQUESTED`:
      return theme.palette.red100;
    case `${enquiryInternalStatusType.PENDING}_ENQUIRY`:
    case ShipmentStatus.PENDING:
    case `AWAITING_PAYMENT`:
    case `NOT_VERIFIED`:
    case 'MANUAL_RECONCILED':
      return theme.palette.yellow100;
    case 'INACTIVE':
    case `RETURN_IN_TRANSIT`:
    case `RETURN_AT_NIVODA`:
    case 'PARTIALLY_CLOSED':
    case 'NOT_PAID':
    case `PURCHASE_ORDER`:
      return `#F6F7F7`;
    case 'CREATED':
      return theme.semanticColors.infoBG;
    case `SELF_CONFIRM_PO`:
    case 'PARTIAL_APPLIED':
      return theme.palette.brandBlue1;
    case 'QC_MISMATCH':
      return theme.semanticColors.labelWarnBG;
    case 'QC_PASSED':
      return theme.semanticColors.labelSuccessBG;
    case 'QC_PENDING':
      return theme.semanticColors.labelInfoBG;
    case `SUPPLIER_RAISE_ISSUE_PRICE_RELATED`:
    case `SUPPLIER_RAISE_ISSUE_SOLD_OUT`:
    case `SUPPLIER_RAISE_ISSUE_RUSSIAN_ORIGIN`:
    case `SUPPLIER_RAISE_ISSUE_OTHER`:
      return '#F65A5A80';
    case 'CONSIGNMENT':
      return theme.palette.blue100;
    case `HOLD`:
      return theme.palette.red100;
    case `INTERNAL_ORDER`:
    case 'SOURCE_TO_ORDER':
    case `INTER_OFFICE_SHIPMENT`:
    case `SUPPLIER_RETURN_SHIPMENT`:
    case `JEWELLERY`:
      return '#fbf1ff';
    case `SUPPLIER_SHIPMENT`:
      return theme.palette.yellow100;
    case `CUSTOMER_RETURN_SHIPMENT`:
      return theme.palette.red100;
    case `CUSTOMER_SHIPMENT`:
      return theme.palette.green11;
    case `INTERCOMPANY_SHIPMENT`:
      return theme.palette.blue50;
    case `FRESH_IMPORT`:
      return theme.palette.pink100;
    case 'EXPIRED':
    case 'STOCK_FINISHED':
    case 'REFUNDED':
      return '#ffe6e8';
    case 'IN_CONSIGNMENT':
      return theme.palette.green100;
    // case 'RETURN_REQUESTED':
    //   return theme.palette.yellow100;
    case 'PENDING_RETURN':
      return theme.palette.yellow100;
    case 'IN_EXPRESS_INVENTORY':
      return theme.palette.yellow100;
    case 'FAAS_ORDER':
      return theme.palette.nivodaBlue80;
    default:
      return `#F9F9F9`;
  }
};

const getBorderColor = (status) => {
  switch (status) {
    case 'ALL_PENDING':
      return theme.palette.offWhite2;
    case 'PHYSICAL_PENDING':
      return theme.palette.yellow1;
    case 'QC_PASSED':
    case 'QC_REJECTED':
    case 'QC_MISMATCH':
    case 'QC_PENDING':
      return getStatusBG(status);
    case `REQUESTED`:
      return '#F5BF00';
    case 'REJECTED':
      return '#F75A5A';
    case 'OVERDUE':
      return theme.palette.red100;
    case 'OPEN':
    case `AWAITING_PAYMENT`:
      return theme.palette.yellow100;
    case 'CLOSED':
    case 'PAID':
      return theme.palette.green3;
    case 'PARTIALLY_CLOSED':
    case 'NOT_PAID':
    case 'PENDING':
      return '#f9bd90';
    case 'AVAILABLE':
      return theme.palette.green3;
    case `RETURN_SHIPMENT`:
    case `INTERNAL_ORDER`:
    case 'SOURCE_TO_ORDER':
    case `INTER_OFFICE_SHIPMENT`:
    case `SUPPLIER_RETURN_SHIPMENT`:
    case `JEWELLERY`:
      return '#8E44AD';
    case `SUPPLIER_SHIPMENT`:
      return theme.palette.yellow7;
    case `CUSTOMER_RETURN_SHIPMENT`:
      return theme.palette.red0;
    case `CUSTOMER_SHIPMENT`:
      return theme.palette.green;
    case `INTERCOMPANY_SHIPMENT`:
      return theme.palette.blue5;
    case `FRESH_IMPORT`:
      return theme.palette.pink;
    case 'RETURN_REQUESTED':
      return theme.palette.yellow100;
    case 'PENDING_RETURN':
      return theme.palette.yellow100;
    case 'IN_EXPRESS_INVENTORY':
      return theme.palette.yellow100;
    case 'FAAS_ORDER':
      return theme.palette.blackPrimary;
    default:
      return lighten(0.4, getStatusColor(status));
  }
};

const StatusDiv = styled.div`
  border: 1px solid
    ${({ name, border = true }) =>
      border ? getBorderColor(name) : 'transparent'};
  box-sizing: border-box;
  border-radius: ${({ isQcStatus }) => (isQcStatus ? '3px' : '32px')};
  font-weight: ${({ isQcStatus }) =>
    isQcStatus ? '600 !important' : '500 !important'};
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  padding: ${({ isQcStatus }) =>
    isQcStatus ? '2px 4px' : `${theme.spacing.px} ${theme.spacing.s2}`};
  min-width: 68px;
  width: fit-content;
  color: ${({ name }) => getStatusColor(name)};
  background: ${({ name }) => getStatusBG(name)};
  text-decoration: none;
  outline: transparent;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: ${({ bs }) => (bs ? 'break-spaces' : 'nowrap')};
  text-overflow: ellipsis;
  &:hover {
    border: 1px solid 1px solid
      ${({ name, border = true }) =>
        border ? getBorderColor(name) : 'transparent'};
    ${({ hover }) =>
      hover ? 'box-shadow: rgba(51, 51, 51, 0.07) 0 3px 10px;' : ''};
  }
  &.center_items {
    display: flex;
    align-items: center;
  }
`;

const customStatusMapper = (text) => {
  if (!text) return '';
  return text?.replace(' ', '_').toLowerCase();
};

export const RequestStatus = ({
  name,
  border = false,
  bs = false, // break spaces on overflow
  fitContent = false,
  hover = false,
  customLabel = '',
  isBlocked = false,
  showIcon = false,
  onClick = () => {},
  style,
  title = '',
  isQcStatus = false,
  alignCenter = false,
}) => {
  let statusPillClass = alignCenter
    ? 'status--pill center_items'
    : 'status--pill';

  return name ? (
    <>
      <StatusDiv
        data-automation-id="order-status"
        border={border}
        style={style}
        name={name}
        title={title || statusMapper[name]}
        fit={fitContent}
        bs={bs}
        hover={hover}
        onClick={onClick}
        isQcStatus={isQcStatus}
        className={statusPillClass}
      >
        {customLabel ? (
          customLabel
        ) : (
          <>
            {statusMapper[name] && (
              <CustomTrans
                text={customStatusMapper(statusMapper[name])}
                returnPlainText={true}
                fallBack={statusMapper[name]}
              />
            )}
          </>
        )}
        {isBlocked && <LockIcon />}
      </StatusDiv>
      {name === 'QC_MISMATCH' && showIcon && (
        <span style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
          <Icon name="nivodaVerified" size={18} color="#ADADAD" />
        </span>
      )}
    </>
  ) : null;
};
