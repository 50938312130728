import { useQuery } from '@apollo/client';
import { graphqlClient, adminGraphqlClient } from 'graphql/factory';
import {
  GET_USER_COMPREHENSIVE_PROFILE,
  GET_ADMIN_USER_COMPREHENSIVE_PROFILE,
} from 'graphql/queries/common/profile';
import React from 'react';
import { getUserReviewStatus } from 'utils/user';

export let UserContext = React.createContext({
  user: null,
  me: null,
  company: null,
  is_aip: false,
});

export let UserContextProvider = ({ is_admin, children }) => {
  let FRAGMENT = is_admin
    ? GET_ADMIN_USER_COMPREHENSIVE_PROFILE
    : GET_USER_COMPREHENSIVE_PROFILE;
  let { data, error, loading } = useQuery(FRAGMENT, {
    client: is_admin ? adminGraphqlClient : graphqlClient,
  });
  if (error) {
    // eslint-disable-next-line no-console
    console.error('error', error);
  }
  let value = {
    user: loading ? null : data?.me,
    me: loading ? null : data?.me,
    company: loading ? null : data?.me?.company,
    is_aip: loading || data == null ? false : data?.me?.company?.all_in_pricing,
    is_kyc_verified:
      loading || data == null ? false : data?.me?.company?.is_kyc_verified,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export let useUserContext = () => {
  let {
    me = {},
    company,
    is_aip,
    is_kyc_verified,
  } = React.useContext(UserContext);
  let { userInReview } = getUserReviewStatus(me);
  return {
    me,
    user: me,
    company,
    userInReview,
    is_aip,
    is_kyc_verified,
  };
};
