import { gql } from '@apollo/client';
import {
  JewelleryFragment,
  JewelleryOrderItemFragment,
  JewelleryMountFragment,
  JewelleryStoneFragment,
  JewelleryFileFragment,
} from '../../fragments';

export const JEWELLERY_ORDERS_BY_STATUS = gql`
  query jewelleryOrdersByStatus(
    $query: JewelleryOrderQuery
    $limit: Int
    $offset: Int
  ) {
    jewellery_orders_by_status(query: $query, limit: $limit, offset: $offset) {
      total_count
      items {
        ...JewelleryFragment
        supplier_request_method
        supplier_order_data
        order_item {
          ...JewelleryOrderItemFragment
        }
        mounts {
          ...JewelleryMountFragment
        }
        stones {
          ...JewelleryStoneFragment
        }
        jewellery_files {
          ...JewelleryFileFragment
        }
      }
    }
  }
  ${JewelleryFragment}
  ${JewelleryOrderItemFragment}
  ${JewelleryMountFragment}
  ${JewelleryStoneFragment}
  ${JewelleryFileFragment}
`;
