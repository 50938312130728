import { gql } from '@apollo/client';
import { SessionFragment } from '../../fragments/SessionFragment';

export let LoginQuery = gql`
  query ($username: String!, $password: String!, $twofactorauth: String) {
    authenticate {
      username_and_password(
        username: $username
        password: $password
        twofactorauth: $twofactorauth
      ) {
        ...session
      }
    }
  }
  ${SessionFragment}
`;

// noinspection GraphQLUnresolvedReference
export const GET_USER_SESSION = gql`
  query GetUserSession {
    userSession @client
  }
`;
