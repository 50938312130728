import styled, { css } from 'styled-components';
import { theme } from 'fabric/theme';
const styled_predicate = (fn) => {
  return (p) => (fn(p) ? '&' : '&:not(&)');
};

export const BtnWrapper = styled.div`
  ${styled_predicate((p) => !p.isListView)} {
    .v2_icon_holds {
      color: #424243;
      margin-right: 2px;
    }
  }
  .v2_holds_button {
    display: flex;
    align-items: center;
    border: none;
    width: 30px;
    height: 30px;
    margin-bottom: ${(p) => !p.isListView && '6px'};
    border-radius: 50%;
    background: transparent;
    justify-content: center;
    &:hover {
      background: ${(p) =>
        p.isHoldButtonHover && !p.isListView && !p.isPdpV2
          ? '#e9f2fd'
          : 'transparent'};
      border: ${(p) =>
        p.isHoldButtonHover && !p.isListView && '1px solid #c2dbfc'};
    }
  }
  .v2_holds_active {
    background: #e9f2fd;
    border: 2px solid #c2dbfc;
  }
  .holds_cta {
    background: ${theme.palette.white};

    &__button {
      display: inline-flex;
      align-items: center;
      background: ${({ btnProps = {} }) => btnProps.bg};
      color: ${({ btnProps = {} }) => btnProps.color};
      gap: ${({ isListView }) => (isListView ? 0 : theme.spacing.s1)};
      border: ${({ btnProps = {} }) => btnProps.border};
      position: ${({ isListView }) => (isListView ? 'initial' : 'absolute')};
      top: ${({ isListView, isOwner = false }) =>
        isListView ? 'unset' : isOwner ? '8px' : '10px'};
      z-index: 1;
      background: ${({ bg }) => bg};
      opacity: 1;
      border-radius: 100px;
      right: ${({ isListView, isOwner = false }) =>
        isListView ? 'unset' : isOwner ? '6px' : '53px'};
      padding: ${({ isListView }) =>
        isListView ? theme.spacing.s1 : `6px ${theme.spacing.s2}`};
      ${theme.fonts.smallPlus};
      @media (max-width: 700px) {
        ${theme.fonts.tiny};
      }

      ${({ isListView }) =>
        isListView
          ? css`
              width: 24px;
              height: 24px;
              justify-content: center;
              transition: 0.2s ease-out all;
            `
          : ''}

      &:hover {
        ${({ isListView }) =>
          isListView
            ? css`
                opacity: 1;
                position: relative;
                width: max-content;
                height: auto;
                transition: 0.2s ease-in all;

                .holds_cta__label {
                  &,
                  &--desc,
                  &--main,
                  &--text {
                    display: inline-flex;
                  }

                  ${theme.fonts.smallPlus};
                  align-items: center;
                  width: max-content;
                  gap: ${theme.spacing.s1};
                  opacity: 1;
                  margin: 0 ${theme.spacing.s1};
                  transition: 0.2s ease-in width, display, opacity;
                  @media (max-width: 700px) {
                    ${theme.fonts.tiny};
                  }

                  &--desc {
                    opacity: 1;
                    width: max-content;
                    height: auto;
                  }
                }
              `
            : ''}
      }
    }

    &__icon {
      margin-top: ${({ isListView }) => (isListView ? '-1px' : '-2px')};
    }

    &__label {
      transition: 0.2s ease-out width, display, opacity;
      opacity: ${({ isListView }) => (isListView ? 0 : 1)};
      ${theme.fonts.smallPlus};
      @media (max-width: 700px) {
        ${theme.fonts.tiny};
      }

      &--main {
        padding: 1px 0;
        display: ${({ isListView }) => (isListView ? 'none' : 'inline-flex')};
        align-items: center;
        opacity: 1;
        transition: 0.2s ease-out opacity, display, width, height;
        width: max-content;
        height: auto;
      }

      &--desc {
        padding: 1px 0;
        display: ${({ isListView }) => (!isListView ? 'none' : 'inline-flex')};
        opacity: 0;
        transition: 0.2s ease-in opacity, display, width, height;
        width: 0;
        height: 0;
      }

      &--text {
        padding: 1px 0;
        display: ${({ isListView }) => (isListView ? 'none' : 'inline-flex')};
        align-items: center;
      }

      &:hover {
        ${({ isListView }) =>
          isListView
            ? ''
            : css`
                .holds_cta__label {
                  &--main {
                    display: none;
                    opacity: 0;
                    transition: 0.2s ease-in opacity, display, width, height;
                    width: 0;
                    height: 0;
                  }

                  &--desc {
                    display: inline-flex;
                    align-items: center;
                    opacity: 1;
                    transition: 0.2s ease-out opacity, display, width, height;
                    width: max-content;
                    height: auto;
                  }

                  &--text {
                    display: inline-flex;
                    transition: 0.2s ease-out display;
                  }
                }
              `}
      }
    }
  }
  ${styled_predicate((p) => p.isListView)} {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
    }
    &:hover {
      svg {
        color: white;
      }
    }
  }
  ${({ customStyles = [] }) => customStyles}
`;

export const BtnWrapperV2 = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${styled_predicate((p) => !p.noHover)} {
    &:hover {
      > div {
        svg {
          color: white;
        }
      }
    }
  }
`;
