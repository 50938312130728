import { gql } from '@apollo/client';
import {
  AdminReturnOrderItemFragment,
  ReturnItemFragment,
} from '../../fragments';

export const FETCH_RETURN_ELIGIBLE_ORDER_ITEMS = gql`
  query fetchReturnEligibleOrderItems($offset: Int, $query: ReturnItemQuery!) {
    admin_get_items_for_returns(limit: 500, offset: $offset, query: $query) {
      error
      type
      total_count
      items {
        ...AdminReturnOrderItem
      }
    }
  }
  ${AdminReturnOrderItemFragment}
`;

export const FETCH_INITIATED_INTERNAL_ORDER_ITEMS = gql`
  query fetchInitiatedInternalReturnOrderItems(
    $offset: Int
    $query: ReturnItemQuery!
  ) {
    get_returned_items(limit: 500, offset: $offset, query: $query) {
      error
      total_count
      items {
        ...ReturnItem
      }
    }
  }
  ${ReturnItemFragment}
`;

export const FETCH_RETURNED_ITEMS = gql`
  query fetchReturnedItems(
    $offset: Int
    $origin_country: String
    $query: ReturnItemQuery!
  ) {
    get_returned_items(
      limit: 500
      offset: $offset
      origin_country: $origin_country
      query: $query
    ) {
      error
      total_count
      items {
        ...ReturnItem
      }
    }
  }
  ${ReturnItemFragment}
`;

export const FETCH_RETURNED_ITEMS_FILTER_OVERVIEW_DATA = gql`
  query fetchReturnItemsFilterOverviewData($query: ReturnItemQuery!) {
    get_returned_items(query: $query) {
      error
      suppliers {
        id
        name
      }
      customers {
        id
        name
      }
    }
  }
`;

export const FETCH_TO_STORE_ITEMS = gql`
  query fetchToStoreItems($offset: Int, $query: ReturnItemQuery!) {
    admin_get_to_store_items(limit: 500, offset: $offset, query: $query) {
      error
      total_count
      items {
        ...ReturnItem
      }
    }
  }
  ${ReturnItemFragment}
`;

export const FETCH_TO_STORE_ITEMS_FILTER_OVERVIEW_DATA = gql`
  query fetchToStoreItemsFilterOverviewData($query: ReturnItemQuery!) {
    admin_get_to_store_items(query: $query) {
      error
      suppliers {
        id
        name
      }
      customers {
        id
        name
      }
    }
  }
`;

export const ADMIN_FETCH_TO_STORE_TRAY_DATA = gql`
  query adminFetchToStoreData($current_location: String) {
    get_to_store_trays(current_location: $current_location) {
      total_trays {
        trays_count
        stone_count
        cert_count
      }
      individual_trays {
        tray_number
        stone_count
        cert_count
      }
    }
  }
`;

export const VALIDATE_FRESH_IMPORT_ITEMS = gql`
  query validateFreshImportItems($shipment_id: ID!) {
    admin_validate_fresh_import_items(shipment_id: $shipment_id)
  }
`;

export const FETCH_RETURN_REASONS = gql`
  query fetchReturnReasons {
    admin_get_return_reasons {
      id
      reason
      type
    }
  }
`;
