import { Diamond, isStoneFancy } from '../helpers';
import { isDiamondItem, isGemstoneItem, isMeleeItem } from './productTypes';

export const get_diamond_discount = (items_to_summarise, type) => {
  let non_fancy_count = 0;
  const total = items_to_summarise.reduce((acc, item) => {
    const is_melee_type = isMeleeItem(item.offer);
    const is_gemstone_type = isGemstoneItem(item.offer);
    const { Product } = item;
    let discount = 0;
    if (is_gemstone_type) return acc;
    // if stone is fancy then don't consider it for discount
    if (
      !isStoneFancy(
        !is_melee_type ? Product?.certificate?.color : Product?.color,
        !is_melee_type ? Product?.certificate?.carats : Product?.carats
      )
    ) {
      discount = parseFloat(
        !is_melee_type
          ? type === 'diamond'
            ? item.offer
              ? item.offer['product_discount']
              : 0
            : item?.offer?.discount
          : 0
      );
      non_fancy_count += 1;
    }

    return acc + discount;
  }, 0);

  return total === 0 ? 0 : (total / 100 / non_fancy_count).toFixed(2);
};

export const getItemsInCartOffers = (
  items_in_cart,
  returnable_array = [],
  all_in_pricing
) => {
  return items_in_cart.map((item) => {
    const { __typename } = item;
    let is_diamond_or_gemstone_type = !isMeleeItem(item.offer);

    if (__typename === 'OrderItem' && item['Product'] !== void 0) {
      is_diamond_or_gemstone_type = !isMeleeItem(item);
    }
    // check return and get the prices and discounts accordingly
    let is_in_returnables = is_diamond_or_gemstone_type
      ? Boolean(returnable_array.find((r) => r === item.offer.id))
      : false;

    const discount_and_price = is_diamond_or_gemstone_type
      ? withReturnCharges(
          item.offer,
          is_diamond_or_gemstone_type
            ? is_in_returnables ||
                optedForReturn(item, is_diamond_or_gemstone_type)
            : false,
          is_diamond_or_gemstone_type,
          all_in_pricing
        )
      : {};

    const { offer, ...rest } = item;

    return {
      ...(is_diamond_or_gemstone_type
        ? {
            ...rest,
            ...offer,
          }
        : {
            ...rest,
            ...offer,
            total_price_without_credit_charge:
              (+offer.total_price_without_credit_charge /
                +offer.Product.carats) *
              item.carats,
            return_price:
              (+offer.return_price / +offer.Product.carats) * item.carats,
            product_price: item.carats * +offer.price_per_carat,
            price: item.carats * +offer.price_per_carat,
          }),
      ...discount_and_price,
    };
  });
};
// Diamond.get_diamond_discount({
//   diamond,
//   manual_discount: `${(discount / 100).toFixed(2)}%`,
//   is_hover: is_hover,
// })
export const getItemDiscountWithReturn = (diamond, discount, is_hover) => {
  const is_diamond_type = isDiamondItem(diamond);

  return is_diamond_type
    ? Diamond.get_discount({
        diamond,
        manual_discount: `${(discount / 100).toFixed(2)}%`,
        is_hover: is_hover,
      })
    : null;
};

// check if return charges are applicable and return price and discount accordingly
export const withReturnCharges = (
  offer = { return_price: '', return_discount: '' },
  need_return = false,
  is_diamond_or_gemstone_type,
  is_aip = false
) => {
  const return_charges =
    is_diamond_or_gemstone_type && offer ? offer.return_price - offer.price : 0;

  return is_diamond_or_gemstone_type && offer
    ? need_return && !is_aip
      ? {
          price: offer.return_price,
          discount: offer.return_discount,
          return_charges,
        }
      : { price: offer.price, discount: offer.discount, return_charges }
    : { price: 0, discount: 0, return_charges: 0 };
};

// check if return charges are applicable
export const optedForReturn = (item, is_diamond_or_gemstone_type) => {
  return is_diamond_or_gemstone_type
    ? item?.offer?.is_returnable &&
        item?.offer['Product']?.supplier?.company_settings.accept_returns &&
        item.return_option
    : false;
};

// export const getTotalIncentiveBal = (incentive) =>
//   incentive.reduce(
//     (total, { incentive_pay_balance }) => total + +incentive_pay_balance,
//     0
//   );
//
// //existing subtotal + 1% incentive pay charge on sub-total - the incentive pay balance
// export const apply_incentive = (
//   sub_total,
//   incentive_bal,
//   incentive_pay_active
// ) =>
//   incentive_pay_active
//     ? sub_total + +Number(sub_total / 100).toFixed(2) - incentive_bal
//     : sub_total;

export const hasShadeEyeCleanCheck = (diamond) =>
  (Diamond.get_shade(diamond).length &&
    Diamond.get_shade(diamond)[0].code !== 'No Shade') ||
  (Diamond.get_bgm(diamond) && Diamond.get_bgm(diamond) !== 'No BGM') ||
  (Diamond.get_eyeclean(diamond) &&
    Diamond.get_eyeclean(diamond).slice(0, -1) < 100) ||
  Diamond.get_eyeclean(diamond) === 'No';
