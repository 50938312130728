import { gql } from '@apollo/client';

export const SessionFragment = gql`
  fragment session on Session {
    token
    user {
      id
      firstName
      lastName
      email
      role
      preferred_language
      country
      steps_required
      company {
        id
        name
        loupe360_url
        company_settings {
          CompanyId
          holds_enabled
          accept_holds
          supersaver_enabled
          company_code
        }
        market_pay_active
        disable_checkout
        all_in_pricing
      }
    }
    expires
    refresh_token
    hubspot_token
    zendesk_token
  }
`;
