import { theme } from '@nivoda/ui';

export const holdsTabStatus = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  INACTIVE: 'INACTIVE',
};

export const mapHoldStatusToTabId = Object.freeze({
  REQUESTED: holdsTabStatus.PENDING,
  ON_HOLD: holdsTabStatus.ACTIVE, // Active
  EXTENSION_REQUESTED: holdsTabStatus.ACTIVE, // Active
  REJECTED: holdsTabStatus.REJECTED,
  EXPIRED: holdsTabStatus.INACTIVE, // Inactive
  CANCELLED: holdsTabStatus.INACTIVE, // Inactive
});

export const holdStatusTypes = Object.freeze({
  REQUESTED: 'REQUESTED',
  ON_HOLD: 'ON_HOLD',
  EXTENSION_REQUESTED: 'EXTENSION_REQUESTED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED', // Inactive
  CANCELLED: 'CANCELLED', // Inactive
});

export const mapHoldStatusToTab = {
  [holdStatusTypes.REQUESTED]: '/supplier/supplier-holds/pending',
  [holdStatusTypes.ON_HOLD]: '/supplier/supplier-holds/active',
  [holdStatusTypes.EXTENSION_REQUESTED]: '/supplier/supplier-holds/active',
  [holdStatusTypes.REJECTED]: '/supplier/supplier-holds/rejected',
  [holdStatusTypes.EXPIRED]: '/supplier/supplier-holds/inactive',
  [holdStatusTypes.CANCELLED]: '/supplier/supplier-holds/inactive',
};

// export const holdActionOptions = [
//   {
//     id: holdStatusTypes.CANCELLED,
//     label: 'Release Diamond',
//   },
//   {
//     id: holdStatusTypes.EXTENSION_REQUESTED,
//     label: 'Request Hold Extension',
//   },
// ];

export const holdActionOptions = (t, productType) => {
  return [
    {
      id: holdStatusTypes.CANCELLED,
      label: t('release_product').replace('{{PRODUCT}}', productType),
    },
    {
      id: holdStatusTypes.EXTENSION_REQUESTED,
      label: t('request_hold_extension'),
    },
  ];
};

export const colorMap = {
  DEFAULT: {
    color: theme.palette.blackPrimary,
    bg: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
  },
  [holdStatusTypes.ON_HOLD]: {
    color: theme.palette.white,
    bg: theme.semanticColors.holdButtonBG,
    border: theme.semanticProps.holdButtonBorder,
  },
  [holdStatusTypes.REQUESTED]: {
    color: theme.palette.white,
    bg: theme.semanticColors.requestedHoldBG,
    border: theme.semanticProps.requestedHoldBorder,
  },
  [holdStatusTypes.EXTENSION_REQUESTED]: {
    color: theme.palette.white,
    bg: theme.semanticColors.extensionRequestedBG,
    border: theme.semanticProps.extensionRequestedBorder,
  },
  [holdStatusTypes.CANCELLED]: {
    color: theme.semanticColors.cancelledColor,
    bg: theme.semanticColors.pendingBG,
    border: theme.semanticProps.expiredBorder,
  },
  [holdStatusTypes.REJECTED]: {
    color: theme.semanticColors.rejectedColor,
    bg: theme.semanticColors.rejectedBG,
    border: `1px solid ${theme.semanticColors.rejectedBG}`,
  },
  [holdStatusTypes.EXPIRED]: {
    color: theme.semanticColors.pendingColor,
    bg: theme.semanticColors.pendingBG,
    border: theme.semanticProps.expiredBorder,
  },
};
