import { AdminGraphqlMutation } from 'graphql-admin';
import { gql } from '@apollo/client';

// Please avoid modifying this NOTES_FRAGMENT component
export const NOTES_FRAGMENT = gql`
  fragment NotesFragment on Note {
    id
    status
    createdAt
    data
    user {
      id
      firstName
      lastName
      profile_image
    }
  }
`;

export const ADMIN_MELEE_NOTES = gql`
  mutation adminUpdateMeleeNotes(
    $item_id: ID!
    $type: NoteType
    $comment: String
  ) {
    admin_update_note(item_id: $item_id, type: $type, comment: $comment) {
      ...NotesFragment
    }
  }
  ${NOTES_FRAGMENT}
`;

export const AdminUpdateNote = AdminGraphqlMutation({
  query: ADMIN_MELEE_NOTES,
});

export const ADMIN_UPDATE_NOTE = gql`
  mutation adminUpdateNote($item_id: ID!, $type: NoteType, $comment: String) {
    admin_update_note(item_id: $item_id, type: $type, comment: $comment) {
      id
      status
      createdAt
      data
      user {
        id
        firstName
        lastName
        profile_image
      }
    }
  }
`;

export const ADMIN_EDIT_NOTE = gql`
  mutation adminEditNote(
    $item_id: ID!
    $type: NoteType!
    $comment: String
    $note_id: ID!
  ) {
    admin_edit_note(
      item_id: $item_id
      type: $type
      comment: $comment
      note_id: $note_id
    ) {
      id
      status
      createdAt
      updatedAt
      data
      user {
        id
        firstName
        lastName
        profile_image
      }
    }
  }
`;
export const ADMIN_DELETE_NOTE = gql`
  mutation adminDeleteNote($type: NoteType!, $note_id: ID!) {
    admin_delete_note(type: $type, note_id: $note_id)
  }
`;

export const ADMIN_ADD_SUBNOTE = gql`
  mutation adminAddSubNote($GroupedUpdateId: ID!, $comment: String) {
    admin_add_subnote_groupUpdate(
      GroupedUpdateId: $GroupedUpdateId
      comment: $comment
    ) {
      id
      data
      createdAt
      updatedAt
    }
  }
`;

export const ADMIN_EDIT_SUBNOTE = gql`
  mutation adminEditSubNote($comment: String, $note_id: ID!) {
    admin_edit_subnote_groupUpdate(comment: $comment, note_id: $note_id) {
      id
      data
      createdAt
      updatedAt
    }
  }
`;

export const ADMIN_DELETE_SUBNOTE = gql`
  mutation adminDeleteSubNote($note_id: ID!) {
    admin_delete_subnote_groupUpdate(note_id: $note_id)
  }
`;
