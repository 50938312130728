import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

let get_options_from_props =
  (variable_names) =>
  ({
    options,
    dontRenderWhileLoading,
    suspendWhileLoading,
    swallowError,
    children,
    onError,
    ...variables
  }) => {
    if (process.env.NODE_ENV !== 'production') {
      let unknown_variables = Object.keys(variables).filter(
        (key) => !variable_names.includes(key)
      );
      if (unknown_variables.length !== 0) {
        throw new Error(
          `Unknown props '${unknown_variables.join(', ')}', given to query`
        );
      }
    }

    return {
      ...options,
      variables: variables,
    };
  };

class GraphqlServerError extends Error {
  name = 'Graphql Server Error';

  constructor(graphql_errors) {
    super();

    let get_info = (graphql_errors) => {
      let { graphQLErrors } = graphql_errors;
      if (graphQLErrors == null) {
        // eslint-disable-next-line no-console
        console.log(`graphql_errors:`, graphql_errors);
        return { message: '' };
      }
      if (graphQLErrors.length === 0) {
        return {
          message: `GraphQL network error: ${graphql_errors.message}`,
        };
      }
      if (graphQLErrors.length === 1) {
        let graphql_error = graphQLErrors[0];
        let message =
          graphql_error && graphql_error.message ? graphql_error.message : '';
        return {
          message: `${message} @ query.${
            graphql_error ? graphql_error.path.join('.') : 'NO_PATH'
          }`,
        };
      }
      if (graphQLErrors.length >= 2) {
        // eslint-disable-next-line no-console
        console.error(
          'Multiple graphql errors returned from query:',
          graphQLErrors
        );
        let graphql_error = graphQLErrors[0];
        return {
          message: `${graphql_error.message} @ query.${graphql_error.path.join(
            '.'
          )}`,
        };
      }
      // eslint-disable-next-line no-console
      console.dir(`GRAPHQL ERROR UNKNOWN graphql_errors:`, graphql_errors);
      return {
        message: `Error actually... unknown?`,
      };
    };

    let { message } = get_info(graphql_errors);
    this.message = message;
  }
}

export let GraphqlFactory = (options) => {
  // TODO Some extra options like dontRenderWhileLoading or ThrowOnError?
  // TODO Maybe make ThrowOnError the default ?
  // TODO Throw/Deprecate using query as option only
  let { query, map_data = (x) => x } =
    options.query != null ? options : { query: options };

  // NOTE This figures out what props are variables by looking at the query:
  // TODO Warn/Error when a prop is provided that is neither option nor variable
  // TODO Error when a variable shares the name of a prop we use
  let query_operation = query.definitions.find(
    (x) => x.kind === 'OperationDefinition' && x.operation === 'query'
  );
  let variable_names =
    query_operation &&
    query_operation.variableDefinitions.map((x) => x.variable.name.value);

  // TODO Figure out from here what fields I expect to have + use then for dontRenderWhileLoading ?

  let sub_component = graphql(query, {
    options: get_options_from_props(variable_names),
  })((stuff) => {
    let {
      data,
      children,
      dontRenderWhileLoading,
      suspendWhileLoading,
      swallowError,
      mutate,
    } = stuff;

    if (suspendWhileLoading === true && data.networkStatus === 1) {
      // This will make React suspense keep the loading screen up
      // NOTE Use with care!
      throw new Promise(() => {});
    }

    if (dontRenderWhileLoading === true && data.networkStatus === 1) {
      return null;
    }

    if (data == null) {
      if (mutate != null) {
        throw new Error(
          `You are using GraphqlQuery when you need GraphqlMutation.`
        );
      } else {
        throw new Error(
          `For some reason, 'data' is empty, and mutate is not set`
        );
      }
    }

    // Properly throw the error when we encountered it = good debugging <3
    if (data.error != null) {
      // eslint-disable-next-line no-console
      console.error(`Error(s) found in graphql response:`, data.error);
      if (swallowError) {
        return children({
          error: data.error,
          // We still pass on the data, so that the user can still use the parts that didn't error.
          // Need to make sure to handle the error and throw if there is errors that we can't handle/didn't expect.
          //  Doesn't work with apollow 2.8 we should update asap
          // ...map_data(data),
        });
      } else {
        throw new GraphqlServerError(data.error);
      }
    }
    return children({
      ...map_data(data),
      // TODO This should be either a separate utility or more generic/tested
      fetchMore: (key, variables = {}, mode = 'infinite-scroll') => {
        return data.fetchMore({
          variables: {
            offset: data[key]?.items?.length,
            ...variables, // enables passing variables dynamically or conditionally from fetchMore
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const mergeByMode = () =>
              mode === 'cursor'
                ? fetchMoreResult[key]?.items
                : [
                    ...previousResult[key]?.items,
                    ...fetchMoreResult[key]?.items,
                  ];
            return fetchMoreResult
              ? {
                  ...previousResult,
                  ...fetchMoreResult, // This makes sure we update all fields from prev result
                  [key]: {
                    ...previousResult[key],
                    ...fetchMoreResult[key], // This will update other fields with new results
                    items: mergeByMode(),
                  },
                }
              : previousResult;
          },
        });
      },
    });
  });

  sub_component.query = query;

  return sub_component;
};

export let GraphqlMutation = ({
  query: graphql_query,
  optimisticResponse,
  update,
  refetchQueries,
  swallowError,
}) => {
  let query_operation = graphql_query.definitions.find(
    (x) => x.kind === 'OperationDefinition' && x.operation === 'mutation'
  );
  let variable_names = query_operation.variableDefinitions.map(
    (x) => x.variable.name.value
  );

  let MutationComponent = graphql(graphql_query, {
    options: get_options_from_props(variable_names),
  })(({ mutate, children, options }) => {
    let { client } = options;
    let mutate_wrapped = async (variables) => {
      try {
        return await mutate({
          variables,
          refetchQueries,
          optimisticResponse:
            optimisticResponse &&
            ((variables) => {
              return optimisticResponse(variables, client);
            }), // TODO Error without optimistic ?
          update, // TODO Error without update ?
        });
      } catch (graphql_errors) {
        // Properly throw the error when we encounted it = good debugging <3
        if (swallowError) {
          return children({
            error: graphql_errors,
          });
        } else {
          throw new GraphqlServerError(graphql_errors);
        }
      }
    };
    return children(mutate_wrapped);
  });

  // I know this looks weird - and it feels hacky, though it is the most
  // solid (and only?) way I can overwrite the apollo client inside a mutation
  return ({ options, children, ...props }) => {
    if (!options.client) {
      throw new Error(`No client provided to mutation`);
    }
    return (
      <ApolloProvider client={options.client}>
        <MutationComponent
          {...props}
          options={options}
          // eslint-disable-next-line no-console
          onError={(errors) => console.log(`errors:`, errors)}
        >
          {(mutation_props) => (
            <ApolloProvider client={options.client}>
              {children(mutation_props)}
            </ApolloProvider>
          )}
        </MutationComponent>
      </ApolloProvider>
    );
  };
};
