import styled from 'styled-components';
import theme from 'fabric/theme';

export const AsContainerDiv = styled.div`
  padding: ${({ isSupplier }) => (isSupplier ? 0 : theme.spacing.s2)} ${
  theme.spacing.s1
};
  .ask_supplier_button {
    ${theme.fonts.xTiny};
  }
  .holds {
    &__aac {
      &--btn {
        margin: 0;
      }
    }
    &__section {
      display: flex;
      gap: 6px;
      flex-direction: column;
    }
    &__action {
      &--request ,
      &--release {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--release ,
      &--reject {
        &,
        &:active,
        &:focus,
        &:focus-visible,
        &:disabled,
        &:hover {
          color: ${theme.palette.red};
          border-color: ${theme.palette.red};
        }
      }
      &--request {
        &,
        &:disabled,
        &:active,
        &:focus,
        &:focus-visible,
        &:hover {
          color: ${theme.palette.themePrimary};
        }
      }
    }
    &__actions {
      display: flex;
      gap: 6px;
      align-items: center;

      button {
        margin: 0;
        height: 36px;
        &.article__add_button_grey {
          scale(1);
          background: ${theme.palette.gray} !important;
          &:hover {
            box-shadow: 0 3px 1px rgb(51 51 51 / 10%),
              0 4px 8px rgb(51 51 51 / 10%);
            transform: scale(1.02);
            transition: transform,box-shadow 0.5s ease-out;
          }
        }
      }
    }
    &__card {
      &_container {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(3, minmax(375px, 1fr));
        gap: ${theme.spacing.s3};
        margin: ${theme.spacing.s2};

        .aws_wrapper {
          width: 44px !important;
          height: 44px !important;
        }
        .gia-overlap,
        .gia-modal-content {
          z-index: 12;
        }
      }
    }
    &__content {
      margin-top: ${theme.spacing.s3};
      .no-diamonds-match {
        box-shadow: none;
      }
      &--supplier {
        margin-top:0;
      }
      .skeleton:empty:after {
        height: 12px;
      }
      .skeleton:not(.checkbox):nth-child(even):empty:after {
        width: 35px !important;
      }
      .skeleton:not(.checkbox):nth-child(odd):empty:after {
        width: 40px !important;
      }
      .skeleton.skeleton__body:not(.checkbox):nth-child(odd):empty:after {
        width: 200px !important;
      }
      .table__header {
        z-index: 1;
        .table__col{
          &:last-child {
            min-width: fit-content;
          }
          &.skeleton:last-child:empty:after {
            width: 0 !important;
          }
        }
      }
      .table__row {
        gap: ${theme.spacing.s1};
        .timer__tooltip {
          align-items: center;
          gap: ${theme.spacing.px};
        }
      }
    // Mobile css 
      .mc {
        &__action {
          display: block;
          .holds__actions {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            margin-top: ${theme.spacing.s4};
          }
        }
        &__header {
          ${theme.fonts.medium};
          @media (max-width: 700px) {
            ${theme.fonts.small};
          }
        }
      }
      .empty__spacer-y {
        height: 290px !important;
      }
    }
    &__filter {
      height: 34px;
      justify-content: space-between;
      &,
      &--filter-by {
        display: flex;
        align-items: center;
      }
      gap: ${theme.spacing.s4};
      &--filter-by {
        .block_heading {
          ${theme.fonts.mediumBold};
          @media (max-width: 700px) {
            ${theme.fonts.smallBold};
          }
        }
        gap: ${theme.spacing.s3};
      }
      &--select {
        height: 30px;
      }
      .result-text {
        margin-right: ${theme.spacing.s2};
      }
    }

    &__item {
      &--image {
        width: 40px;
        position: relative;
        .aws_wrapper {
          width: 40px !important;
          height: 40px !important;
        }
      }
      &--reason {
        display: flex;
        align-items: center;
      }
      &--seller {
        @media (max-width: 700px) {
          display: flex;
          gap: ${theme.spacing.s2};
        }
      }
    }
    
    &__header_container {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.s6};
    }
    
    &__enquiry_container {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.s1};
    }

    &__page {
      &--active,
      &--inactive,
      &--rejected,
      &--pending {
        // Mobile card styles
        .mc__multi_action .holds__actions,
        .mc__action .holds__actions {
          display: flex;

          button {
            width: 100%;
          }
        }
        .mc__multi_action .holds__actions {
          .holds__aac {
            width: 100%;
            overflow-x: hidden;
          }
        }
      }
     
      .mc__multi_action {
        background: ${theme.palette.white};
      }
    }

    &__switch {
      &_container {
        display: flex;
        align-items: center;
      }
      &-label {
        color: ${theme.semanticColors.placeholderText};
        @media (max-width: 700px) {
          ${theme.fonts.tiny};
        }
      }
      &--title {
        font-weight: 500;
        @media (max-width: 700px) {
        ${theme.fonts.small};
          margin-right: ${theme.spacing.s1};
        }
      }
    }
  }

  @media (max-width: 700px) {
    padding: ${theme.spacing.s1};

    .holds {
      &__content {
        margin-top: ${theme.spacing.s2};
      }
      &__page {
        margin-top: ${theme.spacing.s3};
      }
      &__summary {
        .summary__container {
          grid-template-columns: unset;
        }
      }
    }
    
    .tooltip__content {
      width: 150px;
    }
  }
`;

export { AsDiv } from './holdsInputModalStyles';
export { IconDiv } from './iconStyles';
