import { gql } from '@apollo/client';

export const CompanySettingsFragment = gql`
  fragment CompanySettingsFragment on CompanySettings {
    CompanyId
    holds_enabled
    supersaver_enabled
    hk_only_delivery
    in_with_charges_delivery
    website_order_placement
    accept_returns
    return_days
    accept_holds
    auto_fetch_feeds
    hold_hours
    upload_allowed
    blocked_reason
    blocked_at
    final_price
    customer_order_number_req
    communicates_through_email
    communication_email
    in_delivery_upto
    in_delivery_upto_amount
    cfm_onboarding
    hide_customer_names
    action_other_user_hold
    supplier_based_comment
    self_serve_po
    display_supplier_name
    accept_qc_returns
    supplier_qc_comment
    nivoda_express_supplier
    supplier_return_method
    customer_return_days
    self_serve_holds
    max_allowed_returns
    is_mor_enabled
    is_supplier_details_enabled
    supplier_invoicing_enabled
    memo_enabled
    memo_supplier
    memo_stock_outside_express
    memo_duration
    supplier_auto_return_invoicing
    supplier_auto_return_email
    fedex_account_number
    address_kyc_enabled
  }
`;
