import React from 'react';
import { AsDiv } from './diamond_badge_style';
import { Tooltip, Icon } from '@nivoda/ui';
import { useTranslation } from 'react-i18next';
import theme from 'fabric/theme';
import { useReactiveVar } from '@apollo/client';
import { dashboardModeStateRx } from 'graphql/factory/reactiveVar';

export let DiamondBadge = ({
  is_new = false,
  width = 30,
  height = 30,
  tooltip_width = 160,
  with_tooltip = true,
  is_labgrown,
  is_hpht,
  eligible_for_free_delivery = false,
  top = '2',
  left = '15',
  list_icon = false,
  tooltop_postion = 'bottomLeft',
  view = 'GRID',
  fontSize = '12px',
}) => {
  let { t } = useTranslation();
  const { isMemoMode } = useReactiveVar(dashboardModeStateRx);

  if (!is_labgrown && !is_hpht && !eligible_for_free_delivery) return null;
  let info = () => {
    if (is_labgrown) {
      return {
        tooltip_text: t('Labgrown_diamonds'),
        renderLabDiv: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                padding: !list_icon ? '4px 6px' : '2px 2px 0',
                borderRadius: '4px',
                fontWeight: 400,
                fontSize: !list_icon ? '12px' : '8px',
                lineHeight: '12px',
                backgroundColor: theme.palette.yellow400,
              }}
            >
              {t('lab_short')}
            </div>
          );
        },
      };
    } else if (eligible_for_free_delivery) {
      return {
        tooltip_text: 'Free Delivery',
        src: list_icon
          ? '/img/ui-icons/shipping-label.svg'
          : '/img/ui-icons/shipping-label.svg',
        alt: 'Free Delivery',
        content: (
          <span
            className={`diamond__badge--text ${view}`}
            title={'Free Delivery'}
          >
            {view === 'GRID' ? (
              'Free Delivery'
            ) : (
              <>
                <Icon
                  className={'diamond__badge--icon'}
                  color="white"
                  size={20}
                  name={'coupons'}
                  hoverColor={'#fff'}
                  viewBox="0 0 16 12"
                  title={'Free Delivery'}
                />
                <span className={'diamond__badge--text-desc'}>Free</span>
              </>
            )}
          </span>
        ),
      };
    } else
      return {
        tooltip_text: 'High Pressure High Temperature',
        src: list_icon
          ? '/img/ui-icons/mobile-hpht.svg'
          : '/img/ui-icons/hpht_badge.svg',
        alt: 'hpht',
        renderHPHT: () => {
          return (
            <div
              className="natural_type_badge"
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                padding: '4px',
                borderRadius: '4px',
                fontWeight: 400,
                fontSize,
                backgroundColor: 'rgb(12, 10, 9)',
                color: '#FFFFFF',
              }}
            >
              {t('treated')}
            </div>
          );
        },
      };
  };

  const _info = info();
  if (!_info) return null;
  const tooltip =
    (with_tooltip && (
      <Tooltip
        radius={4}
        placement={tooltop_postion}
        content={
          <div style={{ width: tooltip_width }}>{_info?.tooltip_text}</div>
        }
        no_tooltip={is_hpht}
      >
        {' '}
        {is_labgrown ? (
          _info.renderLabDiv()
        ) : is_new || is_hpht ? (
          _info.renderHPHT()
        ) : (
          <img style={{ width: '100%' }} src={_info.src} alt={_info.alt} />
        )}
      </Tooltip>
    )) ||
    '';

  const modifiedLeft = view === 'LIST' && isMemoMode ? 7 : left;

  return (
    <AsDiv
      className={`diamond__badge ${
        (eligible_for_free_delivery && 'diamond__badge--free-delivery') || ''
      } ${view}`}
      top={top}
      left={modifiedLeft}
      width={width}
      height={height}
    >
      {!eligible_for_free_delivery ? (
        with_tooltip ? (
          tooltip
        ) : (
          <img style={{ width: '100%' }} src={_info.src} alt={_info.alt} />
        )
      ) : view === 'LIST' && with_tooltip ? (
        <Tooltip
          radius={4}
          placement={tooltop_postion}
          no_tooltip={is_hpht}
          content={
            <div
              className={'diamond__badge--tooltip-text'}
              style={{ width: tooltip_width }}
            >
              {_info?.tooltip_text}
            </div>
          }
        >
          {_info?.content || _info?.renderHPHT()}
        </Tooltip>
      ) : (
        _info?.content
      )}
    </AsDiv>
  );
};

export const DiamondBadgeDisplay = ({ product_type, Product }) => {
  let { t } = useTranslation();
  return (
    product_type === 'labgrown' && (
      <span
        className="bgmEC_label yellow"
        style={{ padding: '1px 2px', borderRadius: '2px' }}
      >
        {t('lab_short')}
      </span>
    )
  );
};
