import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';

export const ExchangeRateOnDate = AdminGraphqlQuery({
  query: gql`
    query ($currency: String, $date: String) {
      exchange_rate_on_date(currency: $currency, date: $date) {
        name
        to_USD
      }
    }
  `,
});

export const GET_EXCHANGE_RATES = gql`
  query getExchangeRates {
    all_exchange_rates {
      id
      rate
      date
    }
  }
`;
