import { useEffect } from 'react';
import { useFeatureFlags, useHubspotChatWidgetUpdates } from 'fabric/hooks';
import { useZendeskChatWidgetStore } from './useZendeskChatWidgetStore';
import { noop } from 'lodash';
import { featureFlagKey } from '../../constants';
import { Track } from 'fabric/integrations';

export const useZendeskFlag = () => {
  const rxCtx = useFeatureFlags();
  return rxCtx.featureFlags[featureFlagKey.allowZendeskWidget];
};

export const useToggleZendeskHubspot = () => {
  const [hubspotState, updateHubspotState] = useHubspotChatWidgetUpdates();
  const { setZendesk } = useZendeskChatWidgetStore();
  const showZendesk = useZendeskFlag();
  // const { data: zendesk_status, isLoading } = useQuery({
  //   queryKey: ['zendeskStatus'],
  //   queryFn: getZendeskStatus,
  // });

  const toggleSupportWidget = () => {
    if (showZendesk) Track.track('Zendesk_enabled');
    // !isLoading && zendesk_status?.data?.data.length === 0
    if (showZendesk) {
      setZendesk(true);
    } else {
      return updateHubspotState({
        ...hubspotState,
      });
    }
  };
  return [toggleSupportWidget];
};

export const Zendesk = ({ handleOnInitialLoad = () => noop }) => {
  const ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_KEY;

  const showZendesk = useZendeskFlag();
  useEffect(() => {
    if (showZendesk) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_API_KEY}`;
      script.addEventListener('load', handleOnInitialLoad());

      document.body.appendChild(script);

      return () => {
        document.getElementById('ze-snippet')?.remove();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showZendesk]);
  return null;
};

// export const useZendeskScript = () => {
//   const ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_KEY;
//   const showZendesk = useZendeskFlag();

//   useEffect(() => {
//     const script = document.createElement('script');
//     if (showZendesk) {
//       script.id = 'ze-snippet';
//       script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_API_KEY}`;
//       // script.addEventListener('load', handleOnInitialLoad());
//       document.body.appendChild(script);
//     }
//     return () => {
//       if (script && script.parentNode === document.body) {
//         document.body.removeChild(script);
//       }
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [showZendesk]);
// };
