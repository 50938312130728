import { useEffect, useState } from 'react';
import { SignupContainer } from '../../../Signup/SignupContainer';
import { ConfirmAddressWrapper } from '../styles';
import { useTranslation } from 'react-i18next';
import { AddressFinder } from '@ideal-postcodes/address-finder';
import { theme } from 'fabric/theme';
import { CustomTrans } from 'helpers';
import {
  CustomInput,
  CustomSelect,
  PrimaryButton,
} from 'Signup/components/CustomSignupComponents';
import { ALL_COUNTRIES, getRegionDataOnCountry } from './helper';
import { Track } from 'fabric/integrations';
import { SwitchV3 } from 'components/filter-drawer/switchV3';
import { useMutation } from '@apollo/client';
import { SET_ADDRESS } from 'graphql/mutations/common/user-onboarding';
import { graphqlClient } from 'graphql/factory';
import { useErrorWrapper } from 'fabric/hooks';
import ScrollToTop from 'layout/ScrollToTop';
import { GET_AUSTRALIAN_SUBURBS } from 'graphql/queries/common/buyer';

let POST_CODE_API =
  process.env.REACT_APP_POSTCODE_KEY || 'ak_lcsukimrONBC8SMPZZpiBpk7lpM5L';

export const ConfirmAddress = ({ onConfirm }) => {
  useEffect(() => {
    Track.track_page('Signup page -> Address page');
  }, []);
  const { t } = useTranslation();
  let session = localStorage.getItem('graphql_session');
  if (!session) {
    window.location.href = '/register';
  }

  const [confirm_address] = useMutation(SET_ADDRESS, {
    client: graphqlClient,
  });

  const [state, set_state] = useState({
    error: null,
    registered_address_country: session ? JSON.parse(session).user.country : '',
    registered_address_state: '',
    registered_address_city: '',
    registered_address_address1: '',
    registered_address_address2: '',
    registered_address_postal_code: '',
    registered_address_suburb: '',
    shipping_address_country: '',
    shipping_address_state: '',
    shipping_address_city: '',
    shipping_address_address1: '',
    shipping_address_address2: '',
    shipping_address_postal_code: '',
    shipping_address_suburb: '',
    is_different: false,
    tcs: false,
    user_id: session ? JSON.parse(session).user.id : '',
    role: session ? JSON.parse(session).user.role : '',
  });
  let {
    registered_address_country,
    registered_address_city,
    registered_address_state,
    registered_address_address1,
    registered_address_address2,
    registered_address_postal_code,
    shipping_address_country,
    shipping_address_city,
    shipping_address_state,
    shipping_address_address1,
    shipping_address_address2,
    shipping_address_postal_code,
    registered_address_suburb,
    shipping_address_suburb,
    is_different,
    user_id,
    role,
  } = state;

  const [fnWrapper, context] = useErrorWrapper({
    showSuccess: false,
    disableHelmet: true,
    useToastNotification: true,
    hideSuccess: true,
  });

  const onSubmit = async () => {
    await fnWrapper(
      async () => {
        context.setBusy({
          type: 'Processing',
          status: true,
        });
        Track.track('Address added', {
          country: registered_address_country,
        });
        let result = await confirm_address({
          variables: {
            user_registered_address: {
              name: 'Registered address',
              country: registered_address_country,
              city: registered_address_city,
              state: registered_address_state,
              address1: registered_address_address1,
              address2: registered_address_address2,
              postalCode: registered_address_postal_code?.trim(),
              suburb: registered_address_suburb,
            },
            user_shipping_address: {
              name: 'Shipping address',
              country: !is_different
                ? registered_address_country
                : shipping_address_country,
              city: !is_different
                ? registered_address_city
                : shipping_address_city,
              state: !is_different
                ? registered_address_state
                : shipping_address_state,
              address1: !is_different
                ? registered_address_address1
                : shipping_address_address1,
              address2: !is_different
                ? registered_address_address2
                : shipping_address_address2,
              postalCode: !is_different
                ? registered_address_postal_code?.trim()
                : shipping_address_postal_code?.trim(),
              suburb: !is_different
                ? registered_address_suburb
                : shipping_address_suburb,
            },
            same_address: !is_different,
            user_id: user_id,
          },
        });

        // Mixpanel
        Track.track({
          country: registered_address_country,
          city: registered_address_city,
          state: registered_address_state,
          postalCode: registered_address_postal_code,
        });
        Track.alias(result.data.set_address.user.id);
        Track.identify(result.data.set_address.user.id, {
          country: registered_address_country,
          city: registered_address_city,
          state: registered_address_state,
          postalCode: registered_address_postal_code,
          ...result.data.set_address.user,
        });
        Track.track('Onboarding: Address confirmed');

        await onConfirm({ session: result.data.set_address });
        context.setBusy({
          type: '',
          status: false,
        });
        //-- if user selects role as supplier then redirect him to supplier route --
        if (role === 'SUPPLIER') {
          window.location.href = '/suppliers/stock-info';
        } else {
          window.location.href = '/setup-completed';
        }
      },
      null,
      {
        error: {
          title: 'Error',
          messageResolver: (msg) => {
            Track.track('Address added error', {
              error: msg,
            });
            return t('something_wrong_contact_support');
          },
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem('signup_id');
    localStorage.removeItem('role');
    localStorage.removeItem('step');
  }, []);

  const set_value = (key, value) => {
    set_state({ ...state, [key]: value });
  };

  let is_registered_address_filled = Object.values({
    registered_address_country,
    registered_address_city,
    registered_address_state,
    registered_address_address1,
    registered_address_postal_code,
  }).every(Boolean);

  let is_shipping_address_filled = Object.values({
    shipping_address_country,
    shipping_address_city,
    shipping_address_state,
    shipping_address_address1,
    shipping_address_postal_code,
  }).every(Boolean);

  if (registered_address_country === 'AU') {
    if (!registered_address_suburb) {
      is_registered_address_filled = false;
    }
  }

  if (shipping_address_country === 'AU') {
    if (!shipping_address_suburb) {
      is_shipping_address_filled = false;
    }
  }

  let disabled = !(
    is_registered_address_filled &&
    (is_different ? is_shipping_address_filled : true)
  );

  return (
    <SignupContainer>
      <ScrollToTop />
      {context.responseJSX}
      <ConfirmAddressWrapper className="confirm_address_wrapper">
        <div className="confirm_address_wrapper--header">
          <span className="confirm_address_wrapper--header__title">
            <CustomTrans
              text="we_need_your_companys_registered_address"
              returnPlainText={true}
            />
          </span>
          <span className="confirm_address_wrapper--header__subtitle">
            <CustomTrans
              text="this_is_necessary_for_our_checks_and_also_to_ensure_we_ship_everything_to_the_correct_address"
              returnPlainText={true}
            />
          </span>
        </div>
        <AddressFormComponent
          set_value={set_value}
          country={registered_address_country}
          postcode={registered_address_postal_code}
          address1={registered_address_address1}
          address2={registered_address_address2}
          city={registered_address_city}
          province={registered_address_state}
          suburb={registered_address_suburb}
          set_state={set_state}
          is_shipping={false}
          mainState={state}
        />
        <AddressFormComponent
          set_value={set_value}
          country={shipping_address_country}
          postcode={shipping_address_postal_code}
          address1={shipping_address_address1}
          address2={shipping_address_address2}
          city={shipping_address_city}
          province={shipping_address_state}
          suburb={shipping_address_suburb}
          is_different={is_different}
          is_shipping={true}
          set_state={set_state}
          mainState={state}
        />
        <PrimaryButton
          btnText={'continue'}
          onClick={() => onSubmit()}
          disabled={disabled || context.busy.status}
          loading={context.busy.status}
        />
      </ConfirmAddressWrapper>
    </SignupContainer>
  );
};

const AddressFormComponent = ({
  country,
  address1,
  address2,
  city,
  postcode,
  province,
  is_different,
  set_value,
  is_shipping,
  set_state,
  mainState,
  suburb,
}) => {
  const [state, setState] = useState({
    hasPostCode: false,
    hasAddressLine1: false,
    hasCity: false,
    hasState: false,
    suburbs_shipping: [],
    suburbs: [],
  });

  const getSuburbsOption = () => {
    return is_shipping ? state.suburbs_shipping : state.suburbs;
  };
  const setAustralianSuburbsOptions = async () => {
    const data = await graphqlClient.query({
      query: GET_AUSTRALIAN_SUBURBS,
      variables: { postal_code: postcode },
    });

    const get_australian_suburbs =
      (data && data && data.data.get_australian_suburbs) || [];

    if (is_shipping) {
      setState({
        ...state,
        suburbs_shipping: get_australian_suburbs.map((suburb) => {
          return { label: suburb.name, value: suburb.name };
        }),
      });
    } else {
      setState({
        ...state,
        suburbs: get_australian_suburbs.map((suburb) => {
          return { label: suburb.name, value: suburb.name };
        }),
      });
    }
  };

  useEffect(() => {
    if (country === 'GB' && !is_shipping) {
      AddressFinder.setup({
        inputField: document.getElementById('search_address'), // Target <input> to host Address Finder
        apiKey: POST_CODE_API, // API Key from your account
        injectStyle: true,
        onAddressRetrieved: ({
          line_1,
          line_2,
          post_town,
          postcode,
          county,
        }) => {
          set_state({
            ...mainState,
            registered_address_address1: `${line_1}`,
            registered_address_address2: `${line_2}`,
            registered_address_city: post_town,
            registered_address_postal_code: postcode,
            registered_address_state: county,
          });
        },
        defaultCountry: 'GBR',
        detectCountry: false,
      });
    }
    // eslint-disable-next-line
  }, [country, is_shipping]);
  return (
    <div className="confirm_address_wrapper--form">
      <div className="confirm_address_wrapper--form__header">
        <span className="confirm_address_title">
          <CustomTrans
            text={is_shipping ? 'shipping_address' : 'registered_address'}
          />
        </span>
        {is_shipping && (
          <div className="toggle_switch">
            <SwitchV3
              wrapProps={{
                wrapWidth: '35px',
                activeBg: theme.palette.Neutrals950,
              }}
              thumbProps={{ thumbBg: theme.palette.gray50 }}
              active={is_different}
              item={{ value: is_different }}
              onChange={(value) => {
                set_value('is_different', !value);
              }}
            />
            <span className="switch_label">
              <CustomTrans
                text="use_different_shipping_address"
                returnPlainText={true}
              />
            </span>
          </div>
        )}
      </div>
      {(!is_shipping || (is_shipping && is_different)) && (
        <>
          <div className="confirm_address_wrapper--form__row1">
            <CustomSelect
              label={<CustomTrans text="country" returnPlainText={true} />}
              value={ALL_COUNTRIES.find((ele) => ele.value === country)}
              onChange={(option) => {
                set_value(
                  is_shipping
                    ? 'shipping_address_country'
                    : 'registered_address_country',
                  option.value
                );
              }}
              options={ALL_COUNTRIES}
              placeholder={'Select country'}
              isRequried={true}
              maxMenuHeight={200}
            />
            {country !== 'GB' && !is_shipping ? (
              <CustomInput
                label={
                  <CustomTrans
                    text={'zip_or_postal_Code'}
                    returnPlainText={true}
                  />
                }
                placeholder={''}
                onBlur={() => {
                  setState({
                    ...state,
                    hasPostCode: true,
                  });
                  if (country === 'AU') {
                    setAustralianSuburbsOptions();
                    set_value(
                      is_shipping
                        ? 'shipping_address_postal_code'
                        : 'registered_address_postal_code',
                      postcode
                    );
                  }
                }}
                onChange={(postcode) =>
                  set_value(
                    is_shipping
                      ? 'shipping_address_postal_code'
                      : 'registered_address_postal_code',
                    postcode
                  )
                }
                type={'text'}
                hasError={state.hasPostCode && postcode?.trim()?.length === 0}
                errorMessage={
                  is_shipping ? (
                    <CustomTrans
                      text={'shipping_address_postal_code_not_filled'}
                      returnPlainText={true}
                    />
                  ) : (
                    <CustomTrans
                      text={'registered_address_postal_code_not_filled'}
                      returnPlainText={true}
                    />
                  )
                }
                value={postcode}
                isRequired={true}
              />
            ) : (
              <CustomInput
                label={
                  <CustomTrans
                    text={'zip_or_postal_Code'}
                    returnPlainText={true}
                  />
                }
                onBlur={() => {
                  if (country === 'AU') {
                    setAustralianSuburbsOptions();
                    set_value(
                      is_shipping
                        ? 'shipping_address_suburb'
                        : 'registered_address_suburb',
                      ''
                    );
                  }
                }}
                onChange={(postcode) =>
                  set_value(
                    is_shipping
                      ? 'shipping_address_postal_code'
                      : 'registered_address_postal_code',
                    postcode
                  )
                }
                id="search_address"
                type={'text'}
                value={postcode}
                isRequired={true}
              />
            )}
          </div>
          <div className="confirm_address_wrapper--form__row1">
            <CustomInput
              label={
                <CustomTrans text={'address_line_1'} returnPlainText={true} />
              }
              placeholder={''}
              onBlur={() =>
                setState({
                  ...state,
                  hasAddressLine1: true,
                })
              }
              onChange={(address1) =>
                set_value(
                  is_shipping
                    ? 'shipping_address_address1'
                    : 'registered_address_address1',
                  address1
                )
              }
              hasError={state.hasAddressLine1 && address1?.trim()?.length === 0}
              errorMessage={
                is_shipping ? (
                  <CustomTrans
                    text={'shipping_address_address_line_not_filled'}
                    returnPlainText={true}
                  />
                ) : (
                  <CustomTrans
                    text={'registered_address_address_line_not_filled'}
                    returnPlainText={true}
                  />
                )
              }
              type={'text'}
              value={address1}
              isRequired={true}
            />
            <CustomInput
              label={
                <CustomTrans text={'address_line_2'} returnPlainText={true} />
              }
              placeholder={''}
              onChange={(address2) =>
                set_value(
                  is_shipping
                    ? 'shipping_address_address2'
                    : 'registered_address_address2',
                  address2
                )
              }
              type={'text'}
              value={address2}
              isRequired={false}
            />
          </div>
          <div className="confirm_address_wrapper--form__row1">
            <CustomInput
              label={
                <CustomTrans
                  text={country !== 'GB' ? 'city' : 'town'}
                  returnPlainText={true}
                />
              }
              placeholder={''}
              // placeholder={country !== 'GB' ? 'City' : 'Town'}
              onBlur={() =>
                setState({
                  ...state,
                  hasCity: true,
                })
              }
              onChange={(city) =>
                set_value(
                  is_shipping
                    ? 'shipping_address_city'
                    : 'registered_address_city',
                  city
                )
              }
              type={'text'}
              hasError={state.hasCity && city?.trim()?.length === 0}
              errorMessage={
                is_shipping ? (
                  <CustomTrans
                    text={'shipping_address_city_not_filled'}
                    returnPlainText={true}
                  />
                ) : (
                  <CustomTrans
                    text={'registered_address_city_not_filled'}
                    returnPlainText={true}
                  />
                )
              }
              value={city}
              isRequired={true}
            />
            {country !== 'GB' ? (
              <CustomSelect
                label={
                  <CustomTrans
                    text="state_or_province"
                    returnPlainText={true}
                  />
                }
                onChange={(option) => {
                  set_value(
                    is_shipping
                      ? 'shipping_address_state'
                      : 'registered_address_state',
                    option.label
                  );
                }}
                options={
                  country
                    ? getRegionDataOnCountry(country)
                    : [{ label: '', value: '' }]
                }
                placeholder={'Select state'}
                isRequried={true}
                maxMenuHeight={130}
                data_automation_id="select-address-state"
              />
            ) : (
              <CustomInput
                label={<CustomTrans text={'county'} returnPlainText={true} />}
                placeholder={''}
                onBlur={() =>
                  setState({
                    ...state,
                    hasCity: true,
                  })
                }
                onChange={(state) =>
                  set_value(
                    is_shipping
                      ? 'shipping_address_state'
                      : 'registered_address_state',
                    state
                  )
                }
                type={'text'}
                hasError={state.hasCity && city?.trim()?.length === 0}
                errorMessage={
                  is_shipping ? (
                    <CustomTrans
                      text={'shipping_address_state_not_filled'}
                      returnPlainText={true}
                    />
                  ) : (
                    <CustomTrans
                      text={'registered_address_state_not_filled'}
                      returnPlainText={true}
                    />
                  )
                }
                value={province}
                isRequired={true}
              />
            )}
          </div>

          <div className="confirm_address_wrapper--form__row1">
            {country === 'AU' && (
              <CustomSelect
                label={<CustomTrans text={'suburb'} returnPlainText={true} />}
                onChange={(option) => {
                  set_value(
                    is_shipping
                      ? 'shipping_address_suburb'
                      : 'registered_address_suburb',
                    option.label
                  );
                }}
                options={getSuburbsOption()}
                placeholder={
                  <CustomTrans text={'suburb'} returnPlainText={true} />
                }
                value={getSuburbsOption().filter((suburbOption) => {
                  return suburbOption.label === suburb;
                })}
                error={
                  <CustomTrans
                    text={'suburb_not_selected'}
                    returnPlainText={true}
                  />
                }
                isRequried={true}
                maxMenuHeight={200}
                rightIconProps={{}}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
