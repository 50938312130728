import React from 'react';
import { successTypes } from '../../constants';
import { css } from 'styled-components';
import { globalStyleFabric } from '../styles';
import { GenericModal } from '../components';
import { bool } from 'prop-types';
import { toast } from 'react-hot-toast';
import { noop } from 'lodash/functions';

const bodyStyle = css`
  ${globalStyleFabric};
`;
const defaultResponse = {
  bodyJSX: null,
  iconProps: {},
  data: null,
  message: '',
  status: false,
  title: '',
  hasCloseIcon: true,
  type: '',
};

/**
 * Wraps the function call inside a try-catch to handle any runtime errors and
 *  shows the response via modal 💪🏻
 * @param disableHelmet {disableHelmet: boolean} Flag to disable browser tab titles
 * @param showSuccess {showSuccess: boolean} Flag to enable success message modal on function completion
 * @returns {[((function(*, *[]=, *=): Promise<*>)|*),{responseJSX: JSX.Element, responseType: string, setBusy: (value: (((prevState: {type: string, status: boolean}) => {type: string, status: boolean}) | {type: string, status: boolean})) => void, data: null, busy: {type: string<'SUCCESS'|'ERROR'|'INFO'>, status: boolean}, closeErrorModal: (function(): Promise<void>)}]}
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 * @example
 * function PlayLottery(props) {
 *   const [fnWrapper, context] = useErrorWrapper({ showSuccess: true });
 *
 *   const handleClick = async () => {
 *     await fnWrapper(
 *       () => {
 *         if (Math.random() < 0.5) {
 *           throw Error('Something went wrong!!');
 *         }
 *       },
 *       null,
 *       {
 *         error: {
 *           title: 'You lost',
 *           messageResolver: (msg) => msg.includes('failed') ? 'Try again, and you might win :)' : 'Not sure what happened',
 *           jsx: <div>Cool!!</div>,
 *         },
 *         // Optional
 *         success: {
 *           title: 'You win',
 *           messageResolver: (results) =>  results ? 'This was a lifetime achievement :O' : 'It went through',
 *           jsx: <div>On the rock!!</div>,
 *         },
 *       }
 *     );
 *   };
 *
 *   return (
 *     <>
 *       <Button onClick={handleClick}>Add stones</Button>
 *       {context.responseJSX}
 *     </>
 *   );
 * }
 */
export const useErrorWrapper = (
  {
    showSuccess = false,
    disableHelmet = false,
    onCloseModal = noop,
    useToastNotification = false,
    toastNotificationProps = {},
    hideSuccess = false,
  } = {
    showSuccess: false,
    disableHelmet: false,
    onCloseModal: noop,
    useToastNotification: false,
    toastNotificationProps: {},
    hideSuccess: false,
  }
) => {
  const [busy, setBusy] = React.useState({ status: false, type: '' });
  const [response, setResponse] = React.useState(defaultResponse);

  /**
   * Error wrapper function for callee
   * @param unsafeFnCall callee function to be wrapped
   * @param args {[*]} array of arguments for callee function
   * @param message {error: {title: string, messageResolver: function(errMessage),jsx: JSX.Element}, success: {title: string, messageResolver: function(errMessage),jsx: JSX.Element}} Provide custom title, message, messages body for success and error
   * @returns {Promise<*>}
   */
  const safeFunctionWrapper = async (
    unsafeFnCall,
    args = [],
    message = { error: {}, success: {} },
    options = {
      useModalForError: false,
    },
    callbacks = {
      errorCB: () => {},
    }
  ) => {
    let useModalForError = options?.useModalForError ?? false;
    const { error, success } = {
      error: {
        title: 'Error',
        messageResolver: (msg = '') => {
          const err = msg.split('@');
          return err.length > 0 ? err[0] : msg || 'Something went wrong';
        },
        jsx: null,
        ...message.error,
      },
      success: {
        title: 'Success',
        messageResolver: (res = '') => ' ',
        jsx: null,
        ...message.success,
      },
    };

    try {
      const results =
        args && Array.isArray(args) && args.length > 0
          ? await unsafeFnCall(...args)
          : await unsafeFnCall();
      let message =
        typeof results?.messageResolver === 'function'
          ? results.messageResolver(results)
          : success.messageResolver(results);

      if (showSuccess) {
        // noinspection JSCheckFunctionSignatures
        await setResponse({
          bodyJSX:
            results?.title === void 0 ? success.jsx : results?.jsx || null,
          data: results?.data,
          iconProps:
            results?.iconProps === void 0
              ? success.iconProps
              : results?.iconProps,
          message: message,
          status: results?.status === void 0 ? true : results?.status,
          type:
            results?.responseType === void 0
              ? successTypes.SUCCESS
              : results?.responseType,
          title: results?.title === void 0 ? success.title : results?.title,
        });
      }

      if (useToastNotification && !hideSuccess) {
        toast.success(message, {
          style: { flexWrap: 'unset' },
          ...toastNotificationProps,
        });
      }
      return results;
    } catch (e) {
      await setBusy({ ...busy, status: false });

      const errorMessage = e?.response?.data?.statusText || e?.message;

      if (useToastNotification && !useModalForError) {
        toast.error(error.messageResolver(errorMessage), {
          style: { flexWrap: 'nowrap' },
          ...toastNotificationProps,
        });
      } else {
        await setResponse({
          ...response,
          bodyJSX: error.jsx,
          status: true,
          iconProps: error.iconProps || {},
          type: error.type || successTypes.ERROR,
          title: error.title,
          message: error.messageResolver(errorMessage),
          hasCloseIcon: error.hasCloseIcon,
        });
      }
      // eslint-disable-next-line no-console
      console.warn('Error caught in useErrorWrapper hook, ', e);

      // execute callback for error scenario
      callbacks?.errorCB();
    }
  };

  const handleCloseResponseModal = async () => {
    await setResponse(defaultResponse);
    if (typeof onCloseModal === 'function') {
      await onCloseModal(response);
    }
  };

  const isSuccess = response.type === successTypes.SUCCESS;

  const responseJSX = response.status ? (
    <GenericModal
      iconProps={response.iconProps}
      bodyStyle={bodyStyle}
      clearModalState={handleCloseResponseModal}
      errorType={response.type.toLowerCase()}
      popupStyle={{ minWidth: 600, marginTop: 0 }}
      hasCloseIcon={response.hasCloseIcon}
      modalTitle={response.title}
      disableHelmet={disableHelmet}
      headingText={response.title}
      renderModalBody={() =>
        response.bodyJSX ? (
          <div className="message_container">{response.bodyJSX}</div>
        ) : (
          <div className="message_container" />
        )
      }
      errorMessage={!isSuccess ? response.message : ''}
      successMessage={isSuccess ? response.message : ''}
    />
  ) : null;

  return [
    safeFunctionWrapper,
    {
      busy,
      closeErrorModal: handleCloseResponseModal,
      data: response.data,
      responseJSX,
      setBusy,
      responseType: response.type,
    },
  ];
};

useErrorWrapper.propTypes = {
  //Flag to enable success message modal on function completion
  showSuccess: bool,
};
