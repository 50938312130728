import theme from 'fabric/theme';
import styled from 'styled-components';
import { isMobile } from 'utils';

export const BGMSelectionContainer = styled.div`
  .wrapper__selection-area__values.supplier div {
    box-shadow: 0 0 0 2px rgba(255, 122, 0, 0.5);
  }
  .wrapper__header-area__tinge-wrp.hide-radio-button > div > div > div {
    display: none;
  }
  .wrapper__selection-area__values.hide-radio-button > div > div {
    display: none;
  }
  .supplier {
    box-shadow: 0 0 0 2px rgba(255, 122, 0, 0.5);
    z-index: 5;
  }
  & .radio__wrapper {
    border: 1px solid ${theme.palette.offWhite2};
    border-radius: 4px;
    height: 42px;
    min-width: 96px;
    justify-content: center;
    padding: 0 0.5rem 0 0.5rem;
    &.active {
      background: ${theme.palette.blue1};
      ${(props) =>
        props.locked &&
        (props.status === 'QC_MISMATCH'
          ? `background: ${theme.semanticColors.labelWarnBG};`
          : props.status === 'QC_PASSED'
          ? `background: ${theme.semanticColors.labelSuccessBG};`
          : props.status === 'QC_REJECTED'
          ? `background: ${theme.semanticColors.labelErrorBG};`
          : `background: ${theme.palette.blue1};`)}
      border: 1px solid #adbad7;
    }
  }
  & .label-circle {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.brown {
      background: #d9a686;
    }
    &.blue {
      background: #90c4f4;
    }
    &.green {
      background: #7fe6a2;
    }
    &.gray {
      background: #a6a19e;
    }
    &.milky {
      background: #f2f2f2;
    }
  }
  & .wrapper__ {
    &tinge_selection {
      display: grid;
      gap: 15px;
      border-radius: 4px;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    &label {
      ${theme.fonts.mediumPlus};
      color: ${theme.palette.offBlack4};
    }
    &header-area {
      margin-bottom: ${theme.spacing.s5};
      margin-top: ${theme.spacing.s1};
      &__tinge-wrp {
        display: ${isMobile() ? 'grid' : 'flex'};
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: ${isMobile() ? theme.spacing.s3 : theme.spacing.s4};
      }
      &__ec-wrp {
        display: ${isMobile() ? 'grid' : 'flex'};
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: ${isMobile() ? theme.spacing.s3 : theme.spacing.s4};
      }
      &__sub-label {
        ${theme.fonts.mediumPlus};
        color: ${theme.palette.offBlack2};
        margin: ${theme.spacing.s1} 0;
      }
      &__upper-tinge {
        display: flex;
      }
    }
    &no-tinge {
      display: flex;
      border: 1px solid ${theme.palette.offWhite2};
      padding: 10px;
      justify-content: center;
      color: ${theme.palette.gray400};
      margin-bottom: ${theme.spacing.s4};
      background: ${theme.palette.white0};
      ${theme.fonts.mediumPlus};
    }
    &selection-area {
      display: grid;
      grid-template-columns: 44px auto auto auto;
      align-items: center;
      margin-bottom: ${theme.spacing.s4};
      &__label {
        border: 1px solid ${theme.palette.offWhite2};
        border-radius: 4px;
        padding: 11px 12px;
        height: 42px;
        column-gap: ${theme.spacing.s3};
        &--text {
          ${theme.fonts.mediumPlus};
          color: ${theme.palette.offBlack4};
          margin-top: ${theme.spacing.s1};
        }
      }
      &__values {
        align-items: center;
        max-width: 250px;
      }
    }
  }
`;

export const SelectionRadioStyle = {
  ...theme.fonts.mediumPlus,
  marginBottom: '-1px',
  color: theme.palette.offBlack4,
};
