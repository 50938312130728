import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';

export const AddCompanyMarkup = GraphqlMutation({
  query: gql`
    mutation ($input_markups: [MarkupInput!]!) {
      add_markups(input_markups: $input_markups) {
        id
        from_amount
        to_amount
        percent
      }
    }
  `,
});

export const UpdateCompanyMarkup = GraphqlMutation({
  query: gql`
    mutation ($markups: [UpdateMarkupInput!]!) {
      update_markups(markups: $markups) {
        from_amount
        to_amount
        percent
      }
    }
  `,
});

export const DeleteCompanyMarkup = GraphqlMutation({
  query: gql`
    mutation ($markup_ids: [ID!]!) {
      delete_markups(markup_ids: $markup_ids)
    }
  `,
});

export const ADD_COMPANY_MARKUP = gql`
  mutation ($input_markups: [MarkupInput!]!) {
    add_markups(input_markups: $input_markups) {
      id
      from_amount
      to_amount
      percent
    }
  }
`;

export const UPDATE_COMPANY_MARKUP = gql`
  mutation ($markups: [UpdateMarkupInput!]!) {
    update_markups(markups: $markups) {
      from_amount
      to_amount
      percent
    }
  }
`;

export const DELETE_COMPANY_MARKUP = gql`
  mutation ($markup_ids: [ID!]!) {
    delete_markups(markup_ids: $markup_ids)
  }
`;

export const SAVE_MARKUP_SETTINGS = gql`
  mutation ($markup_preference: MarkupPreference, $markup_currency: String) {
    save_cfm_settings(
      markup_preference: $markup_preference
      markup_currency: $markup_currency
    ) {
      markup_currency
      markup_preference
    }
  }
`;
