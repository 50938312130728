import React from 'react';
import { bool } from 'prop-types';
import { BtnWrapperV2 } from './styles';
import { Icon } from 'fabric/resources/icon';
import { Tooltip } from '@nivoda/ui';
import { CustomTrans } from 'helpers';

AvailabilityButton.propTypes = {
  isListView: bool,
  noHover: bool,
};
export function AvailabilityButton(props) {
  return (
    <BtnWrapperV2 noHover={props.noHover}>
      <Tooltip
        radius={4}
        placement={'bottom'}
        content={
          <div style={{ width: 150, textAlign: 'center' }}>
            <CustomTrans text="on_hold" returnPlainText={true} />
          </div>
        }
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Icon
          aria-hidden="true"
          style={!props.isListView ? { marginTop: '-5px' } : {}}
          // className={'holds_cta__icon'}
          color={'#424243'}
          name={'hand_off'}
          width={20}
          fill_type="stroke"
          height={25}
        />
      </Tooltip>
    </BtnWrapperV2>
  );
}
