import { useReactiveVar } from '@apollo/client';
import { hubspotStateVar } from 'graphql/factory';
import { config } from 'fabric/config';
import { appEnv } from 'utils';
import { toggleHubspotChat } from 'helpers';

/**
 * Hook to store and update hubspot chat widget state
 * @returns widget state and update fx
 */
export const useHubspotChatWidgetUpdates = () => {
  const hubspotState = useReactiveVar(hubspotStateVar);
  const updateHubspotState = (settings) => {
    const updated_value = {
      ...hubspotState,
      ...settings,
    };
    if (appEnv.isProd || config.integration.intercomSettings.enableOnDevENV) {
      hubspotStateVar(updated_value);
      toggleHubspotChat(updated_value);
    }
  };
  return [hubspotState, updateHubspotState];
};
