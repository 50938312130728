import React from 'react';
import styled from 'styled-components';
import theme from 'fabric/theme';

const CardBadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  .pdp_badge_button {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 4px 10px;
    border-radius: 4px;
    .badge_label {
      font-weight: 700;
      font-size: 12px;
    }
    .badge-label-white {
      color: white;
    }
  }
`;

const RiskCollectionBadge = ({ text }) => {
  return (
    <CardBadgesWrapper>
      <div
        className="pdp_badge_button"
        style={{
          backgroundColor: theme.palette.red2,
        }}
      >
        <span className="badge_label">{text}</span>
      </div>
    </CardBadgesWrapper>
  );
};

export default RiskCollectionBadge;
