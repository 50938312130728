import { gql, useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';

// noinspection GraphQLUnresolvedReference
export const GET_APP_SESSION = gql`
  query GetAppSession($input: SessionInput) {
    appSession @client(input: $input)
  }
`;

// noinspection JSClosureCompilerSyntax
/**
 * App Session Query
 * @returns {{subscribeToMore<TSubscriptionData=any, TSubscriptionVariables={input: {pathname: unknown}} extends OperationVariables>(options: SubscribeToMoreOptions<any, TSubscriptionVariables, TSubscriptionData>): () => void, variables: {input: {pathname: unknown}} | undefined, data: any, called: boolean, startPolling(pollInterval: number): void, networkStatus: NetworkStatus, refetch(variables?: Partial<{input: {pathname: unknown}}>): Promise<ApolloQueryResult<any>>, reobserve(newOptions?: Partial<WatchQueryOptions<{input: {pathname: unknown}}, any>>, newNetworkStatus?: NetworkStatus): Promise<ApolloQueryResult<any>>, error?: ApolloError, loading: boolean, updateQuery<TVars={input: {pathname: unknown}} extends OperationVariables>(mapFn: (previousQueryResult: any, options: Pick<WatchQueryOptions<TVars, any>, "variables">) => any): void, fetchMore<TFetchData=any, TFetchVars={input: {pathname: unknown}} extends OperationVariables>(fetchMoreOptions: (FetchMoreQueryOptions<TFetchVars, TFetchData> & {updateQuery?: (previousQueryResult: any, options: {fetchMoreResult: TFetchData, variables: TFetchVars}) => any})): Promise<ApolloQueryResult<TFetchData>>, stopPolling(): void, observable: ObservableQuery<any, {input: {pathname: unknown}}>, client: ApolloClient<any>, location: unknown, previousData?: any}}
 * @param props
 */
export const useAppSession = (props = {}) => {
  const { variables = { input: {} }, ...rest } = props;
  const location = useLocation();
  const res = useQuery(GET_APP_SESSION, {
    variables: {
      ...variables,
      input: { pathname: location.pathname, ...variables.input },
    },
    ...rest,
  });

  return {
    ...res,
    location,
  };
};
