import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { darken, lighten } from 'polished';
import styled from 'styled-components';
// import { DEFAULT_FONTS } from 'utils';
import { theme } from 'fabric/theme';

const propTypes = {
  animateSeg: PropTypes.bool,
  size: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  chartSegments: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  chartTotal: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
  }),
  showSegPercentage: PropTypes.bool,
  showSegLabel: PropTypes.bool,
  thickness: PropTypes.number.isRequired,
  startAngle: PropTypes.number,
  className: PropTypes.string,
  circleProps: PropTypes.object,
  textProps: PropTypes.shape({
    // styling
    style: PropTypes.shape({
      // text box border radius
      borderRadius: PropTypes.string,
      // text color
      color: PropTypes.string,
      // background color for text box
      textBoxBackground: PropTypes.string,
    }),
  }),
};
const defaultProps = {
  size: 160,
  radius: 60,
  chartSegments: [],
  showSegLabel: false,
  showSegPercentage: false,
  thickness: 30,
  startAngle: -90,
  className: '',
  circleProps: {},
  textProps: {},
};

/**
 * Draws chart for given color, value pairs
 * @constructor
 * @param {string} animateSeg - animate the segments
 * @param {string} chardId - unique chart id
 * @param {string} className - root className for custom class name.
 * @param {boolean} loadingData - loading data state.
 * @param {object} chartTotal - show chart summary.
 * @param {string} defaultColor - default color code if percent/value is 0.
 * @param {object} circleProps - valid svg circle tag attributes.
 * @param {number} radius - radius of circle for the chart in number
 * @param {[{ id: string, color:string, label:string, value:number }]} chartSegments - array of
 *        [{ id:$id, color: $color, value: $value}], where each segment represent % of chart
 * @param {boolean} showSegLabel - to show or hide the label (default)
 * @param {boolean} showSegPercentage - to show or hide the percentage (default)
 * @param {number} size - Size of the chart
 * @param {number} startAngle - start angel for every segment
 * @param {number} thickness - thickness of chart circle
 * @param {object} textProps - valid svg text tag attributes
 * @return {React.ReactElement} - CustomChart
 */
export function CustomChart({
  animateSeg,
  chardId,
  className,
  loadingData,
  circleProps,
  chartSegments,
  chartTotal,
  defaultColor = '#f1f1f1',
  radius,
  showSegLabel,
  showSegPercentage,
  size,
  startAngle,
  textProps,
  thickness,
}) {
  //
  // Component State
  // ----------------------------------------------------------------------
  const [segments, setSegments] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [toggledLabel, setToggleLabel] = React.useState({ id: '', value: '' });

  //
  // Component props and local variables
  // ----------------------------------------------------------------------
  const { style, ...restTextProps } = textProps ? textProps : { style: {} };
  const textStyle = style ? style : {};

  const timeoutRef = React.useRef(null);
  const dummyFunc = () => {};

  // total value for drawing the circle
  const total = getTotal(chartSegments);

  /**
   * getTextCoordinates: gets the text coordinates for each segment
   */
  const getTextCoordinates = useCallback(
    (value, angleOffset) => {
      const angle = (getPercent(value, total) * 360) / 2 + angleOffset;
      const radians = convertDegreesToRadians(angle);

      return {
        x: radius * Math.cos(radians) + size / 2,
        y: radius * Math.sin(radians) + size / 2,
      };
    },
    [radius, size, total]
  );
  /**
   * getTransforms: gets the cal values for rotations and text coordinates
   */
  const getTransforms = useCallback(() => {
    const rotations = [];
    const textCoords = [];
    let rotateAngle = startAngle;

    sortValues(chartSegments).forEach(({ value }) => {
      const data = rotateAngle;
      const percent = getPercent(value, total);
      const { x, y } = getTextCoordinates(value, rotateAngle);

      rotations.push(data);
      textCoords.push({ x, y });

      const result = rotations[rotations.length - 1] || startAngle;

      rotateAngle = percent * 360 + result;
    });

    return { rotations, textCoords };
  }, [chartSegments, getTextCoordinates, startAngle, total]);

  const getStrokeDashOffset = (value, circumference) => {
    const diff = getPercent(value, total) * circumference;
    return circumference - diff;
  };
  /**
   * hideLabel: reset the values and removes the text box width to hide it from FE
   */
  const hideLabel = async (id = '') => {
    await setToggleLabel({ id: '', value: '' });

    if (typeof id === 'string') {
      const svg_context = document.getElementById(`svg_${chardId || ''}`);

      if (svg_context) {
        const rect_element = svg_context.getElementById(`svg_rect__${id}`);

        if (rect_element) {
          rect_element.setAttribute('width', '0');
          rect_element.setAttribute('height', '0');
          rect_element.setAttribute('fill', 'transparent');
        }
      }
    }
  };
  /**
   * showLabel: sets the state and width of the text box
   */
  const showLabel = async (id, e) => {
    const first_arg_string = typeof id === 'string';
    const target = first_arg_string ? e.target : id.target;
    const {
      dataset: { segValue, segId },
    } = target;

    await setToggleLabel({ id: segId, value: segValue });

    if (first_arg_string) {
      const svg_context = document.getElementById(`svg_${chardId || ''}`);

      if (svg_context) {
        const text_element = svg_context.getElementById(`svg_text__${id}`);
        const rect_element = svg_context.getElementById(`svg_rect__${id}`);
        const SVGRect = text_element && text_element.getBBox();

        if (rect_element && SVGRect) {
          rect_element.setAttribute('x', SVGRect.x - 3 + '');
          rect_element.setAttribute('y', SVGRect.y + '');
          rect_element.setAttribute('width', SVGRect.width + 6 + '');
          rect_element.setAttribute('height', SVGRect.height + '');
          rect_element.setAttribute(
            'fill',
            textStyle.textBoxBackground || '#2C2C2C'
          );
        }
      }
    }
  };

  // did mount hook
  React.useEffect(() => {
    const { rotations, textCoords } = getTransforms();

    setSegments(
      sortValues(chartSegments).map(({ color, id, label, value }, i) => ({
        color,
        id,
        label,
        percent: getPercent(value, total),
        rotate: `rotate(${rotations[i]}, ${size / 2}, ${size / 2})`,
        textCoords: textCoords[i],
        value,
      }))
    );

    // delay for showing seg %
    timeoutRef.current = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [chartSegments, getTransforms, size, total]);

  // give circle size half of required size
  const halfSize = size / 2;
  const circumference = getCircumference(radius);

  const getCircleCoordinate = (seg_id) =>
    animateSeg && toggledLabel.id === seg_id ? halfSize + 0.6 : halfSize;
  const getCircleRingWidth = (seg_id) =>
    animateSeg && toggledLabel.id === seg_id ? thickness + 3 : thickness;

  // disable all the handlers if data is loading
  const data_loading = !loadingData && showSegLabel;
  return (
    <AsDiv
      className={`custom_chart ${(isLoaded && 'custom_chart--loaded') || ' '} ${
        className || ''
      }`}
      showSegLabel={showSegLabel}
    >
      <svg
        id={`svg_${chardId || ''}`}
        className="custom_chart__svg"
        height={size}
        width={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        {segments.map((segment, i) => (
          <g key={i}>
            <circle
              {...circleProps}
              r={radius}
              id={`svg_circle__${segment.id + (chardId || '')}`}
              cx={getCircleCoordinate(segment.id)}
              cy={getCircleCoordinate(segment.id)}
              data-seg-id={segment.id}
              data-seg-value={segment.label}
              transform={segment.rotate}
              stroke={segment.percent > 0 ? segment.color : defaultColor}
              strokeWidth={getCircleRingWidth(segment.id)}
              strokeDasharray={circumference}
              strokeDashoffset={getStrokeDashOffset(
                segment.value,
                circumference
              )}
              onMouseEnter={
                data_loading
                  ? showLabel.bind(null, `${segment.id + (chardId || '')}`)
                  : dummyFunc
              }
              onMouseLeave={
                data_loading
                  ? hideLabel.bind(null, `${segment.id + (chardId || '')}`)
                  : dummyFunc
              }
            />
            {isLoaded && (
              <rect
                id={`svg_rect__${segment.id + (chardId || '')}`}
                onMouseLeave={
                  data_loading
                    ? hideLabel.bind(null, `${segment.id + (chardId || '')}`)
                    : dummyFunc
                }
                rx={textStyle.borderRadius || '2'}
              />
            )}
            <Text
              id={`svg_text__${segment.id + (chardId || '')}`}
              className="custom_chart__text"
              color={textStyle ? textStyle.color || '#fff' : '#fff'}
              {...restTextProps}
              data-seg-id={segment.id}
              data-seg-value={segment.label}
              x={segment.textCoords.x}
              y={segment.textCoords.y}
              dy="3px"
              textAnchor="middle"
              onMouseEnter={
                data_loading
                  ? showLabel.bind(null, `${segment.id + (chardId || '')}`)
                  : dummyFunc
              }
              onMouseLeave={
                data_loading
                  ? hideLabel.bind(null, `${segment.id + (chardId || '')}`)
                  : dummyFunc
              }
              isActive={toggledLabel.id === segment.id}
            >
              {(data_loading &&
                toggledLabel.id === segment.id &&
                toggledLabel.value) ||
                (showSegPercentage && `${Math.round(segment.percent * 100)}%`)}
            </Text>
          </g>
        ))}
      </svg>
      {/* {chartTotal && (
        <div className="custom_chart__center">
          <h3 className="custom_chart__center--heading">{chartTotal.label}</h3>
          <span className="custom_chart__center--value skeleton">
            {chartTotal.value}
          </span>
        </div>
      )} */}
    </AsDiv>
  );
}

// main def of CustomChart
CustomChart.propTypes = propTypes;
CustomChart.defaultProps = defaultProps;
// helpers
const getTotal = (segments) =>
  segments.reduce((acc, { value }) => acc + value, 0);
const getPercent = (value, total) => value / total;
const sortValues = (values) => values.sort((a, b) => b.value - a.value);
const getCircumference = (radius) => 2 * Math.PI * radius;
const convertDegreesToRadians = (angle) => angle * (Math.PI / 180);
// styles
const AsDiv = styled.div`
  &.custom_chart {
    position: relative;

    & text {
      opacity: 0;
      transition: opacity 0.4s ease-in-out 1s;
    }

    & circle {
      fill: none;
      transition: stroke-dashoffset 1s ease-in-out;
    }

    &:not(.custom_chart--loaded) circle {
      stroke-dashoffset: 0;
    }

    &--loaded text {
      cursor: default;
      opacity: ${({ showSegLabel }) => (showSegLabel ? 1 : 0)};
    }
  }

  .custom_chart__ {
    &center {
       {
        width: 80px;
        word-break: break-word;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--heading {
        margin: 0;
        ${theme.fonts.small}
        text-align: center;
        color: #9e9e9e;
      }

      &--value {
        ${theme.fonts.largeBold}
        color: ${theme.palette.gray800};
      }
    }

    &svg {
      position: relative;
    }
  }

  .skeleton:empty:after {
    width: 90px;
  }

  // animation
  @keyframes donut-fill {
    to {
      stroke-dasharray: 100;
    }
  }

  @keyframes text-slideup {
    from {
      opacity: 0;
      transform: translateY(0%);
    }
    to {
      opacity: 1;
      transform: translateY(-50%);
    }
  }
`;
const Text = styled.text`
  font-size: ${({ isActive }) => (isActive ? '14px' : '12px')};
  font-weight: ${({ isActive }) => (isActive ? '500' : '500')};
  line-height: ${({ isActive }) => (isActive ? '21px' : '19px')};

  text-shadow: ${({ isActive }) => (isActive ? `0 0rem 0rem ` : '0 0 0.5rem ')}
    ${({ color }) => (color ? darken(0.6, color) : '#000')};
  fill: ${({ color, isActive }) =>
    isActive ? (color ? lighten(0.6, color) : '#fff') : 'transparent'};

  @media (max-width: 700px) {
    font-size: ${({ isActive }) => (isActive ? '10px' : '8px')};
    line-height: ${({ isActive }) => (isActive ? '14px' : '10px')};
  }
`;
